import React from 'react'
import { Box, Typography, TableContainer, makeStyles, createStyles, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import HoverMenu from '../../../../Globals/GlobalComponents/HoverMenu'
import { useEffect, useState } from 'react';
import { getThirdPartyList } from '../../actions';
import { useSelector, useDispatch } from 'react-redux'
import AddThirdparty from './AddThirdParty'
import { MICROPOLICY_PROPERTY_CHANGE } from './policyReducer'
import AddIcon from '@material-ui/icons/Add';


function ThirdParties(props) {
    const dispatch = useDispatch()
    const {microPolicySettings, setMicroPolicySettings} = props
    // const thirdPartiesList = useSelector(state => (state.getIn(['thirdPartyOps', 'getThirdPartyList'])))
    const policy = useSelector(state => (state.getIn(['micropolicyOps', 'getMicropolicy'])))
    const [openAddThirdPartyDialog, setOpenAddThirdPartyDialog] = useState(false)

    // useEffect(() => {
    //     thirdPartiesList === undefined && handleGetThirdPartyList()
    // }, [])

    const handleGetThirdPartyList = () => {
        dispatch(getThirdPartyList(props.params.policyId))
    }

    const handleNewTPOpen = () => {
        setOpenAddThirdPartyDialog(!openAddThirdPartyDialog)
    }

    const handleChange = (e, index) =>{
		let data = {...microPolicySettings}
		console.log(data)
		data.thirdParty[index][e.target.name]=e.target.value
		setMicroPolicySettings({type: MICROPOLICY_PROPERTY_CHANGE, data:{name:'thirdParty', value: data.thirdParty}})
	}


	const handleDelete = (dataPoint,index)=>{
		console.log(dataPoint)
		let data = {...microPolicySettings}
		data.thirdParty.splice(index,1)
		setMicroPolicySettings({type: MICROPOLICY_PROPERTY_CHANGE, data:{name:'thirdParty', value: data.thirdParty}})
	}

	const handleAdd = () =>{
		let data = {...microPolicySettings}
		data.thirdParty.push({name:'', policyUrl: ''})
		setMicroPolicySettings({type: MICROPOLICY_PROPERTY_CHANGE, data:{name:'thirdParty', value: data.thirdParty}})
	}



    return (
        <Box>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <Typography variant="h5">
                        Third Parties
                    </Typography>
                    <Typography variant="body2">
                        Let your users know where their data goes
                    </Typography>
                </Box>
            </Box>

            <TableContainer style={{ marginTop: '1.5rem' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Third Party Name</TableCell>
                            <TableCell>Privacy Policy URL</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {microPolicySettings && microPolicySettings.thirdParty.map((thirdParty, index) => (
                            <TableRow key={index}>
									<TableCell><Input name="name" placeholder="Policy name" onChange={(e)=>handleChange(e, index)} value={thirdParty.name}/></TableCell>
									<TableCell><Input name="policyUrl" placeholder="Policy URL" onChange={(e)=>handleChange(e, index)}  value={thirdParty.policyUrl}/></TableCell>
									<TableCell key={index} onClick={()=>handleDelete(thirdParty, index)} style={{cursor:'pointer'}}>x</TableCell>
                            </TableRow>
                        ))
                        }
                        <TableRow>
							<TableCell>
                                <Button variant="outlined" onClick={handleAdd}><AddIcon/> Add</Button>
							</TableCell>
						</TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <AddThirdparty open={openAddThirdPartyDialog} onClose={handleNewTPOpen} policyId={props.params.policyId} campId={props.params.id} trackingId={policy.trackingId} /> */}
        </Box>
    )
}


const Input= (props)=>{
	const classes = useStyles()
	return(
		<input className={classes.inputClass} {...props}/>
	)
}


const useStyles= makeStyles(theme =>
	createStyles({
		inputClass:{
            border: '1px solid transparent',
            background:'#c6c6c64d',
			'&:focus':{
			outline:'none'
		}
	}
}))

export default ThirdParties
