import React, {useState, useEffect} from 'react'
import { Box, makeStyles, createStyles, Tabs, Tab, Paper } from "@material-ui/core";
// import PropTypes from "prop-types"
import { Link } from "react-router";
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import PolicyIcon from '@material-ui/icons/Policy';
import ReorderIcon from '@material-ui/icons/Reorder';
import PolymerIcon from '@material-ui/icons/Polymer';



function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`
	}
}


const CookieNavigation = (props) =>{

	const classes = useStyles()
    const [currentTab, setCurrentTab] = useState(0)
    const currentUrl = window.location.href
    
    useEffect(()=>{ 
        if(currentUrl.includes('appearance')) setCurrentTab(0)
        if(currentUrl.includes('micro-policies')) setCurrentTab(1)
        if(currentUrl.includes('autoblocking')) setCurrentTab(2)
    },[currentUrl])


	const handleTabChange = (e, newTab) =>{
		setCurrentTab(newTab)
	}
	
	return(
		<Box>
			<Box className={classes.tabsContainer} style={{borderRadius:'4px', backgroundColor:'#fff'}}>
				<Tabs
                    orientation="horizontal"
                    // value={value}
                    // onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className={classes.tabs}
					variant="fullWidth" 
					centered
					value={currentTab}
					onChange={handleTabChange}
					indicatorColor="primary"
					textColor="primary"
                >
                    {/* <Tab 
                        className={classes.tabLinkStyle} 
                        classes={{wrapper: classes.iconLabelWrapper, labelIcon:classes.tablabelIcon, textColorPrimary:classes.textColorPrimary }}
                        component={Link} 
                        to={`/cookie-monster/${props.id}/overview`} 
                        label="Overview" 
                        icon={<ReorderIcon/>} 
                        {...a11yProps(0)} 
                    /> */}
                    <Tab 
                        className={classes.tabLinkStyle} 
                        classes={{wrapper: classes.iconLabelWrapper, labelIcon:classes.tablabelIcon, textColorPrimary:classes.textColorPrimary }}
                        component={Link} 
                        to={`/cookie-monster/${props.id}/appearance`} 
                        label="Appearance" 
                        icon={<PolymerIcon/>} 
                        {...a11yProps(0)} 
                    />
                    <Tab 
                        className={classes.tabLinkStyle} 
                        classes={{wrapper: classes.iconLabelWrapper, labelIcon:classes.tablabelIcon, textColorPrimary:classes.textColorPrimary}}
                        component={Link} 
                        to={`/cookie-monster/${props.id}/micro-policies`} 
                        label="Micro Policies" 
                        icon={<PolicyIcon/>} 
                        {...a11yProps(2)}
                    />
                    <Tab 
                        className={classes.tabLinkStyle} 
                        classes={{wrapper: classes.iconLabelWrapper, labelIcon:classes.tablabelIcon, textColorPrimary:classes.textColorPrimary}}
                        component={Link} 
                        to={`/cookie-monster/${props.id}/autoblocking`} 
                        label="Auto Blocking" 
                        icon={<ToggleOnIcon/>} 
                        {...a11yProps(1)}
                    />
                </Tabs>
			</Box>
		</Box>
	)
}


const useStyles = makeStyles(theme =>
	createStyles({
        tabsContainer:{
            border:'1px solid #c9c9c9',
            borderRadius: theme.shape.borderRadius
        },
		tabs: {
            // borderRight: `1px solid ${theme.palette.divider}`,
        },
        textColorPrimary:{
            color:'#484848',
            textTransform:'capitalize',
            '& svg':{
                marginBottom:'0 !important'
            }
        },
        tablabelIcon:{
            minHeight:'60px'
        },
        tabLinkStyle:{
            fontSize: '15px',
            fontWeight:'600',
            lineHeight: '20px',
            flexGrow: '1'
        },
        labelContainer: {
            width: "auto",
            padding: theme.spacing(2)
        },
        iconLabelWrapper: {
            flexDirection: "row",
            "& svg":{
                marginRight: theme.spacing(2)
            }
        },
	})
)

export default CookieNavigation
