
export const initialState = {
    panelStyle: {
        radius: 5,
        borderWidth: 0,
        borderColor: {
            r: 200,
            g: 200,
            b: 200,
            a: 0.8
        },
        shadow: {
            r: 0,
            g: 0,
            b: 0,
            color: "lightgrey"
        },
        blur: 0,
        color: {
            r: 0,
            g: 149,
            b: 247,
            a: 1
        },
        secondaryColor: {
            r: 102,
            g: 102,
            b: 102,
            a: 1
        },
        linkColor: {
            r: 0,
            g: 137,
            b: 216,
            a: 1
        },
        backgroundColor: {
            r: 255,
            g: 255,
            b: 255,
            a: 0
        },
        iconBGColor: {
            r: 255,
            g: 255,
            b: 255,
            a: 1
        },
        ctaBackgroundColor: {
            r: 9,
            g: 127,
            b: 255,
            a: 1
        },
        ctaTextColor: {
            r: 1,
            g: 1,
            b: 1,
            a: 1
        },
        fontWeight: 'normal',
        fontFamily: 'sans-serif',
        linkFontFamily: "inherit",
        linkFontWeight: "normal",
        selectDurationData: "days",
        selectLastDisplayConversation: "days",
        // bulkData: 5,
        // recentNumber: 5,
        recentConv: 20,
        hideAnonymousConversion: true,
        onlyDisplayNotification: false,
        liveVisitorCount: 0,
        otherText: "signed up for",
        image: ""
    },
    liveVisitorText: 'are viewing this site',
    contentText: "Influence",
    visitorText: "people",
    otherText: "signed up for",
    orderText: "purchased ",
    gglReviewText: "Reviewed us on Google",
    facebookReviewText : "Reviewed us on Facebook",
    trustpilotText:  "Reviewed us on Trustpilot",
    stampedioText: "Reviewed us on Stamped.io",
    //reviewioText,
    capterraText:"Reviewed us on Capterra",
    announcementHeaderText: "Update Available",
    announcementSubText: "Do you want to install the update right now",
    toggleHideName: true,
    isHideCityLocation: false,
    isHideState: false,
    isHideCountryLocation: false,
    isHideFullLocation: false,
    usernameText: "someone",
    poweredBy: "Influence",
    poweredByLink: "https://useinfluence.co",
    togglePoweredBy: true,
    notificationUrl: "",
    toggleMap: "image",
    recentText1: "from",
    recentText2: "by",
    bulkText: "in last ",
    bulkDays: "days",
    liveText: "Verified by ",
    ctaButtonText: "Book Now",
    ctaHyperlinkUrl: "",
    isCTATop: false,
    toggleCTA: true,
    langName: {
        language: "en",
        name: "English"
    },
    bulkDaysLable: "days",
    isDisplaySignup: true,
    isHideLastname: false,
    captureAllData: false,
    isDisplayPurchase: true,
    notificationSize: "large",
    notificationDisplayApp: "google",
    bulkVersion: "2.0",
    isEnablePurchaseNotificationUrl: true
}

export const PROPERTY_CHANGE = 'PROPERTY_CHANGE';
export const PANEL_PROPERTY_CHANGE = 'NAMED_PROPERTY_CHANGE'

const NotifSettingsReducer = (state = initialState, action) => {
    switch(action.type) {
        case PROPERTY_CHANGE : 
            return {...state, [action.data.type] : action.data.value};
        case PANEL_PROPERTY_CHANGE :
            return {...state, panelStyle: {...state.panelStyle, [action.data.type] : action.data.value} }
        case 'LANGUAGE_CHANGE' :
            return {...state, [action.data.type] : action.data.value}
        case 'INIT' : {
            return action.data === "" ? state : action.data
        }
        default :
            return state
    }
}

export default NotifSettingsReducer;