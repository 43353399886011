import React from 'react'
import { Box, makeStyles, createStyles } from '@material-ui/core'

const Tails = () => {
  const classes = useStyles()
  return <Box>asdfasdfa</Box>
}

const useStyles = makeStyles((theme) => createStyles({}))

export default Tails
