import React, { memo, useEffect, useState } from 'react';
import { makeStyles, createStyles, Drawer, Typography, Toolbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import BulkNotificationSettings from './BulkNotificationSettings/BulkNotificationSettings';
import RecentNotificationSetting from './RecentNotificationSetting/RecentNotificationSetting';
import LiveNotificationSettings from './LiveNotificationSetting/LiveNotificationSettings';
import ReviewNotificationSetting from './ReviewNotificationSetting/ReviewNotificationSetting';
import AnnouncementNotificationSetting from './AnnouncementNotificationSetting/AnnouncementNotificationSetting';
import CustomNotificationSettings from './CustomNotificationSettings/CustomNotificationSettings';
import { useSelector } from 'react-redux';
import theme from '../../../Resources/theme';



const SidePanel = (props) => {
    const classes = useStyles();
    
    const {sidePanelStatus, 
        handlePropertyChange,
        handleInputChange,
        notificationType,
        onClose, 
        ruleName, 
        campaignInfo,
        notificationConfiguration,
        handlePanelStyleChange,
        handleLanguageChange,
        handleNotificationSubmit,
        loading
    } = props;


    const notificationSettings = useSelector(state => state.getIn(['NotificationSettings','notificationConfiguration']))
    const profile = useSelector(state => (state.getIn(['profile', 'profile']))) || []

    const [notifBoxBGColor, setNotifBoxBGColor] = useState("#fff");
    const [brandingLocked, setBrandingLocked] = useState(false)
    
    const listenScroll = (e) => {
        if(e.target.scrollTop > 90) {
            setNotifBoxBGColor("linear-gradient( to bottom, rgba(0,0,0,0.5), transparent )")
        } else setNotifBoxBGColor("#fff")
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScroll)
    }, []);


    const lockDecider = (profile) =>{
        if(profile.plan?.id === "plan_H5i0rKx2W17m0k" || profile?.plan.id === "plan_H5i7ea8CpQsSWW" || profile?.plan.id === "price_HJBaYjGF19qCRO" || profile?.plan.id === "price_1HHRfQCkWzi7OrXWqa85Hhvi" || profile.plan?.id === "price_1H88NRCkWzi7OrXW5S0rTlXj" || profile?.plan.id === "price_1HiD20CkWzi7OrXWvCZVywSQ"){
            setBrandingLocked(true)
        } 
    }

    useEffect(()=>{
        lockDecider(profile)
    },[profile])
    
    return(
        <Drawer 
            onScroll={(e) => listenScroll(e)}
            open={sidePanelStatus} 
            anchor="right" 
            id="sidePanel"  
            onClose={onClose} 
            className={classes.drawerPaper} 
        >
            
            <Toolbar classes={{root: classes.toolbarRoot}}>
                <IconButton onClick={onClose} className={classes.fabRoot}>
                    <CloseIcon />
                </IconButton>
                <Typography style={styles.mainHeading}>
                    {ruleName}
                </Typography>
            </Toolbar>

            {ruleName === 'Bulk Activity' ?
                <BulkNotificationSettings 
                    ruleName={ruleName} 
                    campaignInfo={campaignInfo}
                    notificationConfiguration={notificationConfiguration} 
                    handlePropertyChange={handlePropertyChange} 
                    handleInputChange={handleInputChange} 
                    notificationType={notificationType}
                    handlePanelStyleChange={handlePanelStyleChange}
                    notificationSettings={notificationSettings}
                    handleLanguageChange={handleLanguageChange}
                    handleNotificationSubmit={handleNotificationSubmit}
                    loading={loading}
                    notifBoxBGColor={notifBoxBGColor}
                    brandingLocked={brandingLocked}
                    onClose={onClose}
                />
                : ruleName === 'Recent Activity' ?
                <RecentNotificationSetting 
                    ruleName={ruleName} 
                    notificationConfiguration={notificationConfiguration} 
                    handlePropertyChange={handlePropertyChange} 
                    handleInputChange={handleInputChange}
                    handlePanelStyleChange={handlePanelStyleChange} 
                    campaignInfo={campaignInfo}
                    notificationType={notificationType}
                    handleLanguageChange={handleLanguageChange}
                    handleNotificationSubmit={handleNotificationSubmit}
                    loading={loading}
                    notifBoxBGColor={notifBoxBGColor}
                    brandingLocked={brandingLocked}
                    onClose={onClose}
                />
                : ruleName === 'Live Visitor Count' ?
                <LiveNotificationSettings 
                    ruleName={ruleName} 
                    notificationConfiguration={notificationConfiguration} 
                    handlePropertyChange={handlePropertyChange} 
                    handleInputChange={handleInputChange}
                    handlePanelStyleChange={handlePanelStyleChange} 
                    campaignInfo={campaignInfo}
                    notificationType={notificationType}
                    handleLanguageChange={handleLanguageChange}
                    handleNotificationSubmit={handleNotificationSubmit}
                    loading={loading}
                    notifBoxBGColor={notifBoxBGColor}
                    brandingLocked={brandingLocked}
                    onClose={onClose}
                />
                : ruleName === 'Announcement Notifications' ?
                <AnnouncementNotificationSetting
                    ruleName={ruleName} 
                    notificationConfiguration={notificationConfiguration} 
                    handlePropertyChange={handlePropertyChange} 
                    handleInputChange={handleInputChange}
                    handlePanelStyleChange={handlePanelStyleChange} 
                    campaignInfo={campaignInfo}
                    notificationType={notificationType}
                    handleLanguageChange={handleLanguageChange}
                    handleNotificationSubmit={handleNotificationSubmit}
                    loading={loading}
                    notifBoxBGColor={notifBoxBGColor}
                    brandingLocked={brandingLocked}
                    onClose={onClose}
                />
                : ruleName === 'Review Notifications' ?
                <ReviewNotificationSetting 
                    ruleName={ruleName} 
                    notificationConfiguration={notificationConfiguration} 
                    handlePropertyChange={handlePropertyChange} 
                    handleInputChange={handleInputChange}
                    handlePanelStyleChange={handlePanelStyleChange} 
                    campaignInfo={campaignInfo}
                    notificationType={notificationType}
                    handleLanguageChange={handleLanguageChange}
                    handleNotificationSubmit={handleNotificationSubmit}
                    loading={loading}
                    notifBoxBGColor={notifBoxBGColor}
                    brandingLocked={brandingLocked}
                    onClose={onClose}
                />
                : ruleName === 'Custom Notifications' ?
                <CustomNotificationSettings 
                    ruleName={ruleName} 
                    notificationConfiguration={notificationConfiguration} 
                    handlePropertyChange={handlePropertyChange} 
                    handleInputChange={handleInputChange}
                    handlePanelStyleChange={handlePanelStyleChange} 
                    campaignInfo={campaignInfo}
                    notificationType={notificationType}
                    handleLanguageChange={handleLanguageChange}
                    handleNotificationSubmit={handleNotificationSubmit}
                    loading={loading}
                    notifBoxBGColor={notifBoxBGColor}
                    brandingLocked={brandingLocked}
                    onClose={onClose}
                />
                :<AnnouncementNotificationSetting
                    ruleName={ruleName} 
                    notificationConfiguration={notificationConfiguration} 
                    handlePropertyChange={handlePropertyChange} 
                    handleInputChange={handleInputChange}
                    handlePanelStyleChange={handlePanelStyleChange} 
                    campaignInfo={campaignInfo}
                    notificationType={notificationType}
                    handleLanguageChange={handleLanguageChange}
                    handleNotificationSubmit={handleNotificationSubmit}
                    loading={loading}
                    notifBoxBGColor={notifBoxBGColor}
                    brandingLocked={brandingLocked}
                    onClose={onClose}
                />
            }
        </Drawer>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        toolbarRoot: {
            backgroundColor:'#fff',
            borderBottom:'solid 1px rgba(9,30,66,.12)',
            display:'block',
            height:'40px',
            padding:'12px 16px 0',
            width: '100%',
            position:"fixed",
            minHeight:"50px",
            zIndex:theme.zIndex.toolbar
        },
        fabRoot: {
            backgroundColor: 'rgba(9,30,66,.87)',
            borderRadius: '0 0 0 5px',
            color: '#fff',
            float: 'none',
            fontSize: '20px',
            fontWeight: '400',
            height: '50px',
            left: '-51px',
            opacity: '.7',
            position: 'absolute',
            textShadow: '0 0 0 #fff',
            top: '0',
            transition: '.3s',
            width: '50px',
            '&:hover':{
                backgroundColor: 'rgba(9,30,66,1)'
            },
            "&:focus" :{
                outline: 'none'
            }
           

        },

        title: {
            fontSize: '23px',
            color: '#fff',
            letterSpacing: '0.08rem'
        },
        notificationBox: {
            height: '35%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '7%',
            position: 'sticky',
            top: '0px',
            zIndex: '9999',
            paddingBottom: '11%',
            backgroundColor: '#fff'
        },
        drawerPaper: {
            paddingBottom: '42px',
            zIndex:theme.zIndex.drawer + 1000
        }
    })    
)

    const styles ={
        mainHeading: {
            fontSize: '20px',
            color:'rgba(9,30,66,1)'
            // padding: '10px'
        },
        upperForm:{
            display: 'flex',
            flexDirection: 'row',
            padding: '40px'
        },
        mainArea:{
            margin: '60px 15px auto 15px'
        },
        borderLine:{
            borderBottom: '1px solid #e8e8e8',
            width: '100%'
        },
        cellHeader:{
            // width: '29px',
            height: '17px',
            fontFamily: 'Poppins',
            fontSize: '12px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.5',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#9e9e9e'
        },
        textfield:{
            marginRight: '15px'
        },
        tableBodyWrapper:{
            minWidth: '100%',
            textAlign: 'center'
        },
        cellItems:{
            height: '18px',
            fontFamily: 'Poppins',
            fontSize: '13px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.5',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: 'black'
        }

    }



export default memo(SidePanel);

