import axios from 'axios';
import mixpanel from 'mixpanel-browser'

export const GET_CAMPAIGN_INFO = 'GET_CAMPAIGN_INFO';
export const DELETE_CAMPAIGN_INFO ='DELETE_CAMPAIGN_INFO';
export const PUT_TOGGLE_BUTTON_CAMPAIGN_INFO = 'PUT_TOGGLE_BUTTON_CAMPAIGN_INFO';

// get request in campaign section.
export const getCampaignInfo = (setLoading) => {
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    localStorage.getItem('authToken');
    const token = authToken ? authToken.token : null;
    return(
        async(dispatch) => {
            const res = await axios({
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` },
                url: '/campaign'
            }).catch(err => {throw err})
            dispatch({type: GET_CAMPAIGN_INFO, payload: res.data})
            //loader in get request 
            if(setLoading) {
                setLoading(false)
            }
            return(res);
        }
    );
}

//delete request in campaign section.
export const deleteCampaignInfo = (id, setLoadingDelete) => {
    return(
        async(dispatch) => {
            setLoadingDelete(true);
            const res = await axios({
                method:'DELETE',
                url:`campaign/${id}`
            }).catch(err => {
                setLoadingDelete(false)
                throw err
            });
            dispatch({type:DELETE_CAMPAIGN_INFO,payload:res.data});
            //dispatch({type: GET_CAMPAIGN_INFO, payload: res.data})
            setLoadingDelete(false)
            return(res);
        }
    )
}

//put request in toogle button in campaign section.
export const putToggleButtonHandler= (campaignInfo,newData,setLoading) => {
  
    return(
        async(dispatch) => {
            // loader is added in toogle button
            setLoading(true);
            const res = await axios({
                method:'PUT',
                url:`campaign/${campaignInfo._id}`,
                data:{
                    // _id:`${campaignInfo._id}`,
                    // campaignInfo:`${campaignInfo.campaignType}`,
                    // campaignName:`${campaignInfo.campaignName}`,
                    // websiteUrl:`${campaignInfo.websiteUrl}`,
                    // profile:campaignInfo.profile,
                    ...newData
                    // health:`${campaignInfo.health}`,
                    // trackingId:`${campaignInfo.trackingId}`,
                    // rule:`${campaignInfo.rule._id || campaignInfo.rule}`,
                    // webhook_list:campaignInfo.webhook_list
                }

            }).catch(err => {
                setLoading(false);
                throw err
            });
            dispatch({type:PUT_TOGGLE_BUTTON_CAMPAIGN_INFO,payload:res.data});
            setLoading(false);
            return(res);
        }
    )
}



export const publishAction= (campaignInfo,setLoading, openModal)=>{
    return(
        async(dispatch)=>{
            const res = await axios({
                method:'PUT',
                url:`campaign/${campaignInfo._id}`,
                data:{
                    ...campaignInfo,
                    isActive:true
                }
            }).catch(err=>{
                setLoading(false)
                throw err
            })
            .then((response)=>{
                axios({
                    url:`/elasticsearch/update/${campaignInfo._id}`,
                    method:'DELETE'
                })
                .then(res=>{
                    setLoading(false)
                    openModal(true)
                });
                return response

            }) 
            dispatch({type:PUT_TOGGLE_BUTTON_CAMPAIGN_INFO,payload:res.data});
            mixpanel.track('Campaign Publish', {
              campaignId: res.data._id,
              campaignName: res.data.campaignName,
              websiteUrl: res.data.websiteUrl,
            })
        }
    )
}


export const freePlanUpgrade = (user) =>{
    return(
        axios({
            url:'/payment/freePlan',
            method:'POST',
            data:{
                user,
                plan:{
                    currency: "usd",
                    free_quantity: 0,
                    id: "price_HJBaYjGF19qCRO",
                    invoice_name: "Free Forever Plan",
                    name: "Free Forever Plan",
                    object: "plan",
                    interval_count: 1,
                    interval: "month",
                    amount: 0,
                    status: true,
                    description: 1000,
                    teammateQouta: 1,
                    trial_period: null,
                }
            }
        })
    )
}