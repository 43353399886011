import React, { useReducer, useEffect, useState, useRef, memo } from 'react';
import { Box, makeStyles, createStyles } from '@material-ui/core';
import RecentNotificationCard from './RecentNotificationCard';
import RecentNotificationRules from './RecentNotificationRules';
import NotifSettingsReducer, { initialState } from '../../NotifSettingsReducer';
import { useSelector } from 'react-redux';
import translate from 'translate';
import NotificationCardSkeleton from '../../../../Globals/GlobalComponents/NotificationCardSkeleton';
import NotificationRuleSkeleton from '../../../../Globals/GlobalComponents/NotificationRuleSkeleton';


translate.engine = 'google';
// translate.key = 'AIzaSyDB2KZyXOUPBC3v0SWh7-vBzg_gTlpLc30';
translate.key = process.env.REACT_APP_GOOGLE_TRANSLATE_KEY;

const RecentNotificationSetting  = (props) => {
    const classes = useStyles();
    const {
        handlePropertyChange, 
        handleInputChange, 
        // notificationConfiguration, 
        ruleName, 
        handlePanelStyleChange,
        campaignInfo,
        notificationType,
        handleLanguageChange,
        handleNotificationSubmit,
        loading,
        notifBoxBGColor,
        brandingLocked,
        onClose
    } = props;

    
    const [recentSettings, setRecentSettings] = useReducer(NotifSettingsReducer, initialState);
    const [recentText1, setRecentText1] = useState("")
    const [otherText, setOtherText] = useState("");
    const [contentText, setContentText] = useState("");
    const [extraText, setExtraText] = useState("");
    const [recentText2, setRecentText2] = useState("");
    const [poweredBy, setPoweredBy] = useState("");
    const [show, setShow] = useState(false);

    const notificationSettings = useSelector(state => (state.getIn(['NotificationSettings', 'notificationConfiguration'])))

    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        },200)
    },[])



    useEffect(() => {
        setOtherText(recentSettings.otherText);
    },[recentSettings.otherText])

    useEffect(() => {
        setExtraText(recentSettings.extraText);
    },[recentSettings.extraText])

    useEffect(()=>{
        setRecentText1(recentSettings.recentText1)
    },[recentSettings.recentText1])

    useEffect(() => {
        setContentText(recentSettings.contentText);
    },[recentSettings.contentText])

    useEffect(() => {
        setRecentText2(recentSettings.recentText2);
    },[recentSettings.recentText2])

    useEffect(() => {
        setPoweredBy(recentSettings.poweredBy);
    },[recentSettings.poweredBy])

    useEffect(() => {
        if(notificationSettings !== undefined){
            setRecentSettings({type: 'INIT', data: notificationSettings.find(item => item.notificationType.notificationName === ruleName)})
        }
    },[notificationSettings])

    const lastLangRef = useRef()

    // useEffect(() => {
    //     lastLangRef.current = recentSettings.langName.language;
        
    //     translate(recentSettings.extraText, {from: lastLang, to: recentSettings.langName.language}).then(
    //         text => {
    //             setExtraText(text)
    //             // handlePropertyChange('extraText', text, setRecentSettings)
    //         }
    //     )
    //     translate(recentSettings.otherText, {from: lastLang, to: recentSettings.langName.language }).then(
    //         text => {
    //             setOtherText(text)
    //             // handlePropertyChange('otherText', text, setRecentSettings)
    //         }
    //     )
    //     translate(recentSettings.contentText, {from: lastLang, to: recentSettings.langName.language }).then(
    //         text => {
    //             setContentText(text)
    //             // handlePropertyChange('contentText', text, setRecentSettings)
    //         }
    //     )
    //     translate(recentSettings.recentText2, {from: lastLang, to: recentSettings.langName.language }).then(
    //         text => {
    //             setRecentText2(text)
    //             // handlePropertyChange('recentText2', text, setRecentSettings)
    //         }
    //     )
    //     translate(recentSettings.recentText1, {from: lastLang, to: recentSettings.langName.language }).then(
    //         text => {
    //             setRecentText1(text)
    //             // handlePropertyChange('recentText1', text, setRecentSettings)
    //         }
    //     )
    //     translate(recentSettings.poweredBy, {from: lastLang, to: recentSettings.langName.language }).then(
    //         text => {
    //             setPoweredBy(text)
    //             // handlePropertyChange('poweredBy', text, setRecentSettings)
    //         }
    //     )

    // },[
    //     recentSettings.langName.language, 
    //     recentSettings.contentText,
    //     recentSettings.extraText,
    //     recentSettings.otherText,
    //     recentSettings.recentText2,
    //     recentSettings.poweredBy,
    //     recentSettings.recentText1,
    //     // recentSettings.langName.language
    // ])

    // const lastLang = lastLangRef.current

    const languageChangeHandler = (name, value, dispatcher) =>{
        translate(recentSettings.extraText, {from: recentSettings.langName.language, to: value}).then(
            text => {
                setExtraText(text)
                handlePropertyChange('extraText', text, setRecentSettings)
            }
        )
        translate(recentSettings.otherText, {from: recentSettings.langName.language, to: value }).then(
            text => {
                setOtherText(text)
                handlePropertyChange('otherText', text, setRecentSettings)
            }
        )
        translate(recentSettings.contentText, {from: recentSettings.langName.language, to: value }).then(
            text => {
                setContentText(text)
                handlePropertyChange('contentText', text, setRecentSettings)
            }
        )
        translate(recentSettings.recentText2, {from: recentSettings.langName.language, to: value }).then(
            text => {
                setRecentText2(text)
                handlePropertyChange('recentText2', text, setRecentSettings)
            }
        )
        translate(recentSettings.recentText1, {from: recentSettings.langName.language, to: value }).then(
            text => {
                setRecentText1(text)
                handlePropertyChange('recentText1', text, setRecentSettings)
            }
        )
        translate(recentSettings.poweredBy, {from: recentSettings.langName.language, to: value }).then(
            text => {
                setPoweredBy(text)
                handlePropertyChange('poweredBy', text, setRecentSettings)
            }
        )

        handleLanguageChange(name, value, dispatcher)
    }


    
    return(
        show ?
            <Box>
                <Box style={{background: notifBoxBGColor}} className={classes.notificationBox} padding='5% 0%'>
                    <RecentNotificationCard 
                        recentSettings={recentSettings}
                        otherText={otherText}
                        contentText={contentText}
                        extraText={extraText}
                        recentText2={recentText2}
                        recentText1={recentText1}
                        poweredBy={poweredBy}
                    />
                </Box>
                <Box padding='6%'>
                    <RecentNotificationRules
                        handlePropertyChange={handlePropertyChange}                    
                        handleInputChange={handleInputChange}
                        setRecentSettings={setRecentSettings}
                        recentSettings={recentSettings}
                        handlePanelStyleChange={handlePanelStyleChange}
                        campaignInfo={campaignInfo}
                        handleLanguageChange={languageChangeHandler}
                        notificationType={notificationType.filter(item => item.notificationName === ruleName)}
                        notificationConfiguration={notificationSettings.filter(item => item.notificationType.notificationName === ruleName)}
                        handleNotificationSubmit={handleNotificationSubmit}
                        otherText={otherText}
                        contentText={contentText}
                        extraText={extraText}
                        recentText2={recentText2}
                        recentText1={recentText1}
                        poweredBy={poweredBy}
                        loading={loading}
                        brandingLocked={brandingLocked}
                        onClose={onClose}
                    />
                </Box>
            </Box>:
            
            <Box>
                <Box style={{background: notifBoxBGColor}} className={classes.notificationBox} padding='5% 0%'>
                    <NotificationCardSkeleton />
                </Box>
                <Box padding="6%">
                    <NotificationRuleSkeleton />
                </Box>
            </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        notificationBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '7%',
            position: 'sticky',
            top: '50px',
            zIndex: '9999',
            paddingBottom: '11%',
            transition: 'all 0.7s ease-in-out',
            backdropFilter: "blur(0.8px)"
        },
        loaderBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }
    })
)

export default memo(RecentNotificationSetting) ;