import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import DetailsScreen from './Pages/DetailsScreen'
import ProductsScreen from './Pages/ProductsScreen'
import InstallCode from './Pages/InstallCode'
import { Box, makeStyles, createStyles, Paper, Typography, Checkbox, Button, CircularProgress, Input, Card, withStyles } from '@material-ui/core'
import SubSidePanel from "./SubSidePanel";
import { Cog } from '../../img'
import { browserHistory } from 'react-router';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  setUp: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '24px'
  },
  root: {
    display: "flex",
    zIndex: '10000000',
    background: 'transparent'
  },
  appBar: {
    width: `calc(100% - ${145}px)`,
    marginLeft: '0%',
    backgroundColor: '#fff',
    boxShadow: '0 1px 4px rgba(0,0,0,0.2)',
    height:'60px'
  },
  drawer: {
    //width: drawerWidth,
    flexShrink: 0,
    width: '145px'
  },
  drawerPaper: {
    //width: drawerWidth,
    backgroundColor: '#082a52',
    color: '#fff',
    //top:'80px',
    bottom: '0',
    left: '0',
    width: '145px',
    margin: '0',
    zIndex: '9'
  },
  // necessary for content to be below app bar
  toolbar: {
    height:'60px',
    display:'flex',
    justifyContent:'end',
    alignItems:'center',
    paddingLeft: '12%'
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    // padding: theme.spacing(3),
    backgroundColor: '#fff'
  }
}));

const sideNavigationMenus = ["Install Code"]

export default function Sidepanel() {
  const classes = useStyles();
  const [panel, setPanel] = useState(0);

  const logoutHandler= () =>{
    localStorage.removeItem('authToken');
    browserHistory.push('/');
  }

  return (
    <div className={classes.root}>

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar style={{
          padding: '0px 25px',
          height: '60px',
          display:'flex',
          alignItems:'center',
          justifyContent:'space-between'
        }}>
          <Typography variant="h6" noWrap style={{color: '#000'}}>
            Install your pixel
          </Typography>
          <Button onClick={logoutHandler} variant="outlined">
            Logout
          </Button>
        </Toolbar>
      </AppBar>


      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="left"
      >
        <div className={classes.toolbar} >
          <Box className={classes.setUp}>
            <img src={Cog} height='16px' style={{ marginRight: '5px' }}></img>
            <Typography style={{ fontSize: '18px' }}>
              Setup
            </Typography>

          </Box>

        </div>
        <Divider />
        {/* <List style={{ listStyleType: 'disc' }}>
          {sideNavigationMenus.map((menu, index) => (
            <ListItem button key={index} onClick={() => setPanel(index)}>
              <ListItemIcon style={{ minWidth: '15px' }}><FiberManualRecordIcon style={{ color: '#fff', fontSize: '15px', marginRight: '10px' }} /></ListItemIcon>
              <ListItemText primary={menu} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
          <SubSidePanel currentPanel={panel} setPanel={setPanel} />
      </main>
    </div>
  );
}
