import axios from 'axios';
export const UPDATE_PROFILE_SETTINGS = 'UPDATE_PROFILE_SETTINGS';
export const GET_INVOICE = 'GET_INVOICE';
export const GET_PLANS = 'GET_PLANS';
export const UPGRADE_PLAN = 'UPGRADE_PLAN'


export const updateProfileSettings = (id, profileData ,setLoading) => {
    setLoading(true)
    return(
        async (dispatch) => {
            const res = await axios({
                method: 'PUT',
                url: `/profile/${id}`,
                data: {
                    ...profileData,
                    id: id,
                }
            }).catch(error => {
                setLoading(false);
                throw error
            })
            dispatch({type: UPDATE_PROFILE_SETTINGS, payload: res.data})
            setLoading(false)
            return(res);
        }
    );
}


export const getInvoice = (id) =>{
        const authToken = JSON.parse(localStorage.getItem('authToken'));
        const token = authToken ? authToken.token : null;
    return(
        async (dispatch) =>{
            const res = await axios({
                method: 'GET',
                url: `/payment/invoice/${id}`,
                headers: {'Authorization': `Bearer ${token}`}
            }).catch(err => {throw err})
            dispatch({type: GET_INVOICE, payload: res.data.data})
        }
    )
}

export const upgradePlan = (id, quota, name, setLoading,setToaster,setUpdateCardDialogStatus,setToastUpdateCard) =>{
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
    // const planId = 'plan_H3jnRdf6kGCicZ'
   
   
    return(
        async (dispatch) => {
            const res = await axios({
                method: 'POST',
                url: '/payment/servicebot/update',
                data:{
                    id: id,
                    description: quota,
                    name: name
                },
                headers: { 'Authorization': `Bearer ${token}`}
            }).catch(error => { 
                if(error){
                    setLoading(false)
                    setUpdateCardDialogStatus(true)
                    setToastUpdateCard(true)
                }
                throw error
            })
            dispatch({type: UPGRADE_PLAN, payload: res.data})
            setUpdateCardDialogStatus(false)
            setToastUpdateCard(false)
            setLoading(false)
            setToaster(true)
           //window.location.reload()
            //browserHistory.push('/campaigns')
            window.location.href = '/campaigns'
           
            
        }
    )
}

export const getAddedCard = async () =>{
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
    const res = await axios({
        method: 'GET',
        url: '/payment/servicebot/card',
        headers: { 'Authorization': `Bearer ${token}` }
    }).catch(err=>{
        throw err
    })
  
    return res.data;
}