// Common utitlity functions related to forms

import { POST, storeToken } from './Request';
import { base } from 'services/api';

// TODO: Set correct login api url
const LOGIN_API_URL = `${base}auth/local`;
const REGISTER_API_URL = `${base}auth/local/register`;
const VALIDATE_COUPEN_API_URL=`${base}appsumocoupen/codeValidate`;
const UPDATE_COUPEN_API_URL=`${base}appsumocoupen/codeUpdate`;


// Email regexp taken from http://emailregex.com/ (W3C standard)
const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const INVALID_EMAIL_MSG = 'Please enter a valid Email ID';

// Check if email follows valid syntax
const validateEmail = email => typeof email === 'string' && email.match(EMAIL_REGEXP) !== null;


// Password character limits
const PASSWORD_MIN_LENGTH = 5;
const PASSWORD_MAX_LENGTH = 40;

// TODO: Set appropriate password Regexp and then the message according to it.
//const PASSWORD_REGEXP = new RegExp(`^(?=.*\\d).{${PASSWORD_MIN_LENGTH},${PASSWORD_MAX_LENGTH}}$`);   // http://regexlib.com/REDetails.aspx?regexp_id=30
const PASSWORD_REGEXP = new RegExp(`^(?=.*).{${PASSWORD_MIN_LENGTH},${PASSWORD_MAX_LENGTH}}$`);   // http://regexlib.com/REDetails.aspx?regexp_id=30
const INVALID_PASSWORD_MSG = 'Password must be between 4 and 40 digits long.';

// Check if password follows valid syntax
const validatePassword = pwd => typeof pwd === 'string' && pwd.match(PASSWORD_REGEXP) !== null;


// Company Validation
const validateCompanyName = name => typeof name === 'string' && name.trim().length > 0;
/* eslint-disable */
const WEBSITE_REGEXP = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,9}(:[0-9]{1,5})?(\/.*)?$/i)   // https://www.regextester.com/93652
/* eslint-disable */
const validateWebsite = url => typeof url === 'string' && url.match(WEBSITE_REGEXP) !== null;


const validateFields = (email, pwd) => {
  return new Promise((resolve, reject) => {
    const errors = [];

    if (!validateEmail(email))
      errors.push({
        field: 'email',
        msg: INVALID_EMAIL_MSG
      });

    if (!validatePassword(pwd))
      errors.push({
        field: 'password',
        msg: INVALID_PASSWORD_MSG
      });

    // if has errors reject with the errors
    if (errors.length > 0) {
      reject({
        msg: 'Invalid Fields',
        errors
      });

      return;
    }

    resolve();
  });
};


// Validate fields and login. returns json response received
const login = (identifier, password) => {
  return validateFields(identifier, password).then(() => {
    // send request to server and return the response data
    return POST(LOGIN_API_URL, {
      identifier,
      password
    }, true).then(res => {
      // Try storing token in storage and throw error if failed
      if (!res.error && !storeToken(res['jwt'])) {
        throw 'failed to store token';
      }

      if (res.statusCode && res.statusCode !== 200) {
        throw res.message;
      }

      // resolve with the response
      return res;
    });
  });
};


// Validate fields and register. returns json response received
const register = async (email, password, plan, phoneNumber) => {
  await validateFields(email, password);
  const res = await POST(REGISTER_API_URL, {
    email,
    password,
    username: email.match(/^(.+)@/)[1],
    firstName: email.match(/^(.+)@/)[1],
    plan: plan,
    phoneNumber,
    source: document.location.href || "Null",
    access:{
      isCookie:false,
      isSocialProof:true
    },
  }, true);
  if (res.jwt) {
    if (!storeToken(res['jwt'])) {
      throw 'failed to store token';
    }
    return res;
  }
  if (res.statusCode !== 200) {
    throw res.message;
  }
  return res;
};
/* const register = (websiteUrl,firstName,phoneNumber,email, password, affiliate, plan, trafficCategory, uniqueVisitorQouta, isAppsumoUser, teammateQouta, subuserQouta, codeStack) => {
  return validateFields(email, password).then(() => {
    return POST(REGISTER_API_URL, {
      websiteUrl,
      firstName,
      phoneNumber,
      email,
      password,
      username: email.match(/^(.+)@/)[1],
      affiliate: affiliate,
      plan: plan,
      source: document.location.href || "Null",
      trafficCategory: trafficCategory,
      uniqueVisitorQouta: uniqueVisitorQouta,
      isAppsumoUser: isAppsumoUser,
      teammateQouta: teammateQouta,
      subuserQouta: subuserQouta,
      codeStack: codeStack,
      access:{
        isCookie:false,
        isSocialProof:true
      },
    }, true).then(res => {

      if (res.jwt) {
        if (!storeToken(res['jwt'])) {
          throw 'failed to store token';
        }
        return res;
      }

  if (res.statusCode !== 200) {
    throw res.message;
  }

  return res;
});
  });
}; */

//check coupen validation
const checkCoupenValidation = (code_1,code_2,code_3,code_4,code_5,code_6, code_7, code_8, code_9, code_10, code_11, code_12) => {
  const obj={
    code_1:code_1,
    code_2:code_2,
    code_3:code_3,
    code_4:code_4,
    code_5:code_5,
    code_6:code_6,
    code_7:code_7,
    code_8:code_8,
    code_9:code_9,
    code_10:code_10,
    code_11:code_11,
    code_12:code_12
  }
  return POST(VALIDATE_COUPEN_API_URL,obj, true).then(res => {
    return res;
  });
};

//update coupen code status
const updateCoupenValidation = (code_1,code_2,code_3,code_4,code_5,code_6, code_7, code_8, code_9, code_10, code_11, code_12, userid) => {
  const obj={
    code_1:code_1,
    code_2:code_2,
    code_3:code_3,
    code_4:code_4,
    code_5:code_5,
    code_6:code_6,
    code_7:code_7,
    code_8:code_8,
    code_9:code_9,
    code_10:code_10,
    code_11:code_11,
    code_12:code_12,
    userid:userid
    
  }
  return POST(UPDATE_COUPEN_API_URL,obj, true).then(res => {
    return res;
  });
};

export { validateEmail, validatePassword, login, register,checkCoupenValidation,updateCoupenValidation, PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH, WEBSITE_REGEXP, validateWebsite, validateCompanyName, INVALID_PASSWORD_MSG };
