import { fromJS } from 'immutable';

const action = name => `/configuration/${name}`;

export const REVIEW_REDIRECT = action('REVIEW_REDIRECT');
export const FETCH = action('FETCH');
export const FETCH_CAMPAIGN_CONFIG = action('FETCH_CAMPAIGN_CONFIG');
export const FETCH_CAMPAIGN_TYPE = action('FETCH_CAMPAIGN_TYPE');
export const CREATE = action('CREATE');
export const UPDATE = action('UPDATE');
export const SUCCESS = action('SUCCESS');
export const SUCCESS_CAMP_TYPE = action('SUCCESS_CAMP_TYPE');
export const CREATE_SUCCESS = action('CREATE_SUCCESS');
export const CLEAR = action('CLEAR');

export const reviewRedirect = (url) => ({ type: REVIEW_REDIRECT, url });
export const fetchConfiguration = (campId) => ({ type: FETCH, campId });
export const fetchCampaignConfiguration = (campId , notifId) => ({ type: FETCH_CAMPAIGN_CONFIG, campId, notifId });
export const fetchCampaignType = (trackingId) => ({ type: FETCH_CAMPAIGN_TYPE, trackingId });
export const createConfiguration = (configuration) => ({ type: CREATE, configuration });
export const updateConfiguration = (configuration) => ({ type: UPDATE, configuration });
export const successConfiguration = (configuration) => ({ type: SUCCESS, configuration });
export const successCampaignType = (campType) => ({type: SUCCESS_CAMP_TYPE, campType});
export const createSuccess = (configuration) => ({ type: CREATE_SUCCESS, configuration });
export const clearConfiguration = () => ({ type: CLEAR });

const initialConfig = {
  activity: false,
  panelStyle: { // TODO: Take style values from server
    radius: 50,
    borderWidth: 0,
    borderColor: {
      r: 200,
      g: 200,
      b: 200,
      a: 0.80
    },
    shadow: {
      r: 0,
      g: 0,
      b: 0,
      color: 'lightgrey'
    },
    blur: 0,
    color: {
      r: 0,
      g: 149,
      b: 247,
      a: 1
    },
    linkColor: {
      r: 0,
      g: 137,
      b: 216,
      a: 1
    },
    backgroundColor: {
      r: 255,
      g: 255,
      b: 255,
      a: 1
    },
    iconBGColor: {
      r: 0,
      g: 149,
      b: 247,
      a: 1
    },
    fontFamily: 'inherit',
    fontWeight: 'normal',
    linkFontFamily: 'inherit',
    linkFontWeight: 'normal',
    selectDurationData: 'hours',
    selectLastDisplayConversation: 'hours',
    bulkData: 5,
    recentNumber: 5,
    recentConv: 5,
    hideAnonymousConversion: true,
    onlyDisplayNotification: false,
    liveVisitorCount: 0
  },
  visitorText: '',
  contentText: '',
  otherText: '',
  orderText:'',
  gglReviewText:'',
  toggleHideName:'',
  isHideCityLocation: '',
  isHideCountryLocation: '',
  isHideFullLocation: '',
  usernameText:'',
  liveVisitorText:'',
  liveText:'',
  notificationUrl: '',
  channel: {},
  // Oauth variables
  clientId: '',
  clientSecret: '',
  clientname: '',
  errorname: '',
  authorizedOrigin: '',
  redirectURI: '',
  errorName: '',
  errorURI: '',
  errorAuthorizedOrigin: ''
};

const initialState = fromJS({
  configuration: initialConfig
});

const configuration = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS:
      return state.set('configurations', action.configuration);
    case CREATE_SUCCESS:
      return state.set('configuration', action.configuration);
    case CLEAR:
      return state.set('configuration', initialConfig);
    case SUCCESS_CAMP_TYPE:
      return state.set('campType', action.campType);

    default:
      return state;
  }
};

export default configuration;
