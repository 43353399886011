import React, { useEffect } from 'react';
import { Slider, Box, withStyles } from '@material-ui/core';

const PrettoSlider = withStyles({
    root: {
      color: '#2c66c3',
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus,&:hover,&$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);


const SlidingBar = (props) => {

	return(
		<Box>
			<PrettoSlider 
			onChange={(event, value) => props.onChange(props.name ? props.name : "", value, props.dispatcher)} 
			valueLabelDisplay="auto" 
			aria-label="pretto slider" 
			value={props.value}
			min={0}
			max={50}
			step={1}
			/>
		</Box>
	);
}

  export default SlidingBar;