import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import registerServiceWorker from './registerServiceWorker';
import * as Sentry from '@sentry/browser';
// Sentry.init({dsn: "https://c5c6b2781d33460eb78e0992562f16a5@sentry.io/5179231"});
// import "bootstrap/dist/css/bootstrap.min.css"

import App from './App.js';
import {storeToken } from './services/Request';
const rootEl = document.getElementById('root');

if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
}else{
	Sentry.init({dsn: "https://c5c6b2781d33460eb78e0992562f16a5@sentry.io/5179231"});	
}

var url_string = window.location.href
var url = new URL(url_string);
var auth_token = url.searchParams.get("auth_token");
if(auth_token){
	storeToken(auth_token)
}


const render = (Component) => {
  ReactDOM.render(
    <App Component={Component} />,
    rootEl
  );
};

render(Routes);


registerServiceWorker();
