import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import Loading from 'react-loading-animation';
import { ToastContainer, Zoom } from 'react-toastify';
import { CustomLoading } from 'components';
import { socialLogin } from 'ducks/auth';
// import './ConnectPage.scss';

class ConnectPage extends React.Component {
  componentDidMount() {
    const {
      params: { provider },
      location: { search },
    } = this.props;
    const requestURL = `auth/${provider}/callback${search}`;
    this.props.socialLogin(requestURL);
  }

  render() {
    return (
      <div>
        <CustomLoading isLoading={true} />
        <ToastContainer hideProgressBar={true} transition={Zoom}/>
      </div>
    );
  }
}

ConnectPage.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  socialLogin
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(ConnectPage);
