import React, { memo, useState, useEffect } from 'react'
import { Box, makeStyles, createStyles, Grid, Typography } from '@material-ui/core'
import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
import { languageData } from '../../../../Globals/GlobalComponents/languages';
import CustomHelpIcon from '../../../../Globals/GlobalComponents/CustomHelpIcon';
import SelectBox from '../../../SelectBox'
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';


const BasicBox = (props) =>{
	
	const {
        setAnnouncementSettings, 
        handleLanguageChange, 
        handlePropertyChange,
        langName,
        notificationSize,
        handleNotifType,
        notificationDisplayApp
    } = props


	const classes = useStyles()
	return(
		<Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container spacing={2} direction="column">
                <Grid item md={12}>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid item md={3}>
                            <Typography variant="body2">
                                Language
                                {/* <a href={helpArticleLinks.faq.notification.language} target="_blank">
                                    <img src = {HelpQuestion}  style={{ height: "11px", marginLeft:"2%"}}/>
                                </a> */}
                                <InfluenceHelpIcon href={helpArticleLinks.faq.notification.language}/>
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <ReactSelect 
                                name="langName"
                                options={languageData}
                                value={langName.language}
                                dispatcher={setAnnouncementSettings}
                                onChange={handleLanguageChange}
                            
                            />
                        </Grid>
                    </Grid>
                </Grid>
               
                </Grid>
        </Box>
	)
}


const useStyles = makeStyles(theme =>
	createStyles({

	})
)

export default memo(BasicBox);
