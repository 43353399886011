import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Box, Typography, Paper, Grid, Divider,Button } from '@material-ui/core';
import StarRateIcon from '@material-ui/icons/StarRate';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { Fragment } from 'react';
import {Facebookpop, fbRecommendation,Bullhorn, Check} from '../../../../img'
//import Cta from '../../Cta';

const AnnouncementNotificationCard = (props) => {
    const classes = useStyles();
    const { announcementsSettings,
        announcementHeaderText,
        announcementSubText,
        poweredBy} = props;

   

    return(
        <Box 
            component={Paper}
            //width= '370px'
           // height= '110px'
            height= '95px !important'
            padding= '4px 5px'
            paddingBottom= '3px'
            border='1px solid rgba(216, 217, 226, 0.5)'
            // boxShadow='10px 20px 40px 0 rgba(36, 35, 40, 0.1)'
            // borderRadius={`${announcementsSettings.panelStyle.radius}px`}
            // bgcolor={`rgb(${announcementsSettings.panelStyle.backgroundColor.r}, ${announcementsSettings.panelStyle.backgroundColor.g}, ${announcementsSettings.panelStyle.backgroundColor.b})`}
            display='flex'
            alignItems='center'
            style={{
                flexDirection: 'column', 
                justifyContent: 'center',
                borderRadius:`${announcementsSettings.panelStyle.radius}px`,
                backgroundColor:`rgb(${announcementsSettings.panelStyle.backgroundColor.r}, ${announcementsSettings.panelStyle.backgroundColor.g}, ${announcementsSettings.panelStyle.backgroundColor.b})`,
                boxShadow:'10px 20px 40px 0 rgba(36, 35, 40, 0.1)'

            }}
        >

            <Box className={classes.upperPart}>
                <Box 
                    className={classes.imageContainer}
                    style={{
                        borderRadius: `${announcementsSettings.panelStyle.radius}px`,
                        width: '61px', 
                        height: '61px',
                    }}
                >
                
                <img
                src = {
                    announcementsSettings.panelStyle.image ?
                  announcementsSettings.panelStyle.image :
                    Bullhorn
                }
                style={{
                        width: '61px', 
                        height: '61px',
                        borderRadius: `${announcementsSettings.panelStyle.radius}px`,
                        transition: 'all .1s ease-in-out',
                        alignSelf: 'center',
                        padding:'10%'
                    }}
                // className={classes.imageStyle}
            />
                </Box>
                <Box className={classes.textContainer}>
                    <Box className={classes.userNameContainer}>
                        <p 
                             className={classes.headingText}
                            style={{
                                fontFamily: announcementsSettings.panelStyle.fontFamily,
                                fontWeight: '600',
                                color: `rgb(${announcementsSettings.panelStyle.color.r}, ${announcementsSettings.panelStyle.color.g}, ${announcementsSettings.panelStyle.color.b})`,
                                //color: '#2c66c3',
                                fontSize: '14px',
                                whiteSpace: 'mormal',
                                // backgroundColor: '#d6e4ff',
                                // letterSpacing: '0.3px',
                                padding: '3px 5px',
                                textAlign: 'left',
                                margin: '0',
                                textTransform: 'capitalize',
                                lineHeight: "normal",
                                height: "auto",
                                wordBreak: "break-all",
                                overflow: "hidden",
                                maxWidth:"280px",
                                marginTop: "-35px",
                                display: '-webkit-box',
                                webkitLineClamp: '2',
                                webkitBoxOrient: 'vertical'

                            }}
                        >
                      
                    {announcementHeaderText ? announcementHeaderText : 'Update Available!'}
                        </p>
                    </Box>

                    <p 

                    className = {classes.subText}
                        style={{
                            margin: '0',
                            fontFamily: announcementsSettings.panelStyle.fontFamily,
                            //fontFamily: "sans-serifs",
                            fontWeight: announcementsSettings.panelStyle.fontWeight,
                            // whiteSpace: 'nowrap',
                            // overflowX: 'hidden', 
                            // textOverflow: 'ellipsis',
                            color: announcementsSettings.panelStyle.secondaryColor ? `rgb(${announcementsSettings.panelStyle.secondaryColor.r}, ${announcementsSettings.panelStyle.secondaryColor.g}, ${announcementsSettings.panelStyle.secondaryColor.b})` : '#666',
                            //color: '#666',
                            // marginLeft: '2%',
                            letterSpacing: '0.3px',
                            fontSize: '11.5px',
                            padding: '0 5px',
                            // apply webkit here
                            display: '-webkit-box',
                            webkitLineClamp: '3',
                            webkitBoxOrient: 'vertical',
                            lineHeight: '1.2',
                            overflow: 'hidden',
                            alignSelf: 'center',
                            wordBreak: "break-all",
                            marginBottom: '-11%'
                          
                        }}
                        // className={classes.secondaryText}
                    >
                     {announcementSubText ? announcementSubText : 'Know more about the latest updates'}
                  
                    </p>

                   
                   
                      
                   
                </Box>
            </Box>
            
            {/*
            <Box className={classes.lowerPart}>
               
               
               
                    <div className={classes.footerTextRight}>
                    {announcementsSettings.togglePoweredBy &&
                        <div style={{marginLeft:"66px",marginBottom:"30%"}}>
                       
                    <em className={classes.verifiedIcon}>
                        <img src={Check}/>
                    </em>
                <em className={classes.influenceText}>{poweredBy}</em>  
                </div>   
                 }
               
                {announcementsSettings.toggleCTA &&
                    <Cta 
                        ctaBgColor={`rgb(${announcementsSettings.panelStyle.ctaBackgroundColor.r}, ${announcementsSettings.panelStyle.ctaBackgroundColor.g}, ${announcementsSettings.panelStyle.ctaBackgroundColor.b})`}
                        ctaTextColor={`rgb(${announcementsSettings.panelStyle.ctaTextColor.r}, ${announcementsSettings.panelStyle.ctaTextColor.g}, ${announcementsSettings.panelStyle.ctaTextColor.b})`}
                        ctaText={announcementsSettings.ctaButtonText}
                        ctaRadius={announcementsSettings.panelStyle.ctaRadius}
                        ctaUrl={announcementsSettings.ctaHyperlinkUrl}
                        ctaPosition={announcementsSettings.ctaPosition}
                    />
                }
                </div>
            </Box>
            */}
                
           

        
                    
        </Box>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        upperPart:{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'left',
            height: '70px'
        },
        imageContainer:{
            width: '61px',
            height: '61px',
            textAlign: 'center',
            marginRight: '10px',
            alignSelf: 'center',
        },
        textContainer:{
            width: '280px',
            // height: '40px',
            alignSelf: 'center',

        },
        border:{
            width: '100%',
            borderTop: '1px dashed rgba(102,102,102,0.4)',
            margin: '0px 5px',
            marginTop: '5px'
        },
        lowerPart:{
            display:'flex',
            flexDirection:"row",
            justifyContent: 'left',
            textAlign: 'center',
            width: '100%',
            padding: '3px 5px',
            height: '12px',
            marginTop: '1%'
        },
        footerLeft:{
            display: 'flex',
            alignItems: 'center'
        },
        footerLeftText:{
            fontSize: '6px',
            color: '#49445f',
            paddingLeft: '5px',
            fontFamily: 'sans-serif',
            letterSpacing: 'normal',
            lineHeight: '1',
            margin: '0'
        },
        footerLogoContainer:{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '7px'
        },
       
        footerLogo:{
            width: '30px',
            height: '20px'
        },
        footerStartConatainer:{
            fontSize: '7px',
            color: '#ffc136',
            paddingLeft: '4px'
        },
        footerTextRight:{
            paddingRight: '5px',
            lineHeight: '0.8',
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            paddingTop: '3px',
            marginTop: '30px'
        },
        verifiedText:{
            fontSize: '9px',
            fontWeight: '600',
            fontStyle: 'normal',
            textAlign: 'center',
            color: '#666',
            fontFamily: 'sans-serif',
            wordSpacing: '1px'
        },
        verifiedIcon:{
            fontSize: '11px',
            color: '#0093fb',
            padding: '0 5px',
            marginTop: '-2px'
        },
        influenceText:{
            fontStyle: 'normal',
            fontSize: '9.5px',
            fontWeight: '600',
            fontFamily: 'sans-serif',
            color: '#044fff',
            
        }
       
       
    })    
)

export default AnnouncementNotificationCard;





{/*<Box paddingLeft="8px">
                <img
                    src= "https://storage.googleapis.com/influence-197607.appspot.com/googlereview.png"
                    style={{
                        width: '43px', 
                        height: '43px',
                        borderRadius: `${reviewSettings.borderRadius}px`,
                        //backgroundColor: `rgba(${reviewSettings.panelStyle.iconBGColor.r}${reviewSettings.panelStyle.iconBGColor.g},${reviewSettings.panelStyle.iconBGColor.b},${reviewSettings.panelStyle.iconBGColor.a})`
                    }}
                />
            </Box>
        
            <Box width='100%' padding='10px'>
                <Box height='20px'>
                    <span
                        style={{
                            display: 'flex',
                            fontFamily: reviewSettings.panelStyle.fontFamily,
                            fontWeight: reviewSettings.panelStyle.fontWeight,
                            color: 'gold'
                        }}
                    >
                        <StarRateIcon />
                        <StarRateIcon />
                        <StarRateIcon />
                        <StarRateIcon />
                        <StarRateIcon />
                    </span>
                </Box>
            
                <Box height='20px' display='flex' alignItems='center' paddingTop='4px'>
                    <span
                        style={{
                            fontFamily: reviewSettings.panelStyle.fontFamily,
                            fontWeight: '700',
                            color: `rgb(${reviewSettings.panelStyle.color.r}, ${reviewSettings.panelStyle.color.g}, ${reviewSettings.panelStyle.color.b})`,
                            fontSize: '13px',
                            whiteSpace: 'nowrap',
                            backgroundColor: '#d6e4ff',
                            letterSpacing: '0.3px',
                            padding: '1px 5px'

                        }}
                    >
                        {`John Doe`}
                    </span>
                    <span
                        style={{
                            fontFamily: reviewSettings.panelStyle.fontFamily,
                            fontWeight: reviewSettings.panelStyle.fontWeight,
                            whiteSpace: 'nowrap',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            color: `rgb(${reviewSettings.panelStyle.color.r}, ${reviewSettings.panelStyle.color.g}, ${reviewSettings.panelStyle.color.b})`,
                            marginLeft: '2%',
                            letterSpacing: '0.3px',
                            fontSize: '12px'
                        }}
                    >
                        {gglReviewText}
                    </span>
                </Box>
            
                <Box height='20px'>
                    {reviewSettings.togglePoweredBy &&
                        <Fragment>
                            <span
                                style={{
                                    fontFamily: reviewSettings.panelStyle.fontFamily,
                                    fontWeight: reviewSettings.panelStyle.fontWeight,
                                    color: `rgb(${reviewSettings.panelStyle.color.r}, ${reviewSettings.panelStyle.color.g}, ${reviewSettings.panelStyle.color.b})`,
                                    fontSize: '10px',
                                    marginLeft: '2%'
                                }}
                            >
                                <CheckCircleRoundedIcon style={{width:'14px', height:"14px",color:"#5d93fe"}} />
                            </span>
                            <span
                                style={{
                                    fontFamily: reviewSettings.panelStyle.fontFamily,
                                    fontWeight: reviewSettings.panelStyle.fontWeight,
                                    //color: `rgb(${reviewSettings.panelStyle.color.r}, ${reviewSettings.panelStyle.color.g}, ${reviewSettings.panelStyle.color.b})`,
                                    fontSize: '10px',
                                    color: '#738c98',
                                    letterSpacing: '0.3px',
                                    marginLeft: '2%'
                                }}
                            >
                                {`verified by`}
                            </span>
                            
                            <span
                                style={{
                                    fontFamily: reviewSettings.panelStyle.fontFamily,
                                    fontWeight: reviewSettings.panelStyle.fontWeight,
                                    color: "#007bff",
                                    fontSize: '10px',
                                    letterSpacing: '0.3px',
                                    marginLeft: '2%'
                                }}
                            >
                                {reviewSettings.poweredBy}
                            </span>
                        </Fragment>
                    }
                </Box>
            </Box>
        */}