import {fromJS} from 'immutable'
import {
    CREATE_COOKIE_CAMPAIGN, 
    GET_COOKIE_CAMPAIGNS, 
    DELETE_COOKIE_CAMPAIGN, 
    UPDATE_COOKIE_CAMPAIGN,
    SAVE_OPENED_COOKIE_CAMPAIGN,
    GET_COOKIE_PROVIDERS,
    SAVE_COOKIE_PROVIDERS,
    ADD_NEW_MICROPOLICY,
    GET_MICROPOLICIES,
    GET_MICROPOLICY,
    PUT_MICROPOLICY,
    DELETE_MICROPOLICY,
    UPDATE_MICROPOLICY_DATAUSAGE,
    GET_PROVIDER,
    POST_PROVIDER,
    GET_COOKIE_CONFIGURATION,
    PUT_COOKIE_CONFIGURATION,
    GET_THIRDPARTYLIST,
    POST_THIRDPARTY, 
    FIRE_TOASTER,
    COOKIE_CAMPAIGN_PUBLISHED
} from './actions'



const initialState = fromJS({})



export const fireToaster = (state=initialState, action) =>{
    switch (action.type){
        case FIRE_TOASTER:
            return state.set('fireToaster', action.payload)
        default:
            return state
    }
}

/**
 * Reducer function of data related to cookie campaigns
 * @param {Object} state initial state
 * @param {Object} action object
 */
export const cookieCampaigns = (state=initialState, action) =>{
    switch (action.type) {
        case GET_COOKIE_CAMPAIGNS:
            return state.set('cookieCampaigns', action.payload)
        case CREATE_COOKIE_CAMPAIGN:
            return state.set('cookieCampaigns', action.payload)    
        case UPDATE_COOKIE_CAMPAIGN:
            //dublicate copy of reducer data.
            const data = state.get('cookieCampaigns').slice();
            //update the specific status of object in reducer.
            // const updatedData = data.splice(state.get('campaignInfo').findIndex(state => state._id === action.payload._id),1,action.payload); 
            const index = data.findIndex(item=> item._id === action.payload._id)
            data[index].isActive = action.payload.isActive
            //update the change in reducer
            return state.set('cookieCampaigns', data);    
        case DELETE_COOKIE_CAMPAIGN:
            return state.set('cookieCampaigns', action.payload)    
        case SAVE_OPENED_COOKIE_CAMPAIGN:
            return state.set('openedCookieCampaign', action.payload)    
        case COOKIE_CAMPAIGN_PUBLISHED:
            return state.set('cookieCampaignPixel', action.payload)    
        default:
            return state
    }
}




/**
 * Reducer of data gathered during cookie campaign creation process
 * @param {object} state initial state
 * @param {Object} action action
 */
export const cookieCampaignCreationProcess = (state=initialState, action) =>{
    switch (action.type) {
        case GET_COOKIE_PROVIDERS:
            return state.set('rawCookieProviders', action.payload)            
        case SAVE_COOKIE_PROVIDERS:
            return state.set('categorizedCookieProviders', action.payload)            
        default:
            return state
    }
}



export const newMicropolicy = (state=initialState, action)=>{
    switch (action.type) {
        case ADD_NEW_MICROPOLICY:
            return state.set('newMiropolicy', action.payload)
        default:
            return state
    }
}

export const getMicropolicies = (state=initialState, action)=>{
    switch (action.type) {
        case GET_MICROPOLICIES:
            return state.set('getMicropolicies', action.payload)
        default:
            return state
    }
}

export const micropolicyOps = (state=initialState, action)=>{
    switch (action.type) {
        case GET_MICROPOLICY:
            return state.set('getMicropolicy', action.payload)
        case PUT_MICROPOLICY:
            return state.set('putMicropolicy', action.payload)
        case DELETE_MICROPOLICY:
            return state.set('deleteMicropolicy', action.payload)
        case UPDATE_MICROPOLICY_DATAUSAGE:
            return state.set('getMicropolicy', action.payload)
        default:
            return state
    }
}

export const providerOps = (state = initialState, action) => {
    switch(action.type){
        case GET_PROVIDER : 
            return state.set('getProvider', action.payload)
        case POST_PROVIDER : 
            return state.set('postProvider', action.payload)
        default:
            return state

    }
}

//cookie configuration


export const cookieConfiguration = (state = initialState, action) => {
    switch(action.type){
        case GET_COOKIE_CONFIGURATION:
            return state.set('getCookieConfiguration', action.payload);
        case PUT_COOKIE_CONFIGURATION:
            return state.set('putCookieConfiguration', action.payload);
        default:
            return state;
    } 
}


export const thirdPartyOps = (state = initialState, action) => {
    switch(action.type){
        case GET_THIRDPARTYLIST:
            return state.set('getThirdPartyList', action.payload);
        case POST_THIRDPARTY:
            return state.set('postThirdParty', action.payload);
        default:
            return state;
    } 
}

