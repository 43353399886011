/**
 * Categories of cookie provider catogorizatoin
 */

const categories = [
    {value:'uncategorised', label:'Uncategorised'},
    {value:'essential', label:'Essential'},
    {value:'marketing', label:'Marketing'},
    {value:'statistics', label:'Statistics'},
    {value:'liveChat', label:'Live Chat'},
    {value:'payments', label:'Payments'},
    {value:'location', label:'Location'},
    {value:'sharing', label:'Sharing'},
    {value:'media', label:'Media'},
    {value:'other', label:'Other'},
]


export default categories