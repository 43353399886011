import React, { memo, useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
import CustomHelpIcon from '../../../../Globals/GlobalComponents/CustomHelpIcon';
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';


const BasicBox  = (props) => {

    const { 
        languageData,
        langName,
        // notificationSize,
        // bulkVersion,
        setCustomSettings,
        handleLanguageChange,
        // handlePropertyChange
    
    } = props;


    return(
        <Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container direction="column" spacing={3}>
                <Grid item md={12}>
                    <Grid container alignItems='center'>
                        <Grid item md={3}>
                            <Typography variant="body2">
                                Language
                                {/* <a href={helpArticleLinks.faq.notification.language} target="_blank">
                                    <img src = {HelpQuestion}  style={{ height: "11px",marginLeft:"2%"}}/>
                                </a> */}
                                <InfluenceHelpIcon href={helpArticleLinks.faq.notification.language} fontSize="tiny"/>
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <ReactSelect
                                width='120px' 
                                height='25px' 
                                name="langName"
                                options={languageData}
                                value={langName.language}
                                dispatcher={setCustomSettings}
                                onChange={handleLanguageChange}
                            />
                            
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default memo(BasicBox);