import React from 'react';
import { call, put, fork, takeLatest } from 'redux-saga/effects';
import * as api from 'services/api';
import * as actions from 'ducks/payment';
import { createProfile, updateProfile, successProfile } from 'ducks/profile';
import { fetchUserSuccess } from 'ducks/auth';
import { load, loaded } from 'ducks/loading';
import { toast } from 'react-toastify';
import { browserHistory } from 'react-router';
import Popup from 'react-popup';
import { base } from 'services/api';
import * as Sentry from '@sentry/browser';

const toastConfig = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 2000,
  className: 'toast-style'
};

const errorMessge = 'Please try again or refresh!';

function* fetch() {
  try {
    yield put(load());
    const res = yield call(api.GET, 'payment/user');
    if(res.error)
      console.log(res.error);
    else
      yield put(actions.successPayment(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* fetchInvoices() {
  try {
    // yield put(load());
    // const res = yield call(api.GET, 'payment/servicebot/invoice');
    // if(res.error)
    //   console.log(res.error);
    // else
    //   yield put(actions.successInvoice(res));
    // yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* create(action) {
  try {
    yield put(load());
    Popup.create({
      title: 'Processing order',
      content: 'Please wait while we are processing your request...',
      buttons: {}
    }, true);
    window.scrollTo(0,0);
    const res = yield call(api.POST, 'payment', action.payment);
    yield put(actions.paymentStatus(false));
    Popup.close();
    if(res.error) {
      Popup.create({
        title: 'Payment failed',
        content: <div style={{padding: '30px 15px', fontSize: 'medium'}}>
          {res.message?res.message.error_msg?`Payment failed due to ${res.message.error_msg}.`:res.message:'Card Declined'}.
        </div>,
        buttons: {}
      }, true);
    } else {
      yield put(actions.successPayment([res]));
      if(action.update) {
        action.profile['uniqueVisitorQouta']=res.uniqueVisitorQouta;
        action.profile['uniqueVisitorsQoutaLeft']=res.uniqueVisitorsQoutaLeft;
        //what is use of this function, ask shanky
        yield put(updateProfile(action.profile));
        yield browserHistory.push(action.profile.route?'dashboard':'billing-details');
      } else {
        action.profile['uniqueVisitorQouta']=res.uniqueVisitorQouta;
        action.profile['uniqueVisitorsQoutaLeft']=res.uniqueVisitorsQoutaLeft;
        yield put(createProfile(action.profile));
      }
      Popup.create({
        title: 'Payment successful',
        content: <div style={{padding: '30px 15px', fontSize: 'medium'}}>
          {action.profile.plan.name} has been successfully activated for your account
        </div>,
        buttons: {}
      }, true);
      yield put(setTimeout(function(){ Popup.close(); }, 3000));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    console.log('Failed to fetch doc', error);
    Popup.create({
      title: 'Payment failed',
      content: <div style={{padding: '30px 15px', fontSize: 'medium'}}>
        Payment failed due to Card Declined
      </div>,
      buttons: {}
    }, true);
  }
}

function* update(action) {
  try {
    yield put(load());
    const res = yield call(api.PUT, `payment/${action.id}`, action.payment);
    if(res.error)
      console.log(res.error);
    else
      yield put(actions.successPayment(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* downloadInvoice(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `payment/invoice/${action.invoice_id}`);
    if(res.error)
      console.log(res.error);
    require('downloadjs')(base + res.path);
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* updatePaymentMethod() {
  try {
    // yield put(load());
    // const res = yield call(api.PUT, 'payment/servicebot/card', action.details);
    // if(res.error)
    //   console.log(res.error);
    // else {
    //   yield toast.error('New Card Added', toastConfig);
    //   yield put(actions.fetchCards());
    // }
    // yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}


function* upgradePlan() {
  try {
    // yield put(load());
    // const res = yield call(api.POST, 'payment/servicebot/update', action.plan);

    // if(res.error)
    //   yield toast.error(res.message.message, toastConfig);
    // else {
    //   yield toast.info(res.message.message, toastConfig);
    //   yield put(successProfile(res.message.profile));
    // }

    // yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* fetchCards() {
  try {
    // yield put(load());
    // const res = yield call(api.GET, 'payment/servicebot/card');
    // if(res.error)
    //   console.log(res.error);
    // else
    //   yield put(actions.fetchCardsSuccess(res));
    // yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* createAgreement(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'paypalpayments/agreement', action.details);
    if(res.error)
      console.log(res.error);
    else {
      let redirectLink = res.links.filter(link => link.method == 'REDIRECT')[0].href;
      let acceptLink = res.links.filter(link => link.method == 'POST')[0].href;
      localStorage.setItem('redirectLink', redirectLink);
      localStorage.setItem('acceptLink', acceptLink);
      window.open(`${redirectLink}?acceptLink=${acceptLink}`,'_self');
    }

    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* createPaypalPayment(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'paypalpayments/payment', action.token);

    if(res.error)
      yield toast.error(res.message.message, toastConfig);
    else {
      yield toast.info('Payment Successful', toastConfig);
      yield put(successProfile(res));
    }

    yield browserHistory.push('billing-details');
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}


function* createPaypalOnetine(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'paypalpayments/onetime', {plan: action.plan, paypal: action.paypal});

    if(res.error)
      yield toast.error(res.message.message, toastConfig);
    else {
      yield toast.info('Payment Successful', toastConfig);      
      yield put(successProfile(res.profile));
      yield put(fetchUserSuccess(res.user));
    }

    yield browserHistory.push(res.user.path);
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* makeFundPayment() {
  try {
    // yield put(load());
    // const res = yield call(api.POST, 'payment/servicebot/funds', {data: action.data});

    // if(res.error)
    //   yield toast.error(res.message.message, toastConfig);
    // else {
    //   Popup.create({
    //     title: 'Payment successful',
    //     content: <div style={{padding: '30px 15px', fontSize: 'medium', lineHeight: '51px'}}>
    //       Your funds has been successfully received.<br></br>All the documentation will be sent on your registered email.
    //       <br></br>For any query, contact us at <strong>accounts@useinfluence.co</strong>
    //     </div>,
    //     buttons: {
    //       left: [{
    //         text: 'Close',
    //         key: '⌘+s',
    //         className: 'btn btn-primary',
    //         action: () => {
    //           Popup.close();
    //           browserHistory.push('/funding');
    //         }
    //       }]
    //     }
    //   }, true);
    // }

    // yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* checkCoupenAvailability(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `payment/couponById/${action.data.coupon_code}`);
    if (res.error)
      yield toast.warning('Enter valid coupon code.', toastConfig);
    else {
      if (res.message && res.message.coupon) {
        const couponObj = res.message.coupon;
        let isList = couponObj.plan_ids ? couponObj.plan_ids.filter(x => x == action.data.plan_id).length : 0;
        if (isList == 0 && couponObj.plan_constraint !== 'all')
          yield toast.warning('This coupon is not available for selected plan.', toastConfig);
        else if (couponObj.discount_type == 'fixed_amount')
          yield toast.warning(`Successfully apply coupon code. You will get $${couponObj.discount_amount / 100} off.`, toastConfig);
        else if (couponObj.discount_type == 'percentage')
          yield toast.warning(`Successfully apply coupon code. You will get ${couponObj.discount_percentage}% off.`, toastConfig);
      }
      yield put(actions.successPayment(res));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* upgradeCoupenPlan(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'appsumocoupen/planUpgrade', action.plan);
    if(res.error)
      yield toast.error(res.status, toastConfig);
    else if(res.status=='fail'){
      yield toast.info('code invalid: '+ res.message, toastConfig);
    }
    else if(res.status=='duplicate'){
      yield toast.info('code is already used: '+ res.message, toastConfig);
    }
    else{
      const plan_id = res.plan_name;
      const plan = plan_id == 'appsumo_starter' ? 'AppSumo Lifetime Starter'
        : plan_id == 'appsumo_advanced' ? 'AppSumo Advanced'
          : plan_id == 'appsumo_agency' ? 'AppSumo Lifetime Agency'
            : plan_id == 'appsumo_enterprise' ? 'AppSumo Lifetime Enterprise'
              : '';
      Popup.create({
        title: 'Payment successful',
        content: <div style={{padding: '30px 15px', fontSize: 'medium'}}>
          {plan} plan has been successfully activated for your account
        </div>,
        buttons: {}
      }, true);
      yield put(setTimeout(function(){ 
        Popup.close(); 
        window.location.reload(true);
      }, 3000));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* shopifyRecurringPayment(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, `shopify/recurringPayment?trackingId=${action.trackingId}&userId=${action.userId}`, action.plan);
    if (res.error)
      console.log(res.error);
    else {
      window.open(res.message, '_self');
    }

    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

export function* watchFetch() {
  yield takeLatest(actions.FETCH, fetch);
}

export function* watchFetchInvoices() {
  yield takeLatest(actions.FETCH_INVOICES, fetchInvoices);
}

export function* watchCreate() {
  yield takeLatest(actions.CREATE, create);
}

export function* watchUpdate() {
  yield takeLatest(actions.UPDATE, update);
}

export function* watchDownloadInvoice() {
  yield takeLatest(actions.DOWNLOAD_INVOICE, downloadInvoice);
}

export function* watchUpdatePaymentMethod() {
  yield takeLatest(actions.UPDATE_PAYMENT_METHOD, updatePaymentMethod);
}

export function* watchUpgradePlan() {
  yield takeLatest(actions.UPGRADE_PLAN, upgradePlan);
}

export function* watchFetchCards() {
  yield takeLatest(actions.FETCH_CARDS, fetchCards);
}

export function* watchCreateAgreement() {
  yield takeLatest(actions.CREATE_AGREEMENT, createAgreement);
}

export function* watchCreatePaypalPayment() {
  yield takeLatest(actions.CREATE_PAYPAL_PAYMENT, createPaypalPayment);
}

export function* watchCreatePaypalOnetine() {
  yield takeLatest(actions.CREATE_PAYPAL_ONETIME, createPaypalOnetine);
}

export function* watchMakeFundPayment() {
  yield takeLatest(actions.CREATE_FUND_PAYMENT, makeFundPayment);
}

export function* watchCheckCoupenAvailability() {
  yield takeLatest(actions.CHECK_COUPEN_PAYMENT, checkCoupenAvailability);
}

export function* watchUpgradeCoupenPlan() {
  yield takeLatest(actions.UPGRADE_COUPEN_PLAN, upgradeCoupenPlan);
}

export function* watchShopifyRecurringPayment() {
  yield takeLatest(actions.SHOPIFY_RECURRERING_DATA, shopifyRecurringPayment);
}

export default function* rootSaga() {
  yield [
    fork(watchFetch),
    fork(watchCreate),
    fork(watchUpdate),
    fork(watchFetchInvoices),
    fork(watchUpdatePaymentMethod),
    fork(watchUpgradePlan),
    fork(watchDownloadInvoice),
    fork(watchFetchCards),
    fork(watchCreateAgreement),
    fork(watchCreatePaypalPayment),
    fork(watchCreatePaypalOnetine),
    fork(watchMakeFundPayment),
    fork(watchCheckCoupenAvailability),
    fork(watchUpgradeCoupenPlan),
    fork(watchShopifyRecurringPayment)
  ];
}
