import React, { useState, useEffect } from 'react'
import { Box, Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, CircularProgress, makeStyles, createStyles } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux';
import { browserHistory } from 'react-router'
import {addCookieCampaign, saveOpenedCookieCamapign} from '../actions'
import TextInput from '../../../Globals/GlobalComponents/TextInput';
import PropTypes from 'prop-types';


const AddCampaign=(props)=> {

    const {dialogCloseHandler, isDialogOpen} = props
    
    const dispatch = useDispatch()
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [campaignName, setCampaignName] = useState("")
    const [websiteUrl, setWebsiteUrl] = useState("")
    const profile = useSelector(state => (state.getIn(['profile', 'profile'])))
    const cookieCampaigns = useSelector(state => (state.getIn(['cookieCampaigns','cookieCampaigns'])))


    const campaignNameHandler = (e) =>{
        setCampaignName(e.target.value)
    }

    const websiteUrlHandler = (e)=>{
        setWebsiteUrl(e.target.value)
    }



    /**
     * This will run after the axios call gets completed and handle the response accordingly.
     * @param {Boolean} error Whether the funtion runs for error or not. True if this function is called for handling error.
     * @param {Object} response Response data of the api call
     */
    const afterAction = (error, response)=>{
        setLoading(false)
        setCampaignName("")
        setWebsiteUrl("")
        dialogCloseHandler()
        if(error === true){
            return
        } else if (error === false){
            browserHistory.push(`/cookie-monster/new-campaign/${response._id}`)
            dispatch(saveOpenedCookieCamapign(response))
            
        }
    }


    const addNewCampaignHandler = (e) =>{
        e.preventDefault()
        e.stopPropagation()
        const arr = cookieCampaigns.map(item=>{
            return item.websiteUrl
        })
        const userUrl = new URL(websiteUrl)
        if(arr.includes(userUrl.host)){
            dispatch({type:'FIRE_TOASTER', payload:{type:'error', message:'One campaign allowed per website'}})
        }else{
            setLoading(true)
            dispatch(addCookieCampaign(campaignName, websiteUrl, profile._id, cookieCampaigns, afterAction))
        }
    }

    return (
        <Dialog maxWidth="md" classes={{paper: classes.dialogPaper}}  open={isDialogOpen} onClose={dialogCloseHandler}>
            <Box style={{padding:'5% 10%'}}>
                <DialogTitle>
                    <Typography variant="h2">
                        Create your campaign
                    </Typography>
                    <Typography style={{ marginTop:'30px',background:'rgb(235, 248, 255)', textAlign:'right', padding:'10px', color:'#097fff', fontSize:'12px', display:'block', borderTop:'1px solid rgba(0, 0, 0, 0.12)' }} component="a"> 
                        Read the document to get started
                    </Typography>
                </DialogTitle>
                <form onSubmit={addNewCampaignHandler}>
                    <DialogContent style={{display:'flex', alignItems:'center', margin:'20px 0'}}>
                        <Box style={{marginRight:'1rem', flexGrow:'1'}}>
                            <Typography>
                                Campaign Name
                            </Typography>
                            <TextInput
                                placeholder="Name"
                                value={campaignName}
                                onChange={(e)=>campaignNameHandler(e)}
                                required
                            />
                        </Box>
                        <Box style={{flexGrow:'1'}}>
                            <Typography>
                                Campaign URL
                            </Typography>
                            <TextInput
                                placeholder="https://mywebsite.co"
                                value={websiteUrl}
                                onChange={(e)=>websiteUrlHandler(e)}
                                required
                                type="url"
                            />
                        </Box>
                    </DialogContent>

                    <DialogActions style={{display:'flex', alignItems:'center',justifyContent:'flex-end'}}>
                        <Button variant="outlined" onClick={dialogCloseHandler} style={{width:'100px'}}>
                            Cancel
                        </Button>
                        <Button variant="contained" disabled={loading || websiteUrl.length == 0 || campaignName.length == 0} type="submit" style={{position:'relative', width:'100px'}}>
                            {
                                loading?
                                    <CircularProgress size={18} style={{color:'#fff'}}/>:
                                    'Create'
                            }
                        </Button>
                    </DialogActions>
                </form>
            </Box>
        </Dialog>
    )
}


const useStyles = makeStyles(()=>
    createStyles({
        dialogPaper: {
            width: '850px'
        },
    })
)

AddCampaign.propTypes={
    isDialogOpen: PropTypes.bool.isRequired,
    dialogCloseHandler: PropTypes.func.isRequired
}


export default AddCampaign
 