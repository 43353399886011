import { fromJS } from 'immutable';

const action = name => `/lemlist/${name}`;

export const FETCH = action('FETCH');
export const FETCH_SUCCESS = action('FETCH_SUCCESS');
export const CREATE = action('CREATE');
export const CREATE_SUCCESS = action('CREATE_SUCCESS');
export const REMOVE = action('REMOVE');
export const POP_LEMLIST = action('POP_LEMLIST');

export const getLemlist = (campaignId) => ({ type: FETCH, campaignId });
export const addLemlist = (lemlist) => ({ type: CREATE, lemlist });
export const deleteLemlist = (id, index) => ({ type: REMOVE, id, index });
export const succeessLemlist = (data) => ({ type: FETCH_SUCCESS, data });
export const popLemlist = (index) => ({ type: POP_LEMLIST, index });

const initialState = fromJS({});
const lemlist = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return state.set('lemlist', action.data);  
    case POP_LEMLIST:
      return state.set('lemlist', state.get('lemlist').slice(0, action.index).concat(state.get('lemlist').slice(action.index+1)));
    default:
      return state;
  }
};

export default lemlist;
