import React from 'react'
import { Box, makeStyles, createStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const SettingsPageSeketon  = () => {
  const classes = useStyles()
  return(
    <Box>
      <Box className={classes.fieldsWrapper}>

        <Box>
          <Box>
            <Skeleton className={'text'} variant="text"/>
            <Skeleton height={40}/>
          </Box>
          <Box>
            <Skeleton className={'text'} variant="text"/>
            <Skeleton height={40}/>
          </Box>
          <Box>
            <Skeleton className={'text'} variant="text"/>
            <Skeleton height={40}/>
          </Box>
        </Box>

        <Box>
          <Box>
            <Skeleton className={'text'} variant="text"/>
            <Skeleton height={40}/>
          </Box>
          <Box>
            <Skeleton className={'text'} variant="text"/>
            <Skeleton height={40}/>
          </Box>
          <Box>
            <Skeleton className={'text'} variant="text"/>
            <Skeleton height={40}/>
          </Box>
        </Box>

      </Box>
        <Skeleton style={{marginTop:'1rem'}} height={50} width={300}/>
        <Skeleton width={300} height={50}/>

        <Skeleton style={{marginTop:'40px'}} height={60} width={120}/>
    </Box>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    fieldsWrapper:{
      display:'flex',
      alignItems:'center',
      gap:'75px',
      '&> div':{
        flexGrow:'1',
        flexBasis:'0',
      },
      '&> div > div':{
        display:'flex',
        alignItems:'center',
        margin:'16px 0',
        '&> span:nth-child(1)':{
          width:'80px',
          marginRight:'26px'
        },
        '& > span:nth-child(2)':{
          width:'100%',
          height:'46px'
        }
      }
    }
  })
)

export default SettingsPageSeketon