import React, { useEffect, useState } from 'react'
import { Box, makeStyles, createStyles, Drawer, Typography, Button } from '@material-ui/core'
import { Link } from 'react-router'

const SideDrawer = () => {
  const classes = useStyles()
  const [openDrawer, setOpenDrawer] = useState(true)
  const [activeNav, setActiveNav] = useState(0)

  const addActiveClass = (testString) => {
    // if (window.location.href.includes(testString)) return 'active'
    for (const cls in testString) {
      if (window.location.href.includes(testString[cls])) return 'active'
      else return ''
    }
    for (let i = 0; i <= testString.lenght; i++) {
      if (window.location.href.includes(testString[cls])) {
        return 'active'
      }
    }
  }

  const activeNavHandler = () => {
    const currLocation = window.location.href
    if (
      currLocation.includes('/policies') ||
      currLocation.includes('/analytics') ||
      currLocation.includes('policy-generator/edit')
    )
      setActiveNav(0)
    else if (currLocation.includes('/pixels')) setActiveNav(1)
    else setActiveNav(10)
  }

  useEffect(() => activeNavHandler())

  useEffect(() => {
    setOpenDrawer(true)
    return () => {
      setOpenDrawer(false)
    }
  }, [])

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={openDrawer}
      classes={{ paper: classes.drawerPaper }}
    >
      <Box className={classes.sideBarNavs}>
        <Button
          component={Link}
          to="/policy-generator/new/step-1"
          variant="outlined"
          className="newButton"
        >
          Add New
        </Button>
        <Typography
          component={Link}
          className={activeNav === 0 ? 'active' : ''}
          to="/policy-generator/policies"
        >
          Policies
        </Typography>
        <Typography
          component={Link}
          className={activeNav === 1 ? 'active' : ''}
          to="/policy-generator/pixels"
        >
          Pixels
        </Typography>
      </Box>
    </Drawer>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    drawerPaper: {
      width: theme.custom.sideDrawer.width,
      marginLeft: theme.custom.sideBar.width,
      marginTop: theme.custom.header.height,
      zIndex: '99',
      backgroundColor: '#f5f7f9',
      // padding:'10px'
    },
    sideBarNavs: {
      marginTop: '18px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > a': {
        fontSize: '14px',
        fontWeight: '400',
        color: '#222',
        padding: '8px 10px',
        width: '100%',
        borderRadius: theme.shape.borderRadius,
        paddingLeft: '20px',
        '&:hover': {
          color: '#077fff',
        },
      },
      '& > a.active': {
        color: '#077fff',
        fontWeight: 500,
        // backgroundColor:'rgba(7,127,255,.16)'
      },
      '& > a.newButton': {
        marginLeft: 20,
        width: 'auto',
        color: '#333',
        padding: '6px 16px',
        fontSize: 12,
        '&:hover': {
          // color: '#fff',
          opacity:  '0.8',
          color:'#333'
        },
      },
    },
  })
)

export default SideDrawer
