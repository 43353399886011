import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, createStyles, makeStyles, Typography } from '@material-ui/core'
import { TabPanel } from './PixelNavigation'
import CopyPixelCode from './CopyPixeCode'
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CopyBox from './CopyBox'
import { useSelector } from 'react-redux';



const PixelTabPanels = ({value, pixelStatusCheckHandler}) => {
    const userPixelStatusObject = useSelector(state => (state.getIn(['userPixelStatus', 'userPixelStatus'])))
    const userdetails = useSelector(state => (state.getIn(['auth', 'user'])))
    const [loading, setLoading] = useState(false)
    const [userPixelStatus, setUserPixelStatus] = useState(false)

    const handleRecheckPixel = ()=>{
        setLoading(true)
        pixelStatusCheckHandler(pixelCheckAfterAction)
    }

    const pixelCheckAfterAction = (err, response) =>{
        setLoading(false)
    }


    useEffect(()=>{
        // userPixelStatusObject.constructor === Object && Object.keys(userPixelStatusObject).length !== 0
        userPixelStatusObject !== undefined 
            && setUserPixelStatus(userPixelStatusObject.status)
        setLoading(false)
    },[userPixelStatusObject])


    return (
        <React.Fragment>
            <JavascriptPixelTab index={0} value={value} userPixelStatus={userPixelStatus} handleRecheckPixel={handleRecheckPixel} loading={loading}/>
            <WordpressPixelTab userdetails={userdetails} index={1} value={value} userPixelStatus={userPixelStatus} handleRecheckPixel={handleRecheckPixel} loading={loading}/>
            <WoocommercePixelTab userdetails={userdetails} index={2} value={value} userPixelStatus={userPixelStatus} handleRecheckPixel={handleRecheckPixel} loading={loading}/>
            <MagentoPixelTab userdetails={userdetails} index={3} value={value} userPixelStatus={userPixelStatus} handleRecheckPixel={handleRecheckPixel} loading={loading}/>
            <GTMPixelTab userdetails={userdetails} index={4} value={value} userPixelStatus={userPixelStatus} handleRecheckPixel={handleRecheckPixel} loading={loading}/>
            <ShopifyPixelTab index={5} value={value} userPixelStatus={userPixelStatus} handleRecheckPixel={handleRecheckPixel} loading={loading}/>
            <ClickFunnelTab index={6} value={value} userPixelStatus={userPixelStatus} handleRecheckPixel={handleRecheckPixel} loading={loading}/>
            <OtherHelpTabs index={7} value={value} userPixelStatus={userPixelStatus} handleRecheckPixel={handleRecheckPixel} loading={loading}/>
        </React.Fragment>
    )
}


const useStyles = makeStyles(()=>
    createStyles({
        subHeading:{
            color:'#4E5963',
            fontSize:'14px',
            fontWeight:'bold'
        },
        containerBox:{
            marginTop:'2rem',            
        },
        stepsListStyle:{
            margin:'5px 0',
        },
        stepsBox:{
            marginTop:'2rem',
            paddingLeft:'1rem',
            // borderLeft:'5px solid #ebebeb',
            "& p":{
                fontFamily:'Poppins',
            }
        },
        stepHeading:{
            fontFamily:'Poppins',
            color:'#343434',
            fontSize:'20px',
            fontWeight: '600'
        },
        stepSubHeading:{
            // fontFamily:'Poppins',
            color:'#707070',
            fontSize:'12px',
            letterSpacing: '-0.25px',
            margin: '0.5% 0'
        },
        sendtoDeveloperBtnStyle:{
            marginTop:'1rem'
        }
    })
)

export default PixelTabPanels


const usePixelVerifyBoxStyle = makeStyles((theme)=>
    createStyles({
        pixelVerifiedContainer:{
            padding:'9px 16px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            backgroundColor: props=> props.pixelStatus?'#81d8a2':'#e74040cc',
            height:'35px',
            borderRadius: theme.shape.borderRadius,
            color:'#fff',
            '& p':{
                marginLeft:'5px',
                fontSize:'12px',
            }
        }
    })
)




/**
 * Pixel verified status visual component 
 */
const PixelVerifyingBox = (props) =>{
    const {pixelStatus} = props
    const classes = usePixelVerifyBoxStyle(props)
    return(
        <Box className={classes.pixelVerifiedContainer}>
            {pixelStatus? <CheckCircleIcon style={{fontSize:'1.025rem'}} /> : <CancelIcon fontSize="small" style={{fontSize:'1.025rem'}}/>}
            <Typography>
                {pixelStatus? 'Congrats! Your pixel is verified now!': "Waiting for installation!"}
            </Typography>
        </Box>
    )
}



/**
 * Send to Developer btn. Render as a tag. Pass href tag or email content. 
 */
const SendToDeveloperButton = (props)=>{
    return(
        <Button component={'a'} variant="outlined" {...props}>
            Send to Developer 
        </Button>
    )
}


/**
 * Code pixel installation tab panel
 */
const JavascriptPixelTab = ({value, index, userPixelStatus, handleRecheckPixel, loading}) =>{
    const classes = useStyles()
    return(
        <TabPanel  index={index} value={value}>
            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>
                    Step 1: Install your pixel.
                </Typography>
                <Typography className={classes.stepSubHeading}>
                    This code goes between your website's "head" tag.
                </Typography>
                
                <CopyPixelCode helpLink={"https://support.useinfluence.co/article/697"} containerStyle={classes.containerBox} heading={false}/>
            </Box>


            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 2: Read The Help Article</Typography>
                <Typography className={classes.stepSubHeading}>Follow the installation <a href="https://support.useinfluence.co/article/697" target="_blank"><b style={{color:'#097fff'}} >instructions for using Influence with HTML/JS</b></a> </Typography>
            </Box>


            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 3: Verify The Pixel</Typography>
                <Typography className={classes.stepSubHeading}>Now, visit your website in Incognito mode (to avoid cache issues) and refresh the website 2-3 times to verify the pixel</Typography>
                <Box style={{display:'flex', alignItems:'center', marginTop:'1rem'}}>
                    <PixelVerifyingBox pixelStatus={userPixelStatus}/>
                    <CheckPixelButton loading={loading} onClick={handleRecheckPixel}/>
                </Box>
            </Box>

        </TabPanel>
    )
}


/**
 * Wordpress pixel installation tab panel
 */
const WordpressPixelTab = ({userdetails, value, index, userPixelStatus, handleRecheckPixel, loading}) =>{
    const classes = useStyles()

    return(
        <TabPanel  index={index} value={value}>
            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>
                    Step 1: Install Influence Using Wordpress
                </Typography>
                {/* <Typography className={classes.stepSubHeading} style={{marginTop:'0.5%'}}>
                    <a href="https://wordpress.org/plugins/useinfluence/" target="_blank"><b style={{color:'#097fff'}} >Click here</b></a> to install Influence Wordpress plugin. 
                </Typography>
                <Box style={{width:'60%', marginTop:'1rem'}}>
                    <CopyBox copyContent={userdetails.apiKey} name={"API Key"}/>
                </Box> 
                <Box style={{width:'60%', marginTop:'1rem'}}>
                    <CopyBox copyContent={userdetails.trackingId} name={"Tracking Id"}/>
                </Box>
                <SendToDeveloperButton className={classes.sendtoDeveloperBtnStyle} href={`mailto:?subject=Influence integration with wordpress&body=${worpressMailContent}`}/> */}

                <Typography className={classes.stepSubHeading}>
                    This code goes between your website's "head" tag.
                </Typography>
                
                <CopyPixelCode helpLink={"https://support.useinfluence.co/article/668"} appName={"Wordpress"} containerStyle={classes.containerBox} heading={false}/>
                
            </Box>


            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 2: Read The Help Article</Typography>
                <Typography className={classes.stepSubHeading}>Follow the installation <a href="https://support.useinfluence.co/article/668" target="_blank"><b style={{color:'#097fff'}} >instructions for using Influence with Wordpress.</b></a> </Typography>
            </Box>


            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 3: Verify The Pixel</Typography>
                <Typography className={classes.stepSubHeading}>Now, visit your website in Incognito mode (to avoid cache issues) and refresh the website 2-3 times to verify the pixel </Typography>
                <Box style={{display:'flex', alignItems:'center', marginTop:'1rem'}}>
                    <PixelVerifyingBox pixelStatus={userPixelStatus}/>
                    <CheckPixelButton loading={loading} onClick={handleRecheckPixel}/>
                </Box>
            </Box>

        </TabPanel>
    )
}


/**
 * Woocommerce installtion guide tab 
 */
const WoocommercePixelTab = ({ userdetails, value, index, userPixelStatus, handleRecheckPixel, loading}) =>{
    const classes = useStyles()
    const woocommerceMailContent = `Hi there,%0D%0A %0D%0A %0D%0A Please integrate the Influence to our website. Here is the installation help article:- https://support.useinfluence.co/article/650%0D%0A%0D%0A%0D%0AAPI Key:- ${userdetails.apiKey} %0D%0ATracking ID:- ${userdetails.trackingId}`
    return(
        <TabPanel  index={index} value={value}>
            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>
                    Step 1: Install Influence plugin on your Woocommerce store
                </Typography>
                <Typography className={classes.stepSubHeading} style={{marginTop:'0.5%'}}>
                    <a href="https://wordpress.org/plugins/useinfluence/" target="_blank"><b style={{color:'#097fff'}} >Click here</b></a> to install Influence Wordpress (Woocommerce) plugin. 
                </Typography>

                <Box style={{width:'60%', marginTop:'1rem'}}>
                    <CopyBox copyContent={userdetails.apiKey} name={"API Key"}/>
                </Box>
                <Box style={{width:'60%', marginTop:'1rem'}}>
                    <CopyBox copyContent={userdetails.trackingId} name={"Tracking Id"}/>
                </Box>
                <SendToDeveloperButton className={classes.sendtoDeveloperBtnStyle} href={`mailto:?subject=Influence integration with Woocommerce&body=${woocommerceMailContent}`}/>
            </Box>
            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 2: Read The Help Article</Typography>
                <Typography className={classes.stepSubHeading}>Follow the installation <a href="https://support.useinfluence.co/article/650" target="_blank"><b style={{color:'#097fff'}} >instructions for using Influence Wordpress/Woocommerce plugin.</b></a> </Typography>
            </Box>
            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 3: Verify The Pixel</Typography>
                <Typography className={classes.stepSubHeading}>Now, visit your website in Incognito mode (to avoid cache issues) and refresh the website 2-3 times to verify the pixel </Typography>
                <Box style={{display:'flex', alignItems:'center', marginTop:'1rem'}}>
                    <PixelVerifyingBox pixelStatus={userPixelStatus}/>
                    <CheckPixelButton loading={loading} onClick={handleRecheckPixel}/>
                </Box>
            </Box>

        </TabPanel>
    )
}




/**
 * Magento installtion guide tab 
 */

const MagentoPixelTab = ({userdetails, value, index, userPixelStatus, handleRecheckPixel, loading}) =>{
    const classes = useStyles()
    const magentoMailContent = `Hi there,%0D%0A %0D%0A %0D%0A Please integrate the Influence to our website. Here is the installation help article:- https://support.useinfluence.co/article/637%0D%0A%0D%0A%0D%0A
API Key:- ${userdetails.apiKey} %0D%0A
Tracking ID:- ${userdetails.trackingId}
`
    return(
        <TabPanel  index={index} value={value}>
            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>
                    Step 1: Install Influence Magento plugin
                </Typography>
                <Typography className={classes.stepSubHeading} style={{marginTop:'0.5%'}}>
                    <a href="https://marketplace.magento.com/peter-sales-influence-tracking.html" target="_blank"><b style={{color:'#097fff'}} >Click here</b></a> to install Influence plugin from Magento marketplace.
                </Typography>

                <Box style={{width:'60%', marginTop:'1rem'}}>
                    <CopyBox copyContent={userdetails.apiKey} name={"API Key"}/>
                </Box>

                <Box style={{width:'60%', marginTop:'1rem'}}>
                    <CopyBox copyContent={userdetails.trackingId} name={"Tracking Id"}/>
                </Box>

                <SendToDeveloperButton className={classes.sendtoDeveloperBtnStyle} href={`mailto:?subject=Influence integration with Magento&body= ${magentoMailContent}`}/>

                
            </Box>


            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 2: Read The Help Article</Typography>
                <Typography className={classes.stepSubHeading}>Follow the installation <a href="https://support.useinfluence.co/article/637" target="_blank"><b style={{color:'#097fff'}} >instructions for using Influence Magento plugin.</b></a> </Typography>
            </Box>


            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 3: Verify The Pixel</Typography>
                <Typography className={classes.stepSubHeading}>Now, visit your website in Incognito mode (to avoid cache issues) and refresh the website 2-3 times to verify the pixel </Typography>
                <Box style={{display:'flex', alignItems:'center', marginTop:'1rem'}}>
                    <PixelVerifyingBox pixelStatus={userPixelStatus}/>
                    <CheckPixelButton loading={loading} onClick={handleRecheckPixel}/>
                </Box>
            </Box>

        </TabPanel>
    )
}




/**
 * Click Funnel installtion guide tab 
 */

const ShopifyPixelTab = ({value, index, userPixelStatus, handleRecheckPixel, loading}) =>{
    const classes = useStyles()
    return(
        <TabPanel  index={index} value={value}>
            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>
                    Step 1: Get Influence app from Shopify Marketplace
                </Typography>

                <Typography className={classes.stepSubHeading} style={{marginTop:'0.5%'}}>
                    <a href="https://apps.shopify.com/influence-1?surface_detail=influence+social&surface_inter_position=1&surface_intra_position=4&surface_type=search" target="_blank"><b style={{color:'#097fff'}} >Click here</b></a> to install Influence app from Shopify marketplace. 
                </Typography>
                
            </Box>


            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 2: Read The Help Article</Typography>
                <Typography className={classes.stepSubHeading}>Follow the installation <a href="https://support.useinfluence.co/article/651" target="_blank"><b style={{color:'#097fff'}} >instructions for using Influence Shopify plugin.</b></a> </Typography>
            </Box>


            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 3: Verify The Pixel</Typography>
                <Typography className={classes.stepSubHeading}>Now, visit your website in Incognito mode (to avoid cache issues) and refresh the website 2-3 times to verify the pixel </Typography>
                <Box style={{display:'flex', alignItems:'center', marginTop:'1rem'}}>
                    <PixelVerifyingBox pixelStatus={userPixelStatus}/>
                    <CheckPixelButton loading={loading} onClick={handleRecheckPixel}/>
                </Box>
            </Box>

        </TabPanel>
    )
}
const ClickFunnelTab = ({value, index, userPixelStatus, handleRecheckPixel, loading}) =>{
    const classes = useStyles()
    return(
        <TabPanel  index={index} value={value}>
            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>
                    Step 1: Install Influence Using ClickFunnel
                </Typography>

                <CopyPixelCode helpLink={"https://support.useinfluence.co/article/678"} appName={'ClickFunnel'} containerStyle={classes.containerBox} heading={false}/>
                
            </Box>


            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 2: Read The Help Article</Typography>
                <Typography className={classes.stepSubHeading}>Follow the installation <a href="https://support.useinfluence.co/article/678" target="_blank"><b style={{color:'#097fff'}} >instructions for using Influence with ClickFunnel.</b></a> </Typography>
            </Box>


            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 3: Verify The Pixel</Typography>
                <Typography className={classes.stepSubHeading}>Now, visit your website in Incognito mode (to avoid cache issues) and refresh the website 2-3 times to verify the pixel </Typography>
                <Box style={{display:'flex', alignItems:'center', marginTop:'1rem'}}>
                    <PixelVerifyingBox pixelStatus={userPixelStatus}/>
                    <CheckPixelButton loading={loading} onClick={handleRecheckPixel}/>
                </Box>
            </Box>

        </TabPanel>
    )
}




/**
 * Google installtion guide tab 
 */

const GTMPixelTab = ({value, index, userPixelStatus, handleRecheckPixel, loading}) =>{
    const classes = useStyles()
//     const gtmMailContent = `
// Influence installation with Google Tag Manager %0D%0A
// API Key:- ${userdetails.apiKey} %0D%0A
// Tracking ID:- ${userdetails.trackingId}
//     `
    return(
        <TabPanel  index={index} value={value}>
            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>
                    Step 1: Install your Influence Pixel as a Tag
                </Typography>
                <Typography className={classes.stepSubHeading}>
                    Copy the pixel code & use it as custom HTML in GTM tag
                </Typography>

                <CopyPixelCode helpLink={'https://support.useinfluence.co/article/673'} containerStyle={classes.containerBox} heading={false}/>


                {/* <SendToDeveloperButton className={classes.sendtoDeveloperBtnStyle} href={`mailto:?subject=Influence integration&body=Pixeffl Link:- <br/> ${gtmMailContent}`}/> */}

                {/* <Typography className={classes.stepSubHeading} style={{marginTop:'1rem'}}>
                    <a href="#" target="_blank">Click here</a> to install Influence app from plugin market place 
                </Typography> */}
                
            </Box>


            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 2: Read The Help Article</Typography>
                <Typography className={classes.stepSubHeading}>Follow the installation <a href="https://support.useinfluence.co/article/673" target="_blank"><b style={{color:'#097fff'}} >instructions for using Influence with GTM.</b></a> </Typography>
            </Box>


            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 3: Verify The Pixel</Typography>
                <Typography className={classes.stepSubHeading}>Now, visit your website in Incognito mode (to avoid cache issues) and refresh the website 2-3 times to verify the pixel </Typography>
                <Box style={{display:'flex', alignItems:'center', marginTop:'1rem'}}>
                    <PixelVerifyingBox pixelStatus={userPixelStatus}/>
                    <CheckPixelButton loading={loading} onClick={handleRecheckPixel}/>
                </Box>
            </Box>

        </TabPanel>
    )
}




/**
 * Other installtion guide tab 
 */
const OtherHelpTabs = ({value, index, userPixelStatus, handleRecheckPixel, loading}) =>{
    const classes = useStyles()
    const gtmMailContent = "GTM conten coms here......."
    return(
        <TabPanel  index={index} value={value}>
            {/* <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>
                    Step 1: Install the pixel!
                </Typography>
                <Typography className={classes.stepSubHeading}>
                    This code goes between your website's "head" tag. After installing the pixel, create a campaign to verify it.
                </Typography>

                <Box style={{width:'60%', marginTop:'1rem'}}>
                    <CopyBox copyContent={"asdfasfdasfsadfsad"} name={"Tracking Id"}/>
                </Box>

                <SendToDeveloperButton className={classes.sendtoDeveloperBtnStyle} href={`mailto:?subject=Influence integration&body=Pixeffl Link:- <br/> ${gtmMailContent}`}/>                
            </Box> */}


            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 1: Read The Help Article</Typography>
                <Typography className={classes.stepSubHeading}>Follow the installation <a href="#"><b style={{color:'#097fff'}} >instructions for using Influence on your platform</b></a> </Typography>
            </Box>


            <Box className={classes.stepsBox}>
                <Typography className={classes.stepHeading}>Step 2: Verify The Pixel</Typography>
                <Typography className={classes.stepSubHeading}>Now, visit your website in Incognito mode (to avoid cache issues) and refresh the website 2-3 times to verify the pixel </Typography>
                <Box style={{display:'flex', alignItems:'center', marginTop:'1rem'}}>
                    <PixelVerifyingBox pixelStatus={userPixelStatus}/>
                    <CheckPixelButton loading={loading} onClick={handleRecheckPixel}/>
                </Box>
            </Box>

        </TabPanel>
    )
}



const CheckPixelButton = (props) =>{
    return(
        <Button disabled={props.loading} {...props} variant="contained" style={{marginLeft:'10px', height:'35px', position:'relative'}}>
            Recheck Status
            {
                props.loading && <CircularProgress size={19} style={{color:'#fff', position:'absolute'}} aria-busy={true}/>
            }
        </Button>
    )
}


