import React, { useEffect } from 'react';
import { Box, makeStyles, createStyles, Dialog } from '@material-ui/core';
import NewCampaignCreation from '../NewCampaignCreation/NewCampaignCreation';



const CreateCampDialogForm = (props) => {
    const classes = useStyles();
    const {
        open,
        closeHandler,
        handleClose
        } = props;
    
    return(
        <Box>
            <Dialog 
                open={open} 
                keepMounted

                maxWidth="md" 
                classes={{paper: classes.dialogPaper}} 
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                style={{zIndex:'3600'}}
            >

                <NewCampaignCreation 
                closeHandler={closeHandler}
                handleClose={handleClose}
                />    
                
                
            </Dialog>
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
       
        dialogPaper: {
            width: '850px'
        },
        divider: {
            margin: '4% 2%'
        },
        inputContainers:{
            paddingTop: '10px'
        },
       
        button2: {
            backgroundColor:'#2c66c3',
            height:'32px',
            width:'121px',
            fontSize:'12px !important',
            textTransform:'initial',
            borderRadius:'4px',
            "&focus": {
                outline:'none'
            }
        },
        inputName: {
            fontSize: '14px',
            paddingLeft: '3px'
        },
        heading:{
            fontWeight: 'bold'
        },
        inputStyle:{
            border: '1px solid hsl(0,0%,80%)',
            marginTop: '1%'
        }
    })    
)

  

export default CreateCampDialogForm;