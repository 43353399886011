import { call, put, fork, takeLatest } from 'redux-saga/effects';
import * as api from 'services/api';
import * as actions from 'ducks/google';
import * as actions_webhook from 'ducks/webhooks';
import { succeessGoogle, popGoogle } from 'ducks/google';
import { load, loaded } from 'ducks/loading';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

const errorMessge = 'Please try again or refresh!';

const toastConfig = {
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: 2000,
  className: 'toast-style'
};

function* get(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `google?campaignId=${action.campaignId}`);
    if (!res.error) {
      yield put(succeessGoogle(res));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}
function* add(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'google', action.google);
    if (!res.error) {
      yield put(succeessGoogle(res));
      yield toast.error('api key added', toastConfig);
      yield put(actions_webhook.fetchAppsConnection(action.google.campaignId));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* update(action) {
  try {
    yield put(load());
    const res = yield call(api.PUT, `google/${action.google._id}`, action.google);
    if (res.error)
      Sentry.captureException(res.error);
    else if (action.aweber)
      yield put(actions.succeessGoogle(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    }
    else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* deleteGoogle(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `google/${action.id}`);
    if (!res.error) {
      yield put(popGoogle(action.index));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

export function* watchAdd() {
  yield takeLatest(actions.CREATE, add);
}
export function* watchUpdate() {
  yield takeLatest(actions.UPDATE, update);
}
export function* watchGet() {
  yield takeLatest(actions.FETCH, get);
}
export function* watchDelete() {
  yield takeLatest(actions.REMOVE, deleteGoogle);
}


export default function* rootSaga() {
  yield [
    fork(watchAdd),
    fork(watchGet),
    fork(watchDelete),
    fork(watchUpdate)
  ];
}
