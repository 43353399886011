import React from 'react'
import {
  Box,
  makeStyles,
  createStyles,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@material-ui/core'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined'
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined'
import Pill from 'Globals/GlobalComponents/Pill'

const PixelPage = () => {
  const classes = useStyles()
  const dummyData = [
    {
      id: 1,
      website: 'https://asdf.co',
      status: 'active',
      isActive: false,
    },
    {
      id: 2,
      website: 'https://aseeedf.co',
      status: 'inactive',
      isActive: true,
    },
    {
      id: 1,
      website: 'https://asaaaadf.co',
      status: 'active',
      isActive: false,
    },
  ]
  return (
    <Box className={classes.pixelPageContainer}>
      <Typography variant="h4">Embebd Pixel</Typography>
      <Box className={classes.textContainer}>
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Consectetur lorem donec massa sapien faucibus et
          molestie ac. Tincidunt vitae semper quis lectus.
        </Typography>
      </Box>

      <Box className={classes.pixelsConainer}>
        <Box className={classes.pixelsContaineHeader}>
          <Typography variant="h5" style={{ fontWeight: '500' }}>
            Pixels
          </Typography>
          <Button variant="contained">
            <CachedOutlinedIcon fontSize="small" />
          </Button>
        </Box>

        <TableContainer style={{ backgroundColor: '#fff' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>website</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>copy</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {dummyData.map((data) => (
                <TableRow hover>
                  <TableCell style={{ color: 'rgba(9,30,66,.74)' }}>{data.website}</TableCell>
                  <TableCell>
                    <Pill type={data.isActive ? 'Green' : 'Red'}>
                      {data.isActive ? 'Active' : 'Inactive'}
                    </Pill>
                  </TableCell>
                  <TableCell className={classes.iconCell}>
                    <FileCopyOutlinedIcon fontSize="small" style={{ color: 'grey' }} />
                  </TableCell>
                  <TableCell className={classes.iconCell}>
                    <CodeOutlinedIcon fontSize="small" style={{ color: 'grey' }} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    pixelPageContainer: {
      '&>h4:first-child': {
        fontSize: '2rem',
        color: '#000',
      },
    },
    textContainer: {
      marginTop: theme.spacing(2),
    },
    pixelsContaineHeader: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
      '&>h5': {
        fontSize: 18,
      },
    },
    iconCell: {
      '&>svg': {
        color: 'grey',
        cursor: 'pointer',
      },
    },
  })
)

export default PixelPage
