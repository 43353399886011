import React, { useState, useEffect } from 'react';
import { Box, makeStyles, createStyles, Dialog, Typography, Divider, Grid, InputBase, Button } from '@material-ui/core';
import TextInput from '../../Globals/GlobalComponents/TextInput';
import SelectBox from '../SelectBox';
import PageLinkChips from './PageLinkChips'
//import CustomHelpIcon from '../.././Globals/GlobalComponents/CustomHelpIcon'
import {HelpQuestion} from '../../img'
import helpArticleLinks from '../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../Globals/GlobalComponents/InfluenceHelpIcon';


const CreateGoalDialogForm = (props) => {
    const classes = useStyles();
    const {
        campaignInfoData,
        open,
        handleCampaignChange,
        handleCategoryChange,
        handleGoalName,
        handleUrl,
        handleDescription,
        handleConversionValue,
        handleSubmit,
        closeHandler,
        goalName,
        goalconversionValue,
        goalUrl,
        goalDescription,
        conversionDisabled,
        campaignInfo,
        handleLinkUrls,
        goalCategory,
        linkUrl,
        handleLinkSeperator,
        linksUrlArray,
        setLinksUrlArray,
        accessLinkClickFeatre
        } = props;
        


    var campaignInfoNew = campaignInfoData.map((campaign) =>  {return {label : campaign.campaignName , value : campaign._id}})

    const goalCategories = ()=>{
        if(accessLinkClickFeatre()){
            return[
                {value:'Purchase',label:'Purchase'},
                {value:'Subscribe/Signup',label:'Subscribe/Signup'},
                {value:'Page Visit',label:'Page Visit'},
                {value:'Link Click', label:'Link Click'}
            ]
        } else{
            return [
                {value:'Purchase',label:'Purchase'},
                {value:'Subscribe/Signup',label:'Subscribe/Signup'},
                {value:'Page Visit',label:'Page Visit'},
            ]
        }
    }
  



    return(
        <Box>
            <Dialog 
                open={open} 
                keepMounted
                maxWidth="md" 
                classes={{paper: classes.dialogPaper}} 
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                style={{zIndex:'3800'}}
            >
                <Box padding='5% 10%'>
                    <Grid container direction="column" spacing={4}>
                        <Grid item md={12} style={{paddingBottom: '0'}} >
                            <Typography variant="h3"  className={classes.heading}>Create your goal</Typography>
                        </Grid>
                        <Divider classes={{root: classes.divider}} />
                        <Grid item md={12} style={{display:'flex',flexDirection:'row',alignItems:'center'}}>

                            <Grid item md={6} style={{paddingTop: '0'}}>
                                <Typography  className={classes.inputName}>
                                    Choose a Campaign
                                </Typography>
                                <SelectBox
                                    options={campaignInfoNew}
                                    onChange={handleCampaignChange}
                                />
                            </Grid>
                        
                            <Grid item md={6} style={{paddingLeft: '3%'}}>
                                <Typography  className={classes.inputName}>
                                    Choose a goal category
                                    {/* <a href={helpArticleLinks.faq.goals.category} target="_blank">
                                        <img src = {HelpQuestion}  style={{ height: "12px",marginLeft:"2%"}}/>
                                    </a> */}
                                    <InfluenceHelpIcon href={helpArticleLinks.faq.goals.category}  />
                                </Typography>
                                <SelectBox
                                    options={goalCategories()}
                                    onChange={handleCategoryChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item md={12} style={{paddingBottom: '0'}}>
                            <Grid container spacing={0} >
                                <Grid item md={6}>
                                    <Typography  className={classes.inputName}>Name your goal</Typography>
                                    <TextInput value={goalName} className={classes.inputStyle} placeholder={"Ex: Leverage"} onChange={handleGoalName} />
                                </Grid>
                                <Grid item md={6} style={{paddingLeft:'3%'}}>
                                    <Typography className={classes.inputName}>
                                        Set conversion value
                                        {/* <a href={helpArticleLinks.faq.goals.setConversionValue} target="_blank">
                                            <img src = {HelpQuestion}  style={{ height: "12px",marginLeft:"2%"}}/>
                                        </a> */}
                                        <InfluenceHelpIcon href={helpArticleLinks.faq.goals.setConversionValue} />
                                    </Typography>
                                    <TextInput  value={goalconversionValue} className={classes.inputStyle} type={'number'} placeholder={"$99"} onChange={handleConversionValue} disabled={conversionDisabled}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.inputContainers} md={12}>
                            <Typography  className={classes.inputName}>{goalCategory.value==="Link Click"? "Enter link URLs": "Enter target path"}</Typography>
                            <TextInput 
                                value={goalCategory.value==="Link Click"?linkUrl:goalUrl} 
                                className={classes.inputStyle} 
                                placeholder={ goalCategory.value==="Link Click"? "pagURL.com": "/path.html"}
                                onKeyDown={goalCategory.value === "Link Click" && handleLinkSeperator} 
                                onChange={goalCategory.value==="Link Click"?    handleLinkUrls : handleUrl} 
                            />
                        </Grid>

                        <Grid  item className={ goalCategory.value === "Link Click" ? classes.inputContainers : classes.hideBox} md={12}>
                            <PageLinkChips
                                linksArray = {linksUrlArray}
                                setLinksArray  = {setLinksUrlArray}
                            />
                        </Grid>
                        <Grid item className={classes.inputContainers} md={12}>
                            <Typography className={classes.inputName}>Description</Typography>
                            <TextInput value={goalDescription} className={classes.inputStyle} placeholder={"Ex: Lorem ipsum dolroos ethos vigo"} onChange={handleDescription}/>
                        </Grid>
                        <Grid item md={12} className={classes.buttonBox}>
                            <Button onClick={closeHandler} variant="outlined" style={{marginRight: '12px',}}>Cancel</Button>
                            <Button onClick={handleSubmit} variant="contained" >Create Goal</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </Box>
    );
}




const useStyles = makeStyles(theme =>
    createStyles({
        buttonBox: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            height: '100px',
        },
        dialogPaper: {
            width: '850px'
        },
        divider: {
            margin: '4% 2%'
        },
        inputContainers:{
            paddingTop: '10px'
        },
        inputName: {
            fontSize: '14px',
            paddingLeft: '3px'
        },
        heading:{
            fontWeight: 'bold'
        },
        inputStyle:{
            border: '1px solid hsl(0,0%,80%)',
            marginTop: '1%'
        },
        hideBox:{
            display:'none'
        }
    })    
)

  

export default CreateGoalDialogForm;



export const lnikClickFeatureBlocker = (profile)=>{

}