import React, { useState, useEffect,  memo } from 'react';
import { makeStyles, createStyles, Typography, Box, Grid, Checkbox, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FlagIcon from '@material-ui/icons/Flag';
import BasicBox from './BasicBox'
import AppearanceBox from './AppearanceBox'
import Textbox from './Textbox'
import FeaturesBox from './FeaturesBox'
import AdditionalFeaturesBox from './AdditionalFeaturesBox'
import InputBox from '../../../../Globals/GlobalComponents/InputBox';
import CustomHelpIcon from '../../../../Globals/GlobalComponents/CustomHelpIcon';
import NotificationsClickableBox from './NotificationsClickableBox';
import { useSelector } from 'react-redux';




const AnnouncementNotificationRules = (props) => {
    const classes = useStyles();
    const user = useSelector(state=>(state.getIn(['auth','user'])))

    const [bgColorPopper, setBgColorPopper] = useState(false);
    const [fontColorPopper, setFontColorPopper] = useState(false);
    const [fontColorPopper2, setFontColorPopper2] = useState(false);
    const [checkBoxStatus1, setCheckBoxStatus1] = useState(false);
    const [checkBoxStatus2, setCheckBoxStatus2] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [bgColorValue, setBgColorValue] = useState('rgba(0,0,0,0)')
    const [iconColorValue, setIconColorValue] = useState('rgba(0,0,0,0)')
    const [checkBoxStatus, setCheckBoxStatus] = useState(false);
    const [fontBgColor, setFontBgColor] = useState("rgba(0,0,0,0)")
    const [secondaryFontBgColor, setSecondaryFontBgColor] = useState('rgb(0,0,0,0)')

    const [ctaBgColorValue, setCtaBgColorValue] = useState('rgb(9,127,255,1)')
    const [ctaFontColorValue, setCtaFontColorValue] = useState('rgb(0,0,0,0)')

    const { 
        announcementHeaderText,
        announcementSubText,
        handlePropertyChange, 
        handleInputChange,
        setAnnouncementSettings,
        announcementsSettings,
        notificationConfiguration,
        handlePanelStyleChange,
        campaignInfo,
        notificationType,
        handleLanguageChange,
        handleNotificationSubmit,
        loading,
        brandingLocked,
        onClose
    } = props;

    const handleBgColorPopper = (event) => {
        if(bgColorPopper === false) {
            setBgColorPopper(true);
            setAnchorEl(event.currentTarget)
        } else {
            setBgColorPopper(false)
            setAnchorEl(null)
        }
    }

    const handleFontColorPopper = (event) => {
        if(fontColorPopper === false) {
            setFontColorPopper(true)
            setAnchorEl(event.currentTarget)
        } else {
            setFontColorPopper(false)
            setAnchorEl(null)
        }
    }

    const handleCheckBox1 = () => {
        if(checkBoxStatus1 === false) {
            setCheckBoxStatus1(true);
        } else setCheckBoxStatus1(false);
    }

    const handleCheckBox2 = () => {
        if(checkBoxStatus2 === false) {
            setCheckBoxStatus2(true);
        } else {
            setCheckBoxStatus2(false)
            handlePropertyChange('notificationUrl', "" , setAnnouncementSettings)
        }
    }
    const handleImageUpload = (file) => {
        handlePanelStyleChange('image', file.base64, setAnnouncementSettings)
    }

    const handleCheckBox = () => {
        if(checkBoxStatus === false) {
            setCheckBoxStatus(true);
        } else {
            setCheckBoxStatus(false)
            handlePropertyChange('notificationUrl', '', setAnnouncementSettings, )
        }
    }

   
    useEffect(()=>{
        if(announcementsSettings.notificationUrl.length > 0){
            setCheckBoxStatus2(true)
        }
    },[announcementsSettings.notificationUrl])

   /* const rgbSelector = (obj, stateName) =>{
        stateName(`rgba(${obj.r},${obj.g},${obj.b},${obj.a})`)
    }*/

    return(
        <Box>
            <BasicBox

                setAnnouncementSettings={setAnnouncementSettings}
                notificationDisplayApp={announcementsSettings.notificationDisplayApp}
                handleLanguageChange={handleLanguageChange}
                handlePropertyChange={handlePropertyChange}
                langName={announcementsSettings.langName}
                notificationSize={announcementsSettings.notificationSize}
               
            />

            <AppearanceBox
                setAnnouncementSettings={setAnnouncementSettings}
                handlePanelStyleChange={handlePanelStyleChange}
                radius={announcementsSettings.panelStyle.radius}
                backgroundColor={announcementsSettings.panelStyle.backgroundColor}
                handleImageUpload={handleImageUpload}
            />            

            <Textbox
                handlePanelStyleChange={handlePanelStyleChange}
                handlePropertyChange={handlePropertyChange}
                setAnnouncementSettings={setAnnouncementSettings}
                fontWeight={announcementsSettings.panelStyle.fontWeight}
                fontFamily={announcementsSettings.panelStyle.fontFamily}
                color={announcementsSettings.panelStyle.color}
                fontColorPopper={fontColorPopper}
                fontColorPopper2={fontColorPopper2}
                setAnchorEl={setAnchorEl}
                anchorEl={anchorEl}
                anchorEl2={anchorEl2}
                setAnchorEl2={setAnchorEl2}
                fontBgColor={fontBgColor}
                setFontBgColor={setFontBgColor}
                announcementHeaderText = {announcementHeaderText}
                announcementSubText = {announcementSubText}
                setFontColorPopper={setFontColorPopper}
                setFontColorPopper2={setFontColorPopper2}
                secondaryFontBgColor={secondaryFontBgColor}
                setSecondaryFontBgColor={setSecondaryFontBgColor}
            />            

            <FeaturesBox
               
                announcementHeaderText = {announcementHeaderText}
                announcementSubText = {announcementSubText}
                handleInputChange={handleInputChange}
                setAnnouncementSettings={setAnnouncementSettings}
                handlePropertyChange={handlePropertyChange}
                handlePanelStyleChange={handlePanelStyleChange}
            />

            <AdditionalFeaturesBox
                setAnnouncementSettings={setAnnouncementSettings}
                handlePropertyChange={handlePropertyChange}
                recentText2={announcementsSettings.recentText2}
                poweredBy={announcementsSettings.poweredBy}
                poweredByLink={announcementsSettings.poweredByLink}
                togglePoweredBy={announcementsSettings.togglePoweredBy}
                brandingLocked={brandingLocked}
            />

            <NotificationsClickableBox 
                notificationUrl={announcementsSettings.notificationUrl}
                ctaButtonText={announcementsSettings.ctaButtonText}
                ctaHyperlinkUrl={announcementsSettings.ctaHyperlinkUrl}
                isEnablePurchaseNotificationUrl={announcementsSettings.isEnablePurchaseNotificationUrl}
                toggleCTA={announcementsSettings.toggleCTA}
                ctaRadius={announcementsSettings.panelStyle.ctaRadius}
                ctaBgColorValue={announcementsSettings.panelStyle.backgroundColor}
                ctaFontColorValue={announcementsSettings.panelStyle.ctaFontColor}
                setAnnouncementSettings={setAnnouncementSettings}
                handleInputChange={handleInputChange}
                handlePropertyChange={handlePropertyChange}
                handlePanelStyleChange={handlePanelStyleChange}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                isCTATop={announcementsSettings.isCTATop}
                announcementsSettings={announcementsSettings}
            />

            
            <Box className={classes.saveBtnContainer}>
                <Button variant="outlined" onClick={onClose} className={classes.cancelBtn}>
                    Close
                </Button>
                <Button
                    variant="contained"
                    className={classes.saveBtn}
                    disabled={loading || user.role.name === "campaignB"}
                    onClick={
                        () => handleNotificationSubmit(
                                notificationConfiguration[0]._id,
                                announcementsSettings,
                                campaignInfo._id,
                                notificationType[0]._id,
                                campaignInfo.websiteUrl,
                                campaignInfo.trackingId
                            )
                    }
                >   
                     Save Changes
                    {loading && <CircularProgress classes={{colorPrimary: classes.circularProgress}} size={25} thickness={5} />}
                </Button>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        colorIcon: {
            width: '35px',
            height: '35px',
            border: '1px solid #000',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        colorIconActive: {
            width: '35px',
            height: '35px',
            border: '2px solid rgba(201,201,201,0.7)',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        input: {
            height: '35px',
            backgroundColor: '#fff',
            marginTop: '4%',
            border: ' 0.5px solid rgba(0, 0, 0, 0.23)',
            width: '100%',
            borderRadius: '4px',
            padding: '0% 3%',
            fontSize: '13px'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            // paddingBottom: '5%'
        },
        gridPading:{
            paddingTop: '3%'
        },
        lowerBoxPadding:{
            paddingLeft: '2%'
        },
        saveBtnContainer:{
            position: 'fixed',
            width: '46%',
            bottom: '0',
            zIndex: '2',
            backgroundColor: '#fff',
            padding: '17px 0px',
            marginLeft: '-2.8%',
            borderTop: '1px solid #c6c6c6',
            display: 'flex',
            justifyContent: 'flex-end'
        },
        saveBtn:{
            // textTransform: 'initial',
            // fontWeight: '200',
            // fontSize: '12px',
            position:'relative',
            margin: '0 4%'

        },
        circularProgress:{
            color: '#fff',
            top:'52%',
            left:'52%',
            marginTop:-12,
            marginLeft:-12,
            position:'absolute'
        },
        // cancelBtn:{
        //     backgroundColor: '#fff',
        //     border: '1px solid #c6c6c6 !important',
        //     color: '#484848',
        //     textTransform: 'unset',
        //     width: '85px',
        //     fontWeight: '200',
        //     fontSize: '12px',
        //     '&:hover':{
        //         backgroundColor: '#c6c6c686',
        //         color: '#484848',
        //     }
        // }
    })    
)

export default memo(AnnouncementNotificationRules);