import React from 'react'
import { Box } from '@material-ui/core'
import { CookieDetailsSkeleton } from '../../../../../Globals/GlobalComponents/Skeletons'
// import Details from './Details'
const Details = React.lazy(()=> import('./Details'))

const DetailsContainer  = (props) => {

  return(
    <Box>
      <React.Suspense fallback={<CookieDetailsSkeleton/>}>
        <Details {...props}/>
      </React.Suspense>
    </Box>
  )
}



export default DetailsContainer

