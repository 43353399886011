import React, { memo, useState, useEffect } from 'react';
import { Box, makeStyles, createStyles, Grid, Checkbox, Typography, Popover } from '@material-ui/core';
// import TextInput from '../../../../Globals/GlobalComponents/TextInput';
import SlidingBar from '../../../../Globals/GlobalComponents/SlidingBar';
// import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
import { SketchPicker } from 'react-color';
import InputBox from '../../../../Globals/GlobalComponents/InputBox';
import CustomHelpIcon from '../../../../Globals/GlobalComponents/CustomHelpIcon';
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';


const NotificationsClickableBox  = (props) => {
    const classes = useStyles();

    const {
        notificationUrl,
        announcementsSettings,
        ctaButtonText,
        ctaHyperlinkUrl,
        isEnablePurchaseNotificationUrl,
        toggleCTA,
        ctaRadius,
        setAnnouncementSettings,
        handleInputChange,
        handlePropertyChange,
        handlePanelStyleChange,
        isCTATop,
        anchorEl,
        setAnchorEl
    } = props;

    //const [clickableCheck, setClickableCheck] = useState(false);
    const [checkBoxStatus, setCheckBoxStatus] = useState(false);
   // const [ctaFontColorPopper, setCtaFontColorPopper] = useState(false)
    //const [ctaBackgroundColorPopper, setCtaBackgroundColorPopper] = useState(false);
    //const [ctaBgColorValue, setCtaBgColorValue] = useState('rgb(9,127,255,1)')
    //const [ctaFontColorValue, setCtaFontColorValue] = useState('rgb(0,0,0,0)')

    const handleCheckBox = () => {
        if(checkBoxStatus === false) {
            setCheckBoxStatus(true);
        } else {
            setCheckBoxStatus(false)
            handlePropertyChange('notificationUrl', '', setAnnouncementSettings, )
        }
    }

    useEffect(()=>{
        if(announcementsSettings.notificationUrl.length >0){
            setCheckBoxStatus(true)
        }
    },[announcementsSettings.notificationUrl])

/*
    const handleCtaBgColorPopper = (event) =>{
        if(ctaBackgroundColorPopper === false){
            setCtaBackgroundColorPopper(true)
            setAnchorEl(event.currentTarget)
        }else{
            setCtaBackgroundColorPopper(false)
            setAnchorEl(null)
        }
    }

    const handleCtaFontColorPopper = (event) =>{
        if(ctaFontColorPopper === false){
            setCtaFontColorPopper(true)
            setAnchorEl(event.currentTarget)
        }else{
            setCtaFontColorPopper(false)
            setAnchorEl(null)
        }
    }

    const rgbSelector = (obj, stateName) =>{
        stateName(`rgba(${obj.r},${obj.g},${obj.b},${obj.a})`)
    }
*/
    return(

        <Box borderTop='1px solid #f5f6f7' padding='5% 0%'>
                <Grid container>
                    <Grid item md={12}>
                        <Grid container alignItems='center'>
                            <Grid item >
                                <Checkbox 
                                    checked={checkBoxStatus}
                                    onChange={handleCheckBox}
                                />
                            </Grid> 
                            <Grid item md={5}>
                                <Typography className={classes.boxTitle}>
                                    Notifications Clickable
                                    {/* <a href={helpArticleLinks.faq.notification.clickable} target="_blank">
                                        <img src = {HelpQuestion}  style={{ height: "13px",marginLeft:"2%"}}/>
                                    </a> */}
                                    <InfluenceHelpIcon href={helpArticleLinks.faq.notification.clickable} />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {checkBoxStatus &&
                        <Grid item md={12} style={{paddingTop: '9px', paddingLeft: '0'}}>
                            <Grid container alignItems="center">
                                <Grid item style={{paddingLeft:'11px'}} md={3}>
                                    <Typography variant="body2">HyperLink</Typography>
                                </Grid>
                                <Grid item md={9} style={{paddingLeft: '15px'}}>
                                    <InputBox
                                        name="notificationUrl"
                                        value={announcementsSettings.notificationUrl}
                                        dispatcher={setAnnouncementSettings}
                                        onChange={handlePropertyChange}
                                        maxLength={150}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Box>
       
       
                      
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        colorIcon: {
            width: '35px',
            height: '35px',
            border: '1px solid #000',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        colorIconActive: {
            width: '35px',
            height: '35px',
            border: '0.5px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        input: {
            height: '35px',
            //width:'120px',
            backgroundColor: '#fff',
            marginTop: '4%',
            border: ' 0.5px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            padding: '0% 3%',
            width:'100%'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            // paddingBottom: '5%'
        },
    })
)

export default memo(NotificationsClickableBox);

