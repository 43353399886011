const accessList = [
  {
    name: 'customer',
    pages: ['dashboard', 'billingDetails', 'campaign', 'affiliate', 'installpixel', 'settings', 'team', 'analytics', 'accountsummary']
  },
  {
    name: 'account',
    pages: ['dashboard', 'billingDetails', 'campaign', 'affiliate', 'installpixel', 'settings', 'team', 'analytics', 'accountsummary']
  },
  {
    name: 'billing',
    pages: ['billingDetails']
  },
  {
    name: 'campaign',
    pages: ['dashboard', 'campaign', 'analytics', 'installpixel', 'accountsummary']
  },
  {
    name: 'affiliate',
    pages: ['affiliate']
  },
  {
    name: 'accountB',
    pages: ['dashboard', 'billingDetails', 'campaign', 'affiliate', 'installpixel', 'settings', 'team', 'analytics', 'accountsummary']
  },
  {
    name: 'billingB',
    pages: ['billingDetails']
  },
  {
    name: 'campaignB',
    pages: ['dashboard', 'campaign', 'analytics', 'installpixel', 'accountsummary']
  },
  {
    name: 'affiliateB',
    pages: ['affiliate']
  }
];

export const checkPermission = (role, page) => {
  let accessCheck = false;
  
  accessList.map(item => {
    if(item.name == role && item.pages.indexOf(page) > -1) {
      accessCheck = true;
    }
  });
  return accessCheck;
};

export const checkWhitelable = () => {
  const host = window.location.host;
  if (
    host &&
    host != 'localhost:3000' &&
    host != 'localhost:3002' &&
    host != 'localhost:3001' &&
    host != 'useinfluence.co' &&
    host != 'staging.useinfluence.co' &&
    host != 'app.useinfluence.co' &&
    host != 'devp-influence-dash001.netlify.app' &&
    host != 'api.useinfluence.co' &&
    host != 'localhost:5000' &&
    host != 'musing-ramanujan-726716.netlify.app' &&
    host != 'suspicious-payne-eb90c6.netlify.app' &&
    host != 'stoic-lamarr-9bf3fb.netlify.app' &&
    host != 'localhost:1387' &&
    host != 'dazzling-mayer-88592c.netlify.app'
  )
    return true
  return false;
};
