import React, { useReducer, useEffect, useState, useRef, memo } from 'react';
import { Box, makeStyles, createStyles } from '@material-ui/core';
import CustomNotificationCard from './CustomNotificationCard';
import CustomNotificationRules from './CustomNotificationRules';
import NotifSettingsReducer, { initialState } from '../../NotifSettingsReducer';
import { useSelector } from 'react-redux';
import translate from 'translate';
import NotificationCardSkeleton from '../../../../Globals/GlobalComponents/NotificationCardSkeleton';
import NotificationRuleSkeleton from '../../../../Globals/GlobalComponents/NotificationRuleSkeleton';



translate.engine = 'google';
translate.key = process.env.REACT_APP_GOOGLE_TRANSLATE_KEY;

const BulkNotificationSettings  = (props) => {
    const classes = useStyles();
    const {
        handlePropertyChange, 
        handlePanelStyleChange,
        handleInputChange, 
        ruleName, 
        //notificationConfiguration,
        campaignInfo,
        notificationType,
        handleLanguageChange,
        handleNotificationSubmit,
        loading,
        notifBoxBGColor,
        brandingLocked,
        onClose
    } = props;

    const [customSettings, setCustomSettings] = useReducer(NotifSettingsReducer, initialState);
    const [visitorText, setVisitorText] = useState(customSettings.visitorText);
    const [otherText, setOtherText] = useState("");
    const [contentText, setContentText] = useState("");
    const [bulkText, setBulkText] = useState("");
    const [bulkDaysLable, setBulkDaysLable] = useState("");
    const [recentText2, setRecentText2] = useState("");
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        },2000)
    },[])

    useEffect(()=>{
        console.log('customSettings', customSettings)
    },[customSettings])


    const languageHandler = (name, value, dispatcher) =>{
        
        translate(visitorText, {from: customSettings.langName.language, to: value}).then(
            text => {
                setVisitorText(text)
                handlePropertyChange('visitorText', text, setCustomSettings)
            }
        )
        translate(otherText, {from: customSettings.langName.language, to: value }).then(
            text => {
                setOtherText(text)
                handlePropertyChange('otherText', text, setCustomSettings)
            }
        )
        translate(contentText, {from: customSettings.langName.language, to: value }).then(
            text => {
                setContentText(text)
                handlePropertyChange('contentText', text, setCustomSettings)
            }
        )
        translate(bulkText, {from: customSettings.langName.language, to: value }).then(
            text => {
                setBulkText(text)
                handlePropertyChange('bulkText', text, setCustomSettings)
            }
        )
        translate(bulkDaysLable, {from: customSettings.langName.language, to: value }).then(
            text => {
                setBulkDaysLable(text)
                handlePropertyChange('bulkDaysLable', text, setCustomSettings)
            }
        )
        translate(recentText2, {from: customSettings.langName.language, to: value }).then(
            text => {
                setRecentText2(text)
                handlePropertyChange('recentText2', text, setCustomSettings)
            }
        )

        handleLanguageChange(name, value, dispatcher)

    }

    
    const notificationSettings = useSelector(state => (state.getIn(['NotificationSettings', 'notificationConfiguration'])))

    useEffect(()=>{
        setVisitorText(customSettings.visitorText)
    },[customSettings.visitorText])

    useEffect(()=>{
        setOtherText(customSettings.otherText)
    },[customSettings.otherText])

    useEffect(()=>{
        setContentText(customSettings.contentText)
    },[customSettings.contentText])

    useEffect(()=>{
        setBulkText(customSettings.bulkText)
    },[customSettings.bulkText])

    useEffect(()=>{
        setBulkDaysLable(customSettings.bulkDaysLable)
    },[customSettings.bulkDaysLable])

    useEffect(()=>{
        setRecentText2(customSettings.recentText2)
    },[customSettings.recentText2])


    useEffect(() => {
        if(notificationSettings !== undefined){
            setCustomSettings({type: 'INIT', data: notificationSettings.find(item => item.notificationType.notificationName === ruleName)})
        }
    },[notificationSettings])


    
    
    return(
        
        show ? 
            <Box>
                <Box style={{background: notifBoxBGColor}} className={classes.notificationBox} padding='5% 0%'>    
                    <CustomNotificationCard 
                        settings={customSettings}
                        visitorText={visitorText}
                        otherText={otherText}
                        contentText={contentText}
                        bulkText={bulkText}
                        bulkDaysLable={bulkDaysLable}
                        recentText2={recentText2}
                        show={show}
                    />
                </Box>
                <Box padding='6%'>
                    <CustomNotificationRules 
                        campaignInfo={campaignInfo}
                        handlePanelStyleChange={handlePanelStyleChange}
                        handlePropertyChange={handlePropertyChange}
                        handleInputChange={handleInputChange}
                        customSettings={customSettings}
                        setCustomSettings={setCustomSettings}
                        notificationConfiguration={notificationSettings.filter(item => item.notificationType.notificationName === ruleName)}
                        notificationType={(notificationType || []).filter(item => item.notificationName === ruleName)}
                        handleLanguageChange={languageHandler}
                        handleNotificationSubmit={handleNotificationSubmit}
                        loading={loading}
                        visitorText={visitorText}
                        otherText={otherText}
                        contentText={contentText}
                        bulkText={bulkText}
                        bulkDaysLable={bulkDaysLable}
                        recentText2={recentText2}
                        brandingLocked={brandingLocked}
                        onClose={onClose}
                    />
                </Box>
            </Box>
            : 
            <Box>
                <Box style={{background: notifBoxBGColor}} className={classes.notificationBox} padding='5% 0%'>
                    <NotificationCardSkeleton />
                </Box>
                <Box padding="6%">
                    <NotificationRuleSkeleton />
                </Box>
            </Box>
            
        
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        notificationBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '7%',
            position: 'sticky',
            top: '50px',
            zIndex: '9999',
            paddingBottom: '11%',
            transition: 'all 0.7s ease-in-out',
            backdropFilter: "blur(0.8px)"
        },
        loaderBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }
    })
)

export default memo(BulkNotificationSettings) ;