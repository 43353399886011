import React from 'react';
import { Elements } from 'react-stripe-elements';
import { Box, makeStyles, createStyles, Typography, Button } from '@material-ui/core'
import StripePaymentPage from '../LoginFlow/StripePaymentPage';
import StripePaymentForm from './StripePaymentForm'

// import CouponPage from './CouponPage';

const PaymentForm = (props) => {
    const {
        load,
        loaded,
        user,
        plan,
        // couponError,
        cardError,
        nameError,
        // couponDetails,
        disableSubmit,
        handleErrorChange,
        // handleStateChange,
        handleSubmit,
        clearSelectedPlan,
        createPaypalOnetine,
        // submitCoupon,
        // couponProceed,
        handleButtonDisable,
        checkCoupenAvailability,
        overlay
    } = props

    const classes = useStyles()
    return(
        <Box 
            style={{
                padding: '5%', 
                boxShadow: '0 1px 2px rgba(36,35,40,0.2), 0 10px 20px rgba(36,35,40,0.2)', 
                borderRadius: '5px', 
                border: '1px solid #d5d5d5'
            }}
            className={overlay && classes.formOverlay}
        >
            <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography className={classes.startFreeLine}>
                    {
                        plan.amount!=0 ?
                            plan?/\b(Lifetime)\b/m.test(plan.name)?`${plan.name} (Amount: $${plan.amount/100})` :`Try any plan Free for 7 days  |  ${plan.name}`:''
                            :
                            plan.name
                    }
                </Typography>

                <Button variant="contained" onClick={clearSelectedPlan} style={{width:'180px'}}>
                    Change Plan
                </Button>
            </Box>

            <Box style={{marginTop: '5%'}}>
                <Elements>
                    <StripePaymentForm
                        load={load}
                        loaded={loaded}
                        user={user}
                        plan={plan}
                        error={cardError}
                        disableSubmit={disableSubmit}
                        handleErrorChange={handleErrorChange}
                        handleSubmit={handleSubmit}
                        createPaypalOnetine={createPaypalOnetine}
                        handleButtonDisable={handleButtonDisable}
                        checkCoupenAvailability={checkCoupenAvailability}
                    />
                </Elements> 
            </Box>
        </Box>
    )
};

const useStyles = makeStyles(theme=>
    createStyles({
        startFreeLine:{
            color: '#9196b6', 
            fontSize: '17px', 
            fontWeight: '600',
            '@media (max-width: 674px)':{
                fontSize:'14px'
            }
        },
        formOverlay:{
            position:'relative',
            "&:before":{
                content:'""',
                position:'absolute',
                background:'rgba(255,255,255,0.5)',
                cursor:'progress',
                width:'100%',
                height:'100%',
                display:'block',
                top:'0',
                zIndex:'2',
                left:'0'
            }
        }
    })
)

export default PaymentForm;
