import React, {useState} from 'react'
import { Box, makeStyles, createStyles, Typography, IconButton, Grow } from '@material-ui/core'
import { Google, Woocommerce, Wordpress, ShopifyIcon, MagentoIcon,  } from '../../../../img'
import CodeIcon from '@material-ui/icons/Code';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';



const PixelSidebar = () =>{

	const classes = useStyles()
    
    const openDashly=()=>{
        window.dashly.open()
    }

	return(
		<Box>
			<Typography
				style={{
					fontFamily: 'Poppins',
					fontSize: '16px',
					fontWeight: '500',
					color: '#000'
				}}			
			>
				Need help?
			</Typography>

			<Box>
                {
                    integrationsData.map((integration, index)=>(
                        <IconButton component={integration.name === "Chat with us" ? 'div':'a'} title={`${integration.name} | ${integration.tagLine}`} onClick={integration.name==="Chat with us"?openDashly:()=>{}} href={integration.helpLink && integration.helpLink}  target="_blank" key={index} className={classes.cardBox} disableRipple>
                            {
                                integration.iconType == "image" ?
                                    <img src={integration.icon} className={classes.cardImage} alt={integration.name}/>:
                                    <integration.icon fontSize='small' htmlColor='black'/>
                            }
                            <div style={{paddingLeft: '10px'}}>
                                <Typography className={classes.cardHeading} >
                                    {integration.name}
                                </Typography>

                                {/* <Typography className={classes.cardText}>
                                    {integration.tagLine}
                                </Typography> */}
                            </div>
                        </IconButton>
                    ))
                }
			</Box>

		</Box>
	)
}


const useStyles = makeStyles((theme) =>
	createStyles({
		cardBox:{
			display: 'flex',
			flexDirection: 'row',
			width: '188px',
			height: '52px',
			alignItems: 'center',
			backgroundColor: '#fff',
			borderRadius: theme.shape.borderRadius,
			boxShadow: '0px 1px 9px 0 rgb(172 172 172 / 69%)',
			padding: '5px ',
			paddingLeft: '10px',
			justifyContent: 'flex-start',
			marginTop: '15px',
            marginLeft: '0 ',
            border:'0.51px solid #cfcfcf',
			'&:hover':{
                backgroundColor: '#fff',
                boxShadow:'0px 1px 5px 0 rgb(137 137 137 / 69%)',
                border: '1px solid #6f6f6f'
			},
			'&:focus':{
				outline:'none'
			}
		},
		cardHeading:{
			fontSize: '15px ',
			fontWeight: '600',
			fontFamily: 'Poppins',
			margin: '0 ',
			color: '#000 ',
			textAlign: 'left'
		},
		cardText:{
			fontSize: '9px ',
			fontWeight: '500',
			fontFamily: 'Poppins',
			margin: '0 ',
			paddingTop: '5px',
			color: '#7f87a6 ',
			textAlign: 'left',
			padding: '0 '
		},
		cardImage:{
			height: '29px '
		},
	})
)

export default PixelSidebar



const integrationsData = [
    {
        name: "Chat with us",
        // tagLine: "Chat with us",
        icon: ChatOutlinedIcon,
        iconType: "svg",
        helpLink:'',
    },
    {
        name: "Help docs",
        // tagLine: "Integrations Help",
        icon: LiveHelpIcon,
        iconType: "svg",
        helpLink: "https://support.useinfluence.co/category/170",
    },
    {
        name: "Book a demo",
        // tagLine: "Book a call with us",
        icon: AddIcCallIcon,
        iconType: "svg",
        helpLink:'https://calendly.com/kirti-1/one-on-one'
    },
]