import React from 'react'
import {
    DialogBox,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogButton
} from '../../../Globals/GlobalComponents/DialogBox';
import { makeStyles, createStyles, CircularProgress } from '@material-ui/core';

const DeleteDialog = (props) => {

    const {open, handleDialogClose, loadingDelete,handleDeleteCampaign, id} = props
    const classes = useStyles()
    return (
        <DialogBox open={open} onClose={handleDialogClose}>
            <DialogTitle onClose={handleDialogClose}>
                Alert
            </DialogTitle>
            <DialogContent>
                Are you sure want to delete this campaign?
            </DialogContent>

            <DialogActions>
                <DialogButton onClick={handleDialogClose} actionType={"secondary"}>
                    No
                </DialogButton>

                <DialogButton disabled={loadingDelete} actionType={"primary"}  onClick={() =>{handleDeleteCampaign(id)}}>
                    Yes
                    {loadingDelete && <CircularProgress size={19} className={classes.buttonProgress}/>}
                </DialogButton>
            </DialogActions>
        </DialogBox>
    )
}

const useStyles = makeStyles(()=>
    createStyles({
        buttonProgress: {
            color: "#fff",
            position: 'absolute',
            top: '55%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    })
)

export default DeleteDialog
