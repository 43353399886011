import React, {useEffect, useState} from 'react'
import { Box, makeStyles, createStyles, Drawer, Typography } from '@material-ui/core'
import { Link } from 'react-router';
import { quotaExhausted } from '../../utilities/quotaExhausted';
import { useSelector } from 'react-redux';


const SocialProofSideDrawer  = () => {

    const profile = useSelector(state=>(state.getIn(['profile','profile'])))
    const classes = useStyles({quotaExhausted:quotaExhausted(profile)})

    const [openDrawer, setOpenDrawer] = useState(true)
    const [activeNav, setActiveNav] = useState(0)


    const addActiveClass = (testString) =>{
        // if (window.location.href.includes(testString)) return 'active'
        for (const cls in testString) {
            if(window.location.href.includes(testString[cls])) return 'active'
            else return ""
        }
        for(let i=0; i<=testString.lenght; i++){
            if(window.location.href.includes(testString[cls])){
                return 'active'
            }
        }
    }

    const activeNavHandler = () =>{
        const currLocation = window.location.href
        if(currLocation.includes('/campaigns') || (currLocation.includes('/analytics'))) setActiveNav(0)
            else if(currLocation.includes('/goals')) setActiveNav(1)
            else if (currLocation.includes('/installPixelPage')) setActiveNav(2)
            else if (currLocation.includes('/getting-started')) setActiveNav(3)
            else setActiveNav(0)
    }

    useEffect(()=>activeNavHandler())


    useEffect(()=>{
        setOpenDrawer(true)
        return(()=>{ 
            setOpenDrawer(false)
        })
    },[])


    return(
        <Drawer
            variant="persistent"
            anchor="left"
            open={openDrawer}
            classes={{paper:classes.drawerPaper}}
        >
            <Box className={classes.sideBarNavs}>
                <Typography component={Link} className={activeNav === 0 ? 'active':''} to="/campaigns">Campaigns</Typography>
                <Typography component={Link} className={activeNav === 1 ? 'active':''} to="/goals">Goals</Typography>
                <Typography component={Link} className={activeNav === 2 ? 'active':''} to="/installPixelPage">Install Pixel</Typography>
                <Typography component={Link} className={activeNav === 3 ? 'active':''} to="/getting-started">Getting Started</Typography>
            </Box>
            
        </Drawer>
    )
}

const useStyles = makeStyles(theme =>
    createStyles({
        drawerPaper: {
            width: theme.custom.sideDrawer.width,
            marginLeft: theme.custom.sideBar.width ,
            marginTop: props => props.quotaExhausted ?theme.custom.header.height + theme.custom.upgradeStrip.height : theme.custom.header.height,
            zIndex: '99',
            backgroundColor:'#f5f7f9'
            // padding:'10px'
        },
        sideBarNavs:{
            marginTop:'18px',
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-start',
            '& > a':{
                fontSize: '14px',
                fontWeight:'400',
                color:'#222',
                padding:'8px 10px',
                width:'100%',
                borderRadius: theme.shape.borderRadius,
                paddingLeft:'20px',
                '&:hover':{
                    color:'#077fff'
                }
            },
            '& > a.active':{
                color:'#077fff',
                fontWeight:500
                // backgroundColor:'rgba(7,127,255,.16)'
            }
        }
        
    })
)

export default SocialProofSideDrawer
