import React from 'react';
import { makeStyles, createStyles, Box, Typography, Paper, Grid, Divider } from '@material-ui/core';
import StarRateIcon from '@material-ui/icons/StarRate';
import {Facebookpop, fbRecommendation, stampedStar, trustPilotStar, Check} from '../../../../img'

const ReviewNotificationCard = (props) => {
    const classes = useStyles();
    const {reviewSettings, gglReviewText, notifType, recentText2,stampedioText, facebookReviewText,trustpilotText} = props;


    return(
        <Box 
            component={Paper}
            width= '360px'
            height= '85px'
            padding= '4px 10px 2px 10px'
            border='1px solid rgba(216, 217, 226, 0.5)'
            // boxShadow='10px 20px 40px 0 rgba(36, 35, 40, 0.1)'
            // borderRadius={`${reviewSettings.panelStyle.radius}px`}
            // bgcolor={`rgb(${reviewSettings.panelStyle.backgroundColor.r}, ${reviewSettings.panelStyle.backgroundColor.g}, ${reviewSettings.panelStyle.backgroundColor.b})`}
            display='flex'
            alignItems='center'
            style={{
                flexDirection: 'column', 
                justifyContent: 'center',
                borderRadius:`${reviewSettings.panelStyle.radius}px`,
                boxShadow:'10px 20px 40px 0 rgba(36, 35, 40, 0.1)',
                backgroundColor:`rgb(${reviewSettings.panelStyle.backgroundColor.r}, ${reviewSettings.panelStyle.backgroundColor.g}, ${reviewSettings.panelStyle.backgroundColor.b})`
            }}
        >

            <Box className={classes.upperPart}>
                <Box 
                    className={classes.imageContainer}
                    style={{
                        borderRadius: `${reviewSettings.panelStyle.radius}px`,
                        width: '41px', 
                        height: '41px',
                    }}
                >
                    <img
                        //src= {reviewSettings.notificationDisplayApp === 'google' ? "https://cdn.zeplin.io/5de290feb524497c4a9c9959/assets/5FCE8400-0616-426F-8BEA-F53136305123.png" : Facebookpop}
                        style={{
                            borderRadius: `${reviewSettings.panelStyle.radius}px`,
                            height: '41px',
                            width: '41px',
                            objectFit: 'contain',
                            alignSelf: 'center'
                        }}
                        src={reviewSettings.notificationDisplayApp === 'google' ? 
                        "https://storage.googleapis.com/influence-197607.appspot.com/googlereview.png" : 
                        reviewSettings.notificationDisplayApp === 'facebook' ? Facebookpop :
                        reviewSettings.notificationDisplayApp === 'stamped' ? 'https://api.useinfluence.co/images/stamped.png' :
                        reviewSettings.notificationDisplayApp === 'reviewio' ? 'https://api.useinfluence.co/images/reviews-io.png' :
                        reviewSettings.notificationDisplayApp === 'capterra' ? 'https://api.useinfluence.co/images/capterra.png' : 
                        reviewSettings.notificationDisplayApp === 'trustpilot' ? 'https://api.useinfluence.co/images/trustpilot.png' : "https://storage.googleapis.com/influence-197607.appspot.com/googlereview.png"}
                    />
                </Box>
                <Box className={classes.textContainer}>
                    <Box className={classes.userNameContainer}>
                        <p 
                            // className={classes.mainText}
                            style={{
                                fontFamily: reviewSettings.panelStyle.fontFamily,
                                fontWeight: '600',
                                color: `rgb(${reviewSettings.panelStyle.color.r}, ${reviewSettings.panelStyle.color.g}, ${reviewSettings.panelStyle.color.b})`,
                                fontSize: '13px',
                                whiteSpace: 'nowrap',
                                // backgroundColor: '#d6e4ff',
                                // letterSpacing: '0.3px',
                                padding: '1px 5px',
                                textAlign: 'left',
                                margin: '0',
                                textTransform: 'capitalize'

                            }}
                        >
                            {`John Doe`}
                        </p>
                    </Box>

                    <p 
                        style={{
                            margin: '0',
                            fontFamily: reviewSettings.panelStyle.fontFamily,
                            fontWeight: reviewSettings.panelStyle.fontWeight,
                            // whiteSpace: 'nowrap',
                            // overflowX: 'hidden', 
                            // textOverflow: 'ellipsis',
                            // color: `rgb(${reviewSettings.panelStyle.color.r}, ${reviewSettings.panelStyle.color.g}, ${reviewSettings.panelStyle.color.b})`,
                            color: '#666',
                            // marginLeft: '2%',
                            letterSpacing: '0.3px',
                            fontSize: '10px',
                            padding: '0 5px',
                            // apply webkit here
                            display: '-webkit-box',
                            webkitLineClamp: '3',
                            webkitBoxOrient: 'vertical',
                            lineHeight: '1.2',
                            overflow: 'hidden',
                            alignSelf: 'center'
                        }}
                        // className={classes.secondaryText}
                    >
                        {notifType === 'google' ? gglReviewText :
                        notifType === 'facebook' ?facebookReviewText :
                        notifType === 'stamped' ? stampedioText : 
                        notifType === 'reviewio' ? 'reviewed us on Review.io':
                        notifType === 'trustpilot' ? 'reviewed us on TrustPilot':
                        notifType === 'capterra' ?'reviewed us on Capterra' : 
                        gglReviewText
                        }
                    </p>
                </Box>
            </Box>

            <div className={classes.border}></div>

            <Box className={classes.lowerPart}>
                <Box className={classes.footerLeft}>
                    {/*<p className={classes.footerLeftText}>
                        9 mins ago
                    </p>*/}
                    <Box className={classes.footerLogoContainer}>
                        <img
                            className={classes.footerLogo}
                            src={reviewSettings.notificationDisplayApp === 'google' ? 
                            "https://storage.googleapis.com/influence-197607.appspot.com/googlereview.png" : 
                            reviewSettings.notificationDisplayApp === 'facebook' ? Facebookpop :
                            reviewSettings.notificationDisplayApp === 'stamped' ? 'https://api.useinfluence.co/images/stamped.png' :
                            reviewSettings.notificationDisplayApp === 'reviewio' ? 'https://api.useinfluence.co/images/reviews-io.png' :
                            reviewSettings.notificationDisplayApp === 'capterra' ? 'https://api.useinfluence.co/images/capterra.png': ""}
                            // reviewSettings.notificationDisplayApp === 'trustpilot' ? 'https://api.useinfluence.co/images/trustpilot.png' : "https://storage.googleapis.com/influence-197607.appspot.com/googlereview.png"}
                            
                        />
                        {
                            reviewSettings.notificationDisplayApp === "google" ?
                                <Box className={classes.footerStartConatainer}>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star-half"></i>
                                    <StarRateIcon fontSize='small'/>
                                    <StarRateIcon fontSize='small'/>
                                    <StarRateIcon fontSize='small'/>
                                    <StarRateIcon fontSize='small'/>
                                </Box> :

                            reviewSettings.notificationDisplayApp === "stamped" ?
                                <Box className={classes.footerStartConatainer}>
                                    <img src={stampedStar} style={{padding:"0px 3px"}}/>
                                    <img src={stampedStar} style={{padding:"0px 3px"}}/>
                                    <img src={stampedStar} style={{padding:"0px 3px"}}/>
                                    <img src={stampedStar} style={{padding:"0px 3px"}}/>
                                   
                                </Box> :
                            reviewSettings.notificationDisplayApp === "trustpilot" ?
                                <Box className={classes.footerStartConatainer}>
                                    <img src={trustPilotStar} style={{height:'12px'}}/>
                                </Box> :

                            reviewSettings.notificationDisplayApp === "capterra" ?
                                <Box className={classes.footerStartConatainer}>
                                    <img src={stampedStar} style={{padding:"0px 3px"}}/>
                                    <img src={stampedStar} style={{padding:"0px 3px"}}/>
                                    <img src={stampedStar} style={{padding:"0px 3px"}}/>
                                    <img src={stampedStar} style={{padding:"0px 3px"}}/>
                                </Box> :
                            reviewSettings.notificationDisplayApp === "reviewio" ?
                                <Box className={classes.footerStartConatainer}>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star-half"></i>
                                    <StarRateIcon fontSize='small'/>
                                    <StarRateIcon fontSize='small'/>
                                    <StarRateIcon fontSize='small'/>
                                    <StarRateIcon fontSize='small'/>
                                </Box> :
                                reviewSettings.notificationDisplayApp === "facebook" ?
                                <Box className={classes.footerStartConatainer}>
                                    <img style={{width: '13px', marginTop: '1px'}} src={fbRecommendation}/>
                                </Box> :
                                <Box className={classes.footerStartConatainer}>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star-half"></i>
                                    <StarRateIcon fontSize='small'/>
                                    <StarRateIcon fontSize='small'/>
                                    <StarRateIcon fontSize='small'/>
                                    <StarRateIcon fontSize='small'/>
                                </Box> 

                        }
                    </Box>
                </Box>
                {
                    reviewSettings.togglePoweredBy && 
                        <Box className={classes.footerTextRight}>
                            <em className={classes.verifiedText}>{reviewSettings.recentText2}</em>
                            {/* <em className={classes.verifiedIcon}><img src={Check}/></em> */}
                            <em className={classes.verifiedIcon}><Check/></em>
                            <em className={classes.influenceText}>{reviewSettings.poweredBy}</em>
                        </Box>
                }
                
            </Box>
                    
        </Box>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        upperPart:{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'left',
            height: '60px'
        },
        imageContainer:{
            width: '41px',
            height: '41px',
            textAlign: 'center',
            marginRight: '10px',
            alignSelf: 'center'
        },
        textContainer:{
            width: '280px',
            // height: '40px',
            alignSelf: 'center'
        },
        border:{
            width: '100%',
            borderTop: '1px dashed rgba(102,102,102,0.04)',
            margin: '0px 5px',
            marginTop: '5px'
        },
        lowerPart:{
            display:'flex',
            justifyContent: 'space-around',
            textAlign: 'center',
            width: '100%',
            padding: '0 5px',
            height: '12px'
        },
        footerLeft:{
            display: 'flex',
            alignItems: 'center'
        },
        footerLeftText:{
            fontSize: '9px',
            color: '#49445f',
            paddingLeft: '5px',
            fontFamily: 'sans-serif',
            letterSpacing: 'normal',
            lineHeight: '1',
            margin: '0'
        },
        footerLogoContainer:{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '7px'
        },
        footerLogo:{
            width: '9px',
            height: '9px'
        },
        footerStartConatainer:{
            fontSize: '7px',
            color: '#ffc136',
            paddingLeft: '4px'
        },
        footerTextRight:{
            paddingRight: '5px',
            lineHeight: '0.8',
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            paddingTop: '3px'
        },
        verifiedText:{
            fontSize: '9px',
            fontWeight: '600',
            fontStyle: 'normal',
            textAlign: 'center',
            color: '#666',
            fontFamily: 'sans-serif',
            wordSpacing: '1px'
        },
        verifiedIcon:{
            fontSize: '11px',
            color: '#0093fb',
            padding: '0 5px',
            marginTop: '-2px'
        },
        influenceText:{
            fontStyle: 'normal',
            fontSize: '9.5px',
            fontWeight: '600',
            fontFamily: 'sans-serif',
            color: '#044fff'
        }
    })    
)

export default ReviewNotificationCard;
