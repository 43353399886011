import React, {useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';
import { makeStyles, createStyles, Box } from '@material-ui/core';
import moment from 'moment'
const _ = require('lodash');



const AnalyticsGraph = (props) => {

    const {selectedDuration, infoNew} = props
    const [newDataState, setNewDataState] = useState({labels:[], datasets: []})


  const checkMonth = (num) => {
    switch (num) {
      case 1:
        return 'Jan';
      case 2:
        return 'Feb';
      case 3:
        return 'Mar';
      case 4:
        return 'Apr';
      case 5:
        return 'May';
      case 6:
        return 'June';
      case 7:
        return 'July';
      case 8:
        return 'Aug';
      case 9:
        return 'Sept';
      case 10:
        return 'Oct';
      case 11:
        return 'Nov';
      case 12:
        return 'Dec';
      default:
        return '';
    }
  }

  


    // const dateLabelGenerator = (numDays) =>{
    //   let currentDate = new Date();
    //   let labelDate = new Date (currentDate.getTime() - ((selectedDuration - numDays) * 24 * 60 * 60 * 1000))
    //   return (labelDate.getDate() + ' ' + checkMonth(labelDate.getMonth()+1))
    // }


    // const dataCreater = (reqNum) =>{
    //   let chartdata = {
    //     labels: [],
    //     datasets: [
    //       {
    //         label: 'Views', 
    //         pointHoverRadius: 30,
    //         pointRadius: 5,
    //         // pointHitRadius: 10,
    //         hoverBackgroundColor: "rgba(69, 250, 24, 0.2)",
    //         backgroundColor: "rgba(69, 250, 24, 0.2)",
    //         hoverBorderColor: "#246201",
    //         // data: Array(selectedDuration).fill(0)
    //         data:[]
    //       },
    //       {
    //         label: 'Hovers', 
    //         pointHoverRadius: 30,
    //         pointRadius: 5,
    //         // pointHitRadius: 10,
    //         hoverBackgroundColor: "rgba(89, 193, 202,0.3)",
    //         backgroundColor: "rgba(89, 193, 202,0.3)",
    //         hoverBorderColor: "#59c1ca",
    //         // data: Array(selectedDuration).fill(0)
    //         data:[]
    //       },
    //       {
    //         label: 'Clicks', 
    //         pointHoverRadius: 30,
    //         pointRadius: 5,
    //         // pointHitRadius: 10,
    //         hoverBackgroundColor: "rgba(192, 66, 190,0.3)",
    //         backgroundColor: "rgba(192, 66, 190,0.3)",
    //         hoverBorderColor: "#c042be",
    //         // data: Array(selectedDuration).fill(0)
    //         data:[]
    //       },
    //       {
    //         label: 'Signups', 
    //         pointHoverRadius: 30,
    //         pointRadius: 5,
    //         // pointHitRadius: 10,
    //         hoverBackgroundColor: "rgba(216, 87, 42,0.3)",
    //         backgroundColor: "rgba(216, 87, 42,0.3)",
    //         hoverBorderColor: "rgb(216, 87, 42)",
    //         // data: Array(selectedDuration).fill(0)
    //         data:[]
    //       },
    //       // {
    //       //   label: 'test', 
    //       //   pointHoverRadius: 30,
    //       //   pointRadius: 5,
    //       //   // pointHitRadius: 10,
    //       //   hoverBackgroundColor: "rgba(223, 255, 15,0.3)",
    //       //   backgroundColor: "rgba(223, 255, 15,0.3)",
    //       //   hoverBorderColor: "rgb(223, 255, 15)",
    //       //   data: []
    //       // }
    //     ]
    //   };
    //   for (var i = 0; i <reqNum; i++ ) {
    //     chartdata.labels.push(dateLabelGenerator(i+1))
    //     chartdata.datasets[0].data.push(
    //       Object.values(infoNew.perDayView)[i] !== undefined ? Object.values(infoNew.perDayView)[i] : 0 
    //     )

    //     chartdata.datasets[1].data.push(
    //       Object.values(infoNew.perDayHover)[i] !== undefined ? Object.values(infoNew.perDayHover)[i] : 0 
    //     )

    //     chartdata.datasets[2].data.push(
    //       Object.values(infoNew.perDayUniqueCick)[i] !== undefined ? Object.values(infoNew.perDayUniqueCick)[i]  : 0
    //     )

    //     chartdata.datasets[3].data.push(
    //       infoNew.clickToSignup[i] !== undefined ? infoNew.clickToSignup[i] : 0
    //     )

    //   }

    //   setNewDataState(chartdata)

    // }


    const coordinatedGenerator =(data) =>{

      const step_1_data = {
        labels: Object.keys(data).map(date=> moment(date.slice(0,10), "YYYY-MM-DD").format("DD MMM")),
        data: Object.values(data)
      }

      let finalData = []
      for (let i=0; i< step_1_data.data.length; i++){
        // finalData.push({[step_1_data.labels[i]]:step_1_data.data[i]})
        finalData.push({x:step_1_data.labels[i], y:step_1_data.data[i]})
      }

      console.log({finalData})
      return finalData
    }


  /**
  * Get range of dates in formatted way as `[DD MMM]`, i.e; (`[01 Dec)`]
  * @param {Date} startDate 
  * @param {Number} numOfDays 
  * @returns {array}
  */
  const labelsGenerator = (startDate, numOfDays) => {
    console.log({startDate, numOfDays})
    const DAY_IN_MS = 24 * 60 * 60 * 1000
    const startDateMs = startDate.getTime()

    // get array of days and map it to Date object
    const rawArr = [...Array(numOfDays).keys()].map(i => new Date(startDateMs + i * DAY_IN_MS).toISOString())

    const finalArr = rawArr.map(date => moment(date.slice(0, 10), "YYYY-MM-DD").format("DD MMM"))
    return finalArr
  }


    const dataCreater = (reqNum) =>{
      let chartdata = {
        labels: labelsGenerator(new Date(Object.keys(infoNew.perDayView)[0]), parseInt(selectedDuration)),
        datasets: [
          {
            label: 'Views', 
            pointHoverRadius: 30,
            pointRadius: 5,
            hoverBackgroundColor: "rgba(69, 250, 24, 0.2)",
            backgroundColor: "rgba(69, 250, 24, 0.2)",
            hoverBorderColor: "#246201",
            data: coordinatedGenerator(infoNew.perDayView)
          },
          {
            label: 'Hovers', 
            pointHoverRadius: 30,
            pointRadius: 5,
            hoverBackgroundColor: "rgba(89, 193, 202,0.3)",
            backgroundColor: "rgba(89, 193, 202,0.3)",
            hoverBorderColor: "#59c1ca",
            data:coordinatedGenerator(infoNew.perDayHover)
          },
          {
            label: 'Clicks', 
            pointHoverRadius: 30,
            pointRadius: 5,
            hoverBackgroundColor: "rgba(192, 66, 190,0.3)",
            backgroundColor: "rgba(192, 66, 190,0.3)",
            hoverBorderColor: "#c042be",
            data:coordinatedGenerator(infoNew.perDayUniqueCick)
          },
          {
            label: 'Signups', 
            pointHoverRadius: 30,
            pointRadius: 5,
            hoverBackgroundColor: "rgba(216, 87, 42,0.3)",
            backgroundColor: "rgba(216, 87, 42,0.3)",
            hoverBorderColor: "rgb(216, 87, 42)",
            data:coordinatedGenerator(infoNew.perDaySignups)
          },
        ]
      };

      setNewDataState(chartdata)

      console.log({chartdata})

    }
   
    useEffect(()=>{
      if (
        infoNew !== undefined &&
        typeof infoNew === 'object'
        // Object.keys(infoNew).includes('perDayView')
      ) {
        if (Object.keys(infoNew.perDayView).length > 0) dataCreater(selectedDuration)
      }
    },[selectedDuration, infoNew])

 
    return (
      <div>
        <Box style={{marginTop: '20px'}}>
          <Line
            data={newDataState}
            height = {80}
            options={{
              scales:{
                yAxes:[{
                  gridLines:{
                    display:false
                  },
                  ticks:{
                    beginAtZero:true,
                    display:true
                  }
                }]
              }
            }}
          />
        </Box>
      </div>

    );
}

const useStyles = makeStyles(
    createStyles({
        

}))

export default AnalyticsGraph;