import React, { memo } from 'react'
import { Box, makeStyles, createStyles, Grid, Typography,  } from '@material-ui/core'
// import TextInput from '../../../../Globals/GlobalComponents/TextInput'
import InputBox from '../../../../Globals/GlobalComponents/InputBox'
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks'
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon'


const FeaturesBox = (props) =>{

	const {
        visitorText, 
        liveVisitorText, 
        setLiveSettings, 
        handlePropertyChange, 
        handlePanelStyleChange,
        liveViewerText,
        // liveFollowerText,
        liveVisitorCount

    } = props

	const classes= useStyles()
	
	return(
		<Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Typography className={classes.boxTitle}>
                        Features
                        {/* <a href={helpArticleLinks.faq.notification.featuresLive} target="_blank">
                            <img src = {HelpQuestion}  style={{ height: "13px",marginLeft:"2%"}}/>
                        </a> */}
                        <InfluenceHelpIcon href={helpArticleLinks.faq.notification.featuresLive} />
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={3}>
                            <Typography variant="body2">
                                Identify User as
                            </Typography>
                        </Grid>
                        <Grid item md={7}>
                            <InputBox
                                name="visitorText"
                                value={visitorText}
                                dispatcher={setLiveSettings}
                                placeholder="People"
                                onChange={handlePropertyChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={3}>
                            <Typography variant="body2">
                                Other Text
                            </Typography>
                        </Grid>
                        <Grid item md={7}>
                            <InputBox
                                name="liveVisitorText"
                                value={liveVisitorText}
                                dispatcher={setLiveSettings}
                                placeholder="Recently Signed Up"
                                onChange={handlePropertyChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={3}>
                            <Typography variant="body2">
                                Live Viewer Text
                            </Typography>
                        </Grid>
                        <Grid item md={7}>
                            <InputBox
                                name="liveViewerText"
                                value={liveViewerText}
                                dispatcher={setLiveSettings}
                                placeholder="are viewing livestream"
                                onChange={handlePropertyChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems='center'>
                        <Grid item md={4}>
                            <Typography variant="body2">
                                Hide Live viewer if less than
                            </Typography>
                        </Grid>
                        <Grid item md={2}>
                            <InputBox
                                name="liveVisitorCount"
                                value={liveVisitorCount}
                                dispatcher={setLiveSettings}
                                onChange={handlePanelStyleChange}
                                type={"number"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
	)
}


const useStyles = makeStyles(() =>
	createStyles({
        input: {
            height: '35px',
            backgroundColor: '#fff',
            // marginTop: '4%',
            border: '1px solid hsl(0,0%,80%)',
            width: '100%',
            borderRadius: '4px',
            padding: '0% 3%',
            fontSize: '13px'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            paddingRight: '5px'
            // paddingBottom: '2%'
        }
	})
)

export default memo(FeaturesBox);
