import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

const useStyles = makeStyles(() => ({
  input: {
    borderRadius: "5px",
    padding: "5px",
    backgroundColor: "#fff",
    border: "1px solid hsl(0,0%,80%)",
    transition: "0.3s",
    width: "100%",
    height:'40px',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '12px',
    margin: '2% auto'
  },
  focus: {
    backgroundColor: "#f5f6f5 !important",
    border: "1px solid #80bdff !important",
    boxShadow: "0 0 0 0.1rem rgba(0, 123, 255, 0.25)"
  }
}));

const InputBoxCopy = props => {
    const classes = useStyles();
    const { maxLength} = props;
  

    return (
      <InputBase
        classes={{ focused: classes.focus, root: classes.input }}
        inputProps={{maxLength: maxLength ? maxLength : 50}}
        {...props}
        
      />
    );  
};

export default InputBoxCopy;
