import React from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Box, Typography, Button } from '@material-ui/core'
import { withStyles } from "@material-ui/core/styles";
import TextInput from  '../../Globals/GlobalComponents/TextInput'
import Alert from '@material-ui/lab/Alert';
import { loginSuccess } from 'ducks/auth';
import { load, loaded } from 'ducks/loading';
import { base } from 'services/api';
import { storeToken } from 'services/Request';
import { Link, browserHistory } from 'react-router';
import { PASSWORD_MAX_LENGTH } from 'services/FormUtils';
import Divider from '@material-ui/core/Divider';




function validate(password, authEmail){
	return{
		password: '',
		passwordConfirmation: '',
		passwordError: '',
		commonError: ''
	}
}

class ResetPassword extends React.Component{

	constructor() {
		super();
		this.state = {
			email: '',
			authEmail: false,
			emailError: '',
			showemailbtn: false,
			usersemail: ''
		};
	}

	handleStateChange = (evt) => {
		this.setState({
			[evt.target.name]: evt.target.value,
			passwordError: '',
			commonError: ''
		});
	}


	handlePasswordAuth = (evt) => {
		if(evt.target.value == '')
			this.setState({passwordError: 'Enter your password'});
	}


	handlePasswordverifyAuth = (evt) => {
		if(evt.target.value == '')
			this.setState({commonError: 'Verification password required'});
	}


	handleSubmit = (evt) => {
		evt.preventDefault();
		
		const { password, passwordConfirmation } = this.state;
		const code = this.props.location.query.code;
		if (!password) {
			return this.setState({passwordError: 'Enter new password'});
		} else if(!passwordConfirmation) {
			return this.setState({commonError: 'Enter verification password'});
		} else if(!code) {
			return this.setState({commonError: 'Invalid link'});
		} else {
			this.props.load();
			const data = {
				'password' :  this.state.password,
				'passwordConfirmation': this.state.passwordConfirmation,
				'code': code
			};

			fetch(base + 'auth/reset-password', {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			})
			.then(res => res.json())
			.then(res => {
				if(res.user && res.jwt) {
					storeToken(res.jwt)
					this.props.loginSuccess(res);
					browserHistory.push('/campaigns');
				} //else if(res.error)
					// toast.info(res.message, toastConfig);
			});

			this.setState({
				password:'',
				passwordConfirmation: ''
			});
		}
		this.props.loaded();
	}

	handleOpenHelp = () =>{
		window.dashly.open()
	}




	render(){
		const {classes, password, passwordConfirmation, passwordError, commonError} = this.props


		return(
			<Box style={{marginTop: '78px', textAlign: 'center', paddignTop: '6%'}}>
				<form onSubmit={this.handleSubmit} method="POST" className={classes.formContaienr}>
					<Typography variant="h3">
						Reset your password
					</Typography>
					<Divider style={{width: '79%', margin: 'auto', marginTop: '10px'}} light/>
					<div className={classes.inputContainer}>
						<div>
							<TextInput
								required
								placeholder="New password"
								type="password"
								name="password"
								value={password}
								onBlur={this.handlePasswordAuth}
								onChange = {this.handleStateChange}
								maxLength={PASSWORD_MAX_LENGTH}
								className={classes.inputStyle}
							/>
							{
								passwordError && 
									<Alert severity="error">{passwordError}</Alert>
							}
						</div>
						<div>
							<TextInput
								required
								placeholder="Verify New Password"
								type="password"
								name="passwordConfirmation"
								value={passwordConfirmation}
								onBlur={this.handlePasswordverifyAuth}
								onChange = {this.handleStateChange}
								maxLength={PASSWORD_MAX_LENGTH}
								className={classes.inputStyle}
							/>
							{
								passwordError && 
									<Alert severity="error">{passwordError}</Alert>
							}
						</div>
						<Button type="submit" variant="contained">
							Reset Password
						</Button>
					</div>

				</form>

				<div className={classes.supportContainer}>
					<Typography variant="h4">
						Trouble logging in?
					</Typography>

					<Typography style={{color:'#4a90e2', cursor: 'pointer'}} onClick={this.handleOpenHelp}>
						Talk to out support
					</Typography>
				</div>
			</Box>
		)
	}
}


const useStyles= () =>({
	formContaienr:{
		width: '350px',
		margin: 'auto',
		paddingTop: '5%'
	},
	inputContainer:{
		padding: '5%',
	},
	inputStyle:{
		margin: '1.2rem 0'
	},
	divider:{
		width: '100%',
		height: '1px',
		color: '#c6c6c6',
		padding: '10px'
	},
	supportContainer:{
		margin: 'auto',
		marginTop: '10%',

	}

})


const mapDispatchToProps = {
	loginSuccess,
	load,
	loaded
};



export default compose(connect(null,mapDispatchToProps, null, {forwardRef: true}),withStyles(useStyles),)(ResetPassword);