import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  makeStyles,
  createStyles,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core/'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import { pixelVerify, getPixelData } from './Action'
import Moment from 'react-moment'


const PixelPageTable = ({ refreshActionHandler }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [openToaster, setOpenToaster] = useState(false)
  const [refreshCall, setRefreshCall] = useState(false)

  const pixelVerifyObj = useSelector((state) =>
    state.getIn(['PixelPageDetails', 'PixelVerification'])
  )
  const pixelDetails = useSelector((state) => state.getIn(['PixelPageDetails', 'PixelDetails']))

  const refreshHandler = () => {
    refreshActionHandler()
    dispatch(pixelVerify(setLoading))
    setLoading(true)
    setRefreshCall(true)
  }

  useEffect(() => {
    setLoading(false)
    refreshCall && setOpenToaster(true)
    setRefreshCall(false)
  }, [pixelVerifyObj])

  const handleToaster = () => {
    setOpenToaster(false)
  }

  useEffect(() => {
    if (!pixelDetails) {
      dispatch(getPixelData())
    }
  }, [])

  return (
    <Box>
      <Snackbar
        autoHideDuration={2000}
        onClose={handleToaster}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openToaster}
      >
        <Alert variant="filled" style={{ background: 'black' }} severity="success">
          Refreshed
        </Alert>
      </Snackbar>

      <Box className={classes.topContainer}>
        <Typography
          style={{
            color: '#000',
            fontSize: '16px',
            fontWeight: '500',
            marginTop: '0',
            fontFamily: 'Poppins',
          }}
        >
          Pixels
        </Typography>

        <Button
          style={{ width: '86px' }}
          variant="contained"
          onClick={refreshHandler}
          disabled={loading}
        >
          {loading ? <CircularProgress className={classes.loader} size={19} /> : <AutorenewIcon />}
        </Button>
      </Box>

      <Box className={classes.tableBox}>
        <TableContainer
          style={{
            maxHeight: '350px',
            boxShadow: '0px -1px 2px 0px rgba(198, 198, 198, 0.32)',
            border: '1px solid rgba(177, 177, 177, 0.3)',
          }}
        >
          <Table stickyHeader aria-label="sticky table" style={{ borderCollapse: 'collapse' }}>
            <TableHead style={{ borderBottom: '1px solid #e8e8e8', backgroundColor: '#fafbfc' }}>
              <TableRow>
                <TableCell className={classes.headerCell}>Website</TableCell>
                <TableCell className={classes.headerCell}>Pixel Status</TableCell>
                <TableCell className={classes.headerCell}>Last Seen</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {pixelDetails !== undefined ? (
                pixelDetails.map((item, index) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={index + 1000}
                    style={{ height: '48px' }}
                  >
                    <TableCell className={classes.primaryCell}>{item.websiteUrl}</TableCell>
                    <TableCell className={classes.secondaryCell}>
                      <span
                        className={item.pixelStatus ? classes.activeClass : classes.inactiveClass}
                      >
                        {item.pixelStatus ? 'Active' : 'Inactive'}
                      </span>
                    </TableCell>
                    <TableCell className={classes.secondaryCellDate}>
                      {item.lastActive && item.lastActive.value ? (
                        <Moment fromNow>{item.lastActive.value}</Moment>
                      ) : (
                        <p style={{ margin: '0', color: '#928b8b' }}>Searching for data...</p>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <Typography
                    style={{
                      paddingLeft: '5%',
                      paddingBottom: '5%',
                      fontSize: '12px',
                      paddingTop: '5%',
                      width: '100%',
                    }}
                  >
                    No Data Available
                  </Typography>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    topContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '2%',
    },
    tableBox: {
      background: '#fff',
    },
    headerCell: {
      textTransform: 'uppercase',
      color: 'rgba(9,30,66,.74)',
      fontWeight: '700 !important',
      padding: '8px 16px !important',
      fontSize: '12px',
      // fontFamily: `${applefont} !important`,
    },
    headerCellLast: {
      color: '#000',
      fontWeight: '600 !important',
      padding: '18px 16px !important',
      fontSize: '10px',
      letterSpacing: '1.57px',
      textTransform: 'uppercase',
    },
    primaryCell: {
      color: 'rgba(9,30,66,.74) !important',
      fontSize: '12px',
      // fontFamily: applefont,
    },
    secondaryCell: {
      color: 'rgba(9,30,66,.74) !important',
      fontSize: '12px',
      // fontFamily: applefont,
      fontWeight: '400',
      paddingLeft: '2rem',
    },
    secondaryCellDate: {
      color: '#32cd32 !important',
      fontSize: '12px',
      // fontFamily: applefont,
      fontWeight: '400',
    },
    editBtn: {
      '&:hover': {
        backgroundColor: '#fff',
        borderRadius: '50%',
      },
    },
    loader: {
      color: '#fff',
    },
    tableRow: {
      borderTop: '1px solid rgba(9,30,66,.12)',
      transition: '0.2s !important',
      '&:hover': {
        backgroundColor: '#ebecf0',
      },
    },
    activeClass: {
      backgroundColor: '#d1f7c4',
      borderRadius: '999px',
      fontSize: '11px',
      padding: '4px 6px',
      fontWeight: '500',
    },
    inactiveClass: {
      backgroundColor: '#ffdce5',
      borderRadius: '999px',
      fontSize: '11px',
      padding: '4px 6px',
      fontWeight: '500',
    },
  })
)

export default PixelPageTable
