import React from 'react';
import { connect } from 'react-redux';
import { verifyUser } from 'ducks/auth';
import { Spinner } from 'components';
import { ToastContainer } from 'react-toastify';
import './VerificationPage.css';

class VerificationPage extends React.Component {
  componentWillMount() {
    if(this.props.params && this.props.params.code)
      this.props.verifyUser(this.props.params.code);
  }

  render() {
    return (
      <div>
        <Spinner loading={true}/>
        <div className="token-container">
          <h1>Verify your account</h1>
        </div>
        <ToastContainer hideProgressBar={true} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  verifyUser
};

export default connect(null, mapDispatchToProps)(VerificationPage);
