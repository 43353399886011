import axios from 'axios';

export const GET_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS';

export const getNotificationSettings = (id) => {
    return(
        async (dispatch) => {
            const res = await axios({
                method: 'GET',
                url: `/configuration/${id}`,
            }).catch(error => {throw error})
            dispatch({type: GET_NOTIFICATION_SETTINGS, payload: res.data})
            return(res);
        }
    );
}