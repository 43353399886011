import { GET_NOTIFICATION_CONFIGURATION } from "./action";
import { fromJS } from 'immutable';

const initialState = fromJS({});

export const NotificationConfiguration = (state = initialState, action) => {
    switch(action.type){
        case GET_NOTIFICATION_CONFIGURATION:
            return state.set('notificationConfiguration', action.payload);
        default:
            return state;
    } 
}