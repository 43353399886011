
/**
 * Get auth token stored in localstorage
 * tokeName => 'authToken'
 * @returns {string} authToken
 */
const getTokens = () =>{
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
    return token
}


export default getTokens