import React, { useReducer, useEffect, useState, useRef, memo } from 'react';
import { Box, makeStyles, createStyles } from '@material-ui/core';
import LiveNotificationCard from './LiveNotificationCard';
import LiveNotificationRules from './LiveNotificationRules';
import NotifSettingsReducer, { initialState } from '../../NotifSettingsReducer';
import { useSelector } from 'react-redux';
import translate from 'translate';
import NotificationCardSkeleton from '../../../../Globals/GlobalComponents/NotificationCardSkeleton';
import NotificationRuleSkeleton from '../../../../Globals/GlobalComponents/NotificationRuleSkeleton';



translate.engine = 'google';
// translate.key = 'AIzaSyDB2KZyXOUPBC3v0SWh7-vBzg_gTlpLc30';
translate.key = process.env.REACT_APP_GOOGLE_TRANSLATE_KEY;


const LiveNotificationSettings  = (props) => {
    const classes = useStyles();
    const {
        handlePropertyChange, 
        handleInputChange, 
        // notificationConfiguration, 
        ruleName,
        campaignInfo,
        notificationType,
        handlePanelStyleChange,
        handleLanguageChange,
        handleNotificationSubmit,
        loading,
        notifBoxBGColor,
        brandingLocked,
        onClose
    } = props;
    const [liveSettings, setLiveSettings] = useReducer(NotifSettingsReducer, initialState);
    const [visitorText, setVisitorText] = useState(liveSettings.visitorText)
    const [liveText, setLiveText] = useState(liveSettings.liveText);
    const [liveVisitorText, setLiveVisitorText] = useState(liveSettings.liveVisitorText);
    const [poweredBy, setPoweredBy] = useState(liveSettings.poweredBy);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        },200)
    },[])

    const notificationSettings = useSelector(state => (state.getIn(['NotificationSettings', 'notificationConfiguration'])))

    useEffect(() => {
        if(notificationSettings !== undefined)
        setLiveSettings({type: 'INIT', data: notificationSettings.find(item => item.notificationType.notificationName === ruleName)})
    },[notificationSettings])

    const lastLangRef = useRef()

    // useEffect(() => {
    //     lastLangRef.current = liveSettings.langName.language;
        
    //     translate(liveSettings.visitorText, {from: lastLang, to: liveSettings.langName.language}).then(
    //         text => {
    //             setVisitorText(text)
    //             // handlePropertyChange('visitorText', text, setLiveSettings)
    //         }
    //     )
    //     translate(liveSettings.liveText, {from: lastLang, to: liveSettings.langName.language }).then(
    //         text => {
    //             setLiveText(text)
    //             // handlePropertyChange('livetext', text, setLiveSettings)
    //         }
    //     )
    //     translate(liveSettings.liveVisitorText, {from: lastLang, to: liveSettings.langName.language }).then(
    //         text => {
    //             setLiveVisitorText(text)
    //             // handlePropertyChange('liveVisitorText', text, setLiveSettings)
    //         }
    //     )
    //     translate(liveSettings.poweredBy, {from: lastLang, to: liveSettings.langName.language }).then(
    //         text => {
    //             setPoweredBy(text)
    //             // handlePropertyChange('poweredBy', text, setLiveSettings)
    //         }
    //     )
        
    // },[
    //     liveSettings.langName.language,
    //     liveSettings.liveText,
    //     liveSettings.visitorText,
    //     liveSettings.liveVisitorText,
    //     liveSettings.poweredBy
    // ])

    const languageChangeHandler = (name, value, dispatcher) =>{
        translate(liveSettings.visitorText, {from: liveSettings.langName.language, to: value}).then(
            text => {
                setVisitorText(text)
                handlePropertyChange('visitorText', text, setLiveSettings)
            }
        )
        translate(liveSettings.liveText, {from: liveSettings.langName.language, to: value }).then(
            text => {
                setLiveText(text)
                handlePropertyChange('livetext', text, setLiveSettings)
            }
        )
        translate(liveSettings.liveVisitorText, {from: liveSettings.langName.language, to: value }).then(
            text => {
                setLiveVisitorText(text)
                handlePropertyChange('liveVisitorText', text, setLiveSettings)
            }
        )
        translate(liveSettings.poweredBy, {from: liveSettings.langName.language, to: value }).then(
            text => {
                setPoweredBy(text)
                handlePropertyChange('poweredBy', text, setLiveSettings)
            }
        )

        handleLanguageChange(name, value, dispatcher)
    }

    const lastLang = lastLangRef.current

    // useEffect(() => {
    //     setLiveText(liveSettings.livetext);
    //     setLiveVisitorText(liveSettings.liveVisitorText);
    //     setPoweredBy(liveSettings.poweredBy)
    // },[liveSettings.langName ? liveSettings.langName.language : 1])

    useEffect(()=>{
        setVisitorText(liveSettings.visitorText)
    },[liveSettings.visitorText])

    useEffect(()=>{
        setLiveText(liveSettings.livetext);
    },[liveSettings.visitorText])
    
    useEffect(()=>{
        setLiveVisitorText(liveSettings.liveVisitorText)
    },[liveSettings.liveVisitorText])
    
    useEffect(()=>{
        setPoweredBy(liveSettings.poweredBy)
    },[liveSettings.poweredBy])

    
    return(
        show ?
            <Box>
                <Box style={{background: notifBoxBGColor}} className={classes.notificationBox} padding='5% 0%'>
                    <LiveNotificationCard
                        liveSettings={liveSettings}
                        visitorText={visitorText}
                        liveText={liveText}
                        liveVisitorText={liveVisitorText}
                        poweredBy={poweredBy}
                    />
                </Box>
                <Box padding='6%'>
                    <LiveNotificationRules
                        handlePropertyChange={handlePropertyChange}
                        handleInputChange={handleInputChange}
                        setLiveSettings={setLiveSettings}
                        liveSettings={liveSettings}
                        handlePanelStyleChange={handlePanelStyleChange}
                        campaignInfo={campaignInfo}
                        handleLanguageChange={languageChangeHandler}
                        notificationType={notificationType.filter(item => item.notificationName === ruleName)}
                        notificationConfiguration={notificationSettings.filter(item => item.notificationType.notificationName === ruleName)}
                        visitorText={visitorText}
                        liveText={liveText}
                        liveVisitorText={liveVisitorText}
                        poweredBy={poweredBy}
                        handleNotificationSubmit={handleNotificationSubmit}
                        loading={loading}
                        brandingLocked={brandingLocked}
                        onClose={onClose}
                    />
                </Box>
            </Box>:
            
            <Box>
            <Box style={{background: notifBoxBGColor}} className={classes.notificationBox} padding='5% 0%'>
                <NotificationCardSkeleton />
            </Box>
            <Box padding="6%">
                <NotificationRuleSkeleton />
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        notificationBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '7%',
            position: 'sticky',
            top: '50px',
            zIndex: '9999',
            paddingBottom: '11%',
            transition: 'all 0.7s ease-in-out',
            backdropFilter: "blur(0.8px)"
        },
        loaderBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }
    })
)

export default memo(LiveNotificationSettings);