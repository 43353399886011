import React, {Component} from 'react';
import Select from 'react-select';


const customStyle = {
  control: base => ({
    ...base,
    background: '#fff',
    // color:'#fff',
    minHeight:'40px',
    height:'40px',
    cursor: 'pointer',
    fontSize: 12,
    marginTop:'2%',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'
  }),
  singleValue: base => ({
    ...base,
    top: '47%'
  }),
  menu: styles => ({ 
    ...styles, 
    zIndex: 999,
    fontSize: 12,
    fontFamily:'--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'

  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  IndicatorSeparator:()=>({display:'none'})
};


class ReactSelect extends Component{

  valueSetter = (value, options) =>{
    if(typeof (value) === 'string'){
      for(let i =0; i<options.length ; i++){
        if(value=== options[i].value){
          return options[i]
        }
      }
    } 
  }

//   testChange = (e) => {
//       console.log("event",e);
//   }

  render(){
    const {onChange, placeholder, options, name,dispatcher,value, isSearchable=true, className} = this.props;

    return(
      <Select 
        name={name}      
        styles={customStyle}
        isSearchable = {isSearchable}
        onChange={(event) => onChange(name, event.value, dispatcher)}
        // onChange= {(event) => this.testChange(event)}
        value={this.valueSetter(value, options)}
        placeholder={placeholder ? placeholder : 'Select'}
        components={
          {
            IndicatorSeparator: () => null,
          }
        }
        options={options}
        className={className}
      />
    );
  }
}

export default ReactSelect;