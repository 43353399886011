import React, { lazy, Suspense } from 'react'
import { Router, Route, withRouter } from 'react-router'
import { Switch, BrowserRouter } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import {
  ForgetPassword,
  ResetPassword,
  Error,
  WebsitePayment,
  VerificationPage,
  ReviewRedirect,
  PixelPage,
  CustomLoading,
  GettingStarted1,
  GoalsAnalyticsHeader,
  GoalListContainer,
  NewCampaignCreation,
  AnalyticsHeader,
  SettingsPage,
  ProfileSettingsPage,
  PricingDialog1,
  AweberAuthCode,
  SidePanel,
  SidePanel2,
  WebsiteCheckout,
  CampaignSettingsPanel,
  UpGradePlanPage3
} from 'components'

import {
  App,
  DashboardContainer,
  ConnectPage,
  SocialProofContainer,
  CookieContainer,
  PolicyGenContainer,
  // AnalyticsContainer,
  // OauthContainer
} from 'containers'

import {
  Apperance,
  AutoBlocking,
  CampaignSetting_C,
  CookieCampaignCreation,
  CookieListContainer,
  CookiePixels,
  Data,
  DetailsContainer,
  MicroPolicies,
  PolicySettingsContainer,
  ThirdParties,
  PolicyDelete,
  CookieCampaignList,
} from './components/CookieConcent'

import Tails from './components/PolicyGenerator/tail/Tails'
// import { Policies, PolicyPixelPage, Step_1, Step_2 } from 'components/PolicyGenerator'
import { PolicyPage } from './components/PolicyGenerator'
import LockedPolicyPage from './components/PolicyGenerator/Policies/LockedPolicyPage'

const CampaignListContainer = lazy(() => import('./components/CampaignList/CampaignListContainer'))
// const CampaignSettingsPanel = lazy(() => import('./components/CampignSettingsPanel/CampaignSettingsPanel'));
const WebsiteSignInNew = lazy(() => import('./components/WebsiteSignIn/WebsiteSignInNew'))
const WebsiteSignUpNew = lazy(() => import('./components/WebsiteSignUp/WebsiteSignUpNew'))
const WebsiteSignUp = lazy(() => import('./components/WebsiteSignUp/WebsiteSignUp'))

const MyRoutes = ({ routerHistory }) => {
  return (
    <BrowserRouter>
      <Router history={routerHistory}>
        <Route component={DashboardContainer}>
          <Switch>
            <Route component={SocialProofContainer}>
              <Route exact path="/campaigns" component={CampaignListContainer} />
              <Route path="/campaigns/:id" component={CampaignSettingsPanel} />
              <Route path="/analytics/:id" component={AnalyticsHeader} />
              <Route path="/goals" component={GoalListContainer} />
              <Route path="/goalsanalytics/:id" component={GoalsAnalyticsHeader} />
              <Route path="/installPixelPage" component={PixelPage} />
              <Route path="/getting-started" component={GettingStarted1} />
            </Route>

            <Route path="/new" component={NewCampaignCreation} />
            {/* <Suspense fallback={<div><h1>loding</h1></div>}> */}
            {/* </Suspense> */}

            <Route path="/profile" component={ProfileSettingsPage} />
            <Route path="/settings" component={SettingsPage} />
            {/* <Route path="/upgrade-plan" component={PricingDialog1} /> */}
            <Route path="/upgrade-plan" component={UpGradePlanPage3} />
            <Route path="/aweberAuth" component={AweberAuthCode} />
            <Route path="/billing-details" component={SidePanel} />
          </Switch>

          <Route component={CookieContainer}>
            <Switch>
              <Route path="/cookie-monster/campaigns" component={CookieCampaignList} />
              <Route component={CampaignSetting_C}>
                {/* <Route path="/cookie-monster/:id/overview" component={Overview} /> */}
                <Route path="/cookie-monster/:id/appearance" component={Apperance} />
                <Route path="/cookie-monster/:id/micro-policies" component={MicroPolicies} />
                <Route component={PolicySettingsContainer}>
                  <Route
                    path="/cookie-monster/:id/micro-policies/:policyId/details"
                    component={DetailsContainer}
                  />
                  <Route
                    path="/cookie-monster/:id/micro-policies/:policyId/data"
                    component={Data}
                  />
                  <Route
                    path="/cookie-monster/:id/micro-policies/:policyId/thirdparties"
                    component={ThirdParties}
                  />
                  <Route
                    path="/cookie-monster/:id/micro-policies/:policyId/delete"
                    component={PolicyDelete}
                  />
                </Route>
                <Route path="/cookie-monster/:id/autoblocking" component={AutoBlocking} />
              </Route>
              <Route path="/cookie-monster/pixels" component={CookiePixels} />

              <Route path="/cookie-monster/new-campaign/:id" component={CookieCampaignCreation} />
              <Route path="/cookie-concent" component={CookieListContainer} />
              <Route path="/micro-policies" component={MicroPolicies} />
            </Switch>
          </Route>

          <Route component={PolicyGenContainer}>
            <Switch>
              {/* <Route path="/policy-generator/policies" component={Policies} /> */}
              <Route path="/policy-generator/policies" component={LockedPolicyPage} />

              {/* <Route path="/policy-generator/pixels" component={PolicyPixelPage} />
              <Route path="/policy-generator/new/step-1" component={Step_1} />
              <Route path="/policy-generator/new/step-2/:id" component={Step_2} />
              <Route path="/policy-generator/edit/:id" component={Step_2} /> */}
            </Switch>
          </Route>
        </Route>

        {/* <Route exact path="/oauth/:type" component={OauthContainer} /> */}
        <Route exact path="/connect/:provider" component={ConnectPage} />
        <Route exact path="/integrations/:provider/callback/" component={ReviewRedirect} />
        <Route exact path="/verify/:code" component={VerificationPage} />
        <Route exact path="/signin" component={WebsiteSignInNew} />
        <Route path="/install-pixel" component={SidePanel2} />

        <Route component={App}>
          <Switch>
            {/*
              <Route path="/" component={WebsiteSignIn} />
              <Route path="/login" component={WebsiteSignIn} />
              <Route path="/signup" component={WebsiteSignUp} />
              <Route path="/getting-started" component={LoginFlow} />
              <Route path="/sumo-influence-123" component={AppsumoSingUp} />
            */}
            <Route path="/policy-generator/privacy-policy" component={PolicyPage} />
            <Route path="/" component={WebsiteSignInNew} />
            <Route path="/login" component={WebsiteSignInNew} />
            <Route path="/signup" component={WebsiteSignUp} />
            <Route path="/checkout" component={WebsitePayment} />
            <Route path="/checkoutpage" component={WebsiteCheckout} />
            <Route path="/forgot-password" component={ForgetPassword} />
            <Route path="/reset-password" component={ResetPassword} />
          </Switch>
        </Route>
        <Route path="*" component={Error} />
      </Router>
    </BrowserRouter>
  )
}

export default withRouter(MyRoutes)
