import React from 'react'
import { Tooltip, makeStyles} from '@material-ui/core'


const useStylesTooltip = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}));


/**
 * Custom tooltip
 * @param {Object} props Takes props of `Tooltip` of material ui
 */
const CustomTooltop =(props)=> {
    const classes = useStylesTooltip();
    return <Tooltip arrow classes={classes} {...props} />;
}



export default CustomTooltop