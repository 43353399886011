import React, { useState } from 'react'
import { Box, makeStyles, createStyles, Typography, Button } from '@material-ui/core'
import { useDispatch,useSelector } from 'react-redux'
import { cookiePublishAction } from '../actions'
import LoadingButton from '../../../Globals/GlobalComponents/LoadingButton'
import {
    DialogBox,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogButton
  } from '../../../Globals/GlobalComponents/DialogBox'
  import {browserHistory} from 'react-router';
import Moment from 'react-moment'



const CookieSubNavigationBar  = () => {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const dispatch = useDispatch()
    const user = useSelector(state =>(state.getIn(["auth", "user"])))
    const currentCampaign = useSelector(state => (state.getIn(['cookieCampaigns', 'openedCookieCampaign'])))


    const publishHandler = () =>{
        setLoading(true)
        dispatch(cookiePublishAction(currentCampaign.profile, user.trackingId, currentCampaign.websiteUrl, afterActions))
        // setOpenDialog(true)
    }

    const afterActions = (error, res)=>{
        setLoading(false)
        if(error){
            return
        }else{
            setOpenDialog(true)
        }
    }

    const handleCloseDialog = ()=>{
        setOpenDialog(false)
        browserHistory.push('/cookie-monster/campaigns')
    }

    return(
        <Box className={classes.mainContainer}>
            <Box>
                <Typography style={{}}>
                    {currentCampaign.campaignName}
                </Typography>
            </Box>
            <Box className={classes.leftContainer}>
                <Box className={classes.dateContainer}>
                    <Typography className={classes.dateLabel}>
                        Creation Date:
                    </Typography>
                    <Typography className={classes.dateText}>
                        <Moment format="MMM D, YYYY" date={currentCampaign?.createdAt}/>
                    </Typography>
                    <Typography className={classes.dateLabel}>
                        Last Updated:
                    </Typography>
                    <Typography className={classes.dateText}>
                        <Moment format="MMM D, YYYY" date={currentCampaign?.updatedAt}/>
                    </Typography>
                </Box>
                <LoadingButton onClick={publishHandler} variant="contained" loading={loading} style={{width:'83px'}}>
                    Publish
                </LoadingButton>
            </Box>
            <DialogBox onClose={handleCloseDialog} open={openDialog}>
                <DialogTitle onClose={handleCloseDialog}>
                    Campaign is Live!
                </DialogTitle>
                <DialogContent>
                    Congratulations! Your campaign is live now.
                </DialogContent>
                <DialogActions>
                    <DialogButton actionType={"primary"} onClick={() => browserHistory.push('/cookie-monster/campaigns')}>Close</DialogButton>
                </DialogActions>
            </DialogBox>
        </Box>
        
    )
}

const useStyles = makeStyles(theme =>
    createStyles({
        mainContainer:{
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between',
        },
        leftContainer:{
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between',
        },
        dateContainer:{
            display:'flex',
            alignItems:'center',
        },
        dateLabel:{
            color:'#484848',
            fontSize:'12px',
            marginRight:theme.spacing(2)
        },
        dateText:{
            color:'#7e7e7e',
            fontSize:'12px',
            marginRight:theme.spacing(8)
        }
    })
)

export default CookieSubNavigationBar