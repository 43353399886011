import React, { memo, Fragment } from 'react';
import { Box, makeStyles, createStyles, Grid, Checkbox, Typography } from '@material-ui/core';
import InputBox from '../../../../Globals/GlobalComponents/InputBox';
import UpgradeBanner from '../../../../Globals/GlobalComponents/UpgradeBanner'
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';



const AdditionalFeaturesBox  = (props) => {
    const classes = useStyles();

    const {
        recentText2,
        poweredBy,
        poweredByLink,
        handlePropertyChange,
        handleInputChange,
        setRecentSettings,
        togglePoweredBy,
        brandingLocked
    } = props;

    // const [additionalFeaturesCheck, setAdditionalFeaturescheck] = useState(false);

    // const handleAdditionalCheck = () => {
    //     if(additionalFeaturesCheck === false) {
    //         setAdditionalFeaturescheck(true);
    //     } else setAdditionalFeaturescheck(false)
    // }

    return(
        <Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            {
                brandingLocked &&
                    <UpgradeBanner message={"Upgrade to Advanced Plan to Edit Branding"}/>
            }
            <Grid container spacing={1}>
                <Grid item md={12} style={{paddingLeft: '0'}}>
                    <Grid container alignItems='center' >
                        <Grid item md={1}>
                            <Checkbox 
                                checked={togglePoweredBy}
                                name="togglePoweredBy"
                                onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, setRecentSettings)}
                                disabled = {brandingLocked}
                            />
                        </Grid>
                        <Grid item md={7}>
                            <Typography className={classes.boxTitle}>
                                Notification Branding

                                {/* <a href={helpArticleLinks.faq.notification.branding} target="_blank">
                                    <img src = {HelpQuestion}  style={{ height: "13px",marginLeft:"2%"}}/>
                                </a> */}
                                <InfluenceHelpIcon href={helpArticleLinks.faq.notification.branding} />
                                
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {togglePoweredBy &&
                    <Fragment>
                        <Grid item md={12}>
                            <Grid container alignItems="center">
                                <Grid item md={3}>
                                    <Typography variant="body2">'By" Text</Typography>
                                </Grid>
                                <Grid item md={9}>
                                    <InputBox
                                        name="recentText2"
                                        dispatcher={setRecentSettings}
                                        value={recentText2}
                                        placeholder={"by"}
                                        onChange={handlePropertyChange}
                                        disabled = {brandingLocked}
                                        maxLength={14}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12}>
                            <Grid container alignItems="center">
                                <Grid item md={3}>
                                    <Typography variant="body2">Powered by</Typography>
                                </Grid>
                                <Grid item md={9}>
                                    <InputBox
                                        name="poweredBy"
                                        dispatcher={setRecentSettings}
                                        placeholder={"influence"}
                                        value={poweredBy}
                                        onChange={handleInputChange}
                                        disabled = {brandingLocked}
                                        maxLength={18}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12}>
                            <Grid container alignItems="center">
                                <Grid item md={3}>
                                    <Typography variant="body2">Powered by Link</Typography>
                                </Grid>
                                <Grid item md={9}>
                                    <InputBox
                                        name="poweredByLink"
                                        dispatcher={setRecentSettings}
                                        value={poweredByLink}
                                        onChange={handleInputChange}
                                        disabled = {brandingLocked}
                                        maxLength={150}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fragment>    
                }
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        input: {
            height: '35px',
            //width:'120px',
            backgroundColor: '#fff',
            marginTop: '4%',
            border: ' 0.5px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            padding: '0% 3%',
            width:'100%'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            // paddingBottom: '5%'
        },
    })
)

export default memo(AdditionalFeaturesBox);