import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';




function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}


/**
 * 
 * @param {Object} props custom toasrt props
 */
const CustomToast = (props) => {
    const {open, message, severity="success", closeHandler} = props
    return (
        <Snackbar 
            open={open} 
            autoHideDuration={3000}
            onClose={()=>closeHandler(false)}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
        >
            <Alert 
                onClose={()=>closeHandler(false)} 
                variant="filled" 
                style={{background:'black'}} 
                severity={severity}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

CustomToast.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    severity: PropTypes.string,
    closeHandler: PropTypes.func.isRequired
}


export default CustomToast
