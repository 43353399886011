import React, {useEffect, useState} from 'react';
import { Box, makeStyles, createStyles, Typography, Paper, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Moment from 'react-moment'
// import {trashAlt, TrashIcon} from '../../assets/assets'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { deleteSignupData } from './action'
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {Trash} from '../../img'
const applefont = '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'


//ProfileImg, Username,  rating, fromApp, PageName, timestamp
const ReviewList  = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {reviewData, campaignAnalyticsData} = props
    const [openToaster,setOpenToaster] = useState(false);


    const dateFormatter = (date) => {
		return date.slice(0,10)
	}

    // const deleteReviewHandler = (id, type, trackingId)=>{
    //     dispatch(deleteReviewData(id, type, trackingId, campaignAnalyticsData,setOpenToaster))

    // }

    const handleClose = () =>{
        setOpenToaster(false);
    }
    return(
        <TableContainer style={{maxHeight:'65vh',padding:"0%", boxShadow: '0px -1px 2px 0px rgba(198, 198, 198, 0.32)', marginTop: '2rem', marginRight: '5%', width: '95%'}} >
			<Table stickyHeader>

				<TableHead style={{borderBottom: '1px solid #e8e8e8', backgroundColor: '#fafbfc', }}>
					<TableRow>
						<TableCell className={classes.tableHead} align={reviewData && reviewData.length>0 ? "center" : "left"}>Profile</TableCell>
						<TableCell className={classes.tableHead} align="left">Username</TableCell>
						<TableCell className={classes.tableHead} align="center">Rating</TableCell>
						<TableCell className={classes.tableHead} align="left">fromApp</TableCell>
                        <TableCell className={classes.tableHead} align="left">Page Name</TableCell>
                        <TableCell className={classes.tableHead} align="left">Date</TableCell> 
                       
					</TableRow>
				</TableHead>

				<TableBody>
					{
						reviewData && reviewData.length>0 ?
							reviewData.map((data, index)=>(
								<TableRow className={classes.tableRow} key={index}>
									<TableCell className={classes.TableCellMain} align="center">
										<span style={{borderRadius: '50%',
                                            flexShrink: '0',
                                            fontSize: '20px',
                                            height: '36px',
                                            marginRight: '8px',
                                            position: 'relative'}}>
                                            <img style={{background: '#f5f7f9',
                                                border: '1px solid rgba(9,30,66,.12)',
                                                height: '36px',
                                                width: '36px',
                                                borderRadius: '50%'
                                            }} src={data._source.profileImg ? data._source.profileImg : 
                                               "https://assetscdn-web.freshchat.com/agent/static/assets/images/agent_default-287d55bc513b4a4a7c028f1ee0956a7b.svg"} /></span>
										<p className={classes.emailStyle}>{data._source.email}</p>
									</TableCell>
                                    <TableCell className={classes.TableCell} align="left">{data._source.username}</TableCell>
                                    <TableCell className={classes.TableCell} align="center">{data._source.rating}</TableCell>
                                    <TableCell className={classes.TableCell} align="left">{data._source.fromApp}</TableCell>
                                    <TableCell className={classes.TableCell} align="left">{data._source.pageName}</TableCell>
									<TableCell className={classes.TableCell} align="left"><Moment format="MMM D, YYYY" date={dateFormatter(data._source.timestamp)}/></TableCell>
                                    {/*<TableCell className={classes.TableCell}>
                                        <TrashIcon width={14} height={14}/>
                                    </TableCell>
                                    <TableCell className={classes.TableCell}>
                                        <img src={trashAlt}/>
                                    </TableCell>*/}
                                   {/*
                                    <TableCell className={classes.TableCell}>
                                        <IconButton className={classes.deletIconBtn} onClick={()=>{deleteSignupHandler(data._id, data._type, data._source.trackingId)}}>
                                           <img src={Trash} style={{height:'13px'}}/>
                                            <Snackbar 
                                                open={openToaster}
                                                autoHideDuration={5000} 
                                                key={campaignAnalyticsData._id}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}
                                            >
                                                <Alert variant="filled" style={{background:'black'}}>
                                                    <div style={{display:'flex',width:'auto'}}
                                                        {/* <div><DoneAllRoundedIcon style={{color:'#00b300',fontSize:'19px'}}/></div> 
                                                        <div style={{fontSize:'11x',paddingLeft:'6px',fontWeight:'400'}}>Deleted Successfully</div>
                                                    </div>
                                                </Alert>
                                            </Snackbar>
                                        </IconButton>
                                    </TableCell>
                                */}
                                </TableRow>
                                           
							)):
							<TableRow>
                                <Typography style={{paddingLeft:'6%',paddingBottom:'5%',paddingTop:'5%', fontSize:'12px',width:"100%",lineHeight:'1'}}>No Review Available</Typography>
							</TableRow>
					}
				</TableBody>
			</Table>
        </TableContainer>
        
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
		tableHead: {
            color: 'rgba(9,30,66,.74)',
            fontWeight:'700 !important',
            padding : '8px 16px !important',
            fontSize: '11px',
            textTransform: 'uppercase',
            fontFamily: `${applefont} !important`
        },
        tableRow: {
            borderTop: '1px solid rgba(9,30,66,.12)',
            transition: '0.2s !important',
            backgroundColor: '#fff',
            "&:hover": {
                backgroundColor: "#ebecf0"
            }
        },
        TableCell: {
            fontSize:'14px !important',
            textTransform:'capitalize',
            color:'rgba(9,30,66,.87) !important',
            letterSpacing:'normal',
            fontFamily: applefont,
            fontWeight: '400'
        },
        TableCellMain: {
            fontSize:'14px !important',
            textTransform:'capitalize',
            color:'rgba(9,30,66,.87) !important',
            letterSpacing:'normal',
            fontFamily: applefont,
            fontWeight: '500'
        },
        emailStyle:{
			fontSize:'11px !important',
            color:'rgba(9,30,66,.87) !important',
            letterSpacing:'normal',
            fontFamily: applefont,
            fontWeight: '400',
            textTransform: 'lowercase',
            margin: '0 !important'
        },
        signupPill:{
			backgroundColor: '#cfdfff',
			borderRadius: '999px',
			fontSize: '11px',
			padding: '4px 6px',
			fontWeight: '500',
        },
        deletIconBtn:{
            color: 'gray',
            fontSize: '1.2rem',
            borderRadius: '50%',
            active:{
                fontSize: '1.0rem',
            }
        }
    })
)


export default ReviewList ;