import React, { useState, useEffect } from 'react';
import { Box, makeStyles, createStyles, Typography, Paper, Divider, Button,Snackbar } from '@material-ui/core';
import TextInput from '../../Globals/GlobalComponents/TextInput';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { getCampaignInfo } from '../CampaignList/action';
import { browserHistory } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Check} from '../../img'

import Alert from "@material-ui/lab/Alert";
import helpArticleLinks from '../../Resources/helpArticleLinks';
import mixpanel from 'mixpanel-browser'

const NewCampaignCreation  = (props) => {
    const classes = useStyles();


    const {closeHandler,handleClose} = props;
    const [campaignName, setCampaignName] = useState("");
    const [websiteUrl, setWebsiteUrl] = useState("");
    const [newCampaignData, setNewCampaignData] = useState("");
    const [loading, setLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastOpening, setToastOpening] = useState(false);


    const profile = useSelector(state => (state.getIn(['profile', 'profile'])));
    const campaignInfo = useSelector(state => (state.getIn(['CampaignInfo', 'campaignInfo']))) || []
    const dispatch = useDispatch()

    const handleCampaignName = (value) => {
        setCampaignName(value);
    }
 
    const handleWebsiteUrl = (value) => {
        setWebsiteUrl(value)

    }

    var websiteUrl1 = (((websiteUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0]).trim()).split(" ")).join("")
    const handleCreate = async (e) => {
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
        e.stopPropagation(); 
        e.preventDefault();
        setLoading(true);

        const res = await axios({
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}` },
            url: '/campaign',
            data: {
                campaign : {
                    campaignType: "default",
                    campaignName: campaignName,
                    websiteUrl: websiteUrl1,
                    goalname: "",
                    goalurl: "",
                    profile: profile._id
                }
            }
        })
        .catch(err => {throw err})
        dispatch(getCampaignInfo(setLoading))
        setNewCampaignData(res.data.data)
        setToastOpening(true)
        setToastMessage("Campaign Created")
        mixpanel.track('Campaign Creation', {
          campaignId: res.data.data._id,
          campaignName: res.data.data.campaignName,
        })
       
        // if(campaignInfo.length < getPermissions(profile.plan.id).noOfCampaignAllowed){
        //     //handleCreate()
        //     const res = await axios({
        //         method: 'POST',
        //         headers: { 'Authorization': `Bearer ${token}` },
        //         url: '/campaign',
        //         data: {
        //             campaign : {
        //                 campaignType: "default",
        //                 campaignName: campaignName,
        //                 websiteUrl: websiteUrl1,
        //                 goalname: "",
        //                 goalurl: "",
        //                 profile: profile._id
        //             }
        //         }
        //     })
        //     .catch(err => {throw err})
        //     dispatch(getCampaignInfo(setLoading))
        //     setNewCampaignData(res.data.data)
        //     setToastOpening(true)
        //     setToastMessage("Campaign Created")
        //     mixpanel.track('Campaign Creation', {
        //       campaignId: res.data.data._id,
        //       campaignName: res.data.data.campaignName,
        //     })
        //     //handleClose()
        //     //handleCampaignName("")
        //     //handleWebsiteUrl("")
        // }
        // else{
        //     setLoading(false);
        //     setToastOpening(true)
        //     setToastMessage("Only one campaign is allowed in your plan")
        //     handleCampaignName("")
        //     handleWebsiteUrl("")
           
        // }
       
       
    
    }
    //for create campaign
    useEffect(() => {
        if(campaignInfo.length && newCampaignData !== "") {
            handleClose()
            handleCampaignName("")
            handleWebsiteUrl("")
            browserHistory.push(`/campaigns/${newCampaignData._id}`)
            setNewCampaignData("")
        }
    },[campaignInfo])

    

    const handleCloseToast = () =>{
       
        setToastOpening(false)
    }


    return(
      
            <Box component={Paper} padding='20px 80px' style={{borderBottom:"0px"}}>
            <Snackbar 
            open={toastOpening}
            autoHideDuration={5000} 
            onClose={handleCloseToast}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
        >
            <Alert variant="filled" style={{background:'black'}}>
                <div style={{display:'flex',width:'auto'}}>
                    {/* <div><DoneAllRoundedIcon style={{color:'#00b300',fontSize:'19px'}}/></div> */}
                    <div style={{fontSize:'11x',paddingLeft:'6px',fontWeight:'400'}}>{toastMessage}</div>
                </div>
            </Alert>
        </Snackbar>
                <Box padding="30px 0px">
                    <Typography variant="h3" className={classes.heading}>Create Your Campaign</Typography>
                </Box>
                
                <Divider />
                <form onSubmit={handleCreate}>
                
                <Box display='flex' justifyContent='space-between' style={{backgroundColor:"#ebf8ff",border: '0px solid #d2d6dc'}}>
                    <Box width='100%' padding='10px'>
                        <Typography className={classes.stripeLeft}></Typography>
                    </Box>
                    <Box width='100%' padding='10px'>
                        <Typography className={classes.stripeText}>
                            
                            <a style={{textDecoration: 'none', color:'#007bff'}} target="_blank" href={helpArticleLinks.installation.step2}>
                                {/*<img src={Check}/>*/}
                                Read the document to get started 
                            </a>
                        </Typography>
                    </Box>
                </Box>
                <Box display='flex' justifyContent='space-around'>
                    <Box width='100%' paddingBottom="0px" paddingTop="40px" paddingRight="10px" paddingLeft="10px">
                        <Typography className={classes.inputHeading}>Campaign Name</Typography>
                        <TextInput 
                            className={classes.input}
                            value={campaignName}
                            placeholder = {"Name of Your Campaign"}
                            onChange={(event) => handleCampaignName(event.target.value)}
                        />
                    </Box>
                    <Box width='100%' paddingBottom="0px" paddingTop="40px" paddingRight="10px" paddingLeft="10px">
                        <Typography className={classes.inputHeading}>Website url</Typography>
                        <TextInput 
                            className={classes.input}
                            value={websiteUrl}
                            placeholder = {'URL of Customer Facing Website'}
                            onChange={(event) => handleWebsiteUrl(event.target.value)}
                        />
                    </Box>
                </Box>

                <Box padding='40px 0px' display='flex' justifyContent='flex-end'>

                    <Button variant="outlined" onClick={closeHandler} className={classes.button1}>Cancel</Button>
                    <Button 
                        variant="contained"
                        color="primary" 
                        className={classes.button}
                        onClick={handleCreate}
                        disabled={campaignName !== "" && websiteUrl !== "" && loading !== true ? false : true}
                        type="submit"
                    >
                        {loading === true ? <CircularProgress size={24} classes={{colorPrimary: classes.circularProgress}} /> : 'Create'}
                       
                    </Button>
                    
                   
             
                </Box>

               
                {/*
                 <Divider />
                     <Box padding='30px 0px'>
                    <Typography className={classes.heading}>Some important pointers before you start:</Typography>
                    <Box padding='10px 10px'>
                        <Typography className={classes.points}>1. We recommend you to Install your Unique Pixel first, if you haven't done it already.</Typography>
                        <Typography className={classes.points}>2. You can always leave a campaign midway & come back later for editing.</Typography>
                        <Typography className={classes.points}>3. Don't worry if your campaign doesn't come out to be perfect in the first time, a campaign can be edited infinite times after publishing.</Typography>
                        <Typography className={classes.points}>4. Most of the settings are pre-set to save your time, but you can play with everything like 'popup styling', 'timing behaviors' & other 'settings'.</Typography>
                        <Typography className={classes.points}>5. 'Display Pages' step - Notification Popups will run on any webpage which you will connect here.</Typography>
                    </Box>
                </Box>
                */}
               
                </form>
            </Box>
     
    );
}

const useStyles = makeStyles(() =>
    createStyles({

        stripeText:{
            color:'#097fff',
            fontSize: '12px',
            float: 'right'
        },
        stripeLeft:{
            color:'#097fff',
            fontSize: '12px',
            float: 'left'
        },
        buttonBox: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            height: '100px',
            marginRight:'7%',
            textTransform:'initial'
            
        },
        button1: {
            height: '32px',
            width:'100px',
            paddingTop:'1%',
            marginRight: '12px',
        },
        heading: {
            fontWeight: 'bold'
        },
        input: {
            width: '100%',
            height: '39px',
            backgroundColor: '#fff',
            marginTop: '1.5%',
            fontSize:'12px !important',
            paddingLeft: '10px',
            border: '1px solid hsl(0,0%,80%)'
        },
        inputHeading: {
            fontSize: '14px',
            paddingLeft: '3px'
        },
        button:{
              width: '100px',
              color:'white',
              fontSize:'12px !important',
              backgroundColor:'#2c66c3',
              textTransform:'initial',
            "&:hover": {
                backgroundColor: '#091e42de'
            },
        },
        points: {
            fontSize: '13px !important',
            color: '#424242 !important',
            letterSpacing: '0.8px !important',
            // fontWeight: '500'
        },
        circularProgress: {
            color: '#fff',
            position:'absolute',
            top:'52%',
            left:'52%',
            marginTop:-12,
            marginLeft:-12
        },
    })
)

export default NewCampaignCreation ;
