import { Box, Divider } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'


const MicropoliciesSkeleton = () =>{
  return(
    <Box>
        <Skeleton height={100}/>
        <Skeleton height={100}/>
        <Skeleton height={100}/>
        <Skeleton height={100}/>
    </Box>
  )
}

export default MicropoliciesSkeleton