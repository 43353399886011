import React, { useEffect, useState } from 'react';
import {browserHistory} from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Box from '@material-ui/core/Box';
import { fetchUserPixelStatus, userPathChange } from '../../action';
import { fetchUser } from '../../../../ducks/auth';
import PixelNavigation, {TabPanel} from './PixelNavigation';
import PixelTabPanels from './PixelTabPanels';
import PixelSidebar from './PixelSideBar';
import LoadingButton from '../../../../Globals/GlobalComponents/LoadingButton'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';



const PixelScreen =()=> {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false)
  const [verified, setVerified] = useState(false)
  const [openToaster, setOpenToaster] = useState(false)
  const userdetails = useSelector(state => (state.getIn(['auth', 'user'])))
  const userPixelStatusObject = useSelector(state => (state.getIn(['userPixelStatus', 'userPixelStatus'])))


  const handleChange = (event, newValue) => {
    setValue(newValue);
    setVerified(!verified)
  };
  
  useEffect(()=>{
    dispatch(fetchUser())
    document.title = "Influence Pixel Integration || Influence | Increase Conversion Rate by 17% Using Social Proof!"
  },[])

  useEffect(()=>{
    if(userdetails){
      pixelStatusCheckHandler(()=>{})
    }
  },[userdetails])

  /**
   * Check the verfied status of the pixel
   * @param {Function} afterAction Funtion will run after api call completes
   */
  const pixelStatusCheckHandler = (afterAction) =>{
    dispatch(fetchUserPixelStatus(userdetails.trackingId, afterAction))
  }


  /**
   * Proceed user to /getting-started if pixel is verified
   */
  const proceedHandler = () =>{
    if(userPixelStatusObject && userPixelStatusObject.status){
      setLoading(true)
      dispatch(userPathChange({
        email: userdetails.email,
        userId: userdetails._id,
        path: '/getting-started'
      }, fetchUser, afterAction))
    } else{
      setOpenToaster(true)
      return
    }
  }

  /**
   * This function will run after respective api call gets completed
   * @param {Boolean} err API is completed successfully or not
   * @param {Object} res API response object
   */
  const afterAction = (err, res)=>{
    setLoading(false)
    if(err){

    }else{
      return browserHistory.push("/getting-started")
    }
  }
	


  return (
    <Grid container md={12} justify="space-between">
      <Grid item md={9}>
        <Box className={classes.headingContainer}>
          <PixelNavigation handleChange={handleChange} value={value}/>
          <PixelTabPanels value={value} pixelStatusCheckHandler={pixelStatusCheckHandler}/>
        </Box>
        <LoadingButton onClick={proceedHandler} variant="contained" loading={loading} style={{float:'right', background:'green', border:'none', width:'127px'}}>
          Proceed <DoubleArrowIcon style={{marginLeft:'10px', fontSize:'16px'}}/>
        </LoadingButton>
      </Grid>

      <Snackbar 
        open={openToaster}
        autoHideDuration={3000} 
        onClose={()=>setOpenToaster(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Alert variant="filled" severity="error" style={{background:'black'}}>
          <div style={{display:'flex',width:'auto'}}>
            <div style={{fontSize:'11x',paddingLeft:'6px',fontWeight:'400'}}>Please verify your pixel first.</div>
          </div>
        </Alert>
      </Snackbar>

      <Grid item md={1} align="center">
        <Divider orientation="vertical"/>
      </Grid>

      <Grid  item md={2} >
        <PixelSidebar/>
      </Grid>
    </Grid>
  );
}




const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor:'#fff'
  },
  headingContainer:{
    // marginTop:'2rem',
    marginBottom:'2rem',
  }
}));



export default PixelScreen