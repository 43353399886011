import React, { useEffect, Fragment } from 'react';
import { makeStyles, createStyles, Box, Typography, Paper, Grid, Divider } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import {Check} from '../../../../img'
import Cta from '../../Cta';

const RecentNotificationCard = (props) => {
    const classes = useStyles();
    const {
        recentSettings,
        otherText,
        contentText,
        extraText,
        recentText1,
        recentText2,
        poweredBy
    
    } = props;


    return(
        // <a href={recentSettings.notificationUrl} target="_blank" rel="noopener noreferrer">
            <Box 
                component={Paper}
                width= '360px'
                height= '85px'
                padding= {recentSettings.notificationSize === 'default' ? '6px 6px 6px 5px' : '6px 6px 6px 6px'}
                border='1px solid rgba(216, 217, 226, 0.5)'
                // boxShadow='10px 20px 40px 0 rgba(36, 35, 40, 0.1)'
                // borderRadius={`${recentSettings.panelStyle.radius}px`}
                // bgcolor={`rgb(${recentSettings.panelStyle.backgroundColor.r}, ${recentSettings.panelStyle.backgroundColor.g} ,${recentSettings.panelStyle.backgroundColor.b})`}
                display='flex'
                alignItems='center'
                style={{
                    flexDirection: 'column',
                    textDecoration:'none', 
                    position:'relative',
                    boxShadow:'10px 20px 40px 0 rgba(36, 35, 40, 0.1)',
                    borderRadius: `${recentSettings.panelStyle.radius}px`,
                    backgroundColor:`rgb(${recentSettings.panelStyle.backgroundColor.r}, ${recentSettings.panelStyle.backgroundColor.g} ,${recentSettings.panelStyle.backgroundColor.b})`
                }}
            >

                <Box className={classes.upperPart}>
                    <Box className={classes.imageContainer}>
                        <img
                            style={{
                                borderRadius: `${recentSettings.panelStyle.radius}px`,
                                height: '66px',
                                width: '66px',
                                alignSelf: 'center'
                            }}
                            src={
                                recentSettings.panelStyle.image !== "" ?
                                recentSettings.panelStyle.image :
                                recentSettings.toggleMap === 'map' ? 
                                "https://storage.googleapis.com/influence-197607.appspot.com/images/nymapview.jpg" :
                                "https://centrik.in/wp-content/uploads/2017/02/user-image-.png"
                            }
                        />
                    </Box>

                    <Box 
                        // className={classes.textContainer}
                        style={{
                            // padding: '7px',
                            alignSelf: 'center',
                            width: '268px'
                        }}
                    >
                        <p 
                            className={classes.mainText}
                            style={{
                                fontFamily: recentSettings.panelStyle.fontFamily,
                                fontWeight: "600",
                                color: `rgb(${recentSettings.panelStyle.color.r}, ${recentSettings.panelStyle.color.g}, ${recentSettings.panelStyle.color.b})`,
                            }}
                        >
                            {`${recentSettings.toggleHideName ? recentSettings.usernameText : 'Username'}`}
                            <em style={{textTransform:'none'}}>
                                {!recentSettings.isHideFullLocation &&
                                    <Fragment>
                                        <em
                                            style={{
                                                fontStyle: 'normal',
                                                marginLeft: '2%'
                                            }}
                                        >
                                             {recentText1} 
                                        </em>
                                        {!recentSettings.isHideCityLocation &&
                                            <em
                                                style={{
                                                    fontStyle: 'normal',
                                                    marginLeft: '2%'
                                                }}
                                            >
                                                 City,
                                            </em>
                                        }
                                        {(!recentSettings.isHideCountryLocation) &&
                                            <em
                                                style={{
                                                    fontStyle: 'normal',
                                                    marginLeft: '2%'
                                                }}
                                            >
                                                 country
                                            </em>
                                        }
                                        {(!recentSettings.isHideState && recentSettings.isHideCountryLocation) &&
                                            <em
                                                style={{
                                                    fontStyle: 'normal',
                                                    marginLeft: '2%'
                                                }}
                                            >
                                                 state
                                            </em>
                                        }
                                    </Fragment>
                                }
                            </em>
                        </p>

                        <p 
                            // className={classes.secondaryText}
                            style={{
                                fontFamily: recentSettings.panelStyle.fontFamily,
                                fontWeight: recentSettings.panelStyle.fontWeight,
                                margin: '0',
                                marginTop: '2px',
                                fontSize: '10px',
                                fontStyle: 'normal',
                                color: recentSettings.panelStyle.secondaryColor ? `rgb(${recentSettings.panelStyle.secondaryColor.r}, ${recentSettings.panelStyle.secondaryColor.g}, ${recentSettings.panelStyle.secondaryColor.b})` : "#666",
                                lineHeight: '1.2',
                                letterSpacing: '1.33',
                                paddingLeft: '6px',
                                overflow: 'hidden',
                                display: 'inherit',
                                WebkitLineClamp: '3',
                                WebkitBoxOrient: 'vertical'
                            }}
                        >
                             {otherText} {contentText}
                        </p>
                    </Box>

                </Box>

                
                        <div className={classes.border}></div>

                        <Box className={classes.lowerPart}>
                            <p className={classes.footerLeftText}>
                                {
                                    extraText ?
                                        `${extraText} 9 mins (s) ago`:
                                        '9 mins(s) ago'
                                }
                            </p>

                            
                            {recentSettings.togglePoweredBy &&
                                <p className={classes.footerTextRight}>
                                    <em className={classes.verifiedText}>{recentText2}</em>
                                    <em className={classes.verifiedIcon}>
                                        {/* <img src={Check}/> */}
                                        <Check/>
                                    </em>
                                    <em className={classes.influenceText}>{poweredBy}</em>                        
                                </p>
                            }
                            
                        </Box>
                
                
                                       
                {recentSettings.toggleCTA &&
                    <Cta 
                        ctaBgColor={recentSettings.panelStyle.ctaBackgroundColor}
                        ctaTextColor={recentSettings.panelStyle.ctaTextColor}
                        ctaText={recentSettings.ctaButtonText}
                        ctaRadius={recentSettings.panelStyle.ctaRadius}
                        ctaUrl={recentSettings.ctaHyperlinkUrl}
                        ctaPosition={recentSettings.isCTATop}
                        
                    />
                }
            </Box>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        upperPart:{
            display: 'flex',
            flexDirection: 'row',
            // marginTop: '5px',
            width: '100%',
            height: '60px'
        },

        imageContainer:{
            width: '67px',
            height: '68px',
            // backgroundColor: '#f3f7ff',
            borderRadius: '15px',
            textAlign: 'center',
            marginRight: '10px',
            alignSelf: 'center',
            marginTop: '11px'
        },
        mainText:{
            color: '#2c66c3',
            textTransform: 'capitalize',
            fontFamily: 'sans-serif',
            fontSize: '13px',
            textAlign: 'left',
            paddingRight: '2px',
            paddingLeft: '5px',
            margin: '0',
            // backgroundColor: 'aliceblue',
            width: 'inherit',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'flex'
        },
        border:{
            marginTop: '1px',
            borderTop: '1px dashed rgba(102,102,102,0.0)',
            // margin: '10px',
            marginBottom: '0',
            width: '94%'
        },
        lowerPart:{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '76%',
            padding: '0 3px',
            height: '10px',
            marginLeft: '19%'
        },
        footerLeft:{
            display: 'flex'
        },
        footerLeftText:{
            fontSize: '9px',
            color: '#49445f',
            paddingLeft: '5px',
            fontFamily: 'sans-serif',
            lineHeight: '1',
            letterSpacing: 'normal',
            margin: '0',
            paddingTop: '3px'
        },
        footerTextRight:{
            margin: '0',
            paddingRight: '5px',
            lineHeight: '0.8'
        },
        verifiedText:{
            fontSize: '9px',
            fontWeight: '600',
            fontStyle: 'normal',
            textAlign: 'center',
            color: '#666',
            fontFamily: 'sans-serif',
            wordSpacing: '1px'
        },
        verifiedIcon:{
            fontSize: '11px',
            color: '#0093fb',
            paddingLeft: '5px',
            paddingRight: '5px',
            marginTop: '-1px'
        },
        influenceText:{
            fontStyle: 'normal',
            fontSize: '9.5px',
            fontWeight: '600',
            fontFamily: 'sans-serif',
            color: '#077fff'
        }
    })    
)

export default RecentNotificationCard;



{/*

<Box>
                    <img
                        src= {
                            recentSettings.panelStyle.image !== "" ?
                            recentSettings.panelStyle.image :
                            recentSettings.toggleMap === 'map' ? 
                            "https://storage.googleapis.com/influence-197607.appspot.com/images/nymapview.jpg" :
                            "https://centrik.in/wp-content/uploads/2017/02/user-image-.png"
                        }
                        style={{
                            width: '64px',
                            height: '64px',
                            borderRadius: `${recentSettings.panelStyle.radius}px`,
                        }}
                    />
                </Box>
                
                <Box width='100%' padding='0px 10px' style={{overflowX:'hidden',textOverflow:'ellipsis',width:'100%',maxHeight:'72px'}}>
                    <Box>
                        <Box maxHeight='19px'>
                            <span
                                style={{
                                    fontFamily: recentSettings.panelStyle.fontFamily,
                                    fontWeight: recentSettings.panelStyle.fontWeight,
                                    fontSize: '14px',
                                    color: `rgb(${recentSettings.panelStyle.color.r}, ${recentSettings.panelStyle.color.g}, ${recentSettings.panelStyle.color.b})`
                                }}
                            >
                                {`${recentSettings.toggleHideName ? recentSettings.usernameText : 'Username'}`}
                            </span>
                            {!recentSettings.isHideFullLocation &&
                                <Fragment>
                                    <span
                                        style={{
                                            fontFamily: recentSettings.panelStyle.fontFamily,
                                            fontWeight: recentSettings.panelStyle.fontWeight,
                                            color: `rgb(${recentSettings.panelStyle.color.r}, ${recentSettings.panelStyle.color.g}, ${recentSettings.panelStyle.color.b})`,
                                            fontSize: '14px',
                                            marginLeft: '2%'
                                        }}
                                    >
                                        from 
                                    </span>
                                    {!recentSettings.isHideCityLocation &&
                                        <span
                                            style={{
                                                fontFamily: recentSettings.panelStyle.fontFamily,
                                                fontWeight: recentSettings.panelStyle.fontWeight,
                                                color: `rgb(${recentSettings.panelStyle.color.r}, ${recentSettings.panelStyle.color.g}, ${recentSettings.panelStyle.color.b})`,
                                                fontSize: '14px',
                                                marginLeft: '2%'
                                            }}
                                        >
                                            city,
                                        </span>
                                    }
                                    {!recentSettings.isHideCountryLocation &&
                                        <span
                                            style={{
                                                fontFamily: recentSettings.panelStyle.fontFamily,
                                                fontWeight: recentSettings.panelStyle.fontWeight,
                                                color: `rgb(${recentSettings.panelStyle.color.r}, ${recentSettings.panelStyle.color.g}, ${recentSettings.panelStyle.color.b})`,
                                                fontSize: '14px',
                                                marginLeft: '2%'
                                            }}
                                        >
                                            country
                                        </span>
                                    }
                                </Fragment>
                            }
                        </Box>
                        <Box maxHeight='19px' style={{overflowX:'hidden',textOverflow:'ellipsis'}}>
                            <span
                                style={{
                                    fontFamily: recentSettings.panelStyle.fontFamily,
                                    fontWeight: recentSettings.panelStyle.fontWeight,
                                    fontSize: '12px',
                                    color: '#738c98',
                                    whiteSpace: 'nowrap',
                                    overflowX: 'hidden',
                                    textOverflow: 'ellipsis',
                                    letterSpacing: '0.3px',
                                    //color: `rgb(${recentSettings.panelStyle.color.r}, ${recentSettings.panelStyle.color.g}, ${recentSettings.panelStyle.color.b})`
                                }}
                            >
                                {otherText}
                            </span>
                            <span
                                style={{
                                    fontFamily: recentSettings.panelStyle.fontFamily,
                                    fontWeight: recentSettings.panelStyle.fontWeight,
                                    //color: `rgb(${recentSettings.panelStyle.color.r}, ${recentSettings.panelStyle.color.g}, ${recentSettings.panelStyle.color.b})`,
                                    fontSize: '12px',
                                    color: '#738c98',
                                    whiteSpace: 'nowrap',
                                    overflowX: 'hidden',
                                    textOverflow: 'ellipsis',
                                    letterSpacing: '0.3px',
                                    marginLeft: '2%'
                                }}
                            >
                                {contentText}
                            </span>
                        </Box>
                    </Box>
                    
                    <Box>
                        {recentSettings.extraText !== "" &&
                            <span
                                style={{
                                    fontFamily: recentSettings.panelStyle.fontFamily,
                                    fontWeight: recentSettings.panelStyle.fontWeight,
                                    //color: `rgb(${recentSettings.panelStyle.color.r}, ${recentSettings.panelStyle.color.g}, ${recentSettings.panelStyle.color.b})`,
                                    fontSize: '10px',
                                    color: '#738c98',
                                    whiteSpace: 'nowrap',
                                    overflowX: 'hidden',
                                    textOverflow: 'ellipsis',
                                    letterSpacing: '0.3px',
                                    marginLeft: '2%'
                                }}
                            >
                                {extraText}
                            </span>
                        }
                        <span
                            style={{
                                fontFamily: recentSettings.panelStyle.fontFamily,
                                fontWeight: recentSettings.panelStyle.fontWeight,
                                //color: `rgb(${recentSettings.panelStyle.color.r}, ${recentSettings.panelStyle.color.g}, ${recentSettings.panelStyle.color.b})`,
                                fontSize: '10px',
                                color: '#738c98',
                                letterSpacing: '0.3px',
                            }}
                        >
                            a minute ago
                        </span>
                        {recentSettings.togglePoweredBy &&
                            <Fragment>
                                <span
                                    style={{
                                        fontFamily: recentSettings.panelStyle.fontFamily,
                                        fontWeight: recentSettings.panelStyle.fontWeight,
                                        color: `rgb(${recentSettings.panelStyle.color.r}, ${recentSettings.panelStyle.color.g}, ${recentSettings.panelStyle.color.b})`,
                                        fontSize: '10px',
                                        marginLeft: '2%'
                                    }}
                                >
                                    <CheckCircleRoundedIcon style={{width:'14px', height:"14px",color:"#5d93fe"}} />
                                </span>
                                <span
                                    style={{
                                        fontFamily: recentSettings.panelStyle.fontFamily,
                                        fontWeight: recentSettings.panelStyle.fontWeight,
                                        //color: `rgb(${recentSettings.panelStyle.color.r}, ${recentSettings.panelStyle.color.g}, ${recentSettings.panelStyle.color.b})`,
                                        fontSize: '10px',
                                        color: '#738c98',
                                        letterSpacing: '0.3px',
                                        marginLeft: '2%'
                                    }}
                                >
                                    {recentText2}<span
                                                        style={{
                                                            fontFamily: recentSettings.panelStyle.fontFamily,
                                                            fontWeight: recentSettings.panelStyle.fontWeight,
                                                            color: "#007bff",
                                                            fontSize: '10px',
                                                            marginLeft: '2%',
                                                            whiteSpace: 'nowrap',
                                                            overflowX: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            letterSpacing: '0.3px',
                                                        }}>{poweredBy}</span>
                                </span>
                    
                                {/* <span
                                    style={{
                                        fontFamily: recentSettings.panelStyle.fontFamily,
                                        fontWeight: recentSettings.panelStyle.fontWeight,
                                        color: "#007bff",
                                        fontSize: '10px',
                                        marginLeft: '2%',
                                        whiteSpace: 'nowrap',
                                        overflowX: 'hidden',
                                        textOverflow: 'ellipsis',
                                        letterSpacing: '0.3px',
                                    }}
                                >
                                    {poweredBy}
                                </span> 
                            </Fragment>
                        }
                    </Box>
                </Box>
                                   
                {recentSettings.toggleCTA &&
                    <Cta 
                        ctaBgColor={recentSettings.panelStyle.ctaBackgroundColor}
                        ctaTextColor={recentSettings.panelStyle.ctaTextColor}
                        ctaText={recentSettings.ctaButtonText}
                        ctaRadius={recentSettings.panelStyle.ctaRadius}
                        ctaUrl={recentSettings.ctaHyperlinkUrl}
                        isCTATop={recentSettings.isCTATop}
                    />
                }
            </Box>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({

    })    
)

export default RecentNotificationCard;

*/}