import React, { useState, useEffect, memo } from 'react';
import { makeStyles, createStyles, Box, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import BasicBox from './BasicBox';
import AppearanceBox from './AppearanceBox';
import TextBox from './TextBox'
import FeaturesBox from './FeaturesBox';
import AdditionalFeaturesBox from './AdditionalFeaturesBox';
import NotificationsClickableBox from './NotificationsClickableBox';
import { useSelector } from 'react-redux';

const RecentNotificationRules = (props) => {
    const classes = useStyles();
    const user = useSelector(state=>(state.getIn(['auth','user'])))

    const [fontColorPopper, setFontColorPopper] = useState(false);
    const [fontColorPopper2, setFontColorPopper2] = useState(false);
    const [backgroundColorPopper, setBackgroundColorPopper] = useState(false);
    const [fontBgColor, setFontBgColor] = useState('rgb(0,0,0,0)')
    const [secondatyFontBgColor, setSecondaryFontBgColor] = useState('rgb(0,0,0,0)')
    const [bgColorValue, setBgColorValue] = useState('rgb(0,0,0,0)')
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    


    const {
        handlePropertyChange, 
        handleInputChange,
        recentSettings, 
        setRecentSettings, 
        notificationConfiguration,
        handlePanelStyleChange,
        campaignInfo,
        notificationType,
        handleLanguageChange,
        handleNotificationSubmit,
        otherText,
        contentText,
        extraText,
        recentText2,
        recentText1,
        poweredBy,
        loading,
        brandingLocked,
        onClose,
        
    } = props;



    useEffect(() => {
        recentSettings.toggleMap === 'map' &&
            handlePanelStyleChange('image', "", setRecentSettings);
    },[recentSettings.toggleMap])


    return(
        <Box>
            <BasicBox 
                langName={recentSettings.langName}
                notificationSize={recentSettings.notificationSize}
                toggleHideName={recentSettings.toggleHideName}
                usernameText={recentSettings.usernameText}
                isHideFullLocation={recentSettings.isHideFullLocation}
                isHideCountryLocation={recentSettings.isHideCountryLocation}
                isHideState={recentSettings.isHideState}
                isHideCityLocation={recentSettings.isHideCityLocation}
                handlePropertyChange={handlePropertyChange}
                handleInputChange={handleInputChange}
                setRecentSettings={setRecentSettings}
                handleLanguageChange={handleLanguageChange}
            />

            <AppearanceBox 
                radius={recentSettings.panelStyle.radius}
                backgroundColor={recentSettings.panelStyle.backgroundColor}
                toggleMap={recentSettings.toggleMap}
                handlePanelStyleChange={handlePanelStyleChange}
                setRecentSettings={setRecentSettings}
                backgroundColorPopper={backgroundColorPopper}
                bgColorValue={bgColorValue}
                anchorEl={anchorEl}
                handlePropertyChange={handlePropertyChange}
                setBgColorValue={setBgColorValue}
                setBackgroundColorPopper={setBackgroundColorPopper}
                setAnchorEl={setAnchorEl}
            />

            <TextBox 
                fontWeight={recentSettings.panelStyle.fontWeight}
                fontFamily={recentSettings.panelStyle.fontFamily}
                color={recentSettings.panelStyle.color}
                secondaryColor={recentSettings.panelStyle.secondaryColor}
                setRecentSettings={setRecentSettings}
                handlePanelStyleChange={handlePanelStyleChange}
                fontColorPopper={fontColorPopper}
                fontColorPopper2={fontColorPopper2}
                fontBgColor={fontBgColor}
                secondatyFontBgColor={secondatyFontBgColor}
                anchorEl={anchorEl}
                anchorEl2={anchorEl2}
                setAnchorEl={setAnchorEl}
                setAnchorEl2={setAnchorEl2}
                setFontBgColor={setFontBgColor}
                setSecondaryFontBgColor={setSecondaryFontBgColor}
                setFontColorPopper={setFontColorPopper}
                setFontColorPopper2={setFontColorPopper2}
            />

            <FeaturesBox 
                recentText1={recentText1}
                orderText={recentSettings.orderText}
                isDisplayPurchase={recentSettings.isDisplayPurchase}
                isHideLastname={recentSettings.isHideLastname}
                isDisplaySignup={recentSettings.isDisplaySignup}
                recentNumber={recentSettings.panelStyle.recentNumber}
                recentConv={recentSettings.panelStyle.recentConv}
                selectLastDisplayConversation={recentSettings.panelStyle.selectLastDisplayConversation}
                hideAnonymousConversion={recentSettings.panelStyle.hideAnonymousConversion}
                daysAgo={recentSettings.daysAgo}
                hoursAgo={recentSettings.hoursAgo}
                minuteAgo={recentSettings.minuteAgo}
                otherText={otherText}
                contentText={contentText}
                extraText={extraText}
                handleInputChange={handleInputChange}
                setRecentSettings={setRecentSettings}
                handlePropertyChange={handlePropertyChange}
                handlePanelStyleChange={handlePanelStyleChange}
                captureAllData={recentSettings.captureAllData}
            />
            
            <AdditionalFeaturesBox 
                recentText2={recentText2}
                poweredBy={poweredBy}
                poweredByLink={recentSettings.poweredByLink}
                togglePoweredBy={recentSettings.togglePoweredBy}
                handlePropertyChange={handlePropertyChange}
                handleInputChange={handleInputChange}
                setRecentSettings={setRecentSettings}
                brandingLocked={brandingLocked}
            />

            <NotificationsClickableBox 
                notificationUrl={recentSettings.notificationUrl}
                ctaButtonText={recentSettings.ctaButtonText}
                ctaHyperlinkUrl={recentSettings.ctaHyperlinkUrl}
                isEnablePurchaseNotificationUrl={recentSettings.isEnablePurchaseNotificationUrl}
                toggleCTA={recentSettings.toggleCTA}
                ctaRadius={recentSettings.panelStyle.ctaRadius}
                setRecentSettings={setRecentSettings}
                handleInputChange={handleInputChange}
                handlePropertyChange={handlePropertyChange}
                handlePanelStyleChange={handlePanelStyleChange}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                anchorEl2={anchorEl2}
                setAnchorEl2={setAnchorEl2}
                isCTATop={recentSettings.isCTATop}
                recentSettings={recentSettings}
            />

            <Box className={classes.saveBtnContainer}>

                <Button variant="outlined" onClick={onClose} className={classes.cancelBtn}>
                    Close
                </Button>

                <Button className={classes.saveBtn}
                    disabled={loading || user.role.name === "campaignB"}
                    variant="contained"
                    onClick={
                        () => handleNotificationSubmit(
                            notificationConfiguration[0]._id,
                            recentSettings,
                            campaignInfo._id,
                            notificationType[0]._id,
                            campaignInfo.websiteUrl,
                            campaignInfo.trackingId
                        )
                    }
                >
                    Save Changes
                    {loading && <CircularProgress classes={{colorPrimary: classes.circularProgress}} size={25} thickness={5} /> }
                </Button>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({

        saveBtnContainer:{
            position: 'fixed',
            width: '46%',
            bottom: '0',
            zIndex: '2',
            backgroundColor: '#fff',
            padding: '17px 0px',
            marginLeft: '-2.8%',
            borderTop: '1px solid #c6c6c6',
            display: 'flex',
            justifyContent: 'flex-end'
        },
        saveBtn:{
            // textTransform: 'initial',
            // fontWeight: '200',
            // fontSize: '12px',
            // position:'relative',
            margin: '0 4%'
        },
        circularProgress:{
            color: '#fff',
            top:'52%',
            left:'52%',
            marginTop:-12,
            marginLeft:-12,
            position:'absolute'
        },
        // cancelBtn:{
        //     backgroundColor: '#fff',
        //     border: '1px solid #c6c6c6 !important',
        //     color: '#484848',
        //     textTransform: 'unset',
        //     width: '85px',
        //     fontWeight: '400',
        //     '&:hover':{
        //         backgroundColor: '#c6c6c686',
        //         color: '#484848',
        //     }
        // }
    })    
)

export default memo(RecentNotificationRules);