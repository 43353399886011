import {
        FETCH_DISPLAY_PAGE_DATA,
        DELETE_DISPLAY_PAGE_DATA,
        POST_DISPLAY_PAGE_DATA,
     } from './action';

import { fromJS } from 'immutable';

const initialState = fromJS({});

export const DisplayPageData = (state = initialState,action) => {
    switch(action.type){
        case FETCH_DISPLAY_PAGE_DATA:
            //get request data saved in reducer
            return state.set('displayPageData',action.payload);
        case POST_DISPLAY_PAGE_DATA:
            // update the added goal in reducer.
            return state.set('displayPageData',state.getIn(['displayPageData']).concat(action.payload));
        case DELETE_DISPLAY_PAGE_DATA:
            //filter out the deleted object from reducer
            return state.set('displayPageData',state.get('displayPageData').filter(state => state._id !== action.payload._id));   
        default:
            return state;
    }
}

