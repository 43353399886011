import React, { useState, useEffect } from 'react'
import {
  Box,
  makeStyles,
  createStyles,
  Dialog,
  Grid,
  Typography,
  InputBase,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import clsx from 'clsx'
import { SelectBox } from 'components'
import { useSelector } from 'react-redux'
import TextInput from 'Globals/GlobalComponents/TextInput'
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined'
import AddServiceDialog_2 from './AddServiceDialog_2'
import { postPolicymaster } from '../poicyThunks/policymaster/actions'

const AddServiceDialog = (props) => {
  const { open, onClose, policiesData, activeStep } = props
  const classes = useStyles()
  const addedServices = useSelector((state) => state.get('addedServices').toJS())
  // const policyPersonalRegionalData = useSelector((state) => state.get('policyPersonalRegionalData').toJS())

  const [policyCategories, setPolicyCategories] = useState([])
  const [modalScreen, setModalScreen] = useState(1)
  const [allServices, setAllServices] = useState([])
  const [filteredServices, setFilteredServices] = useState({ filterBy: '', services: [] })
  const [allData, setAllData] = useState({
    services: [],
  })
  const [selectedService, setSelectedService] = useState({})

  const [formData, setFormData] = useState({
    selectedService: {},
    personalData: [],
    cookieLifeTime: {
      value: '1',
      data: {
        unit: '',
        value: '',
      },
    },
  })

  const [auxFormData, setAuxFormData] = useState({
    selectedPersonalData: [],
    cookieLifeTime: 0,
    cookieLifeTimeData: [],
  })

  const saveModal_2 = () => {
    const ind = allData.services.findIndex((item) => item._id === selectedService._id)
    let temp = [...allData.services]
    temp[ind] = selectedService
    setAllData((data) => ({ ...data, services: temp }))
  }

  const postData = () => {
    console.log('---------finalData----------', allData)
  }

  const demoFrequentServies = [
    'Contact Form',
    'Facebook Account',
    'Facebook  Like',
    'Google Location',
  ]

  useEffect(() => {
    setPolicyCategories(policiesData.data.filter((item) => item.pid === '0'))
    setAllServices(policiesData.data.filter((item) => item.pid !== '0'))
  }, [policiesData.data])

  useEffect(() => {
    setModalScreen(activeStep)
  }, [activeStep])

  useEffect(() => {
    // console.log({addedServices})
    console.log({ formData })
  }, [formData])

  useEffect(() => {
    setFilteredServices({ filterBy: 'all', services: allServices })
  }, [allServices])

  const filterServicesCatergoryWise = (categoryId) => {
    if (categoryId === 'all') setFilteredServices({ filterBy: 'all', services: allServices })
    else
      setFilteredServices({
        filterBy: categoryId,
        services: allServices.filter((service) => service.pid === categoryId),
      })
  }

  // ---------- step-2 funtions and hadlers --------

  const selectServiceHandler = (service) => {
    setFormData((data) => ({ ...data, selectedService: service }))
    setSelectedService(service)
    // setPersonalDataOptions(service.personalData.map(item => ({value: item._id, label: item.name})))

    setModalScreen(2)
  }

  const backButtonHandler = () => {
    setModalScreen(1)
    setFormData({
      selectedService: {},
      personalData: [],
      cookieLifeTime: {
        value: '1',
        data: [
          {
            value: '',
            unit: '',
          },
        ],
      },
    })
    setAuxFormData({ selectedPersonalData: [{}] })
  }



  useEffect(() => {
    setModalScreen(1)
    console.log('openingasdfadsfasdfasd-f----------------')
  }, [open])

  const demoAddedServices = ['Facebook', 'Contact Form ']
  return (
    <Dialog open={open} maxWidth="md" onClose={onClose} classes={{ paper: classes.dialogPaper }}>
      <Box>
        <Typography style={{ marginBottom: '8px', fontSize: '22px', fontWeight: '500' }}>
          Add Service
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={3} style={{ margin: 'auto 0' }}>
            <Typography
              onClick={backButtonHandler}
              style={{
                // display: 'inline-block',
                fontSize: '12px',
                color: 'grey',
                cursor: 'pointer',
                display: modalScreen === 1 ? 'none' : 'inline-block',
              }}
            >
              Back
            </Typography>
          </Grid>
          <Grid item md={6}>
            <InputBase
              style={modalScreen === 1 ? { display: 'block' } : { display: 'none' }}
              placeholder="Type here to search"
            />
          </Grid>
          <Grid item md={3}>
            <Button variant="outlined">Create Custom Service</Button>
          </Grid>
        </Grid>

        <Grid spacing={2} container style={{ marginTop: '16px' }}>
          <Grid
            item
            md={3}
            style={modalScreen === 1 ? { display: 'block' } : { display: 'none' }}
            className={classes.allCategoriesContainer}
          >
            <Typography>Categories</Typography>
            <Box className={classes.categoriesListContainer}>
              <Typography
                className={clsx({ active: filteredServices.filterBy === 'all' })}
                onClick={() => filterServicesCatergoryWise('all')}
              >
                All Categories
              </Typography>
              {policyCategories.map((category, index) => (
                // <Box>
                <Typography
                  className={clsx({ active: category._id === filteredServices.filterBy })}
                  key={index}
                  onClick={() => filterServicesCatergoryWise(category._id)}
                >
                  {category.name}
                  <Typography component="span">34</Typography>
                </Typography>

                // </Box>
              ))}
            </Box>
          </Grid>

          <Grid
            item
            md={6}
            style={modalScreen === 1 ? { display: 'block' } : { display: 'none' }}
            className={classes.allServicesContainer}
          >
            <Typography>
              Total Services Added: <b>12</b>
            </Typography>
            <Box className={classes.servicesListContainer}>
              {filteredServices.services.map((service) => (
                <Box
                  onClick={() => selectServiceHandler(service)}
                  className={classes.serviceBoxStyle}
                  key={service._id}
                >
                  <Typography>
                    <ClassOutlinedIcon fontSize="small" />
                    {service.name}
                  </Typography>
                  <AddIcon fontSize="small" />
                </Box>
              ))}
            </Box>
          </Grid>

          {modalScreen === 2 && (
            <AddServiceDialog_2
              saveModal_2={saveModal_2}
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              auxFormData={auxFormData}
              setAuxFormData={setAuxFormData}
            />
          )}

          <Grid item md={3}>
            <Box className={classes.frequentServicesContainer}>
              <Typography>Frequent Services</Typography>
              <Box className={classes.frequentServicesListContainer}>
                {demoFrequentServies.map((service, index) => (
                  <Typography key={service + index}>
                    <img src="https://facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png" />
                    <span>{service}</span>
                    <span>+</span>
                  </Typography>
                ))}
              </Box>
            </Box>

            <Box className={classes.addedServicesContainer}>
              <Typography>Added Services</Typography>
              <Box className={classes.addedServicesListContainer}>
                {demoAddedServices.map((service) => (
                  <Box className={classes.addedServiesWrapper}>
                    <Typography>{service}</Typography>
                    <Box className={classes.addedServicesIconsContainer}>
                      <EditIcon />
                      <DeleteOutlineOutlinedIcon />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.footerContainer}>
          <Button variant="outlined" style={{ marginRight: '16px' }} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={postData}>
            Save &amp; Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogPaper: {
      width: '85%',
      maxWidth: '1100px',
      padding: theme.spacing(3),
    },
    allCategoriesContainer: {
      '&>p': {
        marginBottom: theme.spacing(2),
      },
    },
    hide: {
      display: 'none !important',
    },
    categoriesListContainer: {
      border: '1px solid #ddd',
      backgroundColor: '#FBFBFB',
      borderRadius: theme.shape.borderRadius,
      '&>p': {
        borderTop: '1px solid #ddd',
        // borderBottom: '1px solid grey',
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        color: '#808080',
        fontSize: '13px',
        fontWeight: '500',
        '&:first-child': {
          borderTop: 'unset',
          borderRadius: theme.shape.borderRadius,
        },
        '&:last-child': {
          borderBottom: 'unset',
        },
        '&:hover': {
          color: '#59636D',
        },
        '&>span': {
          background: '#fff',
          borderRadius: '10px',
          padding: '4px 6px',
          fontSize: '12px',
        },
      },
      '&>p.active': {
        background: '#fff',
        color: '#59636D',
      },
    },
    allServicesContainer: {
      padding: theme.spacing(1),
      '&>p': {
        marginBottom: theme.spacing(2),
      },
    },
    servicesListContainer: {
      border: '1px solid #ddd',
      borderRadius: theme.shape.borderRadius,
    },
    serviceBoxStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 24px',
      cursor: 'pointer',
      '&>p': {
        color: '#808080',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        '&>svg': {
          fontSize: '19px',
          color: '#000000a8',
        },
        // flexBasis:'80%'
      },
      '&>svg': {
        color: '#808080',
        fontSize: '16px',
        border: ' 1px solid #dddddd',
        borderRadius: '2px',
        // flexBasis:'10%'
      },
      '&:hover': {
        backgroundColor: '#fbfbfb',
      },
    },

    frequentServicesContainer: {
      '&>p': {
        marginBottom: theme.spacing(2),
      },
    },
    frequentServicesListContainer: {
      border: '1px solid #ddd',
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      '&>p': {
        borderBottom: '1px solid #ddd',
        cursor: 'pointer',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        fontSize: '13px',
        color: '#808080',
        '&:last-child': {
          borderBottom: 'unset',
        },
        '&>img': {
          width: 14,
          height: 'auto',
          flexBasis: '8%',
        },
        '&>span:nth-child(2)': {
          flexBasis: '80%',
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 14,
        },
        '&>span:last-child': {
          flexBasis: '10%',
        },
        '&:hover': {
          // backgroundColor:'#fbfbfb'
        },
      },
    },

    addedServicesContainer: {
      '&>p': {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(3),
      },
    },
    addedServicesListContainer: {
      border: '1px solid #ddd',
      borderRadius: theme.shape.borderRadius,
    },
    addedServiesWrapper: {
      borderTop: '1px solid #ddd',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      gap: '8px',
      '&:first-child': {
        borderTop: 'unset',
      },
      '&>p': {
        fontSize: 14,
        flexBasis: '70%',
        color: '#808080',
      },
    },
    addedServicesIconsContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      flexBasis: '25%',
      '&>svg': {
        fontSize: 14,
        color: 'grey',
        cursor: 'pointer',
      },
    },
    footerContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(1),
    },
    step2_inputContainer: {
      marginTop: theme.spacing(2),
      '&:first-child': {
        marginTop: '0',
      },
    },
    addCookieLifetimeContainer: {
      padding: '8px 16px',
      border: '1px solid #ddd',
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      '&>p': {
        fontSize: '14px',
        flexBasis: '0',
        flexGrow: '2',
      },
      '&>div': {
        flexBasis: '0',
        flexGrow: '5',
      },
    },
    formInputLabel: {
      fontWeight: '500',
      color: '#808080',
      fontSize: '14px',
    },
  })
)

export default AddServiceDialog
