import React from "react"
import clsx from "clsx"
import { createStyles, makeStyles, Typography } from "@material-ui/core"
import PropTypes from 'prop-types'


const Pill = props => {
	const {type,...rest} = props
	const classes = useStyles()
	return (
    <Typography
      {...rest}
      display="inline"
      className={clsx(classes.genralStyle, classes[props.type.toLowerCase()])}
    >
      {props.children}
    </Typography>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    genralStyle: {
      borderRadius: '999px',
      fontSize: '10px',
      padding: '4px 6px',
      fontWeight: '500',
      color: 'rgba(9,30,66,.74)',
      textTransform: 'capitalize',
    },
    purple: {
      backgroundColor: '#fbcffc',
    },
    yellow: {
      backgroundColor: '#fafba4',
    },
    red: {
      backgroundColor: '#ffdce5',
    },
    green: {
      backgroundColor: '#d1f7c4',
    },
  })
)

export default Pill



Pill.propTypes = {
  type: PropTypes.oneOf(['green', 'yellow', 'purple', 'red']).isRequired,
}