import { fromJS } from 'immutable';
import { GET_GOALS_ANALYTICS_DATA } from './action';
import { GET_GOALS_ANALYTICS, PUT_GOAL_DATA,GOAL_SCHEDULAR,GET_GOAL_FETCHING} from './action';


const initialState = fromJS({});

export const GoalsAnalyticsData = (state = initialState, action) => {
    switch(action.type){
        case GET_GOALS_ANALYTICS_DATA:
            return state.set('goalsAnalyticsData', action.payload);
        case GET_GOALS_ANALYTICS:
            return state.set('goalsAnalytics', action.payload);
        case PUT_GOAL_DATA:
                // put goal data in reducer.
            return state.set('goalPageData',action.payload);
        case GOAL_SCHEDULAR:
            return state.set('goalSchedularData', action.payload)
        default :
            return state;
    }
}


// another reducer was made because when we add path from campaign table the get request is not called because we had put a condition in it.  
export const GoalFetching = (state = initialState,action) => {
    switch(action.type){
        case GET_GOAL_FETCHING:
            return state.set('goalFetching',action.payload);
        case GOAL_SCHEDULAR:
            return state.set('goalSchedularData', action.payload)
        default :
        return state;    
    }
}

