import React, { useState } from 'react'
import { Box, makeStyles, createStyles, Typography, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core'
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Pill from '../../../Globals/GlobalComponents/Pill'
import { useDispatch, useSelector } from 'react-redux'
import copy from 'copy-to-clipboard'

const CookiePixels  = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const cookieCampaigns = useSelector(state => (state.getIn(['cookieCampaigns','cookieCampaigns'])))
  const userData = useSelector(state => (state.getIn(['auth', 'user']))) || [];

  // const pixel = `https://s3.wasabisys.com/cdns.useinfluence.co/${userData._id}/${'campaignid'}/scribe-analytics.js?trackingId=${userData.trackingId}`


  const copyHandler = (campaignid) =>{
    // copy(`https://cdns.useinfluence.co/${userData._id}/${campaignid}/scribe-analytics.js?trackingId=${userData.trackingId}`)
    copy(`<script async src="https://cdns.useinfluence.co/${userData._id}/${campaignid}/scribe-analytics.js?trackingId=${userData.trackingId}"></script>`)
    dispatch({type: 'FIRE_TOASTER', payload:{type:'success', message:'Pixel Copied to Clipboard'}})
    
  }

  const refreshHandler = () =>{
    console.log('refresafd')
  }


  return(
    <Box className={classes.mainContainer}>
      <Typography variant='h2'>Install Pixel</Typography>
      <Typography className={classes.headerSubtitle}>This code goes between your website's "head" tag. 
					After installing the pixel, create a campaign to verify it.
      </Typography>
      
      <Box className={classes.tableContainerStyle}>
        <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginBottom:'1.2rem'}}>
          <Typography style={{
						color: '#000',
						fontSize: '16px',
						fontWeight: '500',
						marginTop:'0',
						fontFamily: 'Poppins'
					}}>Pixels</Typography>
          <Button style={{width:'86px'}} variant="contained" onClick={refreshHandler} disabled={loading}>
            {
              loading ?
                <CircularProgress className={classes.loader} size = {19}/>:
                <AutorenewIcon />
            }
          </Button>
        </Box>
      
      <TableContainer >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Campaign</TableCell>
              <TableCell>Campaign URL</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Copy Pixel</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            { cookieCampaigns && cookieCampaigns.length>0?
              cookieCampaigns.map((item, index)=>(
                <TableRow key={item._id} hover style={{cursor:'pointer'}} className={classes.tableRowStyle}>
                  <TableCell className={"tableRowHoverText"}>{item.campaignName}</TableCell>
                  <TableCell>{item.websiteUrl}</TableCell>
                  <TableCell>
                    <Pill type={item.isActive ? 'Green': 'Red'}>
                      {item.isActive ? 'Active': 'Inactive'}
                    </Pill>
                  </TableCell>
                  <TableCell><Button onClick={()=>copyHandler(item._id)} variant="outlined">Copy</Button></TableCell>
                </TableRow>
              )):
              <TableRow style={{cursor:'default'}} className={classes.tableRowStyle}>
                <TableCell>No Data Available</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            }
          </TableBody>

        </Table>
      </TableContainer>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    mainContainer:{
      // padding:theme.spacing(3),
      '& > h2':{
        fontSize: '28px',
        fontWeight: '500',
        fontFamily: 'Poppins'
      }
    },
    headerSubtitle:{
      marginTop: '8px',
      fontSize: '14px',
      fontWeight: 'normal',
      marginBottom:theme.spacing(8),
      fontFamily: 'Poppins'

    },
    tableContainerStyle:{
      width:'50%',
      maxWidth:'700px',
      minWidth:'530px'
    },
    tableRowStyle:{
      cursor:'pointer',
      background:'#fff',
      "& .tableRowHoverText":{
          fontWeight:'500',
          textTransform:'capitalize'
      }
  }
  })
)

export default CookiePixels