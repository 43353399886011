import React, { useState, useEffect} from 'react'
import { useSelector } from 'react-redux';
import { Box, makeStyles, createStyles, Typography, Button, Grid } from '@material-ui/core'
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import copy from 'copy-to-clipboard';




const CopyPixelCode = (props) =>{
	const classes= useStyles()
	const { copyContent, name, mailBodyContent } = props
    const [openToaster, setOpenToaster] = useState(false)

    const copybtnHandler = () =>{
		copy(copyContent)
		setOpenToaster(true)
	}

	


	
	return(
		<Box className={props.containerStyle}> 

            <Grid container alignItems='center' style={{}}>

                <Grid item md={7} className={classes.linkContainer}>
                    <Typography className={classes.pixelText}>
                        {copyContent}
                    </Typography>
                </Grid>
                {/* <Grid item md={1}>

                </Grid> */}
                <Grid item md={4}>
                    <Button  variant='contained' onClick={copybtnHandler} style={{marginLeft:'1rem', whiteSpace:'nowrap'}}>
                        Copy {name}
                    </Button>
                </Grid>
                {/* <Grid item md={3}>
                    <Button component="a" href={`mailto:?subject=Influence integration with ${appName}&body=Just testing`}  variant='outlined' onClick={copybtnHandler} style={{marginLeft:'1rem'}}>
                        Send to Developer
                    </Button>
                </Grid> */}

            </Grid>


            <Snackbar 
                open={openToaster}
                autoHideDuration={3000} 
                onClose={()=>setOpenToaster(false)}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <Alert variant="filled" style={{background:'black'}}>
                    <div style={{display:'flex',width:'auto'}}>
                        <div style={{fontSize:'11x',paddingLeft:'6px',fontWeight:'400'}}>Code Copied to Clipboard</div>
                    </div>
                </Alert>
            </Snackbar>

			
		</Box>
	)
}


const useStyles = makeStyles(() =>
	createStyles({
		topContainer:{
			display: 'flex',
			justifyContent: 'space-between',
			marginBottom: '8px'
		},
		copyBtn:{
			fontSize: '12px !important',
			borderRadius: '4px',
			color: '#fcfdff',
			width: '121px',
			height:'32px',
			fontWeight:'400',
			marginRight: '0 !important'
		},
		linkContainer:{
			backgroundColor: '#e6e6e6',
			padding: '8px 15px',
			borderRadius: '4px',
			// marginTop: '1%',
			boxShadow: '0px 0px 4px #aaaaaa',
			border:'1px dotted #707070'
		},
		pixelText:{
			fontFamily: 'Poppins',
			fontSize: '12px',
			color: '#2e364b',
			margin: '0 !important',
			letterSpacing: '-0.345px'
		}
	})
)

export default CopyPixelCode
