import React from "react";
import Box from '@material-ui/core/Box';
import { Paper } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';

const  NotificationCardSkeleton = () => {

  return (
    <Box 
      component={Paper}
      width="320px"
      height="70px"
      boxShadow="rgba(36, 35, 40, 0.1) 10px 20px 40px 0px !important"
      borderRadius="30px !important"
    >
      <Box
        display='flex'
        justifyContent="space-between"
        alignItems="center"
        padding="4px 19px 4px 10px"
      >
        <Skeleton variant="circle" width="50px" height="50px" />
        <Box>
          <Skeleton variant="text" width="210px" height="20px" />
          <Skeleton variant="text" width="150px" height="20px" />
          <Skeleton variant="text" width="180px" height="20px" />
        </Box>
      </Box>
    </Box>
  );
}

export default NotificationCardSkeleton
