import React from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Box, Typography, Button } from '@material-ui/core'
import { forgotPassword, clearForgotPasswordError } from 'ducks/auth';
import { withStyles } from "@material-ui/core/styles";
import TextInput from  '../../Globals/GlobalComponents/TextInput'


function validate(password, authEmail){
	return{
		password: password.length === 0,
		authEmail: authEmail === false
	}
}

class ForgetPassword extends React.Component{

	constructor() {
		super();
		this.state = {
			email: '',
			authEmail: false,
			emailError: '',
			showemailbtn: false,
			usersemail: ''
		};
	}

	emailHandler = (event) =>{
		this.setState({email: event.target.value})
	}

	handleEmailChange(evt) {
		this.props.clearForgotPasswordError();
		this.setState({email: evt.target.value, emailError: ''});
	}

	// checkEmail(evt) {
	// 	var Emailexpr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
	// 	if (!Emailexpr.test(evt.target.value))
	// 		this.setState({emailError: 'Enter a valid Email id'});
	// 	else
	// 		this.setState({authEmail: true, emailError: ''});
	// }

	handleSubmit(evt) {
		if (!this.canBeSubmitted()) {
			evt.preventDefault();
			return;
		} else {
			evt.preventDefault();
			const data = {
				'email': this.state.email
			};
			let useremail = this.state.email.split('@');
			this.props.forgotPassword(data);
			this.props.clearForgotPasswordError();
			this.setState({email: '', emailError: '',showemailbtn:true, usersemail:useremail[1] });
		}
	}

	canBeSubmitted() {
		const errors = validate(this.state.email, this.state.password, this.state.authEmail);
		const isDisabled = Object.keys(errors).some(x => errors[x]);
		return !isDisabled;
	}

	render(){
		const {classes, showemailbtn ,usersemail} = this.props
		return(
			<Box style={{display:'flex',alignItems:'center',justifyContent:'center',height:'100vh' ,textAlign: 'center', paddignTop: '6%'}}>
				<form onSubmit={this.handleSubmit.bind(this)} mehtod="POST">
					<Typography
						style={{
							color: '#353535',
							fontSize: '3rem'
						}}
					>
						Forgot your password?
					</Typography>
					{
						this.state.showemailbtn ? 
							<Typography
								style={{
									color: '#999999',
									fontSize: '1em',
									margin:'2rem 0'
								}}
							>
								Reset link has been sent to your mail
							</Typography>:
							<div>
								<Typography
									style={{
										color: '#999999',
										fontSize: '1.2em',
										marginTop:'10%'
									}}
								>
									Enter your registered Email id below.
								</Typography>
								<TextInput
									placeholder="Enter Email"
									// onChange={this.emailHandler}
									value={this.state.email}
									className={classes.inputBox}
									// onBlur={this.checkEmail.bind(this)}
									onChange={this.handleEmailChange.bind(this)}
									type="email"
								/>
							</div>
					}
					
					<Button variant="contained" type= "submit" className={classes.submitBtn} to="/login" component={this.state.showemailbtn ? Link : 'button'}
						onClick={showemailbtn ? () => window.open(`https://www.${usersemail}`) : ''}
					>
						{this.state.showemailbtn? 'Back to Login' : 'Send reset password email'}
					</Button>
				</form>
			</Box>
		)
	}
}


const useStyles= () =>({
	inputBox:{
		width: '100%',
		height: '35px',
		padding: '0% 3%',
		borderRadius: '3px',
		//backgroundColor: '#ececec',
		backgroundColor: '#ffffff',
		margin:'5% 0%',
		//maxWidth: '320px',
		//minWidth: '250px',

	},
	submitBtn:{
		backgroundColor: '#4270fd !important',
		color: '#fff',
		width: '100%',
		maxWidth: '325px',
		minWidth: '250px',
		borderRadius: '6px !important'
	}

})

const mapStateToProps = state => ({
	error: state.getIn(['auth', 'forgetError'])
});

const mapDispatchToProps = {
	forgotPassword,
	clearForgotPasswordError
};


// export default withStyles(useStyles)(ForgetPassword)


export default compose(connect(mapStateToProps,mapDispatchToProps, null, {forwardRef: true}),withStyles(useStyles),)(ForgetPassword);