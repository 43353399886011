import { call, put, fork, takeLatest } from 'redux-saga/effects';
import * as api from 'services/api';
import * as actions from 'ducks/elastic';
import { load, loaded } from 'ducks/loading';
// import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';
// import { activeWebsites } from '../ducks/elastic';

// const errorMessge = 'Please try again or refresh!';

// const toastConfig = {
//   position: toast.POSITION.TOP_CENTER,
//   autoClose: 2000,
//   className: 'toast-style'
// };

function* fetch(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `elasticsearch/query?trackingId=${action.query}&host=${action.host}`);
    yield put(actions.fetchSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* fetchSignupData(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `elasticsearch/signupNotification?trackingId=${action.trackingId}&host=${action.host}`);
    yield put(actions.fetchSignupSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* fetchVisitorData(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `elasticsearch/visitorNotification?campaignId=${action.campaignId}`);
    yield put(actions.fetchVisitorSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* countryVisitors() {

  /* eslint-disable */
  const body= JSON.stringify({"index":["filebeat-*"],"ignore_unavailable":true,"preference":1531657334094},
{"size":0,"_source":{"excludes":[]},"aggs":{"2":{"terms":{"field":"json.value.geo.country","size":100,"order":{"2-orderAgg":"desc"}},"aggs":{"1":{"cardinality":{"field":"json.value.visitorId"}},"2-orderAgg":{"cardinality":{"field":"json.value.visitorId"}}}}},"stored_fields":["*"],"script_fields":{},"docvalue_fields":["@timestamp"],"query":{"bool":{"must":[{"match_all":{}},{"range":{"@timestamp":{"gte":1531593000000,"lte":1531679399999,"format":"epoch_millis"}}}],"filter":[],"should":[],"must_not":[]}}}
);
  /* eslint-enable */

  try {
    yield put(load());
    yield call(api.POSTELASTIC, 'elasticsearch/_msearch', body);
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* mapGraph(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'elasticsearch/map', action.trackingIds);
    yield put(actions.mapSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* heatMapGraph(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'elasticsearch/heatmap', action.trackingIds);
    yield put(actions.heatMapSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* deleteAnalyticsUser(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `elasticsearch/delete/${action._index}/${action.id}/${action.userType}/${action.trackingId}`);
    if(res.error)
      Sentry.captureException(res.error);
    else
      yield put(actions.popAnalyticsUser(action.index));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* updateAnalyticsUser(action) {
  try {
    yield put(load());
    const res = yield call(api.PUT, `elasticsearch/update/${action.id}/${action.userType}/${action.trackingId}?city=${action.city}&country=${action.country}`);
    if(res.error)
      Sentry.captureException(res.error);
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* activeWebsites() {
  try {
    yield put(load());
    const res = yield call(api.GET, 'elasticsearch/website');
    if(res.error)
      Sentry.captureException(res.error);
    else
      yield put(actions.activeWebsitesSuccess(res.message));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

export function* watchFetch() {
  yield takeLatest(actions.FETCH, fetch);
}

export function* watchCountryVisitors() {
  yield takeLatest(actions.COUNTRY_VISITORS, countryVisitors);
}

export function* watchMapGraph() {
  yield takeLatest(actions.MAP_GRAPH, mapGraph);
}

export function* watchHeatMapGraph() {
  yield takeLatest(actions.HEAT_MAP_GRAPH, heatMapGraph);
}

export function* watchDeleteAnalyticsUser() {
  yield takeLatest(actions.DELETE_ANALYTICS_USER, deleteAnalyticsUser);
}

export function* watchActiveWebsites() {
  yield takeLatest(actions.ACTIVE_WEBSITES, activeWebsites);
}

export function* watchFetchSignupData() {
  yield takeLatest(actions.FETCH_SIGNUP, fetchSignupData);
}

export function* watchFetchVisitorData() {
  yield takeLatest(actions.FETCH_VISITOR, fetchVisitorData);
}

export function* watchUpdateAnalyticsUser() {
  yield takeLatest(actions.UPDATE_ANALYTICS_USER, updateAnalyticsUser);
}


export default function* rootSaga() {
  yield [
    fork(watchFetch),
    fork(watchMapGraph),
    fork(watchCountryVisitors),
    fork(watchHeatMapGraph),
    fork(watchDeleteAnalyticsUser),
    fork(watchActiveWebsites),
    fork(watchFetchSignupData),
    fork(watchFetchVisitorData),
    fork(watchUpdateAnalyticsUser)
  ];
}
