import React from 'react'
import { Box, makeStyles, createStyles } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const CampaignSettingsSekelton  = (props) => {
    const classes = useStyles()
    return(
        <Box className={props.className} style={{padding:'3rem', ...props.style}}>
            <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', height:'45px', marginTop:'15px'}}>
                <Skeleton variant="text" width={130} height={45}/>
                <Skeleton variant="text" width={400} height={45}/>
            </Box>
            <Skeleton variant="rect" width={'100%'} height={60} style={{marginTop:'2%'}}/>
            <Skeleton variant="rect" width={'100%'} height={200} style={{marginTop:'4%'}}/>
        </Box>
    )
}

const useStyles = makeStyles(theme =>
    createStyles({

    })
)

export default CampaignSettingsSekelton