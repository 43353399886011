import React, {useState, memo, Fragment} from 'react'
import { Box, makeStyles, createStyles, Grid, Typography, Checkbox } from '@material-ui/core'
// import TextInput from '../../../../Globals/GlobalComponents/TextInput'
import InputBox from '../../../../Globals/GlobalComponents/InputBox'
import CustomHelpIcon from '../../../../Globals/GlobalComponents/CustomHelpIcon';
import UpgradeBanner from '../../../../Globals/GlobalComponents/UpgradeBanner'
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';




const AdditionalFeaturesBox = (props) =>{
	
	const {
        setReviewSettings, 
        handlePropertyChange,
        recentText2,
        poweredBy,
        poweredByLink,
        togglePoweredBy,
        brandingLocked
    } = props

    // const [checkBoxStatus1, setCheckBoxStatus1] = useState(false);
    const classes = useStyles()
    // const handleCheckBox1 = () => {
    //     if(checkBoxStatus1 === false) {
    //         setCheckBoxStatus1(true);
    //     } else setCheckBoxStatus1(false);
    // }

	return(
		<Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            {
                brandingLocked &&
                    <UpgradeBanner message={"Upgrade to Advanced Plan to Edit Branding"}/>
            }
            <Grid container>
                <Grid item md={12}>
                    <Grid container alignItems='center'>
                        <Grid item>
                            <Checkbox 
                                checked={togglePoweredBy}
                                name="togglePoweredBy"
                                onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, setReviewSettings)}
                                disabled = {brandingLocked}
                            />
                        </Grid>
                        <Grid item md={5}>
                            <Typography className={classes.boxTitle}>
                                Notification Branding

                            {/* <a href={helpArticleLinks.faq.notification.branding} target="_blank">
                                <img src = {HelpQuestion}  style={{ height: "13px",marginLeft:"2%"}}/>
                            </a> */}
                                <InfluenceHelpIcon href={helpArticleLinks.faq.notification.branding} fontSize="tiny"/>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {togglePoweredBy &&
                    <Fragment>
                        <Grid item md={12} className={classes.lowerBoxPadding}>
                            <Grid container alignItems="baseline">
                                <Grid item md={3} className={classes.gridPading}>
                                    <Typography variant="body2">"By" Text</Typography>
                                </Grid>
                                <Grid item md={9}>
                                    <InputBox
                                        name="recentText2"
                                        dispatcher={setReviewSettings}
                                        value={recentText2}
                                        onChange={handlePropertyChange}
                                        disabled = {brandingLocked}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12} className={classes.lowerBoxPadding}>
                            <Grid container alignItems="baseline">
                                <Grid item md={3} className={classes.gridPading}>
                                    <Typography variant="body2">Powered by</Typography>
                                </Grid>
                                <Grid item md={9}>
                                    <InputBox
                                        name="poweredBy"
                                        dispatcher={setReviewSettings}
                                        value={poweredBy}
                                        placeholder={"influence"}
                                        onChange={handlePropertyChange}
                                        disabled = {brandingLocked}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12} className={classes.lowerBoxPadding}>
                            <Grid container alignItems="baseline">
                                <Grid item md={3} className={classes.gridPading}>
                                    <Typography variant="body2">Powered by Link</Typography>
                                </Grid>
                                <Grid item md={9}>
                                    <InputBox
                                        name="poweredByLink"
                                        dispatcher={setReviewSettings}
                                        value={poweredByLink}
                                        onChange={handlePropertyChange}
                                        disabled = {brandingLocked}
                                        maxLength={150}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fragment>    
                }
            </Grid>
        </Box>
	)

}


const useStyles = makeStyles(theme =>
	createStyles({
        input: {
            height: '35px',
            backgroundColor: '#fff',
            marginTop: '4%',
            border: ' 0.5px solid rgba(0, 0, 0, 0.23)',
            width: '100%',
            borderRadius: '4px',
            padding: '0% 3%',
            fontSize: '13px'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            // paddingBottom: '5%'
        },
        gridPading:{
            paddingTop: '3%'
        },
        lowerBoxPadding:{
            paddingLeft: '2%'
        }
	})
)


export default memo(AdditionalFeaturesBox);
