import { fromJS} from 'immutable';

const action = name => `/elastic/${name}`;

export const FETCH = action('FETCH');
export const FETCH_SUCCESS = action('FETCH_SUCCESS');
export const CLEAR_ELASTIC = action('CLEAR_ELASTIC');
export const COUNTRY_VISITORS = action('COUNTRY_VISITORS');
export const MAP_GRAPH = action('MAP_GRAPH');
export const MAP_SUCCESS = action('MAP_SUCCESS');
export const HEAT_MAP_GRAPH = action('HEAT_MAP_GRAPH');
export const HEAT_MAP_SUCCESS = action('HEAT_MAP_SUCCESS');
export const DELETE_ANALYTICS_USER = action('DELETE_ANALYTICS_USER');
export const POP_ANALYTICS_USER = action('POP_ANALYTICS_USER');
export const ACTIVE_WEBSITES = action('ACTIVE_WEBSITES');
export const ACTIVE_WEBSITES_SUCCESS = action('ACTIVE_WEBSITES_SUCCESS');
export const FETCH_SIGNUP = action('FETCH_SIGNUP');
export const FETCH_SUCCESS_SIGNUP = action('FETCH_SUCCESS_SIGNUP');
export const FETCH_VISITOR = action('FETCH_VISITOR');
export const FETCH_SUCCESS_VISITOR = action('FETCH_SUCCESS_VISITOR');
export const UPDATE_ANALYTICS_USER = action('UPDATE_ANALYTICS_USER');

export const countryVisitors = () => ({ type: COUNTRY_VISITORS });
export const fetchElastic = (query, host) => ({ type: FETCH, query, host });
export const fetchSuccess = (elastic) => ({ type: FETCH_SUCCESS, elastic });
export const clearElastic = () => ({ type: CLEAR_ELASTIC });
export const mapGraph = (trackingIds) => ({ type: MAP_GRAPH, trackingIds });
export const mapSuccess = (mapData) => ({ type: MAP_SUCCESS, mapData });
export const heatMapGraph = (trackingIds) => ({ type: HEAT_MAP_GRAPH, trackingIds });
export const heatMapSuccess = (heatMapData) => ({ type: HEAT_MAP_SUCCESS, heatMapData });
export const deleteAnalyticsUser = (_index,id, userType, index, trackingId) => ({ type: DELETE_ANALYTICS_USER,_index, id, userType, index,trackingId });
export const popAnalyticsUser = (index) => ({ type: POP_ANALYTICS_USER, index });
export const updateAnalyticsUser = (id, userType, trackingId,city,country) => ({ type: UPDATE_ANALYTICS_USER, id, userType, trackingId,city,country });
export const activeWebsites = () => ({ type: ACTIVE_WEBSITES });
export const activeWebsitesSuccess = (activewebsites) => ({ type: ACTIVE_WEBSITES_SUCCESS, activewebsites });
export const fetchSignupData = (trackingId,host) => ({ type: FETCH_SIGNUP, trackingId, host });
export const fetchSignupSuccess = (elastic) => ({ type: FETCH_SUCCESS_SIGNUP, elastic });
export const fetchVisitorData = (campaignId) => ({ type: FETCH_VISITOR, campaignId });
export const fetchVisitorSuccess = (elastic) => ({ type: FETCH_SUCCESS_VISITOR, elastic });

const initialState = fromJS({});

const elastic = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return state.set('elastic', action.elastic);
    case FETCH_SUCCESS_SIGNUP:
      return state.set('elasticSignup', action.elastic);
    case FETCH_SUCCESS_VISITOR:
      return state.set('elasticVisitor', action.elastic);
    case CLEAR_ELASTIC:
      return state.set('elastic', undefined);
    case MAP_SUCCESS:
      return state.set('map', action.mapData);
    case HEAT_MAP_SUCCESS:
      return state.set('heatmap', action.heatMapData);
    case ACTIVE_WEBSITES_SUCCESS:
      return state.set('activewebsites', action.activewebsites); 
    default:
      return state;
  }
};

export default elastic;
