import { fromJS } from 'immutable';
import { CREATE_GOAL } from '../../components/Goals/action';
import { GET_ANALYTICS_DATA } from './action';
import { GET_CAMPAIGN_ANALYTICS, DELETE_SIGNUP } from './action';

const initialState = fromJS({});

export const AnalyticsData = (state = initialState, action) => {
    switch(action.type){
        case GET_ANALYTICS_DATA:
            return state.set('analyticsData', action.payload);
        case GET_CAMPAIGN_ANALYTICS:
            return state.set('campaignAnalyticsData', action.payload);
        case DELETE_SIGNUP:
            return state.set('campaignAnalyticsData', action.payload);
        default :
            return state;
    }
}