import { fromJS } from 'immutable';

const action = name => `/goal/${name}`;

export const FETCH = action('FETCH');
export const FETCH_CAMPAIGN = action('FETCH_CAMPAIGN');
export const CREATE = action('CREATE');
export const UPDATE = action('UPDATE');
export const REMOVE = action('REMOVE');
export const SUCCESS = action('SUCCESS');
export const FETCH_SUCCESS = action('FETCH_SUCCESS');
export const CREATE_SUCCESS = action('CREATE_SUCCESS');
export const UPDATE_SUCCESS = action('UPDATE_SUCCESS');
export const POP_GOAL = action('POP_GOAL');
export const FETCH_GOAL_COUNTER = action('FETCH_GOAL_COUNTER');


export const fetchGoal = (trackingId) => ({ type: FETCH, trackingId });
export const fetchGoalByCampaign = (campaignId) => ({ type: FETCH_CAMPAIGN, campaignId });
export const createGoal = (goal) => ({ type: CREATE, goal });
export const updateGoal = (goal) => ({ type: UPDATE, goal });
export const fetchSuccess = (goal) => ({ type: FETCH_SUCCESS, goal });
export const createSuccess = (goal) => ({ type: CREATE_SUCCESS, goal });
export const removeGoal = (id, index) => ({ type: REMOVE, id, index });
export const popGoal = (index) => ({ type: POP_GOAL, index });
export const successGoal = (goal) => ({ type: CREATE_SUCCESS, goal });
export const refreshGoalCount = (trackingId) => ({ type: FETCH_GOAL_COUNTER, trackingId });


const initialState = fromJS({});
const goal = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return state.set('goal', action.goal);
    case CREATE_SUCCESS:
      return state.set('goal', state.get('goal').concat(action.goal));
    case POP_GOAL:
      return state.set('goal', state.get('goal').slice(0,action.index).concat(state.get('goal').slice(action.index+1)));
    default:
      return state;
  }
};

export default goal;
