import React, { useState, useEffect } from 'react'
import { Box, makeStyles, createStyles } from '@material-ui/core'
import CreationStepper from './CreationStepper';
import CookieStep1 from './CookieStep1';
import CookieStep2 from './CookieStep2';
import CookieStep3 from './CookieStep3';
import { useDispatch, useSelector } from 'react-redux';
import { getCookieCampaigns, saveOpenedCookieCamapign } from '../actions';



const CookieCampaignCreation = (props) => {

    const classes = useStyles()
    const [activeStep, setActiveStep] = useState(0)
    const dispatch = useDispatch()
	const cookieCampaigns = useSelector(state => (state.getIn(['cookieCampaigns','cookieCampaigns'])))


	const saveCurrentCampaignToRedux = (allCampaigns, id) =>{
		dispatch(saveOpenedCookieCamapign(allCampaigns.find((item)=>item._id===id)))
	}


	useEffect(()=>{
		cookieCampaigns ? saveCurrentCampaignToRedux(cookieCampaigns, props.params.id) : dispatch(getCookieCampaigns(props.params.id))
		return () => {
			dispatch(saveOpenedCookieCamapign({}))
		}
	},[cookieCampaigns])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };
    return (
        <Box style={{marginTop:'-24px'}}>
            <Box style={{width:'60%', margin:'0 auto'}}>
                <CreationStepper
                    handleNext={handleNext}
                    handleBack={handleBack}
                    activeStep={activeStep}
                />
            </Box>
            <Box className={classes.stepContentBox}>
                {
                    activeStep === 0 ?
                        <CookieStep1 handleNext={handleNext}/>:
                    activeStep === 1?
                        <CookieStep2 handleNext={handleNext} handleBack={handleBack}/>:
                    activeStep === 2 ?
                        <CookieStep3/>:
                        <CookieStep1 handleNext={handleNext}/>
                }
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(()=>
    createStyles({
        stepContentBox:{
            width:'60%',
            margin:'0 auto', 
            magrinTop:'2rem', 
            backgroundColor:'#fff', 
            borderRadius:'4px',
            border:'1px solid #c6c6c6',
            padding:'5%'
        }
    })
)

export default CookieCampaignCreation
