import React, { useEffect } from 'react'
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import Pill from '../../../Globals/GlobalComponents/Pill'
import { getProvider } from '../actions';
import { useSelector, useDispatch } from 'react-redux';
import InfluenceHelpIcon from '../../../Globals/GlobalComponents/InfluenceHelpIcon';

function AutoBlocking(props) {

    const  providers= useSelector(state => (state.getIn(['providerOps','getProvider'])))
    const dispatch = useDispatch()

    useEffect(()=>{
        !providers && dispatch(getProvider(props.params.id))
    },[])

    const questionMarkLine = " Influence can automate part of your website compliance by disabling third party scripts as they load, and enable them when your users consent. We're constantly adding support for more third parties, but for those that are currently not supported, you may manually block them."

    return (
        <Box>
            <Typography variant="h5" style={{ marginBottom:'15px'}}>
                Blocked Script  <InfluenceHelpIcon href="https://google.com"/>
            </Typography>
            <Typography >
               
            </Typography>

            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Provider</TableCell>
                            <TableCell>Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            providers && providers.map((provider, index)=>(
                                <TableRow key={index} hover>
                                    <TableCell>
                                        {provider.provider}
                                    </TableCell>
                                    <TableCell>
                                        <Pill type="Purple">{provider.type}</Pill>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default AutoBlocking
