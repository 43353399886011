import React, { memo } from 'react';
import { Box, makeStyles, createStyles, Grid, Typography, Popover, Button } from '@material-ui/core';
import SlidingBar from '../../../../Globals/GlobalComponents/SlidingBar';
import { ChromePicker } from 'react-color';
import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
import FileBase64 from 'react-file-base64';
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';



const AppearanceBox  = (props) => {
    const classes = useStyles();

    const {
        radius,
        backgroundColor,
        toggleMap,
        handlePanelStyleChange,
        setRecentSettings,
        backgroundColorPopper,
        anchorEl,
        handlePropertyChange,
        setBgColorValue,
        setBackgroundColorPopper,
        setAnchorEl
    } = props;

    const handleBackgroundColorPopper = (event) => {
        if(backgroundColorPopper === false) {
            setBackgroundColorPopper(true);
            setAnchorEl(event.currentTarget)

        } else {
            setBackgroundColorPopper(false)
            setAnchorEl(null)
        }
    }

    const rgbSelector = (obj, stateName) =>{
        stateName(`rgba(${obj.r},${obj.g},${obj.b},${obj.a})`)
    }

    const handleImageUpload = (file) => {
        handlePanelStyleChange('image', file.base64, setRecentSettings)
    }

    return(
        <Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container>
                <Grid item md={12}>
                    <Typography className={classes.boxTitle}>Appearance</Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={3}>
                            <Typography variant="body2">Radius</Typography>
                        </Grid>
                        <Grid item md={9}>
                            <SlidingBar value={radius} name="radius" onChange={handlePanelStyleChange} dispatcher={setRecentSettings}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems='center' style={{paddingTop:'6px'}}>
                        <Grid item md={3}>
                            <Typography variant="body2">Background Color</Typography>
                        </Grid>
                        <Grid item md={3}>
                            <div 
                                className={backgroundColorPopper ?  classes.colorIconActive : classes.colorIcon} 
                                onClick={handleBackgroundColorPopper} 
                                style={{backgroundColor: `rgb(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b})`}}></div>
                            <Popover
                                open={backgroundColorPopper} 
                                onClose={handleBackgroundColorPopper} 
                                anchorOrigin={{vertical: 'bottom',horizontal: 'center',}} 
                                transformOrigin={{vertical: 'top',horizontal: 'right',}}
                                anchorEl={anchorEl}
                            >
                                <ChromePicker 
                                    color={backgroundColor} 
                                    onChange={(event) => {
                                        handlePanelStyleChange("backgroundColor", event.rgb, setRecentSettings)
                                        rgbSelector(event.rgb, setBgColorValue)
                                    }} 
                                />
                            </Popover>
                        </Grid>
                        <Grid item md={3}>
                            <Typography variant="body2">
                                Display Icon
                                {/* <a href={helpArticleLinks.faq.notification.displayIconBulk} target="_blank">
                                    <img src = {HelpQuestion}  style={{ height: "11px",marginLeft:"2%"}}/>
                                </a> */}
                                <InfluenceHelpIcon href={helpArticleLinks.faq.notification.displayIconBulk} fontSize="tiny"/>
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <ReactSelect 
                                width='120px' 
                                height='35px' 
                                value={toggleMap}
                                name="toggleMap"
                                dispatcher={setRecentSettings}
                                onChange={handlePropertyChange}
                                options= {[
                                    {label:"Map", value:"map"},
                                    {label:"Image", value:'image'}
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {toggleMap === 'image' &&
                    <Grid item md={12} style={{paddingTop:'6px'}}>
                        <Grid container>
                            <Grid item md={5} style={{fontSize:'14px',marginTop:'4px'}}>
                                <FileBase64 onDone={handleImageUpload}/>
                            </Grid>
                            <Grid item md={3}>
                                <Button  variant="contained" disableElevation onClick={() => handlePanelStyleChange('image', "", setRecentSettings)}>
                                    Default image
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        button1: {
            fontSize: "13px!important",
            backgroundColor: "#2c66c3 !important",
            textTransform: "initial",
            width: "114px",
            height: "32px",
            fontWeight:'100'
        },
        colorIcon: {
            width: '35px',
            height: '35px',
            border: '1px solid #000',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        colorIconActive: {
            width: '35px',
            height: '35px',
            border: '0.5px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            paddingBottom: '2%'
        },
    })
)

export default memo(AppearanceBox);