import { fromJS, Map } from 'immutable'

const initialState = fromJS({
  loading: false,
  data: [],
  error: {
    status: false,
    message: '',
  },
})

export const POLICIES = {
  LOADING: 'POLICIES_DATA_LOADING',
  LOADED: 'POICIES_DATA_LOADED',
  FETCHED: 'POLICIES_DATA_FETCHD',
  ERROR: 'POLICIES_DATA_ERROR',
}

const policiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case POLICIES.LOADING:
      return state.merge({ loading: true, error: { status: false, message: '' } })
    case POLICIES.LOADED:
      return state.set('loading', false)
    case POLICIES.FETCHED:
      return state.merge({ loading: false, data: action.payload })
    case POLICIES.ERROR:
      return state.merge({ loading: false, error: { status: true, message: action.payload } })
    default:
      return state
  }
}


export default policiesReducer