import React from 'react';
import {  makeStyles, createStyles } from '@material-ui/core';
// import ClearIcon from '@material-ui/icons/Clear';
import Select from 'react-select';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// const names = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder',
// ];

// function getStyles(name, personName, theme) {
    
//     return {
//         fontWeight:
//         personName.indexOf(name) === -1
//             ? theme.typography.fontWeightRegular
//             : theme.typography.fontWeightMedium,
//     };
// }

const SelectMultiple  = (props) => {
    const classes = usestyles();
    // const theme = useTheme();
    const {isSearchable=true} = props
    // const [personName, setPersonName] = React.useState([]);
    // const handleChange = event => {
    //     setPersonName(event.target.value);
    // };
    const customStyle = {
          control: base => ({
            ...base,
            background:isSearchable === true ? '#fff' : 'rgb(30, 104, 198)',
            color:'#fff',
            minHeight:'35px',
            // height:'40px',
            cursor: 'pointer',
            fontSize: 13,
            marginTop:'2%',
            fontWeight: 'inherit'
            //color:isSearchable === true ? 'black' :'#fff'
          }),
          placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: isSearchable ? 'black':'#ffffff',
            }
          },
          singleValue: base => ({
            ...base,
            top: '47%',
            color: isSearchable === true ? 'black' : '#fff'
          }),
          menu: styles => ({ 
            ...styles, 
            zIndex: 999,
            fontSize: 12
          }),
          dropdownIndicator: (base, state) => ({
            ...base,
            transition: 'all .2s ease',
            color:isSearchable === true ? 'grey' : '#fff',
            "&:hover": {
              color:isSearchable === true ? 'grey' : '#fff'
            },
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
          }),
          IndicatorSeparator:()=>({display:'none'})
        };

    const { options,value,onChange, placeholder="Select Country...", name="notificationFromCountry", ...restProps } = props;

    return(
            // <FormControl className={classes.formControl}> 
            //     <InputLabel id="demo-mutiple-chip-label">{props.title}</InputLabel>
            //     <Select
            //     className={classes.selectEmpty}
            //     labelId="demo-mutiple-chip-label"
            //     id="demo-mutiple-chip"
            //     multiple
            //     style={{width:props.width}}
            //     value={personName}
            //     onChange={handleChange}
            //     input={<Input id="select-multiple-chip" />}
            //     renderValue={selected => (
            //         <div className={classes.chips}>
            //         {selected.map(value => (
            //             <Chip key={value} label={value} deleteIcon={<ClearIcon/>} className={classes.chip} />
            //         ))}
            //         </div>
            //     )}
            //     MenuProps={MenuProps}
            //     >
            //     {
            //         props.options.map(name => (
            //             <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
            //                 {name}
            //             </MenuItem>
            //         ))
            //     }
            //     </Select>
            // </FormControl>
            

            <Select
                isMulti
                name={name}
                styles={customStyle} 
                placeholder={placeholder}
                className={classes.countryNotif}
                value={value}
                onChange={onChange}
                options={options} 
                {...restProps}
            />

    );
}

const usestyles = makeStyles(theme =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
            width: '100%'
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
            backgroundColor: '#f5f6f7',
            paddingLeft: '4%',
            height:'50px'
        },
        countryNotif:{
            backgroundColor: '#fff !important',
            '& css-1aya2g8' :{
                backgroundColor: '#fff !important'
            }
        }

          
    })
)

export default SelectMultiple ;



