import { useEffect } from 'react'

/**
 * Change title of the document according to the current page
 * @param {string} title Title of the current page
 */

const useTitle = (title) =>{
	useEffect(()=>{
		document.title = `${title} | Influence | Increase Conversion Rate by 17% Using Social Proof`
	},[])
}


export default useTitle