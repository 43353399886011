import React from "react";
import Box from '@material-ui/core/Box';
import { Divider } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';

const NotificationRuleSkeleton = () => {
  return (
    <Box>
      <Box>
        <Box display="flex" alignItems="center" padding="20px">
          <Skeleton variant="text" width="70px" height="16px" />
          <Skeleton variant="text" width="100px" height="35px" style={{marginLeft:'30px'}} />
        </Box>
        <Box display="flex" alignItems="center" padding="20px">
          <Skeleton variant="text" width="70px" height="16px" />
          <Skeleton variant="text" width="100px" height="35px" style={{marginLeft:'30px'}} />
        </Box>
        <Box display="flex" alignItems="center" padding="20px">
          <Skeleton variant="text" width="70px" height="16px" />
          <Skeleton variant="text" width="100px" height="35px" style={{marginLeft:'30px'}} />
        </Box>
      </Box>

      <Divider />

      <Box>
        <Box display="flex" alignItems="center" padding="15px">
          <Skeleton variant="text" width="70px" height="16px" />
          <Skeleton variant="text" width="480px" height="35px" style={{marginLeft:'30px'}} />
        </Box>
        <Box display="flex" alignItems="center" padding="15px">
          <Skeleton variant="text" width="70px" height="16px" />
          <Skeleton variant="circle" width="35px" height="35px" style={{marginLeft:'30px'}} />
        </Box>
        <Box display="flex" alignItems="center" padding="15px">
          <Skeleton variant="text" width="70px" height="16px" />
          <Skeleton variant="text" width="100px" height="35px" style={{marginLeft:'30px'}} />
          <Skeleton variant="text" width="70px" height="16px" style={{marginLeft:'20px'}} />
          <Skeleton variant="text" width="100px" height="35px" style={{marginLeft:'30px'}} />
        </Box>
      </Box>

      <Divider />

      <Box display="flex" alignItems="center" padding="20px">
        <Skeleton variant="text" width="70px" height="16px" />
        <Skeleton variant="text" width="100px" height="35px" style={{marginLeft:'30px'}} />
        <Skeleton variant="text" width="70px" height="16px" style={{marginLeft:'20px'}} />
        <Skeleton variant="text" width="100px" height="35px" style={{marginLeft:'30px'}} />
      </Box>

      <Divider />

      <Box>
        <Box display="flex" alignItems="center" padding="15px">
          <Skeleton variant="text" width="70px" height="16px" />
          <Skeleton variant="text" width="300px" height="35px" style={{marginLeft:'30px'}} />
        </Box>
        <Box display="flex" alignItems="center" padding="15px">
          <Skeleton variant="text" width="70px" height="16px" />
          <Skeleton variant="text" width="150px" height="35px" style={{marginLeft:'30px'}} />
          <Skeleton variant="text" width="150px" height="35px" style={{marginLeft:'30px'}} />
        </Box>
        <Box display="flex" alignItems="center" padding="15px">
          <Skeleton variant="text" width="70px" height="16px" />
          <Skeleton variant="text" width="300px" height="35px" style={{marginLeft:'30px'}} />
        </Box>
        <Box display="flex" alignItems="center" padding="15px">
          <Skeleton variant="text" width="70px" height="16px" />
          <Skeleton variant="text" width="150px" height="35px" style={{marginLeft:'30px'}} />
          <Skeleton variant="text" width="150px" height="35px" style={{marginLeft:'30px'}} />
        </Box>
      </Box>

      <Divider />

      <Box>
        <Box display="flex" alignItems="center" padding="15px">
          <Skeleton variant="text" width="70px" height="16px" />
          <Skeleton variant="text" width="480px" height="35px" style={{marginLeft:'30px'}} />
        </Box>
        <Box display="flex" alignItems="center" padding="15px">
          <Skeleton variant="text" width="70px" height="16px" />
          <Skeleton variant="circle" width="35px" height="35px" style={{marginLeft:'30px'}} />
        </Box>
        <Box display="flex" alignItems="center" padding="15px">
          <Skeleton variant="text" width="70px" height="16px" />
          <Skeleton variant="text" width="100px" height="35px" style={{marginLeft:'30px'}} />
          <Skeleton variant="text" width="70px" height="16px" style={{marginLeft:'20px'}} />
          <Skeleton variant="text" width="100px" height="35px" style={{marginLeft:'30px'}} />
        </Box>
      </Box>
    </Box>
  );
}

export default NotificationRuleSkeleton;
