import { fromJS } from 'immutable';

const action = name => `/restream/${name}`;

export const FETCH = action('FETCH');
export const FETCH_SUCCESS = action('FETCH_SUCCESS');
export const CREATE = action('CREATE');
export const CREATE_SUCCESS = action('CREATE_SUCCESS');
export const UPDATE = action('UPDATE');
export const REMOVE = action('REMOVE');
export const POP_Restream = action('POP_Restream');

export const getRestream = (campaignId) => ({ type: FETCH, campaignId });
export const addRestream = (restream) => ({ type: CREATE, restream });
export const updateRestream = (restream) => ({ type: UPDATE, restream });
export const deleteRestream = (id, index) => ({ type: REMOVE, id, index });
export const succeessRestream = (data) => ({ type: FETCH_SUCCESS, data });
export const popRestream = (index) => ({ type: POP_Restream, index });

const initialState = fromJS({});
const restream = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return state.set('restream', action.data);  
    case POP_Restream:
      return state.set('restream', state.get('restream').slice(0, action.index).concat(state.get('restream').slice(action.index+1)));
    default:
      return state;
  }
};

export default restream;
