import { fromJS } from 'immutable';

const action = name => `/payment/${name}`;

export const FETCH = action('FETCH');
export const CREATE = action('CREATE');
export const UPDATE = action('UPDATE');
export const SUCCESS = action('SUCCESS');
export const FETCH_INVOICES = action('FETCH_INVOICES');
export const DOWNLOAD_INVOICE = action('DOWNLOAD_INVOICE');
export const SUCCESS_INVOICE = action('SUCCESS_INVOICE');
export const UPDATE_PAYMENT_METHOD = action('UPDATE_PAYMENT_METHOD');
export const UPGRADE_PLAN = action('UPGRADE_PLAN');
export const FETCH_CARDS = action('FETCH_CARDS');
export const FETCH_CARDS_SUCCESS = action('FETCH_CARDS_SUCCESS');
export const CREATE_AGREEMENT = action('CREATE_AGREEMENT');
export const CREATE_PAYPAL_PAYMENT = action('CREATE_PAYPAL_PAYMENT');
export const PAYMENT_STATUS = action('PAYMENT_STATUS');
export const CREATE_PAYPAL_ONETIME = action('CREATE_PAYPAL_ONETIME');
export const CREATE_FUND_PAYMENT = action('CREATE_FUND_PAYMENT');
export const CHECK_COUPEN_PAYMENT = action('CHECK_COUPEN_PAYMENT');
export const UPGRADE_COUPEN_PLAN = action('UPGRADE_COUPEN_PLAN');
export const SHOPIFY_RECURRERING_DATA = action('SHOPIFY_RECURRERING_DATA');

export const fetchPayment = () => ({ type: FETCH });
export const fetchInvoices = () => ({ type: FETCH_INVOICES });
export const createPayment = (payment, profile, update) => ({ type: CREATE, payment, profile, update });
export const updatePayment = (payment) => ({ type: UPDATE, payment });
export const updatePaymentMethod = (details) => ({ type: UPDATE_PAYMENT_METHOD, details });
export const upgradePlan = (plan) => ({ type: UPGRADE_PLAN, plan });
export const downloadInvoice = (invoice_id) => ({ type: DOWNLOAD_INVOICE, invoice_id });
export const successPayment = (payment) => ({ type: SUCCESS, payment });
export const successInvoice = (invoices) => ({ type: SUCCESS_INVOICE, invoices });
export const fetchCards = () => ({ type: FETCH_CARDS });
export const fetchCardsSuccess = (cards) => ({ type: FETCH_CARDS_SUCCESS, cards });
export const createAgreement = (details) => ({ type: CREATE_AGREEMENT, details });
export const createPaypalPayment = (token) => ({ type: CREATE_PAYPAL_PAYMENT, token });
export const paymentStatus = (status) => ({ type: PAYMENT_STATUS, status });
export const createPaypalOnetine = (plan, paypal) => ({ type: CREATE_PAYPAL_ONETIME, plan, paypal });
export const makeFundPayment = (data) => ({ type: CREATE_FUND_PAYMENT, data });
export const checkCoupenAvailability = (data) => ({ type: CHECK_COUPEN_PAYMENT, data });
export const upgradeCoupenPlan = (plan) => ({ type: UPGRADE_COUPEN_PLAN, plan });
export const shopifyRecurringPayment = (plan, trackingId, userId) => ({ type: SHOPIFY_RECURRERING_DATA, plan, trackingId, userId });

const initialState = fromJS({});

const payment = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS:
      return state.set('payments', action.payment);
    case SUCCESS_INVOICE:
      return state.set('invoices', action.invoices);
    case FETCH_CARDS_SUCCESS:
      return state.set('cards', action.cards);
    case PAYMENT_STATUS:
      return state.set('status', action.status);
    default:
      return state;
  }
};

export default payment;
