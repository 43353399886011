import React, { useEffect } from 'react'
import { Box, createStyles,makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import Moment from 'react-moment';
import { getProperty } from '../../../utilities/utilities';




const get1 =(obj, path, def)=> {
	var fullPath = path
		.replace(/\[/g, '.')
		.replace(/]/g, '')
		.split('.')
		.filter(Boolean);

	return fullPath.every(everyFunc) ? obj : def;

	function everyFunc(step) {
		return !(step && (obj = obj[step]) === undefined);
	}
}


const GoalPageLinks = (props) => {

    const {linksArray, linkClickData} = props

    const classes = useStyles()



    return (
        <Box style={{marginRight:'5%', padding:'2% 0', marginTop:'2%'}}>
            <Typography variant="h6">Clicked Links</Typography>
            <Box style={{marginTop:'10px'}}>
                <TableContainer style={{maxHeight:'650px', minHeight: '100px',padding:"0% 0%", boxShadow: '0px -1px 2px 0px rgba(198, 198, 198, 0.32)'}} className={classes.testing}>
                    <Table stickyHeader style={{borderCollapse: 'collapse'}}>
                        <TableHead style={{borderBottom: '1px solid #e8e8e8', backgroundColor: '#fafbfc'}}>
                            <TableRow>
                                <TableCell>Link</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>Path</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                linkClickData?
                                    linkClickData.length > 0 ?
                                    linkClickData.map((data, index)=>(
                                        <TableRow key={index} className={classes.tableRowStyle}>
                                            <TableCell>{`${data._source.json.value.linkData.linkUrl}`}</TableCell>
                                            {/* <TableCell>{`${_.get(data, '_source.json.value.linkData.linkUrl','Nil')}, ${_.get(data, '_source.json.value.geo.country','count')}`}</TableCell> */}
                                            <TableCell>
                                                {getProperty(data, '_source.json.value.geo.city', 'unkown' ) + ", " + getProperty(data, '_source.json.value.geo.country', 'unkown' )}
                                            </TableCell>
                                            <TableCell title={data._source.json.value.timestamp}>
                                                <Moment  format={"MMM D, h:mm a"} date={(data._source.json.value.timestamp)}/>
                                            </TableCell>
                                            <TableCell>{`${data._source.json.value.source.url.protocol}//${data._source.json.value.source.url.hostname}${data._source.json.value.source.url.pathname}`}</TableCell>
                                        </TableRow>
                                    )):
                                    <TableRow className={classes.tableRowStyle}>
                                        <TableCell>There is no data.</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>:
                                    <TableRow className={classes.tableRowStyle}>
                                        <TableCell>There is no data.</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}


const useStyles = makeStyles((theme)=>
    createStyles({
        tableRowStyle:{
            backgroundColor:'#fff',
            transition: '0.2s !important',
            "&:hover": {
                backgroundColor: "#ebecf0",
                '& $campaignNameStyle':{
                    color: '#2c5cc5'
                }
            }
        }
    })
)

export default GoalPageLinks
