import React, { useState, useEffect } from 'react'
import { Box, makeStyles, createStyles, Snackbar, Tooltip } from '@material-ui/core'
import PolicyOutlinedIcon from '@material-ui/icons/PolicyOutlined'
import { Link } from 'react-router'
import ReactTooltip from 'react-tooltip'
import { checkWhitelable } from 'services/permissions'
import { Cog, CookieIcon, SocialProofIcon } from 'img'
import CreateCampDialogForm from './CreateCampDialogForm'
import { useSelector } from 'react-redux'
import Alert from '@material-ui/lab/Alert'
import { getProperty } from '../../utilities/utilities'
import { LockIcon } from '../../img'
import clsx from 'clsx'
import { quotaExhausted } from '../../utilities/quotaExhausted'
const applefont =
  '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'

const NewSideBar = (props) => {
  const { user, setBreadCrumbs } = props
  const profile = useSelector((state) => state.getIn(['profile', 'profile']))
  // const user = useSelector(state=>(state.getIn(['auth','user'])))
  const campaignInfo = useSelector((state) => state.getIn(['CampaignInfo', 'campaignInfo'])) || []

  const classes = useStyles()
  const [activeClassState, setActiveClassState] = useState('')
  const [activeNav, setActiveNav] = useState(0)
  const [dialogStatus, setDialogStatus] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastOpening, setToastOpening] = useState('')
  const [isSocialProofAllowed, setIsSocialProofAllowed] = useState(false)
  const [isCookieAllowed, setIsCookieAllowed] = useState(false)

  useEffect(() => {
    checkAccess()
  }, [user])

  const checkAccess = () => {
    if ('access' in user) {
      setIsCookieAllowed(getProperty(user, '.access.isCookie', false))
      setIsSocialProofAllowed(getProperty(user, '.access.isSocialProof', false))
    }
  }

  // useEffect(()=>{
  //     console.log({isSocialProofAllowed})
  //     console.log({isCookieAllowed})
  // },[isCookieAllowed, isSocialProofAllowed])

  const handleBreadCrumbs = (crumb) => {
    const breadcrumb = {
      name: crumb.name,
      path: crumb.path,
    }
    setBreadCrumbs([breadcrumb])
    setActiveClassState(crumb.name)
  }
  const handleDialogOpen = () => {
    setDialogStatus(true)
    // if (campaignInfo.length < getPermissions(profile.plan.id).noOfCampaignAllowed) {
    //   setDialogStatus(true)
    // } else {
    //   setToastOpening(true)
    //   setToastMessage('Only one campaign is allowed in your plan')
    // }
  }

  const handleClose = () => {
    setDialogStatus(false)
  }

  const closeHandler = () => {
    setDialogStatus(false)
  }

  const handleCloseToast = () => {
    setToastOpening(false)
  }

  const addActiveClass = (index) => {
    // if (index == activeNav) console.log('asdctive---------')
    if (index == activeNav) return 'active'
    else return ' '
  }

  const activeNavHandler = () => {
    const currURL = window.location.href
    if (currURL.includes('/cookie-monster')) setActiveNav(1)
    else if (currURL.includes('/policy-generator')) setActiveNav(2)
    else if (currURL.includes('/settings')) setActiveNav(3)
    else if (currURL.includes('/campaigns') || currURL.includes('/goals')) setActiveNav(0)
    else if (currURL.includes('/installPixelPage')) setActiveNav(0)
    else if (currURL.includes('/getting-started')) setActiveNav(0)
    else if (currURL.includes('/analytics')) setActiveNav(0)
    else setActiveNav(null)
  }

  useEffect(() => {
    activeNavHandler()
  })

  return (
    <Box
      className={classes.sideBarContainer}
      className={clsx({
        [classes.sideBarContainer]: true,
        upgradeStrip: quotaExhausted(profile) && window.location.pathname !== '/upgrade-plan',
      })}
    >
      <Snackbar
        open={toastOpening}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert variant="filled" style={{ background: 'black' }}>
          <div style={{ display: 'flex', width: 'auto' }}>
            {/* <div><DoneAllRoundedIcon style={{color:'#00b300',fontSize:'19px'}}/></div> */}
            <div style={{ fontSize: '11x', paddingLeft: '6px', fontWeight: '400' }}>
              {toastMessage}
            </div>
          </div>
        </Alert>
      </Snackbar>

      <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box style={{ width: '100%' }}>
          <ul style={{ listStyle: 'none', padding: '0' }}>
            <li className={classes.navLinkBtns}>
              <CustomTooltop arrow title={'Social Proof'}>
                <Link
                  to={'/campaigns'}
                  className={`${addActiveClass(0)}  ${
                    isSocialProofAllowed ? '' : classes.lokedIcon
                  }`}
                >
                  {/* <img alt="home" className={classes.iconImage} src={SocialProofIcon}/> */}
                  <SocialProofIcon alt="home" className={classes.iconImage} />
                </Link>
              </CustomTooltop>
            </li>

            <li className={classes.navLinkBtns}>
              <CustomTooltop title={'Cookie Monster'}>
                <Link
                  className={`${addActiveClass(1)}  ${isCookieAllowed ? '' : classes.lokedIcon}`}
                  to={'/cookie-monster/campaigns'}
                >
                  {/* <img src={CookieIcon} className={classes.iconImage} /> */}
                  <CookieIcon className={classes.iconImage} />
                </Link>
              </CustomTooltop>
            </li>

            <li className={classes.navLinkBtns}>
              <CustomTooltop title={'Policy Generator'}>
                <Link
                  className={`${addActiveClass(2)} ${classes.lokedIcon}  ${
                    isCookieAllowed ? ' ' : classes.lokedIcon
                  }`}
                  to={'/policy-generator/policies'}
                >
                  <PolicyOutlinedIcon className={classes.iconImage} />
                </Link>
              </CustomTooltop>
            </li>

            {/* <li className={classes.navLinkBtns} onClick={()=>handleBreadCrumbs({name: 'Install Pixel', path: '/installPixelPage'})}>
                            <CustomTooltop title={"Install Pixel"}>
                                <Link to="/installPixelPage" className={addActiveClass(2)} >
                                    <img className={classes.iconImage} src = {Plug} className={classes.iconImage}/>
                                </Link>
                            </CustomTooltop>
                        </li> */}

            {/* <li className={classes.navLinkBtns} onClick={()=>handleBreadCrumbs({name: 'Getting Started', path: '/getting-started'})}>
                            <CustomTooltop title={"Getting Started"}>
                                <Link to="/getting-started" style={{position: 'relative'}} className={addActiveClass(3)}>
                                    <span className={classes.redDot}></span>
                                    <img alt="help" className={classes.iconImage} src = {QuestionCircle} />
                                </Link>
                            </CustomTooltop>
                        </li> */}
            {checkWhitelable() || profile.hasOwnProperty('masterBrandUrl') ? null : (
              <li className={classes.navLinkBtns}>
                <CustomTooltop title={'Settings'}>
                  <Link className={addActiveClass(3)} to="/settings">
                    {/* <img alt="settings" className={classes.iconImage} src = {Cog} /> */}
                    <Cog alt="settings" className={classes.iconImage} />
                  </Link>
                </CustomTooltop>
              </li>
            )}
          </ul>
        </Box>
      </Box>
      <ReactTooltip effect="solid" backgroundColor="green" className={classes.tooltipStyle} />

      <CreateCampDialogForm
        open={dialogStatus}
        handleClose={handleClose}
        closeHandler={closeHandler}
      />
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    sideBarContainer: {
      width: '55px',
      height: '100%',
      position: 'fixed',
      backgroundColor: '#12344d',
      boxShadow: '0.3px 0px 3px #858585',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: theme.zIndex.sidebar.zIndex,
      marginTop: theme.custom.header.height,
      '&.upgradeStrip': {
        marginTop: theme.custom.header.height + theme.custom.upgradeStrip.height,
      },
    },
    plug: {
      height: '20px !important',
    },
    logoLink: {
      display: 'block',
      height: '70px',
    },
    logoImage: {
      height: '65px !important',
    },
    iconImage: {
      height: '18px',
    },
    addBtn: {
      backgroundColor: '#2d7bf4',
      borderRadius: '30px',
      border: 'none',
      cursor: 'pointer',
      width: '33px',
      marginTop: '10px',
      paddingTop: '0',
      height: '33px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:focus': {
        outline: 'none',
      },
    },
    navLinkBtns: {
      color: '#fff',
      fontSize: '16px',
      marginLeft: '0px',
      marginTop: '16px',
      userSelect: 'none',
      '& > a': {
        color: '#fff',
        // display: 'block',
        height: '40px',
        width: '40px',
        // padding: '11px 16px',
        borderRadius: '6px',
        // transition: '0.2s',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          backgroundColor: 'rgba(221,241,255,.25)',
        },
        '&:active': {
          boxShadow: 'inset 0 0 0.8rem 0 rgba(76,78,86,.3)',
          backgroundColor: 'rgba(221,241,255,.5)',
        },
      },
      '& > a.active': {
        backgroundColor: '#007bff',
      },
    },

    lokedIcon: {
      position: 'relative',
      '&:before': {
        content: '" "',
        position: 'absolute',
        width: '100%',
        height: '100%',
        // backgroundColor:'gray',
        opacity: '0.5',
        borderRadius: 'inherit',
        backdropFilter: 'blur(8px)',
      },
      '&:after': {
        content: '" "',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        opacity: '0.5',
        // background: `url("data:image/svg+xml;utf8,${lockIconSvg}") no-repeat center center`,
        backgroundImage: cssLockBackgroundIage,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top',
        borderRadius: 'inherit',
        backgroundSize: '11px',
      },
    },

    tooltipStyle: {
      fontFamily: applefont,
      background: '#000',
      opacity: '0',
    },
    redDot: {
      height: '8px',
      width: '8px',
      backgroundColor: 'red',
      borderRadius: '50%',
      position: 'absolute',
      boxShadow: '0px 0px 15px 2px rgba(255,0,0,0.8)',
      animation: `$redGlow 2.5s infinite`,
      MozAnimation: `$redGlow 2.5s infinite`,
      top: '7px',
      right: '10px',
    },
    '@keyframes redGlow': {
      '0%': {
        transform: 'scale(0)',
      },
      '50%': {
        transform: 'scale(1)',
      },
      '100%': {
        transform: 'scale(0)',
      },
    },
  })
)

export default NewSideBar

const useStylesTooltip = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    padding: '8px 12px',
    fontSize: '13px',
    fontWeight: '600',
    color: '#222',
    boxShadow: '0 4px 14px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.05)',
    borderRadius: '6px',
    lineHeight: '20px',
  },
}))

/**
 * Custom tooltip
 * @param {Object} props Takes props of `Tooltip` of material ui
 */
const CustomTooltop = (props) => {
  const classes = useStylesTooltip()
  return <Tooltip placement="right" arrow classes={classes} {...props} />
}

const cssLockBackgroundIage = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='%23000'%3E%3Cpath d='M400 224h-16v-62.5C384 73.1 312.9.3 224.5 0 136-.3 64 71.6 64 160v64H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zM96 160c0-70.6 57.4-128 128-128s128 57.4 128 128v64H96v-64zm304 320H48c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16v192c0 8.8-7.2 16-16 16z'/%3E%3C/svg%3E");`
