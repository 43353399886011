import React, { useState,useEffect } from 'react';
import HelpIcon from "@material-ui/icons/Help";
import { makeStyles } from "@material-ui/core/styles";



const CustomHelpIcon = (props) =>{

	const{styles={}, fontSize= "small", href} = props
	const classes = useStyles();


	return(
		<a href={href} target="_blank" style = {{...styles, paddingLeft: '3px'}}>
			{
				fontSize === "small" ?
					<HelpIcon
						style = {styles}
						fontSize = {fontSize}
						className = {classes.iconStyle}
						classes = {{fontSizeSmall: classes.fontSizeSmall}}
					/> :
				fontSize === "medium" ?
					<HelpIcon
						style = {styles}
						fontSize = {fontSize}
						className = {classes.iconStyle}
						classes = {{root: classes.medium}}
					/>:
				fontSize === "large"?
					<HelpIcon
						style = {styles}
						fontSize = {fontSize}
						className = {classes.iconStyle}
						classes = {{root: classes.large}}
					/>:
				fontSize === 'default'?
					<HelpIcon
						style = {{}}
						className = {classes.iconStyle}
						fontSize= "small"
						classes = {{fontSizeSmall: classes.fontSizeSmall}}
						// classes = {{root: classes.large}}
					/>:
					<HelpIcon
						style = {styles}
						fontSize = "default"
						className = {classes.iconStyle}
						classes = {{fontSizeSmall: classes.fontSizeSmall}}
						// classes = {{root: classes.large}}
					/>
			}
			
		</a>
	)
}


const useStyles = makeStyles(theme => ({
	iconStyle: {
		borderRadius: '50%',
		color: "#2c66c3",
        cursor: "pointer",
	},
	fontSizeSmall:{
		fontSize: '15px'
	},
	medium:{
		fontSize: '12px'
	},
	large:{
		fontSize: '30px'
		
		
		
	}
}))


export default CustomHelpIcon