import React from 'react'
import { Box, makeStyles, createStyles, Paper, Typography } from '@material-ui/core'
import AnalyticsGraph from './AnalyticsGraph'

const _ = require('lodash')

const Analytics = ({ analyticsData, selectedDuration }) => {
  const classes = useStyles()

  const conversionCalulator = (analyticsData) => {
    ;(analyticsData !== undefined && analyticsData.currentTimeWindowData.total.signups > 0) ||
    analyticsData.currentTimeWindowData.total.uniqueVisitors > 0
      ? `${(
          (analyticsData.currentTimeWindowData.total.signups /
            analyticsData.currentTimeWindowData.total.uniqueVisitors) *
          100
        ).toFixed(2)} %`
      : '--'
  }

  const exp = () => {
    var totalSignups = _.get(analyticsData, 'currentTimeWindowData.total.signups')
    var totalUniueVisitors = _.get(analyticsData, 'currentTimeWindowData.total.uniqueVisitors')

    if (totalSignups > 0 && totalUniueVisitors > 0) {
      return `${((totalSignups / totalUniueVisitors) * 100).toFixed(2)}%`
    } else {
      return '--'
    }
  }

  const cardData = [
    {
      value: _.get(analyticsData, 'currentTimeWindowData.total.uniqueVisitors'),
      name: 'Visitor Events',
      grow: true,
      color: '#246201',
    },
    {
      value: `${
        _.get(analyticsData, 'conversionGoal_current') !== undefined
          ? parseFloat(_.get(analyticsData, 'conversionGoal_current')).toFixed(2)
          : 0
      }%`,
      name: 'Goal Completion',
      grow: true,
      color: '#bcd514',
    },
    {
      value: _.get(analyticsData, 'currentTimeWindowData.total.pageView'),
      name: 'Page Views',
      grow: true,
      color: '#028690',
    },
    {
      value: _.get(analyticsData, 'currentTimeWindowData.total.hover'),
      name: 'Hovers',
      grow: false,
      color: '#e04427',
    },
    {
      value: _.get(analyticsData, 'currentTimeWindowData.total.clicks'),
      name: 'Clicks',
      grow: false,
      color: '#59c1ca',
    },
    {
      value: _.get(analyticsData, 'currentTimeWindowData.total.signups'),
      name: 'Signups',
      grow: false,
      color: '#c042be',
    },
    {
      value: exp(),
      name: 'Conversions %',
      grow: false,
      color: '#e04427',
    },
  ]

  return (
    <Box
      style={{
        position: 'relative',
        marginTop: '5rem',
        border: '1px solid rgba(0,0,0,.19)',
        borderRadius: '5px',
      }}
    >
      <Box className={classes.cardContainer}>
        {cardData.map((data, index) => (
          <Box className={classes.cardStyle} key={index}>
            <Typography className={classes.cardNameStyle}>{data.name}</Typography>
            <Typography
              style={{ color: data.color, whiteSpace: 'nowrap' }}
              className={classes.cardValueStyle}
            >
              {data.value === '' || !data.value ? '--' : data.value}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box
        component={Paper}
        elevation={0}
        style={{ paddingTop: '4%' }}
        padding="2% 3%"
        width="100%"
      >
        <AnalyticsGraph selectedDuration={selectedDuration} />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    cardContainer: {
      display: 'flex',
      backgroundColor: 'transparent',
      justifyContent: 'center',
      margin: '30px',
      top: '-17%',
      width: '95%',
      position: 'absolute',
    },
    cardStyle: {
      border: '1px solid rgba(0,0,0,.19)',
      borderRadius: '5px',
      padding: '5px 10px',
      textAlign: 'center',
      width: '150px',
      height: '90px',
      backgroundColor: '#fff',
      margin: '0 10px',
    },
    cardNameStyle: {
      margin: '0 !important',
			whiteSpace:"nowrap",
      color: '#8c9392',
      fontSize: '11px !important',
      textTransform: 'uppercase',
      fontWeight: '600',
      letterSpacing: '1px',
      padding: '7px 0',
    },
    cardValueStyle: {
      fontSize: '30px !important',
      margin: '0 !important',
      fontWeight: '300',
    },
    [theme.breakpoints.down('md')]: {
      cardStyle: {
        padding: '5px',
      },
      cardNameStyle: {
        fontSize: '10px !important',
      },
      cardValueStyle: {
        fontSize: '1.6rem !important',
      },
    },
  })
)

export default Analytics
