import axios from 'axios'


export const GET_WEBSITES = 'GET_WEBSITES'
export const PIXEL_VERIFY = 'PIXEL_VERIFY'
export const PIXEL_VERIFICATION = 'PIXEL_VERIFICATION'
export const GET_PIXEL_DETAILS = 'GET_PIXEL_DETAILS'






export const getWebsites = () =>{
	const authToken = JSON.parse(localStorage.getItem('authToken'));
	const token = authToken ? authToken.token : null;
	return(
		async(dispatch) =>{
			const res = await axios ({
				method: 'GET',
				url: `/elasticsearch/website`,
				headers: {'Authorization': `Bearer ${token}`},
			}).catch(err =>{throw err})
			dispatch({type: GET_WEBSITES, payload: res.data.message})
			return(res)
		}
	)
}


export const pixelVerify = (setLoading) =>{
	const authToken = JSON.parse(localStorage.getItem('authToken'));
	const token = authToken ? authToken.token : null;
	return(
		async(dispatch) =>{
			const res = await axios ({
				method: 'GET',
				url: '/campaign/user/verification',
				headers: {'Authorization': `Bearer ${token}`}
			}).catch(err =>{throw err})
			dispatch({type: PIXEL_VERIFY, payload: res.data})
			
			const res1 = await axios ({
				method: 'GET',
				url: '/campaign/user/pixelVerify',
				headers: {'Authorization': `Bearer ${token}`}
			}).catch(err =>{throw err})
			dispatch({type: PIXEL_VERIFICATION, payload: res1.data})
			setLoading(false)
			return(res)

		}
	)
}


export const getPixelData = (setLoading) => {
  const authToken = JSON.parse(localStorage.getItem('authToken'))
  const token = authToken ? authToken.token : null
  return async (dispatch) => {
    const res = await axios({
      method: 'GET',
      url: '/pixelDetail',
      headers: { Authorization: `Bearer ${token}` },
    }).catch((err) => {
      throw err
    })
    dispatch({ type: GET_PIXEL_DETAILS, payload: res.data })
    if  (setLoading) setLoading(false)
    return res
  }
}