import React, { useReducer, useEffect, useState, useRef, memo } from 'react';
import { Box, makeStyles, createStyles } from '@material-ui/core';
import BulkNotificationCard from './BulkNotificationCard';
import BulkNotificationRules from './BulkNotificationRules';
import NotifSettingsReducer, { initialState } from '../../NotifSettingsReducer';
import { useSelector } from 'react-redux';
import translate from 'translate';
// import CustomLoading from '../../../utils/customLoading';
// import CircularProgress from '@material-ui/core/CircularProgress';
import NotificationCardSkeleton from '../../../../Globals/GlobalComponents/NotificationCardSkeleton';
import NotificationRuleSkeleton from '../../../../Globals/GlobalComponents/NotificationRuleSkeleton';



translate.engine = 'google';
// translate.key = 'AIzaSyDB2KZyXOUPBC3v0SWh7-vBzg_gTlpLc30';
translate.key = process.env.REACT_APP_GOOGLE_TRANSLATE_KEY;

const BulkNotificationSettings  = (props) => {
    const classes = useStyles();
    const {
        handlePropertyChange, 
        handlePanelStyleChange,
        handleInputChange, 
        ruleName, 
        //notificationConfiguration,
        campaignInfo,
        notificationType,
        handleLanguageChange,
        handleNotificationSubmit,
        loading,
        notifBoxBGColor,
        brandingLocked,
        onClose
    } = props;

    const [bulkSettings, setBulkSettings] = useReducer(NotifSettingsReducer, initialState);
    const [visitorText, setVisitorText] = useState(bulkSettings.visitorText);
    const [otherText, setOtherText] = useState("");
    const [contentText, setContentText] = useState("");
    const [bulkText, setBulkText] = useState("");
    const [bulkDaysLable, setBulkDaysLable] = useState("");
    const [recentText2, setRecentText2] = useState("");
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        },2000)
    },[])


    const lastLangRef = useRef()

    // lastLangRef.current = bulkSettings.langName.language

    const languageHandler = (name, value, dispatcher) =>{
        
        lastLangRef.current = bulkSettings.langName.language
        translate(visitorText, {from: bulkSettings.langName.language, to: value}).then(
            text => {
                setVisitorText(text)
                handlePropertyChange('visitorText', text, setBulkSettings)
            }
        )
        translate(otherText, {from: bulkSettings.langName.language, to: value }).then(
            text => {
                setOtherText(text)
                handlePropertyChange('otherText', text, setBulkSettings)
            }
        )
        translate(contentText, {from: bulkSettings.langName.language, to: value }).then(
            text => {
                setContentText(text)
                handlePropertyChange('contentText', text, setBulkSettings)
            }
        )
        translate(bulkText, {from: bulkSettings.langName.language, to: value }).then(
            text => {
                setBulkText(text)
                handlePropertyChange('bulkText', text, setBulkSettings)
            }
        )
        translate(bulkDaysLable, {from: bulkSettings.langName.language, to: value }).then(
            text => {
                setBulkDaysLable(text)
                handlePropertyChange('bulkDaysLable', text, setBulkSettings)
            }
        )
        translate(recentText2, {from: bulkSettings.langName.language, to: value }).then(
            text => {
                setRecentText2(text)
                handlePropertyChange('recentText2', text, setBulkSettings)
            }
        )

        handleLanguageChange(name, value, dispatcher)

    }


    // useEffect(() => {
    //     lastLangRef.current = bulkSettings.langName.language;
    //     translate(visitorText, {from: lastLang, to: bulkSettings.langName.language}).then(
    //         text => {
    //             setVisitorText(text)
    //             // handlePropertyChange('visitorText', text, setBulkSettings)
    //         }
    //     )
    //     translate(otherText, {from: lastLang, to: bulkSettings.langName.language }).then(
    //         text => {
    //             setOtherText(text)
    //             // handlePropertyChange('otherText', text, setBulkSettings)
    //         }
    //     )
    //     translate(contentText, {from: lastLang, to: bulkSettings.langName.language }).then(
    //         text => {
    //             setContentText(text)
    //             // handlePropertyChange('contentText', text, setBulkSettings)
    //         }
    //     )
    //     translate(bulkText, {from: lastLang, to: bulkSettings.langName.language }).then(
    //         text => {
    //             setBulkText(text)
    //             // handlePropertyChange('bulkText', text, setBulkSettings)
    //         }
    //     )
    //     translate(bulkDaysLable, {from: lastLang, to: bulkSettings.langName.language }).then(
    //         text => {
    //             setBulkDaysLable(text)
    //             // handlePropertyChange('bulkDaysLable', text, setBulkSettings)
    //         }
    //     )
    //     translate(recentText2, {from: lastLang, to: bulkSettings.langName.language }).then(
    //         text => {
    //             setRecentText2(text)
    //             // handlePropertyChange('recentText2', text, setBulkSettings)
    //         }
    //     )

    // },[
    //     bulkSettings.langName.language
    //     // bulkSettings.visitorText,
    //     // bulkSettings.otherText,
    //     // bulkSettings.contentText,
    //     // bulkSettings.bulkText,
    //     // bulkSettings.recentText2,
    //     // bulkSettings.bulkDaysLable
    // ])

    const lastLang = lastLangRef.current

    
    const notificationSettings = useSelector(state => (state.getIn(['NotificationSettings', 'notificationConfiguration'])))

    useEffect(()=>{
        setVisitorText(bulkSettings.visitorText)
    },[bulkSettings.visitorText])

    useEffect(()=>{
        setOtherText(bulkSettings.otherText)
    },[bulkSettings.otherText])

    useEffect(()=>{
        setContentText(bulkSettings.contentText)
    },[bulkSettings.contentText])

    useEffect(()=>{
        setBulkText(bulkSettings.bulkText)
    },[bulkSettings.bulkText])

    useEffect(()=>{
        setBulkDaysLable(bulkSettings.bulkDaysLable)
    },[bulkSettings.bulkDaysLable])

    useEffect(()=>{
        setRecentText2(bulkSettings.recentText2)
    },[bulkSettings.recentText2])


    useEffect(() => {
        if(notificationSettings !== undefined){
            setBulkSettings({type: 'INIT', data: notificationSettings.find(item => item.notificationType.notificationName === ruleName)})
        }
    },[notificationSettings])


    
    
    return(
        
        show ? 
            <Box>
                <Box style={{background: notifBoxBGColor}} className={classes.notificationBox} padding='5% 0%'>    
                    <BulkNotificationCard 
                        settings={bulkSettings}
                        visitorText={visitorText}
                        otherText={otherText}
                        contentText={contentText}
                        bulkText={bulkText}
                        bulkDaysLable={bulkDaysLable}
                        recentText2={recentText2}
                        show={show}
                    />
                </Box>
                <Box padding='6%'>
                    <BulkNotificationRules 
                        campaignInfo={campaignInfo}
                        handlePanelStyleChange={handlePanelStyleChange}
                        handlePropertyChange={handlePropertyChange}
                        handleInputChange={handleInputChange}
                        bulkSettings={bulkSettings}
                        setBulkSettings={setBulkSettings}
                        notificationConfiguration={notificationSettings.filter(item => item.notificationType.notificationName === ruleName)}
                        notificationType={(notificationType || []).filter(item => item.notificationName === ruleName)}
                        handleLanguageChange={languageHandler}
                        handleNotificationSubmit={handleNotificationSubmit}
                        loading={loading}
                        visitorText={visitorText}
                        otherText={otherText}
                        contentText={contentText}
                        bulkText={bulkText}
                        bulkDaysLable={bulkDaysLable}
                        recentText2={recentText2}
                        brandingLocked={brandingLocked}
                        onClose={onClose}
                    />
                </Box>
            </Box>
            : 
            <Box>
                <Box style={{background: notifBoxBGColor}} className={classes.notificationBox} padding='5% 0%'>
                    <NotificationCardSkeleton />
                </Box>
                <Box padding="6%">
                    <NotificationRuleSkeleton />
                </Box>
            </Box>
            
        
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        notificationBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '7%',
            position: 'sticky',
            top: '50px',
            zIndex: '9999',
            paddingBottom: '11%',
            transition: 'all 0.7s ease-in-out',
            backdropFilter: "blur(0.8px)"
        },
        loaderBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }
    })
)

export default memo(BulkNotificationSettings) ;