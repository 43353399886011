import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles,createStyles} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    margin: 0,
    padding:'8px 14px',
    borderBottom:'1px solid #cfd7df',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#333',
    fontSize: '16px',
    fontWeight: '500', 
    textTransform: 'uppercase',
    height: '55px'
  },
  closeButton: {
    // position: "absolute",
    // right: '4px',
    // top:'5px',
    // color: theme.palette.grey[500],
    "&:hover":{
      backgroundColor:'none'
    }
  }
});


const CustomDialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    minHeight: '100px',
    display: 'flex',
    alignItems: 'center'
  }
}))(MuiDialogContent);


const CustomDialogActions = withStyles(theme => ({
  root: {
    margin: '0',
    padding: "8px 14px",
    color: '#333', 
    textTransform: 'uppercase',
    fontWeight: '500',
    height: '55px',
    borderTop: '1px solid #cfd7df'
  }
}))(MuiDialogActions);




export const DialogTitle = withStyles(styles)(props => {
  const { classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5" style={{fontWeight: '500', fontSize: '12px'}}>
        {props.children}
      </Typography>
      {onClose ? (
        <CloseIcon onClick={onClose} style={{fontSize:'16px', fontWeight: '600', color: '#333', cursor: 'pointer'}} />
      ) : null}
    </MuiDialogTitle>
  );
});




export const DialogContent = props => {
  return (
    <CustomDialogContent>
      <Typography  style={{fontSize: '12px',color:'#333'}}>{props.children}</Typography>
    </CustomDialogContent>
  );
};



export const DialogActions = props => {
  return <CustomDialogActions>{props.children}</CustomDialogActions>;
};



export const DialogButton = props => {
  const {variant='contained', actionType="primary", onClick,disabled, className} = props

  const borderDecider = (variant) =>{
    switch(variant){
      case 'outlined':
        return "1px solid #c6c6c6"
      default:
        return 'inherit'
    }
  }

  const useStyles = makeStyles(theme =>
    createStyles({
      primary:{
        backgroundColor: '#082a52',
        color: '#fff',

      },
      secondary:{
        backgroundColor: '#fff',
        color: '#333',
        border: '1px solid rgb(167 161 161 / 50%) !important',
        "&:hover":{
          backgroundColor: '#dee0da'
        }
      }
    }))

  const classes = useStyles()

  return (
    <Button
      onClick={onClick}
      disabled={disabled ? disabled : null}
      variant={actionType === 'primary' ? 'contained' : 'outlined'}
      color="primary"
      style={{
        fontSize: '12px',
        fontWeight: '500',
        textTransform: 'capitalize',
        marginBottom: '4px',
        padding: 'auto 5px',
        height: '28px',
        border: borderDecider(variant),
        lineHeight: '1.4',
        borderRadius: '3px',
      }}
      // className={classes[actionType]}
      disableElevation
    >
      {props.children}
    </Button>
  )
};



export const DialogBox = props => {

  const useStyles = createStyles(theme =>
    makeStyles({
      dialogContaniner: {
        margin: '15px 12px',
      }
    })
  )
  const classes=useStyles();

  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      fullWidth={true}
      maxWidth={'xs'}
      className={classes.dialogContaniner}
      //classes={{paper: classes.dialogPaper}} 
    >
      {props.children}
    </Dialog>
  );

 


};




/**
 *  Dialog box which is used for dialog prompts
 *  
 *  format:-
 *  
 *  <DialogBox> (all the content wrapped with it)
 *      
 *      <DialogTitle>
 *          (Contains title. don't use any other h or p element)
 *      </DialogTitle>
 *      
 *      
 *      <DialogContent>
 *        (Content/main line of the dialog)
 *      </DialogContent>
 *      
 *      
 *      <DialogActions>
 *        (use DialogButtons inside or use other buttons, dialogBttons don't need seperate styling)
 *        <DialogButton>
 *          (takes two props according to their role and action.)
 *        </DialogButton>
 *      </DialogActions>
 *    
 *  </DialogBox>
 *  
 *  
 *   
**/