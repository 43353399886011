// import React, { useState } from 'react'
// import { Box, makeStyles, createStyles, Typography, Paper, Divider, Button } from '@material-ui/core';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

// const HoverMenu = (props) =>{
//   const [active, setActive] = useState(false)

//   const testfn = () =>{
//     setActive(true)
//   }

//   const closeFn =  () =>{
//     setActive(false)
//   }

//   const classes = useStyles()
//   return(
//     <div onMouseLeave={closeFn} style={{width: '150px', margin:'auto'}} className={active ? classes.activeHoverMenu : classes.inactiveHoverMenu}>
//       {/* <CreateIcon/>
//       <CloseIcon/> */}
//       {props.children}
//       <MoreHorizIcon onMouseEnter={testfn} />
//     </div>
//   )
// }

// const useStyles = makeStyles(()=>
//   createStyles({

//     // styles when it is hovered
//     activeHoverMenu:{
//       '& svg':{
//         cursor: 'pointer',
//         border: '1px solid #c6c6c6 ',
//         transform: 'scale(1)',
//         color: '#4f566b',
//         boxSizing: 'content-box',
//         padding: '5px 10px',
//         fontSize: '16px',
//         transition: '0.05s',
//         // boxShadow: boxShadow
//         background:'rgba(255,255,255, 0.6)'
//       },
//       '& svg:last-child':{
//         borderStyle: 'solid solid solid none',
//         borderRadius: '0 6px 6px 0',
//         color:'rgb(79 86 107 / 59%)',
//         cursor:'default'
//       },
//       '& svg:first-child':{
//         borderStyle: 'solid none solid solid',
//         borderRadius: '6px 0 0 6px'
//       },
//       "& svg:not(:last-child)":{
//         '&:hover':{
//           color:'#000'
//         }
//       }
//     },

//     // styles without hover---------------
//     inactiveHoverMenu:{
//       '& svg':{
//         // display: 'none',
//         border: '1px solid transparent',
//         transform: 'scale(0)',
//         color:'#4f566b',
//         fontSize: '16px',
//         transition: '0.05s',
//         boxSizing: 'content-box',
//         padding: '4px 7px',
//       },
//       '& svg:first-child':{
//         borderStyle: 'solid none solid solid',
//         borderRadius: '6px 0 0 6px'
//       },
//       '& svg:last-child':{
//         display: 'inline',
//         transform: 'scale(1)',
//         cursor: 'pointer',
//         border: '1px solid transparent',
//         borderStyle: 'solid solid solid none',
//       }
//     }
//   })
// )

// export default HoverMenu

// /**
//  *
//  * Pass only svg icon inside this as children.
//  *
//  *
//  */

import React from 'react'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'

export const HoverMenu = (props) => {
  const { scale = 0 } = props
  const classes = useStyles({ scale: parseInt(scale) })
  return (
    <div className={classes.container}>
      <MoreHorizIcon />
      <Box className={classes.iconsContainer}>
        {/* <Edit />
				<Delete /> */}
        {props.children}
      </Box>
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      width: 'fit-content',
      cursor: 'pointer',
      // border: "1px solid transparent",
      display: 'inline-block',
      '& svg': {
        color: '#4f566b',
        fontSize: ({scale}) => 16 +(0.5* scale),
        boxSizing: 'content-box',
        '&:hover': {
          color: '#000',
        },
      },

      '&>svg': {
        // padding: '5px 10px',
        padding:({scale}) => `${5+(0.5*scale)}px ${10+(0.5*scale)}px`,
        border: '1px solid transparent',
        borderRadius: '0 5px 5px 0',
      },

      '&:hover': {
        '& svg': {
          backgroundColor: 'rgba(255,255,255, 0.6)',
        },
        '&>svg': {
          border: '1px solid #c6c6c6',
          cursor: 'default',
          color: 'rgb(79 86 107 / 59%)',

          '&:hover': {
            color: 'rgb(79 86 107 / 59%)',
          },
        },
        '&> $iconsContainer': {
          display: 'flex',
          transform: 'scaleX(1)  translateX(0)',
        },
      },
    },
    iconsContainer: {
      position: 'absolute',
      top: 0,
      right: ({scale}) => 37 + (1.7*scale),
      display: 'flex',
      transform: 'scaleX(0)  translateX(40px)',
      transitionTimingFunction: 'cubic-bezier(0.09, 0.35, 0.84, 1.19)',
      transition: '0.3s',
      transformOrigin: 'right',
      '&>svg': {
        cursor: 'pointer',
        padding: ({scale}) => `${5+(0.5*scale)}px ${10+(0.5*scale)}px`,
        border: '1px solid #c6c6c6',
        borderStyle: 'solid solid solid none',
        // margin:'1px',
        '&:hover': {
          // color:'red'
        },
      },
      '&>svg:first-child': {
        borderRadius: '5px 0 0 5px',
        borderStyle: 'solid solid solid solid',

        // border:'1px solid grey'
      },
    },
  })
)

export default HoverMenu
