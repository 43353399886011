import React,{useState, useEffect} from 'react';
import { Box, makeStyles, createStyles,Typography, Button } from '@material-ui/core';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import {browserHistory} from 'react-router';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SignupsList from './SignupsList';
import ReviewList from './ReviewList';
import Analytics from './Analytics';
import { getAnalyticsData, getCamapaignAnalytics } from './action';
import { useDispatch, useSelector } from 'react-redux';
import SelectBox from '../SelectBox';


const TabPanel = (props)=>{
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps=(index)=>{
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

const AnalyticsHeader = (props) =>{
    const classes = useStyles();
    const [value,setValue] = useState(0);
    const [selectedDuration, setSelectedDuration] = useState('7')
    const [campaignName, setCampaignName] = useState("")
    const dispatch = useDispatch();


    const campaignAnalyticsData = useSelector(state => (state.getIn(['AnalyticsData', 'campaignAnalyticsData'])));
    const campaignInfo = useSelector(state=>(state.getIn(['CampaignInfo','campaignInfo'])))

    useEffect(()=>{
        if(campaignInfo){
            const selectedCampaign = campaignInfo.filter(item => item._id === props.params.id)
            setCampaignName(selectedCampaign[0].campaignName)
        }
    },[campaignInfo, campaignAnalyticsData])

    useEffect(()=>{
        console.log('campaignAnalyticsData', campaignAnalyticsData)
    },[campaignAnalyticsData])



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDurationChange = (e) =>{
        setSelectedDuration(e.value)
    }


    useEffect(() => {
        dispatch(getAnalyticsData());
    },[props.params.id])

    useEffect(()=>{
        if(props.params.id){
            dispatch(getCamapaignAnalytics(props.params.id, parseInt(selectedDuration)))
        }
    },[selectedDuration, props.params.id])


    return(
        // <Box padding= "5% 0% 0% 5%">
        <Box>
            <Box style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1%'}}>
                <Box>
                    <Link to='/campaigns' style={{fontSize:'19px',color:'#000', lineHeight: '1.6',display:'inline-block',paddingTop:'2px', fontWeight: '500', fontFamily:'Poppins', textTransform: 'capitalize'}}>
                        {campaignName ? `${campaignName}'s Summary ` : 'Summary'}
                    </Link>
                </Box>
                <Box>
                    <Button component={Link} to={`/campaigns/${props.params.id}`} style={{width: '100px', height: '33px'}} variant="contained" >Edit</Button>
                </Box>
            </Box>
            <Box>
                <Box style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #c6c6c6'}}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        indicatorColor="primary"
                    >
                        <Tab label="Analytics" classes={{ root: classes.root }} {...a11yProps(0)}/>
                        <Tab label="Signups" classes={{ root: classes.root }} {...a11yProps(1)}/>
                        <Tab label="Reviews" classes={{ root: classes.root }} {...a11yProps(2)}/>
                        {/*<Tab label="Purchases" classes={{ root: classes.root }} {...a11yProps(2)}/>*/}
                    </Tabs>
                    {/*<Button style={{width: '100px', height: '33px'}} onClick={handleEdit}>Edit</Button>*/}
                    <Box style={{padding: '6px 0', width: '98px'}}>
                        <SelectBox
                            options={[
                                    {value: '7', label: '7 days'},
                                    {value: '14', label: '14 days'},
                                    {value: '28', label: '28 days'}
                                ]}
                            value = {selectedDuration}
                            onChange = {handleDurationChange}
                        />
                    </Box>
                </Box>
                <TabPanel value={value} index={0}>
                    <Analytics analyticsData={campaignAnalyticsData} selectedDuration={parseInt(selectedDuration)}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SignupsList campaignAnalyticsData={campaignAnalyticsData} signupData={campaignAnalyticsData && campaignAnalyticsData.currentTimeWindowData.signupDetails}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ReviewList campaignAnalyticsData={campaignAnalyticsData} reviewData={campaignAnalyticsData && campaignAnalyticsData.currentTimeWindowData.reviewDetails}/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    Purchases
                </TabPanel>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(
    createStyles({
        arrowDiv: {
            width:"32px",
            height:"31px",
            padding: '4px 5px',
            boxSizing:'border-box',
            border: '1px solid gainsboro',
            cursor:'pointer',
            marginRight:'9px ',
            borderRadius: '3px',
            backgroundColor: '#fff'
        },
        root: {
            minWidth: "65px",
            fontSize: '14px',
            fontWeight:'500',
            color: '#2d2e2c',
            textTransform: 'capitalize',
            margin: '0',
            '&:focus':{
                outline: 'none'
            }
        },
        selectBoxStyle:{
            width: '17%',
            marginTop: '19px',
            marginLeft: '27px'
        }
}))

export default AnalyticsHeader;