import React from 'react'
import { Box, makeStyles, createStyles } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const AnalyticsScreenSkeleton  = () => {
    const classes = useStyles()
    return(
        <Box>
            <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Skeleton variant="text" width={'30%'} height={50}/>
                <Skeleton variant="text" width={'30%'} height={50}/>
            </Box>
            <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between', borderBottom:'1px solid rgb(198, 198, 198)'}}>
                <Skeleton variant="text" width={'30%'} height={50}/>
                <Skeleton variant="text" width={'30%'} height={50}/>
            </Box>
            <Box style={{ marginTop:'80px'}}>
                <Skeleton variant="rect"  height={350}/>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme =>
    createStyles({

    })
)

export default AnalyticsScreenSkeleton