
import { fromJS } from 'immutable';
import { UPDATE_PROFILE_SETTINGS } from './action';
import { GET_INVOICE } from './action';
import { GET_PLANS, UPGRADE_PLAN } from './action';


const initialState = fromJS({});

export const profile = (state = initialState, action) => {
    switch(action.type){
        case UPDATE_PROFILE_SETTINGS:
            return state.set('profile', action.payload)
        case GET_PLANS:
			return state.set('allPlans', action.payload)
        default:
            return state;
    } 
}

export const Invoice = (state = initialState, action) => {
	switch(action.type){
		case GET_INVOICE:
			return state.set('invoice', action.payload)
		default:
			return state
	}
}
export const UpgradePlan = (state = initialState, action) =>{
	switch(action.type){
		case UPGRADE_PLAN:
			return state.set('UpgradePlan', action.payload)
		default:
			return state
	}
}