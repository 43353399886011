import { fromJS } from 'immutable';
import { PUT_SIGNUP, USER_PIXERL_VERIFY } from './action';

const initialState = fromJS({});

export const detailsScreenOps = (state = initialState, action) => {
    
    switch(action.type) {
        case PUT_SIGNUP :
            return state.set('putSignup', action.payload)
        default:
            return state;
    }
}

export const userPixelStatus = (state= initialState, action)=>{
    switch(action.type){
        case USER_PIXERL_VERIFY:
            return state.set('userPixelStatus', action.payload)
            default:
                return state
    }
}