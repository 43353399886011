import React from 'react'
import Box from '@material-ui/core/Box'
import Chip from "@material-ui/core/Chip";
import LinkIcon from '@material-ui/icons/Link';
import { makeStyles } from "@material-ui/core/styles";


const PageLinkChips = (props) => {

    const {linksArray, setLinksArray} = props

    const handleDelete = (index, ar) => {
        ar.splice(index,1)
        setLinksArray([...ar])
    };

    const classes = useStyles()


    return (
        <Box style={{display:'flex', alignItems:'center', flexWrap:'wrap'}}>
            {
                linksArray.map((link, index)=>(
                    <Chip
                        key={index}
                        variant="outlined"
                        color={link.verified ? "primary" : "secondary" }
                        size="small"
                        onDelete={()=>handleDelete(index, linksArray)}
                        icon={<LinkIcon />}
                        label={link.url? link.url :link}
                        style={{marginRight:'10px', marginBottom:'10px'}}
                        classes={{colorPrimary:classes.primaryStyle}}
                    />
                ))
            }
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    primaryStyle:{
        color:'green',
        border:'1px solid green',
        '& svg':{
            color:'green',
            '&:hover':{
                color:'#008000db'
            }
        }
    }
}));

export default PageLinkChips
