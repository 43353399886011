import React, {useEffect, useState, Fragment} from 'react'
import { CardElement, injectStripe } from 'react-stripe-elements';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Box, makeStyles, createStyles, Dialog, Button, Typography, CircularProgress, Hidden } from '@material-ui/core'
import { load, loaded } from '../../ducks/loading'
import { getAddedCard } from './action'
import TextInput from '../../Globals/GlobalComponents/TextInput'
import axios from 'axios'
import { Check } from '../../img'

const UpdateCard = (props) =>{
	const {open, handleClose, addedCard, addingNewCard, isCardAdded, fetchCard, disableBackdropClick=false } = props
	const classes = useStyles()
	const user = useSelector(state => (state.getIn(['auth', 'user']))) || []
	const [userName, setUserName] = useState('')
	const [loading, setLoading] = useState(false)
	

	const handleSubmit = async (e) =>{
		e.preventDefault()
		if(!userName){
			return
		}
		setLoading(true)

		let token = await props.stripe.createToken({name: userName, amount: 0 })

		const paymentMethod = await props.stripe
			.createPaymentMethod({
				type: 'card',
				card: {token: token.token.id},
				billing_details: {name: userName}
			})

		
		const authToken = JSON.parse(localStorage.getItem('authToken'));
		const tokenofAuth = authToken ? authToken.token : null;

		
		if(addingNewCard){
			await axios({
				method: 'POST',
				url: '/payment/addCard',
				headers: {'Authorization': `Bearer ${tokenofAuth}`},
				data:{
					paymentMethod: paymentMethod.paymentMethod,
					email: user.email
				}
			}).then(res=>{
				//fetchCard()
				handleClose()
			})
		} else{
			await axios({
				method: 'PUT',
				url: '/payment/servicebot/upgradeCard',
				headers: {'Authorization': `Bearer ${tokenofAuth}`},
				data:{
					paymentMethod: paymentMethod.paymentMethod,
					email: user.email
				}
			}).then(res=>{
				setLoading(false)
				setUserName("")
				//fetchCard()
			
				if(!res.err){
					setLoading(false)
					handleClose()
					//fetchCard()
				}
			})
		}
	}

	const handleUserName = (e) =>{
		setUserName(e.target.value)
		
	}


	return(
		<Dialog 
			open={open} 
			disableEscapeKeyDown={addingNewCard}
			disableBackdropClick={disableBackdropClick}
			onClose = {handleClose}
			className={classes.dialogDesign}
			maxWidth ={false}
			style={{zIndex:'3600',
			maxWidth: '100%',
    		maxHeight: '100%',
    		overflowY: 'hidden'
		}}
			
			
		>
			{/*
				!disableBackdropClick && <button onClick={handleClose} className={classes.closeBtn}>+</button>
			*/}
			{
				user.status !== 'cancel' ? 	<button onClick={handleClose} className={classes.closeBtn}>+</button> : '' }
		
			{ user.status === 'cancel' ? 	<Typography style={{fontSize: '13px',padding:'2%',color:'rgb(109 109 109)',textAlign:'center'}}>Please update your card details</Typography> :''}
			
				<Box className={classes.mainContainer}>
					<Typography style={{fontSize: '14px'}}>
						{/*
							!addingNewCard ?
								<Fragment>
									<span style={{color: '#c6c6c6'}}>
										Added card:-
									</span> 
										{addedCard.last4 ? `**** **** **** ${addedCard.last4}` : `Not Found`}
								</Fragment>:
								''
						*/}
						
					</Typography>
					<form onSubmit={handleSubmit} className={classes.formStyles}>
						<TextInput
							placeholder="Cardholder's Name"
							onChange = {handleUserName}
							value={userName}
							className={classes.inputName}
						/>
						{/*style={{padding: '10px', border: '1px solid #c6c6c6'}}*/}
						<CardElement style={{margin:'6% auto'}}/>
						<Box className={classes.updateBtn}>

							<Box style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'left',width:'50%'}}>
								{/* <img src={Check} style={{marginRight:'1%'}}/> */}
								<Check style={{marginRight:'1%'}}/>
								<em className={classes.influenceText}>Powered By Stripe</em>  
							</Box> 
							
							<Button variant='contained' disabled={loading} className={classes.updateBtn} type="submit">
							{
								loading ?
									<CircularProgress size={19} style={{color: '#fff', position: 'relative'}}/>:
									addedCard.err && user.status !== 'cancel'? 'Add Card' : 'Update Card'
							}
						</Button>
						
						</Box>
					</form>
				</Box>
		
		
		</Dialog>
	)
}


const useStyles = makeStyles(theme =>
	createStyles({
		inputName:{
			margin:'6% auto'

		},
		closeBtn:{
			fontSize: '22px',
			float: 'right',
			cursor: 'pointer',
			position: 'absolute',
			right: '4px',
			top: '6px',
			transform: 'rotate(45deg)',
			fontWeight: '400',
			height: '30px',
			width: '30px',
			lineHeight: '0.7',
			textAlign: 'center',
			backgroundColor:'transparent',
			borderRadius:'50%',
			color:'#666',
			border: 'none',
            "&:hover": {
                backgroundColor:'transparent',
                color:'#000'
            },
            "&:focus":{
                outline: 'none'
            }
       
		},
		influenceText:{
            fontSize: '9.5px',
            fontWeight: '600',
            fontFamily: 'sans-serif',
			color: '#044fff',
			marginBottom: '-1%'
            
        },
		mainContainer:{
			height: '213px',
			width: '430px',
			padding: '5%',
			borderRadius: '4px',
			boxShadow: '0 0 3px 0 rgba(0,0,0,.1)',
			backgroundColor: '#f2f2f2'

		},
		formStyles:{
			height: '100%',
			padding:'0% 5%'
		},
		verifiedIcon:{
            fontSize: '11px',
            color: '#0093fb',
            padding: '0 5px',
            marginTop: '-2px'
        },
		updateBtn:{
			fontSize: '12px',
			textTransform: 'capitalize',
			fontWeight: '300',
			//height: '35px',
			margin: '5% 0%',
			//width: '90px',
			//position: 'relative',
			//float:'right',
			display:'flex',
			flexDirection:'row',
			alignItems:'center',
			justifyContent:'space-between'

		}
	})
)

export default injectStripe(withRouter(UpdateCard))




// style={{base: {fontSize: '18px', color: 'red', border: '1px solid #c6c6c6'}}}