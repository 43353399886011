import React, { memo } from 'react';
import { Box, makeStyles, createStyles, Grid, Typography, Popover, Button } from '@material-ui/core';
import SlidingBar from '../../../../Globals/GlobalComponents/SlidingBar';
import { ChromePicker } from 'react-color';
import FileBase64 from 'react-file-base64';
import {HelpQuestion} from '../../../../img'
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';


const AppearanceBox  = (props) => {
    const classes = useStyles();

    const {
        bulkSettings,
        handlePanelStyleChange,
        setBulkSettings,
        backgroundColorPopper,
        handleBackgroundColorPopper,
        anchorEl,
        rgbSelector,
        setBgColor,
        handleImageUpload
    } = props;

    return(
        <Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container>
                <Grid item md={12} style={{marginTop:'9px'}}>
                    <Typography className={classes.boxTitle}>Appearance</Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={3}>
                            <Typography variant="body2">Radius</Typography>
                        </Grid>
                        <Grid item md={9}>
                            <SlidingBar value={bulkSettings.panelStyle.radius} name="radius" onChange={handlePanelStyleChange} dispatcher={setBulkSettings}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} style={{paddingTop: '8px'}}>
                    <Grid container alignItems='center' style={{paddingBottom: '10px'}}>
                        <Grid item md={3}>
                            <Typography variant="body2">Background Color</Typography>
                        </Grid>
                        <Grid item md={5}>
                            <div 
                                className={backgroundColorPopper ? classes.colorIconActive : classes.colorIcon} 
                                style={{backgroundColor: `rgb(${bulkSettings.panelStyle.backgroundColor.r}, ${bulkSettings.panelStyle.backgroundColor.g}, ${bulkSettings.panelStyle.backgroundColor.b})`}} 
                                onClick={handleBackgroundColorPopper}></div>
                            <Popover 
                                open={backgroundColorPopper} 
                                onClose={handleBackgroundColorPopper} 
                                anchorOrigin={{vertical: 'bottom',horizontal: 'center',}} 
                                transformOrigin={{vertical: 'top',horizontal: 'right',}}
                                anchorEl={anchorEl}
                            >
                                <ChromePicker color={bulkSettings.panelStyle.backgroundColor} 
                                    onChange={(event) => {
                                        handlePanelStyleChange("backgroundColor",event.rgb,setBulkSettings)
                                        // setBgColor1(event.rgb)
                                        rgbSelector(event.rgb, setBgColor)
                                    }} 
                                />
                            </Popover>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container style={{marginTop:'8px'}}>
                        <Grid item md={3} style={{marginTop:'11px'}}>
                            <Typography  variant="body2">
                                Display Icon
                                {/* <a href="https://support.useinfluence.co/article/1543" target="_blank">
                                    <img src = {HelpQuestion}  style={{ height: "11px",marginLeft:"2%"}}/>
                                </a> */}
                                <InfluenceHelpIcon fontSize="tiny" href="https://support.useinfluence.co/article/1543" />
                            </Typography>
                        </Grid>
                        <Grid item md={6} style={{fontSize:'14px',marginTop:'5px',paddingLeft:'8%'}}>
                            <FileBase64 
                                className={classes.fileInput}
                                onDone={handleImageUpload}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Button variant='contained' disableElevation onClick={() => handlePanelStyleChange('image', '', setBulkSettings)}>
                                Default image
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            // paddingBottom: '5%'
        },
        fileInput: {
            width: '106px'
        },
        colorIcon: {
            width: '35px',
            height: '35px',
            border: '1px solid #000',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        colorIconActive: {
            width: '35px',
            height: '35px',
            border: '2px solid rgba(201,201,201,0.7)',
            borderRadius: '50%',
            cursor: 'pointer',
        },
        button1: {
            fontSize: "13px",
            backgroundColor: "#2c66c3",
            textTransform: "initial",
            width: "114px",
            height: "32px",
            fontWeight:'100'
        }
    })
)

export default memo(AppearanceBox) ;