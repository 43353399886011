import axios from 'axios';

export const GET_NOTIFICATION_CONFIGURATION = 'GET_NOTIFICATION_CONFIGURATION';
export const GET_NOTIFICATION_TYPES = 'GET_NOTIFICATION_TYPES';
export const TOGGLE_NOTIFICATION_STATUS = 'TOGGLE_NOTIFICATION_STATUS';
export const UPDATE_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_SETTINGS';



export const getNotificationConfiguration = (id, setLoader) => {
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
    return(
        async (dispatch) => {
            const res = await axios({
                method: 'GET',
                url: `/configuration/${id}`,
            }).catch(error => {
                setLoader(false)
                throw error
            })
            dispatch({type: GET_NOTIFICATION_CONFIGURATION, payload: res.data})
            setLoader(false)
            return(res);
        }
    );
}

export const getNotificationTypes = () => {
    return(
        async (dispatch) => {
            const res = await axios.get('notificationtypes').catch(err => {throw err});
            dispatch({type: GET_NOTIFICATION_TYPES, payload: res.data})
            return(res);
        }
    );
}


export const toggleNotificationStatus = (data, notificationConfiguration, setLoader) =>{
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
    setLoader(true);
    return(
        async(dispatch) =>{
            const res = await axios({
                method: 'PUT',
                headers: { 'Authorization': `Bearer ${token}` },
                url: `/configuration/${data._id}`,
                data: {
                    activity: !data.activity,
                    trackingId: data.campaign.trackingId,
                    websiteUrl: data.campaign.websiteUrl,
                    id: data.notificationType._id
                }
            }).catch (error => {throw error})
            dispatch({type: TOGGLE_NOTIFICATION_STATUS, payload: {id: data.notificationType._id, activity: !data.activity}})
            setLoader(false);
            return(res)
        }
    )
}

