import React, { useEffect, useState } from 'react'
import {
  Box,
  makeStyles,
  createStyles,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableBody,
  TableRow,
  RadioGroup,
  FormControlLabel,
  Radio,
  withStyles,
} from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EditIcon from '@material-ui/icons/Edit'
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined'
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import AddServiceDialog from './AddServiceDialog'
import AddServiceDialog_1 from './AddServiceDialog_1'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPolicies } from '../poicyThunks/policies/actions'
import TextInput from 'Globals/GlobalComponents/TextInput'
import LoadingButton from 'Globals/GlobalComponents/LoadingButton'
import PreviewPolicy from './PreviewPolicy'
import { fetchPolicymaster, postPolicymaster } from '../poicyThunks/policymaster/actions'
import HoverMenu from 'Globals/GlobalComponents/HoverMenu'
import { updatePolicyCampaign } from '../poicyThunks/policyCampaign/actions'
import FileBase64 from 'react-file-base64'
import { DialogActions, DialogBox, DialogContent,DialogTitle, DialogButton } from 'Globals/GlobalComponents/DialogBox'
import {browserHistory} from 'react-router';
import copy from 'copy-to-clipboard'


const Step_2 = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const policiesData = useSelector((state) => state.get('policiesData').toJS())
  const addedServices = useSelector((state) => state.get('policymaster').toJS())
  const { openedCampaign, loading } = useSelector((state) => state.get('policyCampaigns').toJS())

  const [expanded, setExpanded] = useState(false)
  const [publishLoading, setPublishLoading] = useState(false)
  const [publishSuccessModalOpen, setPublishSuccessModalOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(1)
  const [serviceEditingData, setServiceEditingData] = useState({})
  const [openAddServicesModal, setOpenAddServiesModal] = React.useState(false)
  const [openPreviewPolicy, setOpenPreviewPolicy] = useState(false)
  const [ownerInfoData, setOwnerInfoData] = useState({
    name: '',
    email: '',
    city: '',
    state: '',
    country: '',
    address1: '',
  })
  const [legislationFieldData, setLegislationFieldData] = useState({
    gdpr: 'All',
    ccpa: false,
    lgpd: false,
    icon: '',
  })

  // const [savedServicesData, setSavedServicesData] = useState({
  //   user: '5c6d4b8698948500132d07e7',
  //   trackingId: 'INF-3gbfcjjsd6vhvo',
  //   policyCampaign: '5fead6944b74a87c48b414f8',
  //   websiteUrl: '23gsf3dsgawf34.coff11',
  //   service: [],
  // })

  /**
   * Finds and returns the category name of the service
   * @param {String} id Id of the service
   * @param {Array<Object>} data Complete object of the policies
   */
  const categoryFinder = (id, data) => {
    const arr = data.find((el) => el._id === id)
    console.log(arr)
    if (arr) return arr.categoryName
    else return 'Not Found'
  }

  useEffect(() => {
    dispatch(fetchPolicies())
    dispatch(fetchPolicymaster(props.params.id))
  }, [])

  useEffect(() => {
    console.log('currentCampaign', openedCampaign)
    // setOwnerInfoData({...currentCampaign.owner})
    if (
      openedCampaign &&
      Object.keys(openedCampaign).includes('owner') &&
      Object.keys(openedCampaign.owner).length > 0
    ) {
      setOwnerInfoData({ ...openedCampaign.owner })
      setLegislationFieldData({...openedCampaign.legislation })
    }
  }, [])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleAddServiesDialog = () => {
    setOpenAddServiesModal(!openAddServicesModal)
  }

  const handlePreviewPolicyModal = () => {
    setOpenPreviewPolicy((state) => !state)
  }

  const ownerFormInputHandler = (e) => {
    setOwnerInfoData((state) => ({ ...state, [e.target.name]: e.target.value }))
  }

  const handleServiceEdit = (data) => {
    setOpenAddServiesModal(true)
    setServiceEditingData(data)
    setActiveStep(2)
  }

  useEffect(() => {
    if (!openAddServicesModal) {
      setServiceEditingData({})
      setActiveStep(1)
    } else return
  }, [openAddServicesModal])

  const handlePostCallback = (res, err) => {
    console.log(res)
  }

  const serviceDeleteHandler = (id) => {
    console.log(id)
    console.log(addedServices.data.service)
    const filteredArr = addedServices.data.service.filter((item) => item._id !== id)
    // const filteredData = {
    //   ...savedServicesData,
    //   service: filteredArr,
    // }
    dispatch(postPolicymaster(filteredArr, handlePostCallback))
    console.log(filteredArr)
  }

  const handleSaveOwnerInfo = (e) => {
    e.preventDefault()
    dispatch(updatePolicyCampaign(openedCampaign._id, { owner: ownerInfoData }))
  }

  const handleLegislationFields = (e) => {
    if (e.target.name === 'gdpr')
      setLegislationFieldData((oldState) => ({ ...oldState, [e.target.name]: e.target.value }))
    else
      setLegislationFieldData((oldState) => ({
        ...oldState,
        [e.target.name]: e.target.value === 'true',
      }))
  }

  const handlePostLegislationFields = () => {
    dispatch(updatePolicyCampaign(openedCampaign._id, { legislation: { ...legislationFieldData } }))
  }

  const handleIconUpload = (file) => {
    console.log(file)
    setLegislationFieldData((oldState) => ({ ...oldState, icon: file.base64 }))
  }

  const handelPublishAction = () => {
    setPublishLoading(true)
    dispatch(updatePolicyCampaign(openedCampaign._id, { status: 'live' }, publishActionCallback))
  }

  const publishActionCallback = () => {
    setPublishLoading(false)
    setPublishSuccessModalOpen(true)
  }

  return (
    <Box className={classes.step_2_container}>
      <Box className={classes.headerContainer}>
        <Typography variant="h4" style={{ color: '#000' }}>
          {openedCampaign.websiteUrl}
          <span>'s Privacy Policy</span>
        </Typography>
        <Button variant="contained">switch to pro</Button>
      </Box>

      <Box className={classes.upperButtonsContainer}>
        <Button
          variant="contained"
          onClick={handlePreviewPolicyModal}
          startIcon={<VisibilityOutlinedIcon />}
        >
          Preview Policy
        </Button>
        {/* <Button variant="contained" startIcon={<TuneOutlinedIcon />}>
          Advance Settings
        </Button> */}
        <LoadingButton
          loading={publishLoading}
          variant="contained"
          startIcon={<PublishOutlinedIcon />}
          onClick={handelPublishAction}
          style={{
            background: publishLoading
              ? 'linear-gradient(rgb(63 179 34 / 59%) 0px, rgb(107 193 91) 100%)'
              : 'linear-gradient(180deg, #3fb322 0, #20800e 100%)',
            border: '1px solid green',
          }}
        >
          Publish
        </LoadingButton>
      </Box>

      <Box className={classes.accordionContainer}>
        {/* {dummyData.map((data, index) => ( */}
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Services Collecting Personal Data</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
            <Box className={classes.addServieBtnContainer}>
              <Button onClick={handleAddServiesDialog} variant="contained">
                Add Service
              </Button>
              {/* <Button variant="contained">Scan Website and ....</Button> */}
            </Box>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableCell>Service</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Provider</TableCell>
                  <TableCell></TableCell>
                </TableHead>
                <TableBody>
                  {Object.keys(addedServices.data).includes('service') &&
                  addedServices.data.service.length > 0 ? (
                    addedServices.data.service.map((item) => (
                      <TableRow hover key={item._id} className={classes.tableRowStyle}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{categoryFinder(item._id, policiesData.data)}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell className={classes.settingsIconContainer}>
                          <HoverMenu scale={'-4'}>
                            <EditIcon onClick={() => handleServiceEdit(item)} />
                            <DeleteOutlineOutlinedIcon
                              onClick={() => serviceDeleteHandler(item._id)}
                            />
                          </HoverMenu>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className={classes.tableRowStyle}>
                      <TableCell>No service added yet</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Website Owner and Contact Info</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
            <form onSubmit={handleSaveOwnerInfo} style={{ width: '80%' }}>
              <Box className={classes.inputBox}>
                <Typography>Name</Typography>
                <TextInput
                  name="name"
                  required
                  onChange={ownerFormInputHandler}
                  value={ownerInfoData.name}
                  placeholder="Enter Your Name"
                />
              </Box>
              <Box className={classes.inputBox}>
                <Typography>Email</Typography>
                <TextInput
                  name="email"
                  required
                  onChange={ownerFormInputHandler}
                  value={ownerInfoData.email}
                  placeholder="Enter Your Email"
                />
              </Box>
              <Box className={classes.inputBox}>
                <Typography>Address</Typography>
                <Box className={'addressContainer'}>
                  <TextInput
                    name="address1"
                    required
                    onChange={ownerFormInputHandler}
                    value={ownerInfoData.address1}
                    placeholder="Street Address"
                  />
                  <TextInput
                    name="city"
                    required
                    onChange={ownerFormInputHandler}
                    value={ownerInfoData.city}
                    placeholder="City"
                  />
                  <TextInput
                    name="state"
                    required
                    onChange={ownerFormInputHandler}
                    value={ownerInfoData.state}
                    placeholder="State"
                  />
                  <TextInput
                    name="country"
                    required
                    onChange={ownerFormInputHandler}
                    value={ownerInfoData.country}
                    placeholder="Country"
                  />
                </Box>
              </Box>

              <Box style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                  // disabled={
                  //   !ownerInfoData.country ||
                  //   !ownerInfoData.state ||
                  //   !ownerInfoData.city ||
                  //   !ownerInfoData.address1 ||
                  //   !ownerInfoData.name ||
                  //   !ownerInfoData.email
                  // }
                  loading={loading}
                  variant="contained"
                  type={"submit"}
                  // onClick={handleSaveOwnerInfo}
                >
                  Save
                </LoadingButton>
              </Box>
            </form>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>Privacy Policy Page</Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
            <Typography>
              You can see yout privacy page here after publishing it. <br />
              {/* <Typography>
                {`https://agitated-elion-5e05a6.netlify.app/${openedCampaign._id}`}
              </Typography> */}
            </Typography>
            <Box className={classes.pixelContainer}>
              <Typography
                className={classes.pixel}
                onClick={() =>
                  window.open(
                    `https://agitated-elion-5e05a6.netlify.app/${openedCampaign._id}`,
                    '_blank'
                  )
                }
              >
                {/* {`<script src="https://cdn.useinfluence.co/static/influence-analytics.js?trackingId=INF-3gbfcjjsd6vhvo" async></script>`} */}
                {`https://agitated-elion-5e05a6.netlify.app/${openedCampaign._id}`}
              </Typography>

              <Button
                variant="contained"
                className={classes.pixelCopyButton}
                startIcon={<FileCopyOutlinedIcon fontSize="small" />}
                onClick={() =>
                  copy(`https://agitated-elion-5e05a6.netlify.app/${openedCampaign._id}`)
                }
              >
                Copy
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Advance Settings</Typography>
          </AccordionSummary>

          <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
            <Typography>Legislation-specific standards</Typography>
            <Box className={classes.inputBox}>
              <Typography>Apply GDPR's broader protection standards to:</Typography>
              <RadioGroup
                value={legislationFieldData.gdpr}
                onChange={handleLegislationFields}
                name="gdpr"
                className={classes.radionButtonContainer}
              >
                <FormControlLabel
                  value="EU Only"
                  control={<CustomRadio size="small" />}
                  label="EU only"
                />
                <FormControlLabel
                  value="All"
                  control={<CustomRadio size="small" />}
                  label="All Users"
                />
              </RadioGroup>
            </Box>
            <Box className={classes.inputBox}>
              <Typography>Enable CCPA disclosures for Californian users:</Typography>
              <RadioGroup
                value={legislationFieldData.ccpa.toString()}
                onChange={handleLegislationFields}
                name="ccpa"
                className={classes.radionButtonContainer}
              >
                <FormControlLabel
                  value="true"
                  control={<CustomRadio size="small" />}
                  label="Enable"
                />
                <FormControlLabel
                  value="false"
                  control={<CustomRadio size="small" />}
                  label="Disable"
                />
              </RadioGroup>
            </Box>
            <Box className={classes.inputBox}>
              <Typography>Enable LGPD disclosures for users residing in Brazil:</Typography>
              <RadioGroup
                value={legislationFieldData.lgpd.toString()}
                onChange={handleLegislationFields}
                name="lgpd"
                className={classes.radionButtonContainer}
              >
                <FormControlLabel
                  value="true"
                  control={<CustomRadio size="small" />}
                  label="Enable"
                />
                <FormControlLabel
                  value="false"
                  control={<CustomRadio size="small" />}
                  label="Disable"
                />
              </RadioGroup>
            </Box>

            <Box className={classes.imageUploadContainer}>
              <Typography>Brand Logo for policy page</Typography>
              <Box>
                <FileBase64 className={classes.fileInput} onDone={handleIconUpload} />
                {legislationFieldData.icon && (
                  <img width="40px" height="40px" src src={legislationFieldData.icon} />
                )}
              </Box>
            </Box>

            <LoadingButton
              loading={loading}
              onClick={handlePostLegislationFields}
              variant="contained"
            >
              Save
            </LoadingButton>
          </AccordionDetails>
        </Accordion>
      </Box>
      <AddServiceDialog
        activeStep={activeStep}
        policiesData={policiesData}
        open={openAddServicesModal}
        onClose={handleAddServiesDialog}
        serviceEditingData={serviceEditingData}
        serviceDeleteHandler={serviceDeleteHandler}
        addedServices={addedServices}
      />

      <PreviewPolicy
        open={openPreviewPolicy}
        campaignId={openedCampaign._id}
        onClose={handlePreviewPolicyModal}
      />

      <PublishSuccessModal open={publishSuccessModalOpen} campaignId={openedCampaign._id} />
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(3),
      '&>h4': {
        fontSize: 18,
      },
    },
    upperButtonsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: `${theme.spacing(1)}px`,
      marginBottom: theme.spacing(3),
    },
    accordionContainer: {},
    accordionDetailsRoot: {
      display: 'block',
      padding: theme.spacing(3),
      paddingTop: '0',
    },
    addServieBtnContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      gap: '8px',
      justifyContent: 'flex-end',
    },
    root: {
      width: '100%',
    },
    heading: {
      fontSize: '15px',
      color: '#6f6f6f',
      fontWeight: '500',
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    settingsIconContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '24px',
      '&>svg': {
        cursor: 'pointer',
        color: 'grey',
      },
    },
    inputBox: {
      display: 'flex',
      // alignItems: 'center',
      flexDirection: 'column',
      gap: '8px',
      marginTop: theme.spacing(2),
      '&>p': {
        fontSize: '14px',
        flexBasis: '15%',
      },
      '&>div': {
        margin: 0,
        width: '60%',
      },
      '&>div.addressContainer': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        // marginLeft: '20px',
        '&>div': {
          margin: '0',
        },
      },
    },
    radionButtonContainer: {
      flexDirection: 'row',
      gap: '20px',
    },
    pixelContainer: {
      display: 'flex',
      alignItems: 'center',
      // justifyContent:'space-between',
      marginTop: theme.spacing(1),
      gap: '24px',
      // '&:first-child': {
      //   borderTop: 'unset',
      //   borderRadius: theme.shape.borderRadius, it's not working, no idea why
      //   background:'red'
      // },
    },
    pixel: {
      border: '1px solid #ddd',
      borderRadius: theme.shape.borderRadius,
      padding: '8px 12px',
      flexBasis: '80%',
      fontSize: '14px',
      fontWeight: '500',
      color: '#59636D',
      cursor:'pointer',
      '&:hover':{
        color:'blue'
      }
      // flexGrow:'8'
    },
    pixelCopyButton: {
      // flexBasis:'0',
      // flexGrow:'2'
    },
    tableRowStyle: {
      '&>td': {
        fontSize: '14px',
        fontWeight: '400',
      },
      '&>td:first-child': {
        fontWeight: '500',
      },
    },
    imageUploadContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '&>div':  {
      marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        gap:  '16px',
      }
    },
  })
)

export default Step_2



const CustomRadio = withStyles({
  root: {
    // color: green[400],
    '&$checked': {
      color: 'rgb(18 52 77)',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


const PublishSuccessModal = ({handleClose=()=>browserHistory.push('/policy-generator/policies'), open, campaignId}) =>{
  return (
    <DialogBox onClose={handleClose} open={open}>
      <DialogTitle>Policy is live!</DialogTitle>
      <DialogContent>
        Congratulations! Your changes are live now.
        <br />
        Your Policy:-{' '}
        <span
          style={{color:'blue', cursor:'pointer'}}
          onClick={()=>window.open(`https://agitated-elion-5e05a6.netlify.app/${campaignId}`,'_blank')}
        >
          {`https://agitated-elion-5e05a6.netlify.app/${campaignId}`}
        </span>
      </DialogContent>
      <DialogActions>
        <DialogButton
          actionType={'primary'}
          onClick={() => browserHistory.push('/policy-generator/policies')}
        >
          Close
        </DialogButton>
      </DialogActions>
    </DialogBox>
  )
}