import React from 'react'
import { Box, createStyles, makeStyles, Divider } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const CookieDetailsSkeleton  = () => {
  const classes = useStyles()
  return(
      <Box>
        <Skeleton variant="text" height={68} width={'40%'} />
        <Box style={{ marginTop: '30px' }}>
          <Box className={classes.teo}>
            <Skeleton height={60} />
            <Skeleton height={100} />
          </Box>
          <Box className={classes.teo}>
            <Skeleton height={60} />
            <Skeleton height={100} />
          </Box>
        </Box>
        <Divider style={{ margin: '20px 0' }} />

        <Skeleton variant="text" height={68} width={'40%'} />
        <Box style={{ marginTop: '30px' }}>
          <Box className={classes.teo}>
            <Skeleton height={60} />
            <Skeleton height={100} />
          </Box>
          <Box className={classes.teo}>
            <Skeleton height={60} />
            <Skeleton height={100} />
          </Box>
          <Box className={classes.teo}>
            <Skeleton height={60} />
            <Skeleton height={100} />
          </Box>
          <Box className={classes.teo}>
            <Skeleton height={60} />
            <Skeleton height={100} />
          </Box>
        </Box>

      </Box>
      // {/* <Skeleton height={430} style={{minWidth:'280px'}} width={'100%'}/> */}
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    teo:{
      display:'flex',
      alignItems:'center',
      padding:'0 40px',
      '& > span':{
        margin:'5px',
        width:'50%'
      }
    }
  })
)


export default CookieDetailsSkeleton