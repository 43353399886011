import React from 'react'
import { Box, makeStyles, createStyles, Paper, Typography } from '@material-ui/core'
import AnalyticsGraph from './AnalyticsGraph';
import ReactTooltip from 'react-tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
const _ = require('lodash');




const Analytics = ({analyticsData, selectedDuration}) =>{
	const classes = useStyles()

	const cardData = [
		{
			value: analyticsData.viewCount ? analyticsData.viewCount : 0,
			name: 'Views',
			grow: true,
			color: '#246201'
		},
		{
			value: analyticsData.hoverCount ? analyticsData.hoverCount : 0,
			name: 'Hovers',
			grow: true,
			color: '#bcd514'
		},
		{
			value: analyticsData.clickNotificationType ?
				(analyticsData.clickNotificationType && analyticsData.clickNotificationType.bulk ? analyticsData.clickNotificationType && analyticsData.clickNotificationType.bulk : 0) +
				(analyticsData.clickNotificationType && analyticsData.clickNotificationType.recent ? analyticsData.clickNotificationType && analyticsData.clickNotificationType.recent : 0) +
				(analyticsData.clickNotificationType && analyticsData.clickNotificationType.announcement ? analyticsData.clickNotificationType && analyticsData.clickNotificationType.announcement : 0) +
				(analyticsData.clickNotificationType && analyticsData.clickNotificationType.live ? analyticsData.clickNotificationType && analyticsData.clickNotificationType.live : 0)
				: 0,
			name: 'Notif Clicks',
			grow: true,
			color: '#028690'

		},
		{
			value: analyticsData.clickCount ? analyticsData.clickCount : 0,
			name: 'Clicks',
			grow: false,
			color: '#e04427'
		},
		{
			// value: analyticsData.clickToSignupCount ? analyticsData.clickToSignupCount : 0,
			value: analyticsData.category ? analyticsData.category.value === "Link Click" ? analyticsData.linkClickData ? analyticsData.linkClickData.length : 0 : analyticsData.clickToSignupCount : 0,
			name: analyticsData.category ? analyticsData.category.value === "Link Click" ? "Hits" : "Clicks To Signup" : "Clicks To Signup",
			grow: false,
			color: '#59c1ca'
		},

		{
			value: analyticsData.influenceVisitorCount ? analyticsData.influenceVisitorCount : 0,
			name: 'Sales Count',
			grow: true,
			color: '#246201'
		}

	]

	

	return(
		<Box style={{position: 'relative', marginTop: '5rem', marginRight: '5%', border: '1px solid rgba(0,0,0,.19)', borderRadius: '5px'}}>
			<Box className={classes.cardContainer}>
				{
					cardData.map((data, index) =>(

						
						<Box className={classes.cardStyle} key={index}  data-tip = { index === 2 ? `<ul style="padding:15px; margin-bottom: 0px;">
						<li>Bulk Clicks : ${analyticsData.clickNotificationType && analyticsData.clickNotificationType.bulk ? analyticsData.clickNotificationType && analyticsData.clickNotificationType.bulk : 0}</li>
						<li>Live Clicks : ${analyticsData.clickNotificationType && analyticsData.clickNotificationType.live ? analyticsData.clickNotificationType && analyticsData.clickNotificationType.live : 0}</li>
						<li>Announcement Clicks : ${analyticsData.clickNotificationType && analyticsData.clickNotificationType.announcement ? analyticsData.clickNotificationType && analyticsData.clickNotificationType.announcement : 0}</li>
						<li>Recent Clicks : ${analyticsData.clickNotificationType && analyticsData.clickNotificationType.recent ? analyticsData.clickNotificationType && analyticsData.clickNotificationType.recent : 0}</li>
						</ul>`:''} data-place='bottom'>
							<Typography className={classes.cardNameStyle} >
								{data.name + " "} {index === 2? <InfoOutlinedIcon style={{fontSize:'13px',marginTop:'-2%'}} /> : ''}
							</Typography>
							<Typography style={{color: data.color}} className={classes.cardValueStyle}>
								{data.value === "" || !data.value ? 0 : data.value}
							</Typography>
						</Box>
				))
				}
			</Box>
			<ReactTooltip effect="solid" html={true} />

			<Box component={Paper} elevation={0} style={{paddingTop: '4%'}} padding='2% 3%' width='100%'>
				<AnalyticsGraph  infoNew={analyticsData} selectedDuration={selectedDuration}/>
			</Box>
		</Box>
	)
}


const useStyles = makeStyles(theme =>
	createStyles({
	
		cardContainer: {
			display: 'flex',
			backgroundColor: 'transparent',
			justifyContent: 'center',
			margin: '30px',
			top: '-17%',
			width: '95%',
			position: 'absolute',
		},
		cardStyle:{
			border: '1px solid rgba(0,0,0,.19)',
			borderRadius: '5px',
			padding: '5px 10px',
			textAlign: 'center',
			width: '150px',
			height: '90px',
			backgroundColor: '#fff',
			margin: '0 10px'
		},
		notifClickCardStyle:{
			border: '1px solid rgba(0,0,0,.19)',
			borderRadius: '5px',
			padding: '5px 10px',
			textAlign: 'center',
			width: '150px',
			height: '90px',
			backgroundColor: '#fff',
			margin: '0 10px',
			'&:hover':{
                
            },
		},
		cardNameStyle:{
			margin: '0 !important',
			color: '#8c9392',
			fontSize: '11px !important',
			textTransform: 'uppercase',
			fontWeight: '600',
			letterSpacing: '1px',
			padding: '7px 0'

		},
		cardValueStyle: {
			fontSize: '30px !important',
			margin: '0 !important',
			fontWeight: '300'
		},
		[theme.breakpoints.down('md')]:{
			cardStyle:{
				padding: '5px',
			},
			cardNameStyle:{
				fontSize: '10px !important',
			},
			cardValueStyle:{
				fontSize: '1.6rem !important'
			}
		}
	})
)

export default Analytics