import { Box, Divider } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'


const CookieAppearanceSkeleton = () =>{
  return(
    <Box>
      <Skeleton height={68}/>
      <Box style={{display:'flex', marginTop:'68px'}}>
        <Box style={{width:'75%', marginRight:'5%'}}>
          <Box>
            <Skeleton variant="text" width="270px"  height="50px" />
            <Box display="flex" alignItems="center">
              <Skeleton variant="text" width="30%" height="50px" />
              <Skeleton variant="circle" width="50px" height="50px" style={{marginLeft:'50px'}} />
            </Box>
            <Box display="flex" alignItems="center">
              <Skeleton variant="text" width="30%" height="50px" />
              <Skeleton variant="text" width="50%" height="50px" style={{marginLeft:'50px'}} />
            </Box>
            <Box display="flex" alignItems="center" >
              <Skeleton variant="text" width="30%" height="50px" />
              <Skeleton variant="text" width="50%" height="50px" style={{marginLeft:'50px'}} />
            </Box>
          </Box>

          <Divider style={{margin:'20px 0'}}/>

          <Box>
            <Skeleton variant="text" width="270px"  height="50px" />
            <Box display="flex" alignItems="center" >
              <Skeleton variant="text" width="30%" height="50px" />
              <Skeleton variant="text" width="50%" height="50px" style={{marginLeft:'50px'}} />
            </Box>
            <Box display="flex" alignItems="center" >
              <Skeleton variant="text" width="30%" height="50px" />
              <Skeleton variant="text" width="50%" height="50px" style={{marginLeft:'50px'}} />
            </Box>
            <Box display="flex" alignItems="center">
              <Skeleton variant="text" width="30%" height="50px" />
              <Skeleton variant="text" width="50%" height="50px" style={{marginLeft:'50px'}} />

            </Box>
          </Box>


        </Box>


        <Box style={{width:'20%'}}>
          <Skeleton height={350}/>
        </Box>

        
      </Box>
    </Box>
  )
}

export default CookieAppearanceSkeleton