import React, { useReducer, useEffect, useRef, useState, memo } from 'react';
import { Box, makeStyles, createStyles } from '@material-ui/core';
import AnnouncementNotificationCard from './AnnouncementNotificationCard';
import AnnouncementNotificationRules from './AnnouncementNotificationRules';
import NotifSettingsReducer, { initialState } from '../../NotifSettingsReducer';
import { useSelector } from 'react-redux';
import translate from 'translate';
import NotificationCardSkeleton from '../../../../Globals/GlobalComponents/NotificationCardSkeleton';
import NotificationRuleSkeleton from '../../../../Globals/GlobalComponents/NotificationRuleSkeleton';
import _ from 'lodash'


translate.engine = 'google';
// translate.key = 'AIzaSyDB2KZyXOUPBC3v0SWh7-vBzg_gTlpLc30';
translate.key = process.env.REACT_APP_GOOGLE_TRANSLATE_KEY;


const AnnouncementNotificationSetting  = (props) => {
    const classes = useStyles();
    const {
        handlePropertyChange, 
        notificationConfiguration, 
        handleInputChange,
        ruleName,
        campaignInfo,
        notificationType,
        handlePanelStyleChange,
        handleLanguageChange,
        handleNotificationSubmit,
        loading,
        notifBoxBGColor,
        brandingLocked,
        onClose
    } = props;
    const [announcementsSettings, setAnnouncementSettings,] = useReducer(NotifSettingsReducer, initialState);
    const [announcementHeaderText, setAnnouncementHeaderText] = useState("");
    const [announcementSubText, setAnnouncementSubText] = useState("");
    const [poweredBy, setPoweredBy] = useState("");
    const [show, setShow] = useState(false);


    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        },200)
    },[])

    

    const notificationSettings = useSelector(state => (state.getIn(['NotificationSettings', 'notificationConfiguration'])))

    useEffect(() => {
        if(notificationSettings !== undefined){
            setAnnouncementSettings({type: 'INIT', data: notificationSettings.find(item => item.notificationType.notificationName === ruleName)})
        }
    },[notificationSettings])

    const lastLangRef = useRef()

    
    const languageChangeHander = (name, value, dispatcher) =>{
        translate(announcementsSettings.announcementHeaderText, {from: announcementsSettings.langName.language, to: value }).then(
            text => {
                setAnnouncementHeaderText(text)
                handlePropertyChange('announcementHeaderText', text, setAnnouncementSettings)
            }
        )
        translate(announcementsSettings.announcementSubText, {from: announcementsSettings.langName.language, to: value}).then(
            text => {
                setAnnouncementSubText(text)
                handlePropertyChange('announcementSubText', text, setAnnouncementSettings)
            }
        )
        handleLanguageChange(name, value, dispatcher)
    }

    useEffect(() => {
       setAnnouncementHeaderText(announcementsSettings.announcementHeaderText);
    },[announcementsSettings.announcementHeaderText])

    useEffect(() => {
        setAnnouncementSubText(announcementsSettings.announcementSubText);
     },[announcementsSettings.announcementSubText])

    const lastLang = lastLangRef.current

    
    useEffect(() => {
        setPoweredBy(announcementsSettings.poweredBy);
    },[announcementsSettings.poweredBy])

    return(
        show ?
            <Box>
                <Box style={{background: notifBoxBGColor}} className={classes.notificationBox} padding='5% 0%'>
                    <AnnouncementNotificationCard 
                        announcementsSettings={announcementsSettings}
                        announcementHeaderText={announcementHeaderText}
                        announcementSubText={announcementSubText}
                        poweredBy={announcementsSettings.poweredBy}
                        
                    />
                </Box>
                <Box padding='6%'>
                    <AnnouncementNotificationRules
                        announcementHeaderText = {announcementHeaderText}
                        announcementSubText = {announcementSubText}
                        handleNotificationSubmit={handleNotificationSubmit}
                        loading={loading}
                        handlePropertyChange={handlePropertyChange}
                        setAnnouncementSettings={setAnnouncementSettings}
                        announcementsSettings={announcementsSettings}
                        campaignInfo={campaignInfo}
                        handlePanelStyleChange={handlePanelStyleChange}
                        handleLanguageChange={languageChangeHander}
                        notificationType={notificationType.filter(item => item.notificationName === ruleName)}
                        notificationConfiguration={notificationConfiguration.filter(item => item.notificationType.notificationName === ruleName)}
                        brandingLocked={brandingLocked}
                        onClose={onClose}
                        handleInputChange={handleInputChange}
                       
                    />
                </Box>
            </Box>:
            
            <Box>
                <Box style={{background: notifBoxBGColor}} className={classes.notificationBox} padding='5% 0%'>
                    <NotificationCardSkeleton />
                </Box>
                <Box padding="6%">
                    <NotificationRuleSkeleton />
                </Box>
            </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        notificationBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '7%',
            position: 'sticky',
            top: '50px',
            zIndex: '9999',
            paddingBottom: '11%',
            transition: 'all 0.7s ease-in-out',
            backdropFilter: "blur(0.8px)",
           
        },
        loaderBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }
    })
)

export default memo(AnnouncementNotificationSetting);