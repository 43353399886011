import axios from 'axios';
import { after } from 'lodash';
import { duration } from 'moment';
export const GET_GOALS_ANALYTICS_DATA = 'GET_GOALS_ANALYTICS_DATA';
export const GET_GOALS_ANALYTICS = 'GET_GOALS_ANALYTICS';
export const PUT_GOAL_DATA = 'PUT_GOAL_DATA';
export const GOAL_SCHEDULAR = 'GOAL_SCHEDULAR'
export const GET_GOAL_DATA = 'GET_GOAL_DATA';
export const GET_GOAL_FETCHING = 'GET_GOAL_FETCHING';
// export const getGoalsAnalyticsData = (id,setGoalsAnalyticsData) => {
//     const authToken = JSON.parse(localStorage.getItem('authToken'));
//     const token = authToken ? authToken.token : null;

//     return(
//         async(dispatch) => {
//             const res = await axios({
//                 method: 'GET',
//                 headers: { 'Authorization': `Bearer ${token}` },
//                 url: `https://api.useinfluence.co/goal/${id}`
//             }).catch(err => {
//                 throw err
//             })
//             dispatch({type: GET_GOALS_ANALYTICS_DATA, payload: res.data})
//             setGoalsAnalyticsData(res.data)
//         }
//     );
// }

// get request in goal section.
export const getGoalData = (trackingId,setLoading,setGoalsData) => {
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
    // loader is shown when request is made.
    setLoading(true);
return(
    async(dispatch) =>{
        const response = await axios({
            method:'GET',
            headers: { 'Authorization': `Bearer ${token}` },
            url:`goal?trackingId=${trackingId}`
        }).catch(error => {throw error})
        dispatch({type:GET_GOAL_DATA,payload:response.data}); 
        dispatch({type:GET_GOAL_FETCHING,payload:response.data});
        // loader is false when request is fullfilled
        setGoalsData && setGoalsData(response.data)
        setLoading(false);
    }
)
}

export const getGoalsAnalyticsData = (id,setGoalsData, afterAction) => {
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;

    return(
        async(dispatch) => {
            const res = await axios({
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` },
                url: `/goal/${id}`
            }).catch(err => {
                throw err
            })
            dispatch({type: GET_GOALS_ANALYTICS_DATA, payload: res.data})
            setGoalsData(res.data)
           if(afterAction) afterAction(res, false)
           
        }
    );
}

export const getGoalsAnalytics = (id, selectedDuration) =>{
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
   
    return(
        async(dispatch) =>{
            const res = await axios ({
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                url: `https://api.useinfluence.co/goal/${id}/${selectedDuration}`
            }).catch(err =>{
               
                throw err}
                )
            dispatch({type: GET_GOALS_ANALYTICS, payload: res.data})
           
        }
    )
}

// put request is called.
export const putGoalData = (data,setSnackBarStatus, setGoalsData, goalsData) =>{
    return(
        async(dispatch) =>{
            const response = await axios({
                method:'PUT',
                url:`/goal/${data.id}`,
                data:{
                    ...data
                }
            }).catch(error => {throw error});
            dispatch({type:PUT_GOAL_DATA,payload:response.data});
            setSnackBarStatus(true)
            setGoalsData({...goalsData, ...data})
        }
    )
}

export const goalSchedular = (trackingId,selectedDuration) =>{
    
    return(
        async(dispatch) =>{
            const res = await axios ({
                method: 'GET',
                url: `/schedularForGoal?trackingId=${trackingId}&dataDuration=${selectedDuration}`
            }).catch(err =>{ throw err})
            dispatch({type: GOAL_SCHEDULAR , payload:res.data})
            
        }
    )
}
