import { call, put, fork, takeLatest } from 'redux-saga/effects';
// import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

import * as api from 'services/api';
import * as actions from 'ducks/goal';
import { load, loaded } from 'ducks/loading';

// const errorMessge = 'Please try again or refresh!';

// const toastConfig = {
//   position: toast.POSITION.TOP_CENTER,
//   autoClose: 2000,
//   className: 'toast-style'
// };

function* fetch(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, 'goal?trackingId='+action.trackingId);
    if (res.error)
      console.log(res.error);
    else
      yield put(actions.fetchSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* fetchByCampaign(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, 'goal?campaign='+action.campaignId);
    if (res.error)
      console.log(res.error);
    else
      yield put(actions.fetchSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* create(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'goal', action.goal);
    if (res.error)
      console.log(res.error);
    else
      yield put(actions.createSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }

}

function* remove(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `goal/${action.id}`);
    if(res.error)
      console.log(res.error);
    else 
      yield put(actions.popGoal(action.index));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* update(action) {
  try {
    yield put(load());
    delete action.goal['_id'];
    const res = yield call(api.PUT, `goal/${action.goal.id}`, action.goal);
    if(res.error)
      console.log(res.error);
    else {
      let goal = action.goal;
      goal['_id'] = goal.id;
      yield put(actions.fetchSuccess(res));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* refreshGoalCount(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, 'schedularForGoal?trackingId='+action.trackingId);
    if (res.error)
      console.log(res.error);
    else
      yield put(actions.fetchGoal(res.trackingId));
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

export function* watchFetch() {
  yield takeLatest(actions.FETCH, fetch);
}

export function* watchFetchByCampaign() {
  yield takeLatest(actions.FETCH_CAMPAIGN, fetchByCampaign);
}

export function* watchCreate() {
  yield takeLatest(actions.CREATE, create);
}

export function* watchRemove() {
  yield takeLatest(actions.REMOVE, remove);
}

export function* watchUpdate() {
  yield takeLatest(actions.UPDATE, update);
}

export function* watchRefreshGoalCount() {
  yield takeLatest(actions.FETCH_GOAL_COUNTER, refreshGoalCount);
}



export default function* rootSaga() {
  yield [
    fork(watchFetch),
    fork(watchCreate),
    fork(watchRemove),
    fork(watchUpdate),
    fork(watchRefreshGoalCount),
    fork(watchFetchByCampaign)
  ];
}
