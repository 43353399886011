import { fromJS } from 'immutable';

const action = name => `/facebook/${name}`;

export const FETCH = action('FETCH');
export const FETCH_SUCCESS = action('FETCH_SUCCESS');
export const CREATE = action('CREATE');
export const CREATE_SUCCESS = action('CREATE_SUCCESS');
export const UPDATE = action('UPDATE');
export const REMOVE = action('REMOVE');
export const POP_NETAPP = action('POP_NETAPP');

export const getFacebook = (campaignId) => ({ type: FETCH, campaignId });
export const addFacebook = (facebook) => ({ type: CREATE, facebook });
export const updateFacebook = (facebook) => ({ type: UPDATE, facebook });
export const deleteFacebook = (id, index) => ({ type: REMOVE, id, index });
export const succeessFacebook = (data) => ({ type: FETCH_SUCCESS, data });
export const popFacebook = (index) => ({ type: POP_NETAPP, index });

const initialState = fromJS({});
const facebook = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return state.set('facebook', action.data);  
    case POP_NETAPP:
      return state.set('facebook', state.get('facebook').slice(0, action.index).concat(state.get('facebook').slice(action.index+1)));
    default:
      return state;
  }
};

export default facebook;
