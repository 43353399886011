import React, { useState } from 'react'
// import {useTitle} from '../../../../CustomHooks'
import { useTitle } from "../../../CustomHooks";
import { Typography, Button } from '@material-ui/core'
import { CookieAppearanceSkeleton } from '../../../Globals/GlobalComponents/Skeletons';
// import CookieSettings from './CookieSettings'
const CookieSettings = React.lazy(()=> import('./CookieSettings'))


const Apperance = (props) => {

    useTitle('Appearance')


    return (
        <div style={{ margin: '0% 1%' }}>
            <Typography variant="h2" style={{ marginBottom: '3%' }}>
                Appearance
            </Typography>
            <React.Suspense fallback={<CookieAppearanceSkeleton/>}>
                <CookieSettings id={props.params.id} />
            </React.Suspense>
        </div>
    )
}

export default Apperance
