import React from "react";
import Box from '@material-ui/core/Box';
import { Divider } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';

const CampaignListSkeleton = () => {
  return (
    <Box>
      <Box padding="3%">
        <Box display="flex" alignItems="center" padding="20px">
          <Skeleton variant="text" width="100px" height="35px" style={{marginLeft:'30px'}} />
          <Skeleton variant="text" width="130px" height="35px" style={{marginLeft:'30px'}} />
          <Skeleton variant="text" width="200px" height="35px" style={{marginLeft:'30px'}} />
          <Skeleton variant="text" width="100px" height="35px" style={{marginLeft:'30px'}} />
          <Skeleton variant="text" width="150px" height="35px" style={{marginLeft:'30px'}} />
          <Skeleton variant="text" width="100px" height="35px" style={{marginLeft:'30px'}} />
        </Box>
        
        <Divider />

        <Box display="flex" alignItems="center" padding="5px">
          <Skeleton variant="text" width="100%" height="25px" style={{marginLeft:'30px'}} />
        </Box>
        <Box display="flex" alignItems="center" padding="5px">
          <Skeleton variant="text" width="100%" height="25px" style={{marginLeft:'30px'}} />
        </Box>
        <Box display="flex" alignItems="center" padding="5px">
          <Skeleton variant="text" width="100%" height="25px" style={{marginLeft:'30px'}} />
        </Box>
        <Box display="flex" alignItems="center" padding="5px">
          <Skeleton variant="text" width="100%" height="25px" style={{marginLeft:'30px'}} />
        </Box>
        <Box display="flex" alignItems="center" padding="5px">
          <Skeleton variant="text" width="100%" height="25px" style={{marginLeft:'30px'}} />
        </Box>
        <Box display="flex" alignItems="center" padding="5px">
          <Skeleton variant="text" width="100%" height="25px" style={{marginLeft:'30px'}} />
        </Box>
        <Box display="flex" alignItems="center" padding="5px">
          <Skeleton variant="text" width="100%" height="25px" style={{marginLeft:'30px'}} />
        </Box>
      </Box>
    </Box>
  );
}

export default CampaignListSkeleton;