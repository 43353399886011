import React, {Component} from 'react'
import { Box, Button } from '@material-ui/core'
import { Link } from 'react-router';
import { withStyles } from "@material-ui/core/styles";


import { LogoInfluence } from 'img';



class WebsiteHeaderNew extends Component{

	constructor(){
		super();
		this.state={
			toggleIcon: '',
			togglebool: true,
			hoverProduct: false
		}
	}

	
	render(){
		const { classes } = this.props
		return(
			<Box 
				className = {classes.mainHeader}
			>
				<Box>
					<Link to={`${process.env.REACT_APP_WEBSITE_URL}`}>
						<img src={LogoInfluence} alt="logo" target='_self' style={{height: '3rem'}}/>
					</Link>
				</Box>
				<Box>
					{
						window.location.pathname === '/login' || window.location.pathname=== '/'?
							<Link style={{textDecoration: 'none'}} to="/signup">
								<Button
									disableRipple
									className={`${classes.btnStyle} ${classes.signupBtn}`}
								>
									SIGN UP
								</Button>
							</Link>:
							<Link style={{textDecoration: 'none'}} to="/login">
								<Button
									disableRipple
									className={`${classes.loginBtn} ${classes.btnStyle}`}
								>
									LOGIN
								</Button>
							</Link>
					}
					
				</Box>
			</Box>
		)
	}
}




const useStyles = theme =>({
	mainHeader:{
		width: '100%', 
		height: '4.5rem', 
		boxShadow: '0px 7px 8px -12px #999', 
		padding: '0 5%',
		position: 'fixed',
		backgroundColor: 'transparent',
		top: '0',
		left: '0',
		display: 'none',
		justifyContent: 'space-between',
		alignItems: 'center',
		zIndex: theme.zIndex.appBar
	},
	btnStyle:{
		width: '9rem',
		letterSpacing: '0.5px',
		fontSize: '16px',
		padding: '5px 8px',
		[theme.breakpoints.down('md')]:{
			width: '7rem'
		}
	},
	loginBtn: {
		color: '#097fff',
		backgroundColor: '#fff',
		border: '1px solid rgba(140,140,140,0.5) !important',
		borderRadius: '6px',
		fontFamily: "'Raleway','Verdana','Helvetica', sans-serif !important",
		'&:hover':{
			backgroundColor: '#fff',
			border: '1px solid rgba(50,50,50,0.7) !important',
			marginBottom: '5px !important',
			transition: 'margin 0.2s'
		}
	},
	signupBtn:{
		backgroundColor: '#097fff !important',
		color: '#fff',
		fontFamily: "'Raleway','Verdana','Helvetica', sans-serif !important",
		fontWeight: '400',
		'&:hover':{
			marginBottom: '5px !important',
			transition: 'margin 0.2s'
		}
	}
})

export default withStyles(useStyles)(WebsiteHeaderNew)
