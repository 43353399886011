import React, { useEffect, useState } from 'react'
import { Box, makeStyles, createStyles, Typography, Button } from '@material-ui/core'
import TextInput from 'Globals/GlobalComponents/TextInput'
import SelectBox from 'components/SelectBox'
import ReactSelect from 'Globals/GlobalComponents/ReactSelect'
import { languageData } from 'Globals/GlobalComponents/languages'
import ArrowRightAltOutlinedIcon from '@material-ui/icons/ArrowRightAltOutlined'
import { browserHistory } from 'react-router';
import { createPolicyCampaign } from '../poicyThunks/policyCampaign/actions'
import { useDispatch } from 'react-redux'
import LoadingButton from 'Globals/GlobalComponents/LoadingButton'


const Step_1 = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    businessName: '',
    websiteName:   '',
    websiteUrl: '',
    policyType: 'website',
    address:   {
      city: '',
      state: '',
      country: '',
    },
    language: 'en'
  })
  const [loading, setLoading] = useState(false)


  const handlePushToStep2 = (id) => {
    browserHistory.push(`/policy-generator/new/step-2/${id}`)
  }

  const formInputHandler = (e) => {
    e.persist();
    if (['city', 'state', 'country'].includes(e.target.name))
      setFormData((data) => ({
        ...data,
        address: { ...data.address, [e.target.getAttribute('name')]: e.target.value },
      }))
    else setFormData((data) => ({ ...data, [e.target.getAttribute('name')]: e.target.value }))
  }

  const lagnFieldHandler = (e) => {
    setFormData((data) => ({ ...data, language: e.value }))
  }
  const policyTypeFieldHandler = (e) => {
    setFormData((data) => ({ ...data, policyType: e.value }))
  }

  useEffect(() => {
    console.log({ formData })
  }, [formData])

  const formSubmitHandler = (e) => {
    e.preventDefault()
    setLoading(true)
    dispatch(createPolicyCampaign(formData, formSubmitCallback))
  }

  const formSubmitCallback = (response, error) => {
    if (error) {
      console.log(error)
      setLoading(false)
    }
    else {
      handlePushToStep2(response._id)
      setLoading(false)
    }
  }

  const policyTypeOptions = [
    { value: 'mobileApp', label: 'Mobile App' },
    { value: 'facebookPage', label: 'Facebook Page' },
    { value: 'website', label: 'Website' },
  ]
  return (
    <Box className={classes.step_1_container}>
      <Typography variant="h4">Generate New Policy</Typography>
      <form onSubmit={formSubmitHandler} className={classes.step_1_form}>
      <Box className={classes.fieldContainer}>
          <Typography>Buissness Name</Typography>
          <TextInput
            required
            onChange={formInputHandler}
            style={{ width: '300px', margin: 0 }}
            // type="url"
            placeholder="Buissness Name"
            value={formData.businessName}
            name="businessName"
          />
        </Box>

        <Box className={classes.fieldContainer}>
          <Typography>Website Name</Typography>
          <TextInput
            required
            onChange={formInputHandler}
            style={{ width: '300px', margin: 0 }}
            // type="url"
            placeholder="Website Name"
            value={formData.websiteName}
            name="websiteName"
          />
        </Box>

        <Box className={classes.fieldContainer}>
          <Typography>Website Url</Typography>
          <TextInput
            required
            onChange={formInputHandler}
            style={{ width: '300px', margin: 0 }}
            // type="url"
            placeholder="Enter URL"
            value={formData.websiteUrl}
            name={"websiteUrl"}
          />
        </Box>
        
        <Box className={classes.fieldContainer}>
          <Typography>Location</Typography>
          <Box className={classes.locationInputContainer}>
            <TextInput
              required
              onChange={formInputHandler}
              style={{ margin: 0 }}
              // type="url"
              placeholder="City"
              value={formData.city}
              name="city"
            />
            <TextInput
              required
              onChange={formInputHandler}
              style={{ margin: 0 }}
              // type="url"
              placeholder="State"
              value={formData.state}
              name="state"
            />
            <TextInput
              required
              onChange={formInputHandler}
              style={{ margin: 0 }}
              // type="url"
              placeholder="Country"
              value={formData.country}
              name="country"
            />
          </Box>
        </Box>
        <Box className={classes.fieldContainer}>
          <Typography>Document Language</Typography>
          <Box style={{ width: '300px' }}>
            <SelectBox
              required
              value={formData.language}
              onChange={lagnFieldHandler}
              options={languageData}
              name="language"
            />
          </Box>
        </Box>
        <Box className={classes.fieldContainer}>
          <Typography>Policy Type</Typography>
          <Box style={{ width: '300px' }}>
            <SelectBox
              required
              value={formData.policyType}
              onChange={policyTypeFieldHandler}
              options={policyTypeOptions}
              name="policyType"
            />
          </Box>
        </Box>
        <LoadingButton
          variant="contained"
          loading={loading}
          // onClick={handlePushToStep2}
          endIcon={<ArrowRightAltOutlinedIcon fontSize="small" />}
          type={'submit'}
        >
          Next
        </LoadingButton>
      </form>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    step_1_container: {
      padding: theme.spacing(2),
      border: '1px solid #ddd',
      backgroundColor: '#fff',
      borderRadius: theme.shape.borderRadius,
      '&>h4': {
        fontSize: 18,
        marginBottom: theme.spacing(2),
        textTransform: 'uppercase',
        marginTop: theme.spacing(1),
        color: '#000',
      },
    },
    fieldContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1),
      '& >p ': {
        fontSize: 16,
        width: '200px',
      },
    },
    locationInputContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: `${theme.spacing(1)}px`,
      '&>div': {
        flexBasis: '30%',
      },
    },
    step_1_form: {
      marginLeft:    '8px',
      '&>button': {
        marginTop: theme.spacing(3),
        marginLeft: '400px',
        width: '100px',
        '&>svg': {
          marginLeft: theme.spacing(1),
        },
      },
    },
  })
)

export default Step_1
