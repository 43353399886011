
import React from 'react'
import { withRouter } from "react-router-dom";
import { browserHistory } from 'react-router';
import { Box, makeStyles, createStyles, Paper, Typography, Checkbox, Button, CircularProgress, Input, Card, withStyles, Grid, Divider } from '@material-ui/core'

const ProductsScreen = () => {
  const classes = useStyles()

  const handleRouting = () => {
    browserHistory.push(`/installPixelPage`)
    //setPanelName("InstallCode")

  }

  const handleRoutingToCheckout = () => {
    browserHistory.push(`/checkoutpage`)
    //setPanelName("Checkout")

  }

  const enterprisePlan_m = [
    "Unlimited Notifications",
    "Unlimited Domains",
    "Custom Settings",
    "All Notification Types",
    "Priority Support",
    "Removable Branding",
    "Teammate Accounts",
  ]

  const advancedPlan_m = [
    "Unlimited Notifications",
    "Unlimited Domains",
    "Custom Settings",
    "All Notification Types",
    "Priority Support",
    "Removable Branding",
    "Teammate Accounts",
  ]

  const growthPlan_m = [
    "Unlimited Notifications",
    "Unlimited Domains",
    "Custom Settings",
    "All Notification Types",
    "Priority Support"
  ]

  const startupPlan_m = [
    "Unlimited Notifications",
    "Unlimited Domains",
    "Custom Settings",
    "All Notification Types",
    "Priority Support"
  ]

  const monthlyPlanDetails = [
    {
      name: "Enterprise",
      price: "$215",
      pointers: enterprisePlan_m,
      interval: "month",
      uniqueVisitors: '500000',
      id: "price_1H89D3CkWzi7OrXWlwuVb6uG"
    },
    {
      name: "Advanced",
      price: "$129",
      pointers: advancedPlan_m,
      interval: "month",
      uniqueVisitors: '200000',
      id: "price_1HiD7PCkWzi7OrXWLMmDDNPn"
    },
    {
      name: "Growth",
      price: "$69",
      pointers: growthPlan_m,
      interval: "month",
      uniqueVisitors: '50000',
      id: "price_1HiD20CkWzi7OrXWvCZVywSQ"
    },
    {
      name: "Startups",
      price: "$19",
      pointers: startupPlan_m,
      interval: "month",
      uniqueVisitors: '10000',
      id: 'price_1H88NRCkWzi7OrXW5S0rTlXj'
    }
  ]


  const yearlyPlanDetails = [
    {
      name: "Enterprise",
      price: "$2150",
      pointers: enterprisePlan_m,
      interval: "year",
      uniqueVisitors: '500000',
      id: 'price_1H89EJCkWzi7OrXWFIhPQBRL'
    },
    {
      name: "Advanced",
      price: "$1050",
      pointers: advancedPlan_m,
      interval: "year",
      uniqueVisitors: '200000',
      id: "price_1H9qkECkWzi7OrXW6z1Vz220"
    },
    {
      name: "Growth",
      price: "$520",
      pointers: growthPlan_m,
      interval: "year",
      uniqueVisitors: '50000',
      id: "price_1H9qZ8CkWzi7OrXWUbIVZRGJ"
    },
    {
      name: "Startups",
      price: "$190",
      pointers: startupPlan_m,
      interval: "year",
      uniqueVisitors: '10000',
      id: "price_1H89N4CkWzi7OrXW1y4N40Ye"
    },
  ]


  return (


    <Box>
      {/*
            <Box className={classes.UpperParent}>
           
            <Grid md={6}>
            
            <Card style={{backgroundColor:'#fff', 
            boxShadow: '0 1px 0 0 rgba(66,72,82,0.11), 0 2px 4px 0 rgba(66,72,82,0.12), 0 5px 5px 0 rgba(66,72,82,0.06), 0 4px 35px 0 rgba(66,72,82,0.1)',
            height:'220px',paddingBottom:'22px',paddingTop:'30px',paddingLeft:'0px',paddingRight:'0px',marginRight:'10px',borderRadius:'4px',diplay:'flex',flexDirection:'column'}}>
            <Box style={{display:'flex', flexDirection:'row'}}>
            <Box>
            <input ng-change="unselectAllCheckbox()" id="combo1" value="combo1" class="radio ng-pristine ng-untouched ng-valid ng-empty" type="radio" ng-model="controllerScope.selectedCombo" name="combo"/> 
            <label class="radio radio--small" for="combo1"></label> 
            </Box>

            <Box>
            <Box>
            <Typography>
            Experience Optimization Essentials
            </Typography>
            </Box>
            <Box>
            <Typography>
            Drive growth with great cx. Drive geowth with great cx. Drive geowth with great cx. Drive geowth with great cx.
            </Typography>
            </Box>
            </Box>
            </Box>
            <Box style={{display:'flex', flexDirection:'row'}}>
                <Box style={{padding:'5%',paddingBottom:'3%'}}>
                View testing
                </Box>
                <span style={{padding:'5%',paddingBottom:'3%'}}>+</span>
                <Box style={{padding:'5%',paddingBottom:'3%'}}>
                View testing
                </Box>
                <span style={{padding:'5%',paddingBottom:'3%'}}>+</span>
                <Box style={{padding:'5%',paddingBottom:'3%'}}>
                View testing
                </Box>
            </Box>
            <Divider/>
            
           
          
            <Box style={{textAlign:'center',padding:'2%'}}> VMO Testing</Box>




          
            </Card>
            
            
            </Grid>
            

            <Grid md={6}>
            
            <Card style={{backgroundColor:'#fff', 
            boxShadow: '0 1px 0 0 rgba(66,72,82,0.11), 0 2px 4px 0 rgba(66,72,82,0.12), 0 5px 5px 0 rgba(66,72,82,0.06), 0 4px 35px 0 rgba(66,72,82,0.1)',
            height:'220px',paddingBottom:'22px',paddingTop:'30px',paddingLeft:'0px',paddingRight:'0px',marginRight:'10px',borderRadius:'4px',diplay:'flex',flexDirection:'column'}}>
            <Box style={{display:'flex', flexDirection:'row'}}>
            <Box>
            <input ng-change="unselectAllCheckbox()" id="combo1" value="combo1" class="radio ng-pristine ng-untouched ng-valid ng-empty" type="radio" ng-model="controllerScope.selectedCombo" name="combo"/> 
            <label class="radio radio--small" for="combo1"></label> 
            </Box>

            <Box>
            <Box>
            <Typography>
            Experience Optimization Essentials
            </Typography>
            </Box>
            <Box>
            <Typography>
            Drive growth with great cx. Drive geowth with great cx. Drive geowth with great cx. Drive geowth with great cx.
            </Typography>
            </Box>
            </Box>
            </Box>
            <Box style={{display:'flex', flexDirection:'row'}}>
                <Box style={{padding:'5%',paddingBottom:'3%'}}>
                View testing
                </Box>
                <span style={{padding:'5%',paddingBottom:'3%'}}>+</span>
                <Box style={{padding:'5%',paddingBottom:'3%'}}>
                View testing
                </Box>
                <span style={{padding:'5%',paddingBottom:'3%'}}>+</span>
                <Box style={{padding:'5%',paddingBottom:'3%'}}>
                View testing
                </Box>
            </Box>
            <Divider/>
            
           
          
            <Box style={{textAlign:'center',padding:'2%'}}> VMO Testing</Box>




          
            </Card>
            
            
            </Grid>
            
            </Box>
            <Box style={{
              display:'flex',
              flexDirection:'row',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
              padding: '4% 0% 2% 0%'
            }}>

            <Divider style={{width:'46%',marginRight:'1%'}}/>
            
            <span style={{

              backgroundColor: 'rgb(238, 239, 241)',
              border: '1px solid rgb(238, 239, 241)',
              borderRadius: '50%',
              fontSize: '12px',
              color: '#1F2532',
              fontWeight: '600',
              padding: '5px'
            }}>OR</span> 
           
           <Divider style={{width:'49%',marginLeft:'1%'}}/>
           </Box>

          */}


      <Box className={classes.LowerParent}>
        <Box style={{ padding: '2% 0%' }}>
          <Card style={{
            backgroundColor: '#fff', height: '128px', boxShadow: 'none',
            border: '0.5px solid lightgrey'
          }}>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Box style={{ display: 'flex', flexDirection: 'row', height: '128px' }}>
                <div>
                  <svg class="icon C(#802050)" width="36" height="36" viewBox="0 0 32 32"> <use></use> </svg>
                </div>
                <div style={{ width: '70%' }}>
                  <h5 className={classes.heading}>Free Plan</h5>
                  <p className={classes.description}>Take Free Trial for 30 days</p>
                </div>

                <Divider orientation="vertical" flexItem />
                <Box style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '5% 7%',
                  /* text-align: center; */
                  verticalAlign: 'middle',
                  flexDirection: 'column'
                }}>
                  <Typography className={classes.freeStyle}>
                    FREE
          </Typography>
                  <Button variant="contained" style={{
                    padding: '15%',
                    width: '9rem',
                    fontSize: '18px'
                  }} onClick={handleRouting}>Start Now</Button>
                </Box>
              </Box>
              <Divider style={{ display: 'none' }} />
              <Box style={{ padding: '2%', textAlign: "left", paddingLeft: '8%', display: 'none' }}>Free Plan</Box>

            </Box>
          </Card>
        </Box>

        <Box style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          textAlign: 'center',
          width: '100%',
          padding: '4% 0% 2% 0%'
        }}>

          <Divider style={{ width: '46%', marginRight: '1%' }} />

          <span style={{

            backgroundColor: 'rgb(238, 239, 241)',
            border: '1px solid rgb(238, 239, 241)',
            borderRadius: '50%',
            fontSize: '12px',
            color: '#1F2532',
            fontWeight: '600',
            padding: '5px',
            display:'none'
          }}>OR</span>

          <Divider style={{ width: '49%', marginLeft: '1%' }} />
        </Box>

        <Typography style={{ fontSize: '17px', fontWeight: '500', textTransform: 'capitalize' }}>Monthly Plans</Typography>
        {
          monthlyPlanDetails.map((plan) => (

            <Box style={{ padding: '2% 0%' }}>

              <Card style={{
                backgroundColor: '#fff', height: '178px', boxShadow: 'none',
                border: '0.5px solid lightgrey'
              }}>
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  <Box style={{ display: 'flex', flexDirection: 'row', height: '178px' }}>

                    <div>
                      <svg class="icon C(#802050)" width="36" height="36" viewBox="0 0 32 32"> <use></use> </svg>
                    </div>
                    <div style={{ width: '70%' }}>
                      <h5 className={classes.heading}>{plan.name}</h5>
                      <p className={classes.description} style={{ display: 'none' }}>{plan.uniqueVisitors} unique visitors per month</p>
                    </div>

                    <Divider orientation="vertical" flexItem />
                    <Box style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '5%',
                      /* text-align: center; */
                      verticalAlign: 'middle',
                      flexDirection: 'column'
                    }}>
                      <Typography className={classes.priceStyle}>
                        {plan.price}
                      </Typography>
                      <p className={classes.description} style={{
                        width: 'max-content',
                        marginTop: '-5%', fontWeight: '900'
                      }}>{plan.uniqueVisitors} unique visitors per month</p>
                      <Button variant="contained" style={{
                        padding: '9%',
                        width: '9rem',
                        fontSize: '18px'
                      }} onClick={handleRoutingToCheckout}>Start Now</Button>
                    </Box>
                  </Box>
                  <Box className={classes.pointerParent}>
                    {
                      plan.pointers.map((point, id) => (
                        <Typography className={classes.pointerStyle}>{point}</Typography>

                      ))
                    }

                  </Box>
                  <Divider style={{ display: 'none' }} />
                  <Box style={{ padding: '2%', textAlign: "left", paddingLeft: '8%', display: 'none' }}></Box>

                </Box>
              </Card>
            </Box>




          ))
        }

        <Box style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          textAlign: 'center',
          width: '100%',
          padding: '4% 0% 2% 0%'
        }}>

          <Divider style={{ width: '46%', marginRight: '1%' }} />

          <span style={{
            backgroundColor: 'rgb(238, 239, 241)',
            border: '1px solid rgb(238, 239, 241)',
            borderRadius: '50%',
            fontSize: '12px',
            color: '#1F2532',
            fontWeight: '600',
            padding: '5px',
            display: 'none',

          }}>OR</span>

          <Divider style={{ width: '49%', marginLeft: '1%' }} />
        </Box>
        <Typography style={{ fontSize: '17px', fontWeight: '500', textTransform: 'capitalize' }}>Yearly Plans</Typography>
        {
          yearlyPlanDetails.map((plan) => (

            <Box style={{ padding: '2% 0%' }}>

              <Card style={{
                backgroundColor: '#fff', height: '178px', boxShadow: 'none',
                border: '0.5px solid lightgrey'
              }}>
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  <Box style={{ display: 'flex', flexDirection: 'row', height: '178px' }}>

                    <div>
                      <svg class="icon C(#802050)" width="36" height="36" viewBox="0 0 32 32"> <use></use> </svg>
                    </div>
                    <div style={{ width: '70%' }}>
                      <h5 className={classes.heading}>{plan.name}</h5>
                      <p className={classes.description} style={{ display: 'none' }}>{plan.uniqueVisitors} unique visitors per month</p>
                    </div>

                    <Divider orientation="vertical" flexItem />
                    <Box style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '5%',
                      /* text-align: center; */
                      verticalAlign: 'middle',
                      flexDirection: 'column'
                    }}>
                      <Typography className={classes.priceStyle}>
                        {plan.price}
                      </Typography>
                      <p className={classes.description} style={{
                        width: 'max-content',
                        marginTop: '-5%', fontWeight: '900'
                      }}>{plan.uniqueVisitors} unique visitors per month</p>
                      <Button variant="contained" style={{
                        padding: '9%',
                        width: '9rem',
                        fontSize: '18px'
                      }} onClick={handleRoutingToCheckout}>Start Now</Button>
                    </Box>
                  </Box>
                  <Box className={classes.pointerParent}>
                    {
                      plan.pointers.map((point, id) => (
                        <Typography className={classes.pointerStyle}>{point}</Typography>

                      ))
                    }

                  </Box>
                  <Divider style={{ display: 'none' }} />
                  <Box style={{ padding: '2%', textAlign: "left", paddingLeft: '8%', display: 'none' }}></Box>

                </Box>
              </Card>
            </Box>

          ))
        }








        <Grid md={12} style={{ padding: '2% 0%', display: 'none' }}>

          <Card style={{ backgroundColor: '#fff', height: '124px' }}>
            <span style={{ backgroundColor: '#47b178', width: '32px', padding: '4px 8px', fontSize: '10px', fontWeight: '500', borderTopLeftRadius: '4px', borderBottomRightRadius: '4px', color: '#fff', fontFamily: 'sans-serif' }}>NEW</span>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Box style={{ display: 'flex', flexDirection: 'row', height: '124px', marginTop: '-1.5%' }}>
                <div >
                  <svg class="icon C(#802050)" width="36" height="36" viewBox="0 0 32 32"> <use href="#icon--vwo-testing"></use> </svg>
                </div>
                <div style={{ width: '70%' }}>
                  <h5 className={classes.heading}>Cookie Consent</h5>
                  <p className={classes.description}>GDPR, CCPA & Privacy solution manager for your website.</p>
                </div>

                <Divider orientation="vertical" flexItem style={{ marginTop: '-2%' }} />
                <Box>
                </Box>
              </Box>

              <Box style={{ padding: '2%', textAlign: "left", paddingLeft: '8%' }}>Free Plan</Box>

            </Box>
          </Card>
        </Grid>




      </Box>
      <Button variant="contained" style={{ display: 'none' }}>Next</Button>
    </Box>


  )
}

const useStyles = makeStyles(theme =>
  createStyles({

    UpperParent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'

    },
    LowerParent: {
      display: 'flex',
      flexDirection: 'column'

    },

    heading: {
      textTransform: 'uppercase',
      color: '#802050',
      fontSize: '1.1428571429rem',
      lineHeight: '1.25',
      fontFamily: 'sans-serif',
      marginBottom: '-1%',
      marginTop: '2%',
      fontSize: '25px',
      color: 'rgb(0, 0, 0)',
      fontFamily: 'Poppins',
      fontWeight: '500',
      textTransform: 'capitalize',
      lineHeight: '1.5'
    },
    description: {
      fontSize: '15px',
      lineHeight: '1.4',
      color: '#4E5963',
      letterSpacing: '1.2',
      fontFamily: 'sans-serif'
    },
    priceStyle: {
      fontSize: '47px',
      fontWeight: '400',
      margin: '0',
      color: '#000',
      padding: '3% 0%',
      marginTop: '-1rem'
    },
    freeStyle: {
      fontSize: '29px',
      fontWeight: '400',
      margin: '0',
      color: '#000',
      padding: '3% 0%'
    },
    pointerStyle: {
      color: '#4E5963',
      lineHeight: '1.3',
      color: '#4E5963',
      letterSpacing: '1.2',
      fontFamily: 'sans-serif',
      width: '5%',
      margin: '0% 1%',
      marginRight: '5%',
      fontSize: '13px'
    },
    pointerParent: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '3px',
      marginRight: '7%',
      marginLeft: '3%',
      marginTop: '-9%',
      paddingBottom: '1px',
      width: '75%'

    },
    [theme.breakpoints.down('md')]: {
      pointerParent: {
        width:'50%',
        flexDirection: 'column',
        marginTop:'-20%',
        marginLeft: '5%'
      },
      pointerStyle:{
        width:'max-content'
      }

    },
    [theme.breakpoints.down('sm')]: {
      pointerParent: {
      flexDirection:'column'
      }
    }


  }))





export default withRouter(ProductsScreen);