import { HANDLE_FORM_DATA } from './Action';
import {RUN_ON_ALL_PAGE_DATA} from '../DisplayPage1/action'
import { fromJS } from 'immutable'

const initialState = fromJS({});

export const FormData = (state = initialState, action)=>{
	switch(action.type){
		case HANDLE_FORM_DATA:
			return state.set('formDetails', action.payload)	
		case RUN_ON_ALL_PAGE_DATA:
			return state.set('formDetails',action.payload);	
		default:
			return state ;
	}
}