import React from 'react'
import { Box, makeStyles, createStyles, MuiThemeProvider } from '@material-ui/core'
import SideDrawer from '../../components/PolicyGenerator/SideDrawer/SideDrawer'
import CookieTheme from 'Resources/CookieTheme'
import theme from 'Resources/theme'
import UniversalToast from '../../Globals/GlobalComponents/UniversalToast'

const PolicyGenContainer = (props) => {
  const classes = useStyles()
  return (
    <MuiThemeProvider theme={theme}>
      <Box className={classes.mainContainer}>
        <SideDrawer />
        <UniversalToast />
        {props.children}
      </Box>
    </MuiThemeProvider>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    mainContainer: {
      marginLeft: theme.custom.sideDrawer.width,
      marginTop: '36px',
      padding: theme.spacing(3),
    },
  })
)

export default PolicyGenContainer
