import { fromJS } from 'immutable'

export const POLICY_MASTER = {
  LOADING: 'POLICY_MASTER_LOADING',
  LOADED: 'POLICY_MASTER_LOADED',
  FETCHED: 'POLICY_MASTER_FETCHED',
  POSTED: 'POLICY_MASTER_POSTED',
  FETCHED_PREVIEW: 'POLICY_MASTER_FETCHED_PREVIEW',
  ERROR: 'POLICY_MASTER_ERROR',
}

const initialState = fromJS({
  loading: false,
  data: {},
  error: false,
  previewData: '',
})

const policymasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case POLICY_MASTER.LOADING:
      return state.merge({ loading: true, error: false })

    case POLICY_MASTER.LOADED:
      return state.merge({ loading: false, error: false })

    case POLICY_MASTER.FETCHED:
      return state.merge({ loading: false, data: action.payload })

    case POLICY_MASTER.POSTED:
      return state.merge({ loading: false, data: action.payload })

    case POLICY_MASTER.FETCHED_PREVIEW:
      return state.merge({ loading: false, previewData: action.payload })

    case POLICY_MASTER.ERROR:
      return state.merge({ loading: false, error: action.payload, data: {} })

    default:
      return state
  }
}

export default policymasterReducer
