import { createMuiTheme } from '@material-ui/core/styles';


const applefont = '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'



let theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2c66c3',
            light: '#2D7BF4'
        }
    },
    typography: {
        fontFamily: applefont,
        root: {
            // fontFamily: applefont
        },
        h2: {
            color: '#424d63'
        },
        h3: {
            fontSize: '22px',
        },
        h4: {
            fontSize: '17px',
            color: '#777777',
            fontWeight: '600'
        },
        h5: {
            fontSize: '16px',
            color: '#000',
            fontWeight: '400',
            marginBottom: '0px'
        },
        h6: {
            color: '#193B7B'
        },
        p:{
            color: '#fff',
            margin: '0'
        },
        body2: {
            fontSize: '12px',
            color: '#727a82',
            fontWeight: '500',
            letterSpacing: '0.05rem',
            fontFamily: 'Poppins'
        }
    },
    props:{
        MuiButton:{
            disableElevation: true
        },
        MuiPaper:{
            elevation:0
        }
    },
    overrides: {
        MuiPopover:{
            root: {
                zIndex: 3800,
            }
        },
        MuiButton: {
            root: {
                borderRadius: '4px',
                textTransform: 'capitalize',
                height: '32px',
                fontSize: '12px',
                fontWeight: '400',
                '&:focus':{
                    outline: 'none'
                }
            },
            contained: {
                background: 'linear-gradient(180deg, #264966 0, #12344d 100%)',
                color: '#fff',
                border: '1px solid #12344d',
                '&:hover':{
                    background: null,
                    backgroundColor: '#12344d',
                    color: '#fff',
                },
                '&:disabled':{
                    color: '#fff',
                    background: '#12344da3',
                    border:'none'
                }
            },
            outlined: {
                background: 'linear-gradient(180deg, #fff 0, #f5f7f9 100%) !important',
                color: 'rgb(51,51,51)',
                border:'1px solid #cfd7df !important',
                '&:hover':{
                    backgroundColor: '#f5f7f9',
                    background: null
                }
            }
        },
        MuiCheckbox: {
            colorSecondary: {
                '&$checked': {
                    color: '#12344d'
                }
            },

        },
        MuiTableContainer:{
            root:{
                boxShadow:'rgba(198, 198, 198, 0.32) 0px -1px 2px 0px'
            }
        },
        MuiTableCell: {
            root:{
                padding: '8px 16px',
                fontFamily: applefont,
                fontSize: '15px',
            },
            head:{
                color: 'rgba(9,30,66,.74)',
                fontSize: '11px',
                textTransform: 'uppercase',
                fontWeight: '700',
                fontFamily: applefont
            },
            body:{
                fontSize:'14px',
                fontWeight:'400'
            },
            stickyHeader:{
                backgroundColor: 'rgb(250, 251, 252)'
            }
        },
        MuiTableRow: {
            root:{
                transition: 'background 0.2s'
            }
        },
        MuiOutlinedInput: {
            root: {
                height: '35px',
                minWidth: '96px'
            },
            hover:{
                backgroundColor:'#fff'
            }
        },
        MuiInput: {
            
            underline: {
                '&:before': {
                    borderBottom: 'none'
                }
            }
        },
        MuiInputBase: {
            root: {
                height: '1.3em',
                border: '1px solid #c8c8c8 !important'
            },
            input: {
                height: '1.54em',
                // border: '1px solid #c8c8c8 !important',
                borderRadius: '3px !important',
                padding: '0%',
                margin: '0%'
            }

        },
        MuiDrawer: {
            paper: {
                width: '46%',
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(4px, 14px) scale(0.75)',
                '&$shrink' : {
                    transform: 'translate(5px, -14px) scale(0.75)'
                }
            }
        },
        MuiSelect: {
            select: {
                '&:focus' : {
                    backgroundColor: 'none'
                }
            }
        },
        MuiListItem: {
            root: {
                "&$selected" : {
                    backgroundColor: '#2684FF',
                    color:'#fff',
                    "&:hover":{
                        backgroundColor: '#2684FF',
                        color:'#fff' 
                    }
                },
            }
        }
    },
    zIndex:{
        snackbar : 4000,
        tooltip:4000,
        toolbar:5000,
        drawer:3600,
        sidebar: {
            zIndex: 3900
        },
        rightSideToolBar:{
            zIndex: 3800
        },
        appBar:3700,
        modal:3800,
        dialog:3600
    },

    custom:{
        sideDrawer:{
            width: '150px'
        },
        sideBar:{
            width:55
        },
        header:{
            height:60
        },
        upgradeStrip:{
            height:50
        }
    }

});

export default theme;