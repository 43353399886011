import { combineReducers } from 'redux-immutable';
import { routerReducer } from 'react-router-redux'

import profile from './profile';
import auth from './auth';
import plan from './plan';
import payment from './payment';
import notification from './notification';
import campaign from './campaign';
import rules from './rules';
import loading from './loading';
import configuration from './configuration';
import elastic from './elastic';
import pageurl from './pageurl';
import webhooks from './webhooks';
import subcampaign from './subcampaign';
import oauth from './oauth';
import breadcrumb from './breadcrumb';
import affiliate from './affiliate';
import integrations from './integrations';
import goal from './goal';
import report from './report';
import lemlist from './lemlist';
import restream from './restream';
import autoklose from './autoklose';
import aweber from './aweber';
import netapp from './netapp';
import agencyPlan from './agencyPlan';
import abhisi from './abhisi';
import facebook from './facebook';
import google from './google';
import {NotificationSettings} from '../components/NotifSettings/SidePanel/reducer';
import { FormData } from '../components/CampignSettingsPanel/Reducer'
import {NotificationConfiguration} from '../components/NotifSettings/reducer';
import { SavedData } from '../components/ConnectAppsSideTable/Reducer';
import { DisplayPageData } from '../components/DisplayPage1/Reducer';
import { WebsiteUrl } from '../components/CaptureLeads/Reducer';
import {CampaignInfo} from '../components/CampaignList/reducer';
import {GoalPageData,GoalFetching} from '../components/GoalList/reducer';
import { PixelPageDetails } from '../components/InstallPixelPage/Reducer'
import { UpgradePlan } from '../components/SettingsPage/reducer'
import { Invoice } from '../components/SettingsPage/reducer'
import { AnalyticsData } from '../components/AnalyticsPage/reducer'
import {GoalsAnalyticsData} from '../components/GoalList/GoalsAnalyticsPage/reducer'
import {detailsScreenOps, userPixelStatus} from '../components/ProductMain/reducer'

import {cookieCampaigns, cookieCampaignCreationProcess, newMicropolicy, getMicropolicies,micropolicyOps,providerOps,thirdPartyOps,cookieConfiguration, fireToaster} from '../components/CookieConcent/reducers'
import {
  addServiceReducer,
  policyCampaignsReducer,
  policiesReducer,
  // policyDataReducer,
} from 'components/PolicyGenerator/poicyThunks'
import policymasterReducer from '../components/PolicyGenerator/poicyThunks/policymaster/reducers'

  
  
  const reducer = combineReducers({
    policyCampaigns: policyCampaignsReducer,
    policiesData: policiesReducer,
    addedServices: addServiceReducer,
    policymaster: policymasterReducer,

    fireToaster,
    cookieConfiguration,
    newMicropolicy,
    getMicropolicies,
    cookieCampaignCreationProcess,
    cookieCampaigns,
    micropolicyOps,
    providerOps,
    thirdPartyOps,

    userPixelStatus,
    detailsScreenOps,
    GoalsAnalyticsData,
    AnalyticsData,
    Invoice,
    UpgradePlan,
    PixelPageDetails,
    CampaignInfo,
    SavedData,
    DisplayPageData,
    WebsiteUrl,
    NotificationSettings,
    FormData,
    GoalPageData,
    GoalFetching,
    profile,
    auth,
    plan,
    payment,
    notification,
    campaign,
    rules,
    loading,
    configuration,
    elastic,
    pageurl,
    webhooks,
    subcampaign,
    oauth,
    breadcrumb,
    affiliate,
    integrations,
    goal,
    report,
    lemlist,
    restream,
    autoklose,
    aweber,
    netapp,
    agencyPlan,
    abhisi,
    facebook,
    google,
    router: routerReducer,
  })

export default reducer;
