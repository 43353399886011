import React, {useEffect} from 'react'
import { Box, makeStyles, createStyles, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import CookieNavigation from '../CookieNavigation/CookieNavigation'
import { getCookieCampaigns, saveOpenedCookieCamapign } from '../actions';
import CookieSubNavigationBar from '../CookieNavigation/CookieSubNavigationBar';




const CampaignSetting_C = (props) =>{


	const dispatch = useDispatch()
	const theme = useTheme()
	const cookieCampaigns = useSelector(state => (state.getIn(['cookieCampaigns','cookieCampaigns'])))


	const saveCurrentCampaignToRedux = (allCampaigns, id) =>{
		dispatch(saveOpenedCookieCamapign(allCampaigns.find((item)=>item._id===id)))
	}


	useEffect(()=>{
		cookieCampaigns ? saveCurrentCampaignToRedux(cookieCampaigns, props.params.id) : dispatch(getCookieCampaigns(props.params.id))
		return () => {
			dispatch(saveOpenedCookieCamapign({}))
		}
	},[cookieCampaigns])
	
	return(
		<Box>
			<Box>
				<CookieSubNavigationBar/>
			</Box>
			<Box style={{ marginTop:theme.spacing(8)}}>
				<CookieNavigation id={props.params.id}/>
			</Box>
			<Box 
				style={{
					marginTop: theme.spacing(8), 
					padding:theme.spacing(8), 
					boxShadow: 'rgba(198, 198, 198, 0.32) 0px -1px 2px 0px', 
					border: '1px solid rgba(177, 177, 177, 0.3)',
					backgroundColor: '#fff',
					borderRadius: theme.shape.borderRadius
				}}
			>
				{props.children}
			</Box>
		</Box>
	)
}


// const useStyles = makeStyles(theme =>
// 	createStyles({

// 	})
// )

export default CampaignSetting_C
