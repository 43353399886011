import React, { useState, useEffect,useSelector } from 'react';
import { Box, makeStyles, createStyles, Dialog,Paper,Typography, Button} from '@material-ui/core';
//import NewCampaignCreation from '../NewCampaignCreation/NewCampaignCreation';
import {Bullhorn, Tick} from '../../img';
import { Link } from 'react-router';
import CheckIcon from '@material-ui/icons/Check';
import PricingDialog1 from '../SettingsPage/PricingDialog1';

const UpgardeModal = (props) => {
    const classes = useStyles();
   
   
    const {
        open,
        closeHandler,
        handleClose,
        profile
        } = props;


        const enterprisePlan_m = [
            "Unlimited Notifications",
            "Unlimited Domains",
            "Custom Settings",
            "All Notification Types",
            "Priority Support",
            "Removable Branding",
            "Teammate Accounts",
        ]
    
        const advancedPlan_m = [
            "Unlimited Notifications",
            "Unlimited Domains",
            "Custom Settings",
            "All Notification Types",
            "Priority Support",
            "Removable Branding",
            "Teammate Accounts",
        ]
    
        const growthPlan_m = [
            "Unlimited Notifications",
            "Unlimited Domains",
            "Custom Settings",
            "All Notification Types",
            "Priority Support"
        ]
    
        const startupPlan_m = [
            "Unlimited Notifications",
            "Unlimited Domains",
            "All Notification Types",
            "Installation support",
            "All Integrations"
        ]

        const freePlan_m = [
            "Unlimited Notifications",
            "Unlimited Domains",
            "All Notification Types",
            "Great Support",

        ]
    
       
    
        

    return(
        <Box>
            <Dialog 
                open={open && (window.location.pathname !== "/upgrade-plan")} 
                keepMounted
                maxWidth={false} 
                classes={{paper: classes.dialogPaper}} 
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                style={{zIndex:'5600'}}
            >
            <Box style={{display : 'flex',flexDirection:'row',padding:'5% 1%',justifyContent:'space-between', alignItems:'center'}}>
               <Box height="100%" width="40%" padding='0% 5%'>
                {/* <img src={Bullhorn} height='85%' width='85%'/> */}
                    <Bullhorn height='85%' width='85%'/>
                </Box>
                <Box height="100%" width="60%" className={classes.righttext}>
                    <Box className={classes.modalTag}>
                        <Typography style={{boxShadow: 'rgba(198, 198, 198, 0.32) 0px -1px 2px 0px', fontSize:'13px', fontWeight:'600', letterSpacing: '1.5px'}}>GO PRO WITH PREMIUM PLANS</Typography>
                    </Box>
                    <Box  style={{ verticalAlign:'baseline'}}>
                        <Typography style={{fontSize:'28px',lineHeight:'1.3',letterSpacing:'1px', marginBottom:'5%',fontFamily: 'poppins' ,color: '#000', fontWeight:'500', textTransform:'capitalize'}}>Get started with a Paid Plan</Typography>
                    </Box>
                    <Box  style={{ verticalAlign:'baseline',marginBottom:'5%',color: '#424242'}}>
                        <Typography style={{fontSize:'16px',letterSpacing:'1.1px'}} className={classes.subText}>Upgraded Limits, Hands-on Installation, Dedicated Support Managers & so much more (get priority access to Beta Features).</Typography>
                    </Box>
                    <Box style={{padding:'0',marginBottom:'5%'}}>
                      
                        {profile.plan?.name === 'Free Forever Plan' ?
                            <ul style={{listStyleType:'none'}}>

                                {startupPlan_m.map(point =>{
                                    return (<li style={{marginLeft:'-37px'}}>
                                        <span className={classes.listItem}><img className={classes.img} src={Tick} />
                                        <Typography className={classes.pointers}>{point}</Typography></span>
                                    </li>)
                                })
                            }
                                
                            </ul>
                         : profile.plan?.name === 'Startups' ?
                            <ul style={{listStyleType:'none'}}>
                                {growthPlan_m.map(point =>{
                                    return (<li style={{marginLeft:'-37px'}}>
                                        <span className={classes.listItem}><img className={classes.img} src={Tick}/>
                                        <Typography className={classes.pointers}>{point}</Typography></span>
                                    </li>)
                                })
                            }
                               
                            </ul>
                        : profile.plan?.name === 'Growth' ?
                            <ul style={{listStyleType:'none'}}>
                                {advancedPlan_m.map(point =>{
                                    return (<li style={{marginLeft:'-37px'}}>
                                        <span className={classes.listItem}><img className={classes.img} src={Tick}/>
                                        <Typography className={classes.pointers}>{point}</Typography></span>
                                    </li>)
                                })
                            }
                            </ul>
                        : profile.plan?.name === 'Advanced' ?
                            <ul style={{listStyleType:'none'}}>
                                {enterprisePlan_m.map(point =>{
                                    return (<li style={{marginLeft:'-37px'}}>
                                        <span className={classes.listItem}><img className={classes.img} src={Tick}/>
                                        <Typography className={classes.pointers}>{point}</Typography></span>
                                    </li>)
                                })
                            }
                            </ul>
                        :   <ul style={{listStyleType:'none'}}>
                                {startupPlan_m.map(point =>{
                                    return (<li style={{marginLeft:'-37px'}}>
                                        <span className={classes.listItem}><img className={classes.img} src={Tick}/>
                                        <Typography className={classes.pointers}>{point}</Typography></span>
                                    </li>)
                                })
                            }
                            </ul>
                        }


                        
                        
                            
                
                    </Box>
                    <Box>
                        
                        <Button className={classes.upgardeButton} component={Link} to={'/upgrade-plan'}>
                             Upgrade Plan
                        </Button>
                    </Box>
                    
                    

                </Box>
            </Box> 
            </Dialog>
        </Box>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
       
        dialogPaper: {
            width: '850px',
            zIndex: theme.zIndex.dialog
        },
        divider: {
            margin: '4% 2%'
        },
        inputContainers:{
            paddingTop: '10px'
        },
        modalTag:{

            fontWeight: '700',
            color: '#077fff',
            background: 'rgba(7,127,255,.16)',
            display: 'inline-block',
            padding: '4px 8px',
            fontSize: '10px',
            borderRadius: '4px',
            marginBottom:'5%',
            marginTop:'0%',
            letterSpacing:'1.5px',
            textTransform:'uppercase'

        },
        button2: {
            backgroundColor:'#2c66c3',
            height:'32px',
            width:'121px',
            fontSize:'12px !important',
            textTransform:'initial',
            borderRadius:'4px',
            "&focus": {
                outline:'none'
            }
        },
       righttext:{
           display: 'block',
            padding: '0% 5%',
            paddingLeft:'0%',
            verticalAlign: 'middle',
            position: 'relative',
            bottom: '0'
        },
        img:{
            height: '14px',
            display: 'flex'
        },
        listItem:{
            fontSize: '16px !important',
            //lineHeight: '1.5',
            display: 'inline-flex',
            verticalAlign: 'middle',
            alignItems:'center',
            lineHeight:'2.0',
            letterSpacing:'1.1px !important',
            height:'14px',
            marginRight:'6px'
        },
        pointers:{
            letterSpacing:'1.1px',
            fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
            color: 'rgba(66,66,66,0.98)',
            marginLeft: '0.71em',
            fontSize: '15.05px',
            fontWeight:'400'
        },
        inputName: {
            fontSize: '14px',
            paddingLeft: '3px'
        },
        heading:{
            fontWeight: 'bold'
        },
        circle:{
            content: "check_circle",
            //color: 'rgba(0,0,0,.1)',
            fontSize: '20px',
            lineHeight: '0',
            verticalAlign: 'middle',
            display: 'inline-block',
            margin: '0 6px 0 0'
        },
        upgardeButton : {
            background: 'rgb(0, 210, 0)',
            border: '2px solid rgb(0, 210, 0)',
            borderRadius: '5px',
            color: '#fff',
            fontSize: '16px',
            fontWeight: '400',
            padding: '2% 4% !important',
            textDecoration: 'none',
            textTransform: 'initial',
            boxShadow: '2px 2px 3px 1px #c6c6c6',
            transition: 'color .25s ease,background-color .25s ease,box-shadow .25s ease',
            '&:hover':{
                color:'#fff',
                backgroundColor:'rgb(5 197 5 / 99%)'
            }
        },
        inputStyle:{
            border: '1px solid hsl(0,0%,80%)',
            marginTop: '1%'
        },
        subText:{
            fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'
        }
    })    
)

  

export default UpgardeModal;