import React, {useEffect, useState} from 'react'
import { Box, makeStyles, createStyles, Typography,Card } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
import {Paper, Tabs, Tab} from '@material-ui/core';
import { LogoInfluence } from 'img';
import { Link } from 'react-router';
import { browserHistory } from 'react-router';
import {LogoInfluenceNew} from '../../img'
import { hover } from 'reactcss';
import BigToggle from '../../Globals/GlobalComponents/BigToggle';


const formatNumber = new Intl.NumberFormat()


const WebsitePriceNew  = (props) =>{

	const {monthlyPlans, yearlyPlans, handlePlanChoose} = props
	const classes = useStyles()
	
	const [value, setValue] = useState(0)
	const [showMonthlyPlans, setShowMonthlyPlans] = useState('monthly')
	//const [scrollState, setScrollstate] = useState(0)

	const handleChangeDuration = (e,name) =>{
		console.log('name--------', name)
		if(name !== "change"){
			setShowMonthlyPlans(prev => prev !== name ? setShowMonthlyPlans(name) : setShowMonthlyPlans(prev))
		}else {
			setShowMonthlyPlans(prev => prev == "monthly" ? setShowMonthlyPlans("yearly"): setShowMonthlyPlans("monthly"))
		}
	}

	

	// const scrollleft = () =>{

	// 	document.getElementById("cardcontainer").scrollTo(scrollState + 500, 0)   
	// 	setScrollstate(scrollState + 300)
	
		
	// }

	// const scrollright = () =>{
		
	// 	document.getElementById("cardcontainer").scrollTo(scrollState - 500 , 0)   
	// 	setScrollstate(scrollState - 300)
		
	// }

	useEffect(()=>{
		console.log("value------------", showMonthlyPlans)
	},[showMonthlyPlans])


	const handleLogout = () =>{
    document.body.style = 'background-color:white'
    localStorage.removeItem('authToken')
    // browserHistory.push('/')
    // console.log('---------------------')
    window.open(window.location.origin + '/login', '_self')
  }
	
	
	return(
		<Box style={{marginTop:'0%'}}>

		<Box 
		style={{
			width: '100%', 
			height: '4.5rem', 
			//boxShadow: '0px 7px 8px -12px #999', 
			padding: '3% 5%',
			paddingBottom:'0%',
			backgroundColor: 'transparent',
			top: '0%',
			left: '0',
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			zIndex: '500',
			marginBottom:'0%'
		}}
	>
				<Box style={{borderRadius:'5px'}}>
					<Link to={`${process.env.REACT_APP_WEBSITE_URL}`}>
						<img src={LogoInfluenceNew} alt="logo" target='_self' style={{height: '3rem'}}/>
					</Link>
				</Box>
				<Box>
					<Button
						disableRipple
						//className={`${classes.loginBtn} ${classes.btnStyle}`}
						variant='contained'
						onClick = {handleLogout}
					>
						SIGNOUT
					</Button>
				</Box>
				
			</Box>

			{/* <Paper style={{width: 'fit-content', border:'1px solid #22456187',margin: 'auto', marginTop: "1%"}} elevation={0}>
				<Tabs
					value={value}
					onChange={handleChangeDuration}
					classes={{indicator:classes.indicatorStyle}}
					textColor="primary"
					centered
					width = "100px"
					height = "50px"
				>
					<Tab className={value === 0 ? classes.activeTab : classes.tab} label="Monthly" />
					<Tab className={value === 1 ? classes.activeTab : classes.tab} label="Yearly" />
				</Tabs>
			</Paper> */}
			<Box>
				<BigToggle onChange={handleChangeDuration} value={showMonthlyPlans}/>
			</Box>

			<Typography style={{fontSize:'11px', color:'#666', textAlign:'center'}}>Save 20% on Annual Plans</Typography>
			
			<Box className={classes.plansContainer}>
				{ showMonthlyPlans == "monthly"?
					monthlyPlans && monthlyPlans.map(plan=>(
						<Card className={plan.name.includes('Growth') ? classes.hightlistPlanCard : classes.planCard}>
							<Box className ={classes.cardHeader}>
								
								<Typography className={classes.planName}>{plan.name.split(" ")[0]}</Typography>
								<Typography className={classes.planPrice}>${plan.amount/100}</Typography>
								
							</Box>
							<Box className={classes.visitorsBox}>
									<Typography className={classes.planVisitors}>{formatNumber.format(plan.description)} <div style={{fontWeight:'400', fontSize:'11px', marginLeft:'4px'}}>Unique Visitors / month</div></Typography>
							</Box>
							<ul className={classes.planDescriptionContainer} style={{listStyleType:'none'}}>
								{
								// 	plan.lines.map(line =>(
								// 		<li className={classes.planLine}>
								// 			<CheckIcon style={{fontSize: '1rem', color: 'green'}}/> {line}
								// 		</li>
								// 	))
								}
								{(/\b(Free)\b/m.test(plan.name)) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> 1 Domain only
									</li>
								}
								{((/\b(Startups)\b/m.test(plan.name)) || (/\b(Basic)\b/m.test(plan.name)) || (/\b(Growth)\b/m.test(plan.name)) || (/\b(Advanced)\b/m.test(plan.name)) || (/\b(Agency)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/>  Unlimited Domains
									</li>
								}
								{((/\b(Startups)\b/m.test(plan.name)) || (/\b(Basic)\b/m.test(plan.name)) || (/\b(Growth)\b/m.test(plan.name)) || (/\b(Advanced)\b/m.test(plan.name)) || (/\b(Agency)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> Custom Settings
									</li>
								}
								

								{((/\b(Startups)\b/m.test(plan.name)) || (/\b(Growth)\b/m.test(plan.name)) || (/\b(Advanced)\b/m.test(plan.name)) || (/\b(Agency)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> Done-For-You installation
									</li>
								}

								{((/\b(Startups)\b/m.test(plan.name)) || (/\b(Basic)\b/m.test(plan.name)) || (/\b(Advanced)\b/m.test(plan.name)) || (/\b(Growth)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> All Notification Types
									</li>
								}
								{/\b(Free)\b/m.test(plan.name) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> 1 Notification Type
									</li>
								}

								{((/\b(Advanced)\b/m.test(plan.name)) || (/\b(Agency)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> Removable Branding
									</li>
								}

								{((/\b(Growth)\b/m.test(plan.name)) || (/\b(Advanced)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> CRO Expert Help
									</li>
								}
								{((/\b(Advanced)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/>Sub-Accounts & Teammates
									</li>
								}

								{((/\b(Startups)\b/m.test(plan.name)) || (/\b(Growth)\b/m.test(plan.name)) || (/\b(Advanced)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> 24/7 Priority Support
									</li>
								}
								{((/\b(Startups)\b/m.test(plan.name)) || (/\b(Basic)\b/m.test(plan.name)) || (/\b(Growth)\b/m.test(plan.name)) || (/\b(Advanced)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> Conversion Goals
									</li>
								}
								{/\b(Free)\b/m.test(plan.name) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> Regular Support
									</li>
								}
	
							</ul>
							<Button onClick={()=>handlePlanChoose(plan)} variant="contained" className={classes.submitBtn}>Start Free Trial</Button>
						</Card>
					)):
					yearlyPlans.map(plan=>(
						<Card className={plan.name.includes('Growth') ? classes.hightlistPlanCard : classes.planCard}>
							<Box className={classes.cardHeader}>

								<Typography className={classes.planName}>{plan.name.split(" ")[0]}</Typography>
								<Typography className={classes.planPrice}>${plan.amount / 100}</Typography>

							</Box>
							<Box className={classes.visitorsBox}>
								<Typography className={classes.planVisitors}>{formatNumber.format(plan.description)} <div style={{fontWeight:'400', fontSize:'11px', marginLeft:'4px'}}>Unique Visitors / month</div></Typography>
							</Box>
							<ul className={classes.planDescriptionContainer} style={{listStyleType:'none'}}>
								
							{(/\b(Free)\b/m.test(plan.name)) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> 1 Domain only
									</li>
								}
								{((/\b(Startups)\b/m.test(plan.name)) || (/\b(Growth)\b/m.test(plan.name)) || (/\b(Advanced)\b/m.test(plan.name)) || (/\b(Agency)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/>  Unlimited Domains
									</li>
								}
								{((/\b(Startups)\b/m.test(plan.name)) || (/\b(Growth)\b/m.test(plan.name)) || (/\b(Advanced)\b/m.test(plan.name)) || (/\b(Agency)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> Custom Settings
									</li>
								}
								

								{((/\b(Startups)\b/m.test(plan.name)) || (/\b(Growth)\b/m.test(plan.name)) || (/\b(Advanced)\b/m.test(plan.name)) || (/\b(Agency)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> Done-For-You installation
									</li>
								}

								{((/\b(Startups)\b/m.test(plan.name)) || (/\b(Advanced)\b/m.test(plan.name)) || (/\b(Growth)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> All Notification Types
									</li>
								}
								{/\b(Free)\b/m.test(plan.name) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> 1 Notification Type
									</li>
								}

								{((/\b(Advanced)\b/m.test(plan.name)) || (/\b(Agency)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> Removable Branding
									</li>
								}

								{((/\b(Growth)\b/m.test(plan.name)) || (/\b(Advanced)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> CRO Expert Help
									</li>
								}
								{((/\b(Advanced)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/>Sub-Accounts & Teammates
									</li>
								}

								{((/\b(Startups)\b/m.test(plan.name)) || (/\b(Growth)\b/m.test(plan.name)) || (/\b(Advanced)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> 24/7 Priority Support
									</li>
								}
								{((/\b(Startups)\b/m.test(plan.name)) || (/\b(Growth)\b/m.test(plan.name)) || (/\b(Advanced)\b/m.test(plan.name)) || (/\b(Enterprise)\b/m.test(plan.name))) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> Conversion Goals
									</li>
								}
								{/\b(Free)\b/m.test(plan.name) &&
									<li className={classes.planLine}>
										<img src="https://cdns.useinfluence.co/images/green-check.png" style={{width: '1rem', height: '1rem', marginRight:'0.35rem'}}/> Regular Support
									</li>
								}
								
							</ul>
							<Button onClick={()=>handlePlanChoose(plan)} variant="contained" className={classes.submitBtn}>Start Free Trial</Button>
						</Card>
					))
				}
			</Box>
		</Box>
	)
}


const useStyles = makeStyles((theme) =>
  createStyles({
    stepBox: {
      height: '50px',
      width: '100px',
      border: '1px solid #c6c6c6',
      borderRadius: '5px',
      margin: 'auto',
      textAlign: 'center',
      padding: '10px',
    },
    cardHeader: {
      textAlign: 'center',
      //borderBottom: '1px solid #c6c6c6',
      padding: '5px 0',
      color: '#2c66c3',
      backgroundColor: '#fff',
    },
    indicatorStyle: {
      background: 'transparent',
    },
    visitorsBox: {
      // color: '#c6c6c6',
      borderBottom: '1px solid #c6c6c6',
      padding: '5px',
      textAlign: 'center',
      color: '#666',
    },
    plansContainer: {
      // display: 'flex',
      //justifyContent: 'center',
      padding: '1% 6%',
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      paddingTop: '0.5%',
      justifyContent: 'space-evenly',
      maxWidth: '1700px',
      margin: 'auto',
    },
    hightlistPlanCard: {
      marginTop: '30px',
      paddingBottom: '20px',
      width: '280px',
      border: '3px solid rgb(120 255 36 / 80%)',
      textAlign: 'center',
      boxShadow: '1px 2px 7px 4px rgba(128, 128, 128, 0.2)',
      '&:hover': {
        boxShadow: '0 10px 20px 0 rgba(0,43,86,.15)',
        borderColor: '#50a3f5',
      },
    },
    planCard: {
      marginTop: '30px',
      paddingBottom: '20px',
      width: '280px',
      border: '1px solid rgba(198, 198, 198, 0.8)',
      textAlign: 'center',
      boxShadow: '1px 2px 7px 4px rgba(128, 128, 128, 0.2)',
      '&:hover': {
        boxShadow: '0 10px 20px 0 rgba(0,43,86,.15)',
        borderColor: '#50a3f5',
      },
    },
    planPrice: {
      fontSize: '28px',
      fontWeight: '700',
      margin: '1px 0',
      color: '#2c66c3',
      padding: '0%',
    },
    planName: {
      fontSize: '17px',
      fontWeight: '700',
      letterSpacing: '0.5px',
      textTransform: 'uppercase',
      color: '#5d5d5d',
      marginTop: '7px',
      marginBottom: '7px',
    },
    planVisitors: {
      fontSize: '12px',
      color: '#666',
      // marginTop: '-26px',
      paddingBottom: '4%',
      fontWeight: '700',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    planDescriptionContainer: {
      height: '225px',
      textAlign: 'left',
      fontSize: '14px',
      padding: '4% 10%',
      marginBottom: '1px',
      marginTop: '1%',
    },

    planLine: {
      fontSize: '13px',
      padding: '2px 5px',
      marginTop: '0',
      marginBottom: '0',
      color: '#082a52',
      letterSpacing: '0.3px',
      fontWeight: '500',
      fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    submitBtn: {
      // backgroundColor: '#000',
      // color: '#fff',
      padding: '4% 15%',
      marginBottom: '7%',
      fontWeight: '500',
      width: '60%',
      whiteSpace: 'nowrap',
      // '&:hover':{
      // 	backgroundColor: '#110ac0'
      // }
    },
    tab: {
      fontSize: '14px',
      width: '90px',
      height: '50px',
      display: 'flex',
      padding: '10px 0px',
      marginRight: '0px',
      marginLeft: '0px',
      color: '#2c66c3',
      backgroundColor: '#fff',
      borderRadius: '9px',
      // transition: '0.3s'
    },
    activeTab: {
      height: '50px',
      // backgroundColor :'#082a52',
      color: '#fff !important',
      fontSize: '14px',
      display: 'flex',
      padding: '0px',
      marginRight: '0px',
      marginLeft: '0px',
      // transition: '0.3s',
      border: '1px solid #12344d',
      background: 'linear-gradient(180deg, #264966 0, #12344d 100%)',
      borderRadius: '2px',
      width: '90px',
    },
    btnStyle: {
      width: '9rem',
      letterSpacing: '0.5px',
      fontSize: '16px',
      padding: '5px 8px',
      [theme.breakpoints.down('md')]: {
        width: '7rem',
      },
    },
    loginBtn: {
      fontSize: '13px',
      color: '#fff',
      backgroundColor: '#082a52',
      borderRadius: '6px',
      fontFamily: "'Raleway','Verdana','Helvetica', sans-serif ",
      '&:hover': {
        backgroundColor: '#fff',
        border: '1px solid rgba(50,50,50,0.7)',
        marginBottom: '5px',
        transition: 'margin 0.2s',
        color: '#fff',
      },
    },
  })
)

export default WebsitePriceNew 
