import React, { memo, useState, useEffect } from 'react';
import { Box, makeStyles, createStyles, Grid, Checkbox, Typography, Popover } from '@material-ui/core';
// import TextInput from '../../../../Globals/GlobalComponents/TextInput';
import SlidingBar from '../../../../Globals/GlobalComponents/SlidingBar';
// import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
import { SketchPicker } from 'react-color';
import { ChromePicker } from 'react-color';
import InputBox from '../../../../Globals/GlobalComponents/InputBox';
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';

const NotificationsClickableBox  = (props) => {
    const classes = useStyles();

    const {
        notificationUrl,
        ctaButtonText,
        ctaHyperlinkUrl,
        isEnablePurchaseNotificationUrl,
        toggleCTA,
        ctaRadius,
        setRecentSettings,
        handleInputChange,
        handlePropertyChange,
        handlePanelStyleChange,
        isCTATop,
        anchorEl,
        setAnchorEl,
        anchorEl2,
        setAnchorEl2,
        recentSettings
    } = props;

    const [clickableCheck, setClickableCheck] = useState(false);
    const [ctaFontColorPopper, setCtaFontColorPopper] = useState(false)
    const [ctaBackgroundColorPopper, setCtaBackgroundColorPopper] = useState(false);
    const [ctaBackgroundColor, setCtaBgColorValue] = useState('rgb(0,0,0,0)')
    const [ctaTextColor, setCtaFontColorValue] = useState('rgb(0,0,0,0)')

    const handleClickableCheck = () => {
        if(clickableCheck === false) {
            setClickableCheck(true);
        } else {
            setClickableCheck(false)
            handleInputChange('notificationUrl', "", setRecentSettings)
        }
    }

    useEffect(()=>{
        if(notificationUrl.length>0){
            setClickableCheck(true)
        }
    },[notificationUrl])

    const handleCtaBgColorPopper = (event) =>{
        if(ctaBackgroundColorPopper === false){
            setCtaBackgroundColorPopper(true)
            setAnchorEl(event.currentTarget)
        }else{
            setCtaBackgroundColorPopper(false)
            setAnchorEl(null)
        }
    }

    const handleCtaFontColorPopper = (event) =>{
        if(ctaFontColorPopper === false){
            setCtaFontColorPopper(true)
            setAnchorEl2(event.currentTarget)
        }else{
            setCtaFontColorPopper(false)
            setAnchorEl2(null)
        }
    }

    const rgbSelector = (obj, stateName) =>{
        
        stateName(`rgba(${obj.r},${obj.g},${obj.b},${obj.a})`)
    }
   
   
    return(
        <Box borderTop='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container direction="column" spacing={3}>
                <Grid item md={12}>
                    <Grid container alignItems='center'>
                        <Grid item md={1}>
                            <Checkbox 
                                checked={clickableCheck}
                                onChange={handleClickableCheck}
                            />
                        </Grid>
                        <Grid item md={5}>
                            <Typography className={classes.boxTitle}>
                                Notification Clickable
                                {/* <a href={helpArticleLinks.faq.notification.clickableRecent} target="_blank">
                                    <img src = {HelpQuestion}  style={{ height: "13px",marginLeft:"2%"}}/>
                                </a> */}
                                <InfluenceHelpIcon href={helpArticleLinks.faq.notification.clickableRecent} fontSize="tiny"/>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {clickableCheck &&
                    <Grid item md={12}>
                        <Grid container alignItems="center">
                            <Grid item md={2}>
                                <Typography variant="body2">HyperLink</Typography>
                            </Grid>
                            <Grid item md={9} style={{paddingLeft: '15px'}}>
                                <InputBox
                                    name="notificationUrl"
                                    dispatcher={setRecentSettings}
                                    value={notificationUrl}
                                    onChange={handleInputChange}
                                    maxLength={150}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item md={12}>
                    <Grid container alignItems='center'>
                        <Grid item md={1}>
                            <Checkbox 
                                checked={isEnablePurchaseNotificationUrl}
                                name='isEnablePurchaseNotificationUrl'
                                onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, setRecentSettings)}
                            />
                        </Grid>
                        <Grid item md={7}>
                            <Typography variant="body2">Enable hyperlink for purchase notifications</Typography>
                        </Grid>
                       
                        <Grid item md={1}>
                            <Checkbox 
                                checked={toggleCTA}
                                name='toggleCTA'
                                onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, setRecentSettings)}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Typography variant="body2">Call to action enable</Typography>
                        </Grid>
                       
                    </Grid>
                </Grid>
                {toggleCTA &&
                    <Grid item md={12}>
                        <Grid container direction="column" spacing={3}>
                            <Grid item md={12}>
                                <Grid container alignItems="center">
                                    <Grid item md={3}>
                                        <Typography variant="body2">Radius</Typography>
                                    </Grid>
                                    <Grid item md={9} style={{paddingLeft: '7px'}}>
                                        <SlidingBar value={ctaRadius} name="ctaRadius" onChange={handlePanelStyleChange} dispatcher={setRecentSettings} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12}>
                                <Grid container alignItems='center'>
                                    <Grid item md={3}>
                                        <Typography variant="body2">CTA on Top</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Checkbox 
                                            checked={isCTATop}
                                            name="isCTATop"
                                            onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, setRecentSettings)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12}>
                                <Grid container alignItems='center'>
                                    <Grid item md={3}>
                                        <Typography variant="body2">Background Color</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <div 
                                            className={ctaBackgroundColorPopper ? classes.colorIconActive : classes.colorIcon} 
                                            onClick={handleCtaBgColorPopper}
                                            style={{backgroundColor: ctaBackgroundColor}}
                                        ></div>
                                        <Popover
                                            open={ctaBackgroundColorPopper} 
                                            onClose={handleCtaBgColorPopper} 
                                            anchorOrigin={{vertical: 'bottom',horizontal: 'center',}} 
                                            transformOrigin={{vertical: 'top',horizontal: 'right',}}
                                            anchorEl={anchorEl}
                                        >
                                           
                                            <ChromePicker 
                                                color={recentSettings.ctaBackgroundColor} 
                                                onChange={(event) => {
                                                    handlePanelStyleChange("ctaBackgroundColor", event.rgb, setRecentSettings)
                                                    rgbSelector(event.rgb, setCtaBgColorValue)
                                                }} 
                                            />
                                        </Popover>
                                    </Grid>
                                    <Grid item md={2} style={{paddingLeft: '8px'}}>
                                        <Typography variant="body2" >Text Color</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <div 
                                            className={ctaFontColorPopper ? classes.colorIconActive : classes.colorIcon} 
                                            onClick={handleCtaFontColorPopper}
                                            style={{backgroundColor: ctaTextColor}}
                                        ></div>
                                        <Popover
                                            open={ctaFontColorPopper} 
                                            onClose={handleCtaFontColorPopper} 
                                            anchorOrigin={{vertical: 'bottom',horizontal: 'center',}} 
                                            transformOrigin={{vertical: 'top',horizontal: 'right',}}
                                            anchorEl={anchorEl2}
                                        >
                                        <ChromePicker 
                                            color = {recentSettings.ctaTextColor}
                                            onChange={(event) => {
                                                handlePropertyChange("ctaTextColor", event.rgb, setRecentSettings)
                                                rgbSelector(event.rgb, setCtaFontColorValue)
                                            }} 
                                        />
                                        </Popover>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item md={3}>
                                        <Typography variant="body2">
                                            Button Text
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3} style={{paddingLeft: '4px'}}>
                                        <InputBox
                                            name="ctaButtonText"
                                            dispatcher={setRecentSettings}
                                            value={ctaButtonText}
                                            onChange={handlePropertyChange}
                                        />
                                    </Grid>
                                    <Grid item md={2}>
                                        <Typography variant="body2">
                                            Redirect URL
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <InputBox
                                            name="ctaHyperlinkUrl"
                                            dispatcher={setRecentSettings}
                                            value={ctaHyperlinkUrl}
                                            onChange={handleInputChange}
                                            maxLength={150}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        colorIcon: {
            width: '35px',
            height: '35px',
            border: '1px solid #000',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        colorIconActive: {
            width: '35px',
            height: '35px',
            border: '0.5px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        input: {
            height: '35px',
            //width:'120px',
            backgroundColor: '#fff',
            marginTop: '4%',
            border: ' 0.5px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            padding: '0% 3%',
            width:'100%'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            // paddingBottom: '5%'
        },
    })
)

export default memo(NotificationsClickableBox);