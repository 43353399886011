import React, { useState, Fragment } from 'react';
import { Box, makeStyles, createStyles, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, CircularProgress } from '@material-ui/core';
import TextInput from '../../Globals/GlobalComponents/TextInput';
import { useSelector } from 'react-redux';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  

const DeleteDialog  = (props) => {
    const{
        deleteDialog, 
        closeDeleteDialog,
        handleDeleteSubmit,
        loading,
        enterOtp,
        otp,
        setOtp,
        handleDeleteConfirm,
        pauseConfirmLoading
    } = props
    const classes = useStyles();
    const user = useSelector(state => (state.getIn(['auth', 'user']))) || []


    return(
        <Dialog
            open={deleteDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeDeleteDialog}
        >
            <DialogTitle id="alert-dialog-slide-title">{`${user.status === 'running' || user.status === true ? 'Delete' : 'Resume'} Your Account`}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Box padding='12px' border='1px solid #e8e8e8' borderRadius='5px'>
                        <Typography style={{fontSize:'23px',color:'#EC3B54',marginBottom:'5px'}}>WARNING!</Typography>
                        <Typography style={{marginBottom:'5px',color:'#EC3B54'}}>Deletes all your data & saved settings. This action is irreversible!</Typography>
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDeleteDialog} variant="outlined">
                    Cancel
                </Button>
                <Button onClick={handleDeleteSubmit} variant="contained">
                    {
                        loading ? <CircularProgress size={19} className={classes.buttonProgress} /> : 'Submit'
                    }
                </Button>
            </DialogActions>
            {enterOtp &&
                <Fragment>
                    <Box marginTop='6%' display='flex' justifyContent='center'>
                        <Typography>Enter the OTP below that has been sent to your registerd email</Typography>
                    </Box>
                    <Box display='flex' height='54px' alignItems='center' justifyContent='center' marginTop='1%'>
                        <TextInput 
                            type='number'
                            className={classes.inputBox} 
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                        <Button 
                            className={classes.button}
                            onClick={handleDeleteConfirm}
                            variant="contained"
                        >
                            {
                                pauseConfirmLoading ? <CircularProgress size={19} className={classes.buttonProgress} /> : 'Confirm'
                            }
                        </Button>
                    </Box>
                </Fragment>
            }
        </Dialog>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        button: {
            width: '140px',
            height: '32px',
            borderRadius: '4px',
            backgroundColor: '#2c66c3',
            color: '#fcfdff',
            fontSize: '12px !important',
            fontWeight: 'normal'
        },
        buttonProgress: {
            color: "#fff",
        },
        inputBox: {
            width: '24%',
            height: '32px',
            borderRadius: '4px',
            paddingLeft: '10px',
            marginLeft: '0px',
            marginRight: '13px'
        },
    })
)

export default DeleteDialog ;