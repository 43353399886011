import {
	FETCH_WEBSITEURL_DATA,
	POST_WEBSITEURL_DATA,
	DELETE_WEBSITEURL_DATA,
	APPS_AND_WEBHOOKS_DATA,
    GET_GENERATED_WEBHOOK,
    GET_APPS_CONNECTED_STATUS
} from './action';



import { fromJS  } from 'immutable';

const initialState = fromJS({});


export const WebsiteUrl = (state = initialState, action) =>{
switch(action.type){
    case FETCH_WEBSITEURL_DATA:
        //get request save in reducer
        return state.set('websiteUrlData',action.payload);
    case POST_WEBSITEURL_DATA:
        //add the path to the reducer
        return state.set('websiteUrlData',state.getIn(['websiteUrlData']).concat(action.payload));
    case DELETE_WEBSITEURL_DATA:
        //filter out the deleted object from reducer
        return state.set('websiteUrlData',state.get('websiteUrlData').filter(state => state._id !== action.payload._id));
    case APPS_AND_WEBHOOKS_DATA:
        //appshooks save in different state
        return state.set('appsAndWebhooks', action.payload)
    case GET_GENERATED_WEBHOOK:
        return state.set('generatedWebhook', action.payload)
    case GET_APPS_CONNECTED_STATUS:
        return state.set('appsConnetedStatus', action.payload)
    default:
        return state;
	}
}

