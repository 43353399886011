import axios from 'axios';

export const CREATE_GOAL = 'CREATE_GOAL';



export const createGoal = (data) => {
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
    return(
        async(dispatch) => {
            const res = await axios({
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` },
                url: '/goal',
                data: data
            }).catch(err => {throw err})
            dispatch({type: CREATE_GOAL, payload: res.data})
        }
    );
}