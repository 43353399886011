import { GET_SAVED_DATA_SENDINBLUE, GET_SAVED_DATA_STAMPED, GET_SAVED_DATA_CAPTERRA } from './Action'
import { GET_SAVED_DATA_MAILCHIMP } from './Action'
import { GET_SAVED_DATA_AWEBER } from './Action'
import { GET_SAVED_DATA_THRIVECART } from './Action'
import { GET_SAVED_DATA_MAILERLITE } from './Action'
import { GET_SAVED_DATA_LEMLIST } from './Action'
import { GET_SAVED_DATA_GOOGLE } from './Action'
import { GET_SAVED_DATA_CONVERTKIT } from './Action'
import { GET_SAVED_DATA_INTERCOM } from './Action'
import { GET_SAVED_DATA_NETO } from './Action'
import { GET_SAVED_DATA_SHOPIFY } from './Action'
import { GET_SAVED_DATA_FACEBOOK } from './Action'
import { ADD_FACEBOOK_PAGE } from './Action'
import { GET_SAVED_DATA_ACTIVECAMPAIGN } from './Action'


import { POST_DATA_SENDINBLUE } from './Action'
import { POST_DATA_MAILCHIMP } from './Action'
import { POST_DATA_AWEBER } from './Action'
import { POST_DATA_THRIVECART } from './Action'
import { POST_DATA_MAILERLITE } from './Action'
import { POST_DATA_LEMLIST } from './Action'
import { POST_DATA_GOOGLE } from './Action'
import { POST_DATA_STAMPED } from './Action'
import { POST_DATA_CAPTERRA } from './Action'
import { POST_DATA_CONVERTKIT } from './Action'
import { POST_DATA_INTERCOM } from './Action'
import { POST_DATA_NETO } from './Action'
import { POST_FACEBOOK } from './Action'
import { POST_DATA_ACTIVECAMPAIGN } from './Action'

// import { APP_DATA_DELETE_SENDINBLUE } from './Action'

import { fromJS } from 'immutable'

const initialState = fromJS({})

export const SavedData = (state = initialState, action)=>{
	switch(action.type){
		case GET_SAVED_DATA_SENDINBLUE:
			return state.set("savedDetailsSendInBlue", action.payload)
		case GET_SAVED_DATA_MAILCHIMP:
			return state.set("savedDetailsMailchimp", action.payload)
		case GET_SAVED_DATA_AWEBER:
			return state.set("savedDetailsAweber", action.payload)
		case GET_SAVED_DATA_THRIVECART:
			return state.set("savedDetailsThrivecart", action.payload)
		case GET_SAVED_DATA_MAILERLITE:
			return state.set("savedDetailsMailerlite", action.payload)
		case GET_SAVED_DATA_LEMLIST:
			return state.set("savedDetailsLemlist", action.payload)
		case GET_SAVED_DATA_GOOGLE:
			return state.set("savedDetailsGoogle", action.payload)
		case GET_SAVED_DATA_STAMPED:
			return state.set("savedDetailsStamped", action.payload)
		case GET_SAVED_DATA_CAPTERRA:
			return state.set("savedDetailsCapterra", action.payload)
		case GET_SAVED_DATA_CONVERTKIT:
			return state.set("savedDetailsConvertkit", action.payload)
		case GET_SAVED_DATA_INTERCOM:
			return state.set("savedDetailsIntercom", action.payload)
		case GET_SAVED_DATA_NETO:
			return state.set("savedDetailsNeto", action.payload)
		case GET_SAVED_DATA_SHOPIFY:
			return state.set("savedDetailsShopify", action.payload)
		case GET_SAVED_DATA_FACEBOOK:
			return state.set("savedDetailsFacebook", action.payload)
		case ADD_FACEBOOK_PAGE:
			return state.set("modifiedDetailsFacebook", action.payload)
		case GET_SAVED_DATA_ACTIVECAMPAIGN:
			return state.set("savedDetailsActivecampaign", action.payload)
			
		case POST_DATA_SENDINBLUE:
			return state.set("savedDetailsSendInBlue", action.payload)
		case POST_DATA_MAILCHIMP:
			return state.set("savedDetailsMailchimp", action.payload)
		case POST_DATA_AWEBER:
			return state.set("savedDetailsAweber", action.payload)
		case POST_DATA_THRIVECART:
			return state.set("savedDetailsThrivecart", action.payload)
		case POST_DATA_MAILERLITE:
			return state.set("savedDetailsMailerlite", action.payload)
		case POST_DATA_LEMLIST:
			return state.set("savedDetailsLemlist", action.payload)
		case POST_DATA_GOOGLE:
			return state.set("savedDetailsGoogle", action.payload)
		case POST_DATA_STAMPED:
				return state.set("savedDetailsStamped", action.payload)
		case POST_DATA_CAPTERRA:
			return state.set("savedDetailsCapterra", action.payload)
		case POST_DATA_CONVERTKIT:
			return state.set("savedDetailsConvertkit", action.payload)
		case POST_DATA_INTERCOM:
			return state.set("savedDetailsIntercom", action.payload)
		case POST_DATA_NETO:
			return state.set("savedDetailsNeto", action.payload)
		case POST_FACEBOOK:
			return state.set("savedDetailsFacebook", action.payload)
		case POST_DATA_ACTIVECAMPAIGN:
			return state.set("savedDetailsActivecampaign", action.payload)
		default:
			return state
	}
}

