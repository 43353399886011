import React, { useState, memo} from 'react'
import { Box, makeStyles, createStyles, Grid, Typography, Popover } from '@material-ui/core'
import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
// import TextInput from '../../../../Globals/GlobalComponents/TextInput'
import { SketchPicker } from 'react-color'
import InputBox from '../../../../Globals/GlobalComponents/InputBox';



const Textbox = (props) =>{

    const classes = useStyles()
    const {
        handlePanelStyleChange, 
        handlePropertyChange, 
        setReviewSettings, 
        gglReviewText,
        facebookReviewText,
        trustpilotText,
        stampedioText,
        reviewioText,
        capterraText,
        fontWeight,
        fontFamily,
        color,
        notifType
        } = props

    const [fontColorPopper, setFontColorPopper] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);



    const handleFontColorPopper = (event) => {
        if(fontColorPopper === false) {
            setFontColorPopper(true)
            setAnchorEl(event.currentTarget)
        } else {
            setFontColorPopper(false)
            setAnchorEl(null)
        }
    }

    return(
        <Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container>
                <Grid item md={12}>
                    <Typography className={classes.boxTitle}>TEXT</Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center" direction="row" spacing={2}>
                        <Grid item md={3}>
                            <Typography variant="body2">Font Style</Typography>
                        </Grid>
                        <Grid item md={3} style={{paddingLeft: '4px'}}>
                            <ReactSelect 
                                width='120px' 
                                height='35px' 
                                placeholder="font weight"
                                name="fontWeight"
                                value={fontWeight}
                                dispatcher={setReviewSettings}
                                onChange={handlePanelStyleChange}
                                options= {[
                                    {label:"Normal", value:"normal"},
                                    {label:"Bold", value:'bold'}
                                ]}
                                
                            />
                        </Grid>
                        <Grid item md={3}>
                            <ReactSelect 
                                width='120px' 
                                height='35px' 
                                placeholder="Font family"
                                onChange={handlePanelStyleChange}
                                name="fontFamily"
                                value={fontFamily}
                                dispatcher={setReviewSettings}
                                options= {[
                                    {label:"Default", value: 'inherit'},
                                    {label:"Monospace", value:'monospace'},
                                    {label:"Arial", value:'arial'},
                                    {label:"Georgia", value:'georgia'}
                                ]}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <div 
                                className={fontColorPopper ? classes.colorIconActive : classes.colorIcon} 
                                onClick={handleFontColorPopper} 
                                style={{backgroundColor: `rgb(${color.r}, ${color.g}, ${color.b})`}}></div>
                            <Popover
                                open={fontColorPopper} 
                                onClose={handleFontColorPopper} 
                                anchorOrigin={{vertical: 'bottom',horizontal: 'center',}} 
                                transformOrigin={{vertical: 'top',horizontal: 'right',}}
                                anchorEl={anchorEl}
                            >
                                <SketchPicker 
                                    color={color} 
                                    onChange={(e) => {
                                        handlePanelStyleChange("color",e.rgb, setReviewSettings)
                                        // rgbSelector(e.rgb, setIconColorValue)
                                    }
                                } />
                            </Popover>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center" style={{paddingTop: '20px'}}>
                        <Grid item md={3} className={classes.gridPading}>
                            <Typography variant="body2">Review Text</Typography>
                        </Grid>
                        <Grid item md={9}>
                            <InputBox
                            value ={
                            notifType === 'google' ? gglReviewText :
                            notifType === 'facebook' ?facebookReviewText :
                            notifType === 'stamped' ? stampedioText : 
                            notifType === 'reviewio' ? 'reviewed us on Review.io':
                            notifType === 'trustpilot' ? trustpilotText:
                            notifType === 'capterra' ?'reviewed us on Capterra' : 
                            gglReviewText
                            }
                            />
                           
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}


const useStyles = makeStyles(() =>
    createStyles({
        colorIcon: {
            width: '35px',
            height: '35px',
            border: '1px solid #000',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        colorIconActive: {
            width: '35px',
            height: '35px',
            border: '2px solid rgba(201,201,201,0.7)',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        input: {
            height: '35px',
            backgroundColor: '#fff',
            marginTop: '4%',
            border: ' 0.5px solid rgba(0, 0, 0, 0.23)',
            width: '100%',
            borderRadius: '4px',
            padding: '0% 3%',
            fontSize: '13px'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            paddingBottom: '2%'
        },
    })
)

export default memo(Textbox);

