const helpArticleLinks = {
    integration:{
        wordpress:'https://support.useinfluence.co/article/668',
        woocommerce:'https://support.useinfluence.co/article/650',
        googleTagManager:'https://support.useinfluence.co/article/673',
        convertkit:'https://support.useinfluence.co/article/646',
        shopify:'https://support.useinfluence.co/article/651',
        mailchimp:'https://support.useinfluence.co/article/662',
        aweber:'https://support.useinfluence.co/article/627',
        sendInBlue:'https://support.useinfluence.co/article/628',
        neto:'https://support.useinfluence.co/article/623',
        mailerlite:'https://support.useinfluence.co/article/629',
        lemlist:'https://support.useinfluence.co/article/634',
        magento:'https://support.useinfluence.co/article/637',
        thrivecart:'https://support.useinfluence.co/article/639',
        aweberApp: 'https://support.useinfluence.co/article/1407',
        convertkitApp:'https://support.useinfluence.co/article/1408',
        lemlistApp:'https://support.useinfluence.co/article/1406',
        thrivecartApp:'https://support.useinfluence.co/article/1409',
        mailerliteApp:'https://support.useinfluence.co/article/1410',
        shopifyApp:'https://support.useinfluence.co/article/1411',
        customWebhook:'https://support.useinfluence.co/article/699',
        jotform:'https://support.useinfluence.co/article/654',
        drip:'https://support.useinfluence.co/article/653',
        gist:'https://support.useinfluence.co/article/649',
        agileCRM:'https://support.useinfluence.co/article/648',
        crisp:'https://support.useinfluence.co/article/647',
        gravityForm:'https://support.useinfluence.co/article/645',
        optinMonster:'https://support.useinfluence.co/article/644',
        stripe:'https://support.useinfluence.co/article/643',
        clickfunnels:'https://support.useinfluence.co/article/678',
        teachable:'https://support.useinfluence.co/article/1412',
        thinkific:'https://support.useinfluence.co/article/619',
        moosend:'https://support.useinfluence.co/article/630',
        recurly:'https://support.useinfluence.co/article/631',
        typeform:'https://support.useinfluence.co/article/640',
        opencart:'https://support.useinfluence.co/article/1061',
        hubspot:'https://support.useinfluence.co/article/1438',
        webflow:'https://support.useinfluence.co/article/680',
        instapage:'https://support.useinfluence.co/article/694',
        infusionSoft:'https://support.useinfluence.co/article/688',
        landingi:'https://support.useinfluence.co/article/665',
        demio:'https://support.useinfluence.co/article/690',
        aloreCRM:'https://support.useinfluence.co/article/1444',
        JVZoo:'https://support.useinfluence.co/article/677',
        mautic:'https://support.useinfluence.co/article/656',
        leadPages:'https://support.useinfluence.co/article/692',
        squareSpace:'https://support.useinfluence.co/article/693',
        wix:'https://support.useinfluence.co/article/664',
        unBounce:'https://support.useinfluence.co/article/666',
        zapier:'https://support.useinfluence.co/article/695',
        ontraport:'https://support.useinfluence.co/article/675',
        mailmunch:'https://support.useinfluence.co/article/674',
        birdSeed:'https://support.useinfluence.co/article/622',
        podia:'https://support.useinfluence.co/article/620',
        joomla:'https://support.useinfluence.co/article/621',
        kartra:'https://support.useinfluence.co/article/633',
        settingUpFBReview:'https://support.useinfluence.co/article/1540',
        activeCamapignWebhook:'https://support.useinfluence.co/article/1736',
        howToAddTrustPilotReviews:'https://support.useinfluence.co/article/1813',
        prestaShop:'https://support.useinfluence.co/article/1814',
        mailchimpApp:'https://support.useinfluence.co/article/1878',
        sendInBlueApp:'https://support.useinfluence.co/article/1879',
        lemlistAppHelp:'https://support.useinfluence.co/article/1880',
        netoApp:'https://support.useinfluence.co/article/1881',
        capterraApp:'https://support.useinfluence.co/article/1882',
        stampedApp:'https://support.useinfluence.co/article/1883',
        trustPilotApp:'https://support.useinfluence.co/article/1884'

    },
    installation:{
        step1:'https://support.useinfluence.co/article/697',
        step2:'https://support.useinfluence.co/article/676',
        step3:'https://support.useinfluence.co/article/679',
        usingGoogleTagManage:'https://support.useinfluence.co/article/673'
    },
    gettingStarted:{
        whatAreSocialProofNotif:'https://support.useinfluence.co/article/682',
        subAccountBrandingURL:'https://support.useinfluence.co/article/1772',
        subAccountBrandingText:'https://support.useinfluence.co/article/1774',
        subAccountIntervalCount:'https://support.useinfluence.co/article/1775',
        subAccountSelectInterval:'https://support.useinfluence.co/article/1776',
        goals:'https://support.useinfluence.co/article/638'
    },
    advancedFeatures:{
        randomizeDelay:'https://support.useinfluence.co/article/1418',
        loopNotifications:'https://support.useinfluence.co/article/1419',
        countrySelection:'https://support.useinfluence.co/article/1420',
        mobilePosition:'https://support.useinfluence.co/article/1421',
        desktopPosition:'https://support.useinfluence.co/article/1422',
        displayTime:'https://support.useinfluence.co/article/1423',
        initialDelay:'https://support.useinfluence.co/article/1424',
        generalHelp:'https://support.useinfluence.co/article/1341'
    },
    faq:{
        apiKeys:'https://support.useinfluence.co/article/1405',
        customerProfile:'https://support.useinfluence.co/article/613',
        gettingTackingIdAPI:'https://support.useinfluence.co/article/606',
        doesInfluenceWorkForOnlyPurchase:'https://support.useinfluence.co/article/612',
        uploadDataOfPastCustomers:'https://support.useinfluence.co/article/614',
        fakeNotification:'https://support.useinfluence.co/article/616',
        affiliateProgram:'https://support.useinfluence.co/article/609',
        removeCustomersProfile:'https://support.useinfluence.co/article/615',
        GDPR:'https://support.useinfluence.co/article/610',
        howDoesInfluenceWork:'https://support.useinfluence.co/article/611',
        getRidOfInfluenceBranding:'https://support.useinfluence.co/article/608',
        whitelabel:'https://support.useinfluence.co/article/658',
        settingUpCapterraReviews:'https://support.useinfluence.co/article/1737',
        settingUpStampedIOReviews:'https://support.useinfluence.co/article/1738',
        addingAndManagingTeamMates:'https://support.useinfluence.co/article/652',
        addingAndManagingSubAccounts:'https://support.useinfluence.co/article/657',
        subAccount:'https://support.useinfluence.co/article/1875',
        subAccountPlans:'https://support.useinfluence.co/article/1876',
        notification:{
            googleReviewText:'https://support.useinfluence.co/article/1413',
            reviewType:'https://support.useinfluence.co/article/1414',
            purchaseTextRecent:'https://support.useinfluence.co/article/1415',
            // displayIconBulk:'https://support.useinfluence.co/article/1416',
            googleApp:'https://support.useinfluence.co/article/1417',
            clickable:'https://support.useinfluence.co/article/1425',
            featuresReview:'https://support.useinfluence.co/article/1426',
            featuresBulk:'https://support.useinfluence.co/article/1427',
            featuresLive:'https://support.useinfluence.co/article/1429',
            displayIconBulk:'https://support.useinfluence.co/article/1543',
            hideUsernameRecent:'https://support.useinfluence.co/article/1430',
            clickableRecent:'https://support.useinfluence.co/article/1434',
            branding:'https://support.useinfluence.co/article/1435',
            settingUpReview:'https://support.useinfluence.co/article/1344',
            settingUpLive:'https://support.useinfluence.co/article/1345',
            settingsUpBulk:'https://support.useinfluence.co/article/1346',
            settingsUpRecent:'https://support.useinfluence.co/article/1347',
            settingUpAnnouncement:'https://support.useinfluence.co/article/1739',
            settingUpGoogleReviews:'https://support.useinfluence.co/article/1143',
            language:'https://support.useinfluence.co/article/607',
        },
        displayPage:{
            exclude:'https://support.useinfluence.co/article/1431',
            settingUp:'https://support.useinfluence.co/article/1343'
        },
        goals:{
            setConversionValue:'https://support.useinfluence.co/article/1432',
            category:'https://support.useinfluence.co/article/1433'
        },
        captureLeads:{
            websiteURL:'https://support.useinfluence.co/article/1436',
            settingUp:'https://support.useinfluence.co/article/1342',
            captureSignupWithSocial:'https://support.useinfluence.co/article/617',
            mailchimp:'https://support.useinfluence.co/article/1878',

        },
        whiteLabel:{
            brandName: 'https://support.useinfluence.co/article/1874',
            helpArticles: 'https://support.useinfluence.co/article/1873',
            maskingDoamin:'https://support.useinfluence.co/article/1872',
            headerScript:'https://support.useinfluence.co/article/1871'
        }
    }

}


export default helpArticleLinks