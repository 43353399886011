import { call, put, fork, takeLatest } from 'redux-saga/effects';
import * as api from 'services/api';
import * as actions from 'ducks/lemlist';
import * as actions_webhook from 'ducks/webhooks';
import { succeessLemlist, popLemlist } from 'ducks/lemlist'; 
import { load, loaded } from 'ducks/loading';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

const errorMessge = 'Please try again or refresh!';

const toastConfig = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 2000,
  className: 'toast-style'
};

function* get(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `lemlist?campaignId=${action.campaignId}`);
    if(!res.error) {    
      yield put(succeessLemlist(res));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}
function* add(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'lemlist', action.lemlist);
    if(!res.error) {    
      yield put(succeessLemlist(res));
      yield toast.error('api key added', toastConfig);
      yield put(actions_webhook.fetchAppsConnection(action.lemlist.campaignId));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}
function* deleteLemlist(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `lemlist/${action.id}`);
    if(!res.error) {    
      yield put(popLemlist(action.index));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

export function* watchAdd() {
  yield takeLatest(actions.CREATE, add);
}
export function* watchGet() {
  yield takeLatest(actions.FETCH, get);
}
export function* watchDelete() {
  yield takeLatest(actions.REMOVE, deleteLemlist);
}


export default function* rootSaga() {
  yield [
    fork(watchAdd),
    fork(watchGet),
    fork(watchDelete),
  ];
}
