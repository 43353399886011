import { call, put, fork, takeLatest } from 'redux-saga/effects';
import * as api from 'services/api';
import * as actions from 'ducks/facebook';
import * as actions_webhook from 'ducks/webhooks';
import { succeessFacebook, popFacebook } from 'ducks/facebook'; 
import { load, loaded } from 'ducks/loading';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

const errorMessge = 'Please try again or refresh!';

const toastConfig = {
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: 2000,
  className: 'toast-style'
};

function* get(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `facebook?campaignId=${action.campaignId}`);
    if(!res.error) {    
      yield put(succeessFacebook(res));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}
function* add(action) {
  try {
    yield put(load());
    if(!action.facebook.accessToken) 
      yield put(loaded());
    else {
      const res = yield call(api.POST, 'facebook', action.facebook);
      if (!res.error) {
        yield put(succeessFacebook(res));
        yield toast.error('api key added', toastConfig);
        yield put(actions_webhook.fetchAppsConnection(action.facebook.campaignId));
      } else {
        yield toast.error(res.message, toastConfig);
      }
      yield put(loaded());
    }
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* update(action) {
  try {
    yield put(load());
    const res = yield call(api.PUT, `facebook/${action.facebook._id}`, action.facebook);
    if (res.error)
      Sentry.captureException(res.error);
    else if(action.aweber)
      yield put(actions.succeessFacebook(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    }
    else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* deleteFacebook(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `facebook/${action.id}`);
    if(!res.error) {    
      yield put(popFacebook(action.index));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

export function* watchAdd() {
  yield takeLatest(actions.CREATE, add);
}
export function* watchUpdate() {
  yield takeLatest(actions.UPDATE, update);
}
export function* watchGet() {
  yield takeLatest(actions.FETCH, get);
}
export function* watchDelete() {
  yield takeLatest(actions.REMOVE, deleteFacebook);
}


export default function* rootSaga() {
  yield [
    fork(watchAdd),
    fork(watchGet),
    fork(watchDelete),
    fork(watchUpdate)
  ];
}
