import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import CustomToast from '../../Globals/GlobalComponents/CustomToast'


const UniversalToast = () => {
    
    const dispatch = useDispatch()
    const fireToasterData = useSelector(state => (state.getIn(['fireToaster','fireToaster']))) || []
    const [open, setOpen] = useState(false) 
    

    useEffect(()=>{
        if(Object.keys(fireToasterData).length !== 0 && fireToasterData.constructor === Object){
            setOpen(true)
        }
    },[fireToasterData])

    
    const closeHandler=()=>{
        setOpen(false)
        dispatch({type: 'FIRE_TOASTER', payload: {}})
    }
    
    return (
        <CustomToast
            open={open}
            closeHandler={closeHandler}
            severity={fireToasterData.type}
            message={fireToasterData.message}
            />
    )
}

export default UniversalToast





/**
 * Universal Toaster message component
 * Rendered in CookieContainer component
 * 
 * 
 * dispatch action 'FIRE_TOASTER' with payload as in format {type: "error || success, message: "messageString"}
 * 
 * dispatch({action: 'FIRE_TOASTER, payload:{type:"type", message: "message"}})
 * 
 */