import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Box, makeStyles, createStyles, Typography, Button, CircularProgress} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import { withStyles } from "@material-ui/core/styles";
import { compose } from 'redux';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { base } from 'services/api';


const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
		color: '#32325d',
		fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
		fontSmoothing: 'antialiased',
		fontSize: '16px',
		'::placeholder': {
			color: '#aab7c4'
		}
	},
	invalid: {
	color: '#fa755a',
	iconColor: '#fa755a'
	}
	}
};

class StripePaymentForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dashlyStatus: false
		}
	}

	state = {
		error:'',
		stripeToken: 'pk_live_bafSzfqWXcJtQ3WdguKE1sRg',
		name: '',
		email: '',
		loading:false
	};


	callDashly = () =>{
		const {user, plan}=this.props
		window.dashly.track("Plan Subscribed", {
			email: user.email,
			name: "Testing1612",
			planName: plan.name,
			planAmount: (plan.amount)/100,
			id: user._id
		});
	}

	handleSubmit = async (e) => {
		e.preventDefault();
		this.setState({loading:true})

		if(!this.state.name){
			this.setState({error: 'Card holder name is empty.', loading:false})
			return
		}

		const {user, plan}=this.props

		let token = await this.props.stripe.createToken({ name: this.state.name, amount:0 })

		if(_.get(token,'error.message')){
			this.setState({error: _.get(token,'error.message'), loading:false})
			return
		}
		// console.log(paymentMethod);
			const paymentMethod = await this.props.stripe
		.createPaymentMethod({
			type: 'card',
			card: {token:token.token.id},
			billing_details: {name: this.state.name},
		})


		//make the call to the NodeJs Striper Server to create the charge

		await fetch(`${base}payment`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				user,
				plan,
				paymentMethod:paymentMethod.paymentMethod,
				token:token.token,
			})
		}).then(()=>{
				this.setState({loading:false, error:'Subscription created successfully.'})
				this.callDashly()
				this.props.history.push('/getting-started')
				window.location.reload();
		}).catch(() =>{
			this.setState({error:'Internal Server Error', loading:false})
		})


	};
	

	render() {
		const {plan, classes}=this.props
		const {loading}=this.state
		return (
			<Box className={classes.mainContainer}>
				<input 
					className={`${classes.nameInput} ${classes.inputStyle}`} 
					placeholder="Cardholder's Name" 
					type="text"
					onChange={(event)=> this.setState({name:event.target.value, error:''})}
				/>
				<form onSubmit={this.handleSubmit}>
					<CardElement style={{base: {fontSize: '14px', backgroundColor: '#ffffff'}}} className={classes.cardElement}/>
					<Box className={classes.lowerBtnContainer}>
						<Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
							<input placeholder="Coupon Code" type="text" className={`${classes.couponInput} ${classes.inputStyle}`}/>
							<Button className={classes.applyBtn} onClick={this.handleCheckCoupenAvailability}>
								Apply
							</Button>
						</Box>
						<Button 
							type="submit" 
							disabled={!this.props.stripe || loading} 
							variant="contained" 
							className={classes.startBtn}
						>
							{`Start Free Trial ($ ${(plan.amount/100)})`} 
							{
								loading && <CircularProgress size={19} className={classes.buttonProgress} />}
							
						</Button>
					</Box>
					{	
						this.state.error && 
							<Alert severity="error">{this.state.error}</Alert>
					}
				</form>
				{plan && (/\b(Lifetime)\b/m.test(plan.name) || plan.amount==0)?'':
					<Box className={classes.textContainer}>
						<Typography className={classes.textStyle}> <CheckCircleOutlinedIcon style={{fontSize:'14px', color:'green', marginRight:'4px'}}/>Your card will not be charged until the end of your 7-day trial.</Typography>
						<Typography className={classes.textStyle}> <CheckCircleOutlinedIcon style={{fontSize:'14px', color:'green', marginRight:'4px'}}/>Cancel any time or Pause your account.</Typography>
						<Typography className={classes.textStyle}> <CheckCircleOutlinedIcon style={{fontSize:'14px', color:'green', marginRight:'4px'}}/>We support you at every step of the process!</Typography>
					</Box>
				}
			</Box>
		);
	}
}


const useStyles = createStyles({
	mainContainer:{
		padding: '5% 0'
	},
	inputStyle:{
		padding: '5px',
		height: '35px',
		border: 'none',
		transition: 'background-color 0.2s',
		'&:focus':{
			backgroundColor: '#c6c6c621',
			outline: 'none'
		}
	},
	nameInput:{
		width: '100%', 
		marginBottom: '15px',
	},
	couponInput:{
		
	},
	lowerBtnContainer:{
		display: 'flex', 
		justifyContent: 'space-between', 
		marginTop: '15px',
		'& >div':{
			marginRight:'10px'
		},
		'@media (max-width: 674px)':{
			flexDirection:'column',
			'& >div':{
				marginBottom:'10px',
				marginRight:'0'
			},
			'& > div > input':{
				width:'70%'
			},
			'& > div > button':{
				width:'30%'
			}
		}
	},
	textContainer:{
		textAlign: 'left',
		marginTop: '10%'

	},
	applyBtn:{
		background: '#ffc107',
		borderRadius: '0 4px 4px 0px',
		height: '35px'
	},
	textStyle:{
		fontSize: '13px',
		color: '#56596c',
		fontWeight: '600',
		padding: '2px 0',
		display:'flex',
		alignItems:'center',
		flexDirection:'row'
	},
	buttonProgress: {
		color: "#fff",
		position: 'absolute',
		top: '55%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	startBtn:{
		width: '270px', 
		height: '35px', 
		fontSize: '1rem',
		fontWeight: '600',
		letterSpacing: '0.3px',
		'@media (max-width: 674px)':{
			width:'100%'
		}
	},
	cardElement: {
		marginBottom: '25px',
		marginTop:'25px',
		padding:'7px',
		background:'#fff'
	},
})


// const useStyles = () =>({
// 	mainContainer:{
// 		padding: '5% 0'
// 	},
// 	inputStyle:{
// 		padding: '5px',
// 		height: '35px',
// 		border: 'none',
// 		transition: 'background-color 0.2s',
// 		'&:focus':{
// 			backgroundColor: '#c6c6c621',
// 			outline: 'none'
// 		}
// 	},
// 	nameInput:{
// 		width: '100%', 
// 		marginBottom: '15px',
		
// 	},
// 	couponInput:{
		
// 	},
// 	textContainer:{
// 		textAlign: 'left',
// 		marginTop: '10%'

// 	},
// 	applyBtn:{
// 		background: '#ffc107',
// 		borderRadius: '0 4px 4px 0px',
// 		height: '35px'
// 	},
// 	textStyle:{
// 		fontSize: '13px',
// 		color: '#56596c',
// 		fontWeight: '600',
// 		padding: '2px 0',
// 		display:'flex',
// 		alignItems:'center',
// 		flexDirection:'row'
// 	},
// 	buttonProgress: {
// 		color: "#fff",
// 		position: 'absolute',
// 		top: '55%',
// 		left: '50%',
// 		marginTop: -12,
// 		marginLeft: -12,
// 	},
// 	cardElement: {
// 		marginBottom: '25px',
// 		marginTop:'25px',
// 		padding:'7px',
// 		background:'#fff'
// 	},
// })




export default injectStripe(withRouter (withStyles(useStyles)(StripePaymentForm)));
// export default compose(injectStripe, withStyles(useStyles())(StripePaymentForm))
