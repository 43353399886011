import React, { useState } from 'react'
import { Box, makeStyles, createStyles, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import LoadingButton from '../../../Globals/GlobalComponents/LoadingButton'

const ProductAccessModal  = (props) => {
  const classes = useStyles()
  const {open, onClick, isLoading, disabled=true, product="socialProof"} = props
  return (
    <Dialog style={{ padding: '26px' }} open={open} style={{ textAlign: 'center' }}>
      <DialogTitle style={{ fontWight: 'bold', fontSize: '24px', color: '#000' }}>
        Start My Free Trial
      </DialogTitle>
      <DialogContent>
        <Typography>
          The product you've attempted to access needs to be activated. Upgrade now to unlock this
          feature.
        </Typography>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isLoading}
          onClick={onClick}
          style={{ width: '100px', margin: 'auto' }}
          disabled={disabled}
          variant="contained"
        >
          {['cookie', 'policy'].includes(product) ? 'Beta Access' : 'Upgrade'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({

  })
)

export default ProductAccessModal