import React, { Suspense, useEffect, lazy } from 'react';
import { Box, makeStyles, createStyles} from '@material-ui/core';
// import CampaignSettingHeader from '../CampaignSettingHeader/CampaignSettingHeader';
import { withRouter } from 'react-router';
// import CampaignSettingNavigation1 from '../CampaignSettingsNavigation/CampaignSettingsNavigation1';
import { useSelector, useDispatch} from 'react-redux'
import { getAdvanceSettings} from './Action';
import CampaignSettingsSekelton from '../../Globals/GlobalComponents/CampaignSettingsSekelton';
const CampaignSettingHeader = lazy(() => import("../CampaignSettingHeader/CampaignSettingHeader"))
const CampaignSettingNavigation1 = lazy(() => import("../CampaignSettingsNavigation/CampaignSettingsNavigation1"))



const CampaignSettingsPanel  = (props) => {

    
    const classes = useStyles();
    const campaignInfo = useSelector(state => (state.getIn(['CampaignInfo', 'campaignInfo']))) || []
    const dispatch = useDispatch()


    useEffect(() =>{ 
        
        // if( savedFormDetails !== undefined && campaignInfo.find(item => item._id === props.params.id)._id === currentId ) return;
        // dispatch(getAdvanceSettings(campaignInfo.find(item => item._id === props.params.id).rule._id))
        if(campaignInfo.find(item => item._id === props.params.id)){
            dispatch(getAdvanceSettings(campaignInfo.find(item => item._id === props.params.id).rule._id))
        }
        
     },[campaignInfo])


    return(
        <Box className={classes.container}>
            <Suspense fallback={<CampaignSettingsSekelton style={{padding:'0'}}/>}>
                <CampaignSettingHeader campaignInfo={campaignInfo.find(item => item._id === props.params.id)} />
                <CampaignSettingNavigation1 campaignInfo={campaignInfo.find(item => item._id === props.params.id)} />
            </Suspense>
        </Box>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        container:{
            // padding: '3rem'
        },
        [theme.breakpoints.down('md')]:{
            container:{
                padding: '2.5rem'
            }
        }
    })
)

export default withRouter(CampaignSettingsPanel) ;