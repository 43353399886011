import React, { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import filter from 'lodash/filter'
import WebsiteCheckout from './WebsiteCheckout'
import WebsitePriceNew from './WebsitePriceNew'
import { GET } from 'services/api'
import { browserHistory } from 'react-router'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'
import getTokens from 'utilities/getTokens'
const stripePromise = loadStripe('pk_live_bafSzfqWXcJtQ3WdguKE1sRg')
class WebsitePayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: false,
      planSelected: '',
      externalValue: true,
      planPeriod: 12,
      servicebotPlans: [],
      planClick: 'Advanced',
      coupon: '',
      monthlyPlans: [],
      yearlyPlans: [],
      selectedPlan: {},
      queryPlan: false,
      // intercomm: ''
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    var url_string = window.location.href
    var url = new URL(url_string)
    // var calendlyStatus = url.searchParams.get('calendly')
    // if (calendlyStatus) {
    //   localStorage.setItem('calendly', 'true')
    // }
  }

  componentWillMount() {
    const queryPlan = this.props.location && this.props.location.query.plan
    if (queryPlan !== 'freePlan') {
      this.setState({ queryPlan })
    }
    var that = this
    const arr = [
      'price_1IfeqLCkWzi7OrXWxhlQF48F',
      'price_1Ifeu2CkWzi7OrXWf6nFYKnT',
      'price_1Ig2EKCkWzi7OrXWACD6IUyK',
      'price_1IqehnCkWzi7OrXWpFB4z6yc',
    ]
    GET('plan/chargebee').then(function (res) {
      const PlanDataList = res.filter(i=> !arr.includes(i.id))
      // const PlanDataList = res
      if (PlanDataList && get(PlanDataList, 'length') > 0) {
        let lstPlan = []
        for (var i = 0; i < PlanDataList.length; i++) {
          if (PlanDataList[i].active)
            lstPlan.push({
              currency: PlanDataList[i].currency,
              details: get(PlanDataList[i], 'description'),
              free_quantity: 0,
              id: PlanDataList[i].id,
              invoice_name: PlanDataList[i].nickname,
              name: PlanDataList[i].nickname,
              object: PlanDataList[i].object,
              interval_count: PlanDataList[i].interval_count,
              interval: PlanDataList[i].interval,
              amount: PlanDataList[i].amount,
              status: PlanDataList[i].active,
              description: Number(PlanDataList[i].metadata.uniquevisitorqouta),
              teammateQouta: Number(get(PlanDataList[i], 'metadata.teammateqouta') || 0),
              trial_period: PlanDataList[i].trial_period_days,
            })
        }

        let planClick, planPeriod
        let selectedPlan = {}
        lstPlan = lstPlan.filter((plan) => plan.name !== 'Agency')

        // if (queryPlan && queryPlan.toLowerCase().indexOf('ltd') !== -1) {
        //   lstPlan = lstPlan.filter((plan) => plan.name.toLowerCase().indexOf('lifetime') !== -1)
        //   planPeriod = 0
        // } else {
        //   lstPlan = lstPlan.filter((plan) => plan.trial_period == 7 || plan.amount == 0)
        // }

        lstPlan.map((plan) => {
          //console.log('===plan.id====',plan.id);
          if (plan.id == queryPlan) {
            planClick = that.filterPlanName(plan.name)
            selectedPlan = plan
          }

          if (plan.id == queryPlan && queryPlan && queryPlan.toLowerCase().indexOf('ltd') === -1)
            planPeriod =
              plan.interval == 'year'
                ? 12
                : plan.interval == 'month' && plan.interval_count == 6
                ? 6
                : 1
        })

        let monthsPlan = []
        monthsPlan = lstPlan.filter((plan) => plan.interval == 'month')
        monthsPlan.sort((a, b) => a.amount - b.amount)

        let yearPlans = []
        yearPlans = lstPlan.filter((plan) => plan.interval == 'year')
        yearPlans.sort((a, b) => a.amount - b.amount)
        console.log('lst-', lstPlan)
        console.log('qury--', queryPlan)

        //that.setState({servicebotPlans: lstPlan});
        that.setState({
          servicebotPlans: lstPlan,
          selectedPlan: selectedPlan,
          planClick: planClick,
          planPeriod: planPeriod,
          monthlyPlans: monthsPlan,
          yearlyPlans: yearPlans,
        })
        //console.log('============ lstPlan ===========',lstPlan,selectedPlan,planClick,planPeriod);
      }
    })
  }

  handleChange = (checked) => {
    this.setState({ checked })
  }

  handleMonthChange = () => {
    this.setState({ externalValue: false, planPeriod: 1 })
  }

  // handleQuaterlyChange = () => {
  //   this.setState({externalValue: false, planPeriod: 6});
  // }

  handleYearChange = () => {
    this.setState({ externalValue: true, planPeriod: 12 })
  }

  handleSwitchChange = (value) => {
    if (value) {
      this.setState({ externalValue: true, planPeriod: 12 })
    } else {
      this.setState({ externalValue: false, planPeriod: 1 })
    }
  }

  filterPlanName = (planName) => {
    let res = ''

    switch (true) {
      case /\b(Enterprise)\b/m.test(planName):
        res = 'Enterprise'
        break
      case /\b(Advanced)\b/m.test(planName):
        res = 'Advanced'
        break
      case /\b(Growth)\b/m.test(planName):
        res = 'Growth'
        break
      case /\b(Startups)\b/m.test(planName):
        res = 'Startups'
        break
      case /\b(Lifetime Starter)\b/m.test(planName):
        res = 'Starter'
        break
      case /\b(Lifetime Professional)\b/m.test(planName):
        res = 'Professional'
        break
      case /\b(Lifetime Business)\b/m.test(planName):
        res = 'Business'
        break
      case /\b(Lifetime Agency)\b/m.test(planName):
        res = 'Agency'
        break
      case /\b(Lifetime Enterprise)\b/m.test(planName):
        res = 'Enterprise'
        break
      case /\b(Lifetime Charter)\b/m.test(planName):
        res = 'Charter Partner'
        break
      case /\b(Free Forever)\b/m.test(planName):
        res = 'Forever Free'
        break
      default:
        break
    }
    return res
  }

  stellarFeel = (name) => {
    let stellar = ''
    this.filterPlanName(name) === this.state.planClick ? (stellar = 'bg--primary') : (stellar = '')
    return stellar
  }

  handlePlanSelect = (plan) => {
    this.setState({ selectedPlan: plan })
  }

  clearSelectedPlan = (coupon) => {
    // const selectedPlan = this.state.servicebotPlans.filter(plan => plan.references.service_template_properties.length && plan.references.service_template_properties[0].data.value === coupon.code);
    // this.setState({ coupon: coupon, selectedPlan: selectedPlan[0]});
    this.setState({ coupon: coupon, selectedPlan: {}, queryPlan: false })
  }

  clearCoupon = () => {
    this.setState({ coupon: '' })
  }

  handlePlanChoose = async (plan) => {
    console.log('$$$$$$$$$$$', plan)
    const res = await axios({
      method: 'POST',
      url: '/payment/session',
      data: { plan, user: this.props.user },
      headers: {
        Authorization: `Bearer ${getTokens()}`,
      },
    })
    console.log(res, '@@@@@22')
    if (res.data.id) {
      console.log('%%%%%%%%%%%%%%%%%%%%%')
      const stripe = await stripePromise
      const result = await stripe.redirectToCheckout({
        sessionId: res.data.id,
      })
      console.log(result)
    }

    // console.log(plan)
    // this.setState({ planClick: this.filterPlanName(plan.name), selectedPlan: plan })
  }

  handleLogout = () => {
    document.body.style = 'background-color:white'
    localStorage.removeItem('authToken')
    browserHistory.push('/login')
    // console.log('---------------------')
    // window.open(window.location.origin + '/login', '_self')
  }

  render() {
    const { selectedPlan, coupon, queryPlan, externalValue, planPeriod } = this.state

    return (
      <div>
        {/* <Intercom appID="bstu2fsj" { ...intercomm } /> */}
        {Object.keys(selectedPlan).length > 0 || queryPlan ? (
          <WebsiteCheckout
            coupon={coupon}
            clearCoupon={this.clearCoupon}
            clearSelectedPlan={this.clearSelectedPlan}
            selectedPlan={selectedPlan}
          />
        ) : (
          <WebsitePriceNew
            servicebotPlans={this.state.servicebotPlans}
            selectedPlan={this.state.selectedPlan}
            yearlyPlans={this.state.yearlyPlans}
            monthlyPlans={this.state.monthlyPlans}
            handlePlanChoose={this.handlePlanChoose}
            handleLogout={this.handleLogout}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.getIn(['auth', 'user']),
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(WebsitePayment)
