import React from 'react';
// import './WebsiteCheckout.scss';
import { Box, makeStyles, createStyles, Typography, Button } from '@material-ui/core'
// import  LoginFlow from '../LoginFlow/LoginFlow';
import PaymentFlow from './PaymentFlow'
import theme from '../../Resources/theme';



const WebsiteCheckout = ({ selectedPlan, clearSelectedPlan, coupon, couponDetails, }) =>  {
    const classes = useStyles();

    // console.log(theme)

    (function() {
        let scrollElm = document.scrollingElement;
        scrollElm.scrollTop = 0;
    })();


    return (
        <Box style={{height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'}}>
            <Box className={classes.mainContainer}>
            {/*
                <Box style={{paddignTop: '5%'}}>
                    <Typography 
                        style={{
                            fontSize: '1.1rem', 
                            color: '#212529', 
                            padding: '0.4em 2em ', 
                            border: '1px solid #c6c6c6', 
                            borderRadius: '4px',
                            margin: 'auto',
                            width: 'fit-content'
                        }}
                    >
                        Step 3/3
                    </Typography>
                    <Typography style={{textAlign: 'center', fontSize: '18px', margin: '15px 0'}}>
                        Let's Get Started
                    </Typography>
                </Box>
                    */}
                <Box>
                    <PaymentFlow
                        coupon={coupon}
                        couponDetails={couponDetails}
                        selectedPlan={selectedPlan}
                        clearSelectedPlan={clearSelectedPlan}
                    />
                </Box>
            </Box>
        </Box>
    );
};


const useStyles = makeStyles((theme)=>
        createStyles({
            mainContainer:{
                width: '45%',
                margin: '0 auto',
                minWidth: '600px',
                maxWidth:'800px'
            },
            [theme.breakpoints.down('md')]:{
                mainContainer:{
                    width:'50%',
                    minWidth:'500px'
                }
            },
            [theme.breakpoints.down('sm')]:{
                mainContainer:{
                    width:'70%',
                    minWidth:'300px',
                    maxWidth:'500px'
                }
            }
        })
    )



export default WebsiteCheckout;














    // <div className="websitecheckout-container" style={{paddingTop:'1.75%'}}>
    //   <div className="main-container">
    //     <h2 className="text-center btn" disabled> Step 3/3</h2>
    //     <p style={{fontSize:'18px', marginBottom:'15px'}}>Lets Get Started</p>
    //     <section className="bg--secondary pt-0">
    //       <div className="container text-center">
    //         <div className="row give-center-align" style={{justifyContent: 'center'}}>
    //           <div className=" col-md-8 card-link">
    //             <div>
    //             {
    //               // selectedPlan.amount == 0 ?
    //               //   proceedWithoutPayment():
    //                 <PaymentFlow
    //                   coupon={coupon}
    //                   couponDetails={couponDetails}
    //                   selectedPlan={selectedPlan}
    //                   clearSelectedPlan={clearSelectedPlan}
    //               />
    //             }
                  
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   </div>
    // </div>