import React, { useState, useEffect } from 'react'
import {
  Box,
  makeStyles,
  createStyles,
  Dialog,
  Grid,
  Typography,
  InputBase,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import clsx from 'clsx'
import { SelectBox } from 'components'
import { useSelector } from 'react-redux'
import TextInput from 'Globals/GlobalComponents/TextInput'
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined'
import SelectMultiple from 'Globals/GlobalComponents/SelectMultiple'

const AddServiceDialog_2 = (props) => {
  const {
    selectedService,
    modalScreen,
    personalDataOptions = [],
    regionDataOptions = [],
    setSavedServicesData,
    savedServicesData,
    isNew,
    addedServices,
  } = props
  const classes = useStyles()
  useEffect(() => {
    console.log('---------props------,,,,,..........------', props)
  }, [props])
  // const { data } = useSelector((state) => state.get('policymaster').toJS())

  const [formData, setFormData] = useState({
    personalData: [],
    regionalData: [],
    cookieLifeTime: {
      value: 0,
      data: [
        {
          name: '',
          unit: '0',
          value: '',
        },
      ],
    },
  })
  // const policyPersonalRegionalData = useSelector((state) => state.get('policyPersonalRegionalData').toJS())

  const personalDataSelectHandler = (e) => {
    setFormData((data) => ({ ...data, personalData: e }))
  }
  const regionDataSelectHandler = (e) => {
    setFormData((data) => ({ ...data, regionalData: e }))
  }

  const cookieLifeTimeRadioSelector = (e) => {
    console.log(e.target.value)
    const val = e.target.value
    setFormData((oldData) => ({
      ...oldData,
      cookieLifeTime: { ...oldData.cookieLifeTime, value: val },
    }))
  }

  const cookieLifeTimeValueHander = (e) => {
    const val = e.target.value
    setFormData((state) => ({
      ...state,
      cookieLifeTime: {
        value: state.cookieLifeTime.value,
        data: [{ unit: state.cookieLifeTime.data[0].unit, value: val }],
      },
    }))
  }

  const cookieLifetimeUnitSelector = (e) => {
    const val = e
    setFormData((state) => ({
      ...state,
      cookieLifeTime: {
        value: state.cookieLifeTime.value,
        data: [{ unit: val.value, value: state.cookieLifeTime.data[0].value }],
      },
    }))
  }

  const saveFinalData = () => {
    // setSavedServicesData(oldData => ({...oldData, services:{...formData}}))
    setSavedServicesData((oldData) => ({
      ...oldData,
      service: [
        ...oldData.service,
        {
          _id: selectedService.id,
          pid: selectedService.pid,
          name: selectedService.name,
          personalData: formData.personalData.map((data) => data.value),
          regionalData: formData.regionalData.map((data) => data.value),
          cookieLifeTime: formData.cookieLifeTime,
        },
      ],
    }))
  }

  const savedFinalDataImproved = () => {
    const temp = Object.keys(addedServices.data).length > 0 ? [...addedServices.data.service] : []
    const indexOfService = temp.findIndex((item) => item._id === selectedService._id)
    if (indexOfService < 0) {
      temp.push({
        _id: selectedService._id,
        pid: selectedService.pid,
        name: selectedService.name,
        personalData: formData.personalData.map((data) => data.value),
        regionalData: formData.regionalData.map((data) => data.value),
        cookieLifeTime: formData.cookieLifeTime,
      })
    } else {
      temp[indexOfService] = {
        _id: selectedService._id,
        pid: selectedService.pid,
        name: selectedService.name,
        personalData: formData.personalData.map((data) => data.value),
        regionalData: formData.regionalData.map((data) => data.value),
        cookieLifeTime: formData.cookieLifeTime,
      }
    }

    console.log('temp----', temp)

    setSavedServicesData(temp)
  }

  useEffect(() => {
    savedFinalDataImproved()
  }, [addedServices])

  const clearFormFields = () => {
    console.log('form cleared')
    setFormData({
      personalData: [],
      regionalData: [],
      cookieLifeTime: {
        value: 0,
        data: [
          {
            name: '',
            cookieDuration: '0',
            cookieDurationUnit: '',
          },
        ],
      },
    })
  }

  useEffect(() => {
    console.log('formData---------', formData)
  }, [formData])

  useEffect(() => {
    console.log('selectedService---------', selectedService)
    if (!isNew) {
      console.log('its ruuning here in the wild====', selectedService.cookieLifeTime)
      setFormData((oldData) => ({
        ...oldData,
        cookieLifeTime: selectedService.cookieLifeTime,
      }))
    }
  }, [selectedService, isNew])

  useEffect(() => {
    console.log('returning')
    return () => {
      clearFormFields()
    }
  }, [modalScreen])

  useEffect(() => {
    // console.log('---------------dddddd------',personalDataOptions.defaultValue.map(val=>personalDataOptions.options.filter(item=> item.value ===val)[0]));
    setFormData((oldData) => ({
      ...oldData,
      personalData: personalDataOptions.defaultValue.map(
        (val) => personalDataOptions.options.filter((item) => item.value === val)[0]
      ),
      regionalData: regionDataOptions.defaultValue.map(
        (val) => regionDataOptions.options.filter((item) => item.value === val)[0]
      ),
    }))
  }, [personalDataOptions, regionDataOptions])

  return (
    // <Grid spacing={2} container style={{ marginTop: '16px' }}>
    <Grid item md={9} style={modalScreen === 2 ? { display: 'block' } : { display: 'none' }}>
      <Box className={classes.step2_inputContainer}>
        <Typography className={classes.formInputLabel}>{selectedService.name}</Typography>
        <Typography>This service needs to be customized.</Typography>
      </Box>
      <Box className={classes.step2_inputContainer}>
        <Typography className={classes.formInputLabel}>Personal Data</Typography>
        <Box stlye={{ width: '100%' }}>
          <SelectMultiple
            value={formData.personalData}
            // defaultValue={personalDataOptions.defaultValue.map(val=>personalDataOptions.options.filter(item=> item.value ===val)[0])}
            isMulti
            onChange={personalDataSelectHandler}
            options={personalDataOptions.options}
            placeholder="Select personal data"
            name="personalDataSelect"
          />
        </Box>
      </Box>
      <Box className={classes.step2_inputContainer}>
        <Typography className={classes.formInputLabel}>Region</Typography>
        <Box stlye={{ width: '100%' }}>
          <SelectMultiple
            value={formData.regionalData}
            // defaultValue={personalDataOptions.defaultValue.map(val=>personalDataOptions.options.filter(item=> item.value ===val)[0])}
            isMulti
            onChange={regionDataSelectHandler}
            options={regionDataOptions.options}
            placeholder="Select Regions"
            name="regionDataSelect"
          />
        </Box>
      </Box>
      <Box className={classes.step2_inputContainer}>
        <Typography className={classes.formInputLabel}>Cookie Lifetime</Typography>
        <RadioGroup
          name="cookieLifeTime"
          value={formData.cookieLifeTime.value}
          onChange={cookieLifeTimeRadioSelector}
          className={classes.radioGroupStyle}
        >
          <FormControlLabel
            control={<Radio size="small" />}
            value={'1'}
            label={<Typography>Do not specify</Typography>}
          />
          <FormControlLabel
            control={<Radio size="small" />}
            value={'2'}
            label={
              <Typography>Define a maximum lifetime across all cookies and trackers</Typography>
            }
          />
        </RadioGroup>
        <Box
          className={clsx({
            [classes.hide]: formData.cookieLifeTime.value === '1',
            [classes.addCookieLifetimeContainer]: true,
          })}
        >
          <Typography>Upto</Typography>
          <Box>
            <TextInput
              onChange={cookieLifeTimeValueHander}
              type="number"
              value={formData.cookieLifeTime.data[0].value}
            />
          </Box>
          <Box style={{ marginTop: '-5px' }}>
            <SelectBox
              onChange={cookieLifetimeUnitSelector}
              options={[
                { label: 'hours', value: 'h' },
                { label: 'days', value: 'd' },
                { label: 'months', value: 'm' },
                { label: 'years', value: 'y' },
              ]}
              value={formData.cookieLifeTime.data[0].unit}
            />
          </Box>
        </Box>

        <Button
          onClick={savedFinalDataImproved}
          style={{ float: 'right', marginTop: '8px' }}
          variant="contained"
        >
          Save Data
        </Button>
      </Box>
    </Grid>
    // </Grid>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogPaper: {
      width: '85%',
      maxWidth:'1100px',
      padding: theme.spacing(3),
    },
    allCategoriesContainer: {
      '&>p': {
        marginBottom: theme.spacing(2),
      },
    },
    hide:  {
      display:  'none !important',
    },
    categoriesListContainer: {
      border: '1px solid #ddd',
      backgroundColor: '#FBFBFB',
      borderRadius: theme.shape.borderRadius,
      '&>p': {
        borderTop: '1px solid #ddd',
        // borderBottom: '1px solid grey',
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        color: '#808080',
        fontSize: '13px',
        fontWeight: '500',
        letterSpacing:'0.5px',
        '&:first-child': {
          borderTop: 'unset',
          borderRadius: theme.shape.borderRadius,
        },
        '&:last-child': {
          borderBottom: 'unset',
        },
        '&:hover': {
          color: '#59636D',
        },
        '&>span':{
          background:'#fff',
          borderRadius:'10px',
          padding:'4px 6px',
          fontSize:'12px'
        }
      },
      '&>p.active': {
        background: '#fff',
        color: '#59636D',
      },
    },
    allServicesContainer: {
      padding: theme.spacing(1),
      '&>p': {
        marginBottom: theme.spacing(2),
      },
    },
    servicesListContainer: {
      border: '1px solid #ddd',
      borderRadius: theme.shape.borderRadius,
    },
    serviceBoxStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 24px',
      cursor: 'pointer',
      borderBottom:'1px solid #dddddd',
      '&>p': {
        color: '#808080',
        fontSize: '14px',
        display:'flex',
        alignItems:'center',
        gap:'20px',
        '&>svg':{
          fontSize:'19px',
          color:'#000000a8',
        }
        // flexBasis:'80%'
      },
      '&>svg': {
        color: '##1f1f1f',
        fontSize: '14px',
        border:' 1px solid #dddddd',
        borderRadius: '2px',
        boxSizing:'initial'
        // flexBasis:'10%'
      },
      '&:hover': {
        backgroundColor: '#fbfbfb',
      },
    },

    frequentServicesContainer: {
      '&>p': {
        marginBottom: theme.spacing(2),
      },
    },
    frequentServicesListContainer: {
      border: '1px solid #ddd',
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      '&>p': {
        borderBottom: '1px solid #ddd',
        cursor: 'pointer',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        fontSize: '13px',
        color: '#5a5a5a',
        '&:last-child': {
          borderBottom: 'unset',
        },
        '&>img': {
          width: 11,
          height: 'auto',
          // flexBasis: '8%',
          marginRight:4
        },
        '&>span:nth-child(2)': {
          flexBasis: '80%',
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 11,
          letterSpacing:'0.7px'
        },
        '&>span:last-child': {
          flexBasis: '10%',
          fontWeight:'800',
          fontSize:'17px',
          lineHeight:'1'
        },
        '&:hover': {
          // backgroundColor:'#fbfbfb'
        },
      },
    },

    addedServicesContainer: {
      '&>p': {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(3),
      },
    },
    addedServicesListContainer: {
      border: '1px solid #ddd',
      borderRadius: theme.shape.borderRadius,
    },
    addedServiesWrapper: {
      borderTop: '1px solid #ddd',
      display: 'flex',
      alignItems: 'center',
      padding:  theme.spacing(1),
      gap: '8px',
      '&:first-child': {
        borderTop: 'unset',
      },
      '&>p': {
        fontSize: 12,
        flexBasis: '70%',
        color:  '#808080',
        letterSpacing:'0.7px'
      },
    },
    addedServicesIconsContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      flexBasis: '25%',
      '&>svg': {
        fontSize: 14,
        color: 'grey',
        cursor: 'pointer',
      },
    },
    footerContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(1),
    },
    step2_inputContainer: {
      marginTop: theme.spacing(2),
      '&:first-child':{
        marginTop:'0'
      }
    },
    addCookieLifetimeContainer: {
      padding: '8px 16px',
      border: '1px solid #ddd',
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      '&>p': {
        fontSize: '14px',
        flexBasis: '0',
        flexGrow: '2',
      },
      '&>div': {
        flexBasis: '0',
        flexGrow: '5',
      },
    },
    formInputLabel:{
      fontWeight:'500',
      color:'#808080',
      fontSize:'14px'
    }
  })
)


export default AddServiceDialog_2
