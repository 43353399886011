import React, { memo } from 'react';
import { Box, makeStyles, createStyles, Grid, Typography, Checkbox } from '@material-ui/core';
import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
import InputBox from '../../../../Globals/GlobalComponents/InputBox';
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';


const FeaturesBox  = (props) => {
    const classes = useStyles();

    const {
        recentText1,
        orderText,
        isDisplayPurchase,
        isHideLastname,
        isDisplaySignup,
        recentNumber,
        recentConv,
        selectLastDisplayConversation,
        hideAnonymousConversion,
        otherText,
        contentText,
        extraText,
        handleInputChange,
        setRecentSettings,
        handlePropertyChange,
        handlePanelStyleChange,
        daysAgo,
        hoursAgo,
        minuteAgo,
        captureAllData,
    } = props;

    return(
        <Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Typography className={classes.boxTitle}>Features</Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={3}>
                            <Typography variant="body2">Brand Name</Typography>
                        </Grid>
                        <Grid item md={9}>
                            <InputBox
                                placeholder="Brand Name"
                                name="contentText"
                                value={contentText}
                                dispatcher={setRecentSettings}
                                onChange={handleInputChange}
                                maxLength={40}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item md={3}>
                            <Typography variant="body2">Other Text</Typography>
                        </Grid>
                        <Grid item md={5} style={{paddingLeft: '4px'}}>                            
                            <InputBox
                                name="otherText"
                                dispatcher={setRecentSettings}
                                value={otherText}
                                placeholder="Other"
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <InputBox
                                name="recentText1"
                                dispatcher={setRecentSettings}
                                value={recentText1}
                                placeholder="for"
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={3}>
                            <Typography variant="body2">
                                Purchase text
                                {/* <a href={helpArticleLinks.faq.notification.purchaseTextRecent} target="_blank">
                                    <img src = {HelpQuestion}  style={{ height: "11px",marginLeft:"2%"}}/>
                                </a> */}
                                <InfluenceHelpIcon href={helpArticleLinks.faq.notification.purchaseTextRecent} fontSize="tiny"/>
                            </Typography>
                        </Grid>
                        <Grid item md={9}>
                            <InputBox
                                name="orderText"
                                dispatcher={setRecentSettings}
                                value={orderText}
                                placeholder="Purchased "
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} style={{paddingLeft: '0'}}>
                    <Grid container alignItems='center'>
                        <Grid item md={1}>
                            <Checkbox 
                                checked={isDisplayPurchase}
                                name='isDisplayPurchase'
                                onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, setRecentSettings)}
                            />
                        </Grid>
                        <Grid item md={6}style={{paddingLeft: '0'}}>
                            <Typography variant="body2">Enable product purchase notifications</Typography>
                        </Grid>
                        <Grid item md={1}>
                            <Checkbox 
                                checked={isDisplaySignup}
                                name='isDisplaySignup'
                                onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, setRecentSettings)}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <Typography variant="body2">
                                Enable signup notifications
                                {/* <a href="https://support.useinfluence.co/article/1869" target="_blank">
                                <img src = {HelpQuestion}  style={{ height: "11px",marginLeft:"2%"}}/>
                                </a> */}
                                <InfluenceHelpIcon href="https://support.useinfluence.co/article/1869" fontSize="tiny"/>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={6}>
                            <Typography variant="body2">
                                Enter number of conversions to display
                            </Typography>
                        </Grid>
                        <Grid item md={6}>
                            <InputBox
                                name="recentNumber"
                                dispatcher={setRecentSettings}
                                value={recentNumber}
                                onChange={handlePanelStyleChange}
                                type={"number"}
                                placeholder="Conversions"
                               
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item md={6}>
                            <Typography variant="body2">
                                Display conversions from last

                                
                            </Typography>
                        </Grid>
                        <Grid item md={3} style={{paddingLeft: '0px'}}>
                            <InputBox
                                name="recentConv"
                                dispatcher={setRecentSettings}
                                value={recentConv}
                                onChange={handlePanelStyleChange}
                                type={"number"}
                               
                            />
                        </Grid>
                        <Grid item md={3}>
                            <ReactSelect 
                               
                                width='120px' 
                                height='35px' 
                                value={selectLastDisplayConversation}
                                name="selectLastDisplayConversation"
                                onChange={handlePanelStyleChange}
                                dispatcher={setRecentSettings}
                                options= {[
                                    {label:"days", value: 'days'},
                                    {label:"hours", value:'hours'},
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} style={{paddingLeft: '0'}}>
                <Grid container alignItems="center">
                    <Grid item md={1}>
                        <Checkbox 
                            checked={captureAllData}
                            name='captureAllData'
                            onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, setRecentSettings)}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Typography variant="body2">
                            Capture All Data
                            {/* <a href={helpArticleLinks.faq.notification.purchaseTextRecent} target="_blank">
                                <img src = {HelpQuestion}  style={{ height: "11px",marginLeft:"2%",marginTop:'-1%'}}/>
                                </a> */}
                            <InfluenceHelpIcon href={helpArticleLinks.faq.notification.purchaseTextRecent} fontSize="tiny"/>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
                <Grid item md={12} style={{paddingLeft: '0'}}>
                    <Grid container alignItems="center">
                        <Grid item md={1}>
                            <Checkbox 
                                checked={isHideLastname}
                                name='isHideLastname'
                                onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, setRecentSettings)}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <Typography variant="body2">
                                Display only first name
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} style={{paddingLeft: '0'}}>
                    <Grid container alignItems="center">
                        <Grid item md={1}>
                            <Checkbox 
                                checked={hideAnonymousConversion}
                                name='hideAnonymousConversion'
                                onChange={(e) => handlePanelStyleChange(e.target.name, e.target.checked, setRecentSettings)}
                            />
                        </Grid>
                        <Grid item md={5}>
                            <Typography variant="body2">
                                Hide anonymous conversions
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Box display='flex' justifyContent='space-between'>
                        <Box>
                            <Typography variant="body2">
                                Extra Text
                            </Typography>
                            <InputBox
                                name="extraText"
                                dispatcher={setRecentSettings}
                                value={extraText}
                                onChange={handleInputChange}
                            />
                        </Box>
                        <Box style={{padding:'0 10px'}}>
                            <Typography variant="body2">
                                Days
                            </Typography>
                            <InputBox
                                name="daysAgo"
                                dispatcher={setRecentSettings}
                                value={daysAgo}
                                onChange={handleInputChange}
                            />
                        </Box>
                        <Box style={{paddingRight:'10px'}}>
                            <Typography variant="body2">
                                Hours
                            </Typography>
                            <InputBox
                                name="hoursAgo"
                                dispatcher={setRecentSettings}
                                value={hoursAgo}
                                onChange={handleInputChange}
                            />
                        </Box>
                        <Box>
                            <Typography variant="body2">
                                Minutes
                            </Typography>
                            <InputBox
                                name="minuteAgo"
                                dispatcher={setRecentSettings}
                                value={minuteAgo}
                                onChange={handleInputChange}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        input: {
            height: '35px',
            //width:'120px',
            backgroundColor: '#fff',
            marginTop: '4%',
            border: ' 1px solid hsl(0,0%,80%)',
            borderRadius: '4px',
            padding: '0% 3%',
            width:'100%'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            paddingBottom: '2%'
        },
    })
)

export default memo(FeaturesBox);