import React, { useState, useEffect} from 'react'
import { useSelector } from 'react-redux';
import { Box, makeStyles, createStyles, Typography, Button } from '@material-ui/core'
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { checkWhitelable } from "../../../../services/permissions"
import copy from 'copy-to-clipboard';




const CopyPixelCode = (props) =>{
	const classes= useStyles()
	const userdetails = useSelector(state => (state.getIn(['auth', 'user'])))
	// const { campaignInfo } = props
    const [openToaster, setOpenToaster] = useState(false)
    const [pixelLink, setPixelLink] = useState('')


    useEffect(()=>{
		checkWhitelable()?
			setPixelLink(`<script src="https://cdn.insidescript.com/static/social-proof-analytics.js?trackingId=${userdetails.trackingId ? userdetails.trackingId : 'XXXXXXXX'}" async></script>`):
			setPixelLink(`<script src="https://cdn.useinfluence.co/static/influence-analytics.js?trackingId=${userdetails.trackingId ? userdetails.trackingId: 'XXXXXXXX'}" async></script>`) 
    },[userdetails])


    const copybtnHandler = () =>{
		copy(pixelLink)
		setOpenToaster(true)
	}
	
	const mailContent = `mailto:?subject=Influence integration  ${props.appName ? 'with ' + props.appName : ''}&body=Hi there,%0D%0A %0D%0A %0D%0APlease add the Influence snippet to our website, below is the installation article:-%0D%0A${props.helpLink ? props.helpLink: 'https://support.useinfluence.co/'}%0D%0A%0D%0A%0D%0APixel:- %0D%0A${pixelLink}`


	
	return(
		<Box className={props.containerStyle}>
			<Box className={classes.topContainer}>

				<Typography
					style={{
						color: '#707070',
						fontSize: '12px',
                    }}
                    className={props.headingStyle}
				>
					Pixel Code
				</Typography>

                

				<Snackbar 
					open={openToaster}
					autoHideDuration={3000} 
					onClose={()=>setOpenToaster(false)}
					anchorOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
				>
					<Alert variant="filled" style={{background:'black'}}>
						<div style={{display:'flex',width:'auto'}}>
							<div style={{fontSize:'11x',paddingLeft:'6px',fontWeight:'400'}}>Pixel Code Copied to Clipboard</div>
						</div>
					</Alert>
				</Snackbar>

			</Box>

			<Box className={classes.linkContainer}>
				<Typography className={classes.pixelText}>
					{pixelLink}
				</Typography>
			</Box>

            <Box className={classes.topContainer} style={{marginTop:'1rem'}}>
                <Typography> </Typography>
                <Box>
                    <Button  variant='contained' onClick={copybtnHandler} style={{marginRight:'8px'}}>
                        Copy Pixel
                    </Button>
                    <Button component="a" href={mailContent} variant='outlined' >
                        Send To Developer
                    </Button>
                </Box>
            </Box>
			
		</Box>
	)
}


const useStyles = makeStyles(() =>
	createStyles({
		topContainer:{
			display: 'flex',
			justifyContent: 'space-between',
			marginBottom: '8px'
		},
		copyBtn:{
			fontSize: '12px !important',
			borderRadius: '4px',
			color: '#fcfdff',
			width: '121px',
			height:'32px',
			fontWeight:'400',
			marginRight: '0 !important'
		},
		linkContainer:{
			backgroundColor: '#e6e6e6',
			padding: '15px',
			borderRadius: '4px',
			marginTop: '1%',
			boxShadow: '0px 0px 4px #aaaaaa',
			border:'1px dotted #707070'
		},
		pixelText:{
			fontFamily: 'Poppins',
			fontSize: '12px',
			color: '#2e364b',
			margin: '0 !important',
			letterSpacing: '-0.345px'
		}
	})
)

export default CopyPixelCode
