import React , { useState, memo } from 'react'
import { Box, Grid, Typography, Popover, makeStyles, createStyles } from '@material-ui/core'
import { SketchPicker } from 'react-color'
import SlidingBar from '../../../../Globals/GlobalComponents/SlidingBar';




const ApperanceBox = (props) =>{
	
	const { 
        handlePanelStyleChange, 
        setLiveSettings,
        radius,
        backgroundColor,
        iconBGColor

    } = props
    const [bgColorPopper, setBgColorPopper] = useState(false);
    const [iconColorPopper, setIconColorPopper] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
	const [bgColorValue, setBgColorValue] = useState('rgb(0,0,0,0)')
    const [iconColorValue, setIconColorValue] = useState('rgb(0,0,0,0)')
    const classes = useStyles()



    const handleBgColorPopper = (event) => {
        if(bgColorPopper === false) {
            setBgColorPopper(true);
            setAnchorEl(event.currentTarget)

        }else {
            setBgColorPopper(false)
            setAnchorEl(null)
        }
    }

    const handleIconColorPopper = (event) => {
        if(iconColorPopper === false) {
            setIconColorPopper(true);
            setAnchorEl(event.currentTarget)
        }else {
            setIconColorPopper(false)
            setAnchorEl(null)
        }
    }


    const rgbSelector = (obj, stateName) =>{
        stateName(`rgba(${obj.r},${obj.g},${obj.b},${obj.a})`)
    }

	return(
		<Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container>
                <Grid item md={12}>
                    <Typography className={classes.boxTitle}>Appearance</Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={3}>
                            <Typography variant="body2">Radius</Typography>
                        </Grid>
                        <Grid item md={9}>
                            <SlidingBar value={radius} name="radius" onChange={handlePanelStyleChange} dispatcher={setLiveSettings} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems='center'>
                        <Grid item md={3}>
                            <Typography variant="body2">Background Color</Typography>
                        </Grid>
                        <Grid item md={3}>
                            <div 
                            className={bgColorPopper ? classes.colorIconActive : classes.colorIcon} 
                            onClick={handleBgColorPopper} 
                            style={{backgroundColor: `rgb(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b})`}}></div>
                            <Popover
                                open={bgColorPopper} 
                                onClose={handleBgColorPopper} 
                                anchorOrigin={{vertical: 'bottom',horizontal: 'center',}} 
                                transformOrigin={{vertical: 'top',horizontal: 'right',}}
                                anchorEl={anchorEl}
                            >
                                <SketchPicker 
                                    color={backgroundColor} 
                                    onChange={(e) => {
                                        handlePanelStyleChange("backgroundColor",e.rgb,setLiveSettings)
                                        rgbSelector(e.rgb, setBgColorValue)
                                    }} 
                                />
                            </Popover>
                        </Grid>

                        <Grid item md={2}>
                            <Typography variant="body2">Icon Color</Typography>
                        </Grid>
                        <Grid item md={3}>
                            <div 
                                className={iconColorPopper ? classes.colorIconActive : classes.colorIcon} 
                                onClick={handleIconColorPopper} 
                                style={{backgroundColor: `rgb(${iconBGColor.r}, ${iconBGColor.g}, ${iconBGColor.b})`}}></div>
                            <Popover
                                open={iconColorPopper} 
                                onClose={handleIconColorPopper} 
                                anchorOrigin={{vertical: 'bottom',horizontal: 'center',}} 
                                transformOrigin={{vertical: 'top',horizontal: 'right',}}
                                anchorEl={anchorEl}
                            >
                                <SketchPicker 
                                    color={iconBGColor} 
                                    onChange={(e) => {
                                        handlePanelStyleChange("iconBGColor",e.rgb,setLiveSettings)
                                        rgbSelector(e.rgb, setIconColorValue)
                                    }
                                }/>
                            </Popover>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
	)
}

const useStyles = makeStyles(theme =>
	createStyles({
		colorIcon: {
            width: '35px',
            height: '35px',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        colorIconActive: {
            width: '35px',
            height: '35px',
            border: '0.5px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            paddingBottom: '2%'
        },
	})

)




export default memo(ApperanceBox);
