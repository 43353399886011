export const updateItemList = (list, item, action) => {
    list = list || [];
    let newList = list.slice();
    let itemIndex;
    if (action === 'UPDATE') {
        itemIndex = newList.findIndex(listItem => item.id === listItem.id);
        if (itemIndex !== -1)
            newList.splice(itemIndex, 1, item);
        return newList;
    } else if (action === 'ADD') {
        newList.unshift(item);
        return newList;
    } else if (action === 'DELETE') {
        return newList.filter(listItem => item.id !== listItem.id);
    }
    else if (action === 'PUT') {
        itemIndex = newList.findIndex(listItem => item.id === listItem.id);
        if (itemIndex !== -1)
            newList.splice(itemIndex, 1, item);
        else {
            newList.push(item);
        }
        return newList;
    }
    return newList;
  }



export const getProperty =(obj, path, def)=> {
	var fullPath = path
		.replace(/\[/g, '.')
		.replace(/]/g, '')
		.split('.')
		.filter(Boolean);

	return fullPath.every(everyFunc) ? obj : def;

	function everyFunc(step) {
		return !(step && (obj = obj[step]) === undefined);
	}
}
