import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, CircularProgress, Button, makeStyles, createStyles } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { getCookieProviders } from "../actions";
import LoadingButton from '../../../Globals/GlobalComponents/LoadingButton'
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const CookieStep2 = (props) => {

    const {handleNext,handleBack} = props
    const classes = useStyles()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)

    const providers = useSelector(state=>(state.getIn(['cookieCampaignCreationProcess', 'rawCookieProviders']))) || []
    const currentCampaign = useSelector(state =>(state.getIn(['cookieCampaigns','openedCookieCampaign'])))


    // const websiteURL = 'https://test2109.herokuapp.com/'


    const handleGetProviders = () =>{
        setLoading(true)
        dispatch(getCookieProviders(`https://${currentCampaign.websiteUrl}`, setLoading))
    }


    useEffect(() => {
        handleGetProviders()
        return () => {
            setLoading(false)
        }
    }, [])

    useEffect(()=>{
        console.log('providers----', providers)
        console.log('providers.length----', providers.length)
        console.log('providers.length ==0----', providers.length ==0)
    },[providers])

    

    return (
        <Box>
            <Typography variant="h5" textAlign="center">
                Scanning
            </Typography>
            <Typography style={{marginTop:'2rem'}}>
                Please wait, we're scanning your website and collecting data.
            </Typography>

            <Typography>
                It'll take some time!
            </Typography>

            <Box style={{display:'flex', justifyContent:'center', margin:'2rem auto'}}>
                {
                    loading ?
                        <CircularProgress size={19}/>:
                            providers.length == 0 ?
                            <Typography className={classes.noProviderLine}><CloseIcon size={19}/> No provider found</Typography>:
                            <CheckCircleRoundedIcon style={{fill:'#1a3d57'}} size={20}/>
                }
            </Box>

            <Box style={{display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
                {
                    loading ?
                    <LoadingButton className={classes.actionBtns} onClick={handleNext} loading={loading} disabled={loading} variant="contained">
                        Scanning
                    </LoadingButton>:
                    <Button className={classes.actionBtns} onClick={handleNext} disabled={loading || providers.length ==0} variant="contained">
                       Proceed <ArrowRightAltIcon/>
                    </Button>
                }
                
                <Button variant="outlined" onClick={handleBack} className={classes.actionBtns}>
                    <KeyboardBackspaceIcon/> Previous
                </Button>
            </Box>
        </Box>
    )
}



const useStyles = makeStyles(()=>
    createStyles({
        actionBtns:{
            transition:'0.3s',
            margin:'1rem', 
            width:"100px",
            '& svg':{
                fontSize:'16px'
            },
            '&:hover':{
                '& svg':{
                    fontSize:'18px'
                }
            }
        },
        noProviderLine:{
            display:'flex',
            alignItems:'center',
            color:'red',
            '& svg':{
                marginRight:'8px'
            }
        }
    })
)



export default CookieStep2
