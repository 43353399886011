import { fromJS } from 'immutable';

const action = name => `/netapp/${name}`;

export const FETCH = action('FETCH');
export const FETCH_SUCCESS = action('FETCH_SUCCESS');
export const CREATE = action('CREATE');
export const CREATE_SUCCESS = action('CREATE_SUCCESS');
export const REMOVE = action('REMOVE');
export const POP_NETAPP = action('POP_NETAPP');

export const getNetApp = (campaignId) => ({ type: FETCH, campaignId });
export const addNetApp = (netapp) => ({ type: CREATE, netapp });
export const deleteNetApp = (id, index) => ({ type: REMOVE, id, index });
export const succeessNetApp = (data) => ({ type: FETCH_SUCCESS, data });
export const popNetApp = (index) => ({ type: POP_NETAPP, index });

const initialState = fromJS({});
const netapp = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return state.set('netapp', action.data);  
    case POP_NETAPP:
      return state.set('netapp', state.get('netapp').slice(0, action.index).concat(state.get('netapp').slice(action.index+1)));
    default:
      return state;
  }
};

export default netapp;
