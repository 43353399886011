import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, makeStyles, createStyles, Typography, CircularProgress } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { checkWhitelable } from '../../services/permissions'
import { pixelVerify, getPixelData } from './Action'
import PixelCode from './PixelCode'
import PixelPageTable from './PixelPageTable'
import PixelPageSideBar from './PixelPageSideBar'
import clsx from 'clsx'


const PixelPage = () =>{
  const classes = useStyles({ whitelable: checkWhitelable() })
  const dispatch = useDispatch()
  const campaignInfo = useSelector((state) => state.getIn(['CampaignInfo', 'campaignInfo']))
  const pixelStatusObject = useSelector((state) => state.getIn(['PixelPageDetails', 'PixelVerify']))
  const pixelVerif = useSelector((state) => state.getIn(['PixelPageDetails', 'PixelDetails']))
  const [loading, setLoading] = useState(true)
  const [pixelStatus, setPixelStatus] = useState(false)
  const [pixelStatus1, setPixelStatus1] = useState(false)
  const [pixelStatus2, setPixelStatus2] = useState(false)

  const pixelVerification = () => {
    dispatch(pixelVerify(setLoading))
  }

  useEffect(() => {
    // !pixelVerif ? pixelVerification() : setLoading(false)
    dispatch(getPixelData(setLoading))
  }, [])

  useEffect(() => {
    if (pixelVerif === undefined) {
      setPixelStatus(false)
    } else {
      let arr = []
      pixelVerif.map((item) => {
        arr.push(item.pixelStatus)
      })
      if (arr.includes(true)) {
        setPixelStatus(true)
      }
    }
  }, [pixelVerif, campaignInfo])

  const refreshHandler = () => {
    dispatch(getPixelData(setLoading))
  }

  // useEffect(() => {
  // 	setPixelStatus(pixelStatus1 || pixelStatus2)
  // }, [pixelStatus1, pixelStatus2])

  // useEffect(() => {
  // 	pixelStatusFinder()
  // 	if (pixelStatusObject === undefined) {
  // 		setPixelStatus1(false)
  // 	} else {
  // 		pixelStatusObject.map((item) => {
  // 			if (item.status) {
  // 				setPixelStatus1(true)
  // 			}
  // 		})
  // 	}
  // }, [])

  const pixelStatusFinder = () => {
    if (campaignInfo) {
      campaignInfo.map((campaign) => {
        if (campaign.pixelStatus === true) {
          setPixelStatus2(true)
        }
      })
    }
  }

  return (
    <Box>
      <div
        className={clsx({
          [classes.pixelStatusContainer]: true,
          loading: loading,
          active: pixelStatus,
          inActive: !pixelStatus && !loading,
        })}
      >
        {loading ? (
          <CircularProgress style={{ color: '#fff' }} size={19} />
        ) : pixelStatus ? (
          <Typography>
            <CheckCircleIcon fontSize="small" /> Congrats! Your pixel is verified now!
          </Typography>
        ) : (
          <Typography>
            <CancelIcon fontSize="small" /> Pixel is not sending data!
          </Typography>
        )}
      </div>

      <Box className={classes.headingContainer}>
        <Typography variant="h2">Install your pixel!</Typography>
        <Typography>
          This code goes between your website's "head" tag. After installing the pixel, create a
          campaign to verify it.
        </Typography>
      </Box>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box style={{ width: checkWhitelable() ? '100%' : '80%', paddingRight: '20px' }}>
          <Box className={classes.pixelCodeContainer}>
            <PixelCode campaignInfo={campaignInfo} />
          </Box>

          <Box style={{ marginTop: '5%' }}>
            <PixelPageTable refreshActionHandler={refreshHandler} campaignInfo={campaignInfo} />
          </Box>
        </Box>

        {!checkWhitelable() && (
          <React.Fragment>
            <div className={classes.border}></div>
            <Box>
              <PixelPageSideBar />
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Box>
  )
}


const useStyles = makeStyles(() =>
	createStyles({
		pixelStatusContainer:{
			borderRadius:'4px',
			height:'43px',
			color:'#fff',
			display:'flex',
			alignItems:'center',
			justifyContent:'center',
			'&.active':{
				backgroundColor: '#81d8a2',
			},
			'&.inActive':{
				backgroundColor: '#e74040cc',
			},
			'&.loading':{
				backgroundColor: '#1c488db3',
			},
			'&>p':{
				fontSize: '15px',
				fontFamily: 'Poppins',
				'&>svg':{
					marginRight:'5px',
					verticalAlign:'middle'
				}
			}
		},
		pixelCodeContainer:{
			marginTop: '3%',
			fontFamily: 'Poppins !important'
		},
		border:{
			borderRight: '1px solid #c6c6c6'
		},
		headingContainer:{
			marginTop:'3%',
			'&>h2':{
				fontFamily: 'Poppins',
				fontSize: '28px',
				fontWeight: '500',
				color: '#000',
			},
			'&>p':{
				fontFamily: 'Poppins',
				marginTop: '8px',
				fontSize: '14px',
				fontWeight: 'normal',
			}
		}

	})
)

export default PixelPage
