import React from 'react'
import { Box, makeStyles, createStyles, CircularProgress } from '@material-ui/core'
import {
  DialogActions,
  DialogBox,
  DialogButton,
  DialogContent,
  DialogTitle,
} from 'Globals/GlobalComponents/DialogBox'

const DeleteConfirmationModel = (props) => {
  const { handleDeleteCampaign, handleDialog, isModalOpen, loadingDelete } = props
  const classes = useStyles()

  return (
    <DialogBox onClose={handleDialog} open={isModalOpen}>
      <DialogTitle onClose={handleDialog}>Alert!</DialogTitle>
      <DialogContent>Are you sure you want to delete this policy campaign?</DialogContent>
      <DialogActions>
        <DialogButton onClick={handleDialog} actionType={'secondary'}>
          No
        </DialogButton>
        <DialogButton
          disabled={loadingDelete}
          actionType={'primary'}
          onClick={handleDeleteCampaign}
        >
          Yes
          {loadingDelete && <CircularProgress size={19} className={classes.buttonProgress} />}
        </DialogButton>
      </DialogActions>
    </DialogBox>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonProgress: {
      color: '#fff',
      position: 'absolute',
      // top: '55%',
      // left: '50%',
      // marginTop: -12,
      // marginLeft: -12,
    },
  })
)

export default DeleteConfirmationModel
