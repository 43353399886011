import { fromJS } from 'immutable';

const action = name => `/abhisi/${name}`;

export const FETCH = action('FETCH');
export const FETCH_SUCCESS = action('FETCH_SUCCESS');
export const CREATE = action('CREATE');
export const CREATE_SUCCESS = action('CREATE_SUCCESS');
export const REMOVE = action('REMOVE');
export const POP_ABHISI = action('POP_ABHISI');
export const UPDATE = action('UPDATE');

export const getAbhisi = (campaignId) => ({ type: FETCH, campaignId });
export const addAbhisi = (abhisi) => ({ type: CREATE, abhisi });
export const deleteAbhisi = (id, index) => ({ type: REMOVE, id, index });
export const succeessAbhisi = (data) => ({ type: FETCH_SUCCESS, data });
export const popAbhisi = (index) => ({ type: POP_ABHISI, index });
export const updateAbhisi = (abhisi) => ({ type: UPDATE, abhisi });
const initialState = fromJS({});
const abhisi = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return state.set('abhisi', action.data);
    case POP_ABHISI:
      return state.set('abhisi', state.get('abhisi').slice(0, action.index).concat(state.get('abhisi').slice(action.index + 1)));
    default:
      return state;
  }
};

export default abhisi;
