import React from 'react'
import { Box, Typography } from '@material-ui/core'




const CookieListContainer = () =>{
    return(
        <Box>
            <Typography variant="h3">CookieListContainer</Typography>
        </Box>
    )
}

export default CookieListContainer