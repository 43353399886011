import { GET_NOTIFICATION_SETTINGS } from "./action";
import { fromJS } from 'immutable';
import { GET_NOTIFICATION_TYPES, UPDATE_NOTIFICATION_SETTINGS,TOGGLE_NOTIFICATION_STATUS, GET_NOTIFICATION_CONFIGURATION } from "../action";
import { updateItemList } from "../../../utilities/utilities";


const initialState = fromJS({});

export const NotificationSettings = (state = initialState, action) => {
    switch(action.type){
        case GET_NOTIFICATION_SETTINGS:
            return state.set('notificationSettings', action.payload)
        case GET_NOTIFICATION_TYPES :
            return state.set('notificationType', action.payload)
        case TOGGLE_NOTIFICATION_STATUS : {
            const obj = state.get('notificationConfiguration').find(item => item.notificationType._id === action.payload.id)
            return state.set('notificationConfiguration', updateItemList(state.get('notificationConfiguration'),{...obj,activity : action.payload.activity},'UPDATE'))
        }
        case GET_NOTIFICATION_CONFIGURATION:
            return state.set('notificationConfiguration', action.payload);
        case UPDATE_NOTIFICATION_SETTINGS : { 
            const abc = updateItemList(state.get('notificationConfiguration'),action.payload,'UPDATE');
            return state.set('notificationConfiguration', abc)
            
        }
        default:
            return state;
    } 
}