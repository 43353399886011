import { fromJS } from 'immutable';

const action = name => `/autoklose/${name}`;

export const FETCH = action('FETCH');
export const FETCH_SUCCESS = action('FETCH_SUCCESS');
export const CREATE = action('CREATE');
export const CREATE_SUCCESS = action('CREATE_SUCCESS');
export const REMOVE = action('REMOVE');
export const POP_AUTOKLOSE = action('POP_AUTOKLOSE');

export const getAutoKlose = (campaignId) => ({ type: FETCH, campaignId });
export const addAutoKlose = (autoklose) => ({ type: CREATE, autoklose });
export const deleteAutoKlose = (id, index) => ({ type: REMOVE, id, index });
export const succeessAutoKlose = (data) => ({ type: FETCH_SUCCESS, data });
export const popAutoKlose = (index) => ({ type: POP_AUTOKLOSE, index });
const initialState = fromJS({});
const autoklose = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return state.set('autoklose', action.data);  
    case POP_AUTOKLOSE:
      return state.set('autoklose', state.get('autoklose').slice(0, action.index).concat(state.get('autoklose').slice(action.index+1)));
    default:
      return state;
  }
};

export default autoklose;
