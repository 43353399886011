import { call, put, fork, takeLatest } from 'redux-saga/effects';
import * as api from 'services/api';
import * as actions from 'ducks/integrations';
import * as actions_webhook from 'ducks/webhooks';
import { succeessMailChimp, popMailChimp, 
  succeessMailerLite, popMailerLite, 
  succeessSendInBlue, popSendInBlue, 
  succeessActiveCampaign, popActiveCampaign,
  succeessConvertKit, popConvertKit,succeessInfusionSoft, popInfusionSoft,
  succeessShopify,
  succeessThrive,
  succeessIntercom
} from 'ducks/integrations'; 
import { load, loaded } from 'ducks/loading';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

const errorMessge = 'Please try again or refresh!';

const toastConfig = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 2000,
  className: 'toast-style'
};

function* addMailChimp(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, `mailchimpapi/addlist/${action.campaign.trackingId}?list=${action.id}&campaignId=${action.campaign._id}`);
    if(!res.error) {    
      yield put(succeessMailChimp(res));
      yield toast.error('api key added', toastConfig);
      yield put(actions_webhook.fetchAppsConnection(action.campaign._id));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* getMailChimp(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `mailchimpapi?campaignId=${action.campaignId}`);
    if(!res.error) {    
      yield put(succeessMailChimp(res));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* deleteMailChimp(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `mailchimpapi/${action.id}`);
    if(!res.error) {    
      yield put(popMailChimp(action.index));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* addMailerLite(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, `mailerliteapi/addlist/${action.campaign.trackingId}`, { apiKey: action.id, campaignId: action.campaign._id, groupId: action.groupId });
    if(!res.error) {    
      yield put(succeessMailerLite(res));
      yield toast.error('api key added', toastConfig);
      yield put(actions_webhook.fetchAppsConnection(action.campaign._id));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* getMailerLite(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `mailerliteapi?campaignId=${action.campaignId}`);
    if(!res.error) {    
      yield put(succeessMailerLite(res));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* deleteMailerLite(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `mailerliteapi/${action.id}`);
    if(!res.error) {    
      yield put(popMailerLite(action.index));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* deleteInfusionSoft(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `infusionsoft/${action.id}`);
    if(!res.error) {    
      yield put(popInfusionSoft(action.index));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* addActiveCampaign(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, `activecampaignapi/addlist/${action.campaign.trackingId}`, {list:action.listId, apiKey: action.id, campaignId: action.campaign._id });
    if(!res.error) {    
      yield put(succeessActiveCampaign(res));
      yield toast.error('api key added', toastConfig);
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* getActiveCampaign(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `activecampaignapi?campaignId=${action.campaignId}`);
    if(!res.error) {    
      yield put(succeessActiveCampaign(res));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* deleteActiveCampaign(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `activecampaignapi/${action.id}`);
    if(!res.error) {    
      yield put(popActiveCampaign(action.index));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* addConvertKit(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, `convertkitapi/addlist/${action.campaign.trackingId}`, {form_id:action.formId, apiSecret: action.id, campaignId: action.campaign._id });
    if(!res.error) {    
      yield put(succeessConvertKit(res));
      yield toast.error('api key added', toastConfig);
      yield put(actions_webhook.fetchAppsConnection(action.campaign._id));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* getConvertKit(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `convertkitapi?campaignId=${action.campaignId}`);
    if(!res.error) {    
      yield put(succeessConvertKit(res));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* getInfusionSoft(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `infusionsoft?trackingId=${action.trackingId}`);
    if(!res.error) {    
      yield put(succeessInfusionSoft(res));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* deleteConvertKit(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `convertkitapi/${action.id}`);
    if(!res.error) {    
      yield put(popConvertKit(action.index));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* getSendInBlue(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `sendinblueapi?campaignId=${action.campaignId}`);
    if(!res.error) {    
      yield put(succeessSendInBlue(res));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}
function* addSendInBlue(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, `sendinblueapi/addlist/${action.campaign.trackingId}`, { apiKey: action.id, campaignId: action.campaign._id });
    if(!res.error) {    
      yield put(succeessSendInBlue(res));
      yield toast.error('api key added', toastConfig);
      yield put(actions_webhook.fetchAppsConnection(action.campaign._id));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}
function* deleteSendInBlue(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `sendinblueapi/${action.id}`);
    if(!res.error) {    
      yield put(popSendInBlue(action.index));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* getShopify(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `shopify?campaignId=${action.campaignId}`);
    if(!res.error) {    
      yield put(succeessShopify(res));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}
function* updateShopifyWebhook(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'shopify/updateWebhook',action);
    if(!res.error) {    
      yield put(succeessShopify(res));
      yield toast.error('Shopify webhook updated', toastConfig);
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* getThriveCart(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `thrivecart?campaignId=${action.campaignId}`);
    if(!res.error) {   
      yield put(succeessThrive(res[0]));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}
function* addThriveWebhook(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'thrivecart',action.data);
    if(!res.error) { 
      yield put(succeessThrive(res));
      yield toast.error('Thrive webhook created', toastConfig);
      yield put(actions_webhook.fetchAppsConnection(action.data.campaignId));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}
function* updateThriveCartWebhook(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'thrivecart/updateWebhook',action);
    if(!res.error) {    
      yield put(succeessShopify(res));
      yield toast.error('Thrive webhook updated', toastConfig);
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}
function* deleteThriveCart(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `thrivecart/${action.id}`);
    if(!res.error) {    
      yield put(actions.getThriveCart(res.campaignId));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* addIntercom(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'intercom', {appId:action.id, campaignId: action.campaign._id, trackingId: action.campaign.trackingId, websiteUrl: action.campaign.websiteUrl });
    if(!res.error) {    
      yield put(succeessIntercom(res));
      yield toast.error('App ID added', toastConfig);
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* getIntercom(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `intercom?campaignId=${action.campaignId}`);
    if(!res.error) {    
      yield put(succeessIntercom(res?res[0]:null));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}
function* deleteIntercom(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `intercom/${action.id}`);
    if(!res.error) {    
      yield put(succeessIntercom(null));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}


export function* watchAddMailChimp() {
  yield takeLatest(actions.ADD_MAIL_CHIMP, addMailChimp);
}

export function* watchGetMailChimp() {
  yield takeLatest(actions.GET_MAIL_CHIMP, getMailChimp);
}

export function* watchDeleteMailChimp() {
  yield takeLatest(actions.DELETE_MAIL_CHIMP, deleteMailChimp);
}


export function* watchAddMailerLite() {
  yield takeLatest(actions.ADD_MAILER_LITE, addMailerLite);
}

export function* watchGetMailerLite() {
  yield takeLatest(actions.GET_MAILER_LITE, getMailerLite);
}

export function* watchDeleteMailerLite() {
  yield takeLatest(actions.DELETE_MAILER_LITE, deleteMailerLite);
}


export function* watchAddActiveCampaign() {
  yield takeLatest(actions.ADD_ACTIVE_CAMPAIGN, addActiveCampaign);
}

export function* watchGetActiveCampaign() {
  yield takeLatest(actions.GET_ACTIVE_CAMPAIGN, getActiveCampaign);
}

export function* watchDeleteActiveCampaign() {
  yield takeLatest(actions.DELETE_ACTIVE_CAMPAIGN, deleteActiveCampaign);
}


export function* watchAddConvertKit() {
  yield takeLatest(actions.ADD_CONVERT_KIT, addConvertKit);
}

export function* watchGetConvertKit() {
  yield takeLatest(actions.GET_CONVERT_KIT, getConvertKit);
}
export function* watchGetInfusionSoft() {
  yield takeLatest(actions.GET_INFUSION_SOFT, getInfusionSoft);
}

export function* watchAddSendInBlue() {
  yield takeLatest(actions.ADD_SEND_IN_BLUE, addSendInBlue);
}
export function* watchGetSendInBlue() {
  yield takeLatest(actions.GET_SEND_IN_BLUE, getSendInBlue);
}
export function* watchDeleteSendInBlue() {
  yield takeLatest(actions.DELETE_SEND_IN_BLUE, deleteSendInBlue);
}

export function* watchDeleteConvertKit() {
  yield takeLatest(actions.DELETE_CONVERT_KIT, deleteConvertKit);
}
export function* watchDeleteInfusionSoft() {
  yield takeLatest(actions.DELETE_INFUSION_SOFT, deleteInfusionSoft);
}

export function* watchGetShopify() {
  yield takeLatest(actions.GET_SHOPIFY, getShopify);
}
export function* watchUpdateShopifyWebhook() {
  yield takeLatest(actions.UPDATE_SHOPIFY_WEBHOOK, updateShopifyWebhook);
}

export function* watchGetThriveCart() {
  yield takeLatest(actions.GET_THRIVE, getThriveCart);
}
export function* watchAddThriveWebhook() {
  yield takeLatest(actions.ADD_THRIVE_WEBHOOK, addThriveWebhook);
}
export function* watchUpdateThriveCartWebhook() {
  yield takeLatest(actions.UPDATE_THRIVE_WEBHOOK, updateThriveCartWebhook);
}
export function* watchDeleteThriveCart() {
  yield takeLatest(actions.DELETE_THRIVE, deleteThriveCart);
}

export function* watchAddIntercom() {
  yield takeLatest(actions.ADD_INTERCOM, addIntercom);
}
export function* watchGetIntercom() {
  yield takeLatest(actions.GET_INTERCOM, getIntercom);
}
export function* watchDeleteIntercom() {
  yield takeLatest(actions.DELETE_INTERCOM, deleteIntercom);
}


export default function* rootSaga() {
  yield [
    fork(watchAddMailChimp),
    fork(watchGetMailChimp),
    fork(watchDeleteMailChimp),

    fork(watchAddMailerLite),
    fork(watchGetMailerLite),
    fork(watchDeleteMailerLite),

    fork(watchAddActiveCampaign),
    fork(watchGetActiveCampaign),
    fork(watchDeleteActiveCampaign),

    fork(watchAddConvertKit),
    fork(watchGetConvertKit),
    fork(watchGetInfusionSoft),
    fork(watchDeleteConvertKit),
    fork(watchDeleteInfusionSoft),    
    
    fork(watchAddSendInBlue),
    fork(watchGetSendInBlue),
    fork(watchDeleteSendInBlue),

    fork(watchGetShopify),
    fork(watchUpdateShopifyWebhook),

    fork(watchGetThriveCart),
    fork(watchAddThriveWebhook),
    fork(watchUpdateThriveCartWebhook),
    fork(watchDeleteThriveCart),

    fork(watchAddIntercom),
    fork(watchGetIntercom),
    fork(watchDeleteIntercom)
  ];
}
