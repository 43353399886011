import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Row, Col, HelpBlock } from 'react-bootstrap/es';
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { base } from 'services/api';

class StripePaymentPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dashlyStatus: false
		}
	}

	state = {
		error:'',
		stripeToken: 'pk_live_bafSzfqWXcJtQ3WdguKE1sRg',
		name: '',
		email: '',
		loading:false
	};


	// customPlan = {
	// 	amount: 1000,
	// 	currency: "usd",
	// 	description: 10000,
	// 	free_quantity: 0,
	// 	id: "plan_H4qiFlrJRKpBPa",
	// 	interval: "month",
	// 	interval_count: 1,
	// 	invoice_name: "Enterprise Plan",
	// 	name: "Enterprise Plan",
	// 	object: "plan",
	// 	status: true,
	// 	teammateQouta: 2,
	// 	trial_period: 1
	// };

	// trm = {
	// 	"id": "plan_H4qiFlrJRKpBPa",
	// 	"object": "plan",
	// 	"active": true,
	// 	"aggregate_usage": null,
	// 	"amount": 1000,
	// 	"amount_decimal": "1000",
	// 	"billing_scheme": "per_unit",
	// 	"created": 1586602589,
	// 	"currency": "usd",
	// 	"interval": "month",
	// 	"interval_count": 1,
	// 	"livemode": false,
	// 	"metadata": {
	// 	},
	// 	"name": "Enterprise Plan",
	// 	"nickname": "testPlan",
	// 	"owning_merchant": "acct_1CEx7rCkWzi7OrXW",
	// 	"owning_merchant_info": "acct_1CEx7rCkWzi7OrXW",
	// 	"product": "prod_H31WAa3z63b5qo",
	// 	"statement_descriptor": "Influence Subscription",
	// 	"tiers": null,
	// 	"tiers_mode": null,
	// 	"transform_usage": null,
	// 	"trial_period_days": 1,
	// 	"usage_type": "licensed"
	// }
	// 
	callDashly = () =>{
		const {user, plan}=this.props
		window.dashly.track("Plan Subscribed", {
			email: user.email,
			name: "Testing1612",
			planName: plan.name,
			planAmount: (plan.amount)/100,
			id: user._id
		});
	}

	handleSubmit = async (e) => {
		e.preventDefault();
		this.setState({loading:true})

		if(!this.state.name){
			this.setState({error: 'Card holder name is empty.', loading:false})
			return
		}

		const {user, plan}=this.props

		let token = await this.props.stripe.createToken({ name: this.state.name, amount:0 })

		if(_.get(token,'error.message')){
			this.setState({error: _.get(token,'error.message'), loading:false})
			return
		}
		// console.log(paymentMethod);
			const paymentMethod = await this.props.stripe
		.createPaymentMethod({
			type: 'card',
			card: {token:token.token.id},
			billing_details: {name: this.state.name},
		})


		//make the call to the NodeJs Striper Server to create the charge

		await fetch(`${base}payment`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				user,
				plan,
				paymentMethod:paymentMethod.paymentMethod,
				token:token.token,
			})
		}).then(()=>{
				this.setState({loading:false, error:'Subscription created successfully.'})
				this.callDashly()
				this.props.history.push('/getting-started')
				window.location.reload();
		}).catch(() =>{
			this.setState({error:'Internal Server Error', loading:false})
		})


	};
	

	render() {
		const {plan}=this.props
		const {loading}=this.state
		return (
      <div className="credit-form-container">
        <input
          placeholder="Card Holder Name"
          name="card_holdername"
          onChange={(event) => this.setState({ name: event.target.value, error: '' })}
          className="card_holdername"
        />
        <form onSubmit={this.handleSubmit}>
          <CardElement />
          <Row className="m-2">
            {/* coupon section */}
            <Col md={6} className="mb-3 text-muted">
              <Row>
                <Col md={9} className="mb-3 text-muted pr-0 pl-1">
                  <input
                    name="coupon_code"
                    placeholder="Coupon Code"
                    type="text"
                    className="coupon_input"
                  />
                </Col>
                <Col md={3} className="pl-0 mb-3 text-muted">
                  <input
                    type="button"
                    className="couponBtn btn mb-0 btn-warning "
                    onClick={this.handleCheckCoupenAvailability}
                    value="Apply"
                  />
                </Col>
              </Row>
            </Col>

            {/* payment btn */}
            <Col md={6} className="mb-3 text-muted">
              <button
                disabled={!this.props.stripe || loading}
                type="submit"
                className="btn btn-primary col-md-12"
              >
                {' '}
                {loading ? (
                  <i
                    className="fa fa-spin fa-refresh spinner-icon"
                    style={{ fontSize: 26, color: '#fff' }}
                  ></i>
                ) : (
                  <React.Fragment>
                    Start Free Trial{' '}
                    <span style={{ fontSize: 12, fontWeight: 500 }}>
                      {' '}
                      (&#36;{' ' + plan.amount / 100})
                    </span>
                  </React.Fragment>
                )}
              </button>
            </Col>
          </Row>
        </form>
        {this.state.error && (
          <HelpBlock style={{ fontSize: 12 }}>
            <p className="error-text">{this.state.error}</p>
          </HelpBlock>
        )}

        {plan && (/\b(Lifetime)\b/m.test(plan.name) || plan.amount == 0) ? (
          ''
        ) : (
          <div className="row m-2 mt-3 co-pts">
            <div className="col-md-12 p-1">
              <i className="co-icon fa fa-check-square"></i>&nbsp;Your card will not be charged
              until the end of your 7-day trial.
            </div>
            <div className="col-md-12 p-1">
              <i className="co-icon fa fa-check-square"></i>&nbsp;Cancel any time or Pause your
              account.
            </div>
            <div className="col-md-12 p-1">
              <i className="co-icon fa fa-check-square"></i>&nbsp;We support you at every step of
              the process!
            </div>
          </div>
        )}
      </div>
    )
	}
}

export default injectStripe(withRouter(StripePaymentPage));
