import React, {useEffect, useState} from 'react';
import { Box, makeStyles, createStyles, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, IconButton, Typography } from '@material-ui/core';
import Moment from 'react-moment'
// import {trashAlt, TrashIcon} from '../../assets/assets'
import { deleteSignupData } from './action'
import { useDispatch } from 'react-redux';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {Trash} from '../../img'
const applefont = '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'



const SignupsList  = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {signupData, campaignAnalyticsData} = props
    const [openToaster,setOpenToaster] = useState(false);


    const dateFormatter = (date) => {
		return date.slice(0,10)
	}

    const deleteSignupHandler = (id, type, trackingId)=>{
        dispatch(deleteSignupData(id, type, trackingId, campaignAnalyticsData,setOpenToaster))

    }

    const handleClose = () =>{
        setOpenToaster(false);
    }
    return(
        <TableContainer style={{maxHeight:'650px',padding:"0%", boxShadow: '0px -1px 2px 0px rgba(198, 198, 198, 0.32)', marginTop: '2rem', marginRight: '5%', width: '95%'}} >
			<Table stickyHeader>

				<TableHead style={{borderBottom: '1px solid #e8e8e8', backgroundColor: '#fafbfc'}}>
					<TableRow>
						<TableCell className={classes.tableHead}>User</TableCell>
						<TableCell className={classes.tableHead}>Location</TableCell>
						<TableCell className={classes.tableHead}>Type</TableCell>
						<TableCell className={classes.tableHead}>Time</TableCell>
                        <TableCell className={classes.tableHead}>Action</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{
						signupData && signupData.length>0 ?
							signupData.map((data, index)=>(
								<TableRow className={classes.tableRow} key={index}>
									<TableCell className={classes.TableCellMain}>
										<span>{data._source.username}</span>
										<p className={classes.emailStyle}>{data._source.email}</p>
									</TableCell>
									<TableCell className={data._source.city && data._source.country ? classes.TableCell : classes.TableCell1}>{data._source.city && data._source.country ? `${data._source.city}, ${data._source.country}`: '--'}</TableCell>
									<TableCell className={classes.TableCell}>
										<span className={classes.signupPill}>
											signup
										</span>
									</TableCell>
									<TableCell className={classes.TableCell}><Moment format="MMM D, YYYY" date={dateFormatter(data._source.timestamp)}/></TableCell>
                                    {/*<TableCell className={classes.TableCell}>
                                        <TrashIcon width={14} height={14}/>
                                    </TableCell>
                                    <TableCell className={classes.TableCell}>
                                        <img src={trashAlt}/>
                                    </TableCell>*/}
                                    <TableCell className={classes.TableCell}>
                                        <IconButton className={classes.deletIconBtn} onClick={()=>{deleteSignupHandler(data._id, data._type, data._source.trackingId)}}>
                                           {/* <img src={Trash} style={{height:'13px'}}/> */}
                                           <Trash style={{height:'13px'}}/>
                                            <Snackbar 
                                                open={openToaster}
                                                autoHideDuration={5000} 
                                                key={campaignAnalyticsData._id}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}
                                            >
                                                <Alert variant="filled" style={{background:'black'}}>
                                                    <div style={{display:'flex',width:'auto'}}>
                                                        {/* <div><DoneAllRoundedIcon style={{color:'#00b300',fontSize:'19px'}}/></div> */}
                                                        <div style={{fontSize:'11x',paddingLeft:'6px',fontWeight:'400'}}>Deleted Successfully</div>
                                                    </div>
                                                </Alert>
                                            </Snackbar>
                                        </IconButton>
                                    </TableCell>
								</TableRow>
							)):
							<TableRow>
                                <Typography style={{paddingLeft:'5%',paddingBottom:'5%',paddingTop:'5%', fontSize:'12px',width:"100%",lineHeight:'0'}}>No Signup Available</Typography>
							</TableRow>
					}
				</TableBody>
			</Table>
        </TableContainer>
        
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
		tableHead: {
            color: 'rgba(9,30,66,.74)',
            fontWeight:'700',
            padding : '8px 16px',
            fontSize: '11px',
            textTransform: 'uppercase',
            fontFamily: `${applefont}`
        },
        tableRow: {
            borderTop: '1px solid rgba(9,30,66,.12)',
            transition: '0.2s',
            backgroundColor: '#fff',
            "&:hover": {
                backgroundColor: "#ebecf0"
            }
        },
        TableCell: {
            fontSize:'14px',
            textTransform:'capitalize',
            color:'rgba(9,30,66,.87)',
            letterSpacing:'normal',
            fontFamily: applefont,
            fontWeight: '400'
        },
        TableCell1: {
            fontSize:'14px !important',
            textTransform:'capitalize',
            color:'rgba(9,30,66,.87) !important',
            letterSpacing:'normal',
            fontFamily: applefont,
            fontWeight: '400',
            paddingLeft: '3%'
        },
        TableCellMain: {
            fontSize:'14px',
            textTransform:'capitalize',
            color:'rgba(9,30,66,.87)',
            letterSpacing:'normal',
            fontFamily: applefont,
            fontWeight: '500'
        },
        emailStyle:{
			fontSize:'11px',
            color:'rgba(9,30,66,.87)',
            letterSpacing:'normal',
            fontFamily: applefont,
            fontWeight: '400',
            textTransform: 'lowercase',
            margin: '0 !important'
        },
        signupPill:{
			backgroundColor: '#cfdfff',
			borderRadius: '999px',
			fontSize: '11px',
			padding: '4px 6px',
			fontWeight: '500',
        },
        deletIconBtn:{
            height:'35px',
            width:'35px'
        }
    })
)


export default SignupsList ;