import React, { useEffect } from 'react';
import { makeStyles, createStyles, Box, Paper } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Fragment } from 'react';
import {Check} from '../../../../img'



const LiveNotificationCard = (props) => {
    const classes = useStyles(props.liveSettings.panelStyle);
    const {
        liveSettings,
        visitorText,
        liveText,
        liveVisitorText,
        poweredBy

    } = props;

    let dynamicStyles = null

    /**
     * Dynamically generates the animation `@keyframes` of name `dynamicPulseAnimation` for Live css 
     * pulse animation and inserts it in style tag of head element
     * @param {String} r red value
     * @param {String} g green value
     * @param {String} b blue value
     */
    function addAnimation(r, g, b) {
        if (!dynamicStyles) {
            dynamicStyles = document.createElement("style")
            dynamicStyles.type = "text/css"
            document.head.appendChild(dynamicStyles)
        }
        
        let animationStyle = `
            @keyframes dynamicPulseAnimation { 
                0% { box-shadow: 0 0 0 0 rgba(${r},${g},${b},.4); }
                100% {  box-shadow: 0 0 0 19px rgba(${r},${g},${b},0); }
            }
            `
        dynamicStyles.sheet.insertRule(animationStyle, dynamicStyles.length)
    }

    
    useEffect(()=>{
        addAnimation(liveSettings.panelStyle.iconBGColor.r, liveSettings.panelStyle.iconBGColor.g, liveSettings.panelStyle.iconBGColor.b)
    },[liveSettings.panelStyle.iconBGColor])
    
    return(
        
        <Box 
            component={Paper}
            width= '360px'
            height= '85px'
            padding= '4px 15px 2px 15px'
            border='1px solid rgba(216, 217, 226, 0.5)'
            // boxShadow='10px 20px 40px 0 rgba(36, 35, 40, 0.1)'
            // borderRadius={`${liveSettings.panelStyle.radius}px`}
            // bgcolor= {`rgb(${liveSettings.panelStyle.backgroundColor.r}, ${liveSettings.panelStyle.backgroundColor.g}, ${liveSettings.panelStyle.backgroundColor.b})`}
            display='flex'
            alignItems='center'
            style={{
                flexDirection: 'column',
                backgroundColor:`rgb(${liveSettings.panelStyle.backgroundColor.r}, ${liveSettings.panelStyle.backgroundColor.g}, ${liveSettings.panelStyle.backgroundColor.b})`,
                borderRadius:`${liveSettings.panelStyle.radius}px`,
                boxShadow:'10px 20px 40px 0 rgba(36, 35, 40, 0.1)'
            }}
        >

            <Box className={classes.upperPart}>
                <div className={classes.animatinWrapper}>
                    {/* <FiberManualRecordIcon className={classes.waveAnimation} style={{ fontSize:'48px'}} /> */}
                    <div className={`${classes.circle} ${classes.circle_1}`}>
                        <div className={`${classes.circle} ${classes.circle_2}`}>

                        </div>
                    </div>
                </div>

                <Box className={classes.textContainer}>
                    <p
                        style={{
                            margin: '0',
                            paddingLeft: '5px',
                            color: '#49445f',
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                            lineHeight: '1',
                            overflow: 'hidden',
                            alignSelf: 'center'
                        }}
                    >
                        <em
                            className={classes.mainText}
                            style={{
                                fontFamily: liveSettings.panelStyle.fontFamily,
                                fontWeight: "600",
                                fontSize: '17px',
                                // backgroundColor: 'rgba(0, 149, 247, 0.05)',
                                borderRadius: '2px',
                                padding: '2px',
                                whiteSpace: 'nowrap',
                                letterSpacing: '0.3px',
                                color: `rgb(${liveSettings.panelStyle.color.r}, ${liveSettings.panelStyle.color.g}, ${liveSettings.panelStyle.color.b})`,
                                fontStyle: 'normal',
                                textAlign: 'left',
                                paddingRight: '5px',
                                textTransform: 'capitalize'
                            }}
                        >
                            {`431 ${visitorText}`}
                        </em>
                        <em
                            style={{
                                fontFamily: liveSettings.panelStyle.fontFamily,
                                fontWeight: liveSettings.panelStyle.fontWeight,
                                fontSize: '11.5px',
                                // whiteSpace: 'nowrap',
                                // overflowX: 'hidden',
                                // textOverflow: 'ellipsis',
                                letterSpacing: '1.33',
                                color: '#666',
                                // marginLeft: '2%',
                                lineHeight: '0.8',
                                paddingLeft: '2px',
                                fontStyle: 'normal'
                            }}
                        >
                            {liveVisitorText}
                        </em>
                    </p>
                </Box>
            </Box>

            {
                liveSettings.togglePoweredBy &&
                    <Fragment>
                        <div className={classes.border}></div>
                        <Box className={classes.lowerPart}>
                            <p className={classes.footerText}>
                                <em className={classes.verifiedText}>{liveText}</em>
                                {/* <em className={classes.verifiedIcon}><img src={Check}/></em> */}
                                <em className={classes.verifiedIcon}><Check/></em>
                                <em className={classes.influenceText}>
                                    {poweredBy}
                                </em>
                            </p>
                        </Box>
                    </Fragment>
            }
            
            

        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        upperPart:{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            height: '60px'
        },
        mainText:{
            fontFamily: 'sans-serif',
            fontWeight: '600',
            color: '#49445f'
        },
        waveAnimation:{
            color: props => `rgb(${props.iconBGColor.r}, ${props.iconBGColor.g}, ${props.iconBGColor.b})`,
        },
        animatinWrapper:{
            width: '36px',
            height: '36px',
            margin:'14px 16px 0px 7px'

        },
        circle:{
            borderRadius: '50%',
            width: '36px',
            height: '36px',
            background: props => `rgb(${props.iconBGColor.r}, ${props.iconBGColor.g}, ${props.iconBGColor.b})`,
            boxShadow: props => `0 0 0 rgba(${props.iconBGColor.r}, ${props.iconBGColor.g}, ${props.iconBGColor.b}, 0.4)`,           
            margin: 'auto',
            /*padding: 0px;*/            
            color: 'white',
            fontSize: '17px',
            textalign: 'center',
            lineheight: '36px',
            padding: '0px'
        },
        circle_1:{
            animation:`dynamicPulseAnimation 3s linear infinite`
        },
        circle_2:{
            animation:`dynamicPulseAnimation 3s linear infinite 1.5s`
        },
        border:{
            borderTop: '1px dashed rgba(102, 102, 102, 0.0)',
            margin: '1px',
            marginBottom: '0',
            width: '100%'
        },
        lowerPart:{
            textAlign: 'center',
            paddingTop: '1px'
        },
        footerText:{
            fontSize: '9px',
            fontWeight: '600',
            fontStyle: 'normal',
            textAlign: 'left',
            color: '#666',
            fontFamily: 'sans-serif',
            wordSpacing: '1px',
            margin:0
        },
        verifiedIcon:{
            fontSize: '11px',
            color: '#0093fb',
            padding: '0px 5px',
            marginTop: '-2px'
        },
        influenceText:{
            fontStyle: 'normal',
            fontSize: '9.5px',
            fontWeight: '600',
            fontFamily: 'sans-serif',
            color: '#077fff'
        },
        verifiedText:{
            fontStyle: 'normal',
            fontFamily: 'sans-serif'
        }

    })    
)

export default LiveNotificationCard;
