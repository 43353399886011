import React, { useEffect, useState } from 'react'
import {
  Box,
  makeStyles,
  createStyles,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core'
import Moment from 'react-moment'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded'
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { deletePolicyCampaign, getPolicyCampaigns, updatePolicyCampaign } from '../poicyThunks/policyCampaign/actions'
import DeleteConfirmationModel from './DeleteConfirmationModel'
import { Link, browserHistory } from 'react-router'
import { POLICY_CAMPAIGNS } from '../poicyThunks/policyCampaign/reducers'
import { Skeleton } from '@material-ui/lab'
import PreviewPolicy from '../NewPolicy/PreviewPolicy'
import { getPolicyPdf } from '../poicyThunks/policymaster/actions'
import HoverMenu from 'Globals/GlobalComponents/HoverMenu'
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined'
import Pill from 'Globals/GlobalComponents/Pill'
import ToggleSwitch from 'Globals/GlobalComponents/ToggleSwitch'

const Policies = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const policyCampaigns = useSelector((state) => state.get('policyCampaigns').toJS())
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState(false)
  const [previewingCampaignId, setPreviewingCampaignId] = useState('')
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false)
  const [campaignToggleLoading, setCampaignToggleLoading] = useState([])

  useEffect(() => console.log({ '----------policies--------------': policyCampaigns }), [
    policyCampaigns,
  ])

  useEffect(() => {
    dispatch(getPolicyCampaigns())
  }, [])

  const handlePolicyDelete = () => {
    setLoadingDelete(true)
    dispatch(deletePolicyCampaign(selectedCampaign, policyDeleteCallback))
  }

  const policyDeleteCallback = (response, error) => {
    setLoadingDelete(false)
    setIsDeleteModalOpen(false)
  }

  const handleSelectCampaignForDelete = (campaign) => {
    if (campaign) setSelectedCampaign(campaign)
    else setSelectedCampaign(false)

    setIsDeleteModalOpen(true)
  }

  const handleDeleteModal = () => {
    setIsDeleteModalOpen((oldState) => !oldState)
    setLoadingDelete(false)
  }

  const pushToSettings = (policy) => {
    dispatch({ type: POLICY_CAMPAIGNS.SAVE_OPENED_CAMPAIGN, payload: policy })
    browserHistory.push(`/policy-generator/edit/${policy._id}`)
  }

  const handelPreviewDialog = (campaignId) => {
    setPreviewDialogOpen((oldData) => !oldData)
    campaignId ? setPreviewingCampaignId(campaignId) : setPreviewingCampaignId('')
  }

  const handleToggleCampaignStatus = (policy) => {
    setCampaignToggleLoading((oldState) => [...oldState, policy._id])
    dispatch(
      updatePolicyCampaign(
        policy._id,
        { isActive: !policy.isActive },
        handleToggleCampaignStatusCallback,
        true
      )
    )
  }

  const handleToggleCampaignStatusCallback = (res, err, id) => {
    if (err) {
      setCampaignToggleLoading((oldState) => oldState.filter((id) => id !== id))
    } else {
      setCampaignToggleLoading((oldState) => oldState.filter((id) => id !== res._id))
    }
  }

  const downloadPdfHandler = (campaignId) => {
    dispatch({
      type: 'FIRE_TOASTER',
      payload: { type: 'success', message: 'Policy PDF will be downloaded shortly...' },
    })
    getPolicyPdf(campaignId, downloadPdfHandlerCallback)
  }

  const downloadPdfHandlerCallback = (mssg, err) =>  {
    if  (err)  {
      dispatch({
        type: 'FIRE_TOASTER',
        payload: { type: 'error', message: mssg },
      })
    }
  }

  return (
    <Box className={classes.policiesContainer}>
      <Typography variant="h4">Policies</Typography>
      <Box className={classes.policyStatsContainer}>
        <Typography>
          <span style={{ color: 'gray' }}>Total Generated: </span>
          <Typography component={'span'}>
            {policyCampaigns.campaigns.filter((item) => item.status !== 'draft').length}
          </Typography>
        </Typography>
        <Typography>
          <span style={{ color: 'gray' }}>Drafts: </span>
          <Typography component={'span'}>
            {policyCampaigns.campaigns.filter((item) => item.status === 'draft').length}
          </Typography>
        </Typography>
      </Box>

      <TableContainer className={classes.policiesTableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Policy</TableCell>
              <TableCell className={'dateCell'}>Created On</TableCell>
              <TableCell className={'dateCell'}>Updated On</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Status</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {policyCampaigns.loading ? (
              <TableRowSkeleton />
            ) : policyCampaigns.campaigns.length > 0 ? (
              policyCampaigns.campaigns.map((policy) => (
                <TableRow hover className={classes.tableRowStyle} key={policy._id}>
                  <TableCell onClick={() => pushToSettings(policy)} className={classes.nameUrlCell}>
                    <span className={'tableRowHoverText'}>{policy.businessName}</span>
                    <span>{policy.websiteUrl}</span>
                  </TableCell>
                  <TableCell className={'dateCell'} onClick={() => pushToSettings(policy)}>
                    <Moment format="MMM D, YYYY" date={policy.createdAt} />
                  </TableCell>
                  <TableCell className={'dateCell'} onClick={() => pushToSettings(policy)}>
                    <Moment format="MMM D, YYYY" date={policy.updatedAt} />
                  </TableCell>

                  <TableCell>
                    <ToggleSwitch
                      checked={policy.isActive}
                      onChange={() => handleToggleCampaignStatus(policy)}
                      loading={campaignToggleLoading.includes(policy._id)}
                    />
                  </TableCell>
                  <TableCell onClick={() => pushToSettings(policy)}>
                    <Pill type={policy.status === 'live' ? 'green' : 'purple'}>
                      {policy.status}
                    </Pill>
                  </TableCell>

                  <TableCell className={classes.actionIconsContainer}>
                    <HoverMenu>
                      <VisibilityOutlinedIcon
                        onClick={() => handelPreviewDialog(policy._id)}
                        fontSize="small"
                      />
                      {/* <Link to={`policy-generator/${policy._id}`}> */}
                      <EditRoundedIcon fontSize="small" onClick={() => pushToSettings(policy)} />
                      {/* </Link> */}
                      <CloudDownloadOutlinedIcon
                        onClick={() => downloadPdfHandler(policy._id)}
                        fontSize="small"
                      />
                      <DeleteOutlineRoundedIcon
                        onClick={() => handleSelectCampaignForDelete(policy)}
                        fontSize="small"
                      />
                      <InfoOutlinedIcon fontSize="small" />
                    </HoverMenu>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>No campaigns</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <DeleteConfirmationModel
        isModalOpen={isDeleteModalOpen}
        handleDialog={handleDeleteModal}
        handleDeleteCampaign={handlePolicyDelete}
        loadingDelete={loadingDelete}
      />

      <PreviewPolicy
        open={previewDialogOpen}
        campaignId={previewingCampaignId}
        onClose={handelPreviewDialog}
      />
    </Box>
  )
}

const TableRowSkeleton = () => (
  <TableRow>
    <TableCell>
      <Skeleton height={40} />
    </TableCell>
    <TableCell>
      <Skeleton height={40} />
    </TableCell>
    <TableCell>
      <Skeleton height={40} />
    </TableCell>
    <TableCell>
      <Skeleton height={40} />
    </TableCell>
    <TableCell>
      <Skeleton height={40} />
    </TableCell>
    <TableCell>
      <Skeleton height={40} />
    </TableCell>
  </TableRow>
)

const useStyles = makeStyles((theme) =>
  createStyles({
    policiesContainer: {
      '&>h4': {
        color: '#000',
        fontSize: '19px',
        fontFamily: 'Poppins',
        fontWeight: '500',
      },
    },
    policyStatsContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(2),
      '&>p:first-child': {
        marginRight: theme.spacing(2),
      },
    },
    tableRowStyle: {
      cursor: 'pointer',
      '&>td': {
        fontSize: '14px',
      },
      '& .tableRowHoverText': {
        fontWeight: '500',
        maxWidth: '125px',
        overflow: 'hidden'
      },
      
    },
    nameUrlCell:{
      display:'flex',
      flexDirection: 'column',
      '&>span:last-child':{
        fontSize:'11px ',
        color:'rgba(9,30,66,.74) ',
        letterSpacing:'normal',
        fontWeight: '400',
        textTransform: 'lowercase'
      }
    },
    policiesTableContainer: {
      marginTop: theme.spacing(4),
      backgroundColor: '#fff',
      '& .dateCell':{
        maxWidth:'70px'
      }
    },
    actionIconsContainer: {
      textAlign: 'center',
      '&>svg': {
        color: 'grey',
        margin: '0 8px',
        cursor: 'pointer',
      },
    },
  })
)

export default Policies
