import {
    GET_GOAL_DATA,
    PUT_GOAL_DATA,
    DELETE_GOAL_DATA,
    GET_GOAL_FETCHING,
    GOAL_SCHEDULAR,
    CREATE_GOAL
}from './action';



import { fromJS } from 'immutable';


const initialState = fromJS({});

export const GoalPageData = (state = initialState,action) => {
    switch(action.type){
        case GET_GOAL_DATA:
            // get request data saved in reducer.
            return state.set('goalPageData',action.payload);
        case CREATE_GOAL:{
            // condition to check whether the goalPageData is undefined or not.
            if (state.get('goalPageData') === undefined) {
                // if reducer state is undefined.
               return state.set('goalPageData', [action.payload])
            // when there is already in table when means request is already made.
            } else return state.set('goalPageData',state.get('goalPageData').concat(action.payload)); 
        }
        case PUT_GOAL_DATA:
            // put goal data in reducer.
            return state.set('goalPageData',action.payload);
        case DELETE_GOAL_DATA:
            // filter out the deleted object from reducer.
            return state.set('goalPageData',state.get('goalPageData').filter(state => state._id !== action.payload._id));
        default :
            return state;
    }
}
// another reducer was made because when we add path from campaign table the get request is not called because we had put a condition in it.  
export const GoalFetching = (state = initialState,action) => {
    switch(action.type){
        case GET_GOAL_FETCHING:
            return state.set('goalFetching',action.payload);
        case GOAL_SCHEDULAR:
            return state.set('goalSchedularData', action.payload)
        default :
        return state;    
    }
}
//reducer for creating goals  data
export const GoalsData = (state = initialState, action) => {
    
    switch(action.type) {
        case CREATE_GOAL :
            return state.set('goalsData', action.payload)
        default:
            return state;
    }
}
