import { fromJS } from 'immutable';

const action = name => `/report/${name}`;

export const FETCH = action('FETCH');
export const FETCH_SUCCESS = action('FETCH_SUCCESS');

export const fetchReport = (userId, duration) => ({ type: FETCH, userId, duration });
export const fetchSuccess = (report) => ({ type: FETCH_SUCCESS, report });

const initialState = fromJS({});
const report = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return state.set('report', action.report);
    default:
      return state;
  }
};

export default report;
