import { fromJS } from 'immutable';

const action = name => `/agencyPlan/${name}`;

export const FETCH = action('FETCH');
export const CREATE = action('CREATE');
export const UPDATE = action('UPDATE');
export const REMOVE = action('REMOVE');
export const SUCCESS = action('SUCCESS');
export const FETCH_SUCCESS = action('FETCH_SUCCESS');
export const CREATE_SUCCESS = action('CREATE_SUCCESS');
export const UPDATE_SUCCESS = action('UPDATE_SUCCESS');
export const POP_GOAL = action('POP_GOAL');


export const fetchAgencyPlan = (trackingId) => ({ type: FETCH, trackingId });
export const createAgencyPlan = (agencyPlan) => ({ type: CREATE, agencyPlan });
export const updateAgencyPlan = (agencyPlan) => ({ type: UPDATE, agencyPlan });
export const fetchSuccess = (agencyPlan) => ({ type: FETCH_SUCCESS, agencyPlan });
export const createSuccess = (agencyPlan) => ({ type: CREATE_SUCCESS, agencyPlan });
export const removeAgencyPlan = (id, index) => ({ type: REMOVE, id, index });
export const popAgencyPlan = (index) => ({ type: POP_GOAL, index });
export const successAgencyPlan = (agencyPlan) => ({ type: CREATE_SUCCESS, agencyPlan });


const initialState = fromJS({});
const agencyPlan = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return state.set('agencyPlan', action.agencyPlan);
    case CREATE_SUCCESS:
      return state.set('agencyPlan', state.get('agencyPlan').concat(action.agencyPlan));
    case POP_GOAL:
      return state.set('agencyPlan', state.get('agencyPlan').slice(0,action.index).concat(state.get('agencyPlan').slice(action.index+1)));
    default:
      return state;
  }
};

export default agencyPlan;
