import React from 'react'
import {TableContainer, TableHead, Table, TableCell, TableRow, TableBody, makeStyles, createStyles, Box, Typography, Button} from '@material-ui/core'
import { MICROPOLICY_PROPERTY_CHANGE } from './policyReducer'
import { useDispatch } from 'react-redux'
import AddIcon from '@material-ui/icons/Add';


const Data = (props)=>{

	const {microPolicySettings, setMicroPolicySettings} = props
	const dispatch = useDispatch()
	
	React.useEffect(()=>{
		console.log('microPolicySettings', microPolicySettings)
	},[microPolicySettings])

	const handleChange = (e, index) =>{
		let data = {...microPolicySettings}
		console.log(data)
		data.dataUsage[index][e.target.name]=e.target.value
		setMicroPolicySettings({type: MICROPOLICY_PROPERTY_CHANGE, data:{name:'dataUsage', value: data.dataUsage}})
	}


	const handleDelete = (dataPoint,index)=>{
		console.log(dataPoint)
		let data = {...microPolicySettings}
		data.dataUsage.splice(index,1)
		setMicroPolicySettings({type: MICROPOLICY_PROPERTY_CHANGE, data:{name:'dataUsage', value: data.dataUsage}})
	}

	const handleAdd = () =>{
		let data = {...microPolicySettings}
		data.dataUsage.push({name:'', description: ''})
		setMicroPolicySettings({type: MICROPOLICY_PROPERTY_CHANGE, data:{name:'dataUsage', value: data.dataUsage}})
	}


	return(
		<Box>
			<Typography variant="h5">
				Data Usage
			</Typography>
			<Typography variant="body2">
				Define the uses of the data you collect in this policy - your users will see this in the Cookie Widget
			</Typography>
			<TableContainer style={{marginTop:'1.5rem'}}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Data Point</TableCell>
							<TableCell>Purpose</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{
							microPolicySettings.dataUsage.map((item, index)=>(
								<TableRow key={index}>
									<TableCell><Input name="name" onChange={(e)=>handleChange(e, index)} placeholder="Name" value={item.name}/></TableCell>
									<TableCell><Input name="description" onChange={(e)=>handleChange(e, index)} placeholder="Description"  value={item.description}/></TableCell>
									<TableCell key={index} onClick={()=>handleDelete(item, index)} style={{cursor:'pointer'}}>x</TableCell>
								</TableRow>
							))
						}
						<TableRow>
							<TableCell>
								<Button variant="outlined" onClick={handleAdd}><AddIcon/> Add</Button>
							</TableCell>
						</TableRow>
					</TableBody>

				</Table>
			</TableContainer>
		</Box>
	)
}


const Input= (props)=>{
	const classes = useStyles()
	return(
		<input className={classes.inputClass} {...props}/>
	)
}


const useStyles= makeStyles(theme =>
	createStyles({
		inputClass:{
			border: '1px solid transparent',
			background:'#c6c6c64d',
			'&:focus':{
			outline:'none'
		}
	}
}))

export default Data