import { fork } from 'redux-saga/effects';
import auth from './auth';
import profile from './profile';
import plan from './plan';
import payment from './payment';
import notification from './notification';
import campaign from './campaign';
import rules from './rules';
import configuration from './configuration';
import elastic from './elastic';
import pageurl from './pageurl';
import webhooks from './webhooks';
import subcampaign from './subcampaign';
import oauth from './oauth';
import affiliate from './affiliate';
import integrations from './integrations';
import goal from './goal';
import report from './report';
import lemlist from './lemlist';
import restream from './restream';
import autoklose from './autoklose';
import aweber from './aweber';
import netapp from './netapp';
import agencyPlan from './agencyPlan';
import abhisi from './abhisi';
import facebook from './facebook';
import google from './google';

export default function* rootSaga() {
  yield [
    fork(auth),
    fork(profile),
    fork(plan),
    fork(payment),
    fork(notification),
    fork(campaign),
    fork(rules),
    fork(configuration),
    fork(elastic),
    fork(pageurl),
    fork(webhooks),
    fork(subcampaign),
    fork(oauth),
    fork(affiliate),
    fork(integrations),
    fork(goal),
    fork(report),
    fork(lemlist),
    fork(restream),
    fork(autoklose),
    fork(aweber),
    fork(netapp),
    fork(agencyPlan),
    fork(abhisi),
    fork(facebook),
    fork(google)
  ];
}
