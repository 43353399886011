import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Box from '@material-ui/core/Box';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

function getSteps() {
    return ['Install', 'Scan', 'Categorize'];
}


function getStepContent(step) {
    switch (step) {
        case 0:
            return 'Install...';
        case 1:
            return 'Scan';
        case 2:
            return 'Categorize';
        default:
            return 'Unknown step';
    }
}


function ColorlibStepIcon(props) {
	const classes = useColorlibStepIconStyles();
	const { active, completed } = props;

	const icons = {
		1: <SettingsIcon style={{fontSize:'1.1em'}}/>,
		2: <GroupAddIcon style={{fontSize:'1.1em'}}/>,
		3: <VideoLabelIcon style={{fontSize:'1.1em'}}/>,
	};

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
			})}
		>
			{icons[String(props.icon)]}
		</div>
	);
}

	const ColorlibConnector = withStyles({
		alternativeLabel: {
			top: 29,
		},
		active: {
			'& $line': {
				backgroundImage:
					'linear-gradient( 95deg,rgb(18 52 77) 0%,rgb(18 52 77) 50%,rgb(18 52 77) 100%)',
			},
		},
		completed: {
			'& $line': {
				backgroundImage:
					'linear-gradient( 95deg,rgb(18 52 77) 0%,rgb(18 52 77) 50%,rgb(18 52 77) 100%)',
			},
		},
		line: {
			height: 3,
			border: 0,
			backgroundColor: '#eaeaf0',
			borderRadius: 1,
		},
	})(StepConnector);


const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 34,
		height: 34,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	active: {
		backgroundImage:
			'linear-gradient( 136deg, rgb(18 52 77) 0%, rgb(51 105 144) 50%, rgb(0 123 255) 100%)',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	},
	completed: {
		backgroundImage:
		'linear-gradient( 95deg,rgb(18 52 77) 0%,rgb(18 52 77) 50%,rgb(18 52 77) 100%)',
	},
});



const CreationStepper = (props) => {
    const {handleNext, handleBack, activeStep} = props
    console.log('activestep', activeStep)
    const classes = useStyles();
    const steps = getSteps();


    return (
        <Box style={{marginBottom:'2rem', marginTop:'1rem'}}>
            <Stepper style={{background:'transparent', padding:'0'}} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {
									steps.map((label) => (
											<Step key={label}>
													<StepLabel classes={{alternativeLabel: classes.alternativeLabelClass}} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
											</Step>))
                }
            </Stepper>

            {/* <div>
                <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                <div>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                    Back
                </Button>
                <Button
                    variant="contained"
                    // color="primary"
                    onClick={handleNext}
                    className={classes.button}
                >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
				<span>This is for testing purpose only.</span>
                </div>
            </div> */}
        </Box>
    )
}



const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
		},
		alternativeLabelClass:{
			marginTop:'7px !important'
		}
  }));
  


export default CreationStepper
