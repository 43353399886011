import React, { useState } from 'react';
import { Box, makeStyles, createStyles } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import copy from 'copy-to-clipboard';
import CustomTooltop from './CustomTooltip';
import CustomToast from './CustomToast';
import PropTypes from 'prop-types'
import Skeleton from '@material-ui/lab/Skeleton';


const CopyBox  = ({copyContent, placement="right", tooltipMessage="Copy", loading=false}) => {

    const classes = useStyles();

    const [openToaster, setOpenToaster] = useState(false)

    const copyHandler = () =>{
        copy(copyContent)
        setOpenToaster(true)
    }

    const closeHandler=()=>{
        setOpenToaster(false)
    }

    return(
        <Box padding="2% 3%" paddingTop="0%">
            {
                loading ? 
                    <Skeleton variant="text" height="100%" width="100%"/>:
                    <CustomTooltop arrow title={tooltipMessage} placement={placement}>
                        <Box className={classes.apikeyContainer} onClick={copyHandler}>
                            <pre>{copyContent}</pre>
                            <FileCopyOutlinedIcon style={{fontSize:'14px', cursor:'pointer', marginLeft:'10px'}} onClick={copyHandler}/>
                        </Box>
                    </CustomTooltop>
                    
            }

            <CustomToast open={openToaster} message={"Copied to clipboard"} closeHandler={closeHandler}/>
        </Box>
    );
}



const useStyles = makeStyles(() =>
    createStyles({
        apikeyContainer:{
            display:'flex',
            alignItems:'center',
            border:'1px solid #c6c6c6',
            padding:'14px 30px',
            borderRadius:'4px',
            width:'fit-content',
            margin:'20px 0',
            color:'#919191',
            cursor:'pointer',
            transition:'0.2s',
            "& pre":{
                fontSize:'13px',
                color:'#777777',
                letterSpacing:'0.3px',
                margin:'0',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all'
            },
            "&:hover":{
                boxShadow:'1px 1px 5px 4px #eae9e9c2'
            }
        }
    })
)

CopyBox.propTypes={
    copyContent: PropTypes.string.isRequired,
    placement: PropTypes.string,
    tooltipMessage: PropTypes.string
}



export default CopyBox ;