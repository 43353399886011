import React, { memo, useEffect } from 'react';
import { Box, makeStyles, createStyles, Grid, Typography, Checkbox } from '@material-ui/core';
import { languageData } from '../../../../Globals/GlobalComponents/languages';
import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
// import TextInput from '../../../../Globals/GlobalComponents/TextInput';
import InputBox from '../../../../Globals/GlobalComponents/InputBox';
import CustomHelpIcon from '../../../../Globals/GlobalComponents/CustomHelpIcon';
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';

const BasicBox  = (props) => {
    // const classes = useStyles();

    const {
        langName,
        // notificationSize,
        toggleHideName,
        usernameText,
        isHideFullLocation,
        isHideCountryLocation,
        isHideState,
        isHideCityLocation,
        handlePropertyChange,
        handleInputChange,
        setRecentSettings,
        handleLanguageChange
    } = props;


    return(
        <Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container direction="column" spacing={3}>
                <Grid item md={12}>
                    <Grid container alignItems='center'>
                        <Grid item md={4}>
                            <Typography variant="body2">
                                Language
                                {/* <a href={helpArticleLinks.faq.notification.language} target="_blank">
                                    <img src = {HelpQuestion}  style={{ height: "11px",marginLeft:"2%"}}/>
                                </a> */}
                                <InfluenceHelpIcon href={helpArticleLinks.faq.notification.language} fontSize="tiny"/>
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <ReactSelect
                                name="langName"
                                options={languageData}
                                value={langName.language}
                                dispatcher={setRecentSettings}
                                onChange={handleLanguageChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {/*<Grid item md={12}>
                    <Grid container alignItems='center'>
                        <Grid item md={4}>
                            <Typography variant="body2">Notification Size</Typography>
                        </Grid>
                        <Grid item md={3}>
                            <ReactSelect 
                                width='120px' 
                                height='35px' 
                                value={notificationSize}
                                name='notificationSize'
                                dispatcher={setRecentSettings}
                                onChange={handlePropertyChange}
                                options={[
                                    {value:"default", label:"Default"},
                                    {value:"large", label:"Large"}
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>*/}
                <Grid item md={12} style={{paddingLeft: '0px'}}>
                    <Grid container alignItems='center'>
                        <Grid item md={1}>
                            <Checkbox 
                                checked={toggleHideName}
                                name='toggleHideName'
                                onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, setRecentSettings)}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Typography variant="body2">Hide Username</Typography>
                        </Grid>
                        {toggleHideName &&
                            <Grid item md={4} style={{paddingLeft:'8px'}}>
                                <InputBox
                                    name="usernameText"
                                    dispatcher={setRecentSettings}
                                    value={usernameText}
                                    onChange ={handleInputChange}
                                />
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item md={12} style={{paddingLeft: '0px'}}>
                    <Grid container spacing={0} alignItems='center'>
                        <Grid item md={1}>
                            <Checkbox 
                                checked={isHideFullLocation}
                                name='isHideFullLocation'
                                onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, setRecentSettings)}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <Typography variant="body2">Hide Location</Typography>
                        </Grid>
                        <Grid item md={1}>
                            <Checkbox 
                                checked={isHideCountryLocation}
                                name='isHideCountryLocation'
                                onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, setRecentSettings)}
                                disabled={isHideFullLocation || isHideState}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <Typography variant="body2">Hide Country</Typography>
                        </Grid>
                        <Grid item md={1}>
                            <Checkbox 
                                checked={isHideState}
                                name='isHideState'
                                onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, setRecentSettings)}
                                disabled={ isHideFullLocation || isHideCountryLocation ? true : false}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <Typography variant="body2">Hide State</Typography>
                        </Grid>
                        <Grid item md={1}>
                            <Checkbox 
                                checked={isHideCityLocation}
                                name='isHideCityLocation'
                                onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, setRecentSettings)}
                                disabled={isHideFullLocation ? true : false}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <Typography variant="body2">Hide City</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        input: {
            height: '35px',
            backgroundColor: '#fff',
            marginTop: '4%',
            border: ' 0.5px solid rgba(0, 0, 0, 0.23)',
            width: '100%',
            borderRadius: '4px',
            padding: '0% 3%'
        },
    })
)

export default memo(BasicBox);