import React, { useState, memo, useEffect } from 'react';
import { makeStyles, createStyles, Typography, Box, Grid, Checkbox, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import BasicBox from './BasicBox'
import ApperanceBox from './ApperanceBox'
import Textbox from './Textbox'
import FeaturesBox from './FeaturesBox'
import AdditionalFeaturesBox from './AdditionalFeaturesBox'
import InputBox from '../../../../Globals/GlobalComponents/InputBox';
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import { useSelector } from 'react-redux';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';



const LiveNotificationRules = (props) => {
    const classes = useStyles();
    const [hyperLinkCheck, setHyperLinkCheck] = useState(false);
    const user = useSelector(state=>(state.getIn(['auth','user'])))



    const {
        handlePropertyChange, 
        setLiveSettings, 
        liveSettings,
        notificationConfiguration,
        campaignInfo,
        notificationType,
        handlePanelStyleChange,
        handleLanguageChange,
        handleNotificationSubmit,
        visitorText,
        liveText,
        liveVisitorText,
        poweredBy,
        loading,
        brandingLocked,
        onClose
    } = props;


    const handleHyperLinkcheck = () => {
        if(hyperLinkCheck === false) {
            setHyperLinkCheck(true);
        }else {
            setHyperLinkCheck(false)
            handlePropertyChange('notificationUrl','', setLiveSettings)
        }
    }

    useEffect(()=>{
        if(liveSettings.notificationUrl.length > 0){
            setHyperLinkCheck(true)
        }
    },[liveSettings.notificationUrl])



    return(
        <Box>
            
            <BasicBox
                langName={liveSettings.langName}
                setLiveSettings={setLiveSettings}
                handleLanguageChange={handleLanguageChange}
                handlePropertyChange={handlePropertyChange}
                notificationSize={liveSettings.notificationSize}

            />

            <ApperanceBox
                handlePanelStyleChange={handlePanelStyleChange}
                setLiveSettings={setLiveSettings}
                radius={liveSettings.panelStyle.radius}
                backgroundColor={liveSettings.panelStyle.backgroundColor}
                iconBGColor={liveSettings.panelStyle.iconBGColor}
            />

            <Textbox
                handlePanelStyleChange={handlePanelStyleChange}
                setLiveSettings={setLiveSettings}
                fontWeight={liveSettings.panelStyle.fontWeight}
                fontFamily={liveSettings.panelStyle.fontFamily}
                color={liveSettings.panelStyle.color}
            />

            <FeaturesBox
                visitorText={visitorText}
                liveVisitorText={liveVisitorText}
                setLiveSettings={setLiveSettings}
                handlePropertyChange={handlePropertyChange}
                handlePanelStyleChange={handlePanelStyleChange}
                liveViewerText={liveSettings.liveViewerText}
                liveFollowerText={liveSettings.liveFollowerText}
                liveVisitorCount={liveSettings.panelStyle.liveVisitorCount}
            />

            <AdditionalFeaturesBox
                liveText={liveText}
                handlePropertyChange={handlePropertyChange}
                poweredBy={poweredBy}
                setLiveSettings={setLiveSettings}
                poweredByLink={liveSettings.poweredByLink}
                togglePoweredBy={liveSettings.togglePoweredBy}
                brandingLocked={brandingLocked}
            />

            <Box borderTop='1px solid #f5f6f7' padding='5% 0%'>
                <Grid container>
                    <Grid item md={12}>
                        <Grid container alignItems='center'>
                            <Grid item >
                                <Checkbox 
                                    checked={hyperLinkCheck}
                                    onChange={handleHyperLinkcheck}
                                />
                            </Grid>
                            <Grid item md={5}>
                                <Typography className={classes.boxTitle}>
                                    Notification Clickable
                                    {/* <a href={helpArticleLinks.faq.notification.clickable} target="_blank">
                                        <img src = {HelpQuestion}  style={{ height: "13px",marginLeft:"2%"}}/>
                                    </a> */}
                                    <InfluenceHelpIcon href={helpArticleLinks.faq.notification.clickable} />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {hyperLinkCheck &&
                        <Grid item md={12}>
                            <Grid container alignItems="center">
                                <Grid item md={2}>
                                    <Typography variant="body2">HyperLink</Typography>
                                </Grid>
                                <Grid item md={9} style={{paddingLeft: '15px'}}>
                                    <InputBox
                                        name="notificationUrl"
                                        value={liveSettings.notificationUrl}
                                        dispatcher={setLiveSettings}
                                        onChange={handlePropertyChange}
                                        maxLength={150}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Box>
            <Box className={classes.saveBtnContainer}>

                <Button variant="outlined" onClick={onClose} className={classes.cancelBtn}>
                    Close
                </Button>

                <Button 
                    className={classes.saveBtn}
                    variant="contained"
                    disabled={loading || user.role.name === "campaignB"}
                    onClick={
                        () => handleNotificationSubmit(
                                notificationConfiguration[0]._id,
                                liveSettings,
                                campaignInfo._id,
                                notificationType[0]._id,
                                campaignInfo.websiteUrl,
                                campaignInfo.trackingId
                            )
                    }
                >
                    Save Changes
                    { loading && <CircularProgress classes={{colorPrimary: classes.circularProgress}} size={25} thickness={5} /> }
                </Button>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        input: {
            height: '35px',
            backgroundColor: '#fff',
            // marginTop: '4%',
            border: ' 0.5px solid rgba(0, 0, 0, 0.23)',
            width: '100%',
            borderRadius: '4px',
            padding: '0% 3%',
            fontSize: '13px'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            paddingBottom: '2%'
        },
        circularProgress: {
            color: '#fff',
            top:'52%',
            left:'52%',
            marginTop:-12,
            marginLeft:-12,
            position:'absolute'
        },
        saveBtnContainer:{
            position: 'fixed',
            width: '46%',
            bottom: '0',
            zIndex: '2',
            backgroundColor: '#fff',
            padding: '17px 0px',
            marginLeft: '-2.8%',
            borderTop: '1px solid #c6c6c6',
            display: 'flex',
            justifyContent: 'flex-end'
        },
        saveBtn:{
            position:'relative',
            margin: '0 4%'
        },
    })    
)

export default memo(LiveNotificationRules);