import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, makeStyles, createStyles, Typography, Button } from '@material-ui/core'
import { checkWhitelable } from '../../services/permissions'
import copy from 'copy-to-clipboard'
import CustomToast from '../../Globals/GlobalComponents/CustomToast'

const PixelCode = () => {
  const classes = useStyles()
  const userdetails = useSelector((state) => state.getIn(['auth', 'user']))
  const [openToaster, setOpenToaster] = useState(false)
  const [pixelLink, setPixelLink] = useState('')

  useEffect(() => {
    checkWhitelable()
      ? setPixelLink(
          `<script src="https://cdn.insidescript.com/static/social-proof-analytics.js?trackingId=${
            userdetails.trackingId
              ? userdetails.trackingId
              // : userdetails.team
              // ? userdetails.team.trackingId
              : 'XXXXXXXX'
          }" async></script>`
        )
      : setPixelLink(
          `<script src="https://cdn.useinfluence.co/static/influence-analytics.js?trackingId=${
            userdetails.trackingId
              ? userdetails.trackingId
              // : userdetails.team
              // ? userdetails.team.trackingId
              : 'XXXXXXXX'
          }" async></script>`
        )
  }, [])

  const copybtnHandler = () => {
    copy(pixelLink)
    setOpenToaster(true)
  }

  return (
    <Box>
      <Box className={classes.topContainer}>
        <Typography
          style={{
            color: '#000',
            fontSize: '16px',
            fontWeight: '500',
            marginTop: '0',
            fontFamily: 'Poppins',
          }}
        >
          Pixel Code
        </Typography>

        <Button variant="contained" disableElevation onClick={copybtnHandler}>
          Copy Pixel
        </Button>

        {/* <Snackbar 
					open={openToaster}
					autoHideDuration={3000} 
					onClose={()=>setOpenToaster(false)}
					anchorOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
				>
					<Alert variant="filled" style={{background:'black'}}>
						<div style={{display:'flex',width:'auto'}}>
							<div style={{fontSize:'11x',paddingLeft:'6px',fontWeight:'400'}}>Pixel Code Copied to Clipboard</div>
						</div>
					</Alert>
				</Snackbar> */}

        <CustomToast
          open={openToaster}
          closeHandler={setOpenToaster}
          message={'Pixel Code Copied to Clipboard'}
        />
      </Box>

      <Box className={classes.linkContainer}>
        <Typography className={classes.pixelText}>{pixelLink}</Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    topContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '2%',
    },
    linkContainer: {
      backgroundColor: '#fff',
      padding: '15px',
      borderRadius: '4px',
      marginTop: '1%',
      boxShadow: '2px 2px 3px 1px #c6c6c6',
    },
    pixelText: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      color: '#575353',
      margin: '0 ',
    },
  })
)

export default PixelCode
