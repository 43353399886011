import React, { memo, useEffect } from 'react'
import { Box, makeStyles, createStyles, Grid, Typography } from '@material-ui/core'
import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
// import TextInput from '../../../../Globals/GlobalComponents/TextInput'
import InputBox from '../../../../Globals/GlobalComponents/InputBox';
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';


const FeaturesBox = (props) =>{
	const {
        handlePanelStyleChange, 
        setReviewSettings,
        recentNumber,
        recentConv,
        minStarRating,
        notificationConfiguration,
        selectLastDisplayConversation
    } = props

	const classes = useStyles()

    useEffect(()=>{
        notificationConfiguration ?
            setReviewSettings({type: 'INIT', data: notificationConfiguration}):
            null
    },[notificationConfiguration])
	
	return(
		<Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container spacing={2} direction="column">
                <Grid item md={12} >
                    <Typography className={classes.boxTitle} style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                        Features
                        {/* <a href={helpArticleLinks.faq.notification.featuresReview} target="_blank">
                            <img src = {HelpQuestion}  style={{ height: "13px",marginLeft:"2%"}}/>
                        </a> */}
                        <InfluenceHelpIcon href={helpArticleLinks.faq.notification.featuresReview} fontSize="tiny"/>
                    </Typography>
                   
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={6}>
                            <Typography variant="body2">Enter number of conversions to display</Typography>
                        </Grid>
                        <Grid item md={6}>
                            <InputBox
                                name="recentNumber"
                                dispatcher={setReviewSettings}
                                value={recentNumber}
                                onChange={handlePanelStyleChange}
                                type="number"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item md={6}>
                            <Typography variant="body2">Show reviews from last</Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Grid container spacing={2}>
                                <Grid item md={6} style={{paddingLeft: '0'}}>
                                    <InputBox
                                        name="recentConv"
                                        dispatcher={setReviewSettings}
                                        value={recentConv}
                                        onChange={handlePanelStyleChange}
                                        type="number"
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <ReactSelect 
                                        width='120px' 
                                        height='40px' 
                                        onChange={handlePanelStyleChange}
                                        name="selectLastDisplayConversation"
                                        value={selectLastDisplayConversation}
                                        dispatcher={setReviewSettings}
                                        options= {[
                                            {label:"days", value: 'days'},
                                            {label:"hours", value:'hours'},
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={6}>
                            <Typography variant="body2">Hide “Review” if star less than</Typography>
                        </Grid>
                        <Grid item md={6}>
                            <InputBox
                                name="minStarRating"
                                dispatcher={setReviewSettings}
                                value={minStarRating}
                                onChange={handlePanelStyleChange}
                                type="number"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
	)
}


const useStyles = makeStyles(() =>
	createStyles({
		input: {
            height: '35px',
            backgroundColor: '#fff',
            // marginTop: '4%',
            border: ' 0.5px solid rgba(0, 0, 0, 0.23)',
            width: '100%',
            borderRadius: '4px',
            padding: '0% 3%',
            fontSize: '13px'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            paddingBottom: '2%'
        },
	})
)

export default memo(FeaturesBox);
