import React, { useReducer, useEffect, useRef, useState, memo } from 'react';
import { Box, makeStyles, createStyles } from '@material-ui/core';
import ReviewNotificationCard from './ReviewNotificationCard';
import ReviewNotificationRules from './ReviewNotificationRules';
import NotifSettingsReducer, { initialState } from '../../NotifSettingsReducer';
import { useSelector } from 'react-redux';
import translate from 'translate';
import NotificationCardSkeleton from '../../../../Globals/GlobalComponents/NotificationCardSkeleton';
import NotificationRuleSkeleton from '../../../../Globals/GlobalComponents/NotificationRuleSkeleton';
import _ from 'lodash'


translate.engine = 'google';
// translate.key = 'AIzaSyDB2KZyXOUPBC3v0SWh7-vBzg_gTlpLc30';
translate.key = process.env.REACT_APP_GOOGLE_TRANSLATE_KEY;


const ReviewNotificationSetting  = (props) => {
    const classes = useStyles();
    const {
        handlePropertyChange, 
        notificationConfiguration, 
        ruleName,
        campaignInfo,
        notificationType,
        handlePanelStyleChange,
        handleLanguageChange,
        handleNotificationSubmit,
        loading,
        notifBoxBGColor,
        brandingLocked,
        onClose
    } = props;
    const [reviewSettings, setReviewSettings,] = useReducer(NotifSettingsReducer, initialState);
    const [gglReviewText, setGglReviewText] = useState("");
    const [trustpilotText, setTrustPilotText] = useState("");
    const [reviewioText, setReviewIoText] = useState("");
    const [stampedioText, setStampedIoText] = useState("");
    const [capterraText, setCapterraText] = useState("");
    const [facebookReviewText, setFacebookReviewText] = useState("");
    const [show, setShow] = useState(false);
    const [notifType, setNotifType] = useState('')

    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        },200)
    },[])

    useEffect(() => {
        setNotifType(reviewSettings.notificationDisplayApp);
    },[reviewSettings.notificationDisplayApp])
    
    //reviewSettings.notificationDisplayApp
   // const handleNotifType = (value) =>{
    //    setNotifType(value.value)    
   // }
    
    const notificationSettings = useSelector(state => (state.getIn(['NotificationSettings', 'notificationConfiguration'])))

    useEffect(() => {
        setReviewSettings({type: 'INIT', data: _.get(notificationSettings,'length') === 4 ? notificationSettings.find(item => item.notificationType.notificationName === ruleName) : ""})
    },[notificationSettings])

    const lastLangRef = useRef()



    const languageChangeHandler = (name, value, dispatcher) =>{
        translate(reviewSettings.gglReviewText, {from: reviewSettings.langName.language, to: value}).then(
            text => {
                setGglReviewText(text)
                handlePropertyChange('gglReviewText', text, setReviewSettings)
            }
        )
        translate(reviewSettings.facebookReviewText, {from: reviewSettings.langName.language, to: value}).then(
            text => {
                setFacebookReviewText(text)
                handlePropertyChange('facebookReviewText', text, setReviewSettings)
            }
        )
        translate(reviewSettings.trustpilotText, {from: reviewSettings.langName.language, to: value}).then(
            text => {
                setTrustPilotText(text)
                handlePropertyChange('trustpilotText', text, setReviewSettings)
            }
        )
        translate(reviewSettings.reviewioText, {from: reviewSettings.langName.language, to: value}).then(
            text => {
                setReviewIoText(text)
                handlePropertyChange('reviewioText', text, setReviewSettings)
            }
        )
        translate(reviewSettings.stampedioText, {from: reviewSettings.langName.language, to: value}).then(
            text => {
                setStampedIoText(text)
                handlePropertyChange('stampedioText', text, setReviewSettings)
            }
        )
        translate(reviewSettings.capterraText, {from: reviewSettings.langName.language, to: value}).then(
            text => {
                setCapterraText(text)
                handlePropertyChange('capterraText', text, setReviewSettings)
            }
        )

        handleLanguageChange(name, value, dispatcher)

    }

    useEffect(() => {
        setGglReviewText(reviewSettings.gglReviewText);
    },[reviewSettings.gglReviewText])

    useEffect(() => {
        setFacebookReviewText(reviewSettings.facebookReviewText);
    },[reviewSettings.facebookReviewText])

    useEffect(() => {
        setTrustPilotText(reviewSettings.trustpilotText);
    },[reviewSettings.trustpilotText])

    useEffect(() => {
        setReviewIoText(reviewSettings.reviewioText);
    },[reviewSettings.reviewioText])

    useEffect(() => {
        setStampedIoText(reviewSettings.stampedioText);
    },[reviewSettings.stampedioText])

    useEffect(() => {
        setCapterraText(reviewSettings.capterraText);
    },[reviewSettings.capterraText])



    const lastLang = lastLangRef.current

    return(
        show ?
            <Box>
                <Box style={{background: notifBoxBGColor}} className={classes.notificationBox} padding='5% 0%'>
                    <ReviewNotificationCard 
                        reviewSettings={reviewSettings}
                        gglReviewText={gglReviewText}
                        facebookReviewText={facebookReviewText}
                        stampedioText = {stampedioText}
                        notifType={notifType}
                    />
                </Box>
                <Box padding='6%'>
                    <ReviewNotificationRules
                        gglReviewText={gglReviewText}
                        facebookReviewText={facebookReviewText}
                        trustpilotText = {trustpilotText}
                        stampedioText = {stampedioText}
                        reviewioText = {reviewioText}
                        capterraText = {capterraText}
                        handleNotificationSubmit={handleNotificationSubmit}
                        loading={loading}
                        handlePropertyChange={handlePropertyChange}
                        setReviewSettings={setReviewSettings}
                        reviewSettings={reviewSettings}
                        campaignInfo={campaignInfo}
                        handlePanelStyleChange={handlePanelStyleChange}
                        handleLanguageChange={languageChangeHandler}
                        notificationType={notificationType.filter(item => item.notificationName === ruleName)}
                        notificationConfiguration={notificationConfiguration.filter(item => item.notificationType.notificationName === ruleName)}
                       
                        notifType={notifType}
                        brandingLocked={brandingLocked}
                        onClose={onClose}
                    />
                </Box>
            </Box>:
            
            <Box>
                <Box style={{background: notifBoxBGColor}} className={classes.notificationBox} padding='5% 0%'>
                    <NotificationCardSkeleton />
                </Box>
                <Box padding="6%">
                    <NotificationRuleSkeleton />
                </Box>
            </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        notificationBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '7%',
            position: 'sticky',
            top: '50px',
            zIndex: '9999',
            paddingBottom: '11%',
            transition: 'all 0.7s ease-in-out',
            backdropFilter: "blur(0.8px)"
        },
        loaderBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }
    })
)

export default memo(ReviewNotificationSetting);
