import React from 'react'
import { MuiThemeProvider } from '@material-ui/core';
import CookieTheme from '../../Resources/CookieTheme'
import CookieSideDrawer from '../../components/CookieConcent/CookieSideDrawer/CookieSideDrawer'
import CookieNavigation from '../../components/CookieConcent/CookieNavigation/CookieNavigation'
import UniversalToast from '../../Globals/GlobalComponents/UniversalToast';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';



const CookieContainer = (props) =>{
	const muiTheme = useTheme()
	console.log("muiTheme-----------", muiTheme)
    return(
		<MuiThemeProvider theme={CookieTheme}>
			<div style={{marginTop: '36px',}}>
				<CookieSideDrawer/>
				<UniversalToast/>
				<div style={{marginLeft: CookieTheme.custom.sideDrawer.width, padding:'24px'}}>
					{props.children}
				</div>
			</div>
		</MuiThemeProvider>
    )
}



export default CookieContainer