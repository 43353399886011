import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import logo from 'img/favicon.ico'
import { createStyles, makeStyles } from '@material-ui/core'

const PolicyPage = () => {
  const classes = useStyles()
  const [companyData, setCompanyData] = useState({ name: 'Influence', email: 'test@test.com' })

  return (
    <Grid container style={{ backgroundColor: '#EBEFF3' }}>
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: '#097FFF',
          color: '#fff',
          padding: 20,
          paddingBottom: 100,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={1} style={{display:'flex',alignItems:'center'}}>
            <img style={{ height: '40px', width: 'auto ' }} src={logo} />
          </Grid>
          <Grid item xs={12} md={7}>
            <h1 className={classes.heading1}>Privacy Policy Of {companyData.name}</h1>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
          >
            <button className={classes.buttonStyle}>We are powered by Influence</button>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={2} md={1}></Grid>
      <Grid item xs={12} md={8} style={{ marginTop: '-80px', padding: '20px 20px' }}>
        <Card style={{ minHeight: '500px', padding:   '24px' }}>
          <CardContent classes={{ root: classes.cardContentRoot }}>
            <div className={classes.lastUpdatedContainer}>
              <h4>Last Updated: </h4>
              <h4>October 30, 2020</h4>
            </div>
            <p className={classes.text2}>
              You can now log into Headway with any Google account, and we highly recommend it. Not
              only it's one less password to remember, but Google offers an extra level of security
              with two-factor authentication options. To connect your Google account, simply
              navigate to Settings Profile and select how you want to log into your account.
            </p>
            <h4 className={classes.heading4}>Heading</h4>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={3} style={{ padding: '0px 20px' }}>
        <h2 className={classes.heading2}>Contact Information</h2>
        <h3 className={classes.heading5}>Owner and Data Controller</h3>
        <p className={classes.text1}>address line 1</p>
        <p className={classes.text1}>address line 2</p>
        <p className={classes.text1}>address line 3</p>
        <h3 className={classes.heading5}>Contact Email:</h3>
        <p className={classes.text1}>{companyData.email}</p>
        {/* <Divider style={{ marginTop: '16px' }} light /> */}
        <p className={classes.text3}>{companyData.name} Policy By Influence</p>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    cardContentRoot: {
      padding: 31,
    },
    buttonStyle: {
      padding: '8px 21px',
      // width: '339px',
      backgroundColor: '#12344D',
      color: '#fff',
      fontSize: 12,
      fontWeight: 500,
      borderRadius: '5px',
      fontFamily: 'Open Sans',
      border: 'none',
      // cursor: 'pointer',
      '&:focus':  {
        outline:  'none',
      },
    },
    heading1: {
      fontSize: 26,
      color: '#fff',
      fontFamily: 'sans-serif',
      fontWeight: 'bold',
    },
    heading2: {
      fontWeight: '800',
      fontSize: 16,
      fontFamily: 'Open Sans',
      color: '#12344D',
    },
    heading4:  {
      fontWeight: '700',
      fontSize: 18,
      fontFamily: 'Open Sans',
      color: '#12344D',
    },
    heading5: {
      fontWeight: '700',
      fontSize: 14,
      color: '#12344D',
      fontFamily: 'Open Sans',
      margin: '20px 0 10px 0',
    },
    text1: {
      fontSize: 13,
      fontFamily: 'Open Sans',
      fontWeight: '400',
      color: '#000',
      margin: '5px 0',
    },
    text2: {
      fontSize: '12.3px',
      fontWeight: '600',
      fontFamily: 'Open Sans',
      color: 'rgba(0, 0, 0, 0.67)',
      margin: '16px 0',
      letterSpacing: '-0.3px',
      lineHeight: '1.7',
    },
    text3: {
      fontSize: 12,
      fontWeight: '300',
      fontFamily: 'Open Sans',
      color: '#000',
      margin: '5px 0',
    },
    lastUpdatedContainer: {
      display: 'flex',
      alignItems: 'baseline',
      gap: '8px',
      color: '#12344D',
      fontSize: 14,
      fontFamily: 'Open Sans',
      '&>h4': {
        margin: '0',
      },
      '&>:first-child': {
        fontWeight: '700',
      },
      '&>:last-child': {
        fontWeight: '600',
      },
    },
  })
)

export default PolicyPage
