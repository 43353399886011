import React, {useState, memo} from 'react'
import { Box, makeStyles, createStyles, Grid, Typography, Popover } from '@material-ui/core'
import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
import { SketchPicker } from 'react-color'



const Textbox = (props) =>{

	const {
        handlePanelStyleChange, 
        setLiveSettings,
        fontWeight,
        fontFamily,
        color

    } = props

    const [fontColorPopper, setFontColorPopper] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [fontColorValue, setFontColorValue] = useState('rgb(0,0,0,0)')
    const classes = useStyles()

    const handleFontColorPopper = (event) => {
        if(fontColorPopper === false) {
            setFontColorPopper(true);
            setAnchorEl(event.currentTarget)
        }else {
            setFontColorPopper(false)
            setAnchorEl(null)
        }
    }

    const rgbSelector = (obj, stateName) =>{
        stateName(`rgba(${obj.r},${obj.g},${obj.b},${obj.a})`)
    }

	
	return(
		<Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container>
                <Grid item md={12}>
                    <Typography className={classes.boxTitle}>TEXT</Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item md={3}>
                            <Typography variant="body2">Font Style</Typography>
                        </Grid>
                        <Grid item md={3}>
                            <ReactSelect
                                placeholder=" Font weight"
                                value={fontWeight}
                                name="fontWeight"
                                dispatcher={setLiveSettings}
                                onChange={handlePanelStyleChange}
                                options= {[
                                    {label:"Normal", value:"normal"},
                                    {label:"Bold", value:'bold'}
                                ]}
                                className={classes.dropDownStyle}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <ReactSelect 
                                width='120px' 
                                height='35px' 
                                placeholder=" Font family"
                                value={fontFamily}
                                name="fontFamily"
                                onChange={handlePanelStyleChange}
                                dispatcher={setLiveSettings}
                                options= {[
                                    {label:"Default", value: 'inherit'},
                                    {label:"Monospace", value:'monospace'},
                                    {label:"Arial", value:'arial'},
                                    {label:"Georgia", value:'georgia'}
                                ]}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <div 
                                className={fontColorPopper ? classes.colorIconActive : classes.colorIcon} 
                                onClick={handleFontColorPopper} 
                                style={{backgroundColor: `rgb(${color.r}, ${color.g}, ${color.b})`}}></div>
                            <Popover
                                open={fontColorPopper} 
                                onClose={handleFontColorPopper} 
                                anchorOrigin={{vertical: 'bottom',horizontal: 'center',}} 
                                transformOrigin={{vertical: 'top',horizontal: 'right',}}
                                anchorEl={anchorEl}
                            >
                                <SketchPicker 
                                    color={color} 
                                    onChange={(e) => {
                                        handlePanelStyleChange("color",e.rgb, setLiveSettings)
                                        rgbSelector(e.rgb, setFontColorValue)
                                    }} 
                                />
                            </Popover>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
	)
}


const useStyles = makeStyles(theme =>
	createStyles({
		colorIcon: {
            width: '35px',
            height: '35px',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        colorIconActive: {
            width: '35px',
            height: '35px',
            border: '0.5px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            // paddingBottom: '2%'
        },
        dropDownStyle:{
            color:'#000'
        }
	})
)

export default memo(Textbox);
