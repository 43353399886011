import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, makeStyles, createStyles, Dialog, Typography, Button, Card, Paper, Tabs, Tab, Slide, CircularProgress, useRadioGroup } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import { upgradePlan } from './action'
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { getAddedCard } from './action'
import { Elements } from 'react-stripe-elements';
import UpdateCard from './UpdateCard'






const PricingDialog1 = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	// const allPlans = useSelector(state => (state.getIn(['profile', 'allPlans'])))
	const upgradePlanResponse = useSelector(state => (state.getIn(['UpgradePlan', 'UpgradePlan'])))
	const profile = useSelector(state => (state.getIn(['profile', 'profile'])))
	const [updateCardDialogStatus, setUpdateCardDialogStatus] = useState(false)
	const [plans, setPlans] = useState([])
	const [value, setValue] = useState(0)
	const [toaster, setToaster] = useState(false)
	//const [open, setOpen] = useState(false)
	const [isCardAdded, setIsCardAdded] = useState(false)
	const [loading, setLoading] = useState(false)
	const [indexLoading, setIndexLoading] = useState(0)
	const [toastAddCard, setToasterAddCard] = useState(false)
	const [toastUpdateCard, setToastUpdateCard] = useState(false)
	const [pricingDialogStatus, setPricingDialogStatus] = useState(false);
	const [addedCard, setAddedCard] = useState(false)
	const user = useSelector(state => (state.getIn(['auth', 'user']))) || []
	useEffect(() =>{
		setPricingDialogStatus(true)
		fetchCard()
	},[])

	const fetchCard = () =>{
        getAddedCard()
            .then(res=>{
                setIsCardAdded(res.err)
				setAddedCard(res)
            })
        
    }
	
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleToaster = () => {
		setToaster(!toaster)
	}
	const handleToaster2 = () =>{
		
		setToasterAddCard(!toastAddCard)
	}
	const handleClose = () =>{
		setPricingDialogStatus(false)
		props.history.goBack()
	}

	// useEffect(() => {
	// 	upgradePlanResponse && setToaster(true)
	// }, [upgradePlanResponse])

	const enterprisePlan_m = [
		"Unlimited Notifications",
		"Unlimited Domains",
		"Custom Settings",
		"All Notification Types",
		"Priority Support",
		"Removable Branding",
		"Teammate Accounts",
	]

	const advancedPlan_m = [
		"Unlimited Notifications",
		"Unlimited Domains",
		"Custom Settings",
		"All Notification Types",
		"Priority Support",
		"Removable Branding",
		"Teammate Accounts",
	]

	const growthPlan_m = [
		"Unlimited Notifications",
		"Unlimited Domains",
		"Custom Settings",
		"All Notification Types",
		"Priority Support"
	]

	const startupPlan_m = [
		"Unlimited Notifications",
		"Unlimited Domains",
		"Custom Settings",
		"All Notification Types",
		"Priority Support"
	]

	const monthlyPlanDetails = [
		{
			name: "Enterprise",
			price: "$215",
			pointers: enterprisePlan_m,
			interval: "month",
			uniqueVisitors: '500000',
			id: "price_1H89D3CkWzi7OrXWlwuVb6uG"
		},
		{
			name: "Advanced",
			price: "$129",
			pointers: advancedPlan_m,
			interval: "month",
			uniqueVisitors: '200000',
			id: "price_1HiD7PCkWzi7OrXWLMmDDNPn"
		},
		{
			name: "Growth",
			price: "$69",
			pointers: growthPlan_m,
			interval: "month",
			uniqueVisitors: '50000',
			id: "price_1HiD20CkWzi7OrXWvCZVywSQ"
		},
		{
			name: "Startups",
			price: "$21",
			pointers: startupPlan_m,
			interval: "month",
			uniqueVisitors: '10000',
			id: 'price_1HQbLBCkWzi7OrXWJob8bWxU'
		}
	]


	const yearlyPlanDetails = [
		{
			name: "Enterprise",
			price: "$2150",
			pointers: enterprisePlan_m,
			interval: "year",
			uniqueVisitors: '500000',
			id: 'price_1H89EJCkWzi7OrXWFIhPQBRL'
		},
		{
			name: "Advanced",
			price: "$1050",
			pointers: advancedPlan_m,
			interval: "year",
			uniqueVisitors: '200000',
			id: "price_1H9qkECkWzi7OrXW6z1Vz220"
		},
		{
			name: "Growth",
			price: "$520",
			pointers: growthPlan_m,
			interval: "year",
			uniqueVisitors: '50000',
			id: "price_1H9qZ8CkWzi7OrXWUbIVZRGJ"
		},
		{
			name: "Startups",
			price: "$190",
			pointers: startupPlan_m,
			interval: "year",
			uniqueVisitors: '10000',
			id: "price_1H89N4CkWzi7OrXW1y4N40Ye"
		},
	]



	const handleUpgradePlan = (id, quota, name) =>{
		setLoading(true)
		//upgradePlan(id, quota, name , setUpdateCardDialogStatus,setLoading,setToaster)
		dispatch(upgradePlan(id, quota, name, setLoading,setToaster,setUpdateCardDialogStatus,setToastUpdateCard))
	}

	// const checkAddedCard = () => {
	// 	getAddedCard()
	// 		.then(res => {
	// 			if (!res.last4) {
	// 				setIsCardAdded(true)
	// 				setIndexLoading(index)
	// 				//setUpdateCardDialogStatus(true)
	// 			}
	// 		})
	// }

	// useEffect(() => {
	// 	checkAddedCard()
	// }, [])

	const handleClose2 = () => {
		setUpdateCardDialogStatus(false)
	}

	const handleToaster3 = () => {
		setToastUpdateCard(false)
	}

	

	const checkAddedCard = (index, id, quota, name) => {
		getAddedCard()
			.then(res => {
				if (!res.last4) {
					setToasterAddCard(true)
					setUpdateCardDialogStatus(true)
					setIndexLoading(index)
					setLoading(false)
					setIsCardAdded(false)
				}
				else{
					setIndexLoading(index)
					setLoading(true)
					handleUpgradePlan(id, quota, name)
					setIsCardAdded(true)
				}
			})
	}



	return (
		<Dialog
		open={pricingDialogStatus}
		disableEscapeKeyDown={true}
		onClose={handleClose}
		fullScreen
		style={{zIndex:'3600'}}
	>

			<Elements>
				<UpdateCard disableBackdropClick={true} addingNewCard={isCardAdded} open={updateCardDialogStatus} handleClose={handleClose2} addedCard={addedCard} />
			</Elements>


			<button className={classes.closeBtn} onClick={handleClose}>+</button>

			<Paper style={{ width: 'fit-content', margin: '5% auto' ,marginTop:'8%' }} elevation={0}>
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					centered
					width="100px"
				>
					<Tab className={value === 0 ? classes.activeTab : classes.tab} label="Monthly" />
					<Tab className={value === 1 ? classes.activeTab : classes.tab} label="Yearly" />
				</Tabs>
			</Paper>

			<Box className={classes.cardContainer} >
				
				<Snackbar
					open={toaster}
					autoHideDuration={7000}
					// key={campaignInfo._id} 
					onClose={handleToaster}
					anchorOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
					className={classes.positionToast}
				
				>
					{
						upgradePlanResponse &&
						<Alert variant="filled" style={{ background: 'black' }} severity="success">
							<div>{`${upgradePlanResponse.message.profile.plan.name} Plan Activated`}</div>
						</Alert>
					}

				</Snackbar>
				<Snackbar 
					open={toastAddCard}
					autoHideDuration={7000}
					// key={campaignInfo._id} 
					onClose={handleToaster2}
					anchorOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
					className={classes.positionToast}
					
					
				>
					{
						toastAddCard &&
							<Alert variant="filled" style={{background:'black'}} severity="success">
							<div>{`Add Card for Plan Upgrade`}</div>
							</Alert> 
					}
					
					
				</Snackbar>

				<Snackbar 
				open={toastUpdateCard}
				autoHideDuration={5000}
				// key={campaignInfo._id} 
				onClose={handleToaster3}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				className={classes.positionToast}
				
			>
				{
					toastUpdateCard &&
						<Alert variant="filled" style={{background:'black'}} severity="success">
						<div>{`Update Card for Plan Upgrade`}</div>
						</Alert> 
				}
				
				
			</Snackbar>
				

				{
					value === 0 ?

						monthlyPlanDetails.map((plan, index) =>


							<Card key={index} className={profile.plan ? (plan.id === profile.plan?.id ? classes.activeCardStyle : classes.cardStyle) : classes.cardStyle}>
								{/*profile.plan && (plan.id === profile.plan.id) ?
									<span className={classes.cPBAckground}><Typography className={classes.currentPlan}>CURRENT PLAN</Typography> </span>
									:
									<span><Typography></Typography> </span>


								*/}

								<Box className={classes.cardHeader}>
									<Typography className={classes.titleStyle}>{plan.name}</Typography>
									<Typography className={classes.priceStyle}>
										{plan.price}
									</Typography>
								</Box>
								<Box className={classes.visitorsBox}>
									<Typography style={{ margin: '0' }} className={classes.uniqueVisitorsText}>
										{plan.uniqueVisitors} unique visitors per month
									</Typography>
								</Box>
								<Box className={classes.pointersContainer}>
									{
										plan.pointers.map((point, ind) =>
											<Typography key={ind} className={classes.pointerStyle}>
												<CheckIcon style={{ paddingRight: '5px', color: '#2c66c3' }} fontSize="small" />
												{point}
											</Typography>
										)
									}
								</Box>
								{
									profile.plan && (plan.id === profile.plan?.id) ?
										<Button disabled className={classes.activePlanButton}>
											Active Plan
										</Button> :
										<Button className={classes.switchToPlanButton} onClick={() => checkAddedCard(index,plan.id, plan.uniqueVisitors, plan.name)}>
										{
											loading && indexLoading === index  ?
												<CircularProgress size={19} style={{color: '#fff', position: 'relative'}}/>:
											'Switch To Plan'
										}
										</Button>
								}
							</Card>

						) :

						yearlyPlanDetails.map((plan, index) =>
							<Slide key={index} in={value === 1 ? true : false} direction="left" mountOnEnter unmountOnExit>
								<Card className={plan.id === profile.plan?.id ? classes.activeCardStyle : classes.cardStyle}>
								{profile.plan && (plan.id === profile.plan?.id) ?
									<span className={classes.cPBAckground}><Typography className={classes.currentPlan}>CURRENT PLAN</Typography> </span>
									:
									<span><Typography></Typography> </span>


								}
									
									<Box className={classes.cardHeader}>
										<Typography className={classes.titleStyle}>{plan.name}</Typography>
										<Typography className={classes.priceStyle}>
											{plan.price}
										</Typography>
									</Box>
									<Box className={classes.visitorsBox}>
										<Typography className={classes.uniqueVisitorsText}>
											{plan.uniqueVisitors} unique visitors per month
										</Typography>
									</Box>
									<Box className={classes.pointersContainer}>
										{
											plan.pointers.map((point, ind) =>
												<Typography key={ind} className={classes.pointerStyle}>
													<CheckIcon style={{ paddingRight: '5px' }} fontSize="small" />
													{point}
												</Typography>
											)
										}
									</Box>
									{
										profile.plan && (plan.id === profile.plan?.id)?
											<Button disabled className={classes.activePlanButton}>
												Active Plan
											</Button> :
											
											<Button className={classes.switchToPlanButton} onClick={() => checkAddedCard(index, plan.id, plan.uniqueVisitors, plan.name)}>
											{
												loading && indexLoading === index ?
													<CircularProgress size={19} style={{color: '#fff', position: 'relative'}}/>:
												'Switch To Plan'
											}
											</Button>
									}
								</Card>
							</Slide>
						)
				}

			</Box>

		</Dialog>
	)
}


const useStyles = makeStyles(theme =>
	createStyles({

		positionToast:{
			marginTop:'40px'
		},
		activePlanButton:{
			backgroundColor: '#082a52', 
			padding: '4% 15%', 
			marginBottom: '7%',
			fontFamily:'revert'
		},
		switchToPlanButton:{
			backgroundColor: '#dae1e7',
			color: '#666',
			padding: '4% 15%',
			marginBottom: '7%',
			fontFamily:'revert',
			"&:hover":{
				backgroundColor: '#dae1e7',
				color: '#666',
			}
		},
		uniqueVisitorsText:{
			color:'#666',
			paddingBottom:'8%',
			fontFamily:'revert'
		},
		currentPlan: {
			backgroundColor: '#082a52',
			marginTop: '0% !important',
			color: '#fff',
			letterSpacing:'1px'
		},
		cardContainer: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			margin: '5% 15%',
			//paddingTop: '4%'
			marginTop:'0%'
		},
		cardStyle: {
			paddingBottom: '20px',
			width: '280px',
			//border: '1px solid rgba(198, 198, 198, 0.8)',
			marginRight: '20px',
			textAlign: 'center',
			boxShadow: '1px 2px 7px 4px rgba(128, 128, 128, 0.2)',
			'&:hover':{
				boxShadow: '0 10px 20px 0 rgba(0,43,86,.15)',
				borderColor: '#50a3f5'
			}
		},
		activeCardStyle: {
			backgroundColor: '#fff',
			paddingBottom: '20px',
			width: '280px',
			marginRight: '20px',
			textAlign: 'center',
			border: '1px solid #2c66c3',
			//boxShadow: '2px 4px 14px 8px rgba(128, 128, 128, 0.2)',
			boxShadow: '0 4px 20px -8px #2c66c3'
		},
		cardHeader: {
			textAlign: 'center',
			padding: '20px 0',
			color: '#2c66c3',
			paddingBottom: '0',
			backgroundColor: '#fff'
		},
		titleStyle: {
			fontSize: '15px',
			fontWeight: '500',
			letterSpacing: '3px',
			textTransform: 'uppercase',
			margin: '0',
			color: '#666',
			marginBottom: '0',
		},
		visitorsBox: {
			// color: '#c6c6c6',
			borderBottom: '1px solid #c6c6c6',
			padding: '5px',
			textAlign: 'center',
			color:'#666'
		},
		priceStyle: {
			fontSize: '28px',
			fontWeight: '400',
			margin: '0',
			color: '#2c66c3',
			padding:'3% 0%'
		},
		moStyle: {
			fontSize: '18px',
			color: '#45a4ec',
			fontWeight: '400',
			marginLeft: '12px'
		},
		pointersContainer: {
			marginTop: '20px',
			height: '250px',
			textAlign: 'left',
			//paddingLeft: '10px',
			fontSize: '14px',
			padding: '5% 10%'
			
		},
		pointerStyle: {
			fontSize: '15px',
			//color: 'rgba(9,30,66,.87)',
			padding: '2px 5px',
			marginTop: '0',
			marginBottom: '0',
			color:'#082a52',
			letterSpacing:'1px',
			fontWeight:'400',
			fontFamily:'revert'
		},
		closeBtn: {
            fontSize:'26px !important',
            textTransform:'initial',
			borderRadius:'4px',
			color:'#666',
            transform:'rotate(45deg)',
            "&:hover": {
                backgroundColor:'transparent',
                color:'#000'
            },
            "&:focus":{
                outline: 'none'
            },
			float: 'right',
			cursor: 'pointer !important',
			position: 'absolute',
			right: '20px',
			top: '50px',
			fontWeight: '400',
			height: '55px',
			width: '55px',
			backgroundColor:'transparent',
			border: 'none'
		},
		tab: {
			fontSize: '16px',
			width: '50px',
			padding: '0px',
			marginRight: '0px ',
			marginLeft: '0px',
			color: "#2c66c3",
			backgroundColor: '#fff',
			borderRadius: '9px 9px 0 0',
			transition: '0.3s',
			fontFamily:'revert'
		},
		activeTab: {
			backgroundColor: '#082a52',
			color: '#fff !important',
			fontSize: '16px',
			display: 'flex',
			padding: '0px',
			marginRight: '0px ',
			marginLeft: '0px',
			transition: '0.3s',
			fontFamily:'revert'
		}
	})
)

export default withRouter(PricingDialog1)
