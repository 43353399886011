import React, { memo, useState, useEffect } from 'react'
import { Box, makeStyles, createStyles, Grid, Typography } from '@material-ui/core'
import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
import { languageData } from '../../../../Globals/GlobalComponents/languages';
import CustomHelpIcon from '../../../../Globals/GlobalComponents/CustomHelpIcon';
import {HelpQuestion} from '../../../../img'
import SelectBox from '../../../SelectBox'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';



const BasicBox = (props) =>{
	
	const {
        setReviewSettings, 
        handleLanguageChange, 
        handlePropertyChange,
        langName,
        notificationSize,
        handleNotifType,
        notificationDisplayApp
    } = props


	const classes = useStyles()
	return(
		<Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container spacing={2} direction="column">
                <Grid item md={12}>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid item md={3}>
                            <Typography variant="body2">
                                Language
                                {/* <a href={helpArticleLinks.faq.notification.language} target="_blank">
									<img src = {HelpQuestion}  style={{ height: "11px",marginLeft:"2%"}}/></a> */}
                                <InfluenceHelpIcon href={helpArticleLinks.faq.notification.language} fontSize="tiny"/>
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <ReactSelect 
                                name="langName"
                                options={languageData}
                                value={langName.language}
                                dispatcher={setReviewSettings}
                                onChange={handleLanguageChange}
                            
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid item md={3}>
                            <Typography variant="body2">
                                Review Type
                                {/* <a href="https://support.useinfluence.co/article/1414" target="_blank">
                                <img src = {HelpQuestion}  style={{ height: "11px",marginLeft:"2%"}}/></a> */}
                                <InfluenceHelpIcon href="https://support.useinfluence.co/article/1414" fontSize="tiny" />
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <ReactSelect 
                                value={notificationDisplayApp? notificationDisplayApp: "google"} 
                                name="notificationDisplayApp"
                                dispatcher={setReviewSettings}
                                onChange={handlePropertyChange}
                                options={[
                                    {label:"Google", value:"google"},
                                    {label:"Facebook", value:"facebook"},
                                    {label:"Trustpilot", value:"trustpilot"},
                                    //{label:"Review", value:"reviewio"},
                                    {label:"Stamped", value:"stamped"},
                                    {label:"Capterra", value:"capterra"}

                                ]}
                            />
                        </Grid>
                        {/*<Grid item md={3}>
                            <Typography variant="body2">Notification Size</Typography>
                        </Grid>
                        <Grid item md={3} >
                            <ReactSelect 
                                width='120px' 
                                height='35px' 
                                value={notificationSize}
                                name="notificationSize"
                                dispatcher={setReviewSettings}
                                onChange={handlePropertyChange}
                                options={[
                                    {value:"default", label:"Default"},
                                    {value:"large", label:"Large"}
                                ]}
                            />
                        </Grid>*/}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
	)
}


const useStyles = makeStyles(theme =>
	createStyles({

	})
)

export default memo(BasicBox);
