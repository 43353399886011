import React, { memo } from 'react';
import { Box, makeStyles, createStyles, Grid, Typography, Checkbox } from '@material-ui/core';
import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
import InputBox from '../../../../Globals/GlobalComponents/InputBox';
import CustomHelpIcon from '../../../../Globals/GlobalComponents/CustomHelpIcon';



const FeaturesBox  = (props) => {
    const classes = useStyles();

    const {
        recentText1,
        orderText,
        isDisplayPurchase,
        isHideLastname,
        isDisplaySignup,
        recentNumber,
        recentConv,
        selectLastDisplayConversation,
        hideAnonymousConversion,
        otherText,
        contentText,
        extraText,
        handleInputChange,
        setAnnouncementSettings,
        handlePropertyChange,
        handlePanelStyleChange,
        announcementHeaderText,
        announcementSubText
    } = props;

    return(
        <Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Typography className={classes.boxTitle}>Features</Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={3}>
                            <Typography variant="body2">Heading</Typography>
                        </Grid>
                        <Grid item md={9}>
                            <InputBox
                                placeholder="Heading"
                                name="announcementHeaderText"
                                value={announcementHeaderText}
                                dispatcher={setAnnouncementSettings}
                                onChange={handleInputChange}
                              
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item md={3}>
                            <Typography variant="body2">Sub Text</Typography>
                        </Grid>
                        <Grid item md={9} style={{paddingLeft: '4px'}}>                            
                            <InputBox
                                name="announcementSubText"
                                dispatcher={setAnnouncementSettings}
                                value={announcementSubText}
                                placeholder="Other"
                                onChange={handleInputChange}
                                maxLength={200}
                                
                            />
                        </Grid>
                    </Grid>
                </Grid>
               
               
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        input: {
            height: '35px',
            //width:'120px',
            backgroundColor: '#fff',
            marginTop: '4%',
            border: ' 1px solid hsl(0,0%,80%)',
            borderRadius: '4px',
            padding: '0% 3%',
            width:'100%'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            paddingBottom: '2%'
        },
    })
)

export default memo(FeaturesBox);