import React from 'react'
import { Box, makeStyles, createStyles, Paper, Typography, Checkbox, Button, CircularProgress, Input,Card,withStyles,Grid } from '@material-ui/core'
import PixelPage from '../../InstallPixelPage/PixelPage'
const InstallCode = () => {
const classes = useStyles()

	return (
		<div>
        <PixelPage />
        </div>
	)
}

const useStyles = makeStyles(theme =>
	createStyles({

        UpperParent: {
            display:'flex',
            flexDirection:'row'

        },
        LowerParent: {
            display:'flex',
            flexDirection:'column'

        }

        
    }))





export default InstallCode