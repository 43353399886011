import React from 'react';
import { call, put, fork, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { browserHistory } from 'react-router';
import * as Sentry from '@sentry/browser';
import Popup from 'react-popup';

import * as api from 'services/api';
import * as actions from 'ducks/campaign';
import { load, loaded } from 'ducks/loading';
import { fetchUserSuccess } from 'ducks/auth';
import { fetchSuccess } from 'ducks/pageurl';
import { base } from 'services/api';

// const errorMessge = 'Please try again or refresh!';

const toastConfig = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 2000,
  className: 'toast-style'
};

function* fetch() {
  try {
    yield put(load());
    const res = yield call(api.GET, 'campaign');
    if(res.error)
      console.log(res.error);
    else
      yield put(actions.fetchSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // }
    // else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* create(action) {
  try {
    yield put(load());
    const campaignData = {
      campaign: action.campaign,
      pages: action.pages
    };
    const res = yield call(api.POST, 'campaign', campaignData);
    if(res.error) {
      if(res.message == 'Invalid domain')
        yield toast.error('This website url is Invalid.', toastConfig);
      else {
        Sentry.captureException(res.error);
        yield toast.error('Campaign already exists for this website', toastConfig);
      }
    } else {
      yield put(actions.fetchCampaign());
      yield put(actions.fetchCampaignInfoNew());
      if(res.updatedUser)
        yield put(fetchUserSuccess(res.updatedUser));
      yield put(actions.successCampaign(res.data, true));
      browserHistory.push(`/new/${res.data._id}`);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // }
    // else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* update(action) {
  try {
    yield put(load());
    const res = yield call(api.PUT, `campaign/${action.campaign.id}`, action.campaign);
    if(res.error)
      Sentry.captureException(res.error);
    else if(action.campaign.singleCampaign)
      yield put(actions.successCampaign(res));
    else
      yield put(actions.successUpdate(res, action.index));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // }
    // else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* remove(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `campaign/${action.campaignId}`);
    if(res.error)
      Sentry.captureException(res.error);
    else {
      yield put(actions.fetchCampaign());
      yield put(actions.fetchCampaignInfoNew());
      yield put(actions.popCampaign(action.index));
      Popup.create({
        title: 'Deleted!',
        className: 'delete-campaign',
        content: <div style={{padding: '30px 15px', fontSize: 'medium'}}>
          Your campaign has been successfully deleted.
        </div>,
        buttons: {
          right: [{
            text: 'Close',
            key: '⌘+s',
            className: 'btn btn-primary delete-btn',
            action: function () {
              browserHistory.push('/campaigns');
              Popup.close();
            }
          }]
        }
      }, true);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // }
    // else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* fetchCampaignsInfo() {
  try {
    yield put(load());
    const res = yield call(api.GET, 'campaign/user/info');
    if(res.error)
      Sentry.captureException(res.error);
    else
      yield put(actions.fetchDashboardSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // }
    // else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* fetchCampaignsInfoNew(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `campaign/user/newinfo?numDays=${action.numDays}`);
    if(res.error)
      Sentry.captureException(res.error);
    else
      yield put(actions.fetchDashboardSuccessNew(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // }
    // else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* fetchSubdomain(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `subdomain?campaign=${action.campaignId}`);
    if(res.error)
      Sentry.captureException(res.error);
    else
      yield put(actions.subDomainSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // }
    // else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* addSubdomain(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'subdomain', action.domain);
    if(res.error)
      Sentry.captureException(res.error);
    else
      yield put(actions.subDomainSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // }
    // else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* removeSubDomain(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `subdomain/${action.id}`);
    if(res.error)
      Sentry.captureException(res.error);
    else {
      yield put(fetchSuccess(res.notificationPaths, res.type));
      // yield put(actions.popSubDomain(action.index));
      yield put(actions.fetchSubdomain(res.campaignId));
    }

    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // }
    // else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* analyticsDownload(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'campaign/analytics/download', action.users);
    if(res.error)
      Sentry.captureException(res.error);
    else
      require('downloadjs')(base + res.path);
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // }
    // else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* campaignVerification() {
  try {
    yield put(load());
    const res = yield call(api.GET, 'campaign/user/verification');
    if(res.error)
      Sentry.captureException(res.error);
    else
      yield put(actions.verificationSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // }
    // else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* pixelVerification() {
  try {
    yield put(load());
    const res = yield call(api.GET, 'campaign/user/pixelVerify');
    if(res.error)
      Sentry.captureException(res.error);
    else
      yield put(actions.pixelVerificationSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
  }
}

export function* watchPixelVerification() {
  yield takeLatest(actions.PIXEL_VERIFICATION, pixelVerification);
}

export function* watchFetch() {
  yield takeLatest(actions.FETCH, fetch);
}

export function* watchCreate() {
  yield takeLatest(actions.CREATE, create);
}

export function* watchUpdate() {
  yield takeLatest(actions.UPDATE, update);
}

export function* watchRemove() {
  yield takeLatest(actions.REMOVE, remove);
}

export function* watchCampaignInfo() {
  yield takeLatest(actions.FETCH_CAMPAIGN_INFO, fetchCampaignsInfo);
}

export function* watchCampaignInfoNew() {
  yield takeLatest(actions.FETCH_CAMPAIGN_INFO_NEW, fetchCampaignsInfoNew);
}

export function* watchAddSubdomain() {
  yield takeLatest(actions.ADD_SUB_DOMAIN, addSubdomain);
}

export function* watchRemoveSubdomain() {
  yield takeLatest(actions.REMOVE_SUB_DOMAIN, removeSubDomain);
}

export function* watchFetchSubdomain() {
  yield takeLatest(actions.FETCH_SUB_DOMAIN, fetchSubdomain);
}

export function* watchAnalyticsDownload() {
  yield takeLatest(actions.ANALYTICS_DOWNLOAD, analyticsDownload);
}

export function* watchCampaignVerification() {
  yield takeLatest(actions.CAMPAIGN_VERIFICATION, campaignVerification);
}

export default function* rootSaga() {
  yield [
    fork(watchFetch),
    fork(watchCreate),
    fork(watchUpdate),
    fork(watchRemove),
    fork(watchCampaignInfo),
    fork(watchCampaignInfoNew),
    fork(watchAddSubdomain),
    fork(watchFetchSubdomain),
    fork(watchRemoveSubdomain),
    fork(watchAnalyticsDownload),
    fork(watchCampaignVerification),
    fork(watchPixelVerification)
  ];
}
