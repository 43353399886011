import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { Box, Typography, makeStyles, createStyles, Button, CircularProgress, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import cookieCategories from '../../../utilities/CookieCategories'
import SelectBox from '../../SelectBox'
import { saveProvidersCaegory } from "../actions";
// import { DialogActions, DialogBox, DialogButton, DialogContent, DialogTitle } from '../../../Globals/GlobalComponents/DialogBox';
import {  DialogBox } from '../../../Globals/GlobalComponents/DialogBox';
import TextInput from '../../../Globals/GlobalComponents/TextInput';
import { set } from 'lodash';




const CookieStep3 = (props) => {

    const {handleNext} = props
    const dispatch = useDispatch()
    const classes = useStyles()
    const [providersWithCatogory,setProvidersWithCatogory] = useState([])
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [error, setError] = useState({})
    const [disableProceed, setDisableProceed] = useState(true)
    const providers = useSelector(state=>(state.getIn(['cookieCampaignCreationProcess', 'rawCookieProviders']))) || []
    const currentCampaign = useSelector(state =>(state.getIn(['cookieCampaigns','openedCookieCampaign'])))


    useEffect(()=>{
        if(providersWithCatogory !== undefined){
            const arr = providersWithCatogory.map(provider => {
                return provider['type']
            })
            setDisableProceed(arr.includes('uncategorised'))
        }
    },[providersWithCatogory])


        
    useEffect(() => {
        if('error' in providers){
            setError(providers)
        }else{
            setProvidersWithCatogory(arrayToObject(sortProviders(providers)))
        }
    }, [providers])


    /**
     * Change type of the provider in array  and return new array
     * @param {string} provider provider of which type needs to change 
     * @param {string} newVal new type of provider
     * @param {Array<Object>} oldState state which needs to update (state before onChange)
     */
    const updateCategories =(provider, newVal, oldState)=>{
        let arr = [...oldState]
        arr.find((ar)=> ar.provider === provider).type = newVal
        console.log({arr})
        setProvidersWithCatogory(arr)
        // return arr
    }
    
    
    /**
     * Convert array of strings of providers into array of objects
     * @param {Array} arr array of strings of providre URLs
     */
    const arrayToObject = (arr) =>{
        let ar = []
        for (const len in arr){
            if(arr[len] !== null){
                ar.push({provider: arr[len], type: 'uncategorised'})
            }
        }
        return ar
    }


    /**
     * Sortout the `null`, invalid urls and hardcoded strings
     * @param {Array} providers Array of string of providers
     * @returns Array of strings containing valid sctipt urls
     */
    const sortProviders = (providers) =>{
        const uniq = [...new Set(providers)]
        const uniq1 = uniq.filter((item)=>{
            return item !== null && 
                item.includes('.') && 
                !item.includes('cdn.useinfluence') &&
                !item.includes('wasabisys') &&
                !item.includes('fontawesome')
        })
        return uniq1
      }


    /**
     * Save providers and categories  
     * It'll throw error if categories of all the providers isn't selected.
     */
    const saveProviders  = () =>{
        console.log('asdfa--',providersWithCatogory)
        if(disableProceed){
            dispatch({type:'FIRE_TOASTER', payload:{type:'error', message:'Please select catogories for all providers'}})
        }else{
            setLoading(true)
            dispatch(saveProvidersCaegory(currentCampaign, providersWithCatogory,setLoading,pushToSettings))
        }
    }

    const pushToSettings = () =>{
        setLoading(false)
        browserHistory.push(`/cookie-monster/${currentCampaign._id}/appearance`)
    }

    const deleteProviderHandler = (index,provider) =>{
        const newData = providersWithCatogory.filter((item)=>item.provider !== provider)
        setProvidersWithCatogory(newData)
    }


    // useEffect(()=>{
    //     console.log('deleteProviderHandler', providersWithCatogory)
    // },[providersWithCatogory])

    const handleModal = () =>{
        setOpenModal(prevState=> !prevState)
    }

    const handleSaveNewProvider = (provider, type) =>{
        setProvidersWithCatogory([...providersWithCatogory, {provider, type:'uncategorised'}])
        handleModal()
    }
   
    
    return (
        <Box>
            <Box style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Typography variant="h5" textAlign="center">
                    Categorization
                </Typography>
                <Button variant="outlined" onClick={handleModal} >Add Custom Provider</Button>
            </Box>
            <Typography style={{marginTop:'2rem'}}>
                Categorize scan results
            </Typography>
            <Box style={{padding:'10px 50px'}}>
                {
                    error.hasOwnProperty('error')?
                    <Typography>Wrong URL</Typography>:
                    providersWithCatogory ?
                        providersWithCatogory.map((item, index)=>(
                            <Box key={index} style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                <Typography>{item.provider}</Typography>
                                <Box style={{display:'flex', alignItems:'center'}}>
                                    <SelectBox
                                        options={cookieCategories}
                                        onChange={(e)=>updateCategories(item.provider, e.value, providersWithCatogory)}
                                        value={item.type}
                                        className={classes.selectInputStyle}
                                    />
                                    <span onClick={()=>{deleteProviderHandler(index, item.provider)}} className={classes.closeBtn}>+</span>
                                </Box>
                            </Box>
                        )):
                    <Box>loading......</Box>
                }
            </Box>

            <Box style={{textAlign:'center'}}>
                <Button variant="contained" disabled={loading || error.error || providersWithCatogory.length == 0 } style={{ position:'relative'}} onClick={saveProviders}>
                    Save and proceed
                    {
                        loading &&
                            <CircularProgress size={19} style={{position:'absolute', top:'5px', left:'35%', color:'#fff'}}/>
                    } 
                </Button>
            </Box>
            
            <AddNewProviderPopup open={openModal} onClose={handleModal} handleSaveNewProvider={handleSaveNewProvider}/>

        </Box>
    )
}


const useStyles = makeStyles(((theme)=>
    createStyles({
        selectInputStyle:{
            width:'140px'
        },
        closeBtn:{
            transform:'rotate(45deg)',
            fontSize:'1.2rem',
            color:theme.palette.grey[500],
            marginLeft:'10px',
            cursor:'pointer'
        },
        // dialogContentContainer:{
        //     '& >p':{
        //         '& $selecteInputStyle':
        //     }
        // }
        dialogContainer:{
            overflow:'visible'
        },
        dialogPaper: {
            width: '850px'
        },
        dialogContentContainer:{
            display:'flex',
            alignItems:'center',
            '& $selectInputStyle':{
                '& .css-9lj2x2':{
                    zIndex:'999999'
                }
            }
        }
    })
))



export default CookieStep3




const AddNewProviderPopup = ({open, onClose, handleSaveNewProvider}) =>{
    const classes = useStyles()
    const [selectedType, setSelectedType] = useState("")
    const [enteredUrl, setEnteredUrl] = useState("")

    const handleInput = (e)=>{
        setEnteredUrl(e.target.value)
    }

    const handleChangeSelect = (e) =>{
        setSelectedType(e.value)
    }
    


    return(
        <DialogBox maxWidth="md" classes={{paperFullWidth:classes.dialogContainer, paper: classes.dialogPaper}} open={open} onClose={onClose}>
            <DialogTitle>Add your own custom provider</DialogTitle>
            <DialogContent style={{ overflowY: 'visible' }} className={classes.dialogContentContainer}>
                <TextInput placeholder="Enter Provider URL" value={enteredUrl} onChange={handleInput}/>
                {/* <SelectBox
                    options={cookieCategories}
                    onChange={(e)=>setSelectedType(e.value)}
                    value={selectedType}
                    className={classes.selectInputStyle}
                    placeholder="Select Category"
                    defaultValue={{value:'uncategorised', label:'Uncategorised'}}
                /> */}
            </DialogContent>
            <DialogActions>
                <Button actionType={"primary"} variant="contained"  onClick={()=>handleSaveNewProvider(enteredUrl, selectedType)}>Save</Button>
                <Button actionType={"secondary"} variant="outlined" onClick={onClose}>Cancel</Button>
            </DialogActions>
        </DialogBox>
    )
}