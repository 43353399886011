import { call, put, fork, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

import * as api from 'services/api';
import * as actions from 'ducks/report';
import { load, loaded } from 'ducks/loading';

const toastConfig = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 2000,
  className: 'toast-style'
};

const errorMessge = 'Please try again or refresh!';

function* fetch(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `report/${action.userId}?duration=${action.duration}`);
    if (res.error)
      console.log(res.error);
    else
      yield put(actions.fetchSuccess(res));
    yield put(loaded());
    localStorage.setItem('durationType',action.duration);
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

export function* watchFetch() {
  yield takeLatest(actions.FETCH, fetch);
}

export default function* rootSaga() {
  yield [
    fork(watchFetch)
  ];
}
