import { Map } from 'immutable';

const action = name => `/breadcrumb/${name}`;
const SET_BREAD_CRUMBS = action('SET_BREAD_CRUMBS');

export const setBreadCrumbs = (component, deep) => ({ type: SET_BREAD_CRUMBS, component, deep });

const initialState = Map({
  breadcrumb: [{
    name: 'Home',
    path: '/campaigns'
  }],
});

const breadcrumb = (state = initialState, action) => {
  switch (action.type) {
    case SET_BREAD_CRUMBS:
      if(action.deep)
        return state.set('breadcrumb', JSON.parse(JSON.stringify(action.component)));
      else
        return state.set('breadcrumb', action.component);
    default:
      return state;
  }
};

export default breadcrumb;
