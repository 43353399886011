import React, {useState} from 'react'
import { Box, makeStyles, createStyles, Typography } from '@material-ui/core'
import copy from 'copy-to-clipboard';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import FileCopyIcon from '@material-ui/icons/FileCopy';





const AweberAuthCode = (props) =>{

	const classes = useStyles()
	const [openToaster, setOpenToaster] = useState(false)

	const copyCodeHandler = () =>{
		copy(props.location.query.code)
		setOpenToaster(true)
	}
	
	return(
		<Box style={{padding: '5%',}}>
			<Typography style={{color: '#98a6ad', fontSize: '1.1em'}}>Authorization Code:</Typography>
			<Box className={classes.codeLine} onClick={copyCodeHandler}>
				<Typography>
					<FileCopyIcon style={{color: 'inherit', marginRight: '15px'}}/>
					{props.location.query.code}
				</Typography>
				
			</Box>

			<Snackbar 
				open={openToaster}
				autoHideDuration={3000} 
				onClose={()=>setOpenToaster(false)}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
			>
				<Alert variant="filled" style={{background:'black'}}>
					<div style={{display:'flex',width:'auto'}}>
						<div style={{fontSize:'11x',paddingLeft:'6px',fontWeight:'400'}}>Code Copied to Clipboard</div>
					</div>
				</Alert>
			</Snackbar>
		</Box>
	)
}


const useStyles = makeStyles(() =>
	createStyles({
		codeLine:{
			padding: '15px 20px', 
			marginTop: '20px', 
			border: '1px solid #d9e3e9', 
			backgroundColor: '#edefff', 
			textAlign: 'center', 
			borderRadius: '5px',
			cursor: 'pointer',
			color: '#495057',
			'&:hover':{
				border:'1px solid #c6c6c6'
			}
		}
	})
)

export default AweberAuthCode
