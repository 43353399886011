import {GET_CAMPAIGN_INFO, DELETE_CAMPAIGN_INFO,PUT_TOGGLE_BUTTON_CAMPAIGN_INFO} from './action';
import { fromJS } from 'immutable';
import { CREATE_GOAL } from '../../components/Goals/action';

const initialState = fromJS({});

export const CampaignInfo = (state = initialState, action) => {
    
    switch(action.type) {
        //get request data saved in reducer
        case GET_CAMPAIGN_INFO :
            return state.set('campaignInfo', action.payload)
        //filter out the deleted object from reducer
        case DELETE_CAMPAIGN_INFO :
            return state.set('campaignInfo',state.get('campaignInfo').filter(state => state._id !== action.payload._id));
            //update the status state in reducer
        case PUT_TOGGLE_BUTTON_CAMPAIGN_INFO: {
            //dublicate copy of reducer data.
            const data = state.get('campaignInfo').slice();
            //update the specific status of object in reducer.
            // const updatedData = data.splice(state.get('campaignInfo').findIndex(state => state._id === action.payload._id),1,action.payload); 
            const index = data.findIndex(item=> item._id === action.payload._id)
            data[index].isActive = action.payload.isActive
            //update the change in reducer
            return state.set('campaignInfo', data); 
        }
        //request when goal is created and to update the connected goal data
        case CREATE_GOAL : {
            //replace the key inside of object
            const goalData = state.get('campaignInfo').find(state => state._id === action.payload.campaign || state._id === action.payload.campaign._id).goal.concat(action.payload);
            const goalCampaign = state.get('campaignInfo').find(state => state._id === action.payload.campaign || state._id === action.payload.campaign._id);
            goalCampaign["goal"] = goalData;
            //replace the updated object in reducer
            const campaign = state.get('campaignInfo').slice();
            campaign.splice(state.get('campaignInfo').findIndex(state => state._id === goalCampaign._id),1,goalCampaign); 
            return state.set('campaignInfo',campaign);
        }
        default:
            return state;
    }
}

