import React, {Component} from 'react';
import Select from 'react-select';


class SelectBox extends Component{

  valueSetter = (value, options) =>{
    if(typeof (value) === 'string'){
      for(let i =0; i<options.length ; i++){
        if(value=== options[i].value){
          return options[i]
        }
      }
    } 
  }

  render(){
    const {
      options,
      onChange,
      value,
      placeholder,
      name,
      isSearchable = true,
      height,
      className,
      defaultValue,
      isMulti = false,
    } = this.props

    const customStyle = {
      control: base => ({
        ...base,
        background:isSearchable === true ? '#fff' : 'rgb(30, 104, 198)',
        color:'#fff',
        minHeight:'34px',
        height: height ? height : '40px',
        cursor: 'pointer',
        fontSize: 12,
        marginTop:'2%',
        fontWeight: 'inherit',
        fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'
        //color:isSearchable === true ? 'black' :'#fff'
      }),
      placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: isSearchable ? 'black':'#ffffff',
        }
      },
      singleValue: base => ({
        ...base,
        top: '47%',
        color: isSearchable === true ? 'black' : '#fff'
      }),
      menu: styles => ({ 
        ...styles, 
        zIndex: 999,
        fontSize: 12,
        fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'
      }),
      dropdownIndicator: (base, state) => ({
        ...base,
        transition: 'all .2s ease',
        color:isSearchable === true ? 'grey' : '#fff',
        "&:hover": {
          color:isSearchable === true ? 'grey' : '#fff'
        },
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      }),
      IndicatorSeparator:()=>({display:'none'})
    };

    return (
      <Select
        name={name}
        styles={customStyle}
        onChange={onChange}
        isSearchable={isSearchable}
        value={this.valueSetter(value, options)}
        placeholder={placeholder ? placeholder : null}
        isMulti={isMulti}
        defaultValue={defaultValue ? defaultValue : ''}
        components={{
          IndicatorSeparator: () => null,
        }}
        options={options}
        className={className}
      />
    )
  }
}

export default SelectBox;