import React from 'react'
import { Box, makeStyles, createStyles, Typography } from '@material-ui/core'
import { Link } from 'react-router';
import FlagIcon from '@material-ui/icons/Flag';


const UpgradeBanner = (props) =>{
	
	const {message} = props
	const classes = useStyles()

	return(
		
        <Box className={classes.upgradeBox}>
			<Link to="/upgrade-plan">
				<Typography style={{margin: '0', fontSize: '12px', color: 'rgba(9,30,66,.87)',display:'flex', alignItems:'center'}}>
					<FlagIcon style={{color: '#f32b2bb0', fontSize: '15px', marginRight: '5px'}}/>
					{message}
				</Typography>
			</Link>
        </Box>
        
)
}


const useStyles = makeStyles(theme =>
	createStyles({
		upgradeBox:{
            margin: '15px auto',
            padding: '5px 8px',
            cursor: 'pointer',
            textAlign: 'center',
            backgroundColor: '#e1e2e6',
            borderRadius: '3px',
            width: 'fit-content'
        }
	})
)

export default UpgradeBanner



/**
 * 
 * display upgrade message in a concise way.
 * wrap the message (string) inside .
 * 
 * 
 */
