import { fromJS } from 'immutable';

const action = name => `/aweber/${name}`;

export const FETCH = action('FETCH');
export const FETCH_SUCCESS = action('FETCH_SUCCESS');
export const CREATE = action('CREATE');
export const CREATE_SUCCESS = action('CREATE_SUCCESS');
export const REMOVE = action('REMOVE');
export const REMOVEWITHLIST = action('REMOVEWITHLIST');
export const POP_AWEBER = action('POP_AUTOKLOSE');
export const UPDATE = action('UPDATE');

export const getAweber = (campaignId) => ({ type: FETCH, campaignId });
export const addAweber = (aweber) => ({ type: CREATE, aweber });
export const deleteAweber = (_id, id, index) => ({ type: REMOVE, _id, id, index });
export const deleteAweberWithList = (_id, index) => ({ type: REMOVEWITHLIST, _id, index });
export const succeessAweber = (data) => ({ type: FETCH_SUCCESS, data });
export const popAweber = (index) => ({ type: POP_AWEBER, index });
export const updateAweber = (aweber) => ({ type: UPDATE, aweber });
const initialState = fromJS({});
const aweber = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return state.set('aweber', action.data);
    case POP_AWEBER:
      return state.set('aweber', state.get('aweber').slice(0, action.index).concat(state.get('aweber').slice(action.index + 1)));
    default:
      return state;
  }
};

export default aweber;
