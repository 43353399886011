import React, { useEffect } from 'react';
import { makeStyles, createStyles, Box, Paper } from '@material-ui/core';
import { Fragment } from 'react';
import {Check} from '../../../../img'

const BulkNotificationCard = (props) => {
    const classes = useStyles();
    const {
        settings,
        visitorText,
        otherText,
        contentText,
        bulkText,
        bulkDaysLable,
        recentText2
        // poweredBy
    } = props;

    useEffect(()=>{
        console.log(settings)
    },[settings])


    return(
        //<a href={settings.notificationUrl} target="_blank" rel="noopener noreferrer">
        <Box 
            component={Paper}
            width= {358}
            height= {85}
            padding= '10px'
            // border='1px solid rgba(216, 217, 226, 0.5)'
            // boxShadow='10px 20px 40px 0 rgba(36, 35, 40, 0.1)'
            // borderRadius={`${settings.panelStyle.radius}px`}
            bgcolor= {`rgb(${settings.panelStyle.backgroundColor.r}, ${settings.panelStyle.backgroundColor.g}, ${settings.panelStyle.backgroundColor.b})`}
            display='flex'
            alignItems='center'
            style={{
                transition: 'all .1s ease-in-out',
                textDecoration:'none', 
                flexDirection: 'column', 
                borderRadius:`${settings.panelStyle.radius}px`,
                backgroundColor:`rgb(${settings.panelStyle.backgroundColor.r}, ${settings.panelStyle.backgroundColor.g}, ${settings.panelStyle.backgroundColor.b})`,
                border:'1px solid rgba(216, 217, 226, 0.5)',
                boxShadow:'10px 20px 40px 0 rgba(36, 35, 40, 0.1)'
            }}
        >

            <Box className={classes.upperPart}>
                <Box 
                    className={classes.imageContainer}
                    style={{
                        borderRadius: `${settings.panelStyle.radius}px`
                    }}
                >
                    <img
                        src = {
                            settings.panelStyle.image ?
                            settings.panelStyle.image :
                            "https://s3.wasabisys.com/influencelogo/logo/fire-icon.png"
                        }
                        style={{
                                width: '41px', 
                                height: '41px',
                                borderRadius: `${settings.panelStyle.radius}px`,
                                transition: 'all .1s ease-in-out',
                                alignSelf: 'center'
                            }}
                        // className={classes.imageStyle}
                    />
                </Box>
                {/*<div className={classes.closeBtnContainer}>
                    <button className={classes.closeBtn}>+</button>
                </div>*/}

                <Box className={classes.textContainer}>
                    <p className={classes.para} 
                            style={{
                                alignSelf: 'center',
                                display: '-webkit-box',
                                WebkitLineClamp: '3',
                                WebkitBoxOrient: 'vertical',
                                lineHeight: '1',
                                overflow: 'hidden',
                            }}
                        >
                        <em 
                            style={{
                                textDecoration:'none',
                                borderRadius: '2px',
                                // backgroundColor: 'rgba(0, 149, 247, 0.1)',
                                fontSize: '17px',
                                padding: '2px',
                                fontWeight: 'bold',
                                fontStyle: 'normal',
                                textAlign: 'left',
                                paddinRight: '5px',
                                fontFamily: settings.panelStyle.fontFamily,
                                color: `rgb(${settings.panelStyle.color.r}, ${settings.panelStyle.color.g}, ${settings.panelStyle.color.b})`,
                                textTransform: 'capitalize',
                                backgroundColor: 'rgb(243, 247, 255)',
                                paddingLeft: '4px'
                            }}
                            // className={classes.mainText}
                        >
                            {`2,002 ${visitorText}`}
                        </em>
                        <em
                            style={{
                                textDecoration:'none',
                                fontSize: '11px',
                                fontStyle:'normal',
                                letterSpacing: '0.3px',
                                // lineHeight: '0.8',
                                // whiteSpace: 'nowrap',
                                // overflowX: 'hidden',
                                // textOverflow: 'ellipsis',0
                                paddingLeft: '2px',
                                fontFamily: settings.panelStyle.fontFamily,
                                fontWeight: settings.panelStyle.fontWeight,
                                color: '#666',
                                
                                
                            }} 
                            // className={classes.secondaryText}
                        >
                            {/*{`${otherText} ${contentText}`} {"\n"} {`${bulkText} ${settings.panelStyle.bulkData} ${bulkDaysLable}`}*/}
                            {`${otherText} ${contentText}\n ${bulkText} ${settings.panelStyle.bulkData} ${bulkDaysLable}`}
                        </em>
                    </p>
                </Box>
            </Box>

            {settings.togglePoweredBy &&             
                <Fragment>
                
                    <div className={classes.border}></div>

                    <Box className={classes.lowerPart}>
                        <p className={classes.footerText}>
                            <em className={classes.verifiedText}>{recentText2}</em>
                            <em className={classes.verifiedIcon}>
                                {/* <img src={Check}/> */}
                                <Check/>
                            </em>
                            <em className={classes.influenceText}>{settings.poweredBy}</em>
                        </p>
                    </Box>
                </Fragment>  
            }           
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        upperPart:{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            width: '100%',
            alignItems: 'center',
            height: '60px'
        },
        imageContainer:{
            width: '41px',
            height: '41px',
            borderRadius: '10px',
            textAlign: 'center',
            marginRight: '10px',
            alignSelf: 'center'
        },
        textContainer:{
            // padding: '5px',
            paddingRight: '10px',
            alignSelf: 'center'
        },
        para:{
            margin: '0',
            alignSelf: 'center'
        },
        border:{
            borderTop: '1px dashed',
            borderColor: 'rgba(102,102,102,0)',
            margin:'1px 10px 0px 10px',
            width: '90%'
        },
        lowerPart:{
            textAlign: 'center',
            height: '8px',
        },
        footerText:{
            paddinRight: '5px',
            lineHeight: '0.8',
            margin: '0px'
        },
        verifiedText:{
            fontSize: '9px',
            fontWeight: '600',
            fontStyle: 'normal',
            textAlign: 'left',
            color: '#666',
            fontFamily: 'sans-serif',
            wordSpacing: '1px'
        },
        verifiedIcon:{
            fontSize: '11px',
            color: '#0093fb',
            paddingLeft: '5px',
            paddingRight: '5px',
            marginTop: '-2px'
        },
        influenceText:{
            fontStyle: 'normal',
            fontSize: '10px',
            fontWeight: '600',
            fontFamily: 'sans-serif',
            color: '#044fff'
        }
    })    
)

export default BulkNotificationCard;




{/*
<Box>
                <img
                    src= {
                        settings.panelStyle.image ?
                        settings.panelStyle.image :
                        "https://app.useinfluence.co/static/media/fire-new.bece222f.png"
                    }
                    style={{
                        width: '55px', 
                        height: '55px',
                        padding : '9px',
                        borderRadius: `${settings.panelStyle.radius}px`,
                        transition: 'all .1s ease-in-out'
                    }}
                />
            </Box>    
            <Box width='100%' padding='0px 15px' style={{overflowX:'hidden',textOverflow:'ellipsis',width:'100%'}}>
                <Box>
                    <span
                        style={{
                            textDecoration:'none',
                            borderRadius: '2px',
                            backgroundColor: 'rgba(0, 149, 247, 0.1)',
                            fontSize: '15px',
                            padding: '2px',
                            fontWeight: '600',
                            fontFamily: settings.panelStyle.fontFamily,
                            color: `rgb(${settings.panelStyle.color.r}, ${settings.panelStyle.color.g}, ${settings.panelStyle.color.b})`
                        }}
                    >
                        {`2,002 ${visitorText}`}
                    </span>
                </Box>

                <Box display='flex' flexDirection="column">
                    <span
                        style={{
                            textDecoration:'none',
                            fontSize: '14px',
                            letterSpacing: '0.3px',
                            whiteSpace: 'nowrap',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            padding: '2px 0px',
                            fontFamily: settings.panelStyle.fontFamily,
                            fontWeight: settings.panelStyle.fontWeight,
                            color: `rgb(${settings.panelStyle.color.r}, ${settings.panelStyle.color.g}, ${settings.panelStyle.color.b})`,
                            
                        }}
                    >
                        {`${otherText} ${contentText}`}
                    </span>
                    <span
                        style={{
                            textDecoration:'none',
                            fontSize: '11px',
                            fontFamily: settings.panelStyle.fontFamily,
                            fontWeight: settings.panelStyle.fontWeight,
                            color: '#9196b7',
                            //color: `rgb(${settings.panelStyle.color.r}, ${settings.panelStyle.color.g}, ${settings.panelStyle.color.b})`,
                            letterSpacing:'0.3px',
                            whiteSpace: 'nowrap',
                            overFlowX: 'hidden',
                            textOverflow: 'ellipsis',
                            marginLeft: '-2px'
                        }}
                    >
                        {`${bulkText} ${settings.panelStyle.bulkData} ${bulkDaysLable}`}
                        {settings.togglePoweredBy &&
                            <Fragment>
                                <CheckCircleRoundedIcon style={{width:'14px', height:"14px",color:"#5d93fe", marginLeft:'15px'}} />
                                <span
                                    style={{
                                        textDecoration:'none',
                                        fontSize: '11px',
                                        fontFamily: settings.panelStyle.fontFamily,
                                        fontWeight: settings.panelStyle.fontWeight,
                                        color: '#007bff',
                                        letterSpacing:'0.3px'
                                    }}
                                >
                                    {` ${recentText2} ${settings.poweredBy}`}
                                </span>
                            </Fragment>
                        }
                    </span>
                </Box>
            </Box>             
        </Box>
        // </a>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({

    })    
)

export default BulkNotificationCard;

*/}