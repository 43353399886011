import React, { useState } from 'react'
import { Box, makeStyles, createStyles, Typography, Button } from '@material-ui/core'
// import MicroPoliciesBox from './MicroPoliciesBox'
import CreatePolicyDialog from './CreatePolicyDialog'
import { MicropoliciesSkeleton } from '../../../Globals/GlobalComponents/Skeletons'
const MicroPoliciesBox = React.lazy(()=> import('./MicroPoliciesBox'))


const MicroPolicies = (props) =>{

	const [openAddPolicyDialog, setOpenAddPolicyDialog] = useState(false)

	const handlePolicyDialog = () =>{
		setOpenAddPolicyDialog(!openAddPolicyDialog)
	}
	
	return(
		<Box>
			<Box style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
				<Typography variant="h5">
					MicroPolicies
				</Typography>
				<Button variant="contained" onClick={handlePolicyDialog}>
					Create New
				</Button>
			</Box>
			<Box style={{marginTop:'30px'}}>
				<React.Suspense fallback={<MicropoliciesSkeleton/>}>
					<MicroPoliciesBox id={props.params.id}/>
				</React.Suspense>
			</Box>
			<CreatePolicyDialog open={openAddPolicyDialog} onClose={handlePolicyDialog}/>
		</Box>
	)
}


const useStyles = makeStyles(theme =>
	createStyles({

	})
)

export default MicroPolicies
