import React, { memo, useEffect } from 'react';
import { Box, makeStyles, createStyles, Grid, Typography, Popover } from '@material-ui/core';
import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
import { ChromePicker } from 'react-color';

const TextBox  = (props) => {
    const classes = useStyles();

    const {
        fontWeight,
        fontFamily,
        color,
        secondaryColor,
        setRecentSettings,
        handlePanelStyleChange,
        fontColorPopper,
        fontColorPopper2,
        fontBgColor,
        anchorEl,
        anchorEl2,
        setAnchorEl,
        setAnchorEl2,
        setFontColorPopper,
        setFontColorPopper2,
        setFontBgColor,
        setSecondaryFontBgColor,
        secondatyFontBgColor
    } = props;

    const handleFontColorPopper = (event) => {
        if(fontColorPopper === false) {
            setFontColorPopper(true);
            setAnchorEl(event.currentTarget)
        } else {
            setFontColorPopper(false)
            setAnchorEl(null)
        }
    }

    const handleFontColorPopper2 = (e) =>{
        if(fontColorPopper2 === false){
            setFontColorPopper2(true);
            setAnchorEl2(e.currentTarget)
        }else{
            setFontColorPopper2(false)
            setAnchorEl2(null)
        }
    }

    const rgbSelector = (obj, stateName) =>{
        stateName(`rgba(${obj.r},${obj.g},${obj.b},${obj.a})`)
    }

    return(
        <Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
                <Grid container>
                    <Grid item md={12}>
                        <Typography className={classes.boxTitle}>TEXT</Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item md={3}>
                                <Typography variant="body2">Font Style</Typography>
                            </Grid>
                            <Grid item md={3}>
                                <ReactSelect 
                                    width='120px' 
                                    height='35px' 
                                    value={fontWeight}
                                    name="fontWeight"
                                    dispatcher={setRecentSettings}
                                    placeholder="font weight"
                                    onChange={handlePanelStyleChange}
                                    options= {[
                                        {label:"Normal", value:"normal"},
                                        {label:"Bold", value:'bold'}
                                    ]}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <ReactSelect 
                                    width='120px' 
                                    height='35px' 
                                    value={fontFamily}
                                    placeholder="Font family"
                                    onChange={handlePanelStyleChange}
                                    name="fontFamily"
                                    dispatcher={setRecentSettings}
                                    options= {[
                                        {label:"Default", value: 'inherit'},
                                        {label:"Monospace", value:'monospace'},
                                        {label:"Arial", value:'arial'},
                                        {label:"Georgia", value:'georgia'}
                                    ]}
                                />
                            </Grid>
                            <Grid item md={3} style={{display: 'flex', justifyContent: 'space-around'}}>
                                <div 
                                    className={fontColorPopper ? classes.colorIconActive : classes.colorIcon} 
                                    onClick={handleFontColorPopper} 
                                    style={{backgroundColor: `rgb(${color.r}, ${color.g}, ${color.b})`}}
                                >
                                </div>
                                <Popover 
                                    open={fontColorPopper} 
                                    onClose={handleFontColorPopper} 
                                    anchorOrigin={{vertical: 'bottom',horizontal: 'center',}} 
                                    transformOrigin={{vertical: 'top',horizontal: 'right',}}
                                    anchorEl={anchorEl}
                                >
                                    <ChromePicker 
                                        color={color} 
                                        onChange={(event) => {
                                            handlePanelStyleChange("color", event.rgb,setRecentSettings)
                                            rgbSelector(event.rgb, setFontBgColor)
                                        }}
                                    />
                                </Popover>


                                <div 
                                    className={fontColorPopper2 ? classes.colorIconActive : classes.colorIcon} 
                                    onClick={handleFontColorPopper2} 
                                    style={{backgroundColor: secondaryColor? `rgb(${secondaryColor.r}, ${secondaryColor.g}, ${secondaryColor.b})`: "#666"}}
                                >
                                </div>
                                    <Popover 
                                        open={fontColorPopper2} 
                                        onClose={handleFontColorPopper2} 
                                        anchorOrigin={{vertical: 'bottom',horizontal: 'center',}} 
                                        transformOrigin={{vertical: 'top',horizontal: 'right',}}
                                        anchorEl={anchorEl2}
                                    >
                                        <ChromePicker 
                                            color={secondaryColor} 
                                            onChange={(event) => {
                                                handlePanelStyleChange("secondaryColor", event.rgb,setRecentSettings)
                                                rgbSelector(event.rgb, setSecondaryFontBgColor)
                                            }}
                                        />
                                    </Popover>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            paddingBottom: '2%'
        },
        colorIcon: {
            width: '35px',
            height: '35px',
            border: '1px solid #000',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        colorIconActive: {
            width: '35px',
            height: '35px',
            border: '2px solid rgba(201,201,201,0.7)',
            borderRadius: '50%',
            cursor: 'pointer',
        },
    })
)

export default memo(TextBox);