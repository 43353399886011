import React, { useState, memo } from 'react'
import { Box, makeStyles, createStyles, Grid, Popover, Typography,Button } from '@material-ui/core'
import SlidingBar from '../../../../Globals/GlobalComponents/SlidingBar';
import { SketchPicker } from 'react-color'
import FileBase64 from 'react-file-base64';
import {HelpQuestion} from '../../../../img'
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';



const AppearanceBox = (props) =>{
    
	const { 
       
        setAnnouncementSettings, 
        handlePanelStyleChange,
        radius,
        backgroundColor,
        handleImageUpload
    } = props

	const [bgColorPopper, setBgColorPopper] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
	const [bgColorValue, setBgColorValue] = useState('rgb(0,0,0,0)')

	const handleBgColorPopper = (event) => {
        if(bgColorPopper === false) {
            setBgColorPopper(true);
            setAnchorEl(event.currentTarget)
        } else {
            setBgColorPopper(false)
            setAnchorEl(null)
        }
    }

    const rgbSelector = (obj, stateName) =>{
        stateName(`rgba(${obj.r},${obj.g},${obj.b},${obj.a})`)
    }


	const classes = useStyles()
	return(
		<Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container>
                <Grid item md={12}>
                    <Typography className={classes.boxTitle}>Appearance</Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={3}>
                            <Typography variant="body2">Radius</Typography>
                        </Grid>
                        <Grid item md={9}>
                            <SlidingBar value={radius} name="radius" onChange={handlePanelStyleChange} dispatcher={setAnnouncementSettings} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems='center'>
                        <Grid item md={3}>
                            <Typography variant="body2">Background Color</Typography>
                        </Grid>
                        <Grid item md={3}>
                            <div 
                                className={bgColorPopper ? classes.colorIconActive : classes.colorIcon} 
                                onClick={handleBgColorPopper} 
                                style={{backgroundColor: `rgb(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b})`}}></div>
                            <Popover
                                open={bgColorPopper} 
                                onClose={handleBgColorPopper} 
                                anchorOrigin={{vertical: 'bottom',horizontal: 'center',}} 
                                transformOrigin={{vertical: 'top',horizontal: 'right',}}
                                anchorEl={anchorEl}
                            >
                                <SketchPicker 
                                    color={backgroundColor} 
                                    onChange={(e) => {
                                        handlePanelStyleChange("backgroundColor", e.rgb,setAnnouncementSettings)
                                        rgbSelector(e.rgb, setBgColorValue)
                                    }
                                } />
                            </Popover>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                <Grid container style={{marginTop:'8px'}}>
                    <Grid item md={3} style={{marginTop:'11px'}}>
                        <Typography  variant="body2">
                            Display Icon
                            {/* <a href="https://support.useinfluence.co/article/1960" target="_blank">
                                <img src = {HelpQuestion}  style={{ height: "11px",marginLeft:"2%"}}/>
                            </a>  */}
                            <InfluenceHelpIcon fontSize="tiny"  href="https://support.useinfluence.co/article/1960" />
                        </Typography>
                    </Grid>
                    
                    <Grid item md={6} style={{fontSize:'14px',marginTop:'5px',paddingLeft:'8%'}}>
                        <FileBase64 
                            className={classes.fileInput}
                            onDone={handleImageUpload}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Button  variant="contained" onClick={() => handlePanelStyleChange('image', '', setAnnouncementSettings)}>
                            Default image
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Box>
	)
}


const useStyles = makeStyles(() =>
	createStyles({
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            paddingBottom: '2%'
        },
        colorIcon: {
            width: '35px',
            height: '35px',
            border: '1px solid #000',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        colorIconActive: {
            width: '35px',
            height: '35px',
            border: '2px solid rgba(201,201,201,0.7)',
            borderRadius: '50%',
            cursor: 'pointer'
        },
	})
)

export default memo(AppearanceBox);
