import React,{useState, lazy, Suspense} from 'react';
import { Paper, makeStyles, Tabs, Tab, Typography, Box} from '@material-ui/core';
//import CampaignSettingHeader from '../CampaignSettingHeader/CampaignSettingHeader';
import { UserCog,Sliders, FileInvoice } from '../../img';
import CampaignSettingsSekelton from '../../Globals/GlobalComponents/CampaignSettingsSekelton';
import clsx from "clsx"
import { SettingsPageSeketon } from '../../Globals/GlobalComponents/Skeletons';

const AccountSummaryPage = lazy(() => import("./AccountSummaryPage"))
const ProfileSettingsPage = lazy(() => import("./ProfileSettingsPage"))
const BillingPage = lazy(() => import("./BillingPage/BillingPage"))
const ConfigurePage = lazy(() => import("./ConfigurePage/ConfigurePage"))




const TabPanel=(props)=>{
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box marginTop='4%'>{children}</Box>}
      </Typography>
    );
  }


const SettingsPageNavigation =()=>{

  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navMenus = [
    {
      icon:<FileInvoice/>,
      text:'Account Summary'
    },
    {
      icon:<UserCog/>,
      text:'Profile Settings'
    },
    {
      icon:<Sliders/>,
      text:'Configure'
    },
    {
      icon:<FileInvoice/>,
      text:'Billing'
    },
  ]

  return(
    <Box className={classes.mainBox}>
      <Paper className={classes.root} elevation={0}>
        <Tabs 
          className={classes.tabMenu}
          value={value} 
          onChange={handleChange} 
          aria-label="simple tabs example"
          variant="fullWidth" 
          centered
          indicatorColor="primary"
          textColor="primary"
        >
          {
            navMenus.map((menu, index)=>(
              <Tab
              // className={value === index ? classes.activeTab : classes.tab} 
                className={clsx({[classes.tab]:true, activeTab: value===index})}
                label={
                  <div className={'tabLabelWrapper'}>
                    {menu.icon}
                    {menu.text}
                  </div>
                }
              />
            ))
          }
        </Tabs>
      </Paper>

      <TabPanel value={value} index={0}>
        <Suspense fallback={<CampaignSettingsSekelton className={classes.skeletonStyle} />}>
          <AccountSummaryPage />
        </Suspense>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Suspense fallback={<SettingsPageSeketon className={classes.skeletonStyle}/>}>
          <ProfileSettingsPage />
        </Suspense>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Suspense fallback={<CampaignSettingsSekelton className={classes.skeletonStyle}/>}>
          <ConfigurePage />
        </Suspense>
      </TabPanel> 

      <TabPanel value={value} index={3}>
        <Suspense fallback={<CampaignSettingsSekelton className={classes.skeletonStyle}/>}>
          <BillingPage />
        </Suspense>
      </TabPanel>  

    </Box>
    )
}

const useStyles = makeStyles({
    root: {
      flexGrow: 1,
      border: 'solid 1px #e2e3e2'
    },
    skeletonStyle:{
      padding:'3% 6%', 
      backgroundColor:'#fff', 
      boxShadow:'rgba(198, 198, 198, 0.32) 0px -1px 2px 0px', 
      border:'1px solid rgba(177, 177, 177, 0.3)'
    },
    tabMenu: {
      flexDirection:'row !important',
      margin:'0px !important',
      textTransform: 'capitalize'
      // justifyContent:'space-between'
    },
    tab: {
      fontSize:'16px',
      width: '25%',
      height: '60px',
      padding:'0px',
      margin:'0px',
      color: "#484848",
      textTransform: 'capitalize',
      '&:focus':{
        outline: 'none'
      },

      '& div.tabLabelWrapper':{
        '&>svg':{
          height:'16px',
          fill:'rgb(72, 72, 72)',
          marginRight:'9px',
          verticalAlign:'middle'
        }
      },
      '&.activeTab':{
        backgroundColor :'#f3f7fd',
        '& div.tabLabelWrapper>svg':{
          fill:'#2c66c3',
        }
      }
    },
    mainBox: {
      marginTop:'2%',
      position:'relative'
    }
  });

  export default SettingsPageNavigation;