import { call, put, fork, takeLatest } from 'redux-saga/effects';
import * as api from 'services/api';
import * as actions from 'ducks/rules';
import { load, loaded } from 'ducks/loading';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import Popup from 'react-popup';

const toastConfig = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 5000
};

// const errorMessge = 'Please try again or refresh!';

function* fetch() {
  try {
    yield put(load());
    const res = yield call(api.GET, 'rules');
    if(res.error)
      console.log(res.error);
    else
      yield put(actions.fetchSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* fetchOne(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `rules/campaign/${action.campId}`, );
    if(res.error)
      console.log(res.error);
    else
      yield put(actions.successRules(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }
}

function* create(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'rules', action.rules);
    if(res.error)
      console.log(res.error);
    else
      yield put(actions.successRules(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }

}

function* update(action) {
  try {
    const delayBetween = action && action.rules && action.rules.delayBetween ? parseInt(action.rules.delayBetween) : 0;
    const initialDelay = action && action.rules && action.rules.initialDelay ? parseInt(action.rules.initialDelay) : 0;
    if(action.rules && delayBetween <= initialDelay)
      yield toast.warning('initial delay time should be less than subsequent time', {position: 'bottom-left', autoClose: 7000});
    else{
      yield put(load());
      delete action.rules['_id'];
      const res = yield call(api.PUT, `rules/${action.rules.id}`, action.rules);
      if(res.status=='duplicate'){
        yield toast.error('You have already one campaign for display all pages', toastConfig);
        yield put(actions.fetchOneRules(res.data.campaign));
      }
      else if(res.error)
        console.log(res.error);
      else {
        let rules = action.rules;
        rules['_id'] = rules.id;
        let data=res.data;
        yield put(actions.successRules(rules));
        yield put(actions.fetchOneRules(data.campaign));
        //update es campaign data
        yield call(api.PUT, `elasticsearch/upateElasticForScriptData?trackingId=${action.rules.trackingId}&host=${action.rules.websiteUrl}`);

        if (action && action.rules && action.rules.tabType == 'display')
          Popup.create({
            title: 'Display pages',
            content: `Now your notifications will be visible ${data.displayOnAllPages ? 'on all pages' : 'only on added paths'}!`,
            buttons: {
              right: [{
                text: 'Close',
                key: '⌘+s',
                className: 'btn btn-primary delete-btn',
                action: function () {
                  Popup.close();
                }
              }]
            }
          }, true);
      }
      yield put(loaded());
    }
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    // if (error == 'TypeError: Failed to fetch') {
    //   yield toast.error(errorMessge, toastConfig);
    // } else {
    //   yield toast.error(error.message, toastConfig);
    // }
  }

}

export function* watchFetch() {
  yield takeLatest(actions.FETCH, fetch);
}

export function* watchFetchOne() {
  yield takeLatest(actions.FETCH_ONE, fetchOne);
}

export function* watchCreate() {
  yield takeLatest(actions.CREATE, create);
}

export function* watchUpdate() {
  yield takeLatest(actions.UPDATE, update);
}

export default function* rootSaga() {
  yield [
    fork(watchFetch),
    fork(watchFetchOne),
    fork(watchCreate),
    fork(watchUpdate)
  ];
}
