import { fromJS, Map } from 'immutable';
const action = name => `/webhooks/${name}`;

export const FETCH_WEBHOOK = action('FETCH_WEBHOOK');
export const FETCH_WEBHOOK_ONE = action('FETCH_WEBHOOK_ONE');
export const CREATE_WEBHOOK = action('CREATE_WEBHOOK');
export const UPDATE_WEBHOOK = action('UPDATE_WEBHOOK');
export const DELETE_WEBHOOK = action('DELETE_WEBHOOK');
export const CREATE_WEBHOOK_SUCCESS = action('CREATE_WEBHOOK_SUCCESS');
export const FETCH_WEBHOOK_SUCCESS = action('FETCH_WEBHOOK_SUCCESS');
export const CLEAR_WEBHOOK = action('CLEAR_WEBHOOK');
export const FETCH_APPS_CONNECTION = action('FETCH_APPS_CONNECTION');
export const SUCCESS_APPS_CONNECTION = action('SUCCESS_APPS_CONNECTION');
export const UPDATE_APPS_CONNECTION = action('UPDATE_APPS_CONNECTION');

export const fetchWebhook = (webhook) => ({ type: FETCH_WEBHOOK, webhook });
export const fetchOneWebhook = (webhook) => ({ type: FETCH_WEBHOOK_ONE, webhook });
export const createWebhook = (webhooks) => ({ type: CREATE_WEBHOOK, webhooks });
export const updateWebhook = (webhooks) => ({ type: UPDATE_WEBHOOK, webhooks });
export const deleteWebhook = (webhooks) => ({ type: DELETE_WEBHOOK, webhooks });
export const successWebhook = (webhooks) => ({ type: CREATE_WEBHOOK_SUCCESS, webhooks });
export const fetchWebhookSuccess = (webhooks) => ({ type: FETCH_WEBHOOK_SUCCESS, webhooks });
export const clearWebhook = (webhooks) => ({ type: CLEAR_WEBHOOK, webhooks });
export const fetchAppsConnection = (campaignId) => ({ type: FETCH_APPS_CONNECTION, campaignId });
export const successAppsConnection = (data) => ({ type: SUCCESS_APPS_CONNECTION, data });
export const updateAppsConnection = (appsStatus) => ({ type: UPDATE_APPS_CONNECTION, appsStatus });

const initialState = fromJS({
  webhooks: Map({})
});

const webhooks = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WEBHOOK_SUCCESS:
      return state.set('webhooks', action.webhooks);
    case CREATE_WEBHOOK_SUCCESS:
      return state.set('webhook', action.webhooks);
    case CLEAR_WEBHOOK:
      return state.set('webhook', null);
    case SUCCESS_APPS_CONNECTION:
      return state.set('appsStatus', action.data);
    default:
      return state;
  }
};

export default webhooks;
