import React, { useEffect } from 'react';
import { makeStyles, createStyles, Box, Paper } from '@material-ui/core';
import {Check} from '../../../../img'




const CustomNotificationCard = (props) => {
    const classes = useStyles();
    const {
        settings,
        visitorText,
        otherText,
        contentText,
        bulkText,
        bulkDaysLable,
        recentText2
        // poweredBy
    } = props;


    return(

        <Box 
            component={Paper}
            width= {358}
            height= {85}
            padding= '10px'
            border='1px solid rgba(216, 217, 226, 0.5)'
            // boxShadow='10px 20px 40px 0 rgba(36, 35, 40, 0.1)'
            // borderRadius={`${settings.panelStyle.radius}px`}
            // bgcolor= {`rgb(${settings.panelStyle.backgroundColor.r}, ${settings.panelStyle.backgroundColor.g}, ${settings.panelStyle.backgroundColor.b})`}
            display='flex'
            alignItems='center'
            style={{
                transition: 'all .1s ease-in-out',
                textDecoration:'none', 
                flexDirection: 'column',
                borderRadius:`${settings.panelStyle.radius}px`,
                boxShadow:'10px 20px 40px 0 rgba(36, 35, 40, 0.1)',
                backgroundColor:`rgb(${settings.panelStyle.backgroundColor.r}, ${settings.panelStyle.backgroundColor.g}, ${settings.panelStyle.backgroundColor.b})`
            }}
        >

            <Box className={classes.upperPart}>
                <Box 
                    className={classes.imageContainer}
                    style={{
                        borderRadius: `${settings.panelStyle.radius}px`
                    }}
                >
                    <img
                        src = {
                            settings.panelStyle.image ?
                            settings.panelStyle.image :
                            "https://s3.wasabisys.com/influencelogo/logo/click.svg"
                        }
                        style={{
                                width: '41px', 
                                height: '41px',
                                borderRadius: `${settings.panelStyle.radius}px`,
                                transition: 'all .1s ease-in-out',
                                alignSelf: 'center'
                            }}
                        // className={classes.imageStyle}
                    />
                </Box>
                {/*<div className={classes.closeBtnContainer}>
                    <button className={classes.closeBtn}>+</button>
                </div>*/}

                <Box className={classes.textContainer}>
                    <p className={classes.para} 
                            style={{
                                alignSelf: 'center',
                                display: '-webkit-box',
                                WebkitLineClamp: '3',
                                WebkitBoxOrient: 'vertical',
                                lineHeight: '1',
                                overflow: 'hidden',
                            }}
                        >
                        <em 
                            style={{
                                textDecoration:'none',
                                borderRadius: '2px',
                                // backgroundColor: 'rgba(0, 149, 247, 0.1)',
                                fontSize: '17px',
                                padding: '2px',
                                fontWeight: 'bold',
                                fontStyle: 'normal',
                                textAlign: 'left',
                                paddinRight: '5px',
                                fontFamily: settings.panelStyle.fontFamily,
                                color: `rgb(${settings.panelStyle.color.r}, ${settings.panelStyle.color.g}, ${settings.panelStyle.color.b})`,
                                textTransform: 'capitalize',
                                backgroundColor: 'rgb(243, 247, 255)',
                                paddingLeft: '4px'
                            }}
                            // className={classes.mainText}
                        >
                            {`474 ${visitorText}`}
                        </em>
                        <em
                            style={{
                                textDecoration:'none',
                                fontSize: '11px',
                                fontStyle:'normal',
                                letterSpacing: '0.3px',
                                paddingLeft: '2px',
                                fontFamily: settings.panelStyle.fontFamily,
                                fontWeight: settings.panelStyle.fontWeight,
                                color: '#666',
                            }} 
                            // className={classes.secondaryText}
                        >
                            {otherText} <b>{contentText}</b> {`\n ${bulkText} ${settings.panelStyle.bulkData} ${bulkDaysLable}`}
                        </em>
                    </p>
                </Box>
            </Box>

                <div className={classes.border}></div>

                <Box className={classes.lowerPart}>
                    {
                        settings.togglePoweredBy ? 
                        <p className={classes.footerText}>
                            <em className={classes.verifiedText}>{recentText2}</em>
                            <em className={classes.verifiedIcon}>
                                {/* <img src={Check}/> */}
                                <Check/>
                            </em>
                            <em className={classes.influenceText}>{settings.poweredBy}</em>
                        </p>:
                        <p> </p>
                    }
                    <button className={classes.slugStyle}>
                        {contentText}
                    </button>
                </Box>
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        upperPart:{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            width: '100%',
            alignItems: 'center',
            height: '60px'
        },
        imageContainer:{
            width: '41px',
            height: '41px',
            borderRadius: '10px',
            textAlign: 'center',
            marginRight: '10px',
            alignSelf: 'center'
        },
        textContainer:{
            // padding: '5px',
            paddingRight: '10px',
            alignSelf: 'center'
        },
        para:{
            margin: '0',
            alignSelf: 'center'
        },
        border:{
            borderTop: '1px dashed',
            borderColor: 'rgba(102,102,102,0)',
            margin:'1px 10px 0px 10px',
            width: '90%'
        },
        lowerPart:{
            display:'flex',
            alignItems:'center',
            justifyContent:'space-around',
            width:'-webkit-fill-available',
            height:'9px'
        },
        slugStyle:{
            margin:'0',
            fontFamily: 'sans-serif',
            fontSize:'9px',
            cursor:'pointer',
            color:'#fff',
            lineHeight:'1.45',
            border:'none',
            textTransform:'capitalize',
            borderRadius:'3px',
            backgroundColor:'rgb(9, 127, 255)'
        },
        footerText:{
            paddinRight: '5px',
            lineHeight: '0.8',
            margin: '0px'
        },
        verifiedText:{
            fontSize: '9px',
            fontWeight: '600',
            fontStyle: 'normal',
            textAlign: 'left',
            color: '#666',
            fontFamily: 'sans-serif',
            wordSpacing: '1px'
        },
        verifiedIcon:{
            fontSize: '11px',
            color: '#0093fb',
            paddingLeft: '5px',
            paddingRight: '5px',
            marginTop: '-2px'
        },
        influenceText:{
            fontStyle: 'normal',
            fontSize: '10px',
            fontWeight: '600',
            fontFamily: 'sans-serif',
            color: '#044fff'
        }
    })    
)

export default CustomNotificationCard;