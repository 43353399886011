import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ProductsScreen from './Pages/ProductsScreen';
import DetailsScreen from './Pages/DetailsScreen';
import PixelScreen from './Pages/PixelScreen/PixelScreen';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: drawerWidth,
  },
  drawer: {
    //width: drawerWidth,
    width: '320px',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '320px',
    backgroundColor:'#eeeff1'
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    backgroundColor:'#fff'
  },
}));

export default function SubSidePanel(props) {


  const {currentPanel,setPanel} = props  
  const classes = useStyles()
  


  return (
    <div className={classes.root}>
      <Box className={classes.content}>
        {
          
          // currentPanel === 0 ?
          //   <DetailsScreen />:
          // currentPanel === 1 ? 
          //   <ProductsScreen />:
          // currentPanel === 2 ? 
          //   <PixelScreen />:
          // <DetailsScreen setPanel={setPanel} />
        }
         <PixelScreen />
        
      </Box>
    </div>
  );
}
