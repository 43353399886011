import React, { memo } from 'react';
import { Box, makeStyles, createStyles, Grid, Typography } from '@material-ui/core';
import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
// import TextInput from '../../../../Globals/GlobalComponents/TextInput';
import InputBox from '../../../../Globals/GlobalComponents/InputBox';
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';


const FeaturesBox  = (props) => {
    const classes = useStyles();

    const {
        contentText,
        handlePropertyChange,
        setBulkSettings,
        otherText,
        bulkText,
        visitorText,
        handlePanelStyleChange,
        bulkDaysLable,
        bulkData,
        selectDurationData
    } = props;


    return(
        <Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Typography className={classes.boxTitle}>Features</Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={3}>
                            <Typography variant="body2">Brand Name</Typography>
                        </Grid>
                        <Grid item md={9}>
                            <InputBox 
                                name="contentText"
                                value={contentText}
                                onChange={handlePropertyChange}
                                dispatcher={setBulkSettings}
                                maxLength={20}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item md={3}>
                            <Typography variant="body2">Other Text</Typography>
                        </Grid>
                        <Grid item md={4} style={{paddingLeft: '4px'}}>
                            <InputBox 
                                name="otherText"
                                value={otherText}
                                onChange={handlePropertyChange}
                                dispatcher={setBulkSettings}
                                maxLength={25}
                            />
                        </Grid>
                        <Grid item md={5}>
                            <InputBox 
                                name="bulkText"
                                value={bulkText}
                                onChange={handlePropertyChange}
                                dispatcher={setBulkSettings}
                                maxLength={15}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={3}>
                            <Typography variant="body2">Identify User As</Typography>
                        </Grid>
                        <Grid item md={9}>
                            <InputBox 
                                name="visitorText"
                                value={visitorText}
                                onChange={handlePropertyChange}
                                dispatcher={setBulkSettings}
                                maxLength={20}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item md={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item md={5}>
                            <Typography variant="body2" style={{whiteSpace:'nowrap'}}>
                                Display Bulk data from the last
                                {/* <a href={helpArticleLinks.faq.notification.featuresBulk} target="_blank">
                                    <img src = {HelpQuestion}  style={{ height: "11px",marginLeft:"2%"}}/>
                                </a> */}
                                <InfluenceHelpIcon href={helpArticleLinks.faq.notification.featuresBulk} />
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <InputBox 
                                name="bulkData"
                                value={bulkData}
                                onChange={handlePanelStyleChange}
                                dispatcher={setBulkSettings}
                                type="number"
                                maxLength={10}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <ReactSelect 
                                width="120px" 
                                height="35px" 
                                value={selectDurationData}
                                name='selectDurationData'
                                dispatcher={setBulkSettings}
                                onChange={handlePanelStyleChange}
                                options={[
                                    {label: 'days', value: 'days'},
                                    {label: 'hours', value: 'hours'}
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems='center'>
                        <Grid item md={3}>
                            <Typography variant="body2">Bulk Label</Typography>
                        </Grid>
                        <Grid item md={9}>
                            <InputBox 
                                name="bulkDaysLable"
                                value={bulkDaysLable}
                                onChange={handlePropertyChange}
                                dispatcher={setBulkSettings}
                                maxLength={15}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        input: {
            height: '35px',
            backgroundColor: '#fff',
            // marginTop: '4%',
            border: ' 1px solid hsl(0,0%,80%)',
            width: '100%',
            borderRadius: '4px',
            padding: '0% 3%',
            fontSize: '14px'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            // paddingBottom: '5%'
        },
    })
)

export default memo(FeaturesBox) ;