import React, { memo, useState } from 'react';
import { Box, makeStyles, createStyles, Grid, Typography, Button } from '@material-ui/core';
import axios from 'axios'
import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
import InputBox from '../../../../Globals/GlobalComponents/InputBox';
import InputBoxCopy from '../../../../Globals/GlobalComponents/InputBoxCopy';
import {HelpQuestion} from '../../../../img'
import PageLinkChips from './LinkChips';
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';


const FeaturesBox  = (props) => {
    const {
        contentText,
        handlePropertyChange,
        setCustomSettings,
        otherText,
        bulkText,
        visitorText,
        handlePanelStyleChange,
        bulkDaysLable,
        bulkData,
        selectDurationData,
        linkClicked
    } = props;

    const classes = useStyles();
    const [linkUrl, setLinkUrl]= useState("")
    const [slug, setSlug] = useState("")



    /**
     * Add the new entered link and slug to chips and clear input fields
     */

    const handleLinkSeperator = () =>{
        if (linkUrl.length == 0 || slug.length == 0) return
        updateLinksArray( [...linkClicked, {url:linkUrl, verified:false, slug:slug}])
        handleNewUrlAdd(linkUrl.trim(), linkClicked, slug)
        setLinkUrl("")
        setSlug("")
    }

    

    /** 
     * Add new URL and Slug entered by user to reducer object.  
     * It also checks Whether entered URL is verified or not.
     * @param {String} inputUrl Entered URL by user
     * @param {Array.<Object>} state Complete object of Links and slugs where new URL needs to be added
     * @param {String} slug Slug entered by user
     * @async
     */

    const handleNewUrlAdd = React.useCallback(async(inputUrl, state, slug)=>{
        const res = await axios({
            method:'GET',
            url:`/micropolicy/webdata?link=${inputUrl}`
        })
        updateLinksArray([...state, {url:inputUrl, verified: res.data.verified, slug:slug}])
        return 
    },[])



    /**
     * Delete a particular link from the array of links.  
     * Remove a link from the chips.
     * @param {Number} index index of element which needs to be deleted from array
     * @param {Array.<Object>} ar Complete array of links and slug object
     */

    const handleDelete = (index, ar) => {
        ar.splice(index,1)
        // handlePropertyChange('linkClicked', [...ar], setCustomSettings)
        updateLinksArray([...ar])
    };



    /**
     * Updates the reducer object with new 'linkClicked' array
     * @param {Array.<Object>} newArray Updated array which needs to be saved
     */
    const updateLinksArray = (newArray)=>{
        handlePropertyChange('linkClicked', newArray, setCustomSettings)
    }



    return(
        <Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Typography className={classes.boxTitle}>Features</Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={3}>
                            <Typography variant="body2">Default slug</Typography>
                        </Grid>
                        <Grid item md={9}>
                            <InputBox 
                                name="contentText"
                                value={contentText}
                                onChange={handlePropertyChange}
                                dispatcher={setCustomSettings}
                                maxLength={20}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item md={3}>
                            <Typography variant="body2">Other text</Typography>
                        </Grid>
                        <Grid item md={4} style={{paddingLeft: '4px'}}>
                            <InputBox 
                                name="otherText"
                                value={otherText}
                                onChange={handlePropertyChange}
                                dispatcher={setCustomSettings}
                                maxLength={25}
                            />
                        </Grid>
                        <Grid item md={5}>
                            <InputBox 
                                name="bulkText"
                                value={bulkText}
                                onChange={handlePropertyChange}
                                dispatcher={setCustomSettings}
                                maxLength={15}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item md={3}>
                            <Typography variant="body2">Identify user as</Typography>
                        </Grid>
                        <Grid item md={9}>
                            <InputBox 
                                name="visitorText"
                                value={visitorText}
                                onChange={handlePropertyChange}
                                dispatcher={setCustomSettings}
                                maxLength={20}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item md={12}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item md={5}>
                            <Typography variant="body2" style={{whiteSpace:'nowrap'}}>
                                Display click data from the last
                                {/* <a href={helpArticleLinks.faq.notification.featuresBulk} target="_blank">
                                    <img src = {HelpQuestion}  style={{ height: "11px",marginLeft:"2%"}}/>
                                </a> */}
                                <InfluenceHelpIcon href={helpArticleLinks.faq.notification.featuresBulk} fontSize="tiny"/>
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <InputBox 
                                name="bulkData"
                                value={bulkData}
                                onChange={handlePanelStyleChange}
                                dispatcher={setCustomSettings}
                                type="number"
                                maxLength={10}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <ReactSelect 
                                width="120px" 
                                height="35px" 
                                value={selectDurationData}
                                name='selectDurationData'
                                dispatcher={setCustomSettings}
                                onChange={handlePanelStyleChange}
                                options={[
                                    {label: 'days', value: 'days'},
                                    {label: 'hours', value: 'hours'}
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems='center'>
                        <Grid item md={3}>
                            <Typography variant="body2">Time label</Typography>
                        </Grid>
                        <Grid item md={9}>
                            <InputBox 
                                name="bulkDaysLable"
                                value={bulkDaysLable}
                                onChange={handlePropertyChange}
                                dispatcher={setCustomSettings}
                                maxLength={15}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container md={12} alignItems="center">
                    <Typography variant="body2">Enter link URL and slug</Typography>
                </Grid>
                <Grid container item alignItems="center" spacing={2} >
                    <Grid item md={6}>
                        <InputBoxCopy
                            placeholder={"Enter URL"}
                            onChange={(e)=>setLinkUrl(e.target.value)}
                            value={linkUrl}
                            maxLength={200}
                            disabled={linkClicked.length>=5}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <InputBoxCopy
                            placeholder={"Enter Slug"}
                            onChange={(e)=>setSlug(e.target.value)}
                            value={slug}
                            disabled={linkClicked.length>=5}
                        />
                    </Grid>
                    <Grid item md={2}>
                        <Button variant="contained" onClick={handleLinkSeperator} disabled={linkClicked.length>=5}>Add</Button>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" item md={12} style={{padding:'8px 16px'}} spacing={2}>
                    <PageLinkChips
                        linksArray={linkClicked} 
                        handleDelete={handleDelete}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        input: {
            height: '35px',
            backgroundColor: '#fff',
            // marginTop: '4%',
            border: ' 1px solid hsl(0,0%,80%)',
            width: '100%',
            borderRadius: '4px',
            padding: '0% 3%',
            fontSize: '14px'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            // paddingBottom: '5%'
        },
    })
)

export default memo(FeaturesBox) ;