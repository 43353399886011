import React,{useState} from 'react'
import { Dialog, Box, Typography, createStyles, makeStyles, Button, CircularProgress, DialogTitle, DialogActions, DialogContent } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import TextInput from '../../../Globals/GlobalComponents/TextInput'
import ToggleSwitch from '../../../Globals/GlobalComponents/ToggleSwitch'
import { addNewMicropolicy } from '../actions';

const CreatePolicyDialog = (props) => {

    const {open, onClose} = props
    const classes = useStyles()
    const dispatch = useDispatch()
    const currentCampaign = useSelector(state =>(state.getIn(['cookieCampaigns','openedCookieCampaign'])))

    const [currentScreen, setCurrentScreen] = useState(1)
    const [policyName, setPolicyName] = useState("")
    const [policyDescription, setPolicyDescription] = useState("")
    const [toggle1Value, setToggle1Value] = useState(true)
    const [toggle2Value, setToggle2Value] = useState(false)
    const [loading, setLoading] = useState(false)



    const proceedHandler = () =>{
        setCurrentScreen(2)
    }


    const backHandler = () =>{
        setCurrentScreen(1)
    }

    const createPolicyHandler = () =>{
        dispatch(addNewMicropolicy(policyName, policyDescription, toggle1Value, true, toggle2Value,currentCampaign, setLoading, afterCreation ))
    }

    const afterCreation = (policyId) =>{
        browserHistory.push(`/cookie-monster/${currentCampaign._id}/micro-policies/${policyId}/details`)
        onClose()
    }

    return (
        <Dialog open={open} maxWidth="md" onClose={onClose} onExited={backHandler} maxWidth={"md"} classes={{ paper: classes.dialogPaper }}>
            <Box>
                <DialogTitle>
                    <Typography variant="h3">
                        Create Micropolicy
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box className={classes.textInputContainer}>
                        <Typography variant="body1">
                            Name
                        </Typography>
                        <TextInput
                            placeholder="Policy Name"
                            onChange={(e)=>setPolicyName(e.target.value)}
                            value={policyName}
                        />
                        <Typography variant="caption">
                            The feature or category of features that require these data
                        </Typography>
                    </Box>

                    <Box className={classes.textInputContainer}>
                        <Typography variant="body1">
                            Description
                        </Typography>
                        <TextInput
                            placeholder="Policy Description"
                            onChange={(e)=>setPolicyDescription(e.target.value)}
                            value={policyDescription}
                        />
                        <Typography variant="caption">
                            Convince your users to opt in by focusing on the value that they’ll get from this feature
                        </Typography>
                    </Box>
                    <Box className={classes.toggleInputContainer}>
                        <Typography variant="body1">
                            Use cookies
                        </Typography>
                        <ToggleSwitch
                            checked={toggle1Value}
                            onChange={(e)=>setToggle1Value(e.target.checked)}
                        />
                    </Box>
                    <Box className={classes.toggleInputContainer}>
                        <Typography variant="body1">
                            Include in Cookie Widget
                        </Typography>
                        <ToggleSwitch
                            checked={toggle2Value}
                            onChange={(e)=>setToggle2Value(e.target.checked)}
                        />
                    </Box>
                    </DialogContent>
                    <DialogActions style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                        <Button variant="outlined" onClick={onClose} style={{width:'100px', marginRight:'1rem'}}>
                            Cancel
                        </Button>
                        <Button 
                            variant="contained" 
                            disabled={loading || !policyName || !policyDescription} 
                            onClick={createPolicyHandler}
                            style={{position:'relative', width:'100px'}}
                        >
                            Create
                            {
                                loading &&
                                    <CircularProgress size={17} style={{color:'#fff', position:'absolute'}}/>
                            }
                        </Button>
                    </DialogActions>
                
            </Box>

            <Box hidden={true} >
                <Typography variant="h5" style={{textAlign:'center'}}>
                    Pick an Icon
                </Typography>

                <Box style={{padding:'0px 6rem'}}>
                    <Typography>Icons.......</Typography>
                </Box>
            </Box>
        </Dialog>
    )
}



const useStyles = makeStyles(()=>
     createStyles({
        dialogPaper:{
            height:'fit-content',
            width:'850px',
            padding:'2rem 4rem'
        },
        textInputContainer:{
            display:'flex',
            justifyContent:'center',
            flexDirection:'column',
            marginTop:'1rem'
        },
        toggleInputContainer:{
            display:'flex',
            alignItems:'center',
            marginTop:'1rem',
            '& p':{
                width:'40%',
                minWidth:'200px'
            }
        }
    })
)

export default CreatePolicyDialog
