import React,{useState} from 'react'
import { Typography, Box, Button, makeStyles, createStyles, CircularProgress } from '@material-ui/core' 
import {deleteMicropolicy, getMicropolicies} from '../../actions'
import {useDispatch} from 'react-redux'
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { browserHistory } from 'react-router'
import {
    DialogBox,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogButton
} from '../../../../Globals/GlobalComponents/DialogBox';



function PolicyDelete(props) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [loading, setLoading]  = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [snackBarStatus, setSnackBarStatus]   =  useState(false)
	
    const handleDeleteMicropolicy = () =>{
        setLoading(true)
        dispatch(deleteMicropolicy(props.params.policyId,afterDeletion))
        dispatch(getMicropolicies(props.params.id))
    }

    const handleSnackBarClose = () =>{
		setSnackBarStatus(false)
    }

    const handleDialog = () =>{
        setOpenDialog(prev => setOpenDialog(!prev))
    }
	
    const afterDeletion = () =>{
        setLoading(false)
        setSnackBarStatus(true)
        handleDialog()
        browserHistory.push(`/cookie-monster/${props.params.id}/micro-policies`)
    }

    const {policyId} = props
    return (
        <Box>
            <Snackbar 
                open={snackBarStatus}
                autoHideDuration={5000} 
                onClose={handleSnackBarClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <Alert variant="filled" style={{background:'black'}}>
                    <div style={{display:'flex',width:'auto'}}>
                        {/* <div><DoneAllRoundedIcon style={{color:'#00b300',fontSize:'19px'}}/></div> */}
                        <div style={{fontSize:'11x',paddingLeft:'6px',fontWeight:'400'}}>{'Deleted successfully'}</div>
                    </div>
                </Alert>
            </Snackbar>
            <Typography variant="h5">
                Delete Micropolicy
            </Typography>
            <Typography style={{marginTop:'5px', marginBottom:'15px'}} variant="body2">
                This micropolicy will be removed from your account. Alternatively, you can hide this policy from cookie widget by disabling "Use Cookie" from "Details" section.
            </Typography>
            <Button variant="contained" onClick={handleDialog}>
                Delete
            </Button>
            <DialogBox onClose={handleDialog} open={openDialog}>
                <DialogTitle  onClose={handleDialog}>Alert!</DialogTitle>
                <DialogContent>
                    Are you sure to delete this micropolicy?
                </DialogContent>
                <DialogActions>
                    <DialogButton actionType={"secondary"} onClick={handleDialog}>No</DialogButton>
                    <DialogButton disabled={loading} actionType={"primary"} onClick={handleDeleteMicropolicy}>
                        Yes
                        {loading && <CircularProgress size={19} className={classes.buttonProgress}/>}
                    </DialogButton>
                </DialogActions>
            </DialogBox>
        </Box>
    )
}

const useStyles = makeStyles((theme)=>
    createStyles({
        buttonProgress: {
            color: "#fff",
            position: 'absolute',
        },
    })
)

export default PolicyDelete
