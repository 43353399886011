import React from 'react'
import { Box, makeStyles, createStyles, Dialog, Typography } from '@material-ui/core'
import clsx from 'clsx'

const VideoDemoModal = (props) => {
  const { open, onClose } = props
  const classes = useStyles()
  return (
    <Box
      onClick={onClose}
      className={clsx({ [classes.modalContainer]: open, [classes.hideModal]: !open })}
    >
      {open && (
        <iframe
          width="660"
          height="415"
          src="https://www.youtube.com/embed/Y_ngAOHgQDw?autoplay=1"
          title="Influence Demo"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    modalContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.4)',
      zIndex: '10000',
    },
    hideModal: {
      display: 'none',
    },
  })
)

export default VideoDemoModal
