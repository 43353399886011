import React, { useState, useEffect } from 'react';
import {Box,makeStyles,createStyles,Dialog,Typography,Divider,Grid,Button} from '@material-ui/core';
import SelectBox from '../SelectBox';
import TextInput from '../.././Globals/GlobalComponents/TextInput';
import { postGoalData } from './action';
import { useDispatch,useSelector } from 'react-redux';
import PageLinkChips from './PageLinkChips';


const GoalDialogForm = (props) =>{

    const classes = useStyles();
    const dispatch = useDispatch();
    //const goalData = useSelector(state => (state.getIn(['GoalPageData','goalPageData']))) || [];
     
    // destructure of props;
    const { 
        open,
        closeHandler,
        handleYourGoal,
        //handleCampaignName,
        handleUrl,
        handlePostGoalRequest,
        goalUrl,
        goalValue,
        linksArray,
        setLinksArray,
        goalType,
        handleLinkSeperator,
        linkUrl,
        setLinkUrl,
        handleLinkAdd
    } = props;
   
    return(
        <Box> 
            <Dialog
                open={open}
                keepMounted
                maxWidth="md"
                classes={{paper: classes.dialogPaper}}
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
            >
                <Box padding='5% 10%'>
                    <Grid container direction="column" spacing={4}>
                            <Grid item md={12} style={{paddingBottom: '0'}} >
                                <Typography variant="h3"  className={classes.heading}>Update your goal</Typography>
                            </Grid>
                            <Divider classes={{root: classes.divider}} />
                            <Grid item md={12} style={{paddingBottom: '0'}}>
                                <Typography  className={classes.inputName}>Name your goal</Typography>
                                <TextInput className={classes.inputStyle} value={goalValue} onChange={handleYourGoal} placeholder={goalValue} />
                            </Grid>
                            <Grid item className={classes.inputContainers} md={12}>
                                <Typography  className={classes.inputName}>Enter url</Typography>
                                <TextInput 
                                    className={classes.inputStyle} 
                                    value={goalType === "Link Click"? linkUrl : goalUrl} 
                                    onChange={goalType === "Link Click" ? handleLinkAdd : handleUrl} 
                                    placeholder={goalType === "Link Click" ? "Enter page URLs" : goalUrl}
                                    onKeyDown={goalType === "Link Click" && handleLinkSeperator}
                                />
                            </Grid>
                            <Grid item className={classes.inputContainers} md={12}>
                                <PageLinkChips
                                    linksArray={linksArray || []}
                                    setLinksArray={setLinksArray}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <div className={classes.buttonBox}>
                                    <Button onClick={closeHandler} className={classes.button1} variant="outlined">Cancel</Button>
                                    <Button onClick={handlePostGoalRequest} className={classes.button2} variant="contained">Update Goal</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
            </Dialog>
       </Box>
    )
}

const useStyles = makeStyles(theme =>
    createStyles({
        buttonBox: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            height: '100px'

        },
        dialogPaper: {
            width: '850px',
            height:'auto'
        },
        divider: {
            margin: '4% 2%'
        },
        inputContainers:{
            paddingTop: '10px'
        },
        button1: {
            // height: '32px',
            // width:'42px',
            // paddingTop:'1%',
            // color:'#a7a7a7',
            // backgroundColor:'white',
            // fontSize:'12px !important',
            // textTransform:'initial',
            // borderRadius:'4px',
            // border: '1px solid rgba(167, 167, 167, 0.5) !important',
            marginRight: '12px',
            // "&:hover": {
            //     backgroundColor:'#dee0da'
            // },
            // "&:focus":{
            //     outline: 'none'
            // }
        },
        button2: {
            backgroundColor:'#082a52',
            height:'32px',
            width:'121px',
            fontSize:'12px !important',
            textTransform:'initial',
            borderRadius:'4px',
            "&focus": {
                outline:'none'
            }
        },
        inputName: {
            fontSize: '14px',
            paddingLeft: '3px'
        },
        heading:{
            fontWeight: 'bold'
        },
        inputStyle:{
            border: '1px solid hsl(0,0%,80%)',
            marginTop: '1%'
        }
    }))
export default GoalDialogForm;    