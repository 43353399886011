import axios from 'axios';
export const GET_ANALYTICS_DATA = 'GET_ANALYTICS_DATA';
export const GET_CAMPAIGN_ANALYTICS = 'GET_CAMPAIGN_ANALYTICS';
export const DELETE_SIGNUP = 'DELETE_SIGNUP';

export const getAnalyticsData = () => {
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;

    return(
        async(dispatch) => {
            const res = await axios({
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` },
                url: '/report/5beeb329d7ba0200113d6fc4?duration=week'
            }).catch(err => {
                throw err
            })
            dispatch({type: GET_ANALYTICS_DATA, payload: res.data})
        }
    );
}

export const getCamapaignAnalytics = (id, selectedDuration) =>{
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;

    return(
        async(dispatch) =>{
            const res = await axios ({
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                url: `/campaign/analytics/${id}/${selectedDuration}`
            }).catch(err =>{throw err})
            dispatch({type: GET_CAMPAIGN_ANALYTICS, payload: res.data})
        }
    )
}

export const deleteSignupData = (id, type, trackingId, olderData,setOpenToaster) =>{
    const authToken = JSON.parse(localStorage.getItem('authToken'));
    const token = authToken ? authToken.token : null;
    const fn = (res, oldData) =>{
        const n = oldData.currentTimeWindowData.signupDetails.filter(item => item._id !== res.data._id)
        let newData = oldData
        newData.currentTimeWindowData.signupDetails = n
        return newData
    }
    return(
        async(dispatch)=>{
            const res = await axios ({
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                url: `/elasticsearch/delete/signups/${id}/${type}/${trackingId}`
            }).catch(err => {throw err})
            dispatch({type: DELETE_SIGNUP, payload: fn(res, olderData)})
            setOpenToaster(true)
        }
    )
}