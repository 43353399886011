import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { CircularProgress, makeStyles } from '@material-ui/core'


const LoadingButton = (props) =>{
  const classes = useStyles()
  const { loading, ...buttonProps } = props



  return(
    <Button classes={{root:classes.root}} {...buttonProps} disabled={props.loading || props.disabled} >
      {props.children}
      {
        loading &&
          <CircularProgress classes={{root:classes.progressRoot}} size={19}  aria-busy={true} style={{color:'#fff'}} />
      }
    </Button>
  )
}


const useStyles = makeStyles({
  root:{
    position:'relative'
  },
  progressRoot:{
    position:'absolute'
  }
})

export default LoadingButton