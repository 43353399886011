import React, { useState, Fragment, useEffect, useReducer, memo } from 'react';
import { makeStyles, createStyles, Typography, Box, Grid, InputBase, Checkbox, Button, IconButton } from '@material-ui/core';
import {languageData} from '../../../../Globals/GlobalComponents/languages';
import CircularProgress from '@material-ui/core/CircularProgress';
import BasicBox from './BasicBox';
import AppearanceBox from './AppearanceBox';
import TextBox from './TextBox';
import FeaturesBox from './FeaturesBox';
import PoweredByBox from './PoweredByBox';
import InputBox from '../../../../Globals/GlobalComponents/InputBox';
import CustomHelpIcon from '../../../../Globals/GlobalComponents/CustomHelpIcon';
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import { useSelector } from 'react-redux';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';


const BulkNotificationRules = (props) => {
    const classes = useStyles();
    const {
        handlePanelStyleChange,
        handlePropertyChange,
        bulkSettings,
        setBulkSettings,
        notificationConfiguration,
        campaignInfo,
        notificationType,
        handleLanguageChange,
        handleNotificationSubmit,
        loading,
        visitorText,
        otherText,
        contentText,
        bulkText,
        bulkDaysLable,
        recentText2,
        brandingLocked,
        onClose
    } = props;
    
    const [backgroundColorPopper, setBackgroundColorPopper] = useState(false);
    const [iconColorPopper, setIconColorPopper] = useState(false);
    const [fontColorPopper, setFontColorPopper] = useState(false);
    const [checkBoxStatus, setCheckBoxStatus] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [bgColor, setBgColor] = useState("rgba(0,0,0,0)")
    const [iconBgColor, setIconBgColor] = useState("rgba(0,0,0,0)")
    const [fontBgColor, setFontBgColor] = useState("rgba(0,0,0,0)")
    const user = useSelector(state=>(state.getIn(['auth','user'])))



    const handleBackgroundColorPopper = (event) => {
        if(backgroundColorPopper === false) {
            setAnchorEl(event.currentTarget)
            setBackgroundColorPopper(true);
        } else {
            setBackgroundColorPopper(false)
            setAnchorEl(null)
        }
    }

    const handleIconColorPopper = (event) => {
        if(iconColorPopper === false) {
            setIconColorPopper(true);
            setAnchorEl(event.currentTarget)
        } else {
            setIconColorPopper(false)
            setAnchorEl(null)
        }
    }

    const handleFontColorPopper = (event) => {
        if(fontColorPopper === false) {
            setFontColorPopper(true);
            setAnchorEl(event.currentTarget)
        } else {
            setFontColorPopper(false)
            setAnchorEl(null)
        }
    }

    const handleCheckBox = () => {
        if(checkBoxStatus === false) {
            setCheckBoxStatus(true);
        } else {
            setCheckBoxStatus(false)
            handlePropertyChange('notificationUrl', '', setBulkSettings, )
        }
    }

    useEffect(()=>{
        if(bulkSettings.notificationUrl.length >0){
            setCheckBoxStatus(true)
        }
    },[bulkSettings.notificationUrl])


    const rgbSelector = (obj, stateName) =>{
        stateName(`rgba(${obj.r},${obj.g},${obj.b},${obj.a})`)
    }

    const handleImageUpload = (file) => {
        handlePanelStyleChange('image', file.base64, setBulkSettings)
    }

    return(
        <Box>
            
            <BasicBox 
                languageData={languageData}
                langName={bulkSettings.langName}
                notificationSize={bulkSettings.notificationSize}
                bulkVersion={bulkSettings.bulkVersion}
                setBulkSettings={setBulkSettings}
                handleLanguageChange={handleLanguageChange}
                handlePropertyChange={handlePropertyChange}
            />

            <AppearanceBox 
                bulkSettings={bulkSettings}
                handlePanelStyleChange={handlePanelStyleChange}
                setBulkSettings={setBulkSettings}
                backgroundColorPopper={backgroundColorPopper}
                bgColor={bgColor}
                handleBackgroundColorPopper={handleBackgroundColorPopper}
                anchorEl={anchorEl}
                rgbSelector={rgbSelector}
                setBgColor={setBgColor}
                handleImageUpload={handleImageUpload}
            />

            <TextBox 
                fontWeight={bulkSettings.panelStyle.fontWeight}
                fontFamily={bulkSettings.panelStyle.fontFamily}
                color={bulkSettings.panelStyle.color}
                setBulkSettings={setBulkSettings}
                handlePanelStyleChange={handlePanelStyleChange}
                fontColorPopper={fontColorPopper}
                fontBgColor={fontBgColor}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setFontBgColor={setFontBgColor}
                setFontColorPopper={setFontColorPopper}
            />

            <FeaturesBox 
                contentText={contentText}
                handlePropertyChange={handlePropertyChange}
                setBulkSettings={setBulkSettings}
                otherText={otherText}
                bulkText={bulkText}
                visitorText={visitorText}
                handlePanelStyleChange={handlePanelStyleChange}
                bulkDaysLable={bulkSettings.bulkDaysLable}
                bulkData={bulkSettings.panelStyle.bulkData}
                selectDurationData={bulkSettings.panelStyle.selectDurationData}
            />

            <PoweredByBox
                setBulkSettings={setBulkSettings}
                handlePropertyChange={handlePropertyChange}
                recentText2={recentText2}
                poweredBy={bulkSettings.poweredBy}
                poweredByLink={bulkSettings.poweredByLink}
                togglePoweredBy={bulkSettings.togglePoweredBy}
                brandingLocked={brandingLocked}
            />

            <Box borderTop='1px solid #f5f6f7' padding='5% 0%'>
                <Grid container>
                    <Grid item md={12}>
                        <Grid container alignItems='center'>
                            <Grid item >
                                <Checkbox 
                                    checked={checkBoxStatus}
                                    onChange={handleCheckBox}
                                />
                            </Grid> 
                            <Grid item md={5}>
                                <Typography className={classes.boxTitle}>
                                    Notifications Clickable
                                    {/* <a href={helpArticleLinks.faq.notification.clickable} target="_blank">
                                        <img src = {HelpQuestion}  style={{ height: "13px",marginLeft:"2%"}}/>
                                    </a> */}
                                    <InfluenceHelpIcon href={helpArticleLinks.faq.notification.clickable} />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {checkBoxStatus &&
                        <Grid item md={12} style={{paddingTop: '9px', paddingLeft: '0'}}>
                            <Grid container alignItems="center">
                                <Grid item style={{paddingLeft:'11px'}} md={3}>
                                    <Typography variant="body2">HyperLink</Typography>
                                </Grid>
                                <Grid item md={9} style={{paddingLeft: '15px'}}>
                                    <InputBox
                                        name="notificationUrl"
                                        value={bulkSettings.notificationUrl}
                                        dispatcher={setBulkSettings}
                                        onChange={handlePropertyChange}
                                        maxLength={150}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Box>
            <Box className={classes.saveBtnContainer}>

                <Button variant="outlined" onClick={onClose} className={classes.cancelBtn}>
                    Close
                </Button>

                <Button 
                    className={classes.saveBtn}
                    variant="contained"
                    disabled={loading || user.role.name === "campaignB"}
                    onClick={
                        () => handleNotificationSubmit(
                                notificationConfiguration[0]._id, 
                                bulkSettings, 
                                campaignInfo._id,
                                notificationType[0]._id,
                                campaignInfo.websiteUrl,
                                campaignInfo.trackingId
                            )
                    }

                >
                    Save Changes
                  {loading &&  <CircularProgress classes={{colorPrimary: classes.circularProgress}} size={19} thickness={5} />}
                </Button>
                
            </Box>
        </Box>
        
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        input: {
            height: '35px',
            backgroundColor: '#fff',
            // marginTop: '4%',
            border: '1px solid hsl(0,0%,80%)',
            width: '100%',
            borderRadius: '4px',
            padding: '0% 3%',
            fontSize: '14px'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            // paddingBottom: '5%'
        },
        circularProgress: {
            color: '#fff',
            position:'absolute',
            top:'52%',
            left:'52%',
            marginTop:-12,
            marginLeft:-12
        },
        saveBtnContainer:{
            position: 'fixed',
            width: '46%',
            bottom: '0',
            zIndex: '2',
            backgroundColor: '#fff',
            padding: '17px 0px',
            marginLeft: '-2.8%',
            borderTop: '1px solid #c6c6c6',
            display: 'flex',
            justifyContent: 'flex-end'
        },
        saveBtn:{
            // textTransform: 'initial',
            // fontWeight: '200',
            // fontSize: '12px',
            position:'relative',
            margin: '0 4%'
        },
        cancelBtn:{
            // backgroundColor: '#fff',
            // border: '1px solid #c6c6c6 !important',
            // color: '#484848',
            // textTransform: 'unset',
            // width: '85px',
            // fontWeight: '400',
            // '&:hover':{
            //     backgroundColor: '#c6c6c686',
            //     color: '#484848',
            // }
        }
    })    
)

export default memo(BulkNotificationRules);