import { fromJS } from 'immutable';

const action = name => `/integrations/${name}`;

export const ADD_MAIL_CHIMP = action('ADD_MAIL_CHIMP');
export const GET_MAIL_CHIMP = action('GET_MAIL_CHIMP');
export const DELETE_MAIL_CHIMP = action('DELETE_MAIL_CHIMP');
export const SUCCESS_MAIL_CHIMP = action('SUCCESS_MAIL_CHIMP');
export const POP_MAIL_CHIMP = action('POP_MAIL_CHIMP');

export const ADD_MAILER_LITE = action('ADD_MAILER_LITE');
export const GET_MAILER_LITE = action('GET_MAILER_LITE');
export const DELETE_MAILER_LITE = action('DELETE_MAILER_LITE');
export const SUCCESS_MAILER_LITE = action('SUCCESS_MAILER_LITE');
export const POP_MAILER_LITE = action('POP_MAILER_LITE');

export const ADD_ACTIVE_CAMPAIGN = action('ADD_ACTIVE_CAMPAIGN');
export const GET_ACTIVE_CAMPAIGN = action('GET_ACTIVE_CAMPAIGN');
export const DELETE_ACTIVE_CAMPAIGN = action('DELETE_ACTIVE_CAMPAIGN');
export const SUCCESS_ACTIVE_CAMPAIGN = action('SUCCESS_ACTIVE_CAMPAIGN');
export const POP_ACTIVE_CAMPAIGN = action('POP_ACTIVE_CAMPAIGN');

export const ADD_CONVERT_KIT = action('ADD_CONVERT_KIT');
export const GET_CONVERT_KIT = action('GET_CONVERT_KIT');
export const GET_INFUSION_SOFT = action('GET_INFUSION_SOFT');
export const DELETE_CONVERT_KIT = action('DELETE_CONVERT_KIT');
export const DELETE_INFUSION_SOFT = action('DELETE_INFUSION_SOFT');

export const ADD_SEND_IN_BLUE = action('ADD_SEND_IN_BLUE');
export const GET_SEND_IN_BLUE = action('GET_SEND_IN_BLUE');
export const DELETE_SEND_IN_BLUE = action('DELETE_SEND_IN_BLUE');
export const SUCCESS_SEND_IN_BLUE = action('SUCCESS_SEND_IN_BLUE');
export const POP_SEND_IN_BLUE = action('POP_SEND_IN_BLUE');


export const SUCCESS_CONVERT_KIT = action('SUCCESS_CONVERT_KIT');
export const POP_CONVERT_KIT = action('POP_CONVERT_KIT');

export const SUCCESS_INFUSION_SOFT = action('SUCCESS_INFUSION_SOFT');
export const POP_INFUSION_SOFT = action('POP_INFUSION_SOFT');

export const GET_SHOPIFY = action('GET_SHOPIFY');
export const SUCCESS_SHOPIFY = action('SUCCESS_SHOPIFY');
export const UPDATE_SHOPIFY_WEBHOOK = action('UPDATE_SHOPIFY_WEBHOOK');

export const GET_THRIVE = action('GET_THRIVE');
export const SUCCESS_THRIVE = action('SUCCESS_THRIVE');
export const ADD_THRIVE_WEBHOOK = action('ADD_THRIVE_WEBHOOK');
export const UPDATE_THRIVE_WEBHOOK = action('UPDATE_THRIVE_WEBHOOK');
export const DELETE_THRIVE = action('DELETE_THRIVE');

export const ADD_INTERCOM = action('ADD_INTERCOM');
export const GET_INTERCOM = action('GET_INTERCOM');
export const DELETE_INTERCOM = action('DELETE_INTERCOM');

export const SUCCESS_INTERCOM = action('SUCCESS_INTERCOM');

export const addMailChimp = (id, campaign) => ({ type: ADD_MAIL_CHIMP, id, campaign });
export const getMailChimp = (campaignId) => ({ type: GET_MAIL_CHIMP, campaignId });
export const deleteMailChimp = (id, index) => ({ type: DELETE_MAIL_CHIMP, id, index });
export const succeessMailChimp = (data) => ({ type: SUCCESS_MAIL_CHIMP, data });
export const popMailChimp = (index) => ({ type: POP_MAIL_CHIMP, index });

export const addMailerLite = (id, campaign, groupId) => ({ type: ADD_MAILER_LITE, id, campaign, groupId });
export const getMailerLite = (campaignId) => ({ type: GET_MAILER_LITE, campaignId });
export const deleteMailerLite = (id, index) => ({ type: DELETE_MAILER_LITE, id, index });
export const succeessMailerLite = (data) => ({ type: SUCCESS_MAILER_LITE, data });
export const popMailerLite = (index) => ({ type: POP_MAILER_LITE, index });

export const addActiveCampaign = (id, campaign, listId) => ({ type: ADD_ACTIVE_CAMPAIGN, id, campaign, listId });
export const getActiveCampaign = (campaignId) => ({ type: GET_ACTIVE_CAMPAIGN, campaignId });
export const deleteActiveCampaign = (id, index) => ({ type: DELETE_ACTIVE_CAMPAIGN, id, index });
export const succeessActiveCampaign = (data) => ({ type: SUCCESS_ACTIVE_CAMPAIGN, data });
export const popActiveCampaign = (index) => ({ type: POP_ACTIVE_CAMPAIGN, index });

export const addConvertKit = (id, campaign, formId) => ({ type: ADD_CONVERT_KIT, id, campaign, formId });
export const getConvertKit = (campaignId) => ({ type: GET_CONVERT_KIT, campaignId });
export const getInfusionSoft = (trackingId) => ({ type: GET_INFUSION_SOFT, trackingId });
export const deleteConvertKit = (id, index) => ({ type: DELETE_CONVERT_KIT, id, index });
export const deleteInfusionSoft = (id, index) => ({ type: DELETE_INFUSION_SOFT, id, index });

export const getSendInBlue = (campaignId) => ({ type: GET_SEND_IN_BLUE, campaignId });
export const addSendInBlue = (id, campaign) => ({ type: ADD_SEND_IN_BLUE, id, campaign });
export const deleteSendInBlue = (id, index) => ({ type: DELETE_SEND_IN_BLUE, id, index });
export const succeessSendInBlue = (data) => ({ type: SUCCESS_SEND_IN_BLUE, data });
export const popSendInBlue = (index) => ({ type: POP_SEND_IN_BLUE, index });

export const succeessConvertKit = (data) => ({ type: SUCCESS_CONVERT_KIT, data });
export const popConvertKit = (index) => ({ type: POP_CONVERT_KIT, index });

export const succeessInfusionSoft = (data) => ({ type: SUCCESS_INFUSION_SOFT, data });
export const popInfusionSoft = (index) => ({ type: POP_INFUSION_SOFT, index });

export const getShopify = (campaignId) => ({ type: GET_SHOPIFY, campaignId });
export const succeessShopify = (data) => ({ type: SUCCESS_SHOPIFY, data });
export const updateShopifyWebhook = (name, state,shopify) => ({ type: UPDATE_SHOPIFY_WEBHOOK, name, state,shopify });

export const getThriveCart = (campaignId) => ({ type: GET_THRIVE, campaignId });
export const succeessThrive = (data) => ({ type: SUCCESS_THRIVE, data });
export const addThriveWebhook = (data) => ({ type: ADD_THRIVE_WEBHOOK, data });
export const updateThriveCartWebhook = (name, state,thrivecart) => ({ type: UPDATE_THRIVE_WEBHOOK, name, state,thrivecart });
export const deleteThriveCart = (id) => ({ type: DELETE_THRIVE, id });

export const addIntercom = (id, campaign) => ({ type: ADD_INTERCOM, id, campaign });
export const getIntercom = (campaignId) => ({ type: GET_INTERCOM, campaignId });
export const deleteIntercom = (id, index) => ({ type: DELETE_INTERCOM, id, index });

export const succeessIntercom = (data) => ({ type: SUCCESS_INTERCOM, data });

const initialState = fromJS({
  mailchips: [],
  mailerlites: [],
  activecampaigns: [],
  convertkits: [],
  infusionsofts:[],
  sendinblues:[],
  intercoms:[]
});

const integrations = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_MAIL_CHIMP:
      return state.set('mailchimps', action.data);
    case POP_MAIL_CHIMP:
      return state.set('mailchimps', state.get('mailchimps').slice(0, action.index).concat(state.get('mailchimps').slice(action.index+1)));
    
    case SUCCESS_MAILER_LITE:
      return state.set('mailerlites', action.data);  
    case POP_MAILER_LITE:
      return state.set('mailerlites', state.get('mailerlites').slice(0, action.index).concat(state.get('mailerlites').slice(action.index+1)));

    case SUCCESS_ACTIVE_CAMPAIGN:
      return state.set('activecampaigns', action.data);  
    case POP_ACTIVE_CAMPAIGN:
      return state.set('activecampaigns', state.get('activecampaigns').slice(0, action.index).concat(state.get('activecampaigns').slice(action.index+1)));
      
    case SUCCESS_CONVERT_KIT:
      return state.set('convertkits', action.data);  
    case SUCCESS_INFUSION_SOFT:
      return state.set('infusionsofts', action.data);        
    case POP_CONVERT_KIT:
      return state.set('convertkits', state.get('convertkits').slice(0, action.index).concat(state.get('convertkits').slice(action.index+1)));
    case POP_INFUSION_SOFT:
      return state.set('infusionsofts', state.get('infusionsofts').slice(0, action.index).concat(state.get('infusionsofts').slice(action.index+1)));
    
    case SUCCESS_SEND_IN_BLUE:
      return state.set('sendinblues', action.data);  
    case POP_SEND_IN_BLUE:
      return state.set('sendinblues', state.get('sendinblues').slice(0, action.index).concat(state.get('sendinblues').slice(action.index+1)));

    case SUCCESS_SHOPIFY:
      return state.set('shopify', action.data);  
      
    case SUCCESS_THRIVE:
      return state.set('thrivecart', action.data);  

    case SUCCESS_INTERCOM:
      return state.set('intercoms', action.data);  
      
    default:
      return state;
  }
};

export default integrations;
