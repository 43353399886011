import React from 'react';
import { Box, makeStyles, createStyles, Paper, Typography } from '@material-ui/core';

const Cta  = (props) => {
    const classes = useStyles();
    const {
        ctaBgColor,
        ctaTextColor,
        ctaText,
        ctaRadius,
        ctaUrl,
        ctaPosition
    } = props;

   
    return(
        <a href={ctaUrl} target="_blank" rel="noopener noreferrer">
            <Box 
                component={Paper} 
                width="76px" 
                height="20px" 
                position="relative"
                marginTop="-41px"
                left = "130px"
                bottom = "-15px"
                display="flex" 
                justifyContent="center" 
                alignItems="center"
                bgcolor= {ctaBgColor ? `rgb(${ctaBgColor.r}, ${ctaBgColor.g}, ${ctaBgColor.b})` : "#fff"}
                borderRadius={ctaRadius}
                color={ctaTextColor ? `rgb(${ctaTextColor.r}, ${ctaTextColor.g}, ${ctaTextColor.b})` : "#666"}
                className={
                    ctaPosition ? classes.top : classes.bottom
                }
            >
                <Typography style={{fontSize:"10px", color: ctaTextColor ? `rgb(${ctaTextColor.r}, ${ctaTextColor.g}, ${ctaTextColor.b})`: '#666'}}>
                    {ctaText}
                </Typography>
            </Box>
        </a>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        top: {
            marginRight: "30px", 
            top: "-60px" 
        },
        bottom: {
            marginRight: "30px", 
            bottom: '-50px'
        },
    })    
)


export default Cta ;