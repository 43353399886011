import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Link } from 'react-router';
import { Box, makeStyles, createStyles, Paper, Typography, Checkbox, Button, CircularProgress, Input,Card,withStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import TextInput from '../../../Globals/GlobalComponents/TextInput'
import { validateEmail, validatePassword, register, registerUpdation, INVALID_PASSWORD_MSG, validateWebsite } from 'services/FormUtils';
import { store } from 'App.js';
import { loaded } from 'ducks/loading';
import { loginSuccess } from 'ducks/auth';
import { browserHistory } from 'react-router';
import { base } from 'services/api';
import { Daydeal, Anabolic, Cococo, Goso, Littlebrand, Workinghouse, Meatstick, Mweb, Sengerio, Socialbu, Storyvault, Zaaptv } from 'img';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import './main.css'
import SelectBox from '../../SelectBox';
import { storeToken } from '../../../services/Request';
import { putSignup } from '../action';
import { useDispatch } from 'react-redux';

const DetailsScreen = () => {

	const classes = useStyles()

	const [enteredEmail, setEnteredEmail] = useState("ayz@gmail.co.in")
	const [enteredWebsiteUrl, setEnteredWebsiteUrl] = useState("")
	const [enteredFirstName, setEnteredFirstName] = useState("")
	const [enteredPhoneNumber, setEnteredPhoneNumber] = useState("")
	const [enteredPassword, setEnteredPassword] = useState("")
	const [error, setError] = useState("")
	const [errorWebsiteUrl, setErrorWebsiteUrl] = useState("")
	const [errorEmail, setErrorEmail] = useState("")
	const [errorPassword, setErrorPassword] = useState("")
	const [phoneValue, setPhoneValue] = useState("")
	const [dialCode, setDialCode] = useState("")
	const [loading, setLoading] = useState(false)
	const [defaultCountry, setDefaultCountry] = useState('')
	const [progressValue,setprogressValue] = useState(0);
	const [trafficCategory,setTrafficCategory] = useState('0')
	const [id, setId] = useState('0')

	const dispatch = useDispatch()


	/**
	 * Finds useless url.
	 * @param {String} url Website URL 
	 */
	const uselessUrlFinder = (url) =>{
		const uselessUrls = [
			"google.com",
			"facebook.com",
			"instagram.com"
		]

		return (uselessUrls.includes(url))
	}
	
	


useEffect(()=>{
	//var url_string = window.location.href
	//var url = new URL(url_string);
	//var auth_token = url.searchParams.get("auth_token");
	//var email = url.searchParams.get("email");
	//var id = url.searchParams.get("id")
	//console.log(auth_token,email)
	//storeToken(auth_token)
	//setEnteredEmail(email)
	//setId(id)

	storeToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZjY0Nzk4ZTFkMmMzMTAwMWMzNjlhMmIiLCJpZCI6IjVmNjQ3OThlMWQyYzMxMDAxYzM2OWEyYiIsImlhdCI6MTYwMDQyMDI0OCwiZXhwIjoxNjAzMDEyMjQ4fQ.8UpPNkyCxYIPuRT2ZAbWekw4hH_22Irkam2txtbUIZY")
	setEnteredEmail("agency12356@gmail.co.in")
	setId("5f64798e1d2c31001c369a2b")
	
},[])


	const websiteUrlInputHandler = (e) => {
		handleWebsiteUrlBlurExp(e)
		var url = e.target.value;
		var host = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
		setEnteredWebsiteUrl(host)
	}


	const firstNameInputHandler = (e) => {
		if(e.target.value === " "){
			e.target.value.replace("")
			setEnteredFirstName(e.target.value)
		}
		else{
		setEnteredFirstName(e.target.value)
		}
	}



	const phoneNumberInputHandler = (value) => {
		if(value === " "){
			value.replace("")
			setEnteredPhoneNumber(value)
		}
		else{
		setEnteredPhoneNumber(value)
		}
	}


	const emailInputHandler = (e) =>{
      
        handleEmailBlur(e)
        setEnteredEmail(e.target.value)
       
    }

    const handleEmailBlur = (e) =>{
        if(!e.target.value){
            setErrorEmail('Email id is required')
        } else if(!validateEmail(e.target.value)){
            setErrorEmail('Enter a valid Email id')
        }else{
            setErrorEmail("")
            if(!errorPassword){
                setError("")
            }
        }
    }

	const handleValue = (value) =>{

	}


	const handleWebsiteUrlBlurExp = (e) =>{
		let urlString = e.target.value
		if(!validateWebsite(urlString)){
			setErrorWebsiteUrl("Enter a valid URL")
		} else if(uselessUrlFinder(urlString)){
			setErrorWebsiteUrl("Enter your Website URL")
		} else{
			setErrorWebsiteUrl("")
		}
	}

	const handlePasswordBlur = (e) =>{
        if(!e.target.value){
            setErrorPassword("Password required")
        } else if(!validatePassword(e.target.value)){
            setErrorPassword("Password must be between 4 and 40 digits long")
        }else{
            setErrorPassword("")
            if(!errorEmail){
                setError("")
            }
        }
    }

	// const passwordInputHandler = (e) => {
	// 	setEnteredPassword(e.target.value)
	// }

	const passwordInputHandler = (e)=>{
        if(e.target.value === " " || e.target.value.includes(" ")){
            setErrorPassword("Password can not have space")
        }
        else{
        handlePasswordBlur(e)
        setEnteredPassword(e.target.value)
        }
    }

	// const handleProgressAndSubmit = () => {

	// 	trafficCategory && !errorWebsiteUrl ? setprogressValue(100) : setprogressValue(0)

	// }
	const handleSubmit = (e) => {
		console.log("abc")
		e.stopPropagation(); e.preventDefault();
		
		// dispatch(putSignup(enteredWebsiteUrl, enteredFirstName, "+" + dialCode + "-" + phoneValue, enteredEmail, enteredPassword, affiliate, plan, trafficCategory))
		if (!enteredEmail || !enteredPassword || !enteredWebsiteUrl || !phoneValue || !enteredFirstName  || !trafficCategory) {
			setError("All fields are required")
			setprogressValue(0)
			setEnteredEmail("")
			setEnteredFirstName("")
			setEnteredPhoneNumber("")
			setEnteredWebsiteUrl("")
			setEnteredPassword("")
			setTrafficCategory('0')
			setErrorEmail("")
			setErrorPassword("")
			setErrorWebsiteUrl("")

		} else {
			setLoading(true)
			setError("")
			//const affiliate = props.location.query.affiliate
			//const plan = props.location.query.plan ? props.location.query.plan : 'freePlan'
			dispatch(putSignup(id,enteredWebsiteUrl, enteredFirstName, "+" + dialCode + "-" + phoneValue, enteredEmail, enteredPassword, "freePlan", trafficCategory,afterUpdationComplete))
		 }

		

	}

	const BorderLinearProgress = withStyles({
        root: {
          height: 14,
          borderRadius:'3px',
          backgroundColor:"#eff4f9",
        },
        bar: {
          borderRadius: 3,
          backgroundColor: 'rgb(100, 189, 255)',
        },
      })(LinearProgress);


	const afterUpdationComplete = () =>{
		setPanel("Select Product")
	}



	const handleCategoryChange = (value) => {
        setTrafficCategory(value);
    }

	useEffect(() => {
		const fetchIp = async () => {
			await axios
				.get('https://ipinfo.io')
				.then(res => {
					setDefaultCountry("" +res.data.country)
				})
				.catch(error => {throw error });
		};

		fetchIp();
	}, [defaultCountry])

	return (
		
		
			<Box>
			<form onSubmit={handleSubmit} style={{ padding: '0%'}} >
			<Typography className={classes.para}>Email</Typography>
							<Typography
								className={classes.signinInput}
								style={{marginLeft:'-5px'}}
							>
							{enteredEmail}
							</Typography>

							<Typography className={classes.para} style={{ marginTop: '10px' }}>Password</Typography>
							<TextInput
								type='password'
								placeholder="Password"
								name="password"
								onChange={passwordInputHandler}
								value={enteredPassword}
								onBlur={handlePasswordBlur}
								className={classes.signinInput}
							/>
							{errorPassword && <Typography style={{ color: '#ff5a50', fontSize:'13px',marginBottom:'0%',marginTop:'-5%'}}>{errorPassword}!</Typography>}

							<Typography className={classes.para} >Name*</Typography>
							<TextInput
								type='text'
								placeholder="Name"
								name="firstName"
								onChange={firstNameInputHandler}
								value={enteredFirstName}
								//onBlur={handleFirstNameBlur}
								className={classes.signinInput}
							/>
							<Typography className={classes.para} style={{ width: "50%" }}>Phone*</Typography>



							<IntlTelInput
								onPhoneNumberChange={(valid, phone, country) => { setDialCode(country.dialCode); setPhoneValue("" + phone); phoneNumberInputHandler(phone) }}
								name="phone"
								defaultCountry={defaultCountry.toLowerCase()}
								//preferredCountries={''}
								//onChange = {phoneNumberInputHandler}
								className={classes.weburl}
								value={enteredPhoneNumber}
							// onPhoneNumberChange={(isValid, phone, country) => {
							// 	setFieldValue(name, processNumber(isValid, phone, country));
							//   }}

							/>

						<Typography className={classes.para}>Website URL*</Typography>

						<TextInput
							type='text'
							placeholder="Website URL"
							name="websiteUrl"
							onChange={websiteUrlInputHandler}
							value={enteredWebsiteUrl}
							onBlur={handleWebsiteUrlBlurExp}
							className={classes.signinInput}
						/>
					{/*
					{/*errorWebsiteUrl && <Alert severity="error">{errorWebsiteUrl}!</Alert>}
					{errorWebsiteUrl && <Typography style={{ color: '#ff5a50', fontSize: '13px',marginBottom:'0%',marginTop:'-5%' }}>{errorWebsiteUrl}!</Typography>}
							<Typography className={classes.para} style={{ marginTop: '10px' }}>Avg Monthly Traffic</Typography>

							<SelectBox
								options={[
									{value:'0',label:'Select Avg Monthly Traffic'},
									{value:'1',label:'Less than 1k visitors/month'},
									{value:'2',label:'Between 1k and 10k Unique Visitors'},
									{value:'3',label:'Between 10k and 50k Unique Visitors'},
									{value:'4',label:'Between 50k and 200k Unique Visitors'},
									{value:'5',label:'Between 200k and 500k Unique Visitors'},
									{value:'6',label:'Between 500k and 1000k Unique Visitors'},
									{value:'7',label:'More than 1000k Unique Visitors'},
									{value:'8',label:'Not sure'}
								]}
								onChange={handleCategoryChange}
								value = {trafficCategory}
								className = {classes.TrafficInput}
								height = '34px'
							/>
							*/}

							<div style={{ 
								position: 'relative',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								 }}>
							<Button
								//disabled={loading || errorWebsiteUrl || trafficCategory  ===  '0' || !enteredWebsiteUrl || enteredWebsiteUrl  === " " || !validateWebsite(enteredWebsiteUrl)}
								onClick={handleSubmit}
								//className={classes.signupBtn}
								variant="contained"
								type="submit"
								style={{width: '7rem',
									height: '2.2rem',
									fontSize: '14px',
									margin: '4% 0%'
								}}
								


							>
								Finish
							</Button>
							{loading && <CircularProgress size={19} className={classes.circularProgress}/>}
						</div>

			
			

						

							

							
						

						


						
			</form>
                
			</Box>
		
		
	)
}




const useStyles = makeStyles(theme =>
	createStyles({
		para:{
			fontWeight: '600',
    		color: '#2b3945',
    		marginBottom: '4px',
			display: 'inline-block',
			marginTop: '20px',
			backgroundColor:'transparent'
			
		},
		signinInput:{
			padding: '4px 8px',
			height: '32px',
			fontSize: '14px',
			outline: '0',
			background: '#fff',
			border: '1px solid #a6aeb9',
			color: '#424852',
			boxshadow: 'inset 0 1px 1px 0 rgba(0,0,0,0.2)',
			fontweight: '500',
			backgroundColor: '#fff',
            borderRadius: '3px',
            border:'none',
            height:'2.0rem',
			fontSize:'0.8rem'
		},
		circularProgress:{
			width: '19px',
			height: '19px',
			marginLeft:'-5%',
			color: '#fff'
		},
		TrafficInput: {
			backgroundColor: '#fff',
            borderRadius: '3px',
            border:'none',
            padding: '0%',
            marginBottom:'5%',
			fontSize:'12px',
			width: "100%",
			outline:'none',
			fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'
		}
	}))

export default DetailsScreen