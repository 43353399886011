import { fromJS, Map } from 'immutable'

const initialState = fromJS({
  loading: false,
  campaigns: [],
  openedCampaign: {},
  error: {
    status: false,
    message: '',
  },
})

export const POLICY_CAMPAIGNS = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
  FETCHED: 'FETCHED_POLICY_CAMPAIGNS',
  CREATED: 'CREATED_POLICY_CAMPAIGN',
  UPDATED: 'UPDATED_POLICY_CAMPAIGN',
  DELETED: 'DELETED_POLICY_CAMPAIGN',
  SAVE_OPENED_CAMPAIGN: 'SAVE_POLICY_OPENED_CAMPAIGN',
}

const policyCampaignsReducer = (state = initialState, action) => {
  console.log('stat.get---', state.get('campaigns').toJS())

  const currState = state.get('campaigns').toJS()

  switch (action.type) {
    case POLICY_CAMPAIGNS.LOADING:
      // return state.set('loading', true).set('error', { status: false, message: '' })
      return state.merge({ loading: true, error: { status: false, message: '' } })
    case POLICY_CAMPAIGNS.LOADED:
      return state.set('loading', false)

    case POLICY_CAMPAIGNS.ERROR:
      return state.merge({ loading: false, error: { status: true, message: action.payload } })

    case POLICY_CAMPAIGNS.FETCHED:
      return state.merge({ campaigns: action.payload, loading: false })

    case POLICY_CAMPAIGNS.CREATED:
      // const oldCampaigns = state.get('campaigns').toJS()
      // oldCampaigns.push(action.payload)
      return state.merge({ campaigns: [...currState, action.payload], loading: false })

    case POLICY_CAMPAIGNS.UPDATED:
      // console.log('stat.get---', state.get('campaigns').toJS())
      // const oldData = state.get('campaigns').toJS()
      const index = currState.findIndex((item) => item._id === action.payload._id)
      // oldData[index] = action.payload
      const newState = [...currState]
      newState[index] = action.payload
      return state.merge({ campaigns: newState, loading: false })

    case POLICY_CAMPAIGNS.DELETED:
      return state.merge({
        loading: false,
        campaigns: currState.filter((state) => state._id !== action.payload._id),
      })

    case POLICY_CAMPAIGNS.SAVE_OPENED_CAMPAIGN:
      return state.merge({
        loading: false,
        openedCampaign: action.payload,
      })

    default:
      return state
  }
}

export default policyCampaignsReducer
