import { call, put, fork, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';

import * as api from 'services/api';
import * as actions from 'ducks/agencyPlan';
import { load, loaded } from 'ducks/loading';

const errorMessge = 'Please try again or refresh!';

const toastConfig = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 2000,
  className: 'toast-style'
};

function* fetch(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, 'agencyplan?trackingId='+action.trackingId);
    if (res.error)
      console.log(res.error);
    else
      yield put(actions.fetchSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* create(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'agencyplan', action.agencyPlan);
    if (res.error)
      console.log(res.error);
    else
      yield put(actions.createSuccess(res));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }

}

function* remove(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `agencyplan/${action.id}`);
    if(res.error)
      console.log(res.error);
    else 
      yield put(actions.popAgencyPlan(action.index));
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* update(action) {
  try {
    yield put(load());
    delete action.agencyPlan['_id'];
    const res = yield call(api.PUT, `agencyplan/${action.agencyPlan.id}`, action.agencyPlan);
    if(res.error)
      console.log(res.error);
    else {
      let agencyPlan = action.agencyPlan;
      agencyPlan['_id'] = agencyPlan.id;
      yield put(actions.fetchSuccess(res));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    } else {
      yield toast.error(error.message, toastConfig);
    }
  }
}


export function* watchFetch() {
  yield takeLatest(actions.FETCH, fetch);
}

export function* watchCreate() {
  yield takeLatest(actions.CREATE, create);
}

export function* watchRemove() {
  yield takeLatest(actions.REMOVE, remove);
}

export function* watchUpdate() {
  yield takeLatest(actions.UPDATE, update);
}


export default function* rootSaga() {
  yield [
    fork(watchFetch),
    fork(watchCreate),
    fork(watchRemove),
    fork(watchUpdate)
  ];
}
