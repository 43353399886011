import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button } from '@material-ui/core'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CopyBox from '../../../Globals/GlobalComponents/CopyBox';
import { cookiePublishAction, getPixelStatusOfCookieCampaign } from '../actions';
import Skeleton from '@material-ui/lab/Skeleton';
import LoadingButton from '../../../Globals/GlobalComponents/LoadingButton';


const CookieStep1 = (props) => {
    
    const {handleNext} = props
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [toastMssg, setToastMssg] = useState("")
    const [pixelStatus, setPixelStatus] = useState(false)
    const pixel = '<script src="https://cdn.useinfluence.co/static/influence-analytics.js?trackingId=INF-3gbfcjjsd6vhvo" async></script>'
    const campaignPixel = useSelector(state =>(state.getIn(['cookieCampaigns','cookieCampaignPixel'])))
    const currentCampaign = useSelector(state =>(state.getIn(['cookieCampaigns','openedCookieCampaign'])))


    useEffect(()=>{
        console.log('currentCampaign--',currentCampaign)
    },[currentCampaign])


    // useEffect(()=>{
    //     currentCampaign && currentCampaign !== undefined && currentCampaign.length == 0 &&
    //         dispatch(cookiePublishAction(currentCampaign.profile, currentCampaign.trackingId, currentCampaign.websiteUrl, afterActions))
    // },[currentCampaign])


    useEffect(()=>{
        typeof(currentCampaign) == "object" && currentCampaign !== undefined && 
        dispatch(cookiePublishAction(currentCampaign.profile, currentCampaign.trackingId, currentCampaign.websiteUrl, afterActions, false))
    },[currentCampaign])

    const afterActions = (err, response) =>{
        console.log(response.data)
        // setLoading(false)
    }

    const verifyPixelAndProcedd = (showToaster=true) =>{
        setLoading(true)
        getPixelStatusOfCookieCampaign(currentCampaign._id)
            .then(res=>{
                setPixelStatus(res.data.status)
                setLoading(false)
                return res.data
            })
            .then((data)=>{
                if(showToaster){
                    fireToaster(data.status)
                }
                if(data.status){
                    handleNext()
                }
            })
            .catch(err=>{
                setLoading(false)
                throw err
            })
            
    }


    // useEffect(()=>{
    //     if(toastMssg.status){
    //         dispatch({type:'FIRE_TOASTER', payload:{type:'success', message:'Pixel verfied'}})
    //     }else if (!toastMssg.status){
    //         dispatch({type:'FIRE_TOASTER', payload:{type:'error', message:'Yout pixel is not verfied yet'}})
    //     }
    // },[toastMssg.status])

    const fireToaster = (status) =>{
        console.log('status-----------------------', status)
        if(status){
            dispatch({type:'FIRE_TOASTER', payload:{type:'success', message:'Pixel verfied'}})
        }else{
            dispatch({type:'FIRE_TOASTER', payload:{type:'error', message:'Your pixel is not verified yet'}})
        }
    }


    return (
        <Box>
            <Typography variant="h5">
                Install pixel
            </Typography>

            <Box style={{marginTop:'2rem'}}>
                <Typography>
                    1. Copy and paste the following code into the <b>first line of your &lt;head&gt; tag</b>
                </Typography>

                <CopyBox loading={false} copyContent={campaignPixel ? `<script async src="${campaignPixel.url}"></script>` : "Loading......"} />

                <Box style={{backgroundColor:'rgba(255, 140, 0, 0.1)', display:'flex', alignItems:'center', padding:'10px 5px', borderLeft:'4px solid rgb(171, 101, 57)', borderRadius:'0px 4px 4px 0px', width:'100%', height:'45px'}}>
                    <WarningRoundedIcon style={{fontSize:'15px', color:'rgb(171, 101, 57)'}}/>
                    <Typography style={{marginLeft:'1rem', color:'rgb(171, 101, 57)',  fontSize:'14px'}}>
                        {`For Autoblocking to work correctly, this must be the first script in the <head> tag of your page.`}
                    </Typography>
                </Box>
            </Box>

            <LoadingButton loading={loading} variant="contained" style={{margin:'0 auto', display:'block', marginTop:'2rem', display:'flex'}} onClick={verifyPixelAndProcedd}>
                I've done this
            </LoadingButton>
        </Box>
    )
}

export default CookieStep1
