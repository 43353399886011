import axios from 'axios';

export const FETCH_DISPLAY_PAGE_DATA = 'FETCH_DISPLAY_PAGE_DATA';
export const POST_DISPLAY_PAGE_DATA = 'POST_DISPLAY_PAGE_DATA' ;
export const DELETE_DISPLAY_PAGE_DATA = 'DELETE_DISPLAY_PAGE_DATA';
export const RUN_ON_ALL_PAGE_DATA = 'RUN_ON_ALL_PAGE_DATA';

//get request called.
export const fetchDisplayPageData = (campaignInfo,setDataLoading) =>{
    // loader added during get request
    setDataLoading(true);
    return(
        async(dispatch)=>{
            // get request for excluded.
            const response1 = await axios({
                method: 'GET',
                url: `/notificationpath/rules/display_exclude/${campaignInfo.rule._id}`
            }).catch(error => {throw error})
            // get request for included.
            const response2 = await axios({
                method:'GET',
                url:`/notificationpath/rules/display/${campaignInfo.rule._id}`
            }).catch(error=>{throw error})
            // both excluded and included request called in same function to save data in same reducer.
            // It also further avoid the bug which could be possible manage different state in reducer.
            dispatch({type:FETCH_DISPLAY_PAGE_DATA, payload:response1.data.concat(response2.data)});
            //loader is removed after completion of get request.
            setDataLoading(false);
        }
       
    )    
};
// post request called .
export const postDisplayPageData = (campaignInfo,displayUrl,checkOnAllExclude) => {
    return(
        async(dispatch) => {
            const response = await axios({
                method: 'POST',
                url: '/notificationpath',
                data: {
                    url: displayUrl === '/' ? displayUrl :`${displayUrl}`,
                    status: 'unverified',
                    type: `${checkOnAllExclude ? 'display_exclude' : 'display' }`,
                    Class: 'warning',
                    rule: `${campaignInfo.rule._id}`,
                    domain: `${campaignInfo.websiteUrl}`,
                    campaignName: `${campaignInfo.campaignName}`,
                    trackingId: `${campaignInfo.trackingId}`,
                    websiteUrl: `${campaignInfo.websiteUrl}`
                }
            }).catch(error =>{throw error})
            dispatch({type:POST_DISPLAY_PAGE_DATA,payload:response.data});
        }
    )
}

export const postSubDomain = (campaignInfo,domainUrl) => {
    return(
        async(dispatch) => {
            const response = await axios({
                method: 'POST',
                url: '/subdomain',
                data: {
                    domainUrl: domainUrl,
                    trackingId: `${campaignInfo.trackingId}`,
                    campaign: `${campaignInfo._id}`,
                    type: "display"
                }
            }).catch(error =>{throw error})
            dispatch({type:POST_DISPLAY_PAGE_DATA,payload:response.data});
        }
    )
}

// delete request called in displayPage
export const deleteDisplayPageData = (id) => {
    return(
        async(dispatch) => {
            const response = await axios({
                method:'DELETE',
                url:`/notificationpath/${id}`
            }).catch(error => {throw error})
            dispatch({type:DELETE_DISPLAY_PAGE_DATA,payload:response.data});
        }
    )
}

//put request is called. 
export const putRunOnAllPageData = (campaignInfo,runOnAllPage,runOnAllPageData) => {
    return(
        async(dispatch) => {
            const response = await axios({
                method: 'PUT',
                url: `/rules/${campaignInfo.rule._id}`,
                data: {
                    displayOnAllPages: runOnAllPage,
                    _id:runOnAllPageData._id,
                    hideNotification: runOnAllPageData.hideNotification,
                    loopNotification: runOnAllPageData.loopNotification,
                    delayNotification: runOnAllPageData.delayNotification,
                    closeNotification: runOnAllPageData.closeNotification,
                    initialDelay: runOnAllPageData.initialDelay,
					displayTime: runOnAllPageData.displayTime,
					delayBetween: runOnAllPageData.delayBetween,
					displayPosition: runOnAllPageData.displayPosition,
					popupAnimationIn: runOnAllPageData.popupAnimationIn,
					popupAnimationOut: runOnAllPageData.popupAnimationOut,
					displayNotification: runOnAllPageData.displayNotification,
					popupPositionInMobile: runOnAllPageData.popupPositionInMobile,
					id: runOnAllPageData.id,
					trackingId: campaignInfo.trackingId,
					websiteUrl:campaignInfo.websiteUrl
                }
            }).catch(error => {throw error});
            dispatch({type:RUN_ON_ALL_PAGE_DATA, payload:response.data.data});
        }
    )
}


