import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import CreateIcon from '@material-ui/icons/Create';
import Moment from 'react-moment';
import { Link, browserHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import HoverMenu from '../../../Globals/GlobalComponents/HoverMenu';
import ToggleSwitch from '../../../Globals/GlobalComponents/ToggleSwitch';
import Pill from '../../../Globals/GlobalComponents/Pill';
import {getCookieCampaigns, deleteCookieCampaign, saveOpenedCookieCamapign, getMicropolicies, updateCookieCamapign} from '../actions'
import AddCampaign from "./AddCampaign";
import DeleteDialog from './DeleteDialog';
import { createStyles, makeStyles } from '@material-ui/core';
import CampaignListSkeleton from '../../../Globals/GlobalComponents/CampaignlistSkeleton';
import ProductAccessModal from '../../../Globals/GlobalComponents/ProductAccessModal/ProductAccessModal';
import { updateUser } from '../../../ducks/auth';


const CookieCampaignList=()=> {
    
    const dispatch = useDispatch()
    const classes = useStyles()
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [selectedCampaign, setSelectedCampaign] = useState("")
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)
    const [access, setAccess] = useState(true)
    const [loading, setLoading] = useState(false)


    const cookieCampaigns = useSelector(state => (state.getIn(['cookieCampaigns','cookieCampaigns'])))
    const user = useSelector(state=>(state.getIn(['auth','user'])))

    useEffect(()=>{
        if("access" in user){
            setAccess(!user.access.isCookie)
            console.log('---------', user.access.isCookie)
            setPageLoading(false)
            // !cookieCampaigns ? handleGetCampaigns() : setPageLoading(false)
        }
        else{
            // !cookieCampaigns ? handleGetCampaigns() : setPageLoading(false)
        }
        !cookieCampaigns ? handleGetCampaigns() : setPageLoading(false)

        setLoading(false)
    },[user])

    const handleUserUpdate = () =>{
        const newUser = {}
        newUser['id'] = user._id
        newUser['access'] = {isCookie:true,isSocialProof:user.access.isSocialProof}
        setLoading(true)
        console.log('updateCalled')
        console.log({newUser})
        dispatch(updateUser(newUser))
        
    }

    const dialogHandler = () =>{
        setIsDialogOpen(!isDialogOpen)
    }

    const handleGetCampaigns = () =>{
        dispatch(getCookieCampaigns(null, afterAction))
    }

    const afterAction = (response, err) =>{
        setPageLoading(false)
    }

    const handleDeleteCampaign=(id) =>{
        setLoadingDelete(true)
        dispatch(deleteCookieCampaign(id, cookieCampaigns, handleDeleteDialogClose))
    }


    // useEffect(()=>{
    //     !cookieCampaigns ? handleGetCampaigns() : setPageLoading(false)
    // },[])

    const dateFormatter = (date) => {
        return date.slice(0,10)
    }

    const pushToSettings = (campaign) =>{
        dispatch(saveOpenedCookieCamapign(campaign))
        dispatch(getMicropolicies(campaign._id))
        if(campaign.onBoarding){
            browserHistory.push(`/cookie-monster/${campaign._id}/appearance`)
        }else{
            browserHistory.push(`/cookie-monster/new-campaign/${campaign._id}`)
        }
        // browserHistory.push(`/cookie-monster/${campaign._id}/appearance`)
    }

    const handleDeleteDialogClose =() =>{
        setIsDeleteDialogOpen(false)
        setSelectedCampaign("")
        setLoadingDelete(false)
    }

    const handleOpenDeleteDialog = (id) =>{
        setIsDeleteDialogOpen(true)
        setSelectedCampaign(id)
    }

    const handleToggle = (campaign) =>{
        setSelectedCampaign(campaign)
        setLoadingDelete(true)
        const newData = {
            ...campaign,
            isActive: !campaign.isActive
        }
        dispatch(updateCookieCamapign(newData, afterToggleAction))
    }

    const afterToggleAction = (err,response)=>{
        setSelectedCampaign("")
        setLoadingDelete(false)
    }

    

    return (
        <Box >
            {/* <ProductAccessModal open={access}/> */}
            <Box style={{display:'flex',alignItems:'center', justifyContent:'space-between', marginBottom:'1rem'}}>
                <Typography variant="h4">
                    Campaigns
                </Typography>
                <Button variant="contained" onClick={dialogHandler}>
                    <AddIcon style={{fontSize:'14px'}}/>Add Campaign
                </Button>
            </Box>


            <Box>
                {
                    pageLoading ? 
                        <CampaignListSkeleton/>:
                        <TableContainer>

                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Campaign</TableCell>
                                        <TableCell>URL</TableCell>
                                        <TableCell>Last Updated</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Pixel Status</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        cookieCampaigns && cookieCampaigns.length > 0 ?
                                        cookieCampaigns.map((item, index)=>(
                                            <TableRow key={index} hover style={{cursor:'pointer'}} className={classes.tableRowStyle}>
                                                <TableCell className={"tableRowHoverText"} onClick={()=>pushToSettings(item)}>{item.campaignName}</TableCell>
                                                <TableCell onClick={()=>pushToSettings(item)}>{item.websiteUrl}</TableCell>
                                                <TableCell onClick={()=>pushToSettings(item)}>
                                                    <Moment format="MMM D, YYYY" date={dateFormatter(item.updatedAt)}/>
                                                </TableCell>
                                                <TableCell>
                                                    <ToggleSwitch onChange={()=>handleToggle(item)} checked={item.isActive} loading={loadingDelete && selectedCampaign === item}/>
                                                </TableCell>
                                                <TableCell onClick={()=>pushToSettings(item)}>
                                                    <Pill type={item.isActive ? 'Green': 'Red'}>{item.isActive ? 'Active' : 'Inactive'}</Pill>
                                                </TableCell>
                                                <TableCell>
                                                    <HoverMenu>
                                                        <CreateIcon titleAccess="Edit"  onClick={()=>pushToSettings(item)}/>
                                                        <DeleteOutlineIcon titleAccess="Delete"  onClick={()=>handleOpenDeleteDialog(item)}/>
                                                    </HoverMenu>
                                                </TableCell>
                                            </TableRow>
                                        )):
                                        <TableRow style={{cursor:'default'}} className={classes.tableRowStyle}>
                                            <TableCell>No data available</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                }
            </Box>
            <AddCampaign dialogCloseHandler={dialogHandler} isDialogOpen={isDialogOpen} cookieCampaigns={cookieCampaigns}/>
            <DeleteDialog 
                open={isDeleteDialogOpen} 
                handleDialogClose={handleDeleteDialogClose} 
                loadingDelete ={loadingDelete}
                handleDeleteCampaign = {handleDeleteCampaign}
                id = {selectedCampaign._id}
            />
            <ProductAccessModal disabled={false} product={'cookie'} open={access} isLoading={loading} onClick={handleUserUpdate}/>
        </Box>
    )
}


const useStyles = makeStyles((theme)=>
    createStyles({
        tableRowStyle:{
            cursor:'pointer',
            background:'#fff',
            "& .tableRowHoverText":{
                fontWeight:'500',
                textTransform:'capitalize'
            }
        }
    })
)

export default CookieCampaignList
