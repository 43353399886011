import { fromJS } from 'immutable'

const initialState = fromJS({
  loading: false,
  error: false,
  data: {
    user: '',
    trackingId: '',
    policyCampaign: '',
    websiteUrl: '',
    services: [
      {
        _id: '2',
        name: 'service2',
        pid: 'asdf',
        personalData: [], //strings ids of personal data
        regionalData: [], //strings ids of region
        cookieLifeTime: {
          value: 0,
          data: [
            {
              name: '',
              cookieDuration: 0, //float
              cookieDurationUnit: '', //d,m,y,h
            },
          ],
        },
      },
    ],
  },
})

export const ADDED_SERVICES = {
  LOADING: 'ADDED_SERVICES_DATA_LOADING',
  LOADED: 'ADDED_SERVICES_DATA_LOADED',
  FETCHED: 'ADDED_SERVICES_DATA_FETCHED',
  ERROR: 'ADDED_SERVICES_DATA_ERROR',
  ADDED: 'ADD_NEW_SERVICE',
}

const addServiceReducer = (state = initialState, action) => {
  const currentState = state.get('data').toJS()

  switch (action.type) {
    case ADDED_SERVICES.LOADING:
      return state.merge({ loading: true, error: false })

    case ADDED_SERVICES.FETCHED:
      return state.merge({ loading: false, data: action.payload })

    case ADDED_SERVICES.ADDED:
      return state.merge({
        loading: false,
        [data.services]: [...currentState.data.services, action.payload],
      })

    case ADDED_SERVICES.ERROR:
      return state.merge({ loading: false, error: action.payload })

    default:
      return state
  }
}

export default addServiceReducer
