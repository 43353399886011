import React, { useState, useEffect } from "react";
import { Box, Drawer, makeStyles, createStyles, Typography } from "@material-ui/core";
import { Link } from "react-router";
import { useSelector } from 'react-redux';
import { quotaExhausted } from "../../../utilities/quotaExhausted";



const CookieSideDrawer = (props) =>{

    const profile = useSelector(state=>(state.getIn(['profile','profile'])))

    const classes = useStyles({quotaExhausted:quotaExhausted(profile)})
    const [drawerOpen, setDrawerOpen] = useState(true)
    const [activeNav, setActiveNav] = useState(0)



    useEffect(()=>{
        setDrawerOpen(true)
        return(()=>{ 
            setDrawerOpen(false)
        })
    },[])


    const addActiveClass = (testString) =>{
        if (window.location.href.includes(testString)) return 'active'
    }

    const addActiveNavClass = () =>{
        const currLocation = window.location.href
        if (currLocation.includes('/cookie-monster/pixels')) setActiveNav(1)
         else setActiveNav(0)
    }

    // addActiveNavClass()


   useEffect(()=>{
    addActiveNavClass()
   })

    return(
        <Drawer
            variant="persistent"
            anchor="left"
            open={drawerOpen}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
        
        <Box className={classes.sideBarNavs}>
            <Typography component={Link}  className={activeNav === 0 && 'active'} to="/cookie-monster/campaigns">Campaigns</Typography>
            <Typography component={Link} className={activeNav === 1 && 'active'} to="/cookie-monster/pixels">Pixels</Typography>
        </Box>
            
            
        </Drawer>
    )
}


const useStyles = makeStyles((theme)=>
    createStyles({
        drawerPaper: {
            width: theme.custom.sideDrawer.width,
            marginLeft: theme.custom.sideBar.width ,
            marginTop: props => props.quotaExhausted ?theme.custom.header.height + theme.custom.upgradeStrip.height : theme.custom.header.height,
            zIndex: '99',
            // padding:'10px',
            backgroundColor:'#f5f7f9'
        },
        sideBarNavs:{
            marginTop:'18px',
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-start',
            '& > a':{
                fontSize: '14px',
                fontWeight:'400',
                color:'#222',
                padding:'8px 10px',
                width:'100%',
                borderRadius: theme.shape.borderRadius,
                paddingLeft:'20px',
                '&:hover':{
                    color:'#077fff'
                }
            },
            '& > a.active':{
                color:'#077fff',
                fontWeight:500
                // backgroundColor:'rgba(7,127,255,.16)'
            }
        }
        
    })
)



export default CookieSideDrawer