import { fromJS } from 'immutable'

import { GET_WEBSITES, PIXEL_VERIFY, PIXEL_VERIFICATION, GET_PIXEL_DETAILS } from './Action'

const initialState = fromJS({})

export const PixelPageDetails = (state = initialState, action) =>{
	switch(action.type){
		case GET_WEBSITES:
			return state.set('GetWebsites', action.payload)
		case PIXEL_VERIFY:
			return state.set('PixelVerify', action.payload)
		case PIXEL_VERIFICATION:
			return state.set('PixelVerification', action.payload)
		case GET_PIXEL_DETAILS:
			return state.set('PixelDetails', action.payload)
		default:
			return state
	}
}
