import * as api from 'services/api';
import * as actions from 'ducks/autoklose';
import * as actions_webhook from 'ducks/webhooks';
import { call, put,takeLatest,fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import { succeessAutoKlose,popAutoKlose } from 'ducks/autoklose';
import { load, loaded } from 'ducks/loading';
// import { deleteAutoKlose } from '../ducks/autoklose';

const errorMessge = 'Please try again or refresh!';

const toastConfig = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 2000,
  className: 'toast-style'
};


function* get(action) {
  try {
    yield put(load());
    const res = yield call(api.GET, `autoklose?campaignId=${action.campaignId}`);
    if(!res.error) {    
      yield put(succeessAutoKlose(res));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    }
    else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* add(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, 'autoklose', action.autoklose);
    if (!res.error) {
      yield put(succeessAutoKlose(res));
      yield toast.error('api key added', toastConfig);
      yield put(actions_webhook.fetchAppsConnection(action.autoklose.campaignId));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    }
    else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

function* deleteAutoKlose(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, `autoklose/${action.id}`);
    if(!res.error) {    
      yield put(popAutoKlose(action.index));
    } else {
      yield toast.error(res.message, toastConfig);
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    Sentry.captureException(error);
    if (error == 'TypeError: Failed to fetch') {
      yield toast.error(errorMessge, toastConfig);
    }
    else {
      yield toast.error(error.message, toastConfig);
    }
  }
}

export function* watchAdd() {
  yield takeLatest(actions.CREATE, add);
}

export function* watchGet() {
  yield takeLatest(actions.FETCH, get);
}

export function* watchDelete() {
  yield takeLatest(actions.REMOVE, deleteAutoKlose);
}

export default function* rootSaga() {
  yield [
    fork(watchAdd),
    fork(watchGet),
    fork(watchDelete)
  ];
}
  