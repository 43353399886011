import React, { Component, Suspense } from 'react';
import { browserHistory } from 'react-router';
import { AppContainer } from 'react-hot-loader';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import rootSaga from './sagas';
import reducer from './ducks';
import thunk from 'redux-thunk';
import { syncHistoryWithStore } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import { StripeProvider } from 'react-stripe-elements';
import { MuiThemeProvider } from '@material-ui/core';
import theme from './Resources/theme';
import logger from 'redux-logger'
import axios from 'axios';
import { CustomLoading } from './components';
// import { BrowserRouter, Switch, Route } from 'react-router-dom';
import "./style.css"
var mixpanel = require('mixpanel-browser')


const sagaMiddleware = createSagaMiddleware();

let middlewares = [sagaMiddleware, thunk]

if(process.env.NODE_ENV !== 'production'){
  // let logger = require('redux-logger')
  middlewares.push(logger)
}

const composeEnhancers = composeWithDevTools(({trace:true}))

export const store = createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)));

const routerHistory = syncHistoryWithStore(browserHistory, store, { selectLocationState: state => state.get('router') });


sagaMiddleware.run(rootSaga);

axios.defaults.baseURL = 'https://api.useinfluence.co'

class App extends Component {
  constructor() {
    super();
    this.state = { stripe: null };
  }
  componentDidMount() {
    if (window.Stripe) {
      process.env.NODE_ENV === 'production'
        ? this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_KEY) })
        : this.setState({ stripe: window.Stripe(process.env.REACT_APP_DEVELOPMENT_STRIPE_KEY) })
    } else {
      // document.querySelector('#stripe-js').addEventListener('load', () => {
      //   process.env.NODE_ENV === 'production' ?  this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_KEY)}) : this.setState({stripe: window.Stripe(process.env.REACT_APP_DEVELOPMENT_STRIPE_KEY)});
      // });
    }
    // const calendlyStatus = window.location.href.includes('calendly=true')
    // if(calendlyStatus){
    //   console.log('-----------------calendly running ------------', calendlyStatus)
    //   window.Calendly.initPopupWidget({url: 'https://calendly.com/kirti-1/one-on-one'})
    // }
    mixpanel.init(
      'da8617de8eb447bb98358506c1c34e33',
      { api_host: 'https://api-eu.mixpanel.com' },
      ''
    )
  }
  render() {
    let { stripe } = this.state;

    return (

      <MuiThemeProvider theme={theme}>
        <AppContainer errorReporter={Error}>
          <Provider store={store}>
            <StripeProvider stripe={stripe} >
              <Suspense fallback={<CustomLoading isLoading={true}/>}>
                <this.props.Component routerHistory={routerHistory} />
              </Suspense>
            </StripeProvider>
          </Provider>
        </AppContainer>
      </MuiThemeProvider>

    );
  }

}

export default App;
