import { fromJS } from 'immutable';

const action = name => `/google/${name}`;

export const FETCH = action('FETCH');
export const FETCH_SUCCESS = action('FETCH_SUCCESS');
export const CREATE = action('CREATE');
export const CREATE_SUCCESS = action('CREATE_SUCCESS');
export const UPDATE = action('UPDATE');
export const REMOVE = action('REMOVE');
export const POP_NETAPP = action('POP_NETAPP');

export const getGoogle = (campaignId) => ({ type: FETCH, campaignId });
export const addGoogle = (google) => ({ type: CREATE, google });
export const updateGoogle = (google) => ({ type: UPDATE, google });
export const deleteGoogle = (id, index) => ({ type: REMOVE, id, index });
export const succeessGoogle = (data) => ({ type: FETCH_SUCCESS, data });
export const popGoogle = (index) => ({ type: POP_NETAPP, index });

const initialState = fromJS({});
const google = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return state.set('google', action.data);  
    case POP_NETAPP:
      return state.set('google', state.get('google').slice(0, action.index).concat(state.get('google').slice(action.index+1)));
    default:
      return state;
  }
};

export default google;
