import React from 'react'
import { Box, makeStyles, createStyles } from '@material-ui/core'
import SocialProofSideDrawer from '../../components/SocialProofSideDrawer/SocialProofSideDrawer'

const SocialProofContainer  = (props) => {
    const classes = useStyles()
    return(
        <Box className={classes.container}>
            {/* <Box style={{}}>Tesing</Box> */}
            <SocialProofSideDrawer/>
            {props.children}
        </Box>
    )
}

const useStyles = makeStyles(theme =>
    createStyles({
        container:{
            marginLeft:theme.custom.sideDrawer.width,
            marginTop:'36px',
            padding:theme.spacing(3),
            // position:'relative'
        }
    })
)

export default SocialProofContainer