import React, {useState} from 'react'
import { Box, makeStyles, createStyles, Typography, IconButton, Grow } from '@material-ui/core'
import { Google, Woocommerce, Wordpress, ShopifyIcon, MagentoIcon,  } from '../../img'
import CodeIcon from '@material-ui/icons/Code';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';


const PixelPageSideBar = () =>{

	const classes = useStyles()
	const [openHelpBox1, setOpenHelpBox1 ] = useState(false)
	const [openHelpBox2, setOpenHelpBox2 ] = useState(false)
	const [openHelpBox3, setOpenHelpBox3 ] = useState(false)
	const [openHelpBox4, setOpenHelpBox4 ] = useState(false)
	const [openHelpBox5, setOpenHelpBox5 ] = useState(false)
	const [openHelpBox6, setOpenHelpBox6 ] = useState(false)
	const [openHelpBox7, setOpenHelpBox7 ] = useState(false)
	const headerHelpLink = 'https://support.useinfluence.co/article/697'
	const googleHelpLink = 'https://support.useinfluence.co/article/673'
	const woocommerceHelpLink = 'https://support.useinfluence.co/article/650'
	const wordpressHelpLink = 'https://support.useinfluence.co/article/668'
	const shopifyHelpLink = 'https://support.useinfluence.co/article/650'
	const clickFunnelHelpLink = 'https://support.useinfluence.co/article/678'
	const otherHelpLink = 'https://support.useinfluence.co/category/170'


	const cardBtnHandler = (state,setState) => {
		if(state === true) {
			setState(false)
		}else setState(true)
		
		if(state !== openHelpBox1){
			setOpenHelpBox1(false)
		}
		if(state !== openHelpBox2){
			setOpenHelpBox2(false)
		}
		if(state !== openHelpBox3){
			setOpenHelpBox3(false)
		}
		if(state !== openHelpBox4){
			setOpenHelpBox4(false)
		}
		if(state !== openHelpBox5){
			setOpenHelpBox5(false)
		}
		if(state !== openHelpBox6){
			setOpenHelpBox6(false)
		}
		if(state !== openHelpBox7){
			setOpenHelpBox7(false)
		}
	}
	

	return(
		<Box>
			<Typography
				style={{
					fontFamily: 'Poppins',
					fontSize: '16px',
					fontWeight: '500',
					color: '#000'
				}}			
			>
				Integrations
			</Typography>

			<Box>
				<IconButton onClick={()=>cardBtnHandler(openHelpBox1, setOpenHelpBox1)} className={classes.cardBox} disableRipple>
					<CodeIcon fontSize='large' htmlColor='black'/>
					<div
						style={{
							paddingLeft: '10px'
						}}
					>
						<Typography
							className={classes.cardHeading}
						>
							Header Code
						</Typography>

						<Typography
							className={classes.cardText}
						>
							Pixel paste
						</Typography>
					</div>
				</IconButton>
				<Grow in={openHelpBox1} >
					<Box className={openHelpBox1 ? classes.showBox : classes.hideBox}>
						<a className={classes.linkText} href={headerHelpLink} target='_blank'>
							Learn more
						</a>
					</Box>
				</Grow>
			</Box>

			<Box>
				<IconButton onClick={()=>cardBtnHandler(openHelpBox2,setOpenHelpBox2)} className={classes.cardBox} disableRipple>
					<img src={Google} alt='Google' className={classes.cardImage} />

					<div
						style={{
							paddingLeft: '10px'
						}}
					>
						<Typography className={classes.cardHeading}>
							Tag Manager
						</Typography>

						<Typography className={classes.cardText}>
							Make Tags
						</Typography>
					</div>
				</IconButton>
				<Grow in={openHelpBox2}>
					<Box className={openHelpBox2 ? classes.showBox : classes.hideBox}>
						<Typography className={classes.linkText} component={'a'} href={googleHelpLink} target='_blank'>
							Learn more
						</Typography>
					</Box>
				</Grow>
			</Box>

			<Box>
				<IconButton onClick={()=>cardBtnHandler(openHelpBox3,setOpenHelpBox3)} className={classes.cardBox} disableRipple>
					<img src={Woocommerce} alt='Woocommerce' className={classes.cardImage} />

					<div
						style={{
							paddingLeft: '10px'
						}}
					>
						<Typography className={classes.cardHeading}>
							Woocommerce
						</Typography>

						<Typography className={classes.cardText}>
							Install Plugin
						</Typography>
					</div>
				</IconButton>
				<Grow in={openHelpBox3}>
					<Box className={openHelpBox3 ? classes.showBox : classes.hideBox}>
						<a className={classes.linkText} href={woocommerceHelpLink} target='_blank'>
							Learn more
						</a>
					</Box>
				</Grow>
			</Box>

			<Box>
				<IconButton onClick={()=>cardBtnHandler(openHelpBox4, setOpenHelpBox4)} className={classes.cardBox} disableRipple>
					<img src={Wordpress} alt='Wordpress' className={classes.cardImage} />

					<div
						style={{
							paddingLeft: '10px'
						}}
					>
						<Typography className={classes.cardHeading}>
							Wordpress
						</Typography>

						<Typography className={classes.cardText}>
							Plugin Install
						</Typography>
					</div>
				</IconButton>
				<Grow in={openHelpBox4}>
					<Box className={openHelpBox4 ? classes.showBox : classes.hideBox}>
						<a className={classes.linkText} href={wordpressHelpLink} target='_blank'>
							Learn more
						</a>
					</Box>
				</Grow>
			</Box>

			<Box>
				<IconButton onClick={()=>cardBtnHandler(openHelpBox5, setOpenHelpBox5)} className={classes.cardBox} disableRipple>
					<img src={ShopifyIcon} alt='Shopify' className={classes.cardImage} />

					<div
						style={{
							paddingLeft: '10px'
						}}
					>
						<Typography className={classes.cardHeading}>
							Shopify
						</Typography>

						<Typography className={classes.cardText}>
							Marketplace app
						</Typography>
					</div>
				</IconButton>
				<Grow in={openHelpBox5}>
					<Box className={openHelpBox5 ? classes.showBox : classes.hideBox}>
						<a className={classes.linkText} href={shopifyHelpLink} target='_blank'>
							Learn more
						</a>
					</Box>
				</Grow>
			</Box>

			<Box>
				<IconButton onClick={()=>cardBtnHandler(openHelpBox6,setOpenHelpBox6)} className={classes.cardBox} disableRipple>
					<img src={"https://s3.wasabisys.com/influencelogo/logo/click-funnels.svg"} alt='ClickFunnel' className={classes.cardImage} />

					<div
						style={{
							paddingLeft: '10px'
						}}
					>
						<Typography className={classes.cardHeading}>
							ClickFunnel
						</Typography>

						<Typography className={classes.cardText}>
						Plugin Install
						</Typography>
					</div>
				</IconButton>
				<Grow in ={openHelpBox6}>
					<Box className={openHelpBox6 ? classes.showBox : classes.hideBox}>
						<a className={classes.linkText} href={clickFunnelHelpLink} target='_blank'>
							Learn more
						</a>
					</Box>
				</Grow>
			</Box>


			<Box>
				<IconButton onClick={()=>cardBtnHandler(openHelpBox7, setOpenHelpBox7)} className={classes.cardBox} disableRipple>
					<DragIndicatorIcon fontSize='large' htmlColor='black'/>

					<div
						style={{
							paddingLeft: '10px'
						}}
					>
						<Typography className={classes.cardHeading}>
							Others
						</Typography>

						<Typography className={classes.cardText}>
							Help articles
						</Typography>
					</div>
				</IconButton>
				<Grow in={openHelpBox7}>
					<Box style={{color: '#6f5502 !important'}} className={openHelpBox7 ? classes.showBox : classes.hideBox}>
						<a className={classes.linkText} href={otherHelpLink} target='_blank'>
							Learn more
						</a>
					</Box>
				</Grow>
			</Box>


		</Box>
	)
}


const useStyles = makeStyles(() =>
	createStyles({
		cardBox:{
			display: 'flex',
			flexDirection: 'row',
			width: '176px',
			height: '48px',
			alignItems: 'center',
			backgroundColor: '#fff',
			borderRadius: '4px !important',
			boxShadow: '0 8px 22px 0 rgba(0, 0, 0, 0.05)',
			padding: '5px !important',
			paddingLeft: '10px',
			justifyContent: 'flex-start',
			marginTop: '15px !important',
			marginLeft: '0 !important',
			'&:hover':{
				backgroundColor: '#fff'
			},
			'&:focus':{
				outline:'none'
			}
		},
		cardHeading:{
			fontSize: '11px',
			fontWeight: '600',
			fontFamily: 'Poppins',
			color: '#000',
			textAlign: 'left'
		},
		cardText:{
			fontSize: '9px',
			fontWeight: '500',
			fontFamily: 'Poppins',
			margin: '0',
			color: '#7f87a6',
			textAlign: 'left',
		},
		cardImage:{
			height: '29px !important'
		},
		showBox:{
			width: '100%',
			height: '31px',
			padding: '4px !important',
			paddingLeft: '10px',
			margin: 'auto',
			marginTop: '8px !important',
			display: 'block',
			alignItems: 'center',
			textAlign: 'center',
			borderRadius: '5px !important',
			backgroundColor: '#feedaf',
			boxShadow: '0 8px 22px 0 rgba(0, 0, 0, 0.05)',
		},
		hideBox:{
			width: '176px',
			height: '40px',
			padding: '5px !important',
			paddingLeft: '10px',
			marginTop: '5px !important',
			display: 'none',
			alignItems: 'center',
			textAlign: 'center',
			borderRadius: '4px !important',
			backgroundColor: 'rgba(237, 237, 28, 0.6)',
			boxShadow: '0 8px 22px 0 rgba(0, 0, 0, 0.05)',
		},
		helpText:{
			color: '#6f5502',
			fontSize: '13px'
		},
		linkText:{
			color: '#6f5502',
			fontSize: '11px',
			fontWeight: '500',
			textDecoration: 'none',
			fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
			'&:hover':{
				textDecoration: 'underline'
			}
		}
	})
)

export default PixelPageSideBar
