import React from 'react';
import { Box } from '@material-ui/core';
import SettingsPageNavigation from './SettingsPageNavigation';

const SettingsPage  = () => {
    // const classes = useStyles();

    return(
        <Box padding='4%'>
            <SettingsPageNavigation />
        </Box>
    );
}

// const useStyles = makeStyles(theme =>
//     createStyles({

//     })
// )

export default SettingsPage ;