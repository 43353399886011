import React,{useEffect,useState} from 'react';
import { Box, Button, createStyles, makeStyles, Typography } from '@material-ui/core';
import GoalList from './GoalList';
import { createGoal } from './action';
import CreateGoalDialogForm from './CreateGoalDialogForm'
// import GoalListHeader from './GoalListHeader';
import { useDispatch,useSelector } from 'react-redux';
import { getGoalData, goalSchedular } from './action';
import CampaignListSkeleton from '../../Globals/GlobalComponents/CampaignlistSkeleton';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddIcon from '@material-ui/icons/Add';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import axios from 'axios'




const GoalListContainer = (props) => {

    const dispatch = useDispatch();
    const classes = useStyles()
    const userData = useSelector(state => (state.getIn(['auth', 'user']))) || [];
    const goalData = useSelector(state => (state.getIn(['GoalPageData','goalPageData'])));
    const goalFetching = useSelector(state => (state.getIn(['GoalFetching','goalFetching'])));
    const goalSchedularData = useSelector(state => (state.getIn(['GoalFetching', 'goalSchedularData'])))
    //get data from reducer.
    const [loading,setLoading] = useState(false);
    const [customizeGoalData,setCustomizeGoalData] = useState([]);
    // const [loadGoal,setLoadGoal] = useState(true);
    const [dialogStatus, setDialogStatus] = useState(false);

    const [snackbarStatus, setSnackbarstatus] = useState(false);
    const [goalCategory, setGoalCategory] = useState("");
    const [selectedCampaign, setSelectedCampaign] = useState("");
    const [goalName, setGoalName] = useState("");
    const [goalconversionValue, setGoalConversionValue] = useState(1);
    const [goalUrl, setGoalUrl] = useState("");
    const [goalDescription, setGoalDescription] = useState("");
    const [linkUrl, setLinkUrl] = useState([])
    const [linksUrlArray, setLinksUrlArray] = useState([])
    const [verifiedUrls,setVerifiedUrl] = useState([]);
    const [conversionDisabled, setConversionDisabled] = useState(true)
    const [toastMessage, setToastMessage] = useState("");
    const [toastOpening, setToastOpening] = useState(false);
    const profile = useSelector(state => (state.getIn(['profile', 'profile'])));
    const campaignInfoData = useSelector(state => (state.getIn(['CampaignInfo', 'campaignInfo']))) || [];
    const campaignInfo = campaignInfoData.filter((campaign) => (campaign.campaignName === selectedCampaign.label))[0]

    useEffect(() =>{
        goalCategory.value === "Purchase" || goalCategory.value === "Subscribe/Signup" ? setConversionDisabled(false) : setConversionDisabled(true)
        },[goalCategory])
    useEffect(() => {
        // data is saved in state variable so that the data intregated can be changed.
        if(goalData !== undefined){
            setCustomizeGoalData(goalData)
        }
    },[goalData]);

    useEffect(()=>{
        console.log('campaignInfo', campaignInfo)
    },[campaignInfo])

    // dispatch get request 
    useEffect(() => {
        if (goalFetching === undefined || goalFetching.length == 0) {
          dispatch(getGoalData(userData.trackingId, setLoading))
        }
    },[]);

    useEffect(()=>{
        if(goalSchedularData){
            goalSchedularData.trackingId === userData.trackingId ? dispatch(getGoalData(userData.trackingId,setLoading)) : null
        }
    },[goalSchedularData])


    
    const accessLinkClickFeatre = () =>{
        const blockedPlans = ["price_1HQbLBCkWzi7OrXWJob8bWxU", "price_1HiD20CkWzi7OrXWvCZVywSQ", "price_1H89N4CkWzi7OrXW1y4N40Ye", "price_1H9qZ8CkWzi7OrXWUbIVZRGJ"]
        const planId = profile.plan?.id
        if (blockedPlans.includes(planId)){
            return false
        } else{
            return true
        }
    }

    const refreshHandler = () =>{
        dispatch(goalSchedular(userData.trackingId))
    }

   
    const handleCampaignChange = (value) => {
        setSelectedCampaign(value);
    }

    const handleCategoryChange = (value) => {
        setGoalCategory(value);
    }

    const handleGoalName = (event) => {
        setGoalName(event.target.value);
    }

    const handleUrl = (event) => {
        setGoalUrl(event.target.value);
    }

    const handleDescription = (event) => {
        setGoalDescription(event.target.value)
    }

    const handleConversionValue = (event) => {
        setGoalConversionValue(event.target.value);
    }

    const handleLinkUrls = (e)=>{
        setLinkUrl(e.target.value)
    }

    const handleLinkSeperator = (e) =>{
        if(e.keyCode === 13 || e.keyCode ===32){
            setLinksUrlArray([...linksUrlArray, {url:linkUrl, verified:false}])
            handleNewUrlAdd(linkUrl.trim(), linksUrlArray)
            setLinkUrl("")
        }
    }

    
    const handleNewUrlAdd = async (inputUrl, state) =>{
        const res = await axios({
            method:'GET',
            url:`/micropolicy/webdata?link=${inputUrl}`
        })
        // change(inputUrl, state)    
        setLinksUrlArray([...state, {url:inputUrl, verified: res.data.verified}])
        return res.data

    }


    const change = (url, state) =>{
        let u = [...linksUrlArray]
        for(i in u){
          console.log('in for loop----',u[i])
          if(u[i].url === url){
            u[i].verified = true
          }
        }
        console.log('resultinh obj++++++++++--  ', u)
        setLinksUrlArray(u)
      }


    const handleSubmit = () => {
        const goal = {
            website: campaignInfo.websiteUrl,
            campaign: campaignInfo._id,
            trackingId: campaignInfo.trackingId,
            name: goalName,
            url: goalUrl,
            conversion: goalconversionValue,
            description: goalDescription,
            category: goalCategory,
            linkUrl: linksUrlArray
        }
   
        dispatch(createGoal(goal))
        setSnackbarstatus(true);
        setToastMessage("Goal Created Successfully.")
        closeHandler();
    }

    const closeHandler = () => {
        setDialogStatus(false);
        setGoalCategory('');
        setGoalName('');
        setGoalDescription('');
        setGoalConversionValue("");
        setLinkUrl()
        setGoalUrl("")
    }

   
    const handleDialogOpen = () => {
        setDialogStatus(true);
        }

    const handleClose = () => {
        setDialogStatus(false);
      
    }

    const handleCloseToast = () => {
        setToastOpening(false);
    }
    //

   
    return(
        <Box>
            <Box className={classes.topContainer}>
                <Typography style={{color:'#000', fontSize:'19px', fontWeight:'500', marginBottom:'0px', fontFamily:'Poppins', width:'150px'}}>Goals</Typography>
                <div style={{display:"flex",flexDirection:"row"}}>

                    <Button 
                        variant="outlined"
                        onClick = {refreshHandler}
                        style={{marginRight: '12px'}}
                    >
                        <AutorenewIcon fontSize="small"/>
                    </Button>
                   
                    <Button variant="contained" disabled={userData.role.name === "campaignB"} onClick={handleDialogOpen}>
                        <AddIcon style={{fontSize:'14px'}}/> Add Goal
                    </Button>
                </div>
                
           
        
               
            </Box>

             {
                loading ?
                    <CampaignListSkeleton /> :
                    <GoalList goalData={customizeGoalData}/>
            }
            <CreateGoalDialogForm 
                createGoal={createGoal} 
                campaignInfoData={campaignInfoData}
                campaignInfo= {campaignInfo}
                open={dialogStatus}
                handleSubmit={handleSubmit}
                handleLinkUrls={handleLinkUrls}
                handleCategoryChange={handleCategoryChange}
                handleCampaignChange={handleCampaignChange}
                handleClose={handleClose}
                handleDescription={handleDescription}
                handleUrl={handleUrl}
                handleConversionValue={handleConversionValue}
                handleGoalName={handleGoalName}
                handleLinkSeperator={handleLinkSeperator}
                closeHandler={closeHandler}
                setLinksUrlArray={setLinksUrlArray}
                linkUrl={linkUrl}
                goalName={goalName}
                goalconversionValue={goalconversionValue}
                goalUrl={goalUrl}
                goalDescription={goalDescription}
                conversionDisabled={conversionDisabled}
                goalCategory={goalCategory}
                linksUrlArray={linksUrlArray}
                accessLinkClickFeatre={accessLinkClickFeatre}
            />
            <Snackbar 
                open={toastOpening}
                autoHideDuration={5000} 
                onClose={handleCloseToast}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <Alert variant="filled" style={{background:'black'}}>
                    <div style={{display:'flex',width:'auto'}}>
                        {/* <div><DoneAllRoundedIcon style={{color:'#00b300',fontSize:'19px'}}/></div> */}
                        <div style={{fontSize:'11x',paddingLeft:'6px',fontWeight:'400'}}>{toastMessage}</div>
                    </div>
                </Alert>
            </Snackbar>
        </Box>
    )
}


const useStyles = makeStyles(theme =>
    createStyles({
        topContainer:{
            // padding: '0 42px',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'center'
        },
        [theme.breakpoints.down('md')]:{
            topContainer:{
                padding:'0'
            }
        }
    })
)



export default GoalListContainer;


