import React, { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, makeStyles, createStyles, Grid, Typography, Checkbox } from '@material-ui/core';
// import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
import InputBox from '../../../../Globals/GlobalComponents/InputBox';
import CustomHelpIcon from '../../../../Globals/GlobalComponents/CustomHelpIcon';
import UpgradeBanner from '../../../../Globals/GlobalComponents/UpgradeBanner'
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';


const PoweredByBox  = (props) => {
    const classes = useStyles();
    const { 
        setBulkSettings,
        handlePropertyChange,
        recentText2,
        poweredBy,
        poweredByLink,
        togglePoweredBy,
        brandingLocked
    } = props;



    return(
        <Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            {
                brandingLocked &&
                    <UpgradeBanner message={"Upgrade to Advanced Plan to Edit Branding"}/>
            }
            <Grid container direction="column" spacing={3}>
                <Grid item md={12}>
                        <Grid container alignItems='center'>
                            <Grid item >
                                <Checkbox 
                                    checked={togglePoweredBy}
                                    name='togglePoweredBy'
                                    onChange={(e) => handlePropertyChange(e.target.name, e.target.checked, setBulkSettings)}
                                    disabled = {brandingLocked}
                                />
                            </Grid> 
                            <Grid item md={4}>
                                <Typography className={classes.boxTitle}>
                                    Branding
                                    {/* <a href={helpArticleLinks.faq.notification.branding} target="_blank">
                                        <img src = {HelpQuestion}  style={{ height: "13px",marginLeft:"2%"}}/>
                                    </a> */}
                                    <InfluenceHelpIcon href={helpArticleLinks.faq.notification.branding} />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                <Grid item md={12}>
                    <Grid container alignItems='center'>
                        <Grid item md={3}>
                            <Typography variant="body2">By Text</Typography>
                        </Grid>
                        <Grid item md={3}>
                            <InputBox 
                                name="recentText2"
                                value={recentText2}
                                dispatcher={setBulkSettings}
                                onChange={handlePropertyChange}
                                maxLength={20}
                                disabled={brandingLocked}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems='center'>
                        <Grid item md={3}>
                            <Typography variant="body2">Powered By</Typography>
                        </Grid>
                        <Grid item md={3}>
                            <InputBox 
                                name="poweredBy"
                                value={poweredBy}
                                dispatcher={setBulkSettings}
                                onChange={handlePropertyChange}
                                maxLength={35}
                                disabled={brandingLocked}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container alignItems='center'>
                        <Grid item md={3}>
                            <Typography variant="body2">Powered By Link</Typography>
                        </Grid>
                        <Grid item md={3}>
                            <InputBox 
                                name="poweredByLink"
                                value={poweredByLink}
                                dispatcher={setBulkSettings}
                                onChange={handlePropertyChange}
                                disabled={brandingLocked}
                                maxLength={150}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

const useStyles = makeStyles(() =>
    createStyles({
        input: {
            height: '35px',
            backgroundColor: '#fff',
            // marginTop: '4%',
            border: '1px solid hsl(0,0%,80%)',
            width: '100%',
            borderRadius: '4px',
            padding: '0% 3%',
            fontSize: '14px'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            // paddingBottom: '5%'
        },
    })    
)

export default memo(PoweredByBox);