import React, { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import filter from 'lodash/filter'
import { GET } from 'services/api'
import { browserHistory } from 'react-router'
import { Box, makeStyles, createStyles, Typography, Card } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import Button from '@material-ui/core/Button'
import { Paper, Tabs, Tab } from '@material-ui/core'
import { LogoInfluence } from 'img'
import { Link } from 'react-router'
import WebsiteCheckout from '../../WebsitePayment/WebsiteCheckout'
import { LogoInfluenceNew } from '../../../img'
import { hover } from 'reactcss'
import BigToggle from '../../../Globals/GlobalComponents/BigToggle'

const formatNumber = new Intl.NumberFormat()

const WebsitePriceNew = (props) => {
  const { monthlyPlans, yearlyPlans, handlePlanChoose } = props
  const classes = useStyles()

  const [value, setValue] = React.useState(0)
  const [showMonthlyPlans, setShowMonthlyPlans] = React.useState('monthly')

  const handleChangeDuration = (e, name) => {
    console.log('name--------', name)
    if (name !== 'change') {
      setShowMonthlyPlans((prev) =>
        prev !== name ? setShowMonthlyPlans(name) : setShowMonthlyPlans(prev)
      )
    } else {
      setShowMonthlyPlans((prev) =>
        prev == 'monthly' ? setShowMonthlyPlans('yearly') : setShowMonthlyPlans('monthly')
      )
    }
  }
  return (
    <Box style={{ marginTop: '5%' }}>
      <Box>
        <BigToggle onChange={handleChangeDuration} value={showMonthlyPlans} />
      </Box>

      <Typography style={{ fontSize: '11px', color: '#666', textAlign: 'center' }}>
        Save 20% on Annual Plans
      </Typography>

      <Box className={classes.plansContainer}>
        {showMonthlyPlans == 'monthly'
          ? monthlyPlans &&
            monthlyPlans?.map((plan) => (
              <Card
                className={
                  plan.name.includes('Growth') ? classes.hightlistPlanCard : classes.planCard
                }
              >
                <Box className={classes.cardHeader}>
                  <Typography className={classes.planName}>{plan.name.split(' ')[0]}</Typography>
                  <Typography className={classes.planPrice}>${plan.amount / 100}</Typography>
                </Box>
                <Box className={classes.visitorsBox}>
                  <Typography className={classes.planVisitors}>
                    {formatNumber.format(plan.description)}{' '}
                    <div style={{ fontWeight: '400', fontSize: '11px', marginLeft: '4px' }}>
                      Unique Visitors / month
                    </div>
                  </Typography>
                </Box>
                <ul className={classes.planDescriptionContainer} style={{ listStyleType: 'none' }}>
                  {/\b(Free)\b/m.test(plan.name) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      1 Domain only
                    </li>
                  )}
                  {(/\b(Startups)\b/m.test(plan.name) ||
                    /\b(Basic)\b/m.test(plan.name) ||
                    /\b(Growth)\b/m.test(plan.name) ||
                    /\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Agency)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      Unlimited Domains
                    </li>
                  )}
                  {(/\b(Startups)\b/m.test(plan.name) ||
                    /\b(Basic)\b/m.test(plan.name) ||
                    /\b(Growth)\b/m.test(plan.name) ||
                    /\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Agency)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      Custom Settings
                    </li>
                  )}

                  {(/\b(Startups)\b/m.test(plan.name) ||
                    /\b(Growth)\b/m.test(plan.name) ||
                    /\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Agency)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      Done-For-You installation
                    </li>
                  )}

                  {(/\b(Startups)\b/m.test(plan.name) ||
                    /\b(Basic)\b/m.test(plan.name) ||
                    /\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Growth)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      All Notification Types
                    </li>
                  )}
                  {/\b(Free)\b/m.test(plan.name) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      1 Notification Type
                    </li>
                  )}

                  {(/\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Agency)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      Removable Branding
                    </li>
                  )}

                  {(/\b(Growth)\b/m.test(plan.name) ||
                    /\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      CRO Expert Help
                    </li>
                  )}
                  {(/\b(Advanced)\b/m.test(plan.name) || /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />
                      Sub-Accounts & Teammates
                    </li>
                  )}

                  {(/\b(Startups)\b/m.test(plan.name) ||
                    /\b(Growth)\b/m.test(plan.name) ||
                    /\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      24/7 Priority Support
                    </li>
                  )}
                  {(/\b(Startups)\b/m.test(plan.name) ||
                    /\b(Basic)\b/m.test(plan.name) ||
                    /\b(Growth)\b/m.test(plan.name) ||
                    /\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      Conversion Goals
                    </li>
                  )}
                  {/\b(Free)\b/m.test(plan.name) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      Regular Support
                    </li>
                  )}
                </ul>
                <Button
                  onClick={() => handlePlanChoose(plan)}
                  variant="contained"
                  className={classes.submitBtn}
                >
                  Start Free Trial
                </Button>
              </Card>
            ))
          : yearlyPlans.map((plan) => (
              <Card
                className={
                  plan.name.includes('Growth') ? classes.hightlistPlanCard : classes.planCard
                }
              >
                <Box className={classes.cardHeader}>
                  <Typography className={classes.planName}>{plan.name.split(' ')[0]}</Typography>
                  <Typography className={classes.planPrice}>${plan.amount / 100}</Typography>
                </Box>
                <Box className={classes.visitorsBox}>
                  <Typography className={classes.planVisitors}>
                    {formatNumber.format(plan.description)}{' '}
                    <div style={{ fontWeight: '400', fontSize: '11px', marginLeft: '4px' }}>
                      Unique Visitors / month
                    </div>
                  </Typography>
                </Box>
                <ul className={classes.planDescriptionContainer} style={{ listStyleType: 'none' }}>
                  {/\b(Free)\b/m.test(plan.name) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      1 Domain only
                    </li>
                  )}
                  {(/\b(Startups)\b/m.test(plan.name) ||
                    /\b(Growth)\b/m.test(plan.name) ||
                    /\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Agency)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      Unlimited Domains
                    </li>
                  )}
                  {(/\b(Startups)\b/m.test(plan.name) ||
                    /\b(Growth)\b/m.test(plan.name) ||
                    /\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Agency)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      Custom Settings
                    </li>
                  )}

                  {(/\b(Startups)\b/m.test(plan.name) ||
                    /\b(Growth)\b/m.test(plan.name) ||
                    /\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Agency)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      Done-For-You installation
                    </li>
                  )}

                  {(/\b(Startups)\b/m.test(plan.name) ||
                    /\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Growth)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      All Notification Types
                    </li>
                  )}
                  {/\b(Free)\b/m.test(plan.name) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      1 Notification Type
                    </li>
                  )}

                  {(/\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Agency)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      Removable Branding
                    </li>
                  )}

                  {(/\b(Growth)\b/m.test(plan.name) ||
                    /\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      CRO Expert Help
                    </li>
                  )}
                  {(/\b(Advanced)\b/m.test(plan.name) || /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />
                      Sub-Accounts & Teammates
                    </li>
                  )}

                  {(/\b(Startups)\b/m.test(plan.name) ||
                    /\b(Growth)\b/m.test(plan.name) ||
                    /\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      24/7 Priority Support
                    </li>
                  )}
                  {(/\b(Startups)\b/m.test(plan.name) ||
                    /\b(Growth)\b/m.test(plan.name) ||
                    /\b(Advanced)\b/m.test(plan.name) ||
                    /\b(Enterprise)\b/m.test(plan.name)) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      Conversion Goals
                    </li>
                  )}
                  {/\b(Free)\b/m.test(plan.name) && (
                    <li className={classes.planLine}>
                      <img
                        src="https://cdns.useinfluence.co/images/green-check.png"
                        style={{ width: '1rem', height: '1rem', marginRight: '0.35rem' }}
                      />{' '}
                      Regular Support
                    </li>
                  )}
                </ul>
                <Button
                  onClick={() => handlePlanChoose(plan)}
                  variant="contained"
                  className={classes.submitBtn}
                >
                  Start Free Trial
                </Button>
              </Card>
            ))}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    stepBox: {
      height: '50px',
      width: '100px',
      border: '1px solid #c6c6c6',
      borderRadius: '5px',
      margin: 'auto',
      textAlign: 'center',
      padding: '10px',
    },
    cardHeader: {
      textAlign: 'center',
      //borderBottom: '1px solid #c6c6c6',
      padding: '5px 0',
      color: '#2c66c3',
      backgroundColor: '#fff',
    },
    indicatorStyle: {
      background: 'transparent',
    },
    visitorsBox: {
      // color: '#c6c6c6',
      borderBottom: '1px solid #c6c6c6',
      padding: '5px',
      textAlign: 'center',
      color: '#666',
    },
    plansContainer: {
      // display: 'flex',
      //justifyContent: 'center',
      padding: '1% 6%',
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      paddingTop: '0.5%',
      justifyContent: 'space-evenly',
      maxWidth: '1700px',
      margin: 'auto',
    },
    hightlistPlanCard: {
      marginTop: '30px',
      paddingBottom: '20px',
      width: '280px',
      border: '3px solid rgb(120 255 36 / 80%)',
      textAlign: 'center',
      boxShadow: '1px 2px 7px 4px rgba(128, 128, 128, 0.2)',
      '&:hover': {
        boxShadow: '0 10px 20px 0 rgba(0,43,86,.15)',
        borderColor: '#50a3f5',
      },
    },
    planCard: {
      marginTop: '30px',
      paddingBottom: '20px',
      width: '280px',
      border: '1px solid rgba(198, 198, 198, 0.8)',
      textAlign: 'center',
      boxShadow: '1px 2px 7px 4px rgba(128, 128, 128, 0.2)',
      '&:hover': {
        boxShadow: '0 10px 20px 0 rgba(0,43,86,.15)',
        borderColor: '#50a3f5',
      },
    },
    planPrice: {
      fontSize: '28px',
      fontWeight: '700',
      margin: '1px 0',
      color: '#2c66c3',
      padding: '0%',
    },
    planName: {
      fontSize: '17px',
      fontWeight: '700',
      letterSpacing: '0.5px',
      textTransform: 'uppercase',
      color: '#5d5d5d',
      marginTop: '7px',
      marginBottom: '7px',
    },
    planVisitors: {
      fontSize: '12px',
      color: '#666',
      // marginTop: '-26px',
      paddingBottom: '4%',
      fontWeight: '700',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    planDescriptionContainer: {
      height: '225px',
      textAlign: 'left',
      fontSize: '14px',
      padding: '4% 10%',
      marginBottom: '1px',
      marginTop: '1%',
    },

    planLine: {
      fontSize: '13px',
      padding: '2px 5px',
      marginTop: '0',
      marginBottom: '0',
      color: '#082a52',
      letterSpacing: '0.3px',
      fontWeight: '500',
      fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    submitBtn: {
      // backgroundColor: '#000',
      // color: '#fff',
      padding: '4% 15%',
      marginBottom: '7%',
      fontWeight: '500',
      width: '60%',
      whiteSpace: 'nowrap',
      // '&:hover':{
      // 	backgroundColor: '#110ac0'
      // }
    },
    tab: {
      fontSize: '14px',
      width: '90px',
      height: '50px',
      display: 'flex',
      padding: '10px 0px',
      marginRight: '0px',
      marginLeft: '0px',
      color: '#2c66c3',
      backgroundColor: '#fff',
      borderRadius: '9px',
      // transition: '0.3s'
    },
    activeTab: {
      height: '50px',
      // backgroundColor :'#082a52',
      color: '#fff !important',
      fontSize: '14px',
      display: 'flex',
      padding: '0px',
      marginRight: '0px',
      marginLeft: '0px',
      // transition: '0.3s',
      border: '1px solid #12344d',
      background: 'linear-gradient(180deg, #264966 0, #12344d 100%)',
      borderRadius: '2px',
      width: '90px',
    },
    btnStyle: {
      width: '9rem',
      letterSpacing: '0.5px',
      fontSize: '16px',
      padding: '5px 8px',
      [theme.breakpoints.down('md')]: {
        width: '7rem',
      },
    },
    loginBtn: {
      fontSize: '13px',
      color: '#fff',
      backgroundColor: '#082a52',
      borderRadius: '6px',
      fontFamily: "'Raleway','Verdana','Helvetica', sans-serif ",
      '&:hover': {
        backgroundColor: '#fff',
        border: '1px solid rgba(50,50,50,0.7)',
        marginBottom: '5px',
        transition: 'margin 0.2s',
        color: '#fff',
      },
    },
  })
)

// export default WebsitePriceNew

class UpgradePlanPage3 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: false,
      planSelected: '',
      externalValue: true,
      planPeriod: 12,
      servicebotPlans: [],
      planClick: 'Advanced',
      coupon: '',
      monthlyPlans: [],
      yearlyPlans: [],
      selectedPlan: {},
      queryPlan: false,
      // intercomm: ''
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    var url_string = window.location.href
    var url = new URL(url_string)
    // var calendlyStatus = url.searchParams.get('calendly')
    // if (calendlyStatus) {
    //   localStorage.setItem('calendly', 'true')
    // }
  }

  componentWillMount() {
    const queryPlan = this.props.location && this.props.location.query.plan
    if (queryPlan !== 'freePlan') {
      this.setState({ queryPlan })
    }

    var outerThisInstance = this
    const arr = [
      'price_1IfeqLCkWzi7OrXWxhlQF48F',
      'price_1Ifeu2CkWzi7OrXWf6nFYKnT',
      'price_1Ig2EKCkWzi7OrXWACD6IUyK',
    ]
    GET('plan/chargebee').then(function (res) {
      // const PlanDataList = filter(
      //   get(res, 'data'),
      //   (obj) => get(obj, 'product') !== 'prod_Ej0I9T0yyoX4Kp'
      // )
      const PlanDataList = res.filter(i=> !arr.includes(i.id))
      if (PlanDataList && get(PlanDataList, 'length') > 0) {
        let lstPlan = []
        for (var i = 0; i < PlanDataList.length; i++) {
          if (PlanDataList[i].active)
            lstPlan.push({
              currency: PlanDataList[i].currency,
              details: get(PlanDataList[i], 'description'),
              free_quantity: 0,
              id: PlanDataList[i].id,
              invoice_name: PlanDataList[i].nickname,
              name: PlanDataList[i].nickname,
              object: PlanDataList[i].object,
              interval_count: PlanDataList[i].interval_count,
              interval: PlanDataList[i].interval,
              amount: PlanDataList[i].amount,
              status: PlanDataList[i].active,
              description: Number(PlanDataList[i].metadata.uniquevisitorqouta),
              teammateQouta: Number(get(PlanDataList[i], 'metadata.teammateqouta') || 0),
              trial_period: PlanDataList[i].trial_period_days,
            })
        }

        let planClick, planPeriod
        let selectedPlan = {}
        // lstPlan = lstPlan.filter((plan) => plan.name !== 'Agency')

        // if (queryPlan && queryPlan.toLowerCase().indexOf('ltd') !== -1) {
        //   lstPlan = lstPlan.filter((plan) => plan.name.toLowerCase().indexOf('lifetime') !== -1)
        //   planPeriod = 0
        // } else {
        //   lstPlan = lstPlan.filter((plan) => plan.trial_period == 7 || plan.amount == 0)
        // }

        lstPlan.map((plan) => {
          //console.log('===plan.id====',plan.id);
          if (plan.id == queryPlan) {
            planClick = outerThisInstance.filterPlanName(plan.name)
            selectedPlan = plan
          }

          if (plan.id == queryPlan && queryPlan && queryPlan.toLowerCase().indexOf('ltd') === -1)
            planPeriod =
              plan.interval == 'year'
                ? 12
                : plan.interval == 'month' && plan.interval_count == 6
                ? 6
                : 1
        })

        let monthsPlan = []
        monthsPlan = lstPlan.filter((plan) => plan.interval == 'month')
        monthsPlan.sort((a, b) => a.amount - b.amount)

        let yearPlans = []
        yearPlans = lstPlan.filter((plan) => plan.interval == 'year')
        yearPlans.sort((a, b) => a.amount - b.amount)

        //that.setState({servicebotPlans: lstPlan});

        outerThisInstance.setState({
          servicebotPlans: lstPlan,
          selectedPlan: selectedPlan,
          planClick: planClick,
          planPeriod: planPeriod,
          monthlyPlans: monthsPlan,
          yearlyPlans: yearPlans,
        })
      }
    })
  }

  handleChange = (checked) => {
    this.setState({ checked })
  }

  handleMonthChange = () => {
    this.setState({ externalValue: false, planPeriod: 1 })
  }

  // handleQuaterlyChange = () => {
  //   this.setState({externalValue: false, planPeriod: 6});
  // }

  handleYearChange = () => {
    this.setState({ externalValue: true, planPeriod: 12 })
  }

  handleSwitchChange = (value) => {
    if (value) {
      this.setState({ externalValue: true, planPeriod: 12 })
    } else {
      this.setState({ externalValue: false, planPeriod: 1 })
    }
  }

  filterPlanName = (planName) => {
    let res = ''

    switch (true) {
      case /\b(Enterprise)\b/m.test(planName):
        res = 'Enterprise'
        break
      case /\b(Advanced)\b/m.test(planName):
        res = 'Advanced'
        break
      case /\b(Growth)\b/m.test(planName):
        res = 'Growth'
        break
      case /\b(Startups)\b/m.test(planName):
        res = 'Startups'
        break
      case /\b(Lifetime Starter)\b/m.test(planName):
        res = 'Starter'
        break
      case /\b(Lifetime Professional)\b/m.test(planName):
        res = 'Professional'
        break
      case /\b(Lifetime Business)\b/m.test(planName):
        res = 'Business'
        break
      case /\b(Lifetime Agency)\b/m.test(planName):
        res = 'Agency'
        break
      case /\b(Lifetime Enterprise)\b/m.test(planName):
        res = 'Enterprise'
        break
      case /\b(Lifetime Charter)\b/m.test(planName):
        res = 'Charter Partner'
        break
      case /\b(Free Forever)\b/m.test(planName):
        res = 'Forever Free'
        break
      default:
        break
    }
    return res
  }

  stellarFeel = (name) => {
    let stellar = ''
    this.filterPlanName(name) === this.state.planClick ? (stellar = 'bg--primary') : (stellar = '')
    return stellar
  }

  handlePlanSelect = (plan) => {
    this.setState({ selectedPlan: plan })
  }

  clearSelectedPlan = (coupon) => {
    // const selectedPlan = this.state.servicebotPlans.filter(plan => plan.references.service_template_properties.length && plan.references.service_template_properties[0].data.value === coupon.code);
    // this.setState({ coupon: coupon, selectedPlan: selectedPlan[0]});
    this.setState({ coupon: coupon, selectedPlan: {}, queryPlan: false })
  }

  clearCoupon = () => {
    this.setState({ coupon: '' })
  }

  handlePlanChoose = (plan) => {
    console.log(plan)
    this.setState({ planClick: this.filterPlanName(plan.name), selectedPlan: plan })
  }

  handleLogout = () => {
    document.body.style = 'background-color:white'
    localStorage.removeItem('authToken')
    browserHistory.push('/login')
  }

  render() {
    const { selectedPlan, coupon, queryPlan, externalValue, planPeriod } = this.state

    return (
      <div>
        {Object.keys(selectedPlan).length > 0 || queryPlan ? (
          <WebsiteCheckout
            coupon={coupon}
            clearCoupon={this.clearCoupon}
            clearSelectedPlan={this.clearSelectedPlan}
            selectedPlan={selectedPlan}
          />
        ) : (
          <WebsitePriceNew
            servicebotPlans={this.state.servicebotPlans}
            selectedPlan={this.state.selectedPlan}
            yearlyPlans={this.state.yearlyPlans}
            monthlyPlans={this.state.monthlyPlans}
            handlePlanChoose={this.handlePlanChoose}
            handleLogout={this.handleLogout}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.getIn(['auth', 'user']),
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(UpgradePlanPage3)
