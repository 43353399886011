import React, { useEffect, useState } from 'react'
import { Box, makeStyles, createStyles, Dialog, Typography, DialogTitle, DialogContent } from '@material-ui/core'
import { getPreviewHTML } from '../poicyThunks/policymaster/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'

const PreviewPolicy = (props) => {
  const { open, onClose, campaignId } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { previewData } = useSelector((state) => state.get('policymaster').toJS())

  useEffect(() => {
    if (open) dispatch(getPreviewHTML(campaignId))
  }, [open])

  return (
    <Dialog scroll={"paper"} open={open} maxWidth="md" onClose={onClose} classes={{ paper: classes.dialogPaper }}>
      <DialogTitle onClick={onClose} classes={{root:classes.dialogTitleRoot}}>
      </DialogTitle>
      <DialogContent>
        {
          Object.keys(previewData).length ===0  ?
          <>
            <Skeleton variant="rect" height="50"/>
            <Skeleton variant="text" height="40"/>
            <Skeleton variant="text" height="40"/>
            <Skeleton variant="text" height="40"/>
            <Skeleton variant="text" height="40"/>
            <Skeleton variant="text" height="40"/>
          </> : 
          Object.keys(previewData).includes('error') && previewData.error ===true ? 
            <Typography color="error">{previewData.message}</Typography>:
            <Box dangerouslySetInnerHTML={{ __html: previewData }}></Box>

        }

      </DialogContent>
      {/* <Box dangerouslySetInnerHTML={{__html:previewData.substring(previewData.lastIndexOf("<body>")+6, previewData.lastIndexOf("</body>") )}}> */}
    </Dialog>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogPaper: {
      width: '850px',
      padding: theme.spacing(3),
      position: 'relative',
      overflow: 'hidden',
      
    },
    dialogTitleRoot:{
      padding: 0,
      '&:after': {
        position: 'absolute',
        content: '"+"',
        top: 2,
        right: 6,
        transform: 'rotate(45deg)',
        cursor: 'pointer',
        fontSize:'15px',
        fontWeight:'800'
      },
    }
  })
)

export default PreviewPolicy
