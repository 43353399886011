import React from 'react'
import {
  Box,
  makeStyles,
  createStyles,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core'
import ProductAccessModal from '../../../Globals/GlobalComponents/ProductAccessModal/ProductAccessModal'

const LockedPolicyPage = () => {
  const classes = useStyles()

  return (
    <Box className={classes.policiesContainer}>
      <Typography variant="h4">Policies</Typography>
      <Box className={classes.policyStatsContainer}>
        <Typography>
          <span style={{ color: 'gray' }}>Total Generated: </span>
          <Typography component={'span'}>
            {/* {policyCampaigns.campaigns.filter((item) => item.status !== 'draft').length} */}0
          </Typography>
        </Typography>
        <Typography>
          <span style={{ color: 'gray' }}>Drafts: </span>
          <Typography component={'span'}>
            {/* {policyCampaigns.campaigns.filter((item) => item.status === 'draft').length} */}0
          </Typography>
        </Typography>
      </Box>

      <TableContainer className={classes.policiesTableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Policy</TableCell>
              <TableCell className={'dateCell'}>Created On</TableCell>
              <TableCell className={'dateCell'}>Updated On</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Status</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>No campaigns</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ProductAccessModal open={true} isLoading={false} product={'policy'} />
    </Box>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    policiesContainer: {
      '&>h4': {
        color: '#000',
        fontSize: '19px',
        fontFamily: 'Poppins',
        fontWeight: '500',
      },
    },
    policyStatsContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(2),
      '&>p:first-child': {
        marginRight: theme.spacing(2),
      },
    },
    policiesTableContainer: {
      marginTop: theme.spacing(4),
      backgroundColor: '#fff',
      '& .dateCell': {
        maxWidth: '70px',
      },
    },
  })
)

export default LockedPolicyPage
