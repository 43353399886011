import React, { useState, useEffect,  memo } from 'react';
import { makeStyles, createStyles, Typography, Box, Grid, Checkbox, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import BasicBox from './BasicBox'
import ApperanceBox from './ApperanceBox'
import Textbox from './Textbox'
import FeaturesBox from './FeaturesBox'
import AdditionalFeaturesBox from './AdditionalFeaturesBox'
import InputBox from '../../../../Globals/GlobalComponents/InputBox';
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import { useSelector } from 'react-redux';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';



const ReviewNotificationRules = (props) => {
    const classes = useStyles();
    const [checkBoxStatus2, setCheckBoxStatus2] = useState(false);
    const user = useSelector(state=>(state.getIn(['auth','user'])))



    const { 
        handlePropertyChange, 
        setReviewSettings,
        reviewSettings,
        notificationConfiguration,
        handlePanelStyleChange,
        campaignInfo,
        notificationType,
        handleLanguageChange,
        handleNotificationSubmit,
        loading,
        gglReviewText,
        facebookReviewText,
        trustpilotText,
        stampedioText,
        reviewioText,
        capterraText,
        handleNotifType,
        notifType,
        brandingLocked,
        onClose,
        // facebookReviewText
    } = props;


    const handleCheckBox2 = () => {
        if(checkBoxStatus2 === false) {
            setCheckBoxStatus2(true);
        } else {
            setCheckBoxStatus2(false)
            handlePropertyChange('notificationUrl', "" , setReviewSettings)
        }
    }

    useEffect(()=>{
        if(reviewSettings.notificationUrl.length > 0){
            setCheckBoxStatus2(true)
        }
    },[reviewSettings.notificationUrl])



    return(
        <Box>
            <BasicBox
                setReviewSettings={setReviewSettings}
                notificationDisplayApp={reviewSettings.notificationDisplayApp}
                handleLanguageChange={handleLanguageChange}
                handlePropertyChange={handlePropertyChange}
                langName={reviewSettings.langName}
                notificationSize={reviewSettings.notificationSize}
                handleNotifType={handleNotifType}
            />

            <ApperanceBox
                setReviewSettings={setReviewSettings}
                handlePanelStyleChange={handlePanelStyleChange}
                radius={reviewSettings.panelStyle.radius}
                backgroundColor={reviewSettings.panelStyle.backgroundColor}
            />            

            <Textbox
                handlePanelStyleChange={handlePanelStyleChange}
                handlePropertyChange={handlePropertyChange}
                setReviewSettings={setReviewSettings}
                gglReviewText={gglReviewText}
                facebookReviewText={facebookReviewText}
                trustpilotText = {trustpilotText}
                stampedioText = {stampedioText}
                reviewioText = {reviewioText}
                capterraText = {capterraText}
                fontWeight={reviewSettings.panelStyle.fontWeight}
                fontFamily={reviewSettings.panelStyle.fontFamily}
                color={reviewSettings.panelStyle.color}
                notifType={notifType}
                reviewSettings={reviewSettings}
            />            

            <FeaturesBox
                handlePanelStyleChange={handlePanelStyleChange}
                setReviewSettings={setReviewSettings}
                recentNumber={reviewSettings.panelStyle.recentNumber}
                recentConv={reviewSettings.panelStyle.recentConv}
                selectDurationData={reviewSettings.panelStyle.selectDurationData}
                minStarRating={reviewSettings.panelStyle.minStarRating}
                notificationConfiguration={notificationConfiguration[0]}
                selectLastDisplayConversation={reviewSettings.panelStyle.selectLastDisplayConversation}
            />
            <AdditionalFeaturesBox
                setReviewSettings={setReviewSettings}
                handlePropertyChange={handlePropertyChange}
                recentText2={reviewSettings.recentText2}
                poweredBy={reviewSettings.poweredBy}
                poweredByLink={reviewSettings.poweredByLink}
                togglePoweredBy={reviewSettings.togglePoweredBy}
                brandingLocked={brandingLocked}
            />

            <Box borderTop='1px solid #f5f6f7' padding='5% 0%'>
                <Grid container>
                    <Grid item md={12}>
                        <Grid container alignItems='center'>
                            <Grid item >
                                <Checkbox 
                                    checked={checkBoxStatus2}
                                    onChange={handleCheckBox2}
                                />
                            </Grid>
                            <Grid item md={5}>
                                <Typography className={classes.boxTitle}>
                                    Notifications Clickable
                                    {/* <a href={helpArticleLinks.faq.notification.clickable} target="_blank">
                                        <img src = {HelpQuestion}  style={{ height: "13px",marginLeft:"2%"}}/>
                                    </a> */}
                                    <InfluenceHelpIcon href={helpArticleLinks.faq.notification.clickable} />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {checkBoxStatus2 &&
                        <Grid item md={12} className={classes.lowerBoxPadding}>
                            <Grid container alignItems="center">
                                <Grid item md={3}>
                                    <Typography variant="body2" className={classes.gridPading}>HyperLink</Typography>
                                </Grid>
                                <Grid item md={9}>
                                    <InputBox
                                        name="notificationUrl"
                                        dispatcher={setReviewSettings}
                                        value={reviewSettings.notificationUrl}
                                        onChange={handlePropertyChange}
                                        maxLength={150}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Box>
            <Box className={classes.saveBtnContainer}>
                <Button  variant="outlined" onClick={onClose} className={classes.cancelBtn}>
                    Close
                </Button>
                <Button
                    className={classes.saveBtn}
                    disabled={loading || user.role.name === "campaignB"}
                    variant="contained"
                    onClick={
                        () => handleNotificationSubmit(
                                notificationConfiguration[0]._id,
                                reviewSettings,
                                campaignInfo._id,
                                notificationType[0]._id,
                                campaignInfo.websiteUrl,
                                campaignInfo.trackingId
                            )
                    }
                >   
                     Save Changes
                    {loading && <CircularProgress classes={{colorPrimary: classes.circularProgress}} size={25} thickness={5} />}
                </Button>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(theme =>
    createStyles({
        colorIcon: {
            width: '35px',
            height: '35px',
            border: '1px solid #000',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        colorIconActive: {
            width: '35px',
            height: '35px',
            border: '2px solid rgba(201,201,201,0.7)',
            borderRadius: '50%',
            cursor: 'pointer'
        },
        input: {
            height: '35px',
            backgroundColor: '#fff',
            marginTop: '4%',
            border: ' 0.5px solid rgba(0, 0, 0, 0.23)',
            width: '100%',
            borderRadius: '4px',
            padding: '0% 3%',
            fontSize: '13px'
        },
        boxTitle: {
            fontSize: '14px',
            color: '#6b91b7',
            textTransform: 'uppercase',
            // paddingBottom: '5%'
        },
        gridPading:{
            paddingTop: '3%'
        },
        lowerBoxPadding:{
            paddingLeft: '2%'
        },
        saveBtnContainer:{
            position: 'fixed',
            width: '46%',
            bottom: '0',
            zIndex: '2',
            backgroundColor: '#fff',
            padding: '17px 0px',
            marginLeft: '-2.8%',
            borderTop: '1px solid #c6c6c6',
            display: 'flex',
            justifyContent: 'flex-end'
        },
        saveBtn:{
            // textTransform: 'initial',
            // fontWeight: '200',
            // fontSize: '12px',
            position:'relative',
            margin: '0 4%'

        },
        circularProgress:{
            color: '#fff',
            top:'52%',
            left:'52%',
            marginTop:-12,
            marginLeft:-12,
            position:'absolute'
        }
    })    
)

export default memo(ReviewNotificationRules);