import React from 'react';
import './customLoading.css';
import { ScaleLoader } from 'react-spinners';
const override = `
    position: fixed;
    z-index: 999;
    height: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
`;
const CustomLoading = ({isLoading}) => {
  return (
    <div id="loaderDiv" style={{display:isLoading?'block':'none'}}>
      <ScaleLoader
        sizeUnit={'px'}
        size={150}
        color={'#097fff'}
        loading={true}
        css={override}
      />
      <p id="pTest">We are loading your data...</p>
    </div>
  );
};

export default CustomLoading;