import React, { useState, useEffect } from 'react'
import {
  Box,
  makeStyles,
  createStyles,
  Dialog,
  Grid,
  Typography,
  InputBase,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { fade } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import clsx from 'clsx'
import { SelectBox } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from 'Globals/GlobalComponents/TextInput'
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import HoverMenu from 'Globals/GlobalComponents/HoverMenu';
import SearchIcon from '@material-ui/icons/Search'
import AddServiceDialog_2 from './AddServiceDialog_2';
import { postPolicymaster } from '../poicyThunks/policymaster/actions';
import LoadingButton from 'Globals/GlobalComponents/LoadingButton'
import { getProperty } from 'utilities/utilities'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const AddServiceDialog = (props) => {
  const {
    open,
    onClose,
    policiesData,
    activeStep,
    serviceEditingData,
    serviceDeleteHandler,
    addedServices
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  // const addedServices = useSelector((state) => state.get('policymaster').toJS())

  // const policyPersonalRegionalData = useSelector((state) => state.get('policyPersonalRegionalData').toJS())

  const [policyCategories, setPolicyCategories] = useState([])
  const [modalScreen, setModalScreen] = useState(1)
  const [allServices, setAllServices] = useState([])
  const [filteredServices, setFilteredServices] = useState({ filterBy: '', services: [] })
  const [selectedService, setSelectedService] = useState({})
  const [personalDataOptions, setPersonalDataOptions] = useState({ options: [], defaultValue: [] })
  const [regionDataOptions, setRegionDataOptions] = useState({ options: [], defaultValue: [] })
  const [searchQuery, setSearchQuery] = useState('')
  const [isNew, setIsNew] = useState(true)
  const [loading, setLoading] = useState(false)
  // const [savedServicesData, setSavedServicesData] = useState({
  //   user: '5c6d4b8698948500132d07e7',
  //   trackingId: 'INF-3gbfcjjsd6vhvo',
  //   policyCampaign: '5fead6944b74a87c48b414f8',
  //   websiteUrl: '23gsf3dsgawf34.coff11',
  //   service: [],
  // })
  const [savedServicesData, setSavedServicesData] = useState([])

  const demoFrequentServies = [
    'Contact Form',
    'Facebook Account',
    'Facebook  Like',
    'Google Location',
  ]

  useEffect(() => {
    setPolicyCategories(policiesData.data.filter((item) => item.pid === '0'))
    setAllServices(policiesData.data.filter((item) => item.pid !== '0'))
  }, [policiesData.data])

  useEffect(() => {
    if (Object.keys(serviceEditingData).length > 0) {
      setIsNew(false)
      selectServiceHandler(serviceEditingData, true)
    }
  }, [serviceEditingData])

  useEffect(() => {
    setModalScreen(activeStep)
  }, [activeStep])

  useEffect(() => {
    console.log('savedServicesData---', savedServicesData)
  }, [savedServicesData])

  useEffect(() => {
    console.log('addedServices---', addedServices)
    // Object.keys(addedServices.data).includes('service') && addedServices.data.service.length > 0
    //   ? setSavedServicesData(addedServices.data.service)
    //   : false
  }, [addedServices.data])

  useEffect(() => {
    setFilteredServices({ filterBy: 'all', services: allServices })
  }, [allServices])

  const filterServicesCatergoryWise = (categoryId) => {
    if (categoryId === 'all') setFilteredServices({ filterBy: 'all', services: allServices })
    else
      setFilteredServices({
        filterBy: categoryId,
        services: allServices.filter((service) => service.pid === categoryId),
      })
  }

  /**
   * Opens editing/adding extra information of services
   * @param {Object} service Service data object
   * @param {Boolean} isEditing Is this function called for editing purpose or creating new service
   */
  const selectServiceHandler = (service, isEditing) => {
    console.log('argument data service--', service)
    setModalScreen(2)
    Object.keys(addedServices.data).includes('service') && addedServices.data.service.length > 0
      ? setSavedServicesData(addedServices.data.service)
      : false
    if (isEditing) {
      setIsNew(false)
      setSelectedService(service)

      const addedServicesObject = allServices.filter((item) => item._id === service._id)

      setSavedServicesData(addedServicesObject)


      const valueGenerator = (fieldName) => {
        const valuesArr = []
        service[fieldName].map((item) => {
          if (typeof item === 'string') valuesArr.push(item)
          else valuesArr.push(item._id)
        })
        return valuesArr
      }

      setPersonalDataOptions({
        options: allServices[
          allServices.findIndex((item) => item._id === service._id)
        ].personalData.map((item) => ({ value: item._id, label: item.name })),
        defaultValue: valueGenerator('personalData'),
      })

      setRegionDataOptions({
        options: allServices[
          allServices.findIndex((item) => item._id === service._id)
        ].regionalData.map((item) => ({ value: item._id, label: item.name })),
        defaultValue: valueGenerator('regionalData'),
      })
      console.log(addedServicesObject)
    } else {
      setIsNew(true)
      setPersonalDataOptions({
        options: service.personalData.map((item) => ({ value: item._id, label: item.name })),
        defaultValue: [],
      })
      setRegionDataOptions({
        options: service.regionalData.map((item) => ({ value: item._id, label: item.name })),
        defaultValue: [],
      })
      setSelectedService(service)
    }
    console.log('selectedService--', service)
  }

  const backButtonHandler = () => {
    setModalScreen(1)
  }

  const handlePostCallback = (res, err) => {
    console.log(res)
    setLoading(false)
  }

  const handlePostData = () => {
    if (modalScreen === 1) return
    setLoading(true)
    // const currServices = addedServices.data.service.map(i=>i._id)
    // let savingData = savedServicesData.filter(item=> currServices.includes(item._id))
    dispatch(postPolicymaster(savedServicesData, handlePostCallback))
  }

  const closeModal = () => {
    setModalScreen(1)
    onClose()
  }



  return (
    <Dialog open={open} maxWidth="md" scroll={"paper"} onClose={onClose} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle classes={{root:classes.dialogTitleRootStyle}}>
          Add Service
        </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item md={3} style={{ margin: 'auto 0' }}>
            <Typography
              onClick={backButtonHandler}
              style={{
                // display: 'inline-block',
                fontSize: '12px',
                color: 'grey',
                cursor: 'pointer',
                display: modalScreen === 1 ? 'none' : 'flex',
                alignItems: 'center',
                gap:'7px'
              }}
            >
              <ArrowBackIcon style={{fontSize:'16px'}} fontsize="small"/>
              Back
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Box
              className={classes.search}
              style={modalScreen === 1 ? { display: 'block' } : { display: 'none' }}
            >
              <div className={classes.searchIcon}>
                <SearchIcon style={{ fill: 'grey' }} />
              </div>
              <InputBase
                style={modalScreen === 1 ? { display: 'block' } : { display: 'none' }}
                placeholder="Type here to search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            {/* <Button variant="outlined">Create Custom Service</Button> */}
          </Grid>
        </Grid>

        <Grid spacing={4} container style={{ marginTop: '16px' }}>
          <Grid
            item
            md={3}
            style={modalScreen === 1 ? { display: 'block' } : { display: 'none' }}
            className={classes.allCategoriesContainer}
          >
            <Typography>Categories</Typography>
            <Box className={classes.categoriesListContainer}>
              <Typography
                className={clsx({ active: filteredServices.filterBy === 'all' })}
                onClick={() => filterServicesCatergoryWise('all')}
              >
                All
                <Typography component="span">{allServices.length}</Typography>
              </Typography>
              {policyCategories.map((category, index) => (
                // <Box>
                <Typography
                  className={clsx({ active: category._id === filteredServices.filterBy })}
                  key={index}
                  onClick={() => filterServicesCatergoryWise(category._id)}
                >
                  {category.name}
                  <Typography component="span">
                    {allServices.filter((item) => item.pid === category._id).length}
                  </Typography>
                </Typography>

                // </Box>
              ))}
            </Box>
          </Grid>

          <Grid
            item
            md={6}
            style={modalScreen === 1 ? { display: 'block' } : { display: 'none' }}
            className={classes.allServicesContainer}
          >
            <Typography>
              Total Services Added:{' '}
              <b>
                {Object.keys(addedServices.data).length > 0
                  ? addedServices.data.service.length
                  : '0'}
              </b>
            </Typography>
            <Box className={classes.servicesListContainer}>
              {filteredServices.services
                // .filter((item) => !addedServices.data.service.map((i) => i._id).includes(item._id))
                .filter((item) => item.name.toUpperCase().includes(searchQuery.toUpperCase()))
                .map((service) => (
                  <Box
                    onClick={() => selectServiceHandler(service, false)}
                    className={clsx({
                      [classes.serviceBoxStyle]: true,
                      // disabledService: addedServices.data.service
                      disabledService: getProperty(addedServices, 'data.service', [])
                        .map((i) => i._id)
                        .includes(service._id),
                    })}
                    key={service._id}
                  >
                    <Typography>
                      {/* <ClassOutlinedIcon className={"serviceIconSvg"} fontSize="small" /> */}
                      {service.icon ? (
                        <img height="18px" width="18px" src={service.icon} alt={'Service Icon'} />
                      ) : (
                        <ClassOutlinedIcon className={'serviceIconSvg'} fontSize="small" />
                      )}
                      {service.name}
                    </Typography>
                    <AddIcon className="serviceAddIcon" fontSize="small" />
                  </Box>
                ))}
            </Box>
          </Grid>
          {/* ----------------step 2 part starts ------------ */}

          {modalScreen === 2 && (
            <AddServiceDialog_2
              personalDataOptions={personalDataOptions}
              regionDataOptions={regionDataOptions}
              modalScreen={modalScreen}
              setSavedServicesData={setSavedServicesData}
              savedServicesData={savedServicesData}
              selectedService={selectedService}
              isNew={isNew}
              addedServices={addedServices}
              // data={editingService}
            />
          )}
          {/* ----------------step 2 part ends ------------ */}

          <Grid item md={3} style={{ borderLeft: '1px solid #f6f6f6', padding: '8px 16px' }}>
            <Box className={classes.frequentServicesContainer}>
              <Typography>Frequent Services</Typography>
              <Box className={classes.frequentServicesListContainer}>
                {demoFrequentServies.map((service, index) => (
                  <Typography key={service + index}>
                    <img src="https://facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png" />
                    <span>{service}</span>
                    <span>+</span>
                  </Typography>
                ))}
              </Box>
            </Box>

            <Box className={classes.addedServicesContainer}>
              <Typography>Added Services</Typography>
              <Box className={classes.addedServicesListContainer}>
                {/* {demoAddedServices.map((service) => (
                  <Box className={classes.addedServiesWrapper}>
                    <Typography>{service}</Typography>
                    <Box className={classes.addedServicesIconsContainer}>
                      <HoverMenu scale={'-4'}>
                        <EditIcon />
                        <DeleteOutlineOutlinedIcon />
                      </HoverMenu>
                    </Box>
                  </Box>
                ))} */}
                {Object.keys(addedServices.data).includes('service') &&
                addedServices.data.service.length > 0 ? (
                  addedServices.data.service.map((item) => (
                    <Box key={item._id} className={classes.addedServiesWrapper}>
                      <Typography>{item.name}</Typography>
                      <Box className={classes.addedServicesIconsContainer}>
                        <HoverMenu scale={'-4'}>
                          <EditIcon onClick={() => selectServiceHandler(item, true)} />
                          <DeleteOutlineOutlinedIcon
                            onClick={() => serviceDeleteHandler(item._id)}
                          />
                        </HoverMenu>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box className={classes.addedServiesWrapper}>
                    <Typography>No service added yet</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        </DialogContent>
        <DialogActions className={classes.footerContainer}>
          <Button variant="outlined" style={{ marginRight: '16px' }} onClick={closeModal}>
            Cancel
          </Button>
          <LoadingButton loading={loading} variant="contained" onClick={handlePostData}>
            Save
          </LoadingButton>
        </DialogActions>
      
    </Dialog>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogPaper: {
      width: '85%',
      height:'90%',
      maxWidth: '1100px',
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(1.5)
    },
    dialogTitleRootStyle:{
      padding:'unset',
      '&>h2':{
        color:'#000',
        marginBottom: '8px', 
        fontSize: '22px', 
        fontWeight: '500'
      }
    },
    allCategoriesContainer: {
      '&>p': {
        marginBottom: theme.spacing(2),
      },
    },
    hide: {
      display: 'none !important',
    },
    categoriesListContainer: {
      border: '1px solid #ddd',
      backgroundColor: '#FBFBFB',
      borderRadius: theme.shape.borderRadius,
      '&>p': {
        borderTop: '1px solid #ddd',
        // borderBottom: '1px solid grey',
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        color: '#808080',
        fontSize: '13px',
        fontWeight: '500',
        letterSpacing: '0.5px',
        '&:first-child': {
          borderTop: 'unset',
          borderRadius: theme.shape.borderRadius,
        },
        '&:last-child': {
          borderBottom: 'unset',
        },
        '&:hover': {
          color: '#121518',
        },
        '&>span': {
          background: '#fff',
          borderRadius: '10px',
          padding: '4px 6px',
          fontSize: '12px',
        },
      },
      '&>p.active': {
        background: '#fff',
        color: '#59636D',
      },
    },
    allServicesContainer: {
      padding: theme.spacing(1),
      '&>p': {
        marginBottom: theme.spacing(2),
      },
    },
    servicesListContainer: {
      border: '1px solid #ddd',
      borderRadius: theme.shape.borderRadius,
    },
    serviceBoxStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 24px',
      cursor: 'pointer',
      borderBottom: '1px solid #dddddd',
      '&.disabledService': {
        pointerEvents: 'none',
        background: '#f7f5f5',
        position: 'relative',
        '&:after': {
          content: '"Added" ',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          fontSize: '8px',
          color: 'grey',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          paddingRight: '20px',
        },
        '& svg.serviceAddIcon': {
          display: 'none',
        },
      },
      '&>p': {
        color: '#808080',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        '&>svg': {
          fontSize: '19px',
          color: '#000000a8',
        },
        // flexBasis:'80%'
      },
      '&>svg': {
        color: '#1f1f1f',
        fontSize: '14px',
        border: ' 1px solid #dddddd',
        borderRadius: '2px',
        boxSizing: 'initial',
        // flexBasis:'10%'
      },
      '&:hover': {
        backgroundColor: '#fbfbfb',
      },
    },

    frequentServicesContainer: {
      '&>p': {
        marginBottom: theme.spacing(2),
      },
    },
    frequentServicesListContainer: {
      border: '1px solid #ddd',
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      '&>p': {
        borderBottom: '1px solid #ddd',
        cursor: 'pointer',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        fontSize: '13px',
        color: '#5a5a5a',
        '&:last-child': {
          borderBottom: 'unset',
        },
        '&>img': {
          width: 11,
          height: 'auto',
          // flexBasis: '8%',
          marginRight: 4,
        },
        '&>span:nth-child(2)': {
          flexBasis: '80%',
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 11,
          letterSpacing: '0.7px',
        },
        '&>span:last-child': {
          flexBasis: '10%',
          fontWeight: '800',
          fontSize: '17px',
          lineHeight: '1',
        },
        '&:hover': {
          // backgroundColor:'#fbfbfb'
        },
      },
    },

    addedServicesContainer: {
      '&>p': {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(3),
      },
    },
    addedServicesListContainer: {
      border: '1px solid #ddd',
      borderRadius: theme.shape.borderRadius,
    },
    addedServiesWrapper: {
      borderTop: '1px solid #ddd',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      gap: '8px',
      '&:first-child': {
        borderTop: 'unset',
      },
      '&>p': {
        fontSize: 12,
        flexBasis: '70%',
        color: '#808080',
        letterSpacing: '0.7px',
      },
    },
    addedServicesIconsContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      flexBasis: '25%',
      '&>svg': {
        fontSize: 14,
        color: 'grey',
        cursor: 'pointer',
      },
    },
    footerContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      // marginTop: theme.spacing(1),
    },
    step2_inputContainer: {
      marginTop: theme.spacing(2),
      '&:first-child': {
        marginTop: '0',
      },
    },
    addCookieLifetimeContainer: {
      padding: '8px 16px',
      border: '1px solid #ddd',
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      '&>p': {
        fontSize: '14px',
        flexBasis: '0',
        flexGrow: '2',
      },
      '&>div': {
        flexBasis: '0',
        flexGrow: '5',
      },
    },
    formInputLabel: {
      fontWeight: '500',
      color: '#808080',
      fontSize: '14px',
    },

    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      padding: theme.spacing(1),
      border: '1px solid #ddd',
      // '&:hover': {
      //   backgroundColor: fade(theme.palette.common.white, 0.25),
      // },
      marginLeft: 0,
      width: '100%',
      // backgroundColor: 'grey',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      // height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: '8px',
    },
    inputRoot: {
      color: 'inherit',
      border: 'none !important',
    },
    inputInput: {
      // padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      paddingLeft: '46px',
      transition: theme.transitions.create('width'),
      width: `calc(100% - 46px)`,
      height: '100%',
    },
  })
)

export default AddServiceDialog
