export const policyInitialState = {
    cookieCampaign: "",
    cookieWidgets: false,
    createdAt: "",
    dataUsage: [],
    description: "",
    essentialPolicy: true,
    // id: "5f6dbdcdd6e809001c82a3ba",
    name: "",
    profile: "",
    provider: [],
    slug: "",
    thirdParty: [],
    trackingId: "",
    updatedAt: "",
    useCookie: true,
    websiteUrl: "",
}


export const MICROPOLICY_PROPERTY_CHANGE = "MICROPOLICY_PROPERTY_CHANGE"
export const INIT = "INIT"


export const micropolicySettingsReducer = (state = policyInitialState, action)=>{
    switch (action.type) {
        case MICROPOLICY_PROPERTY_CHANGE:
            return{...state, [action.data.name]:action.data.value}    
            case INIT :{
                return action.data === "" ? state: action.data
        }
        default:
            return state
    }
}







// export const policyInitialState = {
//     cookieWidgets: false,
//     cookiecampaign: "5f59cf4ab751e80014b5f17d",
//     createdAt: "2020-09-21T12:46:57.723Z",
//     description: "asdfasdfffasd",
//     essentialPolicy: false,
//     id: "5f68a0c13152f3001caa7579",
//     name: "testvv",
//     profile: "5c6d4b8b98948500132d07e9",
//     provider: [
//         {
//             name: 'data1',
//             description: "it's working or nto "
//         },
//         {
//             name: 'data2',
//             description: "Hi! how are you "
//         },
//         {
//             name: 'data3',
//             description: "ito is very fat "
//         },
//     ],
//     slug: "boomb00m",
//     trackingId: "INF-3gbfcjjsd6vhvo",
//     updatedAt: "2020-09-22T11:36:13.812Z",
//     useCookie: false,
//     websiteUrl: "abc123.com",
//     __v: 0,
//     _id: "5f68a0c13152f3001caa7579"
// }