import { TrendingUpRounded } from '@material-ui/icons'
import axios from 'axios'
import getTokens from '../../utilities/getTokens'



export const GET_COOKIE_CAMPAIGNS = "GET_COOKIE_CAMPAIGNS"
export const CREATE_COOKIE_CAMPAIGN = "CREATE_COOKIE_CAMPAIGN"
export const DELETE_COOKIE_CAMPAIGN = "DELETE_COOKIE_CAMPAIGN"
export const UPDATE_COOKIE_CAMPAIGN = "UPDATE_COOKIE_CAMPAIGN"
export const SAVE_OPENED_COOKIE_CAMPAIGN = "SAVE_OPENED_COOKIE_CAMPAIGN"
export const GET_COOKIE_PROVIDERS = "GET_COOKIE_PROVIDERS"
export const SAVE_COOKIE_PROVIDERS = "SAVE_COOKIE_PROVIDERS"
export const ADD_NEW_MICROPOLICY = "ADD_NEW_MICROPOLICY"
export const GET_MICROPOLICIES = "GET_MICROPOLICIES"
export const GET_MICROPOLICY = "GET_MICROPOLICY"
export const PUT_MICROPOLICY = "PUT_MICROPOLICY"
export const UPDATE_MICROPOLICY_DATAUSAGE = "UPDATE_MICROPOLICY_DATAUSAGE"
export const DELETE_MICROPOLICY = "DELETE_MICROPOLICY"
export const GET_PROVIDER = "GET_PROVIDER"
export const POST_PROVIDER = "POST_PROVIDER"
export const GET_COOKIE_CONFIGURATION = 'GET_COOKIE_CONFIGURATION';
export const PUT_COOKIE_CONFIGURATION = 'PUT_COOKIE_CONFIGURATION';
export const POST_THIRDPARTY = 'POST_THIRDPARTY';
export const GET_THIRDPARTYLIST = 'GET_THIRDPARTYLIST';
export const FIRE_TOASTER = "FIRE_TOASTER"
export const COOKIE_CAMPAIGN_PUBLISHED = "COOKIE_CAMPAIGN_PUBLISHED"


/**
 * --Action  
 * Create new Campaign
 * 
 * @param {string} campaignName user entered campaign name
 * @param {string} websiteUrl user entered campaign url
 * @param {string} profile profile._id
 * @param {Array<Object>} cookieCampaigns all previous cookie campaigns of user
 * @param {function} afterAction action after campaign created 
 */

export const addCookieCampaign = (campaignName, completeUrl, profile, cookieCampaigns, afterAction) => {
    const token = getTokens()
    const url = new URL(completeUrl)
    const protocol = url.protocol
    const websiteUrl = url.host
    return (
        async (dispatch) => {
            const res = await axios({
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` },
                url: '/cookie/campaign',
                data: {
                    campaign: {
                        campaignName,
                        protocol,
                        websiteUrl,
                        profile
                    }
                }
            }).catch(err => {
                afterAction(true, err)
                dispatch({type: FIRE_TOASTER, payload: {type:'error', message:err.response.data.message}})
                throw err
            })
            if(res.data.err){
                dispatch({type:'FIRE_TOASTER', payload:{type:'error', message:res.data.message}})
                afterAction(true, err)
            }else{
                dispatch({ type: CREATE_COOKIE_CAMPAIGN, payload: [...cookieCampaigns, res.data.data] })
                dispatch({type: FIRE_TOASTER, payload: {type:'success', message:'Campaign Created Sucessfully'} })
                afterAction(false, res.data.data)
            }
            return res
        }
    )
}


/**
 * --Action
 * 
 * Get request for all the cookie campaigns
 * @param {String} [id] - Optional -> campaignId of opened camapign if opened camapign data needs to save in redux
 * @param {Function} afterAction - Funtion will run after api call
 * If id is passed it'll dispatch the SAVE_OPENED_COOKIE_CAMPAIGN (saveOpenedCookieCamapign) action.
 */
export const getCookieCampaigns = (id ,afterAction) => {
    const token = getTokens()
    return (
        async (dispatch) => {
            const res = await axios({
                method: 'GET',
                headers: { "Authorization": `Bearer ${token}` },
                url: '/cookie/campaign'
            }).catch(err=> {
                dispatch({type: FIRE_TOASTER, payload: {type:"error", message:err.response.data.message}})
                afterAction(res, true)
                throw err
            })
            dispatch({ type: GET_COOKIE_CAMPAIGNS, payload: res.data })
            afterAction(res.data, false)
            if (id){
                dispatch(saveOpenedCookieCamapign(res.data.find(el=>el._id == id)))
            }
            console.log('res--', res)
            return res
        }
    )
}



/**
 * Updates the campaign info 
 * @param {Object} campaignInfo Campaign info of selected campaign
 */

export const updateCookieCamapign = (campaignInfo, afterActions) =>{
    const token = getTokens()
    const {_id, trackingId, profile, ...requestData} = campaignInfo
    return(
        async(dispatch)=>{
            const res = await axios({
                method:'PUT',
                headers: { "Authorization": `Bearer ${token}` },
                url: `/cookie/campaign/${campaignInfo._id}`,
                data:{
                    campaign:{
                        ...requestData
                    }
                }
            }).catch(err=>{
                afterActions(true, err)
                dispatch({type: FIRE_TOASTER, payload: {type:'error', message:'Some Error Occured'} })
                throw err
            })
            afterActions(false, res)
            dispatch({type: UPDATE_COOKIE_CAMPAIGN, payload: res.data})
            dispatch(cookiePublishAction(res.data.profile, res.data.trackingId, res.data.websiteUrl, ()=>{}, false))
            return res
        }
    )
}





/**
 * --Action
 * 
 * Deleting a cookie campaign
 * @param {string} campaignId - camapignId of selected campaign
 * @param {Array<object>} allCampaigns - all camapaign stored
 * @param {function} closeDeleteDialog - close dialog handler
 */

export const deleteCookieCampaign = (campaignId, allCampaigns, closeDeleteDialog) => {
    const token = getTokens()
    return (
        async (dispatch) => {
            const res = await axios({
                method: 'DELETE',
                headers: { "Authorization": `Bearer ${token}` },
                url: `/cookie/campaign/${campaignId}`
            }).catch(err=>{
                dispatch({type: FIRE_TOASTER, payload: {type:"error", message:err.response.data.message}})
                closeDeleteDialog()
                throw err
            })

            if(res.data.err){
                dispatch({type: FIRE_TOASTER, payload: {type:'error', message:'Some error occured'}})
            }else{
                dispatch({type: FIRE_TOASTER, payload: {type:'success', message:'Campaign Deleted Sucessfully'} })
                dispatch({type: DELETE_COOKIE_CAMPAIGN, payload: allCampaigns.filter((campaign)=>campaign._id !== res.data._id)})
            }

            closeDeleteDialog()
        }
    )
}


/**
 * 
 * Add opening campaign into redux store
 * @param {Object} campaign object of campaign which user is opening or has opened
 */
export const saveOpenedCookieCamapign = (campaign) => {
    return ({ type: SAVE_OPENED_COOKIE_CAMPAIGN, payload: campaign })
}



// cookie onboarding process----------------------------



/**
 * --Action
 * 
 * crawling and getting scripts of a website.
 * @param {string} websiteUrl URL of which providers needed
 */
export const getCookieProviders = (websiteUrl, setLoading) => {
    const token = getTokens()

    return (
        async (dispatch) => {
            const res = await axios({
                method: 'POST',
                headers: { "Authorization": `Bearer ${token}` },
                url: `/provider/searchDomain`,
                data: {
                    websiteUrl
                }
            }).catch(err => {
                setLoading(false)
                throw err
            })

            dispatch({ type: GET_COOKIE_PROVIDERS, payload: res.data })
            setLoading(false)
        }
    )
}



/**
 * -- Action  
 * Saving categories of providers during cookie campaign creation
 * @param {string} websiteUrl websiteUrl of campaign
 * @param {string} trackingId trackingId of campaign
 * @param {Array<object>} provider Array of poviders and types/categories
 * @param {function} setLoading setLoading callback function 
 */

export const saveProvidersCaegory = (campaignDetails, provider, setLoading, pushToSettings) => {
    const token = getTokens()

    return (
        async (dispatch) => {
            const res = await axios({
                method: 'POST',
                headers: { "Authorization": `Bearer ${token}` },
                url: '/provider',
                data: {
                    websiteUrl:campaignDetails.websiteUrl,
                    trackingId:campaignDetails.trackingId,
                    cookieCampaign: campaignDetails._id,
                    provider
                }
            }).catch(err => {
                setLoading(false)
                throw err
            })

            dispatch({ type: SAVE_COOKIE_PROVIDERS, payload: res.data })
            dispatch(getCookieCampaigns())
            pushToSettings()
        }
    )
}




/**
 * --Action   
 * Add new micropolicy in campaign
 * @param {string} name Micropolicy Name
 * @param {Boolean} useCookie value of user selected toggle value
 * @param {Boolean} essentialPolicy this policy is essential or not
 * @param {Boolean} cookieWidget Add this to cookie widget
 * @param {Object} campaignObject Object of the current campaign info
 * @param {function} setLoading loader function
 */

export const addNewMicropolicy = (name, description, useCookie, essentialPolicy, cookieWidget, campaignObject, setLoading, afterCreation) => {
    const token = getTokens()

    return (
        async (dispatch) => {
            const res = await axios({
                method: 'POST',
                header: { "Authorization": `Bearer ${token}` },
                url: '/micropolicy',
                data: {
                    name,
                    description: description,
                    useCookie,
                    slug: name,
                    essentialPolicy,
                    cookieWidget,
                    websiteUrl: campaignObject.websiteUrl,
                    trackingId: campaignObject.trackingId,
                    cookieCampaign: campaignObject._id,
                    profile: campaignObject.profile
                }
            }).catch(err => {
                setLoading(false)
                throw err
            })

            dispatch({ type: ADD_NEW_MICROPOLICY, payload: res.data })
            afterCreation(res.data._id)
        }
    )
}

//get micropolicies

export const getMicropolicies = (campId) => {
    const token = getTokens()
    return (
        async (dispatch) => {
            const res = await axios({
                method: 'GET',
                headers: { "Authorization": `Bearer ${token}` },
                url: `/micropolicy/campaign/${campId}`
            }).catch(err => {
                throw err
            })
            dispatch({ type: GET_MICROPOLICIES, payload: res.data })
            return res
        }
    )
}

//get micropolicy by id

export const getMicropolicy = (policyId, updateMicroPolicySettings) => {
    const token = getTokens()
    return (
        async (dispatch) => {
            const res = await axios({
                method: 'GET',
                headers: { "Authorization": `Bearer ${token}` },
                url: `/micropolicy/${policyId}`
            }).catch(err => {
                throw err
            })
            dispatch({ type: GET_MICROPOLICY, payload: res.data })
            // updateMicroPolicySettings(res)
            return res
        }
    )
}

//put mircropolicy details

export const putMicropolicy = (microPolicySettings, setLoading, afterUpdation) => {
    const token = getTokens()

    return (
        async (dispatch) => {
            const res = await axios({
                method: 'PUT',
                headers: { "Authorization": `Bearer ${token}` },
                url: `/micropolicy/update/${microPolicySettings.cookieCampaign}`,
                data: {
                    microPolicy:{
                        ...microPolicySettings
                    },
                    dataUsage:microPolicySettings.dataUsage,
                    provider:microPolicySettings.provider,
                    thirdParty:microPolicySettings.thirdParty
                }
            }).catch(err => {
                setLoading(false)
                throw err
            })

            dispatch({ type: PUT_MICROPOLICY, payload: res.data })
            dispatch({type: FIRE_TOASTER, payload: {type:'success', message:'Micropolicy Updated Successfully'} })
            setLoading(false)
            // afterUpdation(policyId)
        }
    )
}

//delete micropolicy


export const deleteMicropolicy = (policyId, afterDeletion) => {
    const token = getTokens()
    return (
        async (dispatch) => {
            const res = await axios({
                method: 'DELETE',
                headers: { "Authorization": `Bearer ${token}` },
                url: `/micropolicy/${policyId}`
            }).catch(err => {
                dispatch({type: FIRE_TOASTER , payload:{type:'error', message:"Some Error Occured"}})
                throw err
            })
            dispatch({ type: DELETE_MICROPOLICY, payload: res.data })
            dispatch({type: FIRE_TOASTER , payload:{type:'success', message:"Micropolicy Deleted"}})
            afterDeletion()
            return res

        }
    )
}


//To get provider

export const getProvider = (campaignId) => {
    const token = getTokens()
    return (
        async (dispatch) => {
            const res = await axios({
                method: 'GET',
                headers: { "Authorization": `Bearer ${token}` },
                url: `provider/campaign/fetchProvider/${campaignId}`
            }).catch(err => {
                throw err
            })
            dispatch({ type: GET_PROVIDER, payload: res.data })

            return res

        }
    )

}

//To post provider

export const postProvider = () => {
    const token = getTokens()
    return (
        async (dispatch) => {
            const res = await axios({
                method: 'POST',
                headers: { "Authorization": `Bearer ${token}` },
                url: `provider`,
                data: {

                }
            }).catch(err => {
                throw err
            })
            dispatch({ type: POST_PROVIDER, payload: res.data })

            return res

        }
    )

}



//notification configuration

export const getCookieConfiguration = (id, setCookieSettings) => {
    const token = getTokens()
    return (
        async (dispatch) => {
            const res = await axios({
                method: 'GET',
                headers: {"Authorization": `Bearer ${token}`},
                url: `/cookie/configuration/${id}`,
            }).catch(error => { throw error })
            dispatch({ type: GET_COOKIE_CONFIGURATION, payload: res.data })
            setCookieSettings({type: 'INIT', data: res.data})
            return (res);
        }
    );
}

/**
 * Update cookie configuration
 * @param {Object} cookieSettings cookie configuration Object
 * @param {Function} setLoading setLoading funtion
 */

export const putCookieConfiguration = (cookieSettings, setLoading) => {
    const token = getTokens()
    console.log(cookieSettings)
    return (
        async (dispatch) => {
            const res = await axios({
                method: 'PUT',
                headers: { 'Authorization': `Bearer ${token}` },
                url: `/cookie/configuration/${cookieSettings._id}`,
                data: {
                    cookiecampaign: cookieSettings.cookiecampaign,
                    panelStyle: {
                        color: {
                            r: cookieSettings.panelStyle.color.r,
                            g: cookieSettings.panelStyle.color.g,
                            b: cookieSettings.panelStyle.color.b,
                            a: cookieSettings.panelStyle.color.a
                        },
                        noButtonStyle: cookieSettings.panelStyle.noButtonStyle
                    },
                    scrollToConsent: cookieSettings.scrollToConsent,
                    position: cookieSettings.position,
                    customPromptText: cookieSettings.customPromptText,
                    customAcceptText: cookieSettings.customAcceptText,
                    brandingText: cookieSettings.brandingText,
                    brandingStyle: cookieSettings.brandingStyle,
                    byText: cookieSettings.byText,
                    poweredBy: cookieSettings.poweredBy,
                    poweredByLink: cookieSettings.poweredByLink,
                    campaignId: cookieSettings.campaignId,
                    trackingId: cookieSettings.trackingId,
                    langName: {
                        language: cookieSettings.language,
                        name: cookieSettings.langName
                    }
                }
            }).catch(error => {
                setLoading(false)
                throw (error)
            })
            dispatch({ type: PUT_COOKIE_CONFIGURATION, payload: cookieSettings })
            setLoading(false)
        }
    );
}


/**
 * Gets the third party list.
 *
 * @param      {String}  policyId  The id of policy
 * @return     {<type>}  The third party list.
 */
export const getThirdPartyList = (policyId) => {
    const token = getTokens()
    return (
        async (dispatch) => {
            const res = await axios({
                method: 'GET',
                headers: { "Authorization": `Bearer ${token}` },
                url: `/cookie/thirdparty/${policyId}`
            }).catch(err => {
                throw err
            })
            dispatch({ type: GET_THIRDPARTYLIST, payload: res.data })
            return res
        }
    )
}




/**
 * Posts a third party.
 *
 * @param      {String}    name           Policy name
 * @param      {String}    url            Policy URL
 * @param      {String}    trackingId     campaign tracking id
 * @param      {String}    policyId       The id policy 
 * @param      {String}    campId         The campaign Id
 * @param      {String}    profileId      Profile id
 * @param      {Function}  afterCreation  Function which will run after the axios call completion.
 * @return     {Object}    {Response of the post call}
 */
export const postThirdParty = (name, url, trackingId, policyId, campId, profileId, afterCreation) => {
    const token = getTokens()
    return (
        async (dispatch) => {
            const res = await axios({
                method: 'POST',
                headers: { "Authorization": `Bearer ${token}` },
                url: `/cookie/thirdparty`,
                data: {
                    thirdPartyName: name,
                    policyUrl: url,
                    trackingId: trackingId,
                    cookiecampaign: campId,
                    profile: profileId,
                    microPolicy: policyId

                }
            }).catch(err => {
                throw err
            })
            dispatch({ type: POST_THIRDPARTY, payload: res.data })
            afterCreation()
            return res
        }
    )
}




/**
 * Makes the updated changes reflect in the campaign reflect.  
 * Updates memcache.  
 * Path:- cookie/campaign/script/profileId/trackingId?websiteUrl=websiteUrl
 * 
 * @param {String} profileId User/Profile Id
 * @param {String} trackingId Tracking Id of the user
 * @param {String} websiteUrl Website url of the campaign
 * @param {Function} afterActions Function which will run after api call is completed. Error status and response will be passed in args
 * @param {Boolean} showToast Whether to show toast message or not
 * @returns {Object} Response of the API
 */
export const cookiePublishAction = (profileId, trackingId, websiteUrl, afterActions, showToast=TrendingUpRounded)=>{
    const token=getTokens()
    return(
        async(dispatch)=>{
            const res = await axios({
                method:'GET',
                headers:{"Authorization": `Bearer ${token}`},
                url:`/cookie/campaign/script/${profileId}/${trackingId}?websiteUrl=${websiteUrl}`
            }).catch(err=>{
                afterActions(true, err)
                dispatch({type: FIRE_TOASTER, payload: {type:'error', message:err.response.data.message ? err.response.data.message : "Something Went Wrong"}})
                throw err
            })
            showToast && dispatch({type: FIRE_TOASTER, payload: {type:'success', message:'Changes Updated'} })
            dispatch({type: COOKIE_CAMPAIGN_PUBLISHED, payload:res.data})
            afterActions(false, res)
            return res
        }
    )
}




export const getPixelStatusOfCookieCampaign = (campaignId) =>{
    const token = getTokens()
    return(
        axios({
            method:'GET',
            url:`/cookie/pixelverify/${campaignId}`,
            headers:{"Authorization":`Bearer ${token}`}
        })
    )
}