import React, { useEffect, useReducer, useState } from 'react'
import { Box, makeStyles, createStyles, Button, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SubjectIcon from '@material-ui/icons/Subject';
import { Link } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { getMicropolicy, getThirdPartyList, getProvider, putMicropolicy } from '../../actions'
import { CookieIconClientSide, FullCookieIcon } from '../../../../img';
import { policyInitialState, micropolicySettingsReducer, INIT } from './policyReducer';
import LoadingButton from '../../../../Globals/GlobalComponents/LoadingButton';
const CustomCookiePreview3 = React.lazy(()=> import('./CustomCookiePreview3'))

const PolicySettingsContainer = (props) => {
	const { policyId } = props
	const classes = useStyles()
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [tabValue, setTabValue] = useState(0)
	const policy = useSelector(state => (state.getIn(['micropolicyOps', 'getMicropolicy'])))

	const [microPolicySettings, setMicroPolicySettings] = useReducer(micropolicySettingsReducer, policyInitialState)


	const handleGetMicropolicy = () => {
		dispatch(getMicropolicy(props.params.policyId))
		dispatch(getThirdPartyList(props.params.policyId))
	}

	useEffect(()=>{
		policy && setMicroPolicySettings({type: INIT, data: policy})
	},[policy])


	useEffect(() => {
		policyId == undefined && handleGetMicropolicy()
	}, [])

	const handleSaveChanges = () =>{
		console.log(microPolicySettings)
		setLoading(true)
		dispatch(putMicropolicy(microPolicySettings, setLoading))
	}

	useEffect(()=>{
		const currentUrl = window.location.href
		if(currentUrl.includes('details')){
			setTabValue(0)
		}else if( currentUrl.includes('data')){
			setTabValue(1)
		}
		else if( currentUrl.includes('thirdparties')){
			setTabValue(2)
		}
		else if( currentUrl.includes('delete')){
			setTabValue(3)
		}
	})

	const navButtons = [
		{
			name:"Details",
			to:"details",
			icon:<SubjectIcon/>
		},
		{
			name:"Data",
			to:"data",
			icon:<DescriptionOutlinedIcon />

		},
		{
			name:"Third Parties",
			to:"thirdparties",
			icon:<DeviceHubIcon />
		},
		{
			name:"Delete",
			to:"delete",
			icon: <DeleteOutlinedIcon/>
		}
	]

	const activeClassAdder = (index) =>{
		if(index===tabValue) return 'active'
		else return ''
	}

	return (
		<Box>
			<Box style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
				<Box style={{ display: 'flex', width: '70%', justifyContent: 'space-between', alignItems: 'center' }}>
					{navButtons.map((nav, index)=>(
						<Button
							component={Link} to={`/cookie-monster/${props.params.id}/micro-policies/${props.params.policyId}/${nav.to}`} 
							classes={{ root: classes.navButtons, startIcon: classes.startIconStyle}}
							className={tabValue === index ? 'active' : ''}
							startIcon={nav.icon}
						>
							{nav.name}
						</Button>
					))}
				</Box>
				<Box>
					<Button variant="outlined" component={Link} to={`/cookie-monster/${props.params.id}/micro-policies/`} style={{marginRight:'8px'}}>Back</Button>
					<LoadingButton loading={loading} variant="contained" onClick={handleSaveChanges}>
						Save
					</LoadingButton>
				</Box>
			</Box>
			<Grid container md={12} style={{marginTop:'1.5rem'}} spacing={2}>
				<Grid item md={8}>
					{React.cloneElement(props.children, { microPolicySettings, setMicroPolicySettings })}
				</Grid>
				<Grid item md={4}>
					<Box className={classes.cookiBoxStyle}>
						<React.Suspense fallback={<Skeleton height={430} style={{minWidth:'280px'}} width={'100%'}/>}>
							<CustomCookiePreview3 microPolicySettings={microPolicySettings} policyName={"trial 1"} policyDescription={"First trial from other component"}/>
							{/* <img src={FullCookieIcon} className={classes.lockimage} /> */}
							<CookieIconClientSide className={classes.lockimage} />
						</React.Suspense>
					</Box>
				</Grid>
			</Grid>
		</Box>
	)
}


const useStyles = makeStyles(theme =>
	createStyles({
		navButtons: {
			width: '160px',
			height: '30px',
			boxShadow: '0px 1px 2px #c8c8c8',
			fontWeight: '400',
			fontSize: '13px',
			'&:hover': {
				boxShadow: '1px 2px 3px #c6c6c6'
			},
			"&.active":{
				backgroundColor:theme.palette.primary.main,
				color:'#fff',
			}
		},
		cookiBoxStyle: {
			display: 'flex',
			justifyContent: 'center',
			flexDirection: "column",
			alignItems: 'flex-start',
			paddingTop: '7%',
			position: 'sticky',
			top: '7%',
			zIndex: '3600',
			paddingBottom: '11%',
			transition: 'all 0.7s ease-in-out',
			backdropFilter: "blur(0.8px)"
		},
		lockimage: {

			borderRadius: '50%',
			height: '50px',
			width: '50px',
			backgroundColor: 'white',
			boxShadow: 'rgba(84, 92, 164,0.5) 0px 4px 24px',
		},
		startIconStyle:{
			opacity: 0.8,
		}
	})
)

export default PolicySettingsContainer
