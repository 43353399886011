import React, { memo } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import ReactSelect from '../../../../Globals/GlobalComponents/ReactSelect';
import { languageData } from '../../../../Globals/GlobalComponents/languages';
import {HelpQuestion} from '../../../../img'
import helpArticleLinks from '../../../../Resources/helpArticleLinks';
import InfluenceHelpIcon from '../../../../Globals/GlobalComponents/InfluenceHelpIcon';

const BasicBox = (props) =>{

	const { 
        setLiveSettings, 
        handleLanguageChange, 
        // handlePropertyChange,
        langName,
        // notificationSize
		} = props
	
	return(
		<Box borderTop='1px solid #f5f6f7' borderBottom='1px solid #f5f6f7' padding='5% 0%'>
            <Grid container direction="column" spacing={2}>
                <Grid item md={12}>
                    <Grid container alignItems='center'>
                        <Grid item md={3}>
                            <Typography style={{paddingRight: '5px'}} variant="body2">
                                Language
                                {/* <a href={helpArticleLinks.faq.notification.language} target="_blank">
                                    <img src = {HelpQuestion}  style={{ height: "11px",marginLeft:"2%"}}/>
                                </a> */}
                                <InfluenceHelpIcon href={helpArticleLinks.faq.notification.language} fontSize="tiny"/>
                               
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <ReactSelect
                                name="langName"
                                options={languageData}
                                value={langName.language}
                                dispatcher={setLiveSettings}
                                onChange={handleLanguageChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
	)
}


export default memo(BasicBox);
