import React from 'react'
import { Box, makeStyles, createStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types';

const BigToggle  = (props) => {
    const {onChange, value} = props
    const classes = useStyles()
    const [toggleState, setToggleState] = React.useState(false)
    const toggleHandler = () =>{
        setToggleState(prev=>setToggleState(!prev))
    }
    React.useEffect(()=>{
        console.log('adsfadfa-------------', toggleState)
    },[toggleState])
    return(
        <div className={classes.swtichContainer}>
            <div className={classes.leftSide} onClick={(e)=>onChange(e,'monthly')}>
                <Typography className={classes.durationText}>Monthly</Typography>
            </div>
            <div className={classes.toggleNew} onClick={(e)=>onChange(e,"change")}>
                <div className={`${classes.toggleDot} ${value=="yearly" ? classes.animateDotOn : classes.animateDotOff}`}>
                </div>
                <div className={classes.toggleGreen}></div>
            </div>
            <div className={classes.rightSide} onClick={(e)=>onChange(e,'yearly')}> 
                <Typography className={classes.durationText}>Yearly</Typography>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme =>
    createStyles({
        swtichContainer:{
            display: 'flex',
            maxWidth: '200px',
            margin: '30px auto 20px',
            WebkitJustifyContent: 'space-around',
            MsFlexPack: 'distribute',
            justifyContent: 'space-around'
        },
        leftSide:{
            display: 'inline-block',
            padding: '4px 9px',
            borderRadius: '4px',
            fontSize: '15px',
            fontWeight: '500',
            color: '#000',
            cursor: 'pointer'
        },
        toggleNew:{
            left:'auto',
            right:'auto',
            display:'flex',
            width:'40px',
            minHeight:'30px',
            WebkitBoxAlign:'center',
            WebkitAlignItems:'center',
            alignItems:'center',
            MsFlexAlign:'center',
            cursor:'pointer'
        },
        toggleDot:{
            position: 'absolute',
            width: '20px',
            height: '20px',
            borderRadius: '99px',
            backgroundColor: '#fff',
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, .25)',
            transition:'0.3s'
        },
        animateDotOff:{
            transform: ' translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;'
        },
        animateDotOn:{
            transform: ' translate3d(16px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;'
        },
        toggleGreen:{
            width: '40px',
            height: '24px',
            marginRight: 'auto',
            marginLeft: '-2px',
            borderRadius: '999px',
            backgroundColor: '#33d900'
        },
        rightSide:{
            display: 'inline-block',
            padding: '4px 9px',
            borderRadius: '4px',
            fontSize: '15px',
            fontWeight: '500',
            color: '#000',
            cursor: 'pointer'
        },
        durationText:{
            color:'#000',
            fontSize:'15px',
            fontWeight:'500',
            userSelect:"none"
        }
    })
)


BigToggle.propTypes={
    value:PropTypes.oneOf(['monthly', 'yearly']).isRequired,
    onChange: PropTypes.func.isRequired
}


export default BigToggle